import React, { useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';

/**
 * Renders a signature input canvas area
 * @param {{function}} onStrokeEnd: Called when
 * @param {{React.ref object}} reference: Reference to <SignatureCanvas/> object
 * @param {{function}} callOnMount: callback after the component mounts
 */
const SignatureCanvasContainer = ({ onStrokeEnd, reference, callOnMount }) => {
  useEffect(() => {
    callOnMount();
  }, [callOnMount]);

  return (
    <div
      data-qa="patient-info-signature-canvas-container"
      className="signature-canvas-container border border-radius"
    >
      <SignatureCanvas
        ref={reference}
        canvasProps={{
          className: 'signature-canvas',
        }}
        maxWidth={1.5}
        throttle={0}
        onEnd={onStrokeEnd}
        clearOnResize={false}
      />
    </div>
  );
};

export default SignatureCanvasContainer;
