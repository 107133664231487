import React from 'react';

import Button from 'components/common/Button';
import Modal from 'components/COVID/common/Modal';

import { getString } from 'util/lang';

const AddressConfirmationModal = ({
  closeModal,
  address,
  onEditAddress,
  onConfirmAddress,
}) => {
  const onOriginalAddressClick = () => {
    onConfirmAddress();
    closeModal();
  };
  const onEditAddressClick = () => {
    onEditAddress();
    closeModal();
  };
  return (
    <Modal
      close={closeModal}
      containerClassName="confirmation-modal"
      headerContent={getString('confirmAddress')}
      isClosable={true}
    >
      <div className="modal__body">
        <p>{getString('couldNotFindAddressInfo')}</p>
        <div className="address-card-current">
          <div className="address-card-current__label">
            {getString('youEntered')}
          </div>
          <div className="address-card-current__content">
            {address.homeAddress}
          </div>
          <div className="address-card-current__content">
            {address.city && `${address.city}`}
            {address.state && `, ${address.state}`}
            {address.zipCode && ` ${address.zipCode}`}
          </div>
        </div>
      </div>
      <div className="modal__footer d-flex justify-content-between flex-wrap">
        <Button
          isEnabled
          label={getString('useUserProvidedAddress')}
          className="btn btn--large btn-primary btn-block mb-4x"
          onClick={onOriginalAddressClick}
        />
        <Button
          isEnabled
          label={getString('goBackToEdit')}
          className="btn btn--large btn-primary--outline btn-block"
          onClick={onEditAddressClick}
        />
      </div>
    </Modal>
  );
};

export default AddressConfirmationModal;
