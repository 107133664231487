import React, { useCallback, useMemo } from 'react';
import { formatLocationDetailForState } from 'services/services';
import { ServiceGroupMap } from '../constants/clinicAndTimingActions';

export const DetailContext = React.createContext({
  details: {},
  setDetails: () => {},
  pageComplete: {},
  setPageComplete: () => {},
  resetForNewPatient: () => {},
  normalFlow: false,
  setNormalFlow: () => {},
  loading: false,
  setLoading: () => {},
  setActivityId: () => {},
  cancelAppointment: () => {},
  setDateTimeLoading: () => {},
  dateTimeLoading: false,
  fetchNoInsuranceEnabled: () => {},
  getPayerlist: () => {},
  getAppSettings: () => {},
  setAppSettings: () => {},
  setInsuranceCoverageLIst: () => {},
  isSecurityChecked: false,
  setSecurityChecked: () => {},
  updateContentWithSelectedLanguage: () => {},
  updateSelectedLanguageCode: () => {},
  fetchServices: (languageId) => {},
});

/**
 * @template T
 * @typedef {{ allIds: string[], byId: Record<string, T> }} Normalized
 */

/**
 * @typedef {import('types').ServiceType} ServiceType
 * @typedef {import('types').Service} Service
 * @typedef {import('types').SubService} SubService
 * @typedef {import('types').Clinic} Clinic
 * @typedef {{ serviceTypeId: string, serviceId: string, subServiceId?: string }} ServiceSelection
 * @typedef {{
 *    clinicDay: {
 *      schedule_id: string
 *      slot_date: string
 *      block_id: string
 *      venue_code: string
 *      block_start_time: string
 *      block_end_time: string
 *      available_capacity: number
 *    }
 *    date: string
 *    time: string
 *    blockId: string
 *    scheduleId: string
 *    venueCode: string
 *    slotDate: string
 *    blockStartTime: string
 *    blockEndTime: string
 *    canBeRescheduled: boolean
 *  }} Appointment
 * @typedef {import('types').EligibilityQuestion} EligibilityQuestion
 * @typedef {import('types').ScreeningQuestion} ScreeningQuestion
 * @typedef {import('types').LocalizedWebContent} LocalizedWebContent
 *
 *
 * @typedef {{
 *    serviceTypes: Normalized<ServiceType>
 *    services: Normalized<Service>
 *    subServices: Normalized<SubService>
 *    location: Normalized<Clinic>
 *    selectedServices: ServiceSelection[]
 *    selectedLocationId?: string
 *    appLanguageCode: string
 *    appointments: { firstAppointment?: Appointment, secondAppointment?: Appointment }[]
 *    eligibilityQuestions: EligibilityQuestion[][]
 *    screeningQuestions: ScreeningQuestion[][]
 *    eligibilityResponse: Record<string, boolean>
 *    patientHealthAnswers: Record<string, {value: string, additionalInfo?: string}>
 *    localizedWebContents: LocalizedWebContent[]
 *    security: { token?: string }
 *    appLanguageCode: string
 *    loading: boolean
 *    pageComplete: {[page: string]: boolean}
 *    availableInsuranceTypes: string[]
 *  }} DetailsState
 *
 * @returns {{
 *  details: DetailsState
 *  setDetails: (details: Partial<DetailsState> | (details: DetailsState) => Partial<DetailsState>) => void
 * }}
 */
export const useDetailContext = () => {
  return React.useContext(DetailContext);
};

/**
 * @returns {{ short: string, full: string } | undefined}
 */
export const useTeantTimezoneContext = () => {
  const details = useDetailContext();
  return details?.details?.appSettings?.timezone;
};

export const useSelectionsContext = () => {
  const { details, setDetails } = useDetailContext();

  const {
    serviceTypes,
    services,
    subServices,
    selectedServices,
    location,
    selectedLocationId,
    appointments,
  } = details;

  const isCounsellingSelected =
    selectedServices.length > 0 &&
    serviceTypes.byId[selectedServices[0]?.serviceTypeId]?.serviceGroup ===
      ServiceGroupMap.counselling;

  const serviceSelection = useMemo(
    () =>
      selectedServices.map(({ serviceTypeId, serviceId, subServiceId }) => ({
        serviceTypeId,
        serviceType: serviceTypes.byId[serviceTypeId],
        serviceId,
        service: services.byId[serviceId],
        subServiceId,
        subService: subServices.byId[subServiceId],
      })),
    [selectedServices, serviceTypes.byId, services.byId, subServices.byId],
  );

  const setSelectedServices = useCallback(
    /** @param {ServiceSelection[]} selectedServices */
    (selectedServices) => {
      setDetails({ selectedServices });
    },
    [setDetails],
  );

  const selectedSubServiceIds = useMemo(
    () => selectedServices.map((selection) => selection.subServiceId),
    [selectedServices],
  );
  const primarySubServiceId = selectedSubServiceIds[0];

  const selectedTimeBlock = useMemo(() => {
    const primaryAppointment = appointments[0];
    if (!primaryAppointment) {
      return null;
    }

    const appointment =
      primaryAppointment.firstAppointment ||
      primaryAppointment.secondAppointment;

    if (!appointment) {
      console.warn('missing primary appointment');
      return null;
    }

    const { date: appointmentDate, bookingBlock } = appointment;
    const { start_time: blockStartTime, end_time: blockEndTime } =
      bookingBlock ?? {};

    return {
      appointmentDate,
      blockStartTime,
      blockEndTime,
    };
  }, [appointments]);

  const selectedSubServices = useMemo(
    () =>
      selectedServices.map(
        (selection) => subServices.byId[selection.subServiceId],
      ),
    [selectedServices, subServices.byId],
  );

  return {
    selectedServices: serviceSelection,
    selectedClinic: location.byId[selectedLocationId],
    setSelectedServices,
    subServices,
    serviceTypes,
    selectedSubServiceIds,
    selectedSubServices,
    services,
    isCounsellingSelected,
    primarySubServiceId,
    primarySubService: subServices.byId[primarySubServiceId],
    selectedTimeBlock,
    appointments,
  };
};

export const usePatientInfo = () => {
  const { details, setDetails } = useDetailContext();

  const { patientInfo, patientRecordInfo } = details;

  const setPatientInfo = useCallback(
    (patientData) => {
      setDetails((state) => {
        const patientInfo = state.patientInfo.slice();
        patientInfo[details.currentPatientIndex] = {
          ...patientInfo[details.currentPatientIndex],
          ...patientData,
        };
        return {
          ...state,
          patientInfo: patientInfo,
        };
      });
    },
    [setDetails, details.currentPatientIndex],
  );

  return {
    patientInfo,
    patientRecordInfo,
    setPatientInfo,
    currentPatientIndex: details.currentPatientIndex,
    currentPatientInfo: patientInfo[details.currentPatientIndex],
    currentPatientRecordInfo: patientRecordInfo[details.currentPatientIndex],
  };
};

/**
 * @typedef {import('types').AppSettings} AppSettings
 * @typedef {{ languages: {code: string, languageId: string, name: string}[] }} AppSettingsAdditionalFields
 * @typedef {{ setAppSettings: (appSettings: Partial<AppSettings>) => void }} SetAppSettings
 *
 * @returns {AppSettings && AppSettingsAdditionalFields & SetAppSettings}
 */
export const useAppSettings = () => {
  const { details, setDetails } = useDetailContext();

  const { appSettings } = details;

  const isPaymentAvailableForOrganization = useMemo(() => {
    if (appSettings) {
      return appSettings.showPayment === 'TRUE';
    }
    return true;
  }, []);

  return {
    ...details.appSettings,
    setAppSettings: useCallback(
      (appSettings) => {
        setDetails((state) => ({
          appSettings: {
            ...state.appSettings,
            ...appSettings,
          },
        }));
      },
      [setDetails],
    ),
    isPaymentAvailableForOrganization,
  };
};

export const useGroupAppointmentContext = () => {
  const {
    details: {
      patientInfo,
      insuranceInfo,
      acceptanceInfo,
      noInsuranceInfo,
      phicureResponse,
      selfDeclaration,
      patientRecordInfo,
      isGroupAppointment,
      isIndividualReschedule,
      currentPatientIndex,
      eligibilityResponse,
      patientHealthAnswers,
      commonAppointmentDetails,
      appointments,
    },
    setIsGroupAppointment,
    setIndividualReschedule,
    setDetails,
  } = useDetailContext();

  const removePatientFromGroup = useCallback(
    (patientIndex) => {
      const newPatientInfo = [...patientInfo];
      const newSelfDeclaration = [...selfDeclaration];
      const newPatientHealthAnswers = [...patientHealthAnswers];
      const newInsuranceInfo = [...insuranceInfo];
      const newNoInsuranceInfo = [...noInsuranceInfo];
      const newCommonAppointmentDetails = [...commonAppointmentDetails];
      const newAcceptanceInfo = [...acceptanceInfo];
      const newEligibilityResponse = [...eligibilityResponse];
      const newPatientRecordInfo = [...patientRecordInfo];
      const newPhicureResponse = [...phicureResponse];

      // Deleting the current patient info
      newPatientInfo.splice(patientIndex, 1);
      newSelfDeclaration.splice(patientIndex, 1);
      newNoInsuranceInfo.splice(patientIndex, 1);
      newPatientHealthAnswers.splice(patientIndex, 1);
      newInsuranceInfo.splice(patientIndex, 1);
      newCommonAppointmentDetails.splice(patientIndex, 1);
      newAcceptanceInfo.splice(patientIndex, 1);
      newEligibilityResponse.splice(patientIndex, 1);
      newPatientRecordInfo.splice(patientIndex, 1);
      newPhicureResponse.splice(patientIndex, 1);

      setDetails({
        patientInfo: newPatientInfo,
        selfDeclaration: newSelfDeclaration,
        patientHealthAnswers: newPatientHealthAnswers,
        insuranceInfo: newInsuranceInfo,
        noInsuranceInfo: newNoInsuranceInfo,
        commonAppointmentDetails: newCommonAppointmentDetails,
        acceptanceInfo: newAcceptanceInfo,
        eligibilityResponse: newEligibilityResponse,
        patientRecordInfo: newPatientRecordInfo,
        phicureResponse: newPhicureResponse,
      });
    },
    [setDetails],
  );

  const setAllPatientInfo = (allPatientInfo) => {
    setDetails({ patientInfo: allPatientInfo });
  };

  const groupAppointmentId =
    appointments?.[0]?.firstAppointment?.groupAppointmentId;

  return {
    isGroupAppointment,
    setIsGroupAppointment,
    removePatientFromGroup,
    currentPatientIndex,
    groupAppointmentId,
    isIndividualReschedule,
    setIndividualReschedule,
    commonAppointmentDetails,
    allPatientInfo: patientInfo,
    currentPatientInfo: patientInfo[currentPatientIndex],
    setAllPatientInfo,
  };
};

export const useLocationContext = () => {
  const { details, setDetails } = useDetailContext();

  const setLocations = useCallback(
    (details) => {
      setDetails({ location: formatLocationDetailForState(details) });
    },
    [setDetails],
  );

  const setSelectedLocationId = useCallback(
    (locationId) => {
      setDetails({ selectedLocationId: locationId });
    },
    [setDetails],
  );

  const selectedLocationId = details.selectedLocationId;

  const locations = useMemo(
    () => details.location.allIds.map((id) => details.location.byId[id]),
    [details.location],
  );
  const selectedLocation = useMemo(
    () => details.location.byId[selectedLocationId],
    [details.location, selectedLocationId],
  );

  const getLocationById = useCallback(
    (id) => details.location.byId[id],
    [details.location],
  );

  return {
    locations,
    setLocations,
    selectedLocationId,
    setSelectedLocationId,
    selectedLocation,
    getLocationById,
  };
};

export const useAppointmentContext = () => {
  const {
    details: { appointments },
  } = useDetailContext();

  return { appointments };
};

export const useApplicationContext = () => {
  const {
    details: { firstPageLoaded },
  } = useDetailContext();

  const { selectedServices, selectedClinic } = useSelectionsContext();
  const { isPaymentAvailableForOrganization } = useAppSettings();

  const enabledPaymentOptions = useMemo(
    () => ({
      insurance:
        selectedServices.some(
          ({ subService }) => subService?.isInsuranceEnabled,
        ) && selectedClinic?.insurance !== false,
      noInsurance:
        selectedServices.every(
          ({ subService }) => subService?.isNoInsuranceEnabled,
        ) && selectedClinic?.noInsurance !== false,
      payAtTimeOfService:
        selectedServices.every(
          ({ subService }) => subService?.isPayAtTimeOfService,
        ) && selectedClinic?.payAtTimeOfService !== false,
    }),
    [selectedClinic, selectedServices],
  );

  return {
    isFirstPageLoaded: !!firstPageLoaded,
    isPaymentOptionEnabled:
      (enabledPaymentOptions.insurance ||
        enabledPaymentOptions.noInsurance ||
        enabledPaymentOptions.payAtTimeOfService) &&
      isPaymentAvailableForOrganization,
  };
};
