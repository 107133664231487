import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

export const dmeSlice = createSlice({
  name: 'dme',
  initialState,
  reducers: {
    dummyFunc: (state) => {
      return state;
    },
  },
});

export const { dummyFunc } = dmeSlice.actions;

export default dmeSlice.reducer;
