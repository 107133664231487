import React from 'react';
import { getString } from 'util/lang';
import { answerKey } from 'constants/confirm';
import AdditionalScreeningInput from './AdditionalScreeningInput';
import { CHOICE } from 'constants/screeningQuestions';
import { UiInfoCircle } from 'vyaguta-icons/ui';
import cls from 'classnames';

const Question = (props) => {
  const {
    id,
    label,
    question,
    answer,
    onChange,
    onAdditionalInfoChange,
    enableAdditionalQuestion,
    controlType,
    additionalInfoCriteria,
    additionalQuestionLabel,
    prescreening,
    additionalLink,
    additionalLinkLabel,
    dataqa,
    lastItem,
  } = props;

  const yes = prescreening ? 'true' : answerKey.yes;
  const no = prescreening ? 'false' : answerKey.no;

  return (
    <div className="col-12">
      <div className={cls('screening-question', !lastItem && 'mb-10x')}>
        <p className="form-question-text title--small d-flex">
          {question}{' '}
          {additionalLink && (
            <a
              href={additionalLink}
              target="_blank"
              rel="noreferrer"
              className="learn-more-link"
            >
              <UiInfoCircle />
              {additionalLinkLabel}
            </a>
          )}
        </p>
        <div className="solutions">
          <span
            data-qa={`health-screening-question-yes-${id}`}
            className="custom-radio check-yes mr-10x"
          >
            <input
              autoComplete="off"
              name={id}
              type="radio"
              id={`${id}Yes`}
              value={yes}
              className="custom-radio-input"
              onChange={(e) => onChange(e, id)}
              checked={answer?.value === yes}
              data-qa={`${dataqa}-yes`}
            />
            <label htmlFor={`${id}Yes`}>
              <span>{getString('yes')}</span>
            </label>
          </span>
          <span
            data-qa={`health-screening-question-no-${id}`}
            className="custom-radio check-no"
          >
            <input
              autoComplete="off"
              name={id}
              type="radio"
              id={`${id}No`}
              value={no}
              className="custom-radio-input"
              onChange={(e) => onChange(e, id)}
              checked={answer?.value === no}
              data-qa={`${dataqa}-no`}
            />
            <label htmlFor={`${id}No`}>
              <span>{getString('no')}</span>
            </label>
          </span>
        </div>
        {enableAdditionalQuestion === 'YES' &&
          additionalInfoCriteria === CHOICE[answer?.value] && (
            <AdditionalScreeningInput
              qid={id}
              type={controlType}
              questionLabel={additionalQuestionLabel}
              value={answer?.additionalInfo}
              handleChange={onAdditionalInfoChange}
            />
          )}
      </div>
    </div>
  );
};

export default Question;
