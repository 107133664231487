import { useCallback } from 'react';

import c from 'classnames';

import { serviceGroups, serviceGroupIcons } from './serviceGroup';

import styles from './additionalServiceTypeOption.module.scss';

/**
 *
 * @param {{
 *  id: string,
 *  title: string
 *  serviceGroup: string
 *  active?: boolean
 *  onChangeServiceType: (id: string) => void
 * }} props
 * @returns
 */
const AdditionalServiceTypeOption = (props) => {
  const { id, title, serviceGroup, active, onChangeServiceType } = props;
  const typeOfService = serviceGroups[serviceGroup]?.type || 'OTHERS';
  const icon = serviceGroupIcons[typeOfService]?.listIcon;

  const onClick = useCallback(() => {
    onChangeServiceType(id);
  }, [onChangeServiceType, id]);

  return (
    <div
      className={c(styles.additionalServiceTypeOption, active && styles.active)}
      onClick={onClick}
    >
      <div>{icon}</div>
      <div>{title}</div>
    </div>
  );
};

export default AdditionalServiceTypeOption;
