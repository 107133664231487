import config from '../config';
import * as http from '../util/http';

/**
 * Fetch patient screening questions
 *
 * @returns {array} data: List of patient screening question
 */
export async function fetchScreeningQuestions(
  selectedLanguageId,
  subserviceId,
) {
  const url = `${config.entity.screeningQuestions}/${subserviceId}/${selectedLanguageId}`;
  const { data } = await http.get(url);
  return data.data;
}
