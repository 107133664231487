import { useEffect, useState } from 'react';

import { useDetailContext } from 'context';
import { useNavigationHelper } from 'hooks/useNavigationHelper';

import GroupConfirmationCard from '../confirmation';
import { verifyPatientRecord } from 'services/patient';
import { DefaultPatientRecordInfo } from 'constants/app';
import styles from '../confirmation/confirmationCard.module.scss';
import ActivityIndicator from 'components/common/ActivityIndicator';

const getExistingRecordInfo = (patientInfo, patientRecordReponse) => {
  return {
    firstName: patientInfo.firstName,
    lastName: patientInfo.lastName,
    middleName: patientInfo.middleName,
    birthMonth: patientInfo.birthMonth,
    birthDay: patientInfo.birthDay,
    birthYear: patientInfo.birthYear,
    mobileNo: patientInfo.mobileNo,
    email: patientInfo.email,
    ...patientRecordReponse,
    isRecordVerified: true,
  };
};

const getNonExistingRecordInfo = () => {
  return {
    ...DefaultPatientRecordInfo,
    isExistingPatient: false,
    patientId: null,
    isRecordVerified: true,
  };
};

export const GroupConfirmationCardWrapper = () => {
  const nav = useNavigationHelper();
  const { details, setDetails } = useDetailContext();
  const { patientInfo, groupPendingPatientInfo, appSettings } = details;
  const [patientVerificationComplete, setPatientVerificationComplete] =
    useState(false);
  const [verificationResponse, setVerificationResponse] = useState({});
  const [existingPatientInfo, setExistingPatientInfo] = useState([]);

  useEffect(() => {
    startPatientVerification();
  }, []);

  // Network call to check the patientRecord status
  const startPatientVerification = async () => {
    let patientInfoPromises = [];
    let pendingPatientInfoPromises = [];

    patientInfo.forEach((individualPatient) => {
      patientInfoPromises.push(verifyPatientRecord(individualPatient));
    });
    groupPendingPatientInfo.forEach((individualPatient) => {
      pendingPatientInfoPromises.push(verifyPatientRecord(individualPatient));
    });

    const verificationResponse = await Promise.allSettled([
      ...patientInfoPromises,
      ...pendingPatientInfoPromises,
    ]);
    // Get Respective verification data for patient and pending patient
    const patientVerificationResponse = verificationResponse
      .slice(0, patientInfo.length)
      .map((p) => p.value);
    const pendingPatientVerificationResponse = verificationResponse
      .slice(patientInfo.length)
      .map((p) => p.value);

    setVerificationResponse({
      patientVerificationResponse,
      pendingPatientVerificationResponse,
    });
    setPatientVerificationComplete(true);

    // Set the mergable recordInfo for existing patient
    const updatedPatientRecordInfo = [...patientInfo].map((p, idx) => {
      if (patientVerificationResponse[idx].isExistingPatient) {
        return getExistingRecordInfo(p, patientVerificationResponse[idx]);
      } else {
        return getNonExistingRecordInfo();
      }
    });

    const updatedPendingPatientRecordInfo = [...groupPendingPatientInfo].map(
      (p, idx) => {
        if (pendingPatientVerificationResponse[idx].isExistingPatient) {
          return getExistingRecordInfo(
            p,
            pendingPatientVerificationResponse[idx],
          );
        } else {
          return getNonExistingRecordInfo();
        }
      },
    );

    setDetails({
      patientRecordInfo: updatedPatientRecordInfo,
      pendingPatientRecordInfo: updatedPendingPatientRecordInfo,
    });

    let allExistingPatientInfo = [
      ...updatedPatientRecordInfo,
      ...updatedPendingPatientRecordInfo,
    ].filter((p) => p.isExistingPatient === true);
    setExistingPatientInfo(allExistingPatientInfo);
  };

  const goBack = () => {
    nav.goBack();
  };

  const setPatientRecordInfo = (selectedPatient) => {
    // get state patientRecordInfo
    const globalPatientRecordInfo = patientInfo.map((p, idx) => {
      if (
        selectedPatient[
          `${p.firstName}${p.middleName ? `-${p.middleName}` : ''}-${p.lastName}`
        ]
      ) {
        return getExistingRecordInfo(
          p,
          verificationResponse.patientVerificationResponse[idx],
        );
      } else {
        return getNonExistingRecordInfo();
      }
    });

    // get state pendingPatientRecordInfo
    const globalPendingPatientRecordInfo = groupPendingPatientInfo.map(
      (p, idx) => {
        if (
          selectedPatient[
            `${p.firstName}${p.middleName ? `-${p.middleName}` : ''}-${p.lastName}`
          ]
        ) {
          return getExistingRecordInfo(
            p,
            verificationResponse.pendingPatientVerificationResponse[idx],
          );
        } else {
          return getNonExistingRecordInfo();
        }
      },
    );
    // set the local state to app state

    setDetails({
      patientRecordInfo: globalPatientRecordInfo,
      pendingPatientRecordInfo: globalPendingPatientRecordInfo,
    });
  };

  if (!patientVerificationComplete) {
    return (
      <div className={styles.loader}>
        <ActivityIndicator />
      </div>
    );
  }

  return (
    <>
      {patientVerificationComplete && (
        <GroupConfirmationCard
          onBackHandler={goBack}
          organizationName={appSettings.organizationName}
          patientInfo={[...patientInfo, ...groupPendingPatientInfo]}
          existingPatientInfo={existingPatientInfo}
          setPatientRecordInfo={setPatientRecordInfo}
        />
      )}
    </>
  );
};
