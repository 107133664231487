import { FiChevronLeft, FiChevronRight } from 'vyaguta-icons/fi';

import { useAppSettings } from 'context';
import Button from 'components/common/Button';
import SelectBox from 'components/common/SelectBox';
import DateInput from 'components/common/DateInput';
import FormInput from 'components/common/FormInput';
import { componentKeys } from '../../../container/DmeDocument';

import { getString } from 'util/lang';
import { customPick } from 'util/objectUtils';
import { getDisplayDate } from 'util/DateAndTime';
import { validateFormData } from 'util/formValidator';
import { TermsAndConditionSchema } from 'validator/productOrder';
import { BeneficiaryRelation } from '../../../../../../constants/productOrder';

export const TermsAndConditions = ({
  goNext,
  goBack,
  previousLabel,
  nextLabel,
  isLastDocument,
  handleSubmit,
  organizationName,
  organizationContact,
  signedDocumentDetail,
  onChange,
  errors,
  setErrors,
}) => {
  const { logoUrl } = useAppSettings();

  const handleSubmission = () => {
    const { errors, isValid } = validateFormData(
      customPick(signedDocumentDetail, [
        'tncFullName',
        'tncAddress',
        'tncDate',
        'tncRelation',
        'acceptTnc',
        'tncReasonUnableToSign',
      ]),
      TermsAndConditionSchema,
    );

    if (!isValid) {
      setErrors(errors);
      return;
    }
    if (isLastDocument) {
      handleSubmit();
    } else {
      goNext();
    }
    setErrors({});
  };

  return (
    <div className="wrapper">
      <div className="header">
        <div className="logo">
          <img src={logoUrl} alt="pharmacy_logo" />
        </div>
      </div>
      <div className="container-large">
        <h3 className="heading">
          {getString('dme.documentation.termsAndConditions.title')}
        </h3>

        <ul className="list list--numbered mb-6x">
          {getString('dme.documentation.termsAndConditions.terms')?.map(
            (item, index) => {
              const title = getString(
                `dme.documentation.termsAndConditions.terms.${index}.title`,
              );
              const content = getString(
                `dme.documentation.termsAndConditions.terms.${index}.content`,
                {
                  pharmacy: organizationName,
                  contact: organizationContact,
                },
              );
              const meta =
                title === 'Warranty'
                  ? getString(
                      `dme.documentation.termsAndConditions.terms.${index}.meta`,
                    )
                  : '';

              return (
                <li key={index}>
                  <p className="title mb-1x">{title}</p>
                  <p className="desc mb-0x">
                    {content} <b>{meta}</b>
                  </p>
                </li>
              );
            },
          )}
        </ul>

        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            className="cursor-pointer mr-3x"
            id="acceptTnc"
            checked={signedDocumentDetail.acceptTnc}
            onClick={(e) => onChange('acceptTnc', e.target.checked)}
          />
          <label
            htmlFor="acceptTnc"
            className="color-text-dark mb-0x mt-1x desc cursor-pointer"
          >
            {getString(
              'dme.documentation.termsAndConditions.agreeTermsAndConditions',
            )}
          </label>
        </div>
        {errors.acceptTnc && (
          <div className="error-message pl-6x">{errors.acceptTnc}</div>
        )}
        {signedDocumentDetail.acceptTnc && (
          <div className="patient-form-group border-round mt-4x">
            <div className="row align-items-center border-bottom border-right">
              <div className="col-8">
                <div className="py-4x pl-6x pr-0x border-right m-0x">
                  <div className="row ">
                    <div className="col-11">
                      <h3 className="title title--bold mb-2x">
                        {getString(
                          'dme.documentation.termsAndConditions.beneficiary.signature',
                        )}
                      </h3>
                      <FormInput
                        id="tncFullName"
                        labelClassName="label-placeholder label-placeholder--required"
                        label={getString(
                          'dme.documentation.abnForm.fields.patientFullNameLabel',
                        )}
                        solo
                        required={true}
                        value={signedDocumentDetail.tncFullName}
                        hasError={!!errors['tncFullName']}
                        onChange={onChange}
                      />
                      <p className="desc desc--xs color-grey-text-light mt-1x mb-0x">
                        {getString('dme.documentation.abnForm.signatureNote')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 px-6x">
                <DateInput
                  id="tncDate"
                  labelClassName="label-placeholder label-placeholder--required"
                  label={getString(
                    'dme.documentation.termsAndConditions.beneficiary.date',
                  )}
                  value={
                    signedDocumentDetail.tncDate
                      ? new Date(signedDocumentDetail.tncDate)
                      : ''
                  }
                  handleDateChange={(id, date) =>
                    onChange(id, getDisplayDate(date))
                  }
                  hasError={!!errors['tncDate']}
                  required={true}
                  solo
                />
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <FormInput
                  id="tncAddress"
                  labelClassName="label-placeholder"
                  label={getString(
                    'dme.documentation.termsAndConditions.beneficiary.address',
                  )}
                  value={signedDocumentDetail.tncAddress}
                  onChange={onChange}
                />
              </div>
              <div className="col-4">
                <SelectBox
                  id="tncRelation"
                  labelClassName="label-placeholder"
                  label={getString(
                    'dme.documentation.termsAndConditions.beneficiary.relationship',
                  )}
                  options={BeneficiaryRelation}
                  value={signedDocumentDetail.tncRelation}
                  onChange={(event) =>
                    onChange('tncRelation', parseInt(event.target.value))
                  }
                />
              </div>
              <div className="col-12">
                <FormInput
                  id="tncReasonUnableToSign"
                  labelClassName="label-placeholder"
                  label={getString(
                    'dme.documentation.termsAndConditions.beneficiary.reason',
                  )}
                  value={signedDocumentDetail.tncReasonUnableToSign}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        )}
        <div className="pagination">
          <div className="text-left flex-grow">
            {previousLabel && (
              <>
                <p className="d-flex align-items-center" onClick={goBack}>
                  <FiChevronLeft size={12} color="#757575" />
                  <span className="color-grey-dark cursor-pointer ml-1x">
                    {getString('dme.documentation.pagination.previous')}
                  </span>
                </p>
                <Button
                  className="btn btn--text link-pagination"
                  label={
                    previousLabel === componentKeys.esignature
                      ? getString(
                          'dme.documentation.pagination.electronicSignature',
                        )
                      : previousLabel
                  }
                  onClick={goBack}
                  isEnabled
                />
              </>
            )}
          </div>
          <div className="text-right flex-grow">
            {isLastDocument ? (
              <Button
                label={'Submit'}
                className="btn btn-primary btn--fixedWidth"
                onClick={handleSubmission}
                isEnabled
              />
            ) : (
              <>
                <p
                  className="d-flex justify-content-end align-items-center"
                  onClick={handleSubmission}
                >
                  <span className="color-grey-dark cursor-pointer mr-1x">
                    {getString('dme.documentation.pagination.next')}
                  </span>
                  <FiChevronRight size={12} color="#757575" />
                </p>
                <Button
                  className="btn btn--text link-pagination"
                  label={nextLabel}
                  onClick={handleSubmission}
                  isEnabled
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
