import React from 'react';
import {} from 'lodash';
import { getString } from 'util/lang';

import Button from 'components/common/Button';
import styles from './NotEligibleComponent.module.scss';
import MessageListBox from 'components/COVID/common/MessageListBox';

/**
 * @param {string[]} serviceNames
 */
const joinServiceNames = (serviceNames) => {
  // TODO: Intl.ListFormat

  if (serviceNames.length === 1) {
    return serviceNames[0];
  }

  return serviceNames.flatMap((name, idx) => {
    if (idx < serviceNames.length - 3) {
      return [name, ', '];
    }
    if (idx === serviceNames.length - 2) {
      return [name, ` ${getString('and')} `];
    }
    return [name];
  });
};

/**
 * @param {{
 *  serviceNames: string[]
 *  goToHomePage: () => void
 *  contactPhone: string
 *  contactEmail: string
 * }} props
 */
const NotEligibleComponent = (props) => {
  const { serviceNames, goToHomePage, contactEmail, contactPhone } = props;
  const serviceNamesBolded = serviceNames.map((name) => <b>{name}</b>);
  const messages = [
    { title: 'Phone number', message: contactPhone },
    { title: 'Email address', message: contactEmail },
  ];
  return (
    <div className={styles.notEligible}>
      <h2 className="title--normal">
        {getString('notQualifiedForSubserviceMessage1')}
      </h2>
      <div>
        {getString('ageLimitPresent')}{' '}
        <span>{joinServiceNames(serviceNamesBolded)}</span>.
      </div>
      <div className={styles.clinicContactInfo}>
        {getString('clinicContactInformation')}:
      </div>
      <MessageListBox messages={messages} className={styles.messageBox} />
      <Button
        classNames={{ button: styles.button }}
        label={getString('bookDifferentService')}
        outline
        isEnabled
        onClick={goToHomePage}
        dataqa="goto-homepage"
      />
    </div>
  );
};

export default NotEligibleComponent;
