import { getString } from 'util/lang';

export const testResult = {
  POSITIVE: 153940000,
  NEGATIVE: 153940001,
  INCONCLUSIVE: 276880000,
};

export const TestKitIdTypeOption = [
  {
    label: () => getString('emergencyUseAuthorization'),
    value: 153940000,
  },
  {
    label: () => getString('manufacturingName'),
    value: 153940001,
  },
  {
    label: () => getString('deviceIdentifier'),
    value: 153940002,
  },
  {
    label: () => getString('manualTestKit'),
    value: 153940003,
  },
];

export const TestResultOption = [
  {
    label: () => getString('positive'),
    value: testResult.POSITIVE,
  },
  {
    label: () => getString('negative'),
    value: testResult.NEGATIVE,
  },
  {
    label: () => getString('inconclusive'),
    value: testResult.INCONCLUSIVE,
  },
];
