import * as dateUtil from '../util/DateAndTime';
import DoseNumber from 'constants/covid/doseNumber';
import { removeAllEmptyFieldValuesForObject } from '../util/objectUtils';

const ServiceGroups = {
  COVID19_VACCINE: 249790001,
  POINT_OF_CARE_TESTING: 249790003,
  COMMON_DISEASE_VACCINE: 276880002,
  TRAVEL_VACCINE: 276880003,
  COUNSELLING: 153940001,
};

const InsuranceActiveCoverage = {
  YES: 153940000,
  NO: 153940001,
  DONT_KNOW: 153940002,
};

export const mapInsuranceCoverageBooleanToValue = (value) => {
  if (value === true) return InsuranceActiveCoverage.YES;
  else if (value === false) return InsuranceActiveCoverage.NO;
  else return InsuranceActiveCoverage.DONT_KNOW;
};

function getBlockDetails(appointments, primaryServiceCheckOnly) {
  if (!appointments || !appointments.length) {
    return {};
  }

  const flatAppointments = appointments
    .map(({ first, second }) => [first, second])
    .flat()
    .filter((i) => i);

  if (!flatAppointments.length) {
    console.warn('no appointments');
    return {};
  }

  const { end_time, ...bookingBlockInfo } = flatAppointments[0].bookingBlock;

  return {
    serviceCodes: flatAppointments.map(({ serviceCode }) => serviceCode),
    venueCode: flatAppointments[0].venueCode,
    date: flatAppointments[0].date,
    bookingBlock: bookingBlockInfo,
    primaryServiceCheckOnly,
  };
}

export function getCreateOrUpdateAppointmentPayload({
  appointments,
  patientInfo: allPatientInfo,
  eligibilityResponse,
  screeningAnswers,
  organizationId,
  captchaResponseKey,
  selfDeclaration,
  selections,
  selectedLanguageId,
  requiresRegistration,
  registrationCode,
  patientRecordInfo,
  clinic,
  insuranceCoverageList,
  localizedWebContents,
  primaryServiceCheckOnly,
  insuranceData,
  noBlockDetails = false,
  getPayloadForUpdate = false,
}) {
  const recordInfo = {
    isExistingPatient: patientRecordInfo.isExistingPatient,
    patientId: patientRecordInfo.patientId,
  };

  const patientInfo = {
    ...allPatientInfo,
    ...removeAllEmptyFieldValuesForObject({
      mobileNo: allPatientInfo.mobileNo?.replaceAll('-', ''),
      homePhoneNo: allPatientInfo.homePhoneNo?.replaceAll('-', ''),
      physicianPhoneNumber: allPatientInfo.physicianPhoneNumber?.replaceAll(
        '-',
        '',
      ),
    }),
  };

  const [
    appointmentToDynamicsFormatConversion,
    patientInfoToDynamicsFormatConversion,
  ] = getPayloadForUpdate
    ? [
        convertAppointmentObjectToDynamicsFormatForUpdate,
        convertPatientInfoObjectToDynamicsFormatForUpdate,
      ]
    : [
        convertAppointmentObjectToDynamicsFormat,
        convertPatientInfoObjectToDynamicsFormat,
      ];

  // separator @@
  const termsAndConditionContents = localizedWebContents.map((content) => {
    return `
      ${content.termsAndCondition}
  
      @@
  
      ${content.termsAndConditionsAcceptance.map((item) => item.label).join('\n')}
      `;
  });

  const requestAppointments = appointments.map(
    ({ first: firstAppointment, second: secondAppointment }, idx) =>
      firstAppointment
        ? {
            ...appointmentToDynamicsFormatConversion({
              patientInfo,
              subService: selections[idx].subService,
              appointment: firstAppointment,
              organizationId,
              appointmentStatus:
                selections[idx].subService.firstAppointmentStatus,
              languageId: selectedLanguageId,
              clinic,
              serviceType: selections[idx].serviceType,
              typeOfServiceCodeId: selections[idx].service.typeOfServiceCodeId,
              iisConsent: patientInfo.iisConsent,
              insuranceCoverage: insuranceCoverageList[idx],
              patientConsentData: termsAndConditionContents[0],
            }),
            // Backend explicitly converts the entity of insuranceData to dynamics format
            insurance: insuranceData[idx],
          }
        : null,
  );

  const blockDetail = noBlockDetails
    ? undefined
    : getBlockDetails(appointments, primaryServiceCheckOnly);

  const patientInfoObj = patientInfoToDynamicsFormatConversion(
    patientInfo,
    requiresRegistration,
    registrationCode,
  );

  return {
    appointments: requestAppointments,
    patientInfo: patientInfoObj,
    captchaResponseKey,
    screeningAnswers,
    selfDeclaration,
    eligibilityResponse,
    recordInfo,
    blockDetail,
  };
}

/**
 * Convert appointment object to the standard form that dynamics accepts.
 *
 */
export function convertAppointmentObjectToDynamicsFormat({
  patientInfo,
  subService,
  appointment,
  organizationId,
  appointmentStatus,
  languageId,
  clinic,
  serviceType,
  typeOfServiceCodeId,
  iisConsent,
  patientConsentData,
  insuranceCoverage = null,
}) {
  const {
    date,
    time,
    memberID,
    memberIDPrefix,
    memberIDSuffix,
    subscriberRelationship,
    cardHolderFirstName,
    cardHolderLastName,
    cardHolderMiddleName,
    groupNumber,
    compressedSignature,
    paymentType,
    insuranceType,
    company,
    otherInsuranceCompany,
    doseNumber,
    noInsuranceAttempted,
    noInsuranceIdType,
    noInsuranceIdNumber,
    noInsuranceState,
    consentedBy,
    insurancePlanType,
    rxBin,
    rxPCN,
    rxGroup,
    medicareID,
    medicareIssuer,
  } = appointment;

  const dobMMDDYYYY =
    patientInfo.birthYear && patientInfo.birthMonth && patientInfo.birthDay
      ? `${patientInfo.birthMonth}-${patientInfo.birthDay}-${patientInfo.birthYear}`
      : null;

  const isGuardianRequired = dateUtil.isUnderNineteen(dobMMDDYYYY);

  // const { selectedClinic, selectedService } = serviceSelection;

  return {
    smvs_associatelclinicid: clinic.id,
    smvs_associated_insurance_organization: company,
    smvs_health_insurance_company: otherInsuranceCompany,
    smvs_member_id: memberID,
    smvs_member_id_prefix: memberIDPrefix,
    smvs_member_id_suffix: memberIDSuffix,
    smvs_relationship_subscriber: subscriberRelationship,
    smvs_cardholder_first_name: cardHolderFirstName,
    smvs_cardholder_last_name: cardHolderLastName,
    smvs_cardholder_middle_name: cardHolderMiddleName,
    smvs_group_number: groupNumber,
    smvs_active_insurance_coverage:
      mapInsuranceCoverageBooleanToValue(insuranceCoverage),
    smvs_clientsignature: compressedSignature,
    smvs_organizationid: organizationId,
    smvs_payment_type: paymentType,
    smvs_insurance_type: insuranceType,
    smvs_appointment_dose_number: doseNumber || DoseNumber.FirstDose,
    smvs_appointmentdatetime: dateUtil.convertDateAndTimeToUTC(date, time),
    smvs_idtype: noInsuranceIdType,
    smvs_idstate: noInsuranceState,
    smvs_identificationnumber: noInsuranceIdNumber,
    smvs_attemptedtogetdocumentation: noInsuranceAttempted,
    msemr_appointmentstatus: appointmentStatus,
    smvs_associatedserviceid: subService.id,
    smvs_associated_language_code: languageId,
    smvs_send_to_iis: iisConsent,
    smvs_appointment_test_type: ServiceGroups[serviceType.serviceGroup],
    smvs_type_of_service_codeid: typeOfServiceCodeId,
    smvs_insurance_plan_type: insurancePlanType,
    smvs_rx_bin: rxBin,
    smvs_rx_pcn: rxPCN,
    smvs_rx_group: rxGroup,
    smvs_medicare_id: medicareID,
    smvs_medicare_issuer: medicareIssuer,
    smvs_guardian_first_name: isGuardianRequired
      ? patientInfo.guardianFirstName
      : '',
    smvs_guardian_last_name: isGuardianRequired
      ? patientInfo.guardianLastName
      : '',
    smvs_guardian_middle_name: isGuardianRequired
      ? patientInfo.guardianMiddleName
      : '',

    smvs_primary_care_physician_name: patientInfo.physicianName,
    smvs_primary_care_physician_phone_number: patientInfo.physicianPhoneNumber,
    smvs_consented_by: consentedBy,
    smvs_patient_consent_data: patientConsentData,
    smvs_notification_email_enabled: patientInfo.emailNotificationConsent,
    smvs_notification_sms_enabled: patientInfo.smsNotificationConsent,
  };
}

function convertAppointmentObjectToDynamicsFormatForUpdate(params) {
  const updateInformation = removeAllEmptyFieldValuesForObject({
    appointmentId: params.appointment?.activityid,
  });
  return {
    ...convertAppointmentObjectToDynamicsFormat(params),
    ...updateInformation,
  };
}

/**
 * @param {object} patientInfo: Patient object
 * @returns {object} patient object but in standarded for accepted by dynamics.
 */
export function convertPatientInfoObjectToDynamicsFormat(
  patientInfo,
  requiresRegistration,
  registrationCode,
) {
  const birthDate =
    patientInfo.birthYear && patientInfo.birthMonth && patientInfo.birthDay
      ? `${patientInfo.birthYear}-${patientInfo.birthMonth}-${patientInfo.birthDay}`
      : null;

  return {
    birthdate: birthDate,
    gendercode: patientInfo.gender,
    lastname: patientInfo.lastName,
    firstname: patientInfo.firstName,
    middlename: patientInfo.middleName,
    emailaddress1: patientInfo.email,
    address1_city: patientInfo.city,
    mobilephone: patientInfo.mobileNo,
    telephone2: patientInfo.homePhoneNo,
    address1_line1: patientInfo.homeAddress,
    address1_postalcode: patientInfo.zipCode,
    address1_stateorprovince: patientInfo.state,
    address1_county: patientInfo.county,
    smvs_apartment_number: patientInfo.aptUnit,
    smvs_address_filled_type: patientInfo.addressFilledType,
    smvs_race: patientInfo.race,
    smvs_ethnicity: patientInfo.ethnicity,
    smvs_receivenotification: patientInfo.emailNotificationConsent,
    smvs_smsnotification: patientInfo.smsNotificationConsent,
    smvs_marketing_notification:
      patientInfo.marketingNotificationConsent &&
      patientInfo.emailNotificationConsent,
    smvs_marketing_notification_sms:
      patientInfo.marketingNotificationConsent &&
      patientInfo.smsNotificationConsent,
    smvs_language: patientInfo.primaryLanguage,
    smvs_referredby: patientInfo.referredBy,
    smvs_associated_singe_use_code: requiresRegistration
      ? registrationCode
      : '',
  };
}

function convertPatientInfoObjectToDynamicsFormatForUpdate(params) {
  const updateInformation = removeAllEmptyFieldValuesForObject({
    patientId: params.patientInfo?.patientId,
  });
  return {
    ...convertPatientInfoObjectToDynamicsFormat(params),
    ...updateInformation,
  };
}
