import * as routes from 'constants/routes/covidRoutes';

import { trainingManual, iconYoutube, iconHelp } from 'assets/images';

export const helpfulResourceList = [
  // {
  //   img_src: trainingManual,
  //   title: 'Training Manual',
  //   alt_text: 'training_manual',
  //   linkName: 'trainingManual',
  // },
  // {
  //   img_src: iconYoutube,
  //   title: 'Demo Video',
  //   alt_text: 'icon_youtube',
  //   linkName: 'demoVideo',
  //   linkTarget: '_blank',
  // },
  {
    img_src: iconHelp,
    title: 'Help',
    alt_text: 'icon_help',
    className: 'help-btn',
    link: routes.HELP_CENTER,
  },
];
