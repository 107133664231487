import React from 'react';
import { getString } from 'util/lang';
import Button from 'components/common/Button';
import Modal from 'components/COVID/common/Modal';

const VerifyPatientRecord = (props) => {
  const { saveAndMergePatientData } = props;

  const allowMerge = () => {
    saveAndMergePatientData(true);
  };

  const disallowMerge = () => {
    saveAndMergePatientData(false);
  };

  const headerContent = (
    <h3 className="color-primary-base mb-0x">
      {getString('isOldPatientConfirmation')}
    </h3>
  );
  return (
    <Modal
      close={null}
      containerClassName="confirmation-modal"
      headerContent={headerContent}
      isClosable={false}
    >
      <div className="modal__content">
        <br />
        <br />
      </div>
      <div className="modal__footer text-right border-top d-flex justify-content-between flex-wrap">
        <div>
          <Button
            dataqa="disallow-merge-for-old-patient"
            label={'No'}
            className="btn btn--large btn-primary--outline"
            isEnabled
            onClick={disallowMerge}
          />
        </div>
        <Button
          dataqa="allow-merge-for-old-patient"
          label={'Yes'}
          className="btn btn--large btn-primary"
          isEnabled
          onClick={allowMerge}
        />
      </div>
    </Modal>
  );
};

export default VerifyPatientRecord;
