import React from 'react';
import * as Regex from 'util/regex';
import regexPattern from 'constants/regexPattern';

import FormInput from '../../../../common/FormInput';
import SelectBox from '../../../../common/SelectBox';
import FormErrors from '../../../../common/FormErrors';

import DateTimeUtils from 'util/DateAndTime';

import {
  GenderOptions,
  MonthList,
  DayList,
  ShowAsRequiredFields,
  RaceOptions,
  EthnicityOptions,
  YearList,
} from '../../../../../constants/patientInfo';
import { isLeapYear } from '../../../../../util/DateAndTime';
import { getString } from '../../../../../util/lang';
import { useState } from 'react';
import { useDetailContext, useSelectionsContext } from 'context';
import { dashMobileFormat, limitChars } from 'util/TextFormat';
import PatientGuardianInfo from '../../PatientInformation/presentation/PatientGuardianInfo';
import { replaceArrayElementByIndex } from 'util/array';
import { useEffect } from 'react';
import styles from './PatientInformation.module.scss';
import cls from 'classnames';
import { contactTypes } from '../container/PatientEligibilityWrapper';

const ContactType = ({
  onChange,
  value,
  errors,
  label,
  contactType,
  setContactType,
  setPatientInfoErrors,
}) => {
  const { setDetails, details } = useDetailContext();
  const { patientInfo } = details;

  const onContactTypeChange = (e) => {
    const updatedPatientInfo = replaceArrayElementByIndex(
      patientInfo,
      details.currentPatientIndex,
      {
        ...patientInfo[details.currentPatientIndex],
        mobileNo: '',
        email: '',
      },
    );

    setDetails({
      patientInfo: updatedPatientInfo,
    });

    setPatientInfoErrors({
      ...errors,
      mobileNo: null,
      email: null,
    });

    setContactType(e.target.value);
  };

  return (
    <div>
      <div>
        <input
          autoComplete="off"
          name="email-checkbox"
          type="radio"
          id={'emailCheckbox'}
          value={contactTypes.email}
          className={cls(styles.radioButton, 'custom-radio-input', 'mr-4x')}
          checked={contactType === contactTypes.email}
          onChange={onContactTypeChange}
        />
        <label htmlFor={'emailCheckbox'}>{label}</label>
        {contactType === contactTypes.email && (
          <div className="patient-form-group border-rounded mt-2x">
            <div className="fs-mask">
              <FormInput
                label={getString('emailAddress')}
                id="email"
                type="email"
                inputAutoComplete={'off'}
                disablePaste={true}
                onChange={onChange}
                value={value.email}
                hasError={!!errors['email']}
                required={true}
                data-qa="email-info-page"
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-4x">
        <input
          autoComplete="off"
          name="phone-checkbox"
          type="radio"
          id={'mobileNoCheckBox'}
          value={contactTypes.mobileNo}
          className={cls(styles.radioButton, 'custom-radio-input', 'mr-4x')}
          checked={contactType === contactTypes.mobileNo}
          onChange={onContactTypeChange}
        />
        <label htmlFor={'mobileNoCheckBox'}>Phone number</label>

        {contactType === contactTypes.mobileNo && (
          <div className="patient-form-group border-rounded mt-2x">
            <div className="fs-mask">
              <FormInput
                label={getString('phoneNumber')}
                id="mobileNo"
                type="tel"
                onChange={onChange}
                value={value.mobileNo}
                hasError={!!errors['mobileNo']}
                aria-label="mobile number"
                data-qa="mobile-info-page"
                required={true}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-4x">
        <input
          autoComplete="off"
          name="email-and-phone-checkbox"
          type="radio"
          id={'emailAndPhoneCheckbox'}
          value={contactTypes.emailAndMobileNo}
          className={cls(styles.radioButton, 'custom-radio-input', 'mr-4x')}
          checked={contactType === contactTypes.emailAndMobileNo}
          onChange={onContactTypeChange}
        />
        <label htmlFor={'emailAndPhoneCheckbox'}>
          Phone number and email address
        </label>

        {contactType === contactTypes.emailAndMobileNo && (
          <div className="patient-form-group border-rounded mt-2x">
            <div className="row">
              <div className="col-6-md fs-mask">
                <FormInput
                  label={getString('emailAddress')}
                  id="email"
                  type="email"
                  inputAutoComplete={'off'}
                  disablePaste={true}
                  onChange={onChange}
                  value={value.email}
                  hasError={!!errors['email']}
                  required={true}
                  data-qa="email-info-page"
                />
              </div>
              <div className="col-6-md fs-mask">
                <FormInput
                  label={getString('phoneNumber')}
                  id="mobileNo"
                  type="tel"
                  onChange={onChange}
                  value={value.mobileNo}
                  hasError={!!errors['mobileNo']}
                  aria-label="mobile number"
                  data-qa="mobile-info-page"
                  required={true}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PatientInformation = (props) => {
  const { setDetails, details } = useDetailContext();
  const { selectedServices } = useSelectionsContext();
  const { patientInfo } = details;
  const currentPatient = patientInfo[details.currentPatientIndex] ?? {};

  const {
    patientInfoErrors,
    guardianInfoErrors,
    setPatientInfoErrors,
    setGuardianInfoErrors,
    contactType,
    setContactType,
    isWaitlist = false,
  } = props;

  const [isUnderNineteen, setIsUnderNineteen] = useState(false);
  const [isUnderMinimumAge, setIsUnderMinimumAge] = useState(false);

  const getDaysInMonth = (birthMonth, birthYear) => {
    const TwentyNineDayMonths = ['2'];
    const ThirtyDayMonths = ['4', '6', '9', '11'];
    if (TwentyNineDayMonths.includes(birthMonth)) {
      if (!isLeapYear(birthYear)) {
        return DayList.slice(0, 28);
      }
      return DayList.slice(0, 29);
    } else if (ThirtyDayMonths.includes(birthMonth)) {
      return DayList.slice(0, 30);
    }
    return DayList;
  };

  const formatFormInput = (key, value) => {
    switch (key) {
      case 'mobileNo':
        if (!Regex.IsMobileNumber(value)) return;
        value = limitChars(value, 12);
        value = dashMobileFormat(value);
        break;
      case 'birthYear':
        if (isNaN(value)) return;
        break;
      case 'zipCode':
        if (!Regex.isNumber(value)) return;
        value = limitChars(value, 5);
        break;
      case 'firstName':
        value = value.trimStart();
        value = value.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      case 'middleName':
        value = value.trimStart();
        value = value.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      case 'lastName':
        const startTrimed = value.trimStart();
        value = startTrimed.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      default:
        break;
    }

    return value;
  };

  const onChangeInput = (key, value, event) => {
    if (key === 'mobileNo' && value.length < 12) {
      const cursor = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = cursor;
        element.selectionEnd = cursor;
      });
      value = value.replaceAll('-', '');
    }

    if (key === 'mobileNo' && !Regex.isNumber(value)) {
      return;
    }

    if (key == null || value == null) return;

    const guardianNameKeys = [
      'guardianFirstName',
      'guardianLastName',
      'guardianMiddleName',
    ];

    const nameKeys = ['firstName', 'lastName', 'middleName'];

    if (
      (nameKeys.includes(key) || guardianNameKeys.includes(key)) &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    )
      return;

    value = formatFormInput(key, value);

    if (currentPatient[key] === value) return;

    const newChange = { [key]: value };

    if (guardianNameKeys.includes(key)) {
      setGuardianInfoErrors({ ...guardianInfoErrors, [key]: null });
    } else {
      setPatientInfoErrors({ ...patientInfoErrors, [key]: null });
    }

    // updating the patient Information for current patient
    const updatedPatientInfo = replaceArrayElementByIndex(
      patientInfo,
      details.currentPatientIndex,
      {
        ...patientInfo[details.currentPatientIndex],
        ...newChange,
      },
    );

    setDetails({
      patientInfo: updatedPatientInfo,
    });
  };

  useEffect(() => {
    const { birthYear, birthDay, birthMonth } = currentPatient;

    if (birthDay && birthMonth && birthYear) {
      const birthDate = `${birthMonth}-${birthDay}-${birthYear}`;
      const isUnderMinimumAge = selectedServices.some(({ subService }) =>
        DateTimeUtils.isUnderMinimumAge(
          birthDate,
          subService.minAgeRequirement,
        ),
      );
      const isUnderNineteen = DateTimeUtils.isUnderNineteen(birthDate);

      setIsUnderMinimumAge(isUnderMinimumAge);
      setIsUnderNineteen(isUnderNineteen);
    }
  }, [
    currentPatient.birthDay,
    currentPatient.birthMonth,
    currentPatient.birthYear,
  ]);

  return (
    <section>
      <p>{getString('patientInformationSubText')}</p>
      <div className="patient-form-group border-rounded mb-5x">
        <div className="row">
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('firstName')}
              id="firstName"
              onChange={onChangeInput}
              value={currentPatient.firstName}
              hasError={!!patientInfoErrors['firstName']}
              required={ShowAsRequiredFields['firstName']}
              data-qa="first-name"
            />
          </div>
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('middleName')}
              id="middleName"
              onChange={onChangeInput}
              value={currentPatient.middleName}
              hasError={!!patientInfoErrors['middleName']}
              required={ShowAsRequiredFields['middleName']}
              data-qa="middle-name"
            />
          </div>
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('lastName')}
              id="lastName"
              onChange={onChangeInput}
              value={currentPatient.lastName}
              hasError={!!patientInfoErrors['lastName']}
              required={ShowAsRequiredFields['lastName']}
              data-qa="last-name"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              label={getString('year')}
              id="birthYear"
              defaultValue={getString('birthYear')}
              onChange={(e) => onChangeInput('birthYear', e.target.value)}
              value={currentPatient.birthYear}
              hasError={!!patientInfoErrors['birthYear']}
              required={ShowAsRequiredFields['birthYear']}
              options={YearList()}
              dataqa="birth-year-info-page"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              id="birthMonth"
              onChange={(e) => onChangeInput('birthMonth', e.target.value)}
              defaultValue={getString('birthMonth')}
              label={getString('birthMonth')}
              value={currentPatient.birthMonth}
              options={MonthList}
              hasError={!!patientInfoErrors['birthMonth']}
              required={ShowAsRequiredFields['birthMonth']}
              dataqa="birth-month-info-page"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              id="birthDay"
              onChange={(e) => onChangeInput('birthDay', e.target.value)}
              defaultValue={getString('day')}
              label={getString('day')}
              value={currentPatient.birthDay}
              options={getDaysInMonth(
                currentPatient.birthMonth,
                currentPatient.birthYear,
              )}
              hasError={!!patientInfoErrors['birthDay']}
              required={ShowAsRequiredFields['birthDay']}
              dataqa="birth-day-info-page"
            />
          </div>

          <div className="col-3-md fs-mask">
            <SelectBox
              id="ethnicity"
              onChange={(e) => onChangeInput('ethnicity', e.target.value)}
              label={getString('ethnicity')}
              defaultValue=""
              value={currentPatient.ethnicity}
              options={EthnicityOptions}
              hasError={!!patientInfoErrors['ethnicity']}
              required={ShowAsRequiredFields['ethnicity']}
              dataqa="ethnicity-info-page"
            />
          </div>

          <div className="col-3-md fs-mask">
            <SelectBox
              id="race"
              onChange={(e) => onChangeInput('race', e.target.value)}
              label={getString('race')}
              defaultValue=""
              value={currentPatient.race}
              options={RaceOptions}
              hasError={!!patientInfoErrors['race']}
              required={ShowAsRequiredFields['race']}
              dataqa="race-info-page"
            />
          </div>
          <div className="col-3-md fs-mask">
            <SelectBox
              id="gender"
              onChange={(e) => onChangeInput('gender', e.target.value)}
              defaultValue={getString('gender')}
              label={getString('gender')}
              value={currentPatient.gender}
              options={GenderOptions}
              hasError={!!patientInfoErrors['gender']}
              required={ShowAsRequiredFields['gender']}
              dataqa="gender-info-page"
            />
          </div>

          <div className="col-3-md fs-mask">
            <FormInput
              label={getString('zip')}
              id="zipCode"
              onChange={onChangeInput}
              value={currentPatient.zipCode}
              hasError={!!patientInfoErrors['zipCode']}
              required={ShowAsRequiredFields['zipCode']}
              data-qa="zipcode"
            />
          </div>
        </div>
      </div>

      {!isUnderMinimumAge && isUnderNineteen && (
        <PatientGuardianInfo
          onChangeInput={onChangeInput}
          patientInfo={currentPatient}
          errors={guardianInfoErrors}
        />
      )}

      <h4 className="mt-5x">{getString('contactMethod')}</h4>
      <p>{getString('contactMethodSubText')}</p>

      {isWaitlist ? (
        <ContactType
          onChange={onChangeInput}
          value={currentPatient}
          errors={patientInfoErrors}
          label={'Email Address'}
          contactType={contactType}
          setContactType={setContactType}
          setPatientInfoErrors={setPatientInfoErrors}
        />
      ) : (
        <div className="patient-form-group border-rounded">
          <div className="row">
            <div className="col-6-md fs-mask">
              <FormInput
                label={getString('emailAddress')}
                id="email"
                type="email"
                inputAutoComplete={'off'}
                disablePaste={true}
                onChange={onChangeInput}
                value={currentPatient.email}
                hasError={!!patientInfoErrors['email']}
                required={ShowAsRequiredFields['email']}
                data-qa="email-info-page"
              />
            </div>
            <div className="col-6-md fs-mask">
              <FormInput
                label={getString('phoneNumber')}
                id="mobileNo"
                type="tel"
                onChange={onChangeInput}
                value={currentPatient.mobileNo}
                hasError={!!patientInfoErrors['mobileNo']}
                aria-label="mobile number"
                data-qa="mobile-info-page"
              />
            </div>
          </div>
        </div>
      )}

      <FormErrors errors={patientInfoErrors} />
    </section>
  );
};

export default PatientInformation;
