import cls from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import FormInput from '../../../../common/FormInput';
import { getString } from '../../../../../util/lang';
import SelectBox from '../../../../common/SelectBox';
import { MonthList, YearList } from '../../../../../constants/patientInfo';
import styles from './AddSinglePatientInfoInput.module.scss';
import { PatientFormActiveHeader } from './PatientFormActiveHeader';
import Collapsible from 'react-collapsible';
import { FiChevronDown } from 'vyaguta-icons/fi';
import FormErrors from '../../../../common/FormErrors';
import * as Regex from '../../../../../util/regex';
import regexPattern from '../../../../../constants/regexPattern';
import { getDaysInMonth } from '../../../../../util/DateAndTime';
import {
  addPatientFieldNames,
  checkAddPatientFormValidity,
  formatMobileNumber,
  getServiceAgeValidation,
} from '../validation/AddPatientInfoValidation';
import { handleSpaceOrEnterKey } from 'util/handleSpaceOrEnterKey';

const AddSinglePatientInfoInput = ({
  patientInfo,
  patientIndex,
  setState,
  removeSelectedPatientForm,
  setFormHasErrors,
  optionalSubtitle,
  selectedServices,
}) => {
  const [isPatientHeaderActive, setPatientHeaderActive] = useState(false);
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(true);
  const [errorSet, setErrorSet] = useState({});
  const sendEmail = !!patientInfo?.sendEmail;
  const setSendEmail = (sendEmailValue) => {
    setState({ ...patientInfo, sendEmail: sendEmailValue });
  };

  const clearCurrentForm = () => {
    setPatientHeaderActive(false);
    setState({});
  };

  useEffect(() => {
    const formErrors = {
      ...checkAddPatientFormValidity(patientInfo),
      ...getServiceAgeValidation(
        selectedServices,
        patientInfo?.birthYear,
        patientInfo?.birthMonth,
        patientInfo?.birthDay,
        patientIndex,
      ),
    };
    setErrorSet({ ...formErrors });
    setFormHasErrors(Object.keys(formErrors).length > 0);
  }, [patientInfo]);

  const onPatientInfoChange = (key, value, event) => {
    if (key == null || value == null) return;

    if (key === addPatientFieldNames.mobileNo) {
      //mobileNo
      if (value.length < 12) {
        const cursor = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
          element.selectionStart = cursor;
          element.selectionEnd = cursor;
        });
        value = value.replaceAll('-', '');
      }

      if (!Regex.isNumber(value)) return;

      value = formatMobileNumber(value); //produce the dashed output
      setState({ ...patientInfo, [key]: value });
    } else if (
      // name
      [
        addPatientFieldNames.firstName,
        addPatientFieldNames.middleName,
        addPatientFieldNames.lastName,
      ].includes(key) &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    ) {
      return;
    }

    setState({ ...patientInfo, [key]: value });
  };

  return (
    <div>
      {
        <Collapsible
          trigger={
            isPatientHeaderActive ? (
              <PatientFormActiveHeader
                patientInfo={patientInfo}
                patientIndex={patientIndex}
                onClear={clearCurrentForm}
                optionalSubtitle={
                  Number(patientIndex) === 1 ? optionalSubtitle : ''
                }
              />
            ) : (
              <CollapsibleFormHeader
                isOpen={isCollapsibleOpen}
                patientInfo={patientInfo}
                patientIndex={patientIndex}
                onRemove={removeSelectedPatientForm}
              />
            )
          }
          triggerDisabled={isPatientHeaderActive}
          open={isPatientHeaderActive || isCollapsibleOpen}
          onTriggerOpening={() => setIsCollapsibleOpen(true)}
          onTriggerClosing={() => setIsCollapsibleOpen(false)}
        >
          <AddSinglePatientInfoInputForm
            patientInfo={patientInfo}
            patientIndex={patientIndex}
            sendEmail={sendEmail}
            setSendEmail={setSendEmail}
            errorSet={errorSet}
            setErrorSet={setErrorSet}
            onPatientInfoChange={onPatientInfoChange}
            setPatientHeaderActive={setPatientHeaderActive}
          />
        </Collapsible>
      }

      <FormErrors errors={errorSet} />
    </div>
  );
};

const AddSinglePatientInfoInputForm = ({
  sendEmail,
  setSendEmail,
  patientInfo,
  errorSet,
  onPatientInfoChange,
  patientIndex,
  setPatientHeaderActive,
}) => {
  const inputFormRef = useRef(null);

  const handleSendEmailCheckBox = () => {
    setPatientHeaderActive(false);
    setSendEmail(!sendEmail);
  };

  return (
    <div
      className={cls('patient-form-group', styles.PatientFormGroupBorders)}
      onFocus={() => setPatientHeaderActive(true)}
      ref={inputFormRef}
      onBlur={(e) => {
        const checkboxId = `sendEmail${patientIndex}`;
        const clearBtnId = `clr-btn-${patientIndex}`;

        // prevent on blur if current target is checkbox or clear btn
        // so that respective handler is executed
        if (
          [checkboxId, clearBtnId].includes(e.target?.id) ||
          [checkboxId, clearBtnId].includes(e.relatedTarget?.id)
        ) {
          return;
        }

        if (
          inputFormRef.current &&
          !inputFormRef.current.contains(e.relatedTarget)
        ) {
          // this  click or any event received is registered from inside this div element
          // so add blur logics
          e.preventDefault();
          setPatientHeaderActive(false);
        }
      }}
    >
      <div className="row">
        <div className="col-4-md">
          <FormInput
            label={getString('firstName')}
            id={addPatientFieldNames.firstName}
            onChange={onPatientInfoChange}
            value={patientInfo?.firstName || ''}
            hasError={!!errorSet[addPatientFieldNames.firstName]}
            required={true}
            data-qa="firstname-check"
          />
        </div>
        <div className="col-4-md">
          <FormInput
            label={getString('middleName')}
            id={addPatientFieldNames.middleName}
            onChange={onPatientInfoChange}
            value={patientInfo?.middleName || ''}
            hasError={!!errorSet[addPatientFieldNames.middleName]}
            required={false}
            data-qa="middlename-check"
          />
        </div>
        <div className="col-4-md">
          <FormInput
            label={getString('lastName')}
            id={addPatientFieldNames.lastName}
            onChange={onPatientInfoChange}
            value={patientInfo?.lastName || ''}
            hasError={!!errorSet[addPatientFieldNames.lastName]}
            required={true}
            data-qa="lastname-check"
            inputClassName={styles.DisableRightBorder}
          />
        </div>
        <div className="col-4">
          <SelectBox
            label={getString('year')}
            id={addPatientFieldNames.birthYear}
            defaultValue={getString('birthYear')}
            onChange={(e) => onPatientInfoChange('birthYear', e.target.value)}
            value={patientInfo.birthYear}
            hasError={!!errorSet[addPatientFieldNames.birthDate]}
            required={!sendEmail}
            options={YearList()}
            dataqa="birthyear-check"
            inputClassName={styles.DisableBottomBorder}
          />
        </div>
        <div className="col-4">
          <SelectBox
            id={addPatientFieldNames.birthMonth}
            onChange={(e) => onPatientInfoChange('birthMonth', e.target.value)}
            defaultValue={getString('birthMonth')}
            label={getString('birthMonth')}
            value={patientInfo.birthMonth}
            options={MonthList}
            hasError={!!errorSet[addPatientFieldNames.birthDate]}
            required={!sendEmail}
            dataqa="birthmonth-check"
            inputClassName={styles.DisableBottomBorder}
          />
        </div>
        <div className="col-4">
          <SelectBox
            id={addPatientFieldNames.birthDay}
            onChange={(e) => {
              onPatientInfoChange('birthDay', e.target.value);
            }}
            defaultValue={getString('birthDay')}
            label={getString('birthDay')}
            value={patientInfo.birthDay}
            options={getDaysInMonth(
              patientInfo.birthMonth,
              patientInfo.birthYear,
            )}
            hasError={!!errorSet['birthDate']}
            required={!sendEmail}
            dataqa="birthday-check"
            inputClassName={cls(
              styles.DisableBottomBorder,
              styles.DisableRightBorder,
            )}
          />
        </div>
      </div>

      {patientIndex !== '1' && (
        <div className={cls(styles.AddSendEmailCheckBox, 'form-group mb-0x')}>
          <div className="custom-check py-4x pl-4x px-0x terms-condition-check">
            <input
              data-qa="self-declaration-checkbox"
              autoComplete="off"
              type="checkbox"
              id={addPatientFieldNames.sendEmail + patientIndex}
              checked={sendEmail}
              onChange={handleSendEmailCheckBox}
            />
            <label htmlFor={addPatientFieldNames.sendEmail + patientIndex}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12" />
              </svg>
              <div>{getString('appPatientsSendEmail')}</div>
              <span className={styles.AddSendEmailInfo}>
                <b>Note: </b>
                {getString('appPatientsSendEmailInfo')}
              </span>
            </label>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-6-md">
          <FormInput
            label={getString('emailAddress')}
            id={addPatientFieldNames.email}
            onChange={onPatientInfoChange}
            value={patientInfo?.email || ''}
            hasError={!!errorSet[addPatientFieldNames.email]}
            required={true}
            data-qa="firstname-security-check"
            inputClassName={styles.EnableOnlyRightTopBorder}
            isVisible={sendEmail}
          />
        </div>
        <div className="col-6-md">
          <FormInput
            label={getString('mobileNumber')}
            id={addPatientFieldNames.mobileNo}
            type="tel"
            onChange={onPatientInfoChange}
            value={patientInfo?.mobileNo || ''}
            hasError={!!errorSet[addPatientFieldNames.mobileNo]}
            aria-label="mobile number"
            data-qa="mobile-info-page"
            inputClassName={styles.EnableTopOnly}
            isVisible={sendEmail}
          />
        </div>
      </div>
    </div>
  );
};

const CollapsibleFormHeader = ({
  isOpen,
  onRemove,
  patientInfo,
  patientIndex = 1,
}) => {
  return (
    <div
      className={cls(styles.CollapsibleTriggerItem, styles.FlexJustify, {
        [styles.CollapsibleTriggerItemBorderOpen]: isOpen,
        [styles.CollapsibleTriggerItemBorderClose]: !isOpen,
      })}
    >
      <span className={styles.CollapsibleTriggerItemText}>
        {patientIndex}.{' '}
        {`${patientInfo?.firstName || ''} ${
          patientInfo.middleName
            ? patientInfo.middleName + ' ' + patientInfo?.lastName || ''
            : patientInfo?.lastName || ''
        }`}
      </span>
      <div className={styles.FlexJustify}>
        <button
          className={styles.CollapsibleTriggerItemRemoveBtn}
          onClick={onRemove}
          onKeyDown={(e) => {
            handleSpaceOrEnterKey(e, onRemove);
          }}
          tabIndex="0"
        >
          Remove
        </button>
        <div className={styles.CollapsibleTriggerItemSeparator} />
        <FiChevronDown
          size={24}
          className={styles.CollapsibleTriggerItemArrow}
          tabIndex="0"
        />
      </div>
    </div>
  );
};

export default AddSinglePatientInfoInput;
