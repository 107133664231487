import { useCallback, useMemo } from 'react';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { getString } from 'util/lang';
import { ClinicAndTiming } from '../../NewClinicAndTiming/ClinicAndTiming';
import {
  makeFollowupRescheduleNavLinks,
  makeFollowupRescheduleNavLinksWithNoPaymentOption,
} from 'components/common/NavBar/navLinks';
import { useAppParams } from 'context/useAppParams';
import { useApplicationContext, useDetailContext } from 'context';
import { changeTitle } from 'util/siteInfo';

export const RescheduleDateTime = () => {
  changeTitle('Clinic and Timing');
  const {
    details: { pageComplete },
  } = useDetailContext();
  const { isPaymentOptionEnabled } = useApplicationContext();

  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();

  const toNextPage = useCallback(() => {
    nav.toFollowup.reschedule.screening(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      isPaymentOptionEnabled
        ? makeFollowupRescheduleNavLinks(
            pageComplete,
            appointmentId,
            organizationId,
          )
        : makeFollowupRescheduleNavLinksWithNoPaymentOption(
            pageComplete,
            appointmentId,
            organizationId,
          ),
    [organizationId, pageComplete, appointmentId],
  );

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ClinicAndTiming
        disableEdit
        toNextPage={toNextPage}
        toNextPageLabel={getString('continueToEligibilityAndScreeningPage')}
      />
    </PageWrapper>
  );
};
