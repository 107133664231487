export const InsuranceType = {
  MEDICARE: 153940000,
  MEDICARE_ADVANTAGE: 153940001,
  COMMERCIAL: 153940002,
  MEDICAID: 153940003,
  MILITARY_AND_VETERANS: 153940004,
} as const;

export const PaymentType = {
  INSURANCE: 153940000,
  NO_INSURANCE: 153940002,
  OUT_OF_POCKET: 153940003,
  WORKERS_COMP: 153940004,
  BILL_TO_FACILITY: 153940005,
} as const;
