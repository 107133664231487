/**
 *
 * @param {any[]} a
 * @param {any[]} b
 * @returns
 */
const shallowEqualArrays = (a, b) => {
  return a.length === b.length && a.every((item, idx) => item === b[idx]);
};

/**
 * @template T
 *
 * @param {T} func
 * @returns
 */
export const memoize = (func) => {
  let cache = {
    set: false,
    args: [],
    value: null,
  };

  /** @type {typeof func} */
  const memoized = (...args) => {
    if (!cache.set || !shallowEqualArrays(args, cache.args)) {
      cache = {
        set: true,
        args,
        value: func(...args),
      };
    }

    return cache.value;
  };

  return memoized;
};
