import React from 'react';

import { newSigneticLogo } from 'assets/images';

export const Navbar = (props) => {
  return (
    <div className="welcome-page__navbar">
      <div className="welcome-page__navbar-text">{props.organizationName}</div>
      <div className="welcome-page__navbar-logo-wrapper">
        <div className="welcome-page__navbar-sponser">powered by</div>
        <div className="welcome-page__navbar-logo">
          <img src={newSigneticLogo} alt="signetic_logo" />
        </div>
      </div>
    </div>
  );
};
