import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';

const TestFacilityDetailsPdfView = ({
  facilityName,
  facilityPhone,
  facilityAddress,
  facilityClia,
  orderingProvider,
  facilityNpi,
}) => (
  <View style={styles.section}>
    <Text style={styles.sectionTitle}>
      {getString('testReport.testFacilityDetails')}
    </Text>
    <View style={styles.sectionContent}>
      <View style={styles.detailsRow}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>
            {getString('testReport.testFacilityName')}
          </Text>
          <Text style={styles.detailsValue}>{facilityName}</Text>
        </View>
      </View>
      <View style={styles.detailsRow}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>
            {getString('testReport.testFacilityPhone')}
          </Text>
          <Text style={styles.detailsValue}>{facilityPhone}</Text>
        </View>
      </View>
      <View style={styles.detailsRow}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>
            {getString('testReport.testFacilityAddress')}
          </Text>
          <Text style={styles.detailsValue}>{facilityAddress}</Text>
        </View>
      </View>
      {facilityClia && (
        <View style={styles.detailsRow}>
          <View style={styles.detailsItem}>
            <Text style={styles.detailsLabel}>
              {getString('testReport.testFacilityCliaNumber')}
            </Text>
            <Text style={styles.detailsValue}>{facilityClia}</Text>
          </View>
        </View>
      )}
      {orderingProvider && (
        <View style={styles.detailsRow}>
          <View style={styles.detailsItem}>
            <Text style={styles.detailsLabel}>
              {getString('testReport.testOrderingProvider')}
            </Text>
            <Text style={styles.detailsValue}>{orderingProvider}</Text>
          </View>
        </View>
      )}
      {facilityNpi && (
        <View style={styles.detailsRow}>
          <View style={styles.detailsItem}>
            <Text style={styles.detailsLabel}>
              {getString('testReport.testFacilityNpi')}
            </Text>
            <Text style={styles.detailsValue}>{facilityNpi}</Text>
          </View>
        </View>
      )}
    </View>
  </View>
);

const styles = StyleSheet.create({
  section: {
    border: '1px solid #757575',
    marginBottom: 16,
  },
  sectionTitle: {
    fontWeight: 700,
    backgroundColor: '#dddddd',
    padding: '8px 12px',
  },
  sectionContent: {
    padding: 12,
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  detailsItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    minWidth: 200,
    width: '50%',
  },
  detailsLabel: {
    fontWeight: 700,
    minWidth: 120,
    paddingRight: 12,
  },
});

export default TestFacilityDetailsPdfView;
