/**
 *
 * @param {array} keys: array of keys strings to be filter
 * @param {object} obj: Object from which the keys are to be filtered
 * @return {object} result: filtered object instance
 */
const filterByKeys = (keys, obj) => {
  let result = {};

  keys.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      result[key] = obj[key];
    }
  });

  return result;
};

const isEmpty = (object) => Object.keys(object).length === 0;

/**
 *
 * @param object - Object literal
 * @returns {{}|{}|*}
 */
export const removeAllEmptyFieldValuesForObject = (object) => {
  if (!object) {
    return object;
  }

  return Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
    )
      return acc;

    return { ...acc, [key]: value };
  }, {});
};

// Custom pick utility function
export const customPick = (obj, keys) => {
  return keys.reduce((result, key) => {
    if (key in obj) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  filterByKeys,
  isEmpty,
};
