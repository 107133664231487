import { useCallback } from 'react';
import { useDetailContext, useSelectionsContext } from 'context';
import * as appointmentService from 'services/appointment';

export const useAppointmentReschedule = () => {
  const { setDetails } = useDetailContext();

  const { isCounsellingSelected } = useSelectionsContext();

  const rescheduleAppointment = useCallback(
    async (captchaResponseKey) => {
      const {
        appointments,
        security: { token },
      } = await new Promise((resolve) => {
        setDetails((details) => {
          resolve(details);
          return {};
        });
      });

      const rescheduleAppointmentList = appointments
        .map(({ firstAppointment, secondAppointment }) => [
          firstAppointment,
          secondAppointment,
        ])
        .flat()
        .filter((appointment) => appointment?.canBeRescheduled); // NOTE: this might be problematic

      return appointmentService.rescheduleAppointments(
        rescheduleAppointmentList,
        captchaResponseKey,
        isCounsellingSelected,
        token,
      );
    },
    [setDetails, isCounsellingSelected],
  );

  return { rescheduleAppointment };
};
