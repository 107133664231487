import { useMemo } from 'react';
import { useSelectionsContext } from 'context';
import { compareStrings, joinStringsWithAnd } from 'util/string';

export const isDefaultSubServiceName = (subServiceName) =>
  compareStrings(subServiceName, 'i am not sure') ||
  compareStrings(subServiceName, "i'm not sure");

/**
 *
 * @param {{subService: {name: string}, service: {name: string}}[]} selectedServices
 * @returns
 */
export const getValidServiceNames = (selectedServices) =>
  selectedServices.map((selection) =>
    isDefaultSubServiceName(selection.subService.name)
      ? selection.service.name
      : selection.subService.name,
  );

export const useSelectedServiceNames = () => {
  const { selectedServices } = useSelectionsContext();

  return useMemo(() => {
    const serviceNames = getValidServiceNames(selectedServices);
    return joinStringsWithAnd(
      serviceNames.map((name, idx) => <b key={idx}>{name}</b>),
    );
  }, [selectedServices]);
};
