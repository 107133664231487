import React, { useState, useEffect } from 'react';

import FormInput from 'components/common/FormInput';
import NotificationPreferences from 'components/COVID/views/PatientInformation/presentation/NotificationPreferences';
import IISConsent from 'components/COVID/views/PatientInformation/presentation/IISConsent';
import { ShowAsRequiredFields } from 'constants/patientInfo';
import { ContactInfoKeys } from 'constants/patientInfo';
import FormErrors from 'components/common/FormErrors';

import { getString } from 'util/lang';
import styles from './contact.module.scss';

export const Contact = (props) => {
  const {
    patientInfo = {},
    errors = {},
    isIISConsentEnabled = true,
    onPatientInfoChange,
    validateEmailOnBlur,
    validateConfirmEmailOnBlur,
    clientName,
  } = props;

  const [patientInfoState, setPatientInfoState] = useState({});

  const errorSet = React.useMemo(() => {
    const errorSet = {};
    [...ContactInfoKeys].forEach((key) => {
      if (errors[key]) {
        errorSet[key] = errors[key];
      }

      if (patientInfo.emailOptOut) {
        errorSet.confirmEmail = null;
      }
      if (isIISConsentEnabled && !patientInfo.iisConsent) {
        errorSet.njisValidation = getString('njisValidationMessage');
      }
    });
    return errorSet;
  }, [errors]);

  useEffect(() => {
    setPatientInfoState({ ...patientInfo });
  }, [patientInfo]);

  const onInputChange = (key, value, event) => {
    if (typeof onPatientInfoChange === 'function') {
      onPatientInfoChange(key, value, event);
    } else {
      setPatientInfoState((prevState) => {
        return {
          ...prevState,
          [key]: value,
        };
      });
    }
  };

  const validateEmail = () => {
    if (typeof validateEmailOnBlur === 'function') {
      validateEmailOnBlur();
    }
  };

  const validateConfirmEmail = () => {
    if (typeof validateConfirmEmailOnBlur === 'function') {
      validateConfirmEmailOnBlur();
    }
  };

  return (
    <>
      <div className={`${styles['patient-form-group']}`}>
        <div className={`${styles['border-reset']} patient-form-group`}>
          <div className={`row`}>
            <div className="col-12-md">
              <FormInput
                label={getString('mobileNumber')}
                id="mobileNo"
                type="tel"
                inputClassName={`${styles['border-reset-right']}`}
                onChange={onInputChange}
                value={patientInfoState.mobileNo}
                hasError={!!errors['mobileNo']}
                required={ShowAsRequiredFields['mobileNo']}
                aria-label="mobile number"
                data-qa="mobile-info-page"
              />
            </div>
            <div className="col-6-md">
              <FormInput
                label={getString('emailAddress')}
                id="email"
                type="email"
                inputClassName={`${styles['input-email-address-border-reset']}`}
                inputAutoComplete={'off'}
                disablePaste={true}
                onChange={onInputChange}
                value={patientInfoState.email}
                hasError={!!errors['email']}
                data-qa="email-info-page"
                onBlur={validateEmail}
                required={
                  patientInfoState.emailNotificationConsent
                    ? ShowAsRequiredFields['email']
                    : false
                }
              />
            </div>
            <div className="col-6-md">
              <FormInput
                label={getString('emailAddressConfirm')}
                id="confirmEmail"
                type="email"
                inputClassName={`${styles['border-reset-right']}`}
                disablePaste={true}
                onChange={onInputChange}
                value={patientInfoState.confirmEmail}
                hasError={!!errors['confirmEmail']}
                data-qa="email-confirm-info-page"
                onBlur={validateConfirmEmail}
                required={
                  patientInfoState.emailNotificationConsent
                    ? ShowAsRequiredFields['confirmEmail']
                    : false
                }
              />
            </div>
          </div>
        </div>

        <div className={`${styles['patient-form-group__consent']} mt-3x`}>
          {isIISConsentEnabled && (
            <IISConsent
              isConsentAgree={
                isIISConsentEnabled ? patientInfoState.iisConsent : false
              }
              onConsentChange={onInputChange}
              dataqa="iis-consent"
            />
          )}
          <NotificationPreferences
            patientInfo={patientInfo}
            onChange={onInputChange}
            clientName={clientName}
          />
        </div>
      </div>
      <FormErrors errors={errorSet} />
    </>
  );
};
