import { getString } from 'util/lang';
import PharmacyInfo from '../../common/PharmaInfo/PharmacyInfo';
import FormHeader from '../../common/FormHeader/FormHeader';

import styles from './EducationBooklet.module.scss';
import { contactMasking } from 'util/contactFormat';

const LABEL_PREFIX = 'dme.deliveryPacketForm.educationBooklet';

const EducationBooklet = (props) => {
  const { organizationDetail, clinicDuration } = props?.educationBookletData;

  const contents = JSON.parse(
    getString(`${LABEL_PREFIX}.contents`, {
      companyName: organizationDetail?.organizationName,
      companyAddress: organizationDetail?.address,
      hoursOfOperation: `${clinicDuration}`,
      companyPhone: contactMasking(
        organizationDetail?.organizationContactPhone,
      ),
    }),
  );

  return (
    <div className="d-flex flex-column">
      <FormHeader text={getString(`${LABEL_PREFIX}.title`)} />

      <PharmacyInfo className={styles.pharamaInfoWrap} />

      <p className={styles.desc}>{getString(`${LABEL_PREFIX}.description`)}</p>

      <ul className={styles.unOrderlist}>
        {contents.map(({ title, description }, idx) => (
          <li key={idx}>
            <h3>{title}</h3>
            <p>{description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EducationBooklet;
