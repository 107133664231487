import IconRemove from '../assets/images/icon-remove.svg';

export const RemoveIcon = (props) => {
  return (
    <img
      src={IconRemove}
      alt="React Logo"
      width={props.size}
      height={props.size}
      className={props.className}
      tabIndex={0}
    />
  );
};
