import React from 'react';

import { getString } from 'util/lang';
import DTPicker from '../presentation/DTPicker';
import ActivityIndicator from 'components/common/ActivityIndicator';
import RefreshDataButton from 'components/COVID/common/RefreshDataButton';

const DateAndTimeSelection = (props) => {
  const { isFetchingClinicDays, refreshSlot, ...dtPickerProps } = props;

  return (
    <div className="clinic-and-timing">
      <section className="section__margin vaccine-selection">
        <div className="section__container border-radius border">
          <div className="d-md-flex justify-content-between align-items-center">
            <h3>{getString('scheduleAppointmentHeader')}</h3>
            <RefreshDataButton
              data-qa="refresh-date-time-button"
              label={getString('refreshData')}
              handleRefresh={refreshSlot}
            />
          </div>
          {isFetchingClinicDays ? (
            <div className="mt-16x py-20x">
              <ActivityIndicator />
              <p className="mt-6x text-center">
                {getString('fetchingAvailableDates')}
              </p>
            </div>
          ) : (
            <DTPicker {...dtPickerProps} />
          )}
        </div>
      </section>
    </div>
  );
};

export default DateAndTimeSelection;
