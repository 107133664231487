/**
 * disable inspect element shortcuts with {boolean} disableInspectFlag
 */
export function disableInspect() {
  document.addEventListener('contextmenu', function (e) {
    e.preventDefault();
  });

  document.addEventListener('keydown', function (e) {
    if (
      e.keyCode === 123 ||
      (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) ||
      (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) ||
      (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) ||
      (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
    ) {
      e.preventDefault();
      return false;
    }
  });
}
