import * as http from '../util/http';
import config from '../config';

export function convertUserInfoObjectToDynamicsFormat(userInfo) {
  const { birthYear, birthMonth, birthDay } = userInfo;
  const fullBirthMonth = birthMonth < 10 ? `0${birthMonth}` : birthMonth;
  const fullBirthDay = birthDay < 10 ? `0${birthDay}` : birthDay;
  const fullBirthDate = `${birthYear}-${fullBirthMonth}-${fullBirthDay}`;

  return {
    birthdate: fullBirthDate,
    firstname: userInfo.firstName,
    middlename: userInfo.middleName,
    lastname: userInfo.lastName,
    captchaResponseKey: userInfo.captcha,
  };
}

/**
 * Create message for contact us
 *
 * @param {object} userDetails : User Info Obj
 */
export async function verifySecurityQuestions(activityId, user) {
  const userDetails = convertUserInfoObjectToDynamicsFormat(user);
  const url = config.entity.securityVerification(activityId);

  const { data } = await http.post(url, userDetails);
  return data;
}

/**
 *
 * @desc Verification for group appointment for reschedule/cancel
 * @param {object} userDetails : User Info Obj
 */
export async function verifySecurityQuestionsGroupAppointment(
  activityId,
  user,
) {
  const userDetails = convertUserInfoObjectToDynamicsFormat(user);
  const url = `${config.entity.securityVerificationGroupAppointment(activityId)}`;
  const { data } = await http.post(url, userDetails);
  return data;
}

export async function verifySecurityQuestionsGroupRegistration(
  activityId,
  userInfo,
) {
  const userDetails = {
    firstname: userInfo.firstName,
    middlename: userInfo.middleName,
    lastname: userInfo.lastName,
    email: userInfo.email,
    confirmationCode: userInfo.confirmationCode,
    captchaResponseKey: userInfo.captcha,
  };

  const url =
    config.entity.securityVerificationPendingGroupAppointment(activityId);

  const { data } = await http.post(url, userDetails);
  return data;
}

export async function verifyIfRescheduleIsValid(appointmentId) {
  const url = config.entity.verifyAppointmentById(appointmentId);

  const { data } = await http.get(url);

  return data.data;
}
