import React, { useContext, useEffect } from 'react';

import {
  NavigationContext,
  GoToNextNavigation,
  GoToPreviousNavigation,
  Tabs,
} from '../presentation/SideNavBar';
import { useDetailContext } from 'context';

export const GroupAppointmentwrapper = ({ children }) => {
  const { dispatch, state: navigationState } = useContext(NavigationContext);
  const { setDetails } = useDetailContext();

  useEffect(() => {
    setDetails({
      currentPatientIndex: navigationState.activeMenuIndex,
    });
  }, []);

  const navigateToNextMenu = () => {
    GoToNextNavigation(dispatch);
  };

  const navigateToPreviousMenu = () => {
    GoToPreviousNavigation(dispatch);
  };

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        goToNextMenuGroupSideBar: navigateToNextMenu,
        goToPreviousMenuGroupSideBar: navigateToPreviousMenu,
      });
    });
  };
  return <>{renderChildren()}</>;
};
