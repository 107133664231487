import React, { useCallback, useEffect, useRef, useState } from 'react';

import AlertMessage from 'components/COVID/common/AlertMessage';

import { getString } from 'util/lang';
import { getTimeFromMinuteAndSeconds } from 'util/DateAndTime';
import { clamp } from '../../../../../util/math';
import cls from 'classnames';

export const CountDownTimer = ({
  timeoutCallback,
  countDownTime,
  className,
}) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [noOfSeconds, setNoOfSeconds] = useState(countDownTime);
  const timerId = useRef();
  const tickIntervals = 1 * 1000; // seconds

  useEffect(() => {
    const seconds = Math.floor(noOfSeconds % 60);
    const minutes = Math.floor((noOfSeconds / 60) % 60);
    const timeRemaining = getTimeFromMinuteAndSeconds(minutes, seconds);
    setTimeRemaining(timeRemaining);

    if (noOfSeconds === 0) {
      timeoutCallback();
      clearInterval(timerId.current);
    }
  }, [noOfSeconds]);

  useEffect(() => {
    if (timerId.current) {
      clearInterval(timerId.current);
    }

    timerId.current = setInterval(function () {
      setNoOfSeconds((noOfSeconds) => clamp(0, countDownTime, noOfSeconds - 1));
    }, tickIntervals);

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, []);

  return (
    <AlertMessage
      type="danger"
      className={cls(className, 'col-12 mt-5x p-3x')}
      message={
        <span>
          {getString(
            'insuranceValidationTimeRemainingMessage',
            <b>{timeRemaining}</b>,
          )}
        </span>
      }
      dataqa="is-not-eligible-insurance-message-with-timer"
      isVisible={true}
    />
  );
};
