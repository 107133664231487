import React from 'react';

import { graidentRightArrow } from 'assets/images';

export const ApplicationCard = (props) => {
  const { img_src, subtitle, description, link } = props.application;

  return (
    <div className="col-4-md mb-4x-xs mb-4x-sm">
      <a href={link} target="_blank" rel="noreferrer">
        <div class="applinks__list-item">
          <div class="applinks__list-image-wrapper">
            <div className="applinks__list-image">
              <img src={img_src} alt={subtitle} />
            </div>
          </div>
          <div className="welcome-page__subtitle">{subtitle}</div>
          <div class="row align-items-center">
            <div className="col-10-md ">
              <div className="applinks__list-text">{description}</div>
            </div>
            <div className="col-2-md">
              <div className="applinks__list-arrow-icon">
                <img src={graidentRightArrow} alt="graident_right_arrow" />
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
