const WarningIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-3x"
    >
      <path
        d="M8.5749 3.21659L1.51656 14.9999C1.37104 15.2519 1.29403 15.5377 1.29322 15.8287C1.2924 16.1197 1.3678 16.4059 1.51192 16.6587C1.65603 16.9115 1.86383 17.1222 2.11465 17.2698C2.36547 17.4174 2.65056 17.4967 2.94156 17.4999H17.0582C17.3492 17.4967 17.6343 17.4174 17.8851 17.2698C18.136 17.1222 18.3438 16.9115 18.4879 16.6587C18.632 16.4059 18.7074 16.1197 18.7066 15.8287C18.7058 15.5377 18.6288 15.2519 18.4832 14.9999L11.4249 3.21659C11.2763 2.97168 11.0672 2.76919 10.8176 2.62866C10.568 2.48813 10.2863 2.41431 9.9999 2.41431C9.71345 2.41431 9.43184 2.48813 9.18223 2.62866C8.93263 2.76919 8.72345 2.97168 8.5749 3.21659V3.21659Z"
        stroke="#E08F00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.5V10.8333"
        stroke="#E08F00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.1667H10.0083"
        stroke="#E08F00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningIcon;
