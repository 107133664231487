export const QuestionnaireCheckedOption = {
  NO: 0,
  YES: 1,
  NOT_APPLICABLE: 2,
};

export const QuestionnaireRadioOption = [
  { label: 'Yes', value: QuestionnaireCheckedOption.YES },
  { label: 'No', value: QuestionnaireCheckedOption.NO },
  { label: 'N/A', value: QuestionnaireCheckedOption.NOT_APPLICABLE },
];
