import React from 'react';
import styles from './ActivityIndicator.module.scss';

export const ActivityIndicator = ({
  className = '',
  message = '',
  marginBottom = true,
  loaderClass = '',
  loaderContainerClass = '',
  loaderLoopClass = '',
}) => {
  return (
    <div
      className={`${loaderClass} loader ${className}`}
      data-qa="activity-indicator"
    >
      <div className={`${loaderContainerClass} loader__container`}>
        <div
          className={`${loaderLoopClass} loader__loop ${marginBottom ? 'mb-5x' : ''} mx-auto`}
        />
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default ActivityIndicator;

export const ActivityIndicatorOverlay = () => (
  <div className={styles.overlayContainer}>
    <div className={styles.overlayBackground} />
    <ActivityIndicator />
  </div>
);
