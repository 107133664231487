import React, { useContext } from 'react';
import { DetailContext } from '../../../../../context';

const PolicyContent = () => {
  const appContext = useContext(DetailContext);

  const { appSettings } = appContext.details;
  const orgName = appSettings.organizationName;
  const orgContactEmail = appSettings.organizationContactEmail;
  const orgContactPhone = appSettings.organizationContactPhone;

  const getPrimaryContactInfo = () => {
    if (orgContactEmail) {
      return <a href={`mailto:${orgContactEmail}`}>{orgContactEmail}</a>;
    } else {
      return <a href={`tel:${orgContactPhone}`}>{orgContactPhone}</a>;
    }
  };

  return (
    <div className="main-wrapper">
      <div className="container mt-10x mb-10x">
        <section className="flu-shot-info section__margin border-radius border">
          <h2 tabIndex="0" className="section__title">
            Privacy Policy
          </h2>
          <p tabIndex="0">Effective Date: February 10, 2021</p>
          <p tabIndex="0">
            This Privacy Policy applies to the website that is linked to this
            Privacy Policy (the “Services”) provided by {orgName} or its
            affiliates (“Company” “we,” “us,” or “our”).
          </p>

          <p tabIndex="0">
            <strong>IMPORTANT NOTE:</strong> This Privacy Policy applies to
            individually identifiable information that you provide to us for
            purposes of obtaining medical care through the Services (such
            information is also referred to as “Protected Health Information” or
            “PHI”), which is subject to our Health Insurance Portability and
            Accountability Act Notice of Privacy Practices (the “HIPAA Notice”),
            and information that is not PHI. The HIPAA Notice describes how we
            can use and share your PHI and also describes your rights with
            respect to your PHI. This Privacy Policy supplements the HIPAA
            Notice for PHI. If there is ever any conflict between this Privacy
            Policy and the HIPAA Notice, the HIPAA Notice will apply. The HIPAA
            Notice does not apply to information that is not PHI.
          </p>

          <br />
          <h5 tabIndex="0">Website Terms and Conditions of Use</h5>
          <h5 tabIndex="0">I. Collection of Information</h5>
          <p tabIndex="0">
            We may collect the following kinds of information when you use a
            Service:
          </p>
          <p tabIndex="0">
            Information you provide directly to us. For certain Services or
            activities, such as when you register with a Service, subscribe to
            our alerts, or contact us directly, we may collect some or all of
            the following types of information:
          </p>
          <ul>
            <li tabIndex="0">
              Contact information, such as your full name, email address, mobile
              phone number, and address;
            </li>
            <li tabIndex="0">
              Personal health information, previous treatments, general health,
              and health insurance; and
            </li>
            <li tabIndex="0">Any other information you provide to us.</li>
          </ul>
          <br />
          <p tabIndex="0">
            We may combine such information with information we already have
            about you.
          </p>
          <p tabIndex="0">
            Information we collect automatically. We may collect certain
            information automatically when you use our Services, such as your
            computer’s Internet protocol (IP) address, device and advertising
            identifiers, browser type, operating system, Internet service
            provider, pages that you visit before and after using the Services,
            the date and time of your visit, information about the links you
            click and pages you view within the Services, and other standard
            server log information. We may also collect certain location
            information when you use our Services, such as your mobile device’s
            GPS signal, or information about nearby WiFi access points and cell
            towers.
          </p>
          <p tabIndex="0">
            We may use cookies to automatically collect this information.
            Cookies are small bits of information that are stored by your
            computer’s web browser. By using the Services, you consent to our
            use of cookies and similar technologies.
          </p>
          <p tabIndex="0">
            We may also collect technical data to address and fix technical
            problems and improve our Services. Your device or browser settings
            may permit you to control the collection of this technical data. By
            using the Services, you are consenting to us or any party acting on
            our behalf collecting this technical data.
          </p>
          <p tabIndex="0">
            Information from Third-Party Services. If you access the Services
            from an advertisement on a third-party website, application, or
            other service (a “Third-Party Service”) we may receive information
            from the owner of the Third-Party Service related to you or that
            advertisement.
          </p>

          <br />
          <h5 tabIndex="0">II. Use of Information</h5>
          <p tabIndex="0">We may use the information we collect online to:</p>
          <ul>
            <li tabIndex="0">Provide and improve the Services;</li>
            <li tabIndex="0">Contact you;</li>
            <li tabIndex="0">
              Fulfill your requests for services, and information;
            </li>
            <li tabIndex="0">
              Send you information about appointments from us or on behalf of
              our affiliates;
            </li>
            <li tabIndex="0">
              Analyze the use of the Services and user data to understand and
              improve the Services;
            </li>
            <li tabIndex="0">
              Conduct research using your information, which may be subject to
              your separate written authorization;
            </li>
            <li tabIndex="0">
              Prevent potentially prohibited or illegal activities and otherwise
              in accordance with our Terms of Use; and
            </li>
            <li tabIndex="0">
              For any other purposes disclosed to you at the time we collect
              your information or pursuant to your consent.
            </li>
          </ul>

          <br />
          <h5 tabIndex="0">III. Sharing of Information</h5>
          <p tabIndex="0">
            We are committed to maintaining your trust, and we want you to
            understand when and with whom we may share the information we
            collect.
          </p>
          <ul>
            <li tabIndex="0">
              Authorized third-party vendors and service providers. We may share
              your information with third-party vendors and service providers
              that help us with specialized services, including billing, payment
              processing, customer service, email deployment, business
              analytics, marketing (including but not limited to advertising,
              attribution, deep-linking, direct mail, mobile marketing,
              optimization and retargeting) advertising, performance monitoring,
              hosting, and data processing. These third-party vendors and
              service providers may not use your information for purposes other
              than those related to the services they are providing to us.
            </li>
            <li tabIndex="0">
              Corporate affiliates. We may share your information with our
              affiliates.
            </li>
            <li tabIndex="0">
              Legal purposes. We may disclose information to respond to
              subpoenas, court orders, legal process, law enforcement requests,
              legal claims or government inquiries, and to protect and defend
              the rights, interests, health, safety, and security of Company,
              our affiliates, patients, users, or the public.
            </li>
            <li tabIndex="0">
              Business Transfers. HIPAA permits organizations to transfer PHI in
              certain circumstances. We can transfer your information as part of
              a transfer of the assets of the organization, merger, or
              consolidation or in the unlikely event of bankruptcy, if such
              transfer is permissible under HIPAA and the HIPAA Notice.
            </li>
            <li tabIndex="0">
              Protected Health Information. We may transfer your PHI as
              described in the HIPAA Notice and permitted under HIPAA.
            </li>
            <li tabIndex="0">
              With your consent or at your direction. We may share information
              for any other purposes disclosed to you at the time we collect the
              information or pursuant to your consent or direction.
            </li>
          </ul>
          <br />
          <p tabIndex="0">
            If you choose to engage in public activities on the third party
            sites that we link to, you should be aware that any information you
            share there can be read, collected, or used by other users of these
            sites and forums. You should use caution in disclosing personal
            information while participating in these areas. We are not
            responsible for the information you choose to submit in public
            areas.
          </p>

          <p tabIndex="0">
            No information provided by patients during medical consultations or
            requests for medical appointments is ever used for marketing
            purposes.
          </p>

          <br />
          <h5 tabIndex="0">IV. Security</h5>
          <p tabIndex="0">
            We use measures to protect Protected Health Information from loss,
            theft, misuse, and unauthorized access, disclosure, alteration, and
            destruction in accordance with HIPAA. We use measures designed to
            protect other information from loss, theft, misuse, and unauthorized
            access, disclosure, alteration, and destruction. You should
            understand that no data storage system or transmission of data over
            the Internet or any other public network can be guaranteed to be 100
            percent secure.
          </p>

          <br />
          <h5 tabIndex="0">V. Your Choices</h5>
          <p tabIndex="0">
            You may also request that we delete your personal information by
            contacting us at {getPrimaryContactInfo()}. We will delete such
            information unless we are required to maintain information in
            accordance with applicable law.
          </p>
          <p tabIndex="0">
            You may be able to refuse or disable cookies by adjusting your web
            browser settings. Because each web browser is different, please
            consult the instructions provided by your web browser (typically in
            the “help” section). If you choose to refuse, disable, or delete
            these technologies, some of the functionality of the Services may no
            longer be available to you.
          </p>
          <p tabIndex="0">
            We do not share Protected Health Information with third parties for
            their own direct marketing purposes.
          </p>

          <br />
          <h6 tabIndex="0">VI. Third-party Links and Content</h6>
          <p tabIndex="0">
            Some of the Services may contain links to content maintained by
            third parties that we do not control. We are not responsible for the
            privacy practices of these third parties, and the information
            practices of these third parties are not covered by this Privacy
            Policy.
          </p>

          <br />
          <h5 tabIndex="0">VII. Limiting Data Collection and Do Not Track</h5>
          <p tabIndex="0">
            Opt-Out. To opt out of interest-based advertising across browsers
            and devices from companies that participate in the Digital
            Advertising Alliance or Network Advertising Initiative opt-out
            programs, please visit their respective websites. You may also be
            able to opt out of interest-based advertising through the settings
            within the mobile app or your mobile device, but your opt-out choice
            may apply only to the browser or device you are using when you opt
            out, so you should opt out on each of your browsers and devices if
            you want to disable interest-based advertising for those browsers
            and devices. If you opt out, you will still receive ads but they may
            not be as relevant to you and your interests, and your experience on
            our Services may be degraded.
          </p>
          <p tabIndex="0">
            Do-Not-Track Signals and Similar Mechanisms. Some web browsers
            transmit “do-not-track” signals to websites. Because of differences
            in how web browsers incorporate and activate this feature, it is not
            always clear whether users intend for these signals to be
            transmitted, or whether they even are aware of them. We currently do
            not take action in response to these signals.
          </p>

          <br />
          <h6 tabIndex="0">VIII. International Users</h6>
          <p tabIndex="0">
            We maintain information in the United States of America and in
            accordance with the laws of the United States, which may not provide
            the same level of protection as the laws in your jurisdiction. By
            using the Services and providing us with information, you understand
            and agree that your information may be transferred to and stored on
            servers located outside your resident jurisdiction and, to the
            extent you are a resident of a country other than the United States,
            that you consent to the transfer of such data to the United States
            for processing by us in accordance with this Privacy Policy.
          </p>

          <br />
          <h5 tabIndex="0">X. Changes to the Privacy Policy</h5>
          <p tabIndex="0">
            We may update this Privacy Policy from time to time. When we update
            the Privacy Policy, we will revise the “Effective Date” date above
            and post the new Privacy Policy. We recommend that you review the
            Privacy Policy each time you visit the Services to stay informed of
            our privacy practices.
          </p>

          <br />
          <h6 tabIndex="0">XI. Questions?</h6>
          <p tabIndex="0">
            If you have any questions about this Privacy Policy or our
            practices, please contact us at {getPrimaryContactInfo()}.
          </p>

          <br />
          <h5 tabIndex="0">TERMS OF USE</h5>

          <p tabIndex="0">
            We are pleased to offer you information via an online vaccination
            scheduling service. We think it is important for you to know how we
            handle information we communicate via the Internet. This Terms &
            Conditions statement outlines our practices and our sensitivity to
            your right to privacy. We reserve the right to revoke access at any
            time, for any reason.
          </p>

          <p tabIndex="0">
            <strong>Consent</strong> - You agree that your use of online service
            materials are subject to your agreement with all of these Terms of
            Use, and the Privacy Policy. You agree that you will not violate any
            local, state, federal or international laws in using this website or
            accessing any Material on this website.
          </p>

          <p tabIndex="0">
            <strong>Site Access</strong> - We reserve the right to prohibit,
            restrict or discontinue your access to certain pages within the
            website if you violate any terms of this agreement. We may modify
            these Terms of Use at any time without notice. The modified terms of
            use will be effective upon posting on our website. To remain in
            compliance, We suggest that you review the Terms of Use, as well as
            the other website policies listed above, at regular intervals.
          </p>

          <p tabIndex="0">
            <strong>Response to Electronic Communication</strong> - We will make
            its best effort to provide a timely response to electronic inquiries
            (emails and other electronic communication). In some cases, the
            clinic staff who needs to respond to an electronic inquiry or other
            communication may not be immediately available; you should allow at
            least two (2) business days for a response. Accordingly, emergency
            situations requiring immediate attention should not be submitted
            electronically. We are only able to respond to electronic
            communications based on the information sent by you, the patient. If
            there is insufficient information provided, we will be unable to
            provide accurate and reliable services.
          </p>

          <p tabIndex="0">
            <strong>Website Links</strong> - may offer links to related medical
            websites not managed by us. These website link(s) are for your
            informational purposes only. We do not endorse and have not verified
            the accuracy of the information in/on these websites, and you should
            not rely on any of the information found on the websites for
            purposes of treatment or diagnosis.
          </p>

          <p tabIndex="0">
            <strong>E-mail Privacy</strong> - As a user of online service you
            should be aware that you will be notified via email for your
            upcoming vaccination. This means that any person with access to your
            email will be able to see this notification. This could include your
            family members, employer or anyone else who can access your email
            account. If you send us an e-mail communication, it may be shared
            with the staff who assist the provider in providing your
            vaccination.{' '}
          </p>

          <p tabIndex="0">
            <strong>Appropriate Use</strong> - The platform is designed to be
            used for end-to-end COVID vaccination solution, the platform is not
            a substitute for medical care or office visits.
          </p>

          <p tabIndex="0">
            <strong>Security and Confidentiality</strong> - We are committed to
            protecting the confidentiality of your medical information.
            Firewalls, encryption, and audit trails are further used to
            safeguard your information. We have taken steps to make all
            information received from our online visitors as secure as possible
            against unauthorized access and use.
          </p>

          <p tabIndex="0">
            The platform must be accessed with a Secure Sockets Layer (SSL)
            compatible browser or terminal. Our SSL web server uses
            authentication and offers a high level of encryption technology.
          </p>

          <p tabIndex="0">
            You can tell when your connection is encrypted by looking at the
            location (URL) field. If the URL begins with https:// (instead of
            http://), the connection is encrypted. This means your data cannot
            be read or deciphered by unauthorized individuals.{' '}
          </p>

          <p tabIndex="0">
            Children - Except as otherwise indicated, we do not knowingly or
            intentionally collect personal information from children under age
            18. The content of our Site is directed at adults, and therefore
            this Site is intended for use only by adults over the age of 18. If
            you are under the age of 18, please consult a parent or guardian for
            help in using this online service.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PolicyContent;
