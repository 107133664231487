import React from 'react';

import NavBar from '../../../common/navbar';
import Footer from '../../../common/footer';
import PolicyContent from '../presentation/PolicyContent';
import { changeTitle } from 'util/siteInfo';

const PrivacyPolicy = () => {
  changeTitle('Privacy Policy');
  return (
    <>
      <NavBar displayNavLinks={false} />
      <PolicyContent />
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
