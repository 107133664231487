import React, { useMemo, useContext } from 'react';
import { FiChevronDown, FiGlobe } from 'vyaguta-icons/fi';

import SelectBox from '../../common/SelectBox';
import { DetailContext } from '../../../context';
import { getLanguageIdByLanguageCode } from 'util/lang';

const LanguageSelect = () => {
  const context = useContext(DetailContext);

  const { fetchLocalizedWebContents } = context;

  const { appLanguageCode } = context.details;

  const { languages } = context.details.appSettings;

  const languageOptions = useMemo(
    () =>
      languages.map((language) => ({
        value: language.code,
        label: language.name,
      })),
    [languages],
  );

  if (!languageOptions || languageOptions.length <= 1) {
    return null;
  }

  const handleLanguageChange = (e) => {
    const languageCode = e.target.value;
    context.updateSelectedLanguageCode(languageCode);
    const languageId = getLanguageIdByLanguageCode(languageCode, languages);
    // Fetch and Update state localized web content upon language change
    fetchLocalizedWebContents(languageId);
  };

  return (
    <div className="language-select">
      <FiGlobe size={20} className="icon-globe" />
      <SelectBox
        id="siteLanguage"
        onChange={handleLanguageChange}
        defaultValue=""
        label={''}
        value={appLanguageCode}
        options={languageOptions}
      />
      <FiChevronDown size={20} className="dropdown-arrow" />
    </div>
  );
};

export default LanguageSelect;
