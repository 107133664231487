import React from 'react';
import { getString } from 'util/lang';
import styles from './LandingPageBrand.module.scss';
import cls from 'classnames';

import { useAppSettings } from 'context';

const LandingPageBrand = ({ showSubText = true }) => {
  const { organizationName } = useAppSettings();
  return (
    <div className={cls('nlanding-page__brand', styles.landingPageMain)}>
      <h1
        className={cls(
          'nlanding-page__brand-title',
          styles.landingPageBrandTitle,
        )}
      >
        <span
          className={cls(
            'nlanding-page__brand-title__placeholder',
            styles.landingPageBrandTitlePlaceholder,
          )}
        >
          {getString('welcomeTo')}
          <b> {organizationName}</b>
        </span>
      </h1>
      {showSubText && (
        <p
          className={cls(
            'nlanding-page__brand-helper',
            styles.landingPageHelperInfo,
          )}
        >
          {getString('welcomeSubText')}
        </p>
      )}
      <p className={styles.landingPageServicesInfo}>
        {getString('serviceInfoText')}
      </p>
    </div>
  );
};

export default LandingPageBrand;
