export const MoreIcon = () => {
  return (
    <svg
      width="17"
      height="4"
      viewBox="0 0 17 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 2C4.25 2.9665 3.4665 3.75 2.5 3.75C1.5335 3.75 0.75 2.9665 0.75 2C0.75 1.0335 1.5335 0.25 2.5 0.25C3.4665 0.25 4.25 1.0335 4.25 2ZM10.25 2C10.25 2.9665 9.4665 3.75 8.5 3.75C7.5335 3.75 6.75 2.9665 6.75 2C6.75 1.0335 7.5335 0.25 8.5 0.25C9.4665 0.25 10.25 1.0335 10.25 2ZM14.5 3.75C15.4665 3.75 16.25 2.9665 16.25 2C16.25 1.0335 15.4665 0.25 14.5 0.25C13.5335 0.25 12.75 1.0335 12.75 2C12.75 2.9665 13.5335 3.75 14.5 3.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
