import React, { useCallback } from 'react';
import { useAppSettings } from 'context';
import MessageListBox from 'components/COVID/common/MessageListBox';
import { getString } from 'util/lang';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useResetAppState } from 'context/useResetAppState';

const RescheduleInvalid = (props) => {
  const nav = useNavigationHelper();
  const resetAppState = useResetAppState();
  const { organizationContactPhone, organizationContactEmail } =
    useAppSettings();
  const organizationMessages = [
    { title: 'Phone number', message: organizationContactPhone },
    { title: 'Email address', message: organizationContactEmail },
  ];

  const rescheduleInvalidMessage = getString('rescheduleInvalidMessage');
  const contactInformationTitle = getString('contactInformation');

  const toHomePage = useCallback(() => {
    resetAppState();
    nav.resetToFirstPage();
  }, [resetAppState, nav]);

  return (
    <>
      <section className="form-patient-info section__margin reschedule__wrapper">
        <div className="section-information-title section section-align">
          {rescheduleInvalidMessage}
        </div>
        <div className="section-title mt-6x">{contactInformationTitle}:</div>
        <MessageListBox messages={organizationMessages} />
      </section>
      <div className="section-align">
        <a href={nav.routes.selectService} onClick={toHomePage}>
          {getString('goToHomePageMessage')}
        </a>
      </div>
    </>
  );
};

export default RescheduleInvalid;
