import { AnyObjectSchema } from 'yup';

/**
 *
 * @param formValues The form Data which is to be validated
 * @param YupSchema The schema against which form data is to be validated
 * @returns
 */
export const validateFormData = <
  T extends Record<string, string | number | boolean>,
>(
  formValues: T,
  YupSchema: () => Record<keyof T, AnyObjectSchema>,
) => {
  let errors: Partial<Record<keyof T, string | null>> = {};
  let isValid = true;

  (Object.keys(formValues) as Array<keyof T>).forEach((key) => {
    const value = formValues[key];

    try {
      YupSchema()[key].validateSync(value);
      errors[key] = null;
    } catch (error) {
      isValid = false;
      errors[key] = (error as Error).message;
    }
  });

  return {
    errors,
    isValid,
  };
};
