import { FiArrowLeft } from 'vyaguta-icons/fi';
import cls from 'classnames';

/**
 * @type {React.FC<{
 *  text: string
 *  onClick: () => void
 *  classNames?: {
 *      container?: string
 *      button?: string
 *      arrow?: string
 *      text?: string
 *  }
 * }>} props
 */
const BackToPage = (props) => {
  const { text, onClick, classNames = {} } = props;

  return (
    <div className={cls('back-to-location mb-5x pl-0x', classNames.container)}>
      <button
        className={cls(
          'btn btn--plain p-0x color-grey-text-light d-flex align-items-center text-normal',
          classNames.button,
        )}
        onClick={onClick}
        tabIndex="-1"
      >
        <FiArrowLeft
          size={18}
          className={cls('mr-2x color-black-text', classNames.arrow)}
        />
        <span tabIndex="0" className={cls(classNames.text)}>
          {text}
        </span>
      </button>
    </div>
  );
};

export default BackToPage;
