import { useCallback, useEffect, useMemo } from 'react';
import { useDetailContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { makeGroupRegisterNavLinks } from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { PaymentInformation } from '../../PaymentInfo/PaymentInfoWrapper';

export const GroupRegisterPatientInfo = () => {
  const {
    details: { pageComplete, patientInfo },
    getContactByAppointmentId,
  } = useDetailContext();

  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();

  useEffect(() => {
    getContactByAppointmentId(appointmentId);
  }, [getContactByAppointmentId, appointmentId]);

  const toNextPage = useCallback(() => {
    nav.toGroup.register.confirmation(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      makeGroupRegisterNavLinks(pageComplete, appointmentId, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  const goBack = () => nav.goBack();

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <PaymentInformation
        confirmEditService={false}
        organizationId={organizationId}
        goBack={goBack}
        toNextPage={toNextPage}
        disableEdit={true}
        isFollowup={true}
      />
    </PageWrapper>
  );
};
