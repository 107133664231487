import React from 'react';

import Skeleton from 'react-loading-skeleton';

const SkeletonLoadingContainer = (props) => {
  const { loading } = props;

  if (loading) {
    return (
      <div className="section__margin">
        <Skeleton width={'100%'} height={250} />
      </div>
    );
  }

  return <>{props.children}</>;
};

export default SkeletonLoadingContainer;
