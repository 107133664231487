import React from 'react';
import { FiMapPin } from 'vyaguta-icons/fi';

const AddressOptionCard = (props) => {
  const { address, onAddressChange, selectedAddress } = props;
  const { id, homeAddress, city, state, zipCode } = address;
  const isChecked = selectedAddress?.zipCode === zipCode;

  const handleAddressChange = () => {
    onAddressChange(address);
  };

  return (
    <div className="address-item">
      <div className={`address-card`}>
        <input
          id={id}
          name="address-option"
          type="radio"
          className="custom-radio-input"
          onChange={handleAddressChange}
          checked={isChecked}
          autoComplete="off"
        />
        <label htmlFor={id} className="address-card__label">
          <div className="address-card__location">
            <FiMapPin size={20} />
          </div>
          <div className="address-card__content">
            <div className="card__location address__location">
              {homeAddress}
            </div>
            <div className="card__location address__location">
              {city}, {state} {zipCode}, United States
            </div>
          </div>
          <div className="address-card__radio">
            <span className="app-radio-wrapper">
              <span className="app-radio"></span>
            </span>
          </div>
        </label>
      </div>
    </div>
  );
};

export { AddressOptionCard };
