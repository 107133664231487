import React, { useContext } from 'react';
import Footer from '../../../common/footer';
import NavBar from '../../../common/navbar/navbar';
import { DetailContext } from '../../../../../context';
import MessageListBox from '../../../common/MessageListBox';
import { getString } from '../../../../../util/lang';
import { Link } from 'react-router-dom';
import { HOME } from '../../../../../constants/routes/covidRoutes';

const RescheduleValidity = (props) => {
  const appContext = useContext(DetailContext);

  const appSettings = appContext.details.appSettings;
  const organizationMessages = [
    { title: 'Phone number', message: appSettings.organizationContactPhone },
    { title: 'Email address', message: appSettings.organizationContactEmail },
  ];

  const rescheduleInvalidMessage = getString('rescheduleInvalidMessage');
  const contactInformationTitle = getString('contactInformation');

  return (
    <>
      <NavBar
        displayNavLinks={false}
        logo={appContext.details.appSettings.logoUrl}
      />
      <div className="section-main-wrapper">
        <div className="container">
          <section className="form-patient-info section__margin reschedule__wrapper">
            <div className="section-information-title section section-align">
              {rescheduleInvalidMessage}
            </div>
            <div className="section-title mt-6x">
              {contactInformationTitle}:
            </div>
            <MessageListBox messages={organizationMessages} />
          </section>
          <div className="section-align">
            <Link to={{ pathname: HOME, search: window.location.search }}>
              {getString('goToHomePageMessage')}
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RescheduleValidity;
