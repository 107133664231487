import React, { useCallback, useMemo, useState } from 'react';
import ClinicIcon from 'svg-images/ClinicIcon';

import { getString } from 'util/lang';
import { FiCalendar, FiMapPin } from 'vyaguta-icons/fi';
import SelectedServicesListBox from './SelectedServicesListBox';
import { MessageBtn } from './MessageBtn';
import styles from './ServicesInfo.module.scss';
import cls from 'classnames';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useSelectionsContext } from 'context';
import moment from 'moment';
import { convertTo12HourTimeFormatWithAmPm } from 'util/DateAndTime';
import { EditServiceModal } from '../views/EditService/presentation/EditServiceModal';

/**
 *
 * @param {{
 *  selectedServices: string[]
 *  dateInfo: string
 *  timeInfo: string
 *  locationInfo: string
 *  onEditService: () => void
 *  onEditDate: () => void
 *  onEditLocation: () => void
 * }} props
 * @returns
 */
const ServicesInfo = (props) => {
  const {
    selectedServices,
    dateInfo,
    timeInfo,
    locationInfo,
    onEditService,
    onEditDate,
    onEditLocation,
    disableEdit,
  } = props;

  return (
    <div className={styles.serviceInfo}>
      <ClinicIcon size="42" className={cls('mr-4x', styles.clinicIcon)} />

      <div className={styles.childContainer}>
        <SelectedServicesListBox
          selectedServices={selectedServices}
          className="mb-2x"
          onClick={onEditService}
          disableEdit={disableEdit}
        />

        {dateInfo && timeInfo && (
          <div className="mb-2x d-flex flex-wrap align-items-center">
            <FiCalendar size={16} className="mr-2x" />
            {getString('dateAtTime', dateInfo, <b>&nbsp;{timeInfo}</b>)}
            {!disableEdit && (
              <MessageBtn
                label={getString('editDate')}
                onClick={onEditDate}
                className={cls('ml-2x', styles.btn)}
              />
            )}
          </div>
        )}
        <div className="mb-1x d-flex flex-wrap align-items-center">
          {locationInfo && <FiMapPin size={16} className="mr-2x" />}{' '}
          {locationInfo}
          {!disableEdit && locationInfo && (
            <MessageBtn
              label={getString('editLocation')}
              onClick={onEditLocation}
              className={cls('ml-2x', styles.btn)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ServicesInfo;

export const ServicesInfoContainer = ({ disableEdit = false }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const hideModal = useCallback(() => {
    setModalVisibility(false);
  }, []);

  const nav = useNavigationHelper();
  const { selectedServices, selectedClinic, selectedTimeBlock } =
    useSelectionsContext();
  const selectedServiceNames = selectedServices.map(
    ({ service }) => service?.name,
  );

  const locationInfo = selectedClinic
    ? `${selectedClinic.name}, ${selectedClinic.address}`
    : '';

  const { dateInfo, timeInfo } = useMemo(() => {
    const dateInfo = selectedTimeBlock
      ? `${moment(selectedTimeBlock.appointmentDate).format('ddd, MMM D, YYYY')}`
      : null;
    const timeInfo = selectedTimeBlock
      ? convertTo12HourTimeFormatWithAmPm(selectedTimeBlock.blockStartTime)
      : null;

    return {
      dateInfo,
      timeInfo,
    };
  }, [selectedTimeBlock]);

  const onClickEditService = useCallback(() => {
    setModalVisibility(true);
  }, []);

  return (
    <>
      <ServicesInfo
        selectedServices={selectedServiceNames}
        locationInfo={locationInfo}
        dateInfo={dateInfo}
        timeInfo={timeInfo}
        onEditService={onClickEditService}
        onEditDate={nav.toSelectTiming}
        onEditLocation={nav.toSelectLocation}
        disableEdit={disableEdit}
      />
      {modalVisibility && (
        <EditServiceModal onConfirm={nav.toEditService} onClose={hideModal} />
      )}
    </>
  );
};
