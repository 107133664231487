import { PageWrapper } from '../../../common/PageWrapper';
import React, { useMemo } from 'react';
import {
  makeDefaultNavLinks,
  makeDefaultNavLinksWithNoPaymentOption,
} from '../../../../common/NavBar/navLinks';
import {
  useApplicationContext,
  useDetailContext,
} from '../../../../../context';
import { useAppParams } from '../../../../../context/useAppParams';
import { useNavigationHelper } from '../../../../../hooks/useNavigationHelper';
import ActivityIndicator from 'components/common/ActivityIndicator';
import PatientInformation from './PatientInformation';

import { usePatientInfo } from '../hooks/usePatientInfo';
import { changeTitle } from 'util/siteInfo';

export const PatientInformationPage = () => {
  changeTitle('Patient Information');
  const {
    details: { pageComplete, selectedLocationId, selectedServices, loading },
  } = useDetailContext();
  const { PatientInfoProps } = usePatientInfo();
  const { isPaymentOptionEnabled } = useApplicationContext();

  const { organizationId, appointmentId } = useAppParams();
  const nav = useNavigationHelper();

  const navLinks = useMemo(
    () =>
      isPaymentOptionEnabled
        ? makeDefaultNavLinks(pageComplete, organizationId)
        : makeDefaultNavLinksWithNoPaymentOption(pageComplete, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  const toNextPage = () => {
    isPaymentOptionEnabled ? nav.toPaymentInfo() : nav.toConfirmation();
  };

  const goBack = () => {
    nav.toPatientEligibility();
  };

  if (!selectedServices.length && !selectedLocationId) {
    nav.resetToFirstPage();

    return null;
  }

  return (
    <>
      <PageWrapper showNavMenu navLinks={navLinks}>
        {loading && <ActivityIndicator className="loader--clinic" />}
        <PatientInformation
          {...PatientInfoProps}
          toNextPage={toNextPage}
          goBack={goBack}
          organizationId={organizationId}
          appointmentId={appointmentId}
          isFollowup={false}
          disableEdit={false}
          disabledFieldStatus={{}}
        />
      </PageWrapper>
    </>
  );
};
