import React from 'react';
import { HelpfulResourceListContainer } from '../HelpfulResource/HelpfulResourceListContainer';

import { ApplicationList } from './ApplicationList';

export const ApplicationListContainer = (props) => {
  return (
    <div className="applinks__container">
      <div className="welcome-page__title">Here are your app links</div>
      <ApplicationList usefulLinks={props.usefulLinks} />
      <HelpfulResourceListContainer usefulLinks={props.usefulLinks} />
    </div>
  );
};
