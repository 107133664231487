import React from 'react';

import FormInput from '../../../../common/FormInput';
import FormErrors from '../../../../common/FormErrors';
import AlertMessage from '../../../common/AlertMessage';

import {
  ShowAsRequiredFields,
  PatientGuardianInfoKeys,
} from 'constants/patientInfo';
import { getString } from '../../../../../util/lang';
import { guardianConsentInfo } from '../../../../../constants/externalLinks';

const PatientGuardianInfo = (props) => {
  const { onChangeInput, patientInfo, errors = {} } = props;
  const errorSet = {};

  PatientGuardianInfoKeys.forEach((key) => {
    if (errors[key]) {
      errorSet[key] = errors[key];
    }
  });

  return (
    <section className="form-patient-address section__margin">
      <h2>{getString('guardianInfo')}</h2>
      <AlertMessage
        className="mb-6x"
        isVisible={true}
        type="info"
        message={getString('guardianInfoAlert', {
          here: (
            <a
              href={guardianConsentInfo}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'contents' }}
            >
              {getString('here')}
            </a>
          ),
        })}
        dataqa="guardian-info-alert"
      />

      <div className="patient-form-group">
        <div className="row">
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('firstName')}
              id="guardianFirstName"
              onChange={onChangeInput}
              value={patientInfo.guardianFirstName}
              hasError={!!errorSet['guardianFirstName']}
              required={ShowAsRequiredFields['guardianFirstName']}
              data-qa="guardian-first-name"
            />
          </div>
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('middleName')}
              id="guardianMiddleName"
              onChange={onChangeInput}
              value={patientInfo.guardianMiddleName}
              hasError={!!errorSet['guardianMiddleName']}
              required={ShowAsRequiredFields['guardianMiddleName']}
              data-qa="guardian-middle-name"
            />
          </div>
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('lastName')}
              id="guardianLastName"
              onChange={onChangeInput}
              value={patientInfo.guardianLastName}
              hasError={!!errorSet['guardianLastName']}
              required={ShowAsRequiredFields['guardianLastName']}
              data-qa="guardian-last-name"
            />
          </div>
        </div>
      </div>
      <FormErrors errors={errorSet} />
    </section>
  );
};

export default PatientGuardianInfo;
