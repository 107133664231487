import { useEffect, useState } from 'react';

import cls from 'classnames';
import { UiUser } from 'vyaguta-icons/ui';

import styles from './confirmationCard.module.scss';
import { MessageBtn } from 'components/COVID/common/MessageBtn';
import { ConfirmModal } from 'components/common/ConfirmModal';
import AlertMessage from 'components/COVID/common/AlertMessage';

import { getString } from 'util/lang';
import DateAndTime from 'util/DateAndTime';

const UserInfoBox = ({ user }) => {
  return (
    <div className={styles.userInfo}>
      <div className={styles.userSubHeading}>
        <span className={styles.userIcon}>
          <UiUser size={18} />
        </span>
        <div>
          {user.firstName} {user.lastName}
          {`${user?.birthDay ? ', Age' : ''}`}{' '}
          {user?.birthDay
            ? DateAndTime.getAgeFromDate(
                user.birthDay,
                user.birthMonth,
                user.birthYear,
              )
            : ''}
        </div>
      </div>
      {user.sendEmail && (
        <div className={styles.disclaimer}>
          {getString('groupAppointmentConfirmationDisclaimer')}
        </div>
      )}
    </div>
  );
};

export const GroupConfirmationCard = (props) => {
  const {
    patientInfo = [],
    onBackHandler,
    existingPatientInfo = [],
    organizationName,
    setPatientRecordInfo,
  } = props;

  const [checkBoxState, setCheckBoxState] = useState({});
  const [showMergeModal, setShowMergeModel] = useState(false);

  useEffect(() => {
    const INITIAL_CHECKBOX_STATE = [...existingPatientInfo].reduce((acc, p) => {
      const key = `${p.firstName}${p.middleName ? `-${p.middleName}` : ''}-${p.lastName}`;
      acc[key] = true;
      return acc;
    }, {});
    setCheckBoxState({ ...INITIAL_CHECKBOX_STATE });
  }, [existingPatientInfo]);

  const onCheckBoxClick = (key, value) => {
    setCheckBoxState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  return (
    <>
      <div className={styles.cardWrapper}>
        <div className={styles.header}>
          <h2 tabIndex="0" className="mb-1x ">
            {getString('groupAppointment')}
          </h2>
          <MessageBtn label={'Edit'} onClick={onBackHandler} />
        </div>
        <p>{getString('groupAppointmentConfirmationText')}</p>

        {/* Conditional rendering of alert message if some existing patient */}
        {existingPatientInfo.length > 0 && (
          <AlertMessage
            type="info"
            isVisible={true}
            className="my-2x"
            message={
              <div className={styles.alertMessage}>
                {getString('mergeAlert', {
                  newLine: <br></br>,
                  organizationName: <b>{organizationName}</b>,
                  linkElement: (
                    <span
                      className={styles.clickText}
                      onClick={() => {
                        setShowMergeModel(true);
                      }}
                    >
                      {getString('clickHere')}
                    </span>
                  ),
                })}
              </div>
            }
          />
        )}

        <div className={styles.usersInfo}>
          {patientInfo.map((patient, idx) => {
            return <UserInfoBox key={idx} user={patient} />;
          })}
        </div>
        {/* Merge Patients Modal Popup */}
        {showMergeModal && (
          <ConfirmModal
            show={showMergeModal}
            onCancel={() => {
              setShowMergeModel(false);
            }}
            onConfirm={() => {
              setPatientRecordInfo(checkBoxState);
              setShowMergeModel(false);
            }}
            headerContent={
              <div className={styles.modalHeader}>New Patient?</div>
            }
            bodyContent={
              <div className={cls(styles.modalContent)}>
                <div>{getString('mergeModalContent')}</div>
                <div className={styles.modalInputWrapper}>
                  {/* Render checkbox only for existing patients */}
                  {[...existingPatientInfo].map((p, i) => {
                    return (
                      <div
                        key={i}
                        className={cls('custom-check', styles.modalInput)}
                      >
                        <input
                          tabIndex="0"
                          autoComplete="chrome-off"
                          type="checkbox"
                          checked={
                            checkBoxState[
                              `${p.firstName}${p.middleName ? `-${p.middleName}` : ''}-${p.lastName}`
                            ]
                          }
                          id={
                            p.firstName + p.middleName
                              ? `-${p.middleName}-`
                              : '-' + p.lastName
                          }
                          data-qa="marketing-notification-consent"
                          onChange={(e) =>
                            onCheckBoxClick(
                              `${p.firstName}${p.middleName ? `-${p.middleName}` : ''}-${p.lastName}`,
                              e.target.checked,
                            )
                          }
                        />
                        <label htmlFor="marketingNotificationConsent">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                          {`${p.firstName}${p.middleName ? ` ${p.middleName} ` : ' '}${p.lastName}`}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            }
            cancelText={getString('no')}
            confirmText={getString('yes')}
          />
        )}
      </div>
    </>
  );
};
