import React from 'react';

import { getString } from 'util/lang';

const ConfirmationCode = ({ code }) => {
  return (
    <div className="success-content border-bottom">
      <div className="confirmation-code">
        <h3 tabIndex="0" className="text-bold">
          {getString('confirmationCodeHeading')}
        </h3>
        <p tabIndex="0">{getString('confirmationCodeMessage')}</p>
        <div
          tabIndex="0"
          className="conformation-code-box mt-8x color-primary-base"
        >
          {code}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationCode;
