import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { DetailContext } from '../../../context';

/**
 * @typedef {{
 *  includeDates: Date[]
 *  selected?: Date
 *  onChange: (date: Date) => void
 *  minDate: Date
 *  maxDate: Date
 *  dataqa?: string
 * }} Props
 *
 * @param {Props} props
 */
const Calender = (props) => {
  const { includeDates, selected, onChange, minDate, maxDate, dataqa } = props;
  const {
    details: { appLanguageCode },
  } = React.useContext(DetailContext);
  return (
    <DatePicker
      selected={selected}
      disabledKeyboardNavigation={false}
      onChange={onChange}
      includeDates={includeDates}
      minDate={minDate}
      maxDate={maxDate}
      locale={appLanguageCode}
      inline
      data-qa={dataqa}
    />
  );
};

export default Calender;
