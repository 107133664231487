import React, { useState } from 'react';

import Button from '../../../../common/Button';
import RescheduleModal from './RescheduleModal';
import WaitListNotice from '../presentation/WaitListNotice';

import { compareStrings } from 'util/string';
import { getString } from '../../../../../util/lang';
import * as DateTimeUtil from '../../../../../util/DateAndTime';
import { GoogleMapUrl } from '../../../../../constants/success';
import DoseNumber from '../../../../../constants/covid/doseNumber';
import { iconCheckCircle, iconTimesCircle } from '../../../../../assets/images';
import AlertMessage from 'components/COVID/common/AlertMessage';

const RegistrationDetails = (props) => {
  const [showReschedule, setShowReschedule] = useState(false);

  const {
    dosNumber = '',
    activityid,
    showConfirmDialogBox = false,
    onReschedule,
    firstDoseDate,
    isWaitlisted,
    cancelled,
    appointment,
    selectedClinic,
    serviceConfirmationMessage,
    selections,
    isGroupAppointment,
    patientCount,
    groupAppointmentId,
    rescheduleGroupAppointment,
  } = props;

  const time = DateTimeUtil.convertTo12HourTimeFormatWithAmPm(appointment.time);
  const date = DateTimeUtil.dateFormatShort(appointment.date);
  const clinic = appointment.clinic || selectedClinic;

  const locationLink = `${GoogleMapUrl}${clinic?.latitude},${clinic?.longitude}`;

  const location = [
    clinic?.name,
    clinic?.address,
    clinic?.city,
    clinic?.state,
    clinic?.postalCode,
  ]
    .filter((i) => i)
    .join(', ');

  const serviceNames = selections.map((selection) => {
    const subServiceName = selection.subService.name;
    const isNotSure =
      compareStrings(subServiceName, 'i am not sure') ||
      compareStrings(subServiceName, "i'm not sure");
    return isNotSure ? selection.service.name : subServiceName;
  });

  const primaryServiceRegimenType = selections[0].service.regimenType;

  const headerContent = (
    <h4 tabIndex="0" className="color-primary-base mb-1x">
      {serviceNames.length > 1 ||
      primaryServiceRegimenType === 'ONE_DOSE_SERIES' ? (
        <>{getString('singleDoseReschedule', serviceNames.join(' and '))} </>
      ) : appointment.doseNumber === DoseNumber.FirstDose ? (
        <>{getString('firstDoseReschedule', serviceNames[0])} </>
      ) : (
        <>{getString('secondDoseReschedule', serviceNames[0])} </>
      )}
    </h4>
  );

  const onRescheduleButtonClick = async () => {
    setShowReschedule(false);
    if (isGroupAppointment) {
      rescheduleGroupAppointment(groupAppointmentId);
    } else {
      onReschedule(activityid);
    }
  };

  return (
    <div className="success-content border-bottom">
      {isWaitlisted ? (
        <>
          <p className="success-message success-message--covid">
            {getString(
              'waitListedDescription',
              <>
                <br />
                <strong className="color-black-base">
                  {dosNumber} {getString('dose')}{' '}
                </strong>
              </>,
              <>
                <br />
                <strong className="color-grey-text">
                  {getString('dateAtTime', date, time)}
                </strong>
              </>,
              <a
                href={locationLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-bold link-noUnderline"
              >
                {location}
              </a>,
            )}
          </p>
          <WaitListNotice />
        </>
      ) : (
        <>
          <p tabIndex="0" className="success-message success-message--covid">
            {getString(
              isGroupAppointment
                ? 'groupAppointmentConfirmationMessage'
                : 'appointmentConfirmationMessage',
              {
                serviceName: (
                  <>
                    {serviceNames.map((name, idx) => (
                      <React.Fragment key={idx}>
                        <strong className="color-black-base" tabIndex="0">
                          {name}
                        </strong>
                        {idx === serviceNames.length - 1
                          ? ''
                          : idx === serviceNames.length - 2
                            ? ' and '
                            : ', '}
                      </React.Fragment>
                    ))}
                  </>
                ),
                dateAndTime: (
                  <>
                    <br />
                    <strong tabIndex="0" className="color-grey-text">
                      {getString('dateAtTime', date, time)}
                    </strong>
                    <br />
                  </>
                ),
                location: (
                  <a
                    href={locationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-bold link-noUnderline"
                  >
                    {location}
                  </a>
                ),
                patientCount: (
                  <b>
                    {patientCount} {getString('patient')}
                  </b>
                ),
              },
            )}{' '}
          </p>
          {appointment.fulfilled && (
            <div className="text-center mt-7x">
              <span className="pill__sm pill__sm--icon pill__sm--success">
                <img
                  src={iconCheckCircle}
                  alt="Check Circle"
                  className="mr-3x"
                />
                <span tabIndex="0">{getString('fulfilled')}</span>
              </span>
            </div>
          )}
          {cancelled && (
            <div className="text-center mt-7x">
              <span className="pill__sm pill__sm--icon pill__sm--danger">
                <img
                  src={iconTimesCircle}
                  alt="Check Circle"
                  className="mr-3x"
                />
                <span tabIndex="0">{getString('cancelled')}</span>
              </span>
            </div>
          )}
          {appointment.noShow && (
            <div className="text-center mt-7x">
              <span className="pill__sm pill__sm--icon pill__sm--danger">
                <img
                  src={iconTimesCircle}
                  alt="Check Circle"
                  className="mr-3x"
                />
                <span tabIndex="0">{getString('noShow')}</span>
              </span>
            </div>
          )}
          {appointment.canBeRescheduled && (
            <Button
              onClick={
                showConfirmDialogBox
                  ? () => setShowReschedule(true)
                  : () => {
                      onReschedule(activityid);
                    }
              }
              className="btn btn--large btn-primary--outline mt-7x"
              label={getString('rescheduleButton')}
              isEnabled
              dataqa="reschedule-button"
            />
          )}
        </>
      )}
      {showReschedule && (
        <RescheduleModal
          closeModal={() => setShowReschedule(false)}
          headerContent={headerContent}
          onReschedule={onRescheduleButtonClick}
          dosNumber={dosNumber}
          firstDoseDate={firstDoseDate}
          vaccineDetails={selections[0].subService}
        />
      )}
      {serviceConfirmationMessage && (
        <AlertMessage
          type="info"
          className={'col-12 mb-5x'}
          message={serviceConfirmationMessage}
          isVisible={true}
        />
      )}
    </div>
  );
};

export default RegistrationDetails;
