import { useDetailContext } from 'context';
import { useMemo } from 'react';
import {
  composeFilters,
  excludeDoubleDose,
  filterByDefaultStatus,
  filterSubservicesByAge,
  passThrough,
} from 'util/serviceFilter';

export const usePrimaryServiceTypes = () => {
  const {
    details: {
      serviceTypes,
      services,
      subServices,
      patientInfo,
      selectedServices,
    },
  } = useDetailContext();

  const serviceTypesWithServices = useMemo(() => {
    const filterEligibleSubServices = composeFilters(
      selectedServices.length > 1 ? excludeDoubleDose : passThrough,
      filterSubservicesByAge(patientInfo),
      filterByDefaultStatus,
    );
    return serviceTypes.allIds.map((id) => {
      const serviceType = serviceTypes.byId[id];
      const ownServices = serviceType.services
        .map((id) => services.byId[id])
        .filter((i) => i)
        .map((service) => {
          const ownSubServices = service.subServices
            .map((id) => subServices.byId[id])
            .filter((i) => i);
          const eligibleSubServices = filterEligibleSubServices(ownSubServices);

          return {
            ...service,
            isEligible: eligibleSubServices.length > 0,
          };
        });
      return {
        ...serviceType,
        services: ownServices,
      };
    });
  }, [
    serviceTypes.allIds,
    serviceTypes.byId,
    services.byId,
    subServices.byId,
    selectedServices,
    patientInfo,
  ]);

  return serviceTypesWithServices;
};
