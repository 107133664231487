import React from 'react';
import { FiAlertTriangle } from 'vyaguta-icons/fi';

const FormErrors = ({ errors }) => {
  const allErrors = Object.keys(errors).filter((key) => errors[key]);

  if (allErrors.length === 0) {
    return null;
  }
  return (
    <div className="form-error">
      <div className="alert alert--danger alert-flu-shot">
        <FiAlertTriangle size={24} className="icon" />
        <ul>
          {allErrors.map((key, index) => {
            return (
              <li data-qa={`form-error-${index}`} tabIndex="0" key={key}>
                {errors[key]}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FormErrors;
