import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { Success } from 'components/COVID/views/Success/container/Success';

export const RescheduleSuccess = () => {
  return (
    <PageWrapper>
      <Success />
    </PageWrapper>
  );
};
