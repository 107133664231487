import React from 'react';

import FormInput from 'components/common/FormInput';
import { useDebounce } from 'hooks/useDebounce';
import { useAddressSearch } from 'hooks/useAddressSearch';
import { getString } from 'util/lang';
import { AddressSuggestion } from './AddressSuggestion';

const HomeAddressAutofill = ({
  id,
  value,
  onChange,
  onAddressSelect,
  hasError,
  required,
  disableInput,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [shouldShowSuggestions, setShouldShowSuggestions] =
    React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);

  const { suggest, retrieve } = useAddressSearch();
  const debouncedValue = useDebounce(searchTerm, 500);

  const isValidSearchTerm = (searchTerm) =>
    searchTerm.length > 4 || searchTerm.split(' ').length > 1;

  const fetchSuggestions = async (searchValue) => {
    const suggestedAddress = await suggest(
      { homeAddress: searchValue },
      { limit: 5 },
    );
    if (suggestedAddress) {
      setSuggestions(suggestedAddress);
    }
  };
  React.useEffect(() => {
    const shouldSearch = isValidSearchTerm(debouncedValue);
    if (shouldSearch && !shouldShowSuggestions) {
      setShouldShowSuggestions(true);
    }
    if (shouldSearch && shouldShowSuggestions) {
      fetchSuggestions(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleRetrieve = (res) => {
    if (!res?.features) return;
    const feature = res.features?.[0];
    if (!feature) return;
    const selectedAddress = {
      placeName: feature.properties.place_name,
      homeAddress: feature.properties.address_line1,
      city: '',
      state: '',
      zipCode: '',
      county: '',
    };
    for (let i = 0; i < feature.properties.context.length; i++) {
      if (feature.properties.context[i].id.includes('place')) {
        selectedAddress.city = feature.properties.context[i].text;
      }
      if (feature.properties.context[i].id.includes('postcode')) {
        selectedAddress.zipCode = feature.properties.context[i].text;
      }
      if (feature.properties.context[i].id.includes('region')) {
        const stateCode =
          feature.properties.context[i].short_code.split('-')[1]; // US-CA
        selectedAddress.state = stateCode;
      }
      if (feature.properties.context[i].id.includes('district')) {
        selectedAddress.county = feature.properties.context[i].text?.replace(
          ' County',
          '',
        );
      }
    }
    onAddressSelect(selectedAddress, feature);
  };

  const handleSelect = async (suggestion) => {
    const result = await retrieve(suggestion.actionId);
    setShouldShowSuggestions(false);
    handleRetrieve(result);
  };

  const handleChange = (id, value) => {
    onChange(id, value);
    setSearchTerm(value.trim());
  };

  const handleFocus = () => {
    setShouldShowSuggestions(true);
  };

  const handleLostFocus = () => {
    // delay to support touch devices to propagate event
    setTimeout(() => {
      setShouldShowSuggestions(false);
    }, 300);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setShouldShowSuggestions(false);
    }
  };

  return (
    <>
      <FormInput
        label={getString('homeAddress')}
        id={id || 'homeAddress'}
        autoComplete="off"
        onChange={handleChange}
        value={value}
        hasError={hasError}
        required={required}
        disableInput={disableInput}
        onFocus={handleFocus}
        onBlur={handleLostFocus}
        onKeyDown={handleKeyDown}
        data-qa="home-address"
      />
      <AddressSuggestion
        suggestions={suggestions}
        onSelect={handleSelect}
        isFocused={shouldShowSuggestions}
      />
    </>
  );
};

export { HomeAddressAutofill };
