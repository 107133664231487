import React from 'react';
import { UiCheck } from 'vyaguta-icons/ui';
import { FiAlertCircle, FiAlertTriangle, FiInfo } from 'vyaguta-icons/fi';

const alertTypeToIconMap = {
  success: <UiCheck size={24} className="mr-2x" />,
  danger: <FiAlertTriangle size={24} strokeWidth={1.5} className="mr-4x" />,
  warning: <FiAlertCircle size={24} strokeWidth={1.5} className="mr-4x" />,
  info: <FiInfo size={24} strokeWidth={1.5} className="mr-4x" />,
};

/**
 * Alert message for radio button forms
 * @param {{
 *  message: string
 *  isVisible?: boolean
 *  className?: string
 *  type: keyof typeof alertTypeToIconMap
 *  dataqa?: string
 * }} props
 * @returns
 */
const AlertMessage = (props) => {
  const { message, type, className, isVisible, dataqa } = props;
  if (!isVisible) {
    return null;
  }

  return (
    <div
      data-qa={dataqa}
      className={`alert alert--message alert--${type} ${className ? className : ''}`}
      role="alert"
    >
      <div className="d-flex">
        <span className="alert__icon">{alertTypeToIconMap[type]}</span>
        <div className="alert__message">{message}</div>
      </div>
    </div>
  );
};

export default AlertMessage;
