import styles from './YesNoCheckboxes.module.scss';

/**
 * Returns the value to set in the checked attribute for Yes
 *
 * @param {boolean} checked
 * @returns 'checked' | ''
 */
const getCheckedValue = (checked) => {
  if (checked) return 'checked';

  return '';
};

/**
 * Returns the value to set in the checked attribute for No
 *
 * @param {boolean} checked
 * @returns 'checked' | ''
 */
const getOppositeCheckedValue = (checked) => {
  if (checked === false) return 'checked';

  return '';
};

/**
 * Testing Checklists - Product Setting and Testing Form - Delivery Ticket - DME
 * @param {string} name
 * @param {string} yesKey
 * @param {string} noKey
 * @param {boolean} checked
 * @param {func} onCheck
 */
const YesNoCheckboxes = ({
  name,
  yesKey,
  noKey,
  checked,
  onCheck,
  disabled = false,
}) => {
  return (
    <div className={styles.radioWrap}>
      <span className={styles.radio}>
        <input
          autoComplete="off"
          type="radio"
          name={name}
          id={yesKey}
          value={checked || ''}
          disabled={disabled}
          checked={getCheckedValue(checked)}
          className={styles.radioInput}
          onChange={() => onCheck(true)}
        />
        <label htmlFor={yesKey}>
          <span className={styles.radioText}>Yes</span>
        </label>
      </span>
      <span className={styles.radio}>
        <input
          autoComplete="off"
          type="radio"
          name={name}
          id={noKey}
          value={!checked}
          disabled={disabled}
          checked={getOppositeCheckedValue(checked)}
          className={styles.radioInput}
          onChange={() => onCheck(false)}
        />
        <label className={styles.radioText} htmlFor={noKey}>
          <span>No</span>
        </label>
      </span>
    </div>
  );
};

export default YesNoCheckboxes;
