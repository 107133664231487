import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SelectLocationSkeletonLoader = (props) => {
  const { shouldShowMap } = props;

  const clinicContainerClass = shouldShowMap ? 'col-6-lg' : 'col-8-lg';

  return (
    <>
      <div
        className={
          clinicContainerClass + ' pr-0x-lg choose-clinic-location__container'
        }
      >
        <div className="my-2x">
          <Skeleton width="250px" height="30px" />
        </div>
        <div className="row justify-content-between p-2x pb-0x mt-2x">
          <Skeleton height="20px" width="200px" />
          <Skeleton height="20px" width="100px" />
        </div>

        <div className="skeleton__inner">
          <div className="row justify-content-between">
            <div className="col-6 py-2x clinic-item">
              <Skeleton height="140px" />
            </div>
            <div className="col-6 py-2x clinic-item">
              <Skeleton height="140px" />
            </div>
            <div className="col-6 py-2x clinic-item">
              <Skeleton height="140px" />
            </div>
            <div className="col-6 py-2x clinic-item">
              <Skeleton height="140px" />
            </div>
          </div>
        </div>

        <Skeleton width="100%" height="60px" />
      </div>
      {shouldShowMap && (
        <div className="col-6-lg d-none d-lg-block pr-0x-lg">
          <Skeleton width="100" height="700px" />
        </div>
      )}
    </>
  );
};

export default SelectLocationSkeletonLoader;
