import React from 'react';
import { getString } from 'util/lang';
import { FiInfo } from 'vyaguta-icons/fi';

import Button from 'components/common/Button';
import MessageListBox from 'components/COVID/common/MessageListBox';

const NoConfirmationContent = (props) => {
  const { contactEmail, contactPhone, message, goToHomePage } = props;
  const messages = [
    { title: 'Phone number', message: contactPhone },
    { title: 'Email address', message: contactEmail },
  ];
  return (
    <div className="selected-details">
      <div className="success-content border-bottom">
        <FiInfo size={24} className="success-check info" />
        {message && (
          <p tabIndex="0" className="mb-6x">
            {message}
          </p>
        )}
        <Button
          isEnabled
          label={getString('bookAppointment')}
          onClick={goToHomePage}
          className="btn btn--large btn-primary"
          dataqa="goto-homepage"
        />
      </div>
      <div className="success-content">
        <h3 className="clinicContactInfo">
          {getString('clinicContactInformation')}
        </h3>
        <MessageListBox messages={messages} className="messageBox" />
      </div>
    </div>
  );
};

export default NoConfirmationContent;
