import { useCallback, useMemo } from 'react';
import { useDetailContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { makeGroupRegisterNavLinks } from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import ConfirmationAndSubmission from 'components/COVID/views/Confirmation/container/Confirmation';
import { useEmailConfirmationForGroupAppointment } from '../../../../../context/useGroupAppointmentSubmission';

export const GroupRegisterConfirmation = () => {
  const {
    details: { pageComplete },
  } = useDetailContext();
  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();
  const { submitAppointment } = useEmailConfirmationForGroupAppointment();

  const toNextPage = useCallback(() => {
    nav.toGroup.register.success(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      makeGroupRegisterNavLinks(pageComplete, appointmentId, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  const goBack = useCallback(() => nav.goBack(), [nav]);

  const updateAppointment = useCallback(() => {
    return submitAppointment(appointmentId, true);
  }, [submitAppointment, appointmentId]);

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ConfirmationAndSubmission
        confirmAndSubmit={updateAppointment}
        toNextPage={toNextPage}
        goBack={goBack}
        disableEditSchedule
        disableEditService
      />
    </PageWrapper>
  );
};
