import React, { createRef } from 'react';

import CancelModal from '../presentation/CancelModal';
import * as toast from 'util/toast';
import {
  DetailContext,
  useApplicationContext,
  useDetailContext,
} from 'context';
import { handleError } from 'util/errorHandler';
import ActivityIndicator from 'components/common/ActivityIndicator';
import { SecurityCheck } from 'components/COVID/views/SecurityCheck/SecurityCheck';
import { getString } from 'util/lang';
import ConfirmationContent from './ConfirmationContent';
import CONFIG from '../../../../../config';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useAppointmentCancel } from 'context/useAppointmentCancel';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useAppParams } from 'context/useAppParams';
import { replaceArrayElementByIndex } from 'util/array';
import { changeTitle } from 'util/siteInfo';

// TODO: remove class and merge logic inside Success component
class SuccessCls extends React.Component {
  constructor(props) {
    super(props);
    this.mainContentRef = createRef();

    this.state = {
      showCancelModal: false,
      // This is used to track security check for non-reschedule type when cancelling appointment
      showSecurityCheckForCancel: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    changeTitle('Success');

    const { rescheduleComponent } = this.props;

    const { setAppointmentSuccess } = this.context;
    const { firstPageLoaded } = this.context.details;
    setAppointmentSuccess(true);

    if (rescheduleComponent && !firstPageLoaded) {
      this.context.setDetails({
        firstPageLoaded: true,
      });

      return;
    }

    if (!firstPageLoaded) {
      this.props.nav.resetToFirstPage();
    }
  }

  setShowCancelModal = (value) => {
    this.setState({ showCancelModal: value });
  };

  cancelAppointment = async (captchaResponseKey) => {
    const { cancelAppointment, appointmentId } = this.props;
    const { details } = this.context;

    if (!appointmentId)
      handleError({ message: getString('appointmentIdNotFound') });

    this.setShowCancelModal(false);

    try {
      this.context.setLoading(true);
      await cancelAppointment(
        appointmentId,
        captchaResponseKey,
        details.security.token,
      );
      this.setState({ showSecurityCheckForCancel: false });
      // Ask for security question again on a successful action
      this.context.setSecurityChecked(false);
      this.context.setSecurityToken(undefined);
      const route = '/cancel';
      this.props.history.replace({
        pathname: route,
        search: window.location.search,
      });
    } catch (err) {
      toast.error({
        title: '',
        message: err?.response?.data?.error?.message || err.message,
      });
      CONFIG.captchaEnabled && window.grecaptcha.reset();
    } finally {
      this.context.setLoading(false);
    }
  };

  setSecurityChecked = ({ token, details: patientInfo }) => {
    const navState = this.props.nav.getNavigationState();
    const { appointmentId } = this.props;

    this.context.setSecurityChecked(true);

    const { currentPatientIndex, patientInfo: globalPatientInfo } =
      this.context.details ?? 0;
    const updatedGlobalPatientInfo = replaceArrayElementByIndex(
      globalPatientInfo,
      currentPatientIndex,
      {
        ...globalPatientInfo[currentPatientIndex],
        firstName: patientInfo.firstName,
        middleName: patientInfo.middleName,
        lastName: patientInfo.lastName,
        birthMonth: patientInfo.birthMonth,
        birthDay: patientInfo.birthDay,
        birthYear: patientInfo.birthYear,
      },
    );

    this.context.setDetails({
      patientInfo: updatedGlobalPatientInfo,
    });
    this.context.setSecurityToken(token);

    if (navState?.isReschedule) {
      return this.props.nav.toRescheduleAppointment(appointmentId);
    }

    if (navState?.isCancel) {
      return this.setShowCancelModal(true);
    }
  };

  onCancelAppointmentButtonClick = () => {
    const { isSecurityChecked } = this.context.details;
    const { appointmentId } = this.props;

    if (!isSecurityChecked) {
      this.props.nav.toCancel(appointmentId);

      return;
    }

    this.setShowCancelModal(true);
  };

  render() {
    const { firstPageLoaded } = this.context.details;

    const { showCancelModal } = this.state;

    const { rescheduleComponent, appointmentId } = this.props;

    const { isSecurityChecked } = this.context;

    if (!firstPageLoaded)
      return (
        <div className="success">
          <section className="selected-details section__margin">
            <div className="border border-radius section__container p-0x">
              <ActivityIndicator className="loader--date-time" />
            </div>
          </section>
        </div>
      );

    if (rescheduleComponent && !isSecurityChecked) {
      return (
        <SecurityCheck
          onSecurityVerified={this.setSecurityChecked}
          activityId={appointmentId}
        />
      );
    }

    return (
      <>
        <ConfirmationContent
          isRescheduleComponent={rescheduleComponent}
          onCancelButtonClick={this.onCancelAppointmentButtonClick}
        />
        {showCancelModal && (
          <CancelModal
            closeModal={() => this.setShowCancelModal(false)}
            onCancelButtonClick={this.cancelAppointment}
          />
        )}
      </>
    );
  }
}

SuccessCls.contextType = DetailContext;

export const Success = (props) => {
  changeTitle('Success');
  const nav = useNavigationHelper();
  const { cancelAppointment } = useAppointmentCancel();
  const { appointmentId: paramAppointmentId } = useAppParams();
  const {
    details: { appointments },
  } = useDetailContext();

  const appointmentId =
    paramAppointmentId ||
    appointments[0].activityid ||
    appointments[0].firstAppointment?.activityid ||
    appointments[0].secondAppointment?.activityid;

  return (
    <SuccessCls
      {...props}
      nav={nav}
      cancelAppointment={cancelAppointment}
      appointmentId={appointmentId}
    />
  );
};

const SuccessPage = (props) => {
  return (
    <PageWrapper>
      <Success {...props} />
    </PageWrapper>
  );
};

export default SuccessPage;
