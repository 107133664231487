import * as Yup from 'yup';
import regexPattern from '../../../../../constants/regexPattern';
import { getString } from '../../../../../util/lang';

export const validateRegistartionCode = (formValues) => {
  let errors = {};
  let isValid = true;

  Object.keys(formValues).forEach(async (key) => {
    let value = formValues[key];

    try {
      CodeRegistrationSchema()[key].validateSync(value);
      errors[key] = null;
    } catch (error) {
      isValid = false;
      errors[key] = error.message;
    }
  });

  return {
    errors,
    isValid,
  };
};

// Here schema is created as a function so that it can be triggered when the languages change
const CodeRegistrationSchema = () => ({
  registrationCode: Yup.string()
    .trim()
    .matches(
      regexPattern.codeField,
      getString('codeRegistrationValidationMessage'),
    )
    .min(8, getString('codeRegistrationValidationMessageLength'))
    .max(8, getString('codeRegistrationValidationMessageLength')),
});
