import React from 'react';

import { getString } from 'util/lang';
import WarningIcon from 'svg-images/WarningIcon';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import MessageListBox from 'components/COVID/common/MessageListBox';
import { useAppSettings, useDetailContext } from 'context';
import { changeTitle } from 'util/siteInfo';

const WaitlistNotice = () => {
  changeTitle('Wait List');
  const { organizationContactPhone, organizationContactEmail } =
    useAppSettings();
  const {
    details: { selectedServices },
  } = useDetailContext();

  const nav = useNavigationHelper();

  const toNextPage = nav.toWaitlist.screening();
  const toEditService = () => nav.toEditService();

  const messages = [
    { title: 'Phone number', message: organizationContactPhone },
    { title: 'Email address', message: organizationContactEmail },
  ];

  if (!selectedServices.length) {
    nav.resetToFirstPage();

    return null;
  }

  return (
    <div className="section__nosites">
      <div className="section__nosites__info">
        <WarningIcon />
        <h2>{getString('noClinicsAvailable')}</h2>
      </div>
      <p className="text-center label">
        {getString('waitlistNoticeInClinicPage', <b>waitlist</b>)}
      </p>
      <div className="section-title mt-6x">
        {getString('clinicContactInformation')}:
      </div>
      <MessageListBox messages={messages} />
      <button className="btn btn-primary btn-block mt-5x" onClick={toNextPage}>
        {getString('joinWaitList')}
      </button>
      <button
        className="btn btn-primary--outline btn-block mt-5x"
        onClick={toEditService}
      >
        {getString('selectDifferentService')}
      </button>
    </div>
  );
};

export default WaitlistNotice;
