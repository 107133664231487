import React from 'react';

export const HelpfulResource = (props) => {
  const { img_src, title, alt_text, className, link, linkTarget } =
    props.resource;

  return (
    <a href={link} target={linkTarget} tabIndex="0">
      <button className={`btn ${className}`} tabIndex="-1">
        <span className="btn-icon" tabIndex="-1">
          <img src={img_src} alt={alt_text} />
        </span>{' '}
        {title}
      </button>
    </a>
  );
};
