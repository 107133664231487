import { getString } from 'util/lang';
import { Text, View } from '@react-pdf/renderer';

import { useStyles } from '../ABNFormPdf.styles';

export const ABNFormTable = ({
  abnData,
  productHcpcs,
  reason,
  estimatedCost,
}) => {
  const styles = useStyles;
  const title = getString(abnData).title;
  return (
    <View style={styles.table}>
      <View style={[styles.rowTable, styles.tableHeader]}>
        <Text style={[styles.cell, styles.flex3]}>D.</Text>
        <Text style={[styles.cell, styles.flex3]}>
          {getString('dme.documentation.abnForm.fields.reasonLabel', { title })}
        </Text>
        <Text style={[styles.cell, styles.flex2, styles.borderRight0]}>
          {getString('dme.documentation.abnForm.fields.estimatedCostLabel')}
        </Text>
      </View>
      <View style={styles.rowTable}>
        <Text style={[styles.cell, styles.flex3]}>{productHcpcs}</Text>
        <Text style={[styles.cell, styles.flex3]}>{reason}</Text>
        <Text style={[styles.cell, styles.flex2, styles.borderRight0]}>
          {estimatedCost ? `$ ${estimatedCost}` : ''}
        </Text>
      </View>
    </View>
  );
};
