import React from 'react';

import Modal from '../../../common/Modal';
import Button from '../../../../common/Button';
import { getString } from '../../../../../util/lang';

const RescheduleModal = ({ closeModal, headerContent, onReschedule }) => {
  return (
    <Modal
      close={closeModal}
      containerClassName="confirmation-modal"
      headerContent={headerContent}
    >
      <div className="modal__footer text-right border-top d-flex justify-content-between flex-wrap">
        <Button
          label={getString('cancel')}
          className="btn btn--large btn-primary--outline"
          isEnabled
          onClick={closeModal}
          dataqa="close-reschedule-appt"
        />
        <Button
          label={getString('confirmReschedule')}
          className="btn btn--large btn-primary"
          isEnabled
          onClick={onReschedule}
          dataqa="confirm-reschedule-appt"
        />
      </div>
    </Modal>
  );
};

export default RescheduleModal;
