export const AppointmentStatuses = {
  PROPOSED: 935000000,
  PENDING: 935000001,
  BOOKED: 935000002,
  ARRIVED: 935000003,
  FULFILLED: 935000004,
  CANCELLED: 935000005,
  NO_SHOW: 935000006,
  ENTERED_IN_ERROR: 935000007,
  CHECKED_IN: 935000008,
  WAITLIST: 935000009,
};

export const APPOINTMENT_TOKEN = 'APPOINTMENT_TOKEN';
