import { getString } from '../../../../../util/lang';
import styles from './AddPatientInformation.module.scss';

export const AddPatientInformation = () => {
  return (
    <div className="mb-6x">
      <div className={styles.AddPatientInformationTitle}>
        {getString('noOfPatientInformationTitle')}
      </div>
      <div>{getString('addInformationTitle')}</div>
    </div>
  );
};
