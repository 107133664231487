import config from '../config';
import * as http from '../util/http';

export async function fetchLanguages() {
  const url = config.entity.languages;

  const { data } = await http.get(url);

  return data;
}
