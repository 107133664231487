import { useEffect } from 'react';
import { FiCheck } from 'vyaguta-icons/fi';

import { getString } from 'util/lang';

import TextArea from 'components/common/Textarea/LabelledTextArea';
import FormInput from 'components/common/FormInput/LabelledFormInput';
import SelectBox from 'components/common/SelectBox/LabelledSelectBox';
import { proofOfDelivery } from '../../../validation/AddDeliveryTicket';
import { BeneficiaryRelation } from 'constants/productOrder';

const LABEL_PREFIX = 'dme.deliveryTicket.proofOfAcknowledgement';

const proofOfDeliveryChecklist = [
  {
    key: 'equipmentProperlyFitted',
    question: getString(`${LABEL_PREFIX}.listFirst`),
  },
  {
    key: 'receivedWrittenInstruction',
    question: getString(`${LABEL_PREFIX}.listSecond`),
  },
  {
    key: 'understandReturnsPolicy',
    question: getString(`${LABEL_PREFIX}.listThird`),
  },
];

export const proofKeyToQuestionMap = proofOfDeliveryChecklist.reduce(
  (acc, curr) => {
    const { key, question } = curr;
    acc[key] = question;
    return acc;
  },
  {},
);

export const initialProofValues = proofOfDeliveryChecklist.map(({ key }) => ({
  key,
  checked: false,
}));

const Checklist = ({ questionKey, checked, isEdit, handleOnCheck }) => {
  const question = proofKeyToQuestionMap[questionKey];

  return (
    <div key={questionKey} className={`custom-check px-0x mb-3x`}>
      <input
        autoComplete="off"
        type="checkbox"
        id={questionKey}
        name={questionKey}
        disabled={!isEdit}
        onChange={() => handleOnCheck(questionKey)}
        value={checked}
        checked={checked}
        className="row delivery-ticket__acknowledgement--checkbox"
      />
      <label
        htmlFor={questionKey}
        className="row delivery-ticket__acknowledgement--checkbox_text"
      >
        <FiCheck /> {question}
      </label>
    </div>
  );
};

/**
 * ProofOfDeliveryAcknowledgement component - Delivery Ticket - DME
 * @param {object} proofOfDeliveryData
 * @param {function} setProofOfDeliveryAcknowledgement
 */
const ProofOfDeliveryAcknowledgement = ({
  proofOfDeliveryData,
  setDeliveryTicketHandler,
  isEdit,
}) => {
  const proofValues = proofOfDeliveryData[proofOfDelivery.checklist] || [];

  const handleOnCheck = (key) => {
    setDeliveryTicketHandler(
      {
        ...proofOfDeliveryData,
        [proofOfDelivery.checklist]: proofValues.map((state) =>
          state.key === key ? { ...state, checked: !state.checked } : state,
        ),
      },
      'proofOfDeliveryData',
    );
  };

  useEffect(() => {
    if (proofValues.length) return;

    setDeliveryTicketHandler(
      {
        ...proofOfDeliveryData,
        [proofOfDelivery.checklist]: initialProofValues,
      },
      'proofOfDeliveryData',
    );
  }, [proofValues]);

  return (
    <div className="delivery-ticket__acknowledgement--wrap">
      <div className="delivery-ticket__acknowledgement--title">
        <h3>{getString(`${LABEL_PREFIX}.title`)}</h3>
      </div>
      <p className="delivery-ticket__acknowledgement--text">
        {getString(`${LABEL_PREFIX}.description`)}
      </p>
      <div className="pl-6x">
        {proofValues.map(({ key, checked }) => (
          <Checklist
            key={key}
            questionKey={key}
            checked={checked}
            isEdit={isEdit}
            handleOnCheck={handleOnCheck}
          />
        ))}
      </div>
      <div className="row delivery-ticket__acknowledgement--forms">
        <div className="col-6-md">
          <FormInput
            label={getString(`${LABEL_PREFIX}.recipientName`)}
            id={proofOfDelivery.recipientName}
            value={proofOfDeliveryData.recipientName}
            onChange={(_id, value) => {
              setDeliveryTicketHandler(
                {
                  ...proofOfDeliveryData,
                  [proofOfDelivery.recipientName]: value,
                },
                'proofOfDeliveryData',
              );
            }}
            type="text"
            solo
            fullWidth
            disableInput={!isEdit}
          />
        </div>
        <div className="col-6-md">
          <SelectBox
            label={getString(`${LABEL_PREFIX}.relationshipToBeneficiary.label`)}
            id={proofOfDelivery.relationshipToBeneficiary}
            value={proofOfDeliveryData.relationshipToBeneficiary}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setDeliveryTicketHandler(
                {
                  ...proofOfDeliveryData,
                  [proofOfDelivery.relationshipToBeneficiary]:
                    parseInt(selectedValue),
                },
                'proofOfDeliveryData',
              );
            }}
            options={BeneficiaryRelation}
            defaultValue={getString(
              `${LABEL_PREFIX}.relationshipToBeneficiary.placeholder`,
            )}
            solo
            isDisabled={!isEdit}
          />
        </div>
      </div>
      <div className="col-12-md delivery-ticket__acknowledgement--textarea">
        <TextArea
          label={getString(
            `${LABEL_PREFIX}.reasonBeneficiaryIsUnableToSign.label`,
          )}
          id={proofOfDelivery.reasonBeneficiaryIsUnableToSign}
          onChange={(_id, value) => {
            setDeliveryTicketHandler(
              {
                ...proofOfDeliveryData,
                [proofOfDelivery.reasonBeneficiaryIsUnableToSign]: value,
              },
              'proofOfDeliveryData',
            );
          }}
          value={proofOfDeliveryData.reasonBeneficiaryIsUnableToSign}
          cols={5}
          placeholder={getString(
            `${LABEL_PREFIX}.reasonBeneficiaryIsUnableToSign.placeholder`,
          )}
          solo
          disableInput={!isEdit}
        />
      </div>
    </div>
  );
};

export default ProofOfDeliveryAcknowledgement;
