import { getString } from "util/lang";
import { Text, View } from "@react-pdf/renderer"

import { useStyles } from "../ABNFormPdf.styles";

export const ABNFormTitle = ({ abnData, primaryPayerName }) => {
    const styles = useStyles;
    return (
        <View>
            <Text style={styles.title}>
                {getString('dme.documentation.abnForm.abnNonCoverage')}
            </Text>
            <View style={[styles.note, styles.fontWt700]}>
                <Text style={styles.noteTitle}>NOTE:</Text>
                <Text style={[styles.noteDesc, styles.mt0]}>
                    {getString(`${abnData}.description`)}
                </Text>
            </View>
            <Text style={styles.description}>
                {getString(`${abnData}.note`, { primaryPayerName })}
            </Text>
        </View>
    )
}