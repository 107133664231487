import React from 'react';

import AlertList from '../../../common/AlertList';
import { passCriteria } from 'constants/confirm';
import { CHOICE } from 'constants/screeningQuestions';

/* Render the important information section */
const AlertSection = (props) => {
  const { answers, questions } = props;
  const questionsWithErrors =
    questions && questions.filter((qs) => qs.errorType === 'ERROR');
  const dynamicWarnings = Object.keys(answers)
    .filter((key) => {
      const question = questionsWithErrors.find((qs) => qs.id === key);
      if (!question) return false;

      const passCriteriaKey = passCriteria.find(
        (d) => d.value === question.eligibilityCriteria,
      );
      if (!passCriteriaKey) return false;
      return answers[key].value === passCriteriaKey.key;
    })
    .map((key) => {
      const question = questionsWithErrors.find((qs) => qs.id === key);
      return question.eligibilityFailureMessage;
    });

  let isInfoMissing = false;
  questions.forEach((question) => {
    if (
      question.enableAdditionalQuestion === 'YES' &&
      question.additionalInfoCriteria === CHOICE[answers[question.id]?.value] &&
      !answers[question.id]?.additionalInfo
    ) {
      isInfoMissing = true;
    }
  });

  if (isInfoMissing) {
    dynamicWarnings.push('Please fill in all the details');
  }

  if (dynamicWarnings.length === 0) return null;
  return (
    <AlertList
      messages={dynamicWarnings}
      type="danger"
      isVisible={true}
      className="mb-5x mt-5x"
    />
  );
};

export default AlertSection;
