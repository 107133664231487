import config from '../config';
import { patch } from '../util/http';

/**
 *
 * @param {string} patientId
 * @param {string} hash
 * @param {{
 *  sms?: boolean
 *  email?: boolean
 * }} preferences
 */
export const updatePatientNotificationPreferences = async (
  patientId,
  hash,
  preferences,
) => {
  const url = config.entity.updateContactNotificaiontPreferences(patientId);
  const { data } = await patch(url, {
    hash,
    sms: preferences.sms,
    email: preferences.email,
  });

  return data;
};
