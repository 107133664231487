import React, { Fragment } from 'react';
import parse from 'html-react-parser';

const VaccineInformation = ({ screeningInformation }) => {
  if (!screeningInformation.length) return null;
  return (
    <section
      tabIndex="0"
      className="flu-shot-info section__margin border-radius border"
    >
      <h4>Screening Information</h4>
      {screeningInformation.map((si, idx) => (
        <Fragment key={idx}>
          {screeningInformation.length > 1 && (
            <h6 className="service-name">{si?.service}</h6>
          )}
          <div className="info-section">{parse(si?.screeningInformation)}</div>
        </Fragment>
      ))}
    </section>
  );
};

export default VaccineInformation;
