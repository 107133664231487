import { getString } from '../../../../../util/lang';

import styles from './secondaryServiceInfo.module.scss';
import cls from 'classnames';

const SecondaryServiceInfo = ({ onClickAddService, isVisible }) => {
  if (isVisible) {
    return (
      <div
        className={cls(
          styles.secondaryServiceInfoContainer,
          styles.addAdditionalInfoVisible,
        )}
      >
        <span className={styles.secondaryServiceInfo}>
          {getString('secondaryServiceAlertHeading')}
        </span>
        <button
          onClick={onClickAddService}
          className={styles.addAdditionalServiceButton}
        >
          {getString('secondaryServiceAlertLink')}
        </button>
      </div>
    );
  }
  return <></>;
};

export default SecondaryServiceInfo;
