import React from 'react';
import parse from 'html-react-parser';

const Container = ({ about = '' }) => {
  if (!about) return null;
  return (
    <section
      className="flu-shot-info section__margin border-radius border"
      tabIndex="0"
    >
      {parse(about)}
    </section>
  );
};

export default Container;
