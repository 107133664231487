import config from '../config';
import * as toast from './toast';
import picoModal from 'picomodal';
import { getString } from './lang';

const GENERIC_ERROR =
  'An error has occurred. Please try again later or reach out to our support team for assistance. Thank you.';

export class ToastableError extends Error {
  constructor(message, { title, message: toastMessage } = {}) {
    super(message);
    this.toastTitle = title;
    this.toastMessage = toastMessage;
  }
}

/**
 * Generic error handler to handle error events.
 *
 * @param {object} event
 * @param {{title, message}} options
 */
export function handleError(event, options = {}) {
  if (config.env !== 'production') {
    console.log(event);
  }

  const title = event?.toastTitle || getString('error');
  const message =
    event?.response?.data?.error?.message ||
    event?.toastMessage ||
    GENERIC_ERROR;

  const statusCode = event?.response?.status;

  if (statusCode === 429) {
    picoModal({
      content: `
<div className="modal__wrapper open">
  <div class="modal__content">
    <div class="modal__header pb-5x">
      <h4 class="color-primary-base mb-0x text-429"/>
    </div>
    <div class="modal__body date-time-pick vaccine-appointment">
      <p class="text-429"/>
    </div>
    <div class="modal__footer border-top py-4x">
      <div class="d-flex justify-content-end">
        <div class="action-buttons">
          <div class="btn btn--small btn-primary--outline ok-429"></div>
        </div>
      </div>
    </div>
  </div>
</div>`,
      closeButton: false,
    })
      .afterCreate(function (modal) {
        const okButtonNode = modal
          .modalElem()
          .getElementsByClassName('ok-429')[0];
        okButtonNode.append(getString('ok'));
        okButtonNode.addEventListener('click', modal.close);

        const headerNode = modal
          .modalElem()
          .getElementsByClassName('text-429')[0];
        headerNode.append(getString('stayWithUs'));

        const bodyNode = modal
          .modalElem()
          .getElementsByClassName('text-429')[1];
        bodyNode.append(getString('highRequestError'));
      })
      .show();
    return;
  }

  toast.error({
    title: options.title || title,
    message: options.message || message,
  });
}
