import { useHistory } from 'react-router';
import React, { useContext, useState } from 'react';

import { getString } from 'util/lang';
import { DetailContext } from 'context';
import * as routes from 'constants/routes/covidRoutes';

import Modal from './Modal';
import Button from 'components/common/Button';

const CancelAppointment = () => {
  const history = useHistory();
  const context = useContext(DetailContext);
  const organizationId = context.details.organizationId
    ? context.details.organizationId
    : '';
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cancelAndRouteToServicePage = () => {
    const route = `${routes.SERVICE}/${organizationId}`;
    history.push({
      pathname: route,
      search: window.location.search,
    });

    context.resetForNewPatient();
  };

  const openConfirmationModal = () => {
    setIsModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsModalOpen(false);
  };

  const headerContent = (
    <h4 className="color-primary-base mb-0x" tabIndex="0">
      {getString('cancelMessage')}
    </h4>
  );

  const content = (
    <div className="modal__content">
      <div className="modal__body date-time-pick vaccine-appointment">
        <p tabIndex="0">{getString('cancelWarning')}</p>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Button
        isEnabled={true}
        label={getString('cancel')}
        onClick={openConfirmationModal}
        dataqa="cancel-appointment"
        className="btn btn-primary--outline  btn-block mt-6x mt-0x-sm"
      />
      {isModalOpen && (
        <Modal
          close={closeConfirmationModal}
          containerClassName="confirmation-modal"
          headerContent={headerContent}
        >
          {content}
          <div className="modal__footer text-right border-top d-flex justify-content-between flex-wrap">
            <div>
              <Button
                label={getString('cancel')}
                className="btn btn--large btn-primary--outline"
                isEnabled
                onClick={closeConfirmationModal}
                dataqa="close-cancel-appointment"
              />
            </div>
            <Button
              label={getString('positiveConfirmation')}
              className="btn btn--large btn-primary"
              isEnabled
              onClick={cancelAndRouteToServicePage}
              dataqa="confirm-cancel-appointment"
            />
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CancelAppointment;
