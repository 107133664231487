import { useEffect, useState } from 'react';

import { usePatientInfo, useSelectionsContext } from 'context';
import { useNavigationHelper } from 'hooks/useNavigationHelper';

import PrescreeningForm from '../presentation/PrescreeningForm';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import { getString } from 'util/lang';
import Button from 'components/common/Button';
import { changeTitle } from 'util/siteInfo';

const SelectPrescreening = () => {
  changeTitle('Pre-Screening');
  const { patientInfo, setPatientInfo } = usePatientInfo();
  const { selectedServices } = useSelectionsContext();

  const nav = useNavigationHelper();

  const [local, setLocal] = useState({
    birthYear: patientInfo?.birthYear ?? '',
    birthMonth: patientInfo?.birthMonth ?? '',
    birthDay: patientInfo?.birthDay ?? '',
  });

  useEffect(() => {
    setLocal({
      birthYear: patientInfo?.birthYear ?? '',
      birthMonth: patientInfo?.birthMonth ?? '',
      birthDay: patientInfo?.birthDay ?? '',
    });
  }, [patientInfo]);

  const onChangeInput = (key, value) => {
    switch (key) {
      case 'birthYear':
        setLocal((state) => ({ ...state, birthYear: value }));
        break;
      case 'birthMonth':
        setLocal((state) => ({ ...state, birthMonth: value }));
        break;
      case 'birthDay':
        setLocal((state) => ({ ...state, birthDay: value }));
        break;
      default:
        break;
    }
  };

  const allowedToSummit = local.birthDay && local.birthMonth && local.birthYear;

  const onSubmit = () => {
    if (!allowedToSummit) {
      return;
    }

    setPatientInfo({
      birthYear: local.birthYear,
      birthMonth: local.birthMonth,
      birthDay: local.birthDay,
    });

    // NOTE: do the forking into ineligible page, clinics, etc in sub service selection page
    nav.toSelectSubService();
  };

  if (!selectedServices.length) {
    nav.resetToFirstPage();

    return null;
  }

  return (
    <PageWrapper>
      <BackToPage text={getString('backToPreviousPage')} onClick={nav.goBack} />

      <PrescreeningForm
        onSubmit={onSubmit}
        onChangeInput={onChangeInput}
        birthYear={local.birthYear}
        birthDay={local.birthDay}
        birthMonth={local.birthMonth}
      />

      <Button
        isEnabled={allowedToSummit}
        label={getString('prescreeningButtonLabel')}
        onClick={onSubmit}
        dataqa="goto-subservicelist-btn"
      />
    </PageWrapper>
  );
};

export default SelectPrescreening;
