import config from '../config';
import * as http from '../util/http';

export async function getById(organizationId) {
  if (!organizationId) return null;

  const url = `${config.entity.fetchOrganizationById(organizationId)}`;

  const { data } = await http.get(url);

  return data.data;
}
