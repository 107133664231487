import React from 'react';

const InconclusiveNotice = () => {
  return (
    <div className="alert alert--info py-4x px-8x ml-5x mr-5x mb-5x mt-5x text-left">
      Your test result is <span className="text-demibold">Inconclusive</span>.
      You may need to re-test.{' '}
      <div>Please contact the pharmacy for further detail.</div>
    </div>
  );
};

export default InconclusiveNotice;
