import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getString } from 'util/lang';

const TestReportHeaderPdfView = ({ pharmacyName, pharmacyAddress }) => (
  <View style={styles.header}>
    <Text style={styles.headerTitle}>{getString('testReport.testResult')}</Text>
    <View style={styles.headerContent}>
      <Text style={styles.headerTitleHero}>{pharmacyName}</Text>
      <Text style={styles.detailsValue}>{pharmacyAddress}</Text>
    </View>
  </View>
);

const styles = StyleSheet.create({
  header: {
    position: 'relative',
    marginBottom: 20,
  },
  headerTitle: {
    fontWeight: 600,
    padding: '4px 12px',
    position: 'absolute',
    float: 'left',
    fontSize: 14,
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
  },
  headerTitleHero: {
    fontWeight: 700,
    padding: '4px 12px',
    margin: 'auto',
    width: 'auto',
  },
  detailsValue: {
    margin: 'auto',
  },
});

export default TestReportHeaderPdfView;
