import React from 'react';
import { Document, Page, StyleSheet, View } from '@react-pdf/renderer';

import TestDetailsPdfView from '../presentation/TestDetailsPdfView';
import PatientDetailsPdfView from '../presentation/PatientDetailsPdfView';
import TestReportHeaderPdfView from '../presentation/TestReportHeaderPdfView';
import TestFacilityDetailsPdfView from '../presentation/TestFacilityDetailsPdfView';

import { GenderOptions } from 'constants/patientInfo';
import { TestKitIdTypeOption } from 'constants/testReport';
import PharmacistSignaturePdfView from './PharmacistSignaturePdfView';
import { formatDateAndTimeToLongMonthShortDay } from 'util/DateAndTime';

const TestReportDocument = ({
  organizationName,
  billingAddress,
  testReportResponse,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <TestReportHeaderPdfView
            pharmacyName={organizationName}
            pharmacyAddress={billingAddress}
          />
          <PatientDetailsPdfView
            fullname={`${testReportResponse?.firstName} ${testReportResponse?.middleName ?? ''} ${
              testReportResponse?.lastName
            }`}
            gender={GenderOptions.find(
              (option) => option.value === testReportResponse?.genderCode,
            )?.label()}
            birthDate={testReportResponse?.birthDate}
          />
          <TestDetailsPdfView
            testDate={formatDateAndTimeToLongMonthShortDay(
              testReportResponse?.testDateAndTime,
            )}
            testKitIdType={TestKitIdTypeOption.find(
              (option) => option.value === testReportResponse?.testKitIdType,
            )?.label()}
            testKitName={testReportResponse?.testKitName}
            testResults={testReportResponse?.testResults}
            testDevice={testReportResponse?.testDeviceName}
          />
          <TestFacilityDetailsPdfView
            facilityAddress={testReportResponse?.facilityAddress}
            facilityPhone={testReportResponse?.facilityPhone}
            facilityName={testReportResponse?.facilityName}
            orderingProvider={testReportResponse?.orderingFacilityName}
            facilityNpi={testReportResponse?.facilityNpi}
            facilityClia={testReportResponse?.facilityClia}
          />
        </View>
        {testReportResponse?.pharmacistSignature && (
          <PharmacistSignaturePdfView
            pharmacistSignature={testReportResponse?.pharmacistSignature}
            testResultDateTime={formatDateAndTimeToLongMonthShortDay(
              testReportResponse?.testResultDate,
            )}
          />
        )}
      </Page>
    </Document>
  );
};

export default TestReportDocument;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 16,
    boxSizing: 'border-box',
  },
  page: {
    margin: 0,
    fontFamily: 'Lato',
    fontSize: 10,
    color: '#000000',
    backgroundColor: '#ffffff',
  },
});
