import React from 'react';
import { FiX as Cross } from 'vyaguta-icons/fi';

import FormErrors from '../FormErrors';

import SignatureCanvas from './SignatureCanvas';
import { SignaturePlaceholder } from './Signature';

import AlertMessage from '../../COVID/common/AlertMessage';

import ImageUtils from '../../../util/image';
import { getString } from '../../../util/lang';

import { CanvasDimensions } from '../../../constants/confirm';
import { MaxSignatureSize } from '../../../constants/patientInfo';

class Signature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signatureTooLong: false,
    };
    this.signatureRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isUnderMinimumAge !== this.props.isUnderMinimumAge ||
      this.props.isUnderEighteen !== prevProps.isUnderEighteen ||
      this.props.isUnderNineteen !== prevProps.isUnderNineteen
    ) {
      this.onClear();
    }
  }

  /**
   * When clear button of the component is pressed,
   * Clears canvas and sets parent signature state value to null
   */
  onClear = () => {
    const { onDraw } = this.props;
    this.setState({
      signatureTooLong: false,
    });
    if (this.signatureRef.current) {
      this.signatureRef.current.clear();
      onDraw(null);
    }
  };

  isImageSizeValid = (image) => {
    if (image.length > MaxSignatureSize) {
      this.setState({
        signatureTooLong: true,
      });
      this.props.onDraw(null, null);

      return false;
    }

    return true;
  };

  /**
   * When user draws a signature and the touch device leaves the canvas,
   * Update the parent state with base64 image of signature drawn
   */
  onStrokeEnd = () => {
    const isEmpty = this.signatureRef.current.isEmpty();
    const { onDraw } = this.props;

    if (!isEmpty) {
      const {
        dynamicsWidth,
        dynamicsHeight,
        portalWidth,
        portalHeight,
        scale,
      } = CanvasDimensions;

      const image = this.signatureRef.current.toDataURL();

      ImageUtils.resizeImage(
        image,
        dynamicsWidth,
        dynamicsHeight,
        portalWidth,
        portalHeight,
        scale,
      )
        .then((newImage) => {
          if (!this.isImageSizeValid(newImage)) return;
          onDraw(image, newImage);
        })
        .catch(() => {
          let img = this.signatureRef.current.toDataURL('image/png', 0.01);
          if (!this.isImageSizeValid(img)) return;
          onDraw(image, img);
        });
    } else {
      onDraw(null, null);
    }
  };

  /**
   * If a signature( a base64 string), is provided when component mounts,
   * the base64 image is drawn on the canvas after it mounts
   */
  renderPreviousSignature = () => {
    const { source } = this.props;
    if (source) {
      this.signatureRef.current.fromDataURL(source);
    }
  };

  render() {
    const { source, isOpen, onClick, isUnderMinimumAge, isUnderEighteen } =
      this.props;
    const { signatureTooLong } = this.state;
    const showCanvas = isOpen || source;

    const signatureHelp = getString('signatureHelp');
    return (
      <section className="signature section__margin">
        <h2>
          {isUnderEighteen && !isUnderMinimumAge
            ? getString('signatureUnder18Title')
            : getString('patientSignature')}
          <span className="color-danger-base">*</span>
        </h2>

        {showCanvas && (
          <AlertMessage
            type="info"
            message={signatureHelp}
            className="mb-5x"
            isVisible="true"
          />
        )}
        <div className="signature-container">
          {showCanvas && (
            <Cross
              className="signature__close"
              size={20}
              onClick={this.onClear}
            />
          )}
          <SignaturePlaceholder
            onClick={() => onClick(true)}
            isOpen={!showCanvas}
          />
          {showCanvas && (
            <SignatureCanvas
              onStrokeEnd={this.onStrokeEnd}
              reference={this.signatureRef}
              callOnMount={this.renderPreviousSignature}
              source={source}
            />
          )}
          {signatureTooLong && (
            <FormErrors errors={{ error: getString('signatureTooLong') }} />
          )}
        </div>
      </section>
    );
  }
}

export default Signature;
