import React from 'react';

import { getString } from 'util/lang';

import styles from './GroupAppointmentOptions.module.scss';

const SELECTION = [
  {
    name: 'individual',
    title: getString('groupAppointmentIndividualSelectionText'),
  },
  {
    name: 'group',
    title: getString('groupAppointmentGroupSelectionText'),
  },
];

const GroupAppointmentOption = ({ radioInput, setRadioInput }) => {
  const handleRadioChange = (event) => {
    const selectedValue = event.target.id; // Get the ID of the selected radio input
    setRadioInput(selectedValue === 'group'); // Update the groupAppointmentStatus based on the selected radio input
  };

  return (
    <div className={styles.groupSelectionWrapper}>
      <h2 className="title--normal">
        {' '}
        {getString('groupAppointmentOptionTitle')}{' '}
      </h2>
      <p className="mb-8x"> {getString('groupAppointmentOptionSubText')}</p>
      {SELECTION.map((item, key) => {
        return (
          <div className="custom-radio mb-6x" key={key}>
            <input
              id={item.name}
              name={item.name}
              type="radio"
              className="custom-radio-input"
              onChange={handleRadioChange}
              checked={item.name === 'group' ? radioInput : !radioInput}
              autoComplete="off"
            />
            <label htmlFor={item.name}>
              <div>
                <div className="sub-title">{item.title}</div>
              </div>
            </label>
          </div>
        );
      })}
      <p className="ml-7x">{getString('groupAppointmentOptionHelpText')}</p>
    </div>
  );
};

export default GroupAppointmentOption;
