import React from 'react';
import SingleDosePicker from '../../sub-scheduling/presentation/SingleDosePicker';

const DTPicker = (props) => {
  return (
    <React.Fragment>
      {/* First Dose Only */}
      <SingleDosePicker {...props} />
    </React.Fragment>
  );
};

export default DTPicker;
