import InsurancePayment from './InsurancePayment';
import PAYMENT_BOOLEAN from '../../../../../../constants/showPaymentBoolean';
import { getString } from '../../../../../../util/lang';
import styles from './GroupInsurancePayment.module.scss';
import React, { useMemo, useState } from 'react';
import { FiInfo } from 'vyaguta-icons/fi';
import SelectBox from '../../../../../common/SelectBox';
import {
  InsuranceType,
  ShowAsRequiredFields,
  SubscriberRelationship,
  SubscriberRelationshipCode,
} from '../../../../../../constants/patientInfo';
import cls from 'classnames';
import FormInput from '../../../../../common/FormInput';
import DropSelect from '../DropSelect';
import { InsuranceVerificationButton } from '../../../../../common/InsuranceVerificationButton';
import { useGroupAppointmentContext } from '../../../../../../context';
import { useEffect } from 'react';

const SharedInsurance = ({
  insuranceType,
  isInsuranceTypeShared,
  onGroupInsuranceInfoChange,
  selectedPaymentUser,
  errors,
  insuranceInfo,
  insuranceProps,
}) => {
  const isMedicareOrMedicaid = [
    InsuranceType.Medicare,
    InsuranceType.MedicareAdvantage,
    InsuranceType.Medicaid,
  ].includes(insuranceType);

  const filteredSubscriberRelationships = useMemo(() => {
    return isMedicareOrMedicaid
      ? SubscriberRelationship.filter(
          ({ value }) => value === SubscriberRelationshipCode.Self,
        )
      : SubscriberRelationship;
  }, [isMedicareOrMedicaid]);

  const handleFieldValueChange = (e) => {
    e.stopPropagation();
    const { name, value } = e.target;

    onGroupInsuranceInfoChange(name, value);
  };

  return (
    <div className="mb-4x ml-7x">
      <div className={cls(styles.GroupPaymentInformationContainer)}>
        <div>
          Payment Method:{' '}
          <span className={styles.GroupPaymentInformationText}>
            {selectedPaymentUser.paymentMethod}
          </span>
        </div>
        <span>
          <span className={styles.GroupPaymentInformationInsuranceCompany}>
            Insurance Company:{' '}
            <span className={styles.GroupPaymentInformationText}>
              {selectedPaymentUser?.company?.label}
            </span>
          </span>
          <span className={styles.GroupPaymentInformationMemberId}>
            Member ID:{' '}
            <span className={styles.GroupPaymentInformationText}>
              {selectedPaymentUser?.insuranceInfo?.memberID}
            </span>
          </span>
        </span>
      </div>

      {isInsuranceTypeShared && (
        <div className="mt-4x">
          <div className={cls('col-12-md fs-mask', styles.FormInputBorder)}>
            <SelectBox
              label={getString('subscriberRelationship')}
              id="subscriberRelationship"
              onChange={handleFieldValueChange}
              defaultValue={filteredSubscriberRelationships[0]}
              options={filteredSubscriberRelationships}
              value={insuranceInfo.subscriberRelationship}
              hasError={errors['subscriberRelationship']}
              required={ShowAsRequiredFields['subscriberRelationship']}
              inputClassName={cls(
                'border-radius--topRight',
                styles.DisableBottomBorder,
                styles.DisableRightBorder,
              )}
              isDisabled={false}
              dataqa="subscriber-relationship"
            />
          </div>
        </div>
      )}

      {isInsuranceTypeShared && (
        <div className="mt-4x">
          {insuranceInfo.subscriberRelationship &&
            Number(insuranceInfo.subscriberRelationship) !==
              SubscriberRelationshipCode.Self && (
              <div>
                <div className={styles.PrimaryCardHolderTitle}>
                  <span>Primary Card Holder Details </span>
                  <span>
                    <FiInfo className={styles.PrimaryCardHolderIcon} />
                  </span>
                </div>
                <div className={cls('row', styles.FormInputBorder)}>
                  <div className="col-4-md">
                    <FormInput
                      label={getString('firstName')}
                      id={'cardHolderFirstName'}
                      onChange={onGroupInsuranceInfoChange}
                      value={insuranceInfo?.cardHolderFirstName || ''}
                      hasError={!!errors['cardHolderFirstName']}
                      required={true}
                      data-qa="firstname-check"
                      inputClassName={styles.DisableBottomBorder}
                    />
                  </div>
                  <div className="col-4-md">
                    <FormInput
                      label={getString('middleName')}
                      id={'cardHolderMiddleName'}
                      onChange={onGroupInsuranceInfoChange}
                      value={insuranceInfo?.cardHolderMiddleName || ''}
                      hasError={!!errors['cardHolderMiddleName']}
                      required={false}
                      data-qa="middlename-check"
                      inputClassName={styles.DisableBottomBorder}
                    />
                  </div>
                  <div className="col-4-md">
                    <FormInput
                      label={getString('lastName')}
                      id={'cardHolderLastName'}
                      onChange={onGroupInsuranceInfoChange}
                      value={insuranceInfo?.cardHolderLastName || ''}
                      hasError={!!errors['cardHolderLastName']}
                      required={true}
                      data-qa="lastname-check"
                      inputClassName={cls(
                        styles.DisableRightBorder,
                        styles.DisableBottomBorder,
                      )}
                    />
                  </div>
                </div>
              </div>
            )}

          <InsuranceVerificationButton
            insuranceProps={insuranceProps}
            insuranceInfo={insuranceInfo}
          />
        </div>
      )}
    </div>
  );
};

const OPTIONS = {
  INDIVIDUAL: 'individual',
  GROUP: 'group',
};

const GroupPayment = (props) => {
  const {
    showPaymentContainer,
    showPayment,
    insuranceType,
    isInsuranceTypeShared,
    paymentMethodName,
    paymentCompanyName,
    insuranceInfo,
    onInsuranceInfoChange,
    onGroupInsuranceInfoChange,
    errors,
    insuranceShareableProp,
    onGroupPaymentSelection,
    payerlist,
    ...insuranceProps
  } = props;

  const { commonAppointmentDetails, currentPatientIndex } =
    useGroupAppointmentContext();

  const [paymentOption, setPaymentOption] = useState(OPTIONS.INDIVIDUAL);

  const [selectedPaymentUser, setSelectedPaymentUser] = useState({
    ...insuranceShareableProp[0],
  });

  // Persist Selected payment user to local state of patient Info
  const onPaymentOptionChange = (e) => {
    const value = e.target.value;
    e.stopPropagation();
    setPaymentOption(value);
    if (typeof onGroupPaymentSelection === 'function') {
      if (value === OPTIONS.GROUP) {
        onGroupPaymentSelection(selectedPaymentUser, { insuranceInfo: true });
      } else {
        // OPTIONS.INDIVIDUAL
        onGroupPaymentSelection(
          { ...selectedPaymentUser, insuranceInfo: {} },
          { insuranceInfo: true },
        );
      }
    }
  };

  useEffect(() => {
    // see if I'm using the insurance info of someone already available before me
    if (
      insuranceShareableProp?.length > 0 &&
      commonAppointmentDetails[currentPatientIndex]
    ) {
      const matchFromGroup = insuranceShareableProp.find((insuranceDetail) => {
        const aptDetail = commonAppointmentDetails[currentPatientIndex];
        const insuranceDetailInfo = insuranceDetail.insuranceInfo;
        const fullMemberId =
          insuranceDetailInfo.memberIDPrefix +
          insuranceDetailInfo.memberID +
          insuranceDetailInfo.memberIDSuffix;
        const currentFullMemberId =
          aptDetail.memberIDPrefix +
          aptDetail.memberID +
          aptDetail.memberIDSuffix;
        return fullMemberId === currentFullMemberId;
      });
      if (matchFromGroup) {
        setPaymentOption(OPTIONS.GROUP);
      } else {
        setPaymentOption(OPTIONS.INDIVIDUAL);
      }
    }
  }, []);

  const dropSelectOptions = useMemo(() => {
    const options = insuranceShareableProp.map((p) => {
      const middleName = p?.patientMiddleName ? p.patientMiddleName + ' ' : '';
      const nameLabel = `${p.patientFirstName} ${middleName}${p.patientLastName}`;
      return {
        label: nameLabel,
        value: p,
      };
    });
    return options;
  }, [insuranceShareableProp]);

  const onPaymentUserChange = (option) => {
    if (option) {
      setSelectedPaymentUser(option);
    }
    if (typeof onGroupPaymentSelection === 'function') {
      onGroupPaymentSelection(option);
    }
  };

  const selectedPaymentUserFullName = `${selectedPaymentUser.patientFirstName} ${
    selectedPaymentUser?.patientMiddleName
      ? selectedPaymentUser.patientMiddleName + ' '
      : ''
  }${selectedPaymentUser.patientLastName}`;

  return (
    <div>
      <>
        {showPaymentContainer && showPayment === PAYMENT_BOOLEAN.TRUE && (
          <section className={cls('form-payment-type section__margin')}>
            <h2 tabIndex="0">{getString('paymentType')}</h2>
            <div
              className={cls(
                'patient-form-group border border-radius',
                styles.GroupPaymentInformationContainer,
              )}
            >
              {dropSelectOptions.length > 0 && (
                <>
                  <div className={'row mb-3x'}>
                    <input
                      // autoComplete="off"
                      name="group__payment"
                      type="radio"
                      id={'payment__group'}
                      value={OPTIONS.GROUP}
                      checked={paymentOption === OPTIONS.GROUP}
                      onChange={onPaymentOptionChange}
                      data-qa={'dataqa'}
                      className={cls(styles.GroupInsurancePaymentRadio)}
                    />
                    <div>
                      <div className={styles.GroupPaymentFlexContainer}>
                        <label
                          className={styles.GroupInsurancePaymentRadioLabel}
                          htmlFor={'payment__group'}
                        >
                          <div>
                            {getString('useSameInsuranceFromGroup')} &nbsp;
                            <span
                              htmlFor="group"
                              className={
                                styles['patient-contact-selected-name']
                              }
                            >
                              {selectedPaymentUserFullName}
                            </span>{' '}
                          </div>
                        </label>

                        <DropSelect
                          options={dropSelectOptions}
                          onChange={onPaymentUserChange}
                        />
                      </div>
                    </div>
                  </div>

                  {paymentOption === OPTIONS.GROUP && (
                    <SharedInsurance
                      insuranceType={insuranceType}
                      insuranceInfo={insuranceInfo}
                      paymentMethodName={paymentMethodName}
                      paymentCompanyName={paymentCompanyName}
                      memberId={insuranceInfo.memberId}
                      onGroupInsuranceInfoChange={onGroupInsuranceInfoChange}
                      selectedPaymentUser={selectedPaymentUser}
                      errors={errors}
                      payerlist={payerlist}
                      isInsuranceTypeShared={paymentOption}
                      insuranceProps={insuranceProps}
                    />
                  )}
                </>
              )}
              <div className={'row mb-3x'}>
                <input
                  // autoComplete="off"
                  name="payment__method"
                  type="radio"
                  id={'payment__individual'}
                  value={OPTIONS.INDIVIDUAL}
                  checked={paymentOption === OPTIONS.INDIVIDUAL}
                  onChange={onPaymentOptionChange}
                  data-qa={'dataqa'}
                  className={styles.GroupInsurancePaymentRadio}
                />
                <div className={styles.GroupInsurancePaymentRadioLabel}>
                  <label htmlFor={'payment__individual'}>
                    <div>Select different payment type</div>
                  </label>
                </div>
              </div>

              {paymentOption === OPTIONS.INDIVIDUAL && (
                <>
                  <InsurancePayment
                    {...insuranceProps}
                    payerlist={payerlist}
                    errors={errors}
                    insuranceInfo={insuranceInfo}
                    insuranceType={insuranceType}
                    onInsuranceInfoChange={onInsuranceInfoChange}
                    inputClassName={styles.UnderlineContainer}
                  />
                </>
              )}
            </div>
          </section>
        )}
      </>
    </div>
  );
};

export default GroupPayment;
