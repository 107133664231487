import React from 'react';

import Skeleton from 'react-loading-skeleton';

const ReportLoader = () => {
  return (
    <div className="card card__success p-0x">
      <div style={{ maxWidth: '230px' }} className="px-4x py-4x">
        <Skeleton width={'100%'} height={40} />
      </div>

      <div className="px-4x pb-4x">
        <Skeleton width={'100%'} height={20} />
      </div>

      <div className="px-4x pb-4x">
        <Skeleton width={'100%'} height={20} />
      </div>

      <div className="px-4x pb-4x" style={{ maxWidth: '400px' }}>
        <Skeleton width={'100%'} height={20} />
      </div>

      <div className="px-4x pb-4x" style={{ maxWidth: '400px' }}>
        <Skeleton width={'100%'} height={20} />
      </div>
    </div>
  );
};

export default ReportLoader;
