import React from 'react';
import { UiRefresh } from 'vyaguta-icons/ui';

const RefreshDataButton = (props) => {
  const { label, handleRefresh } = props;

  return (
    <button
      className="btn btn--plain refresh-clinic btn__refresh"
      tabIndex="0"
      onClick={handleRefresh}
    >
      <UiRefresh size="16" className="mr-2x" />
      <span>{label}</span>
    </button>
  );
};

export default RefreshDataButton;
