import { PageWrapper } from 'components/COVID/common/PageWrapper';
import LateSubmissionInfo from '../../../common/LateSubmissionInfo';

export const GroupAppointmentConfirmationLate = () => {
  return (
    <PageWrapper bgGrey={true}>
      <LateSubmissionInfo />
    </PageWrapper>
  );
};
