import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiCheck } from 'vyaguta-icons/fi';
import CONFIG from 'config';

import { useNavigationHelper } from 'hooks/useNavigationHelper';

import { useDetailContext } from 'context';
import { useResetAppState } from 'context/useResetAppState';
import { useSecurityToken } from 'context/useSecurityToken';
import { useAppointmentCancel } from 'context/useAppointmentCancel';
import { useSelectedServiceNames } from 'context/useSelectedServiceNames';
import { useGroupStatusCheck } from 'context/useGroupAppointmentStatusCheck';

import Button from 'components/common/Button';
import { ConfirmModal } from 'components/common/ConfirmModal';
import { useConfirmModal } from 'components/common/ConfirmModal';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { InvalidAppointmentMessage } from 'components/COVID/common/InvalidAppointmentMessage';

import styles from './GroupCancelTypeSelection.module.scss';
import { AppointmentStatuses } from 'constants/appointment';
import { SecurityCheckServiceCode } from '../../SecurityCheckServiceCode/container/SecurityCheckServiceCode';

import { toast } from 'util/toast';
import { getString } from 'util/lang';

const COMPONENT_STATE_KEY = {
  showModal: 'showModal',
  isLoading: 'isLoading',
  cancelComplete: 'cancelComplete',
  dataSetupCompleted: 'dataSetupCompleted',
  isCancelValid: 'isCancelValid',
  appointmentId: 'appointmentId',
};

export const GroupPendingCancelVerify = () => {
  const { groupId, organizationId } = useParams();

  const nav = useNavigationHelper();
  const { confirm } = useConfirmModal();
  const resetAppState = useResetAppState();
  const serviceNames = useSelectedServiceNames();
  const groupAppointmentStatus = useGroupStatusCheck(groupId);
  const { rescheduleDataSetup, setDetails } = useDetailContext();
  const { cancelGroupAppointment } = useAppointmentCancel();
  const { securityToken, setSecurityToken, resetSecurityToken } =
    useSecurityToken();

  const [componentState, setComponentState] = useState({
    showModal: false,
    isLoading: false,
    cancelComplete: false,
    isCancelValid: true,
    dataSetupCompleted: false,
    appointmentId: '',
  });

  const updateComponentState = (key, value) => {
    if (Object.keys(COMPONENT_STATE_KEY).includes(key)) {
      setComponentState((prev) => ({ ...prev, [key]: value }));
    }
  };

  const onSecurityVerified = useCallback(
    async ({ token, appointmentId }) => {
      try {
        updateComponentState(COMPONENT_STATE_KEY.isLoading, true);
        setSecurityToken(token);

        if (!componentState.dataSetupCompleted) {
          // if user verifies, then cancels then verifies again, dont do the reschedule setup again
          const result = await rescheduleDataSetup(
            organizationId,
            appointmentId,
          );
          if (!result) {
            throw new Error('Failed to setup  reschedule');
          }
          updateComponentState(
            COMPONENT_STATE_KEY.appointmentId,
            appointmentId,
          );
          updateComponentState(COMPONENT_STATE_KEY.dataSetupCompleted, true);
        }

        const appointment = await new Promise((resolve) => {
          setDetails(({ appointments }) => {
            resolve(appointments[0]);
            return {};
          });
        });

        const isValid =
          AppointmentStatuses.PENDING === appointment.appointmentStatus;
        updateComponentState(COMPONENT_STATE_KEY.isLoading, false);
        updateComponentState(COMPONENT_STATE_KEY.isCancelValid, isValid);

        if (!isValid) {
          return;
        }
        updateComponentState(COMPONENT_STATE_KEY.showModal, true);
      } catch (error) {
        console.error(error);
        error.message && toast.error({ title: '', message: error.message });
        resetSecurityToken();
      }
    },
    [
      confirm,
      setSecurityToken,
      resetSecurityToken,
      groupId,
      organizationId,
      rescheduleDataSetup,
      setDetails,
      componentState.dataSetupCompleted,
    ],
  );

  const handleSubmitButton = async (captchaResponseKey) => {
    updateComponentState(COMPONENT_STATE_KEY.showModal, false);
    updateComponentState(COMPONENT_STATE_KEY.isLoading, true);
    await cancelGroupAppointment(
      groupId,
      captchaResponseKey,
      false,
      securityToken,
    );
    updateComponentState(COMPONENT_STATE_KEY.cancelComplete, true);
    updateComponentState(COMPONENT_STATE_KEY.isLoading, false);
  };

  const onBookAnotherAppointment = useCallback(() => {
    resetAppState();
    nav.resetToFirstPage();
  }, [nav, resetAppState]);

  return (
    <PageWrapper>
      {componentState.cancelComplete ? (
        <CancelSuccess
          serviceNames={<>{serviceNames}</>}
          onBookAnotherAppointment={onBookAnotherAppointment}
        />
      ) : componentState.isCancelValid ? (
        <SecurityCheckServiceCode
          loading={componentState.isLoading || !groupAppointmentStatus.loaded}
          activityId={groupId}
          onSecurityVerified={onSecurityVerified}
          headerMsg={getString('followupRescheduleVerificationHeader')}
          alertMsg={getString('followupRescheduleVerificationAlert')}
        />
      ) : (
        <InvalidAppointmentMessage />
      )}

      <ConfirmModal
        show={componentState.showModal}
        onCancel={() => {
          updateComponentState(COMPONENT_STATE_KEY.showModal, false);
        }}
        onConfirm={handleSubmitButton}
        captchaEnabled={CONFIG.captchaEnabled}
        headerContent={
          <div className={styles.modalHeader}>
            {getString('appointmentCancellationHeader')}
          </div>
        }
        bodyContent={
          <div className={styles.modalContent}>
            {getString('appointmentCancellationDescription')}
          </div>
        }
        cancelText={getString('doNotCancel')}
        confirmText={getString('cancelAppointment')}
      />
    </PageWrapper>
  );
};

const CancelSuccess = ({ serviceNames, onBookAnotherAppointment }) => {
  return (
    <div className="success">
      <section className="selected-details section__margin">
        <div className="border border-radius section__container p-0x">
          <FiCheck
            size={24}
            className="success-check success-check--covid mt-9x"
          />
          <div className="success-content border-bottom">
            <p tabIndex="0" className="mb-0x-md">
              {getString('cancelIndividualAppointmentMessage', {
                serviceName: <>{serviceNames}</>,
              })}
            </p>
            <div className="success-action-covid">
              <Button
                title={getString('bookAppointmentTitle')}
                isEnabled
                onClick={onBookAnotherAppointment}
                className="btn btn--large btn-primary--outline"
                isActive
                label={getString('bookAppointment')}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
