import { getString } from 'util/lang';
import LineBreak from '../../common/LineBreak/LineBreak';
import FormHeader from '../../common/FormHeader/FormHeader';
import PharmacyInfo from '../../common/PharmaInfo/PharmacyInfo';

import styles from './NoticeOfPrivacyPractices.module.scss';

const LABEL_PREFIX = 'dme.deliveryPacketForm.noticeOfPrivacyPractices';

const additionalUsesOfInformationData = getString(
  `${LABEL_PREFIX}.additionalUsesOfInformation.data`,
);
const individualRightsData = getString(`${LABEL_PREFIX}.individualRights.data`);
const companyDutiesDescriptions = getString(
  `${LABEL_PREFIX}.companyDuties.descriptions`,
);

const NoticeOfPrivacyPractices = (props) => {
  const { effectiveDate, organizationDetail } =
    props?.noticeOfPrivacyPracticesData;

  const usesAndDisclosuresData = JSON.parse(
    getString(`${LABEL_PREFIX}.usesAndDisclosures.data`, {
      companyName: organizationDetail?.organizationName,
    }),
  );

  return (
    <div className="d-flex flex-column">
      <FormHeader text={getString(`${LABEL_PREFIX}.title`)} />

      <PharmacyInfo className="mt-6x" />

      <p className={styles.desc}>{getString(`${LABEL_PREFIX}.description`)}</p>

      <LineBreak
        className="mt-6x"
        titleText={getString(`${LABEL_PREFIX}.usesAndDisclosures.title`)}
      />

      <ul className={styles.listWrap}>
        {usesAndDisclosuresData.map(({ title, description }) => (
          <li key={title}>
            <h4>{title}</h4>
            <p>{description}</p>
          </li>
        ))}
      </ul>

      <LineBreak
        className="mt-6x"
        titleText={getString(
          `${LABEL_PREFIX}.additionalUsesOfInformation.title`,
        )}
      />

      <ul className={styles.listWrap}>
        {additionalUsesOfInformationData.map(({ title, description }) => (
          <li key={title}>
            <h4>{title}</h4>
            <p>{description}</p>
          </li>
        ))}
      </ul>

      <div className={styles.individualRightWrap}>
        <LineBreak
          className="mt-6x"
          titleText={getString(`${LABEL_PREFIX}.individualRights.title`)}
        />
        <p>{getString(`${LABEL_PREFIX}.individualRights.description`)}</p>
        <ul className={styles.bulletList}>
          {individualRightsData.map((individualRight, index) => (
            <li key={index}>{individualRight}</li>
          ))}
        </ul>
      </div>

      <LineBreak />

      <div className={`mt-3x ${styles.sectionWrap}`}>
        <h3>
          {getString(`${LABEL_PREFIX}.companyDuties.title`, {
            companyName: organizationDetail?.organizationName,
          })}
        </h3>
        {companyDutiesDescriptions.map((description, index) => (
          <p key={index}>{description}</p>
        ))}
      </div>

      <div className={`mt-3x ${styles.sectionWrap}`}>
        <h3 className="text-uppercase">
          {getString(`${LABEL_PREFIX}.rightToRevisePrivacyPractices.title`)}
        </h3>
        <p>
          {getString(
            `${LABEL_PREFIX}.rightToRevisePrivacyPractices.description`,
          )}
        </p>
      </div>

      <div className={`mt-3x ${styles.sectionWrap}`}>
        <h3 className="text-uppercase">
          {getString(
            `${LABEL_PREFIX}.requestsToInspectProtectedHealthInformation.title`,
          )}
        </h3>
        <p>
          {getString(
            `${LABEL_PREFIX}.requestsToInspectProtectedHealthInformation.description`,
          )}
        </p>
      </div>

      <div className={`mt-3x ${styles.sectionWrap}`}>
        <h3 className="text-uppercase">
          {getString(`${LABEL_PREFIX}.compliants.title`)}
        </h3>
        <p>{getString(`${LABEL_PREFIX}.compliants.description`)}</p>
      </div>

      <div className={styles.privacyOfficer}>
        <h4>{getString(`${LABEL_PREFIX}.compliants.contactDesignation`)}</h4>
        <PharmacyInfo className="pharmaInfoWrap" />
      </div>

      <div className={`mt-3x ${styles.sectionWrap}`}>
        <h3>{getString(`${LABEL_PREFIX}.effectiveDate.title`)}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: getString(`${LABEL_PREFIX}.effectiveDate.description`, {
              date: effectiveDate,
            }),
          }}
        />
      </div>
    </div>
  );
};

export default NoticeOfPrivacyPractices;
