import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import dmeReducer from './dme/dme.slice';
import { dmeApi, testReportApi } from 'api';

export const store = configureStore({
  reducer: {
    dme: dmeReducer,
    [dmeApi.reducerPath]: dmeApi.reducer,
    [testReportApi.reducerPath]: testReportApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(dmeApi.middleware)
      .concat(testReportApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);
