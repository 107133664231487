import React from 'react';

import Collapsible from 'react-collapsible';

export const CustomCollapsible = (props) => {
  const { trigger, content } = props;

  return (
    <Collapsible trigger={trigger} tabIndex={0}>
      <p tabIndex={0}>{content}</p>
    </Collapsible>
  );
};
