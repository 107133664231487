import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { usePopper } from 'react-popper';

import AdditionalServiceTypeOption from './AdditionalServiceTypeOption';
import ServiceTypeTab from './ServiceTypeTab';
import styles from './selectServiceType.module.scss';
import c from 'classnames';

import { MoreIcon } from './icons/MoreIcon';
import { useOutsideAlerter } from 'hooks/useClickOutside';
import { createPortal } from 'react-dom';
import useMediaQuery from 'hooks/useMediaQuery';

/**
 *
 * @typedef {import('types').ServiceType} ServiceType
 *
 * @param {{
 *  serviceTypeList: ServiceType[]
 *  selectedServiceTypeId: string
 *  onServiceTypeChange: (id: string) => void
 *  handleDropDownClick: (id: string) => void
 *  serviceTypeCount: number
 * }} props
 */
export const SelectServiceTypeComponent = (props) => {
  const {
    serviceTypeList,
    selectedServiceTypeId,
    onServiceTypeChange,
    handleDropDownClick,
    serviceTypeCount,
  } = props;

  const { serviceTypes, dropDownOptions } = useMemo(() => {
    const serviceTypes =
      serviceTypeList.length > serviceTypeCount
        ? serviceTypeList.slice(0, serviceTypeCount)
        : serviceTypeList;
    const dropDownOptions =
      serviceTypeList.length > serviceTypeCount
        ? serviceTypeList.slice(serviceTypeCount, serviceTypeList.length)
        : [];

    return {
      serviceTypes,
      dropDownOptions,
    };
  }, [serviceTypeList, serviceTypeCount]);

  // TODO: use css for hover styling
  const [moreTabActive, setMoreTabActive] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const portalElRef = useRef(null);

  useEffect(() => {
    const el = document.createElement('div');
    el.style.position = 'absolute';
    el.style.top = 0;
    el.style.left = 0;
    el.style.width = 0;
    el.style.height = 0;
    el.style.overflow = 'visible';

    const body = document.getElementsByTagName('body').item(0);

    body.append(el);

    portalElRef.current = el;

    return () => body.removeChild(el);
  }, []);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-end',
    },
  );

  const onClickMoreTab = () => {
    setMoreTabActive(!moreTabActive);
  };

  const onChangeServiceType = (id) => {
    onServiceTypeChange(id);
  };

  const onClickDropDown = (id) => {
    onServiceTypeChange(id);
    handleDropDownClick(id);
    setMoreTabActive(false);
  };

  const dropdownRef = useRef(null);
  const moreTabRef = useRef(null);

  const handleOutsideClick = useCallback(() => {
    setMoreTabActive(false);
  }, []);

  useOutsideAlerter(dropdownRef, handleOutsideClick, [moreTabRef]);

  return (
    <div className={c(styles.serviceTypeList)}>
      {serviceTypes.map((serviceType) => {
        return (
          <ServiceTypeTab
            key={serviceType.id}
            id={serviceType.id}
            title={serviceType.name}
            active={selectedServiceTypeId === serviceType.id}
            serviceGroup={serviceType.serviceGroup}
            onChangeServiceType={onChangeServiceType}
          />
        );
      })}
      {dropDownOptions.length > 0 && (
        <div className={c(styles.dropDownParent)} ref={setReferenceElement}>
          <div
            className={c(styles.moreTab)}
            ref={moreTabRef}
            onClick={onClickMoreTab}
          >
            <div className="moreTabIcon">
              <MoreIcon />
            </div>
            <div className="moreTabTitle">More</div>
          </div>
          {moreTabActive &&
            createPortal(
              <div
                ref={(el) => {
                  dropdownRef.current = el;
                  setPopperElement(el);
                }}
                id="service-type-dropdown"
                className={styles.serviceTypeDropdown}
                style={popperStyles.popper}
                {...attributes.popper}
              >
                {dropDownOptions.map((option) => {
                  return (
                    <AdditionalServiceTypeOption
                      key={option.id}
                      id={option.id}
                      title={option.name}
                      serviceGroup={option.serviceGroup}
                      active={option.active}
                      onChangeServiceType={onClickDropDown}
                    />
                  );
                })}
              </div>,
              portalElRef.current,
            )}
        </div>
      )}
    </div>
  );
};

/**
 *
 * @param {Omit<import('react').ComponentProps<typeof SelectServiceTypeComponent>, 'serviceTypeCount'>} props
 * @returns
 */
export const SelectServiceType = (props) => {
  const isMobileView = useMediaQuery('(max-width: 1199px)');
  const isSmallMobileView = useMediaQuery('(max-width: 360px)');

  const serviceTypeCount = isSmallMobileView ? 1 : isMobileView ? 2 : 3;

  const { serviceTypeList, selectedServiceTypeId } = props;

  const reorderedServiceTypes = useMemo(() => {
    const selectedIndex = serviceTypeList.findIndex(
      ({ id }) => id === selectedServiceTypeId,
    );

    if (selectedIndex === -1 || selectedIndex < serviceTypeCount) {
      // no selected item OR selected item is already visible
      return serviceTypeList;
    }

    // move selected item to last visible spot
    return [
      ...serviceTypeList.slice(0, serviceTypeCount - 1),
      serviceTypeList[selectedIndex],
      ...serviceTypeList.slice(serviceTypeCount - 1, selectedIndex),
      ...serviceTypeList.slice(selectedIndex + 1),
    ];
  }, [serviceTypeList, selectedServiceTypeId, serviceTypeCount]);

  return (
    <SelectServiceTypeComponent
      {...props}
      serviceTypeList={reorderedServiceTypes}
      serviceTypeCount={serviceTypeCount}
    />
  );
};
