import React from 'react';

import { getString } from 'util/lang';
import Modal from 'components/COVID/common/Modal';
import ActivityIndicator from 'components/common/ActivityIndicator';

const LoadingModal = () => {
  return (
    <Modal
      close={null}
      containerClassName="confirmation-modal"
      headerContent={null}
      isClosable={false}
    >
      <div className="modal__body py-16x">
        <ActivityIndicator />
        <h3 className="color-primary-base text-center mt-10x">
          {getString('appointmentBookProgress')}
        </h3>
      </div>
    </Modal>
  );
};

export default LoadingModal;
