import * as Yup from 'yup';

import { getString } from 'util/lang';

export const NoInsuranceDetailsValidation = (noInsuranceDetails) => {
  let errors = {};
  let isValid = true;

  Object.keys(noInsuranceDetails).forEach(async (key) => {
    let value = noInsuranceDetails[key];

    try {
      NoInsuranceDetailsYupSchema()[key].validateSync(value);
      errors[key] = null;
    } catch (err) {
      isValid = false;
      errors[key] = err.message;
    }
  });

  return {
    errors,
    isValid,
  };
};

// Here schema is created as a function so that it can be triggered when the languages change
const NoInsuranceDetailsYupSchema = () => ({
  noInsuranceIdType: Yup.string().required(
    getString('insuranceIdTypeRequired'),
  ),
  noInsuranceIdNumber: Yup.string()
    .required(getString('insuranceIdNumberRequired'))
    .matches(/(^$|^([a-z,A-Z,0-9]+\S*[0-9]+)+|^([0-9]+\S*[a-z,A-Z,0-9]+)+$)/, {
      message: getString('insuranceIdNumberValidationMessage'),
    }),
  noInsuranceState: Yup.string().required(getString('insuranceStateRequired')),
});
