import React from 'react';
import { FiCheck } from 'vyaguta-icons/fi';

import Button from 'components/common/Button';
import CancelledMessage from 'components/common/CanceledMessage';
import NavBar from '../../../../COVID/common/navbar';
import Footer from '../../../../COVID/common/footer';
import { getString } from '../../../../../util/lang';
import { DetailContext } from '../../../../../context';
import { GoogleMapUrl } from '../../../../../constants/success';
import * as routes from '../../../../../constants/routes/covidRoutes';
import { getFormattedFullName } from '../../../../../util/TextFormat';

class CancelPage extends React.Component {
  componentWillMount() {
    const { firstPageLoaded } = this.context.details;

    if (!firstPageLoaded) {
      const { organizationId } = this.props.match.params;

      const route = `${routes.SERVICE}/${organizationId ? organizationId : ''}`;
      this.props.history.push({
        pathname: route,
        search: window.location.search,
      });
    }
  }

  /**
   * Create a goole map link for clinic location
   * @param {object} clinic
   */
  getClinicLocationLink = (clinic) => {
    if (!clinic) return '';
    const { smvs_latitude, smvs_longitude } = clinic;
    const clinicMapUrl = `${GoogleMapUrl}${smvs_latitude},${smvs_longitude}`;

    return clinicMapUrl;
  };

  getPatientName = (patientInfo) => {
    if (patientInfo.name) return patientInfo.name;

    const { firstName, lastName, middleName } = patientInfo;

    return getFormattedFullName({ firstName, middleName, lastName });
  };

  getLocationFromClinic = (clinic) => {
    if (!clinic) return '';

    const { smvs_address, smvs_city, smvs_zipcode, smvs_name } = clinic;

    return `${smvs_name}, ${smvs_address}, ${smvs_city}, ${smvs_zipcode}`;
  };

  checkIfCancelled = (appointments) => {
    const { firstAppointment, secondAppointment } = appointments;

    return !!(firstAppointment?.cancelled || secondAppointment?.cancelled);
  };

  bookAnotherAppointment = () => {
    const { resetForNewPatient } = this.context;

    resetForNewPatient();

    const { organizationId } = this.context.details;

    const orgPath = organizationId ? `/${organizationId}` : '';

    const route = `${routes.SERVICE}${orgPath}`;
    this.props.history.push({
      pathname: route,
      search: window.location.search,
    });
  };

  render() {
    const { appointments, firstPageLoaded } = this.context.details;

    const isAlreadyCancelled = this.checkIfCancelled(appointments);

    if (!firstPageLoaded) return null;

    return (
      <>
        <NavBar displayNavLinks={false} />
        <div className="main-wrapper">
          <div className="container">
            {isAlreadyCancelled ? (
              <CancelledMessage
                message={getString('appointmentCancelledMessage')}
                reBook={this.bookAnotherAppointment}
                showBookAppointmentButton={true}
              />
            ) : (
              <div className="success">
                <section className="selected-details section__margin">
                  <div className="border border-radius section__container p-0x">
                    <FiCheck
                      size={24}
                      className="success-check success-check--covid mt-9x"
                    />
                    <div className="success-content border-bottom">
                      <p tabIndex="0" className="mb-0x-md">
                        {getString('cancelAppointmentMessage')}
                      </p>
                      <div className="success-action-covid">
                        <Button
                          title={getString('bookAppointmentTitle')}
                          isEnabled
                          onClick={this.bookAnotherAppointment}
                          className="btn btn--large btn-primary--outline"
                          isActive
                          label={getString('bookAppointment')}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

CancelPage.contextType = DetailContext;

export default CancelPage;
