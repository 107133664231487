import { useAppParams } from '../../../context/useAppParams';
import { useLocation } from 'react-router-dom';
import { useNavigationHelper } from '../../../hooks/useNavigationHelper';
import {
  useAppSettings,
  useDetailContext,
  useSelectionsContext,
} from '../../../context';
import { useCallback, useEffect, useState } from 'react';
import { getLanguageIdByLanguageCode, getString } from '../../../util/lang';
import { handleError } from '../../../util/errorHandler';
import { InfoWithContact } from '../InfoWithContact';
import { iconInfo } from '../../../assets/images';

export const LateSubmissionInfo = () => {
  const { organizationId } = useAppParams();
  const {
    state: { selectedSubServiceIds },
  } = useLocation();
  const nav = useNavigationHelper();

  const {
    fetchServices,
    details: { appLanguageCode },
  } = useDetailContext();
  const { setSelectedServices } = useSelectionsContext();

  const { organizationContactPhone, organizationContactEmail, languages } =
    useAppSettings();
  const organizationMessages = [
    { title: 'Phone number', message: organizationContactPhone },
    { title: 'Email address', message: organizationContactEmail },
  ];

  const [isLoading, setIsLoading] = useState(false);

  // if the page was opened directly, go to first page
  useEffect(() => {
    if (
      !Array.isArray(selectedSubServiceIds) ||
      selectedSubServiceIds.length === 0
    ) {
      nav.resetToFirstPage();
    }
  }, [nav, selectedSubServiceIds]);

  const languageId = getLanguageIdByLanguageCode(appLanguageCode, languages);

  const onBookNewAppointment = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        subServices: { byId: subServicesById },
      } = await fetchServices(languageId, organizationId);

      const subServices = selectedSubServiceIds.map(
        (subServiceId) => subServicesById[subServiceId],
      );
      const validSubServices = subServices.filter((subService) => subService);
      setIsLoading(false);

      if (validSubServices.length === 0) {
        // no valid
        nav.resetToFirstPage();
        return;
      }

      setSelectedServices(
        subServices.map(({ id: subServiceId, serviceId, serviceTypeId }) => ({
          serviceTypeId,
          serviceId,
          subServiceId,
        })),
      );
      nav.toSelectLocation();
    } catch (e) {
      handleError(e);
      setIsLoading(false);
    }
  }, [
    nav,
    setSelectedServices,
    fetchServices,
    selectedSubServiceIds,
    languageId,
    organizationId,
  ]);

  return (
    <InfoWithContact
      icon={
        <img
          tabIndex="0"
          src={iconInfo}
          alt={getString('hurrayAlt')}
          className="mb-4x"
        />
      }
      contents={getString('pastAppointmentDateMessage')}
      onBtnClick={onBookNewAppointment}
      contactInfoMessages={organizationMessages}
      isLoading={isLoading}
    />
  );
};
