import { useCallback, useMemo } from 'react';
import { useAppSettings, useDetailContext } from 'context';
import { useSelectedServiceNames } from 'context/useSelectedServiceNames';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { InfoWithContact } from 'components/common/InfoWithContact';
import { AppointmentStatuses } from 'constants/appointment';
import { FiInfo } from 'vyaguta-icons/fi';
import { getString } from 'util/lang';

export const InvalidAppointmentMessage = () => {
  const { organizationContactPhone, organizationContactEmail } =
    useAppSettings();
  const {
    details: { appointments },
  } = useDetailContext();

  const serviceNames = useSelectedServiceNames();

  const contactInfoMessages = useMemo(
    () => [
      { title: 'Phone Number', message: organizationContactPhone },
      { title: 'Email address', message: organizationContactEmail },
    ],
    [organizationContactPhone, organizationContactEmail],
  );
  const nav = useNavigationHelper();

  const onClickBookAppointment = useCallback(() => {
    nav.resetToFirstPage();
  }, [nav]);

  const appointmentStatus = appointments?.[0]?.appointmentStatus;

  switch (appointmentStatus) {
    case AppointmentStatuses.CANCELLED:
      return (
        <InfoWithContact
          icon={<FiInfo size={24} className="success-check info" />}
          onBtnClick={onClickBookAppointment}
          contactInfoMessages={contactInfoMessages}
          contents={getString('followupCancelledMessage', {
            serviceName: <>{serviceNames}</>,
          })}
        />
      );
    case AppointmentStatuses.NO_SHOW:
      return (
        <InfoWithContact
          icon={<FiInfo size={24} className="success-check info" />}
          onBtnClick={onClickBookAppointment}
          contactInfoMessages={contactInfoMessages}
          contents={getString('noShowMessage')}
        />
      );
    case AppointmentStatuses.PENDING:
      return (
        <InfoWithContact
          icon={<FiInfo size={24} className="success-check info" />}
          onBtnClick={onClickBookAppointment}
          contactInfoMessages={contactInfoMessages}
          contents={getString('appointmentPendingInformation')}
        />
      );
    default:
      // Just in case
      return (
        <InfoWithContact
          icon={<FiInfo size={24} className="success-check info" />}
          onBtnClick={onClickBookAppointment}
          contactInfoMessages={contactInfoMessages}
          contents={getString('appointmentFollowupCompleteInvalid')}
        />
      );
  }
};
