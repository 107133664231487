import { getString } from '../util/lang';

export const PaymentOptions = {
  Insurance: '153940000',
  NoInsurance: '153940002',
  PayAtTimeOfService: '153940003',
};

export const AddressFilledTypeOptions = {
  Verified: '153940000',
  Manual: '153940001',
};

// TODO: convert to using keywords during/after createApplication conversion
// export const InsuranceType = {
//   Medicare: 'MEDICARE',
//   MedicareAdvantage: 'MEDICARE_ADVANTAGE',
//   Commercial: 'COMMERCIAL',
//   Medicaid: 'MEDICAID',
// };
export const InsuranceType = {
  Medicare: '153940000',
  MedicareAdvantage: '153940001',
  Commercial: '153940002',
  Medicaid: '153940003',
  MilitaryAndVeterans: '153940004',
};

export const AvailableInsuranceCoverageIndices = {
  primaryInsuranceIndex: 0,
  secondaryInsuranceIndex: 1,
  tertiaryInsuranceIndex: 2,
  otherInsuranceIndexStart: 3, // start of other insurance index
  maxOtherInsuranceIndex: 4,
};

export const InsuranceCoverageTypes = {
  [AvailableInsuranceCoverageIndices.primaryInsuranceIndex]: 153940000,
  [AvailableInsuranceCoverageIndices.secondaryInsuranceIndex]: 153940001,
  [AvailableInsuranceCoverageIndices.tertiaryInsuranceIndex]: 153940002,
  [AvailableInsuranceCoverageIndices.otherInsuranceIndexStart]: 153940003,
};

/**
 * primary, secondary, tertiary and other insurance types for an individual
 * @type {number}
 */
export const TotalNoOfInsuranceCoverages = 5; //possible total placeholders for insurances
export const MaxNoOfInsuranceCoverages = 5; // actual number of insurances that can be created

export const InsuranceTypeOptions = [
  {
    label: () => getString('insuranceTypeMedicare'),
    value: InsuranceType.Medicare,
    insuranceType: 'MEDICARE',
  },
  {
    label: () => getString('insuranceTypeMedicareAdvantage'),
    value: InsuranceType.MedicareAdvantage,
    insuranceType: 'MEDICARE_ADVANTAGE',
  },
  {
    label: () => getString('insuranceTypeCommercial'),
    value: InsuranceType.Commercial,
    insuranceType: 'COMMERCIAL',
  },
  {
    label: () => getString('insuranceTypeMedicaid'),
    value: InsuranceType.Medicaid,
    insuranceType: 'MEDICAID',
  },
  {
    label: () => getString('insuranceTypeMilitaryAndVeterans'),
    value: InsuranceType.MilitaryAndVeterans,
    insuranceType: 'MILITARY_AND_VETERANS',
  },
];

export const InsurancePlanTypeOptions = [
  {
    label: () => getString('insurancePlanTypePPO'),
    value: 153940000,
  },
  {
    label: () => getString('insurancePlanTypeHMO'),
    value: 153940001,
  },
  {
    label: () => getString('insurancePlanTypeOthers'),
    value: 153940002,
  },
  {
    label: () => getString('insurancePlanTypeDontKnow'),
    value: 153940003,
  },
];

// For label strings for strings pulled from lang files,here we put label as functions call these functions where it is used.
// This is because the "localized strings" exported from these types of constant files do not
// reflect changes in react component after selected language is changed after it is exported as a constant variable
// Please use SelectBoxLocalized.js Component for showing select boxes in such cases

export const SubscriberRelationship = [
  {
    label: () => getString('relationshipSelf'),
    value: 153940000,
  },
  {
    label: () => getString('relationshipSpouse'),
    value: 153940001,
  },
  {
    label: () => getString('relationshipChild'),
    value: 153940002,
  },
  {
    label: () => getString('relationShipOther'),
    value: 153940003,
  },
];

export const SubscriberRelationshipKeys = {
  self: getString('relationshipSelf'),
  spouse: getString('relationshipSpouse'),
  child: getString('relationshipChild'),
  other: getString('relationShipOther'),
};

export const SubscriberRelationshipCode = {
  Self: 153940000,
  Spouse: 153940001,
  Child: 153940002,
  Other: 153940003,
};

export const InsuranceCardLogicalName = {
  InsuranceCardFront: 'smvs_insurance_photo',
  InsuranceCardBack: 'smvs_insurance_backside_card',
};

export const InsuranceCardLimit = {
  InsuranceCardMaxSize: 2 * 1024 * 1024,
};

export const GenderOptions = [
  {
    label: () => getString('genderMale'),
    value: 1,
  },
  {
    label: () => getString('genderFemale'),
    value: 2,
  },
  {
    label: () => getString('genderTrans'),
    value: 153940002,
  },
  {
    label: () => getString('genderNonBinary'),
    value: 153940003,
  },
  {
    label: () => getString('genderOther'),
    value: 153940001,
  },
];

export const RaceOptions = [
  {
    label: () => getString('raceAsian'),
    value: 153940000,
  },
  {
    label: () => getString('raceNative'),
    value: 153940001,
  },
  {
    label: () => getString('raceBlack'),
    value: 153940002,
  },
  {
    label: () => getString('racePacific'),
    value: 153940003,
  },
  {
    label: () => getString('raceWhite'),
    value: 153940004,
  },
  {
    label: () => getString('raceOther'),
    value: 153940006,
  },
  {
    label: () => getString('raceNoPreference'),
    value: 153940005,
  },
];

export const EthnicityOptions = [
  {
    label: () => getString('ethnicityHispanic'),
    value: 153940000,
  },
  {
    label: () => getString('ethnicityNotHispanic'),
    value: 153940003,
  },
  {
    label: () => getString('ethnicityNoPreference'),
    value: 153940001,
  },
];

export const MonthList = [
  {
    label: () => getString('januaryShort'),
    value: '1',
  },
  {
    label: () => getString('februaryShort'),
    value: '2',
  },
  {
    label: () => getString('marchShort'),
    value: '3',
  },
  {
    label: () => getString('aprilShort'),
    value: '4',
  },
  {
    label: () => getString('mayShort'),
    value: '5',
  },
  {
    label: () => getString('juneShort'),
    value: '6',
  },
  {
    label: () => getString('julyShort'),
    value: '7',
  },
  {
    label: () => getString('augustShort'),
    value: '8',
  },
  {
    label: () => getString('septemberShort'),
    value: '9',
  },
  {
    label: () => getString('octoberShort'),
    value: '10',
  },
  {
    label: () => getString('novemberShort'),
    value: '11',
  },
  {
    label: () => getString('decemberShort'),
    value: '12',
  },
];

export const DayList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
].map(function (val) {
  return { label: val, value: val };
});

export const PatientBasicInfoKeys = [
  'firstName',
  'lastName',
  'middleName',
  'birthMonth',
  'birthDay',
  'race',
  'ethnicity',
  'birthYear',
  'njisValidation',
  'gender',
  'mobileNo',
  'email',
  'confirmEmail',
  'referredBy',
  'homePhoneNo',
];

export const GroupPatientBasicInfoKeys = [
  'firstName',
  'lastName',
  'middleName',
  'birthMonth',
  'birthDay',
  'race',
  'ethnicity',
  'birthYear',
  'njisValidation',
  'gender',
  'referredBy',
];

export const ContactInfoKeys = [
  'njisValidation',
  'mobileNo',
  'email',
  'confirmEmail',
];

export const ContactUsKeys = [
  'firstName',
  'lastName',
  'middleName',
  'email',
  'confirmEmail',
];

export const PatientGuardianInfoKeys = [
  'guardianFirstName',
  'guardianMiddleName',
  'guardianLastName',
];

export const PatientAddressInfoKeys = [
  'homeAddress',
  'aptUnit',
  'city',
  'state',
  'county',
  'zipCode',
];

export const InsuranceInfoKeys = [
  'company',
  'insuranceType',
  'insurancePlanType',
  'otherInsuranceCompany',
  'memberID',
  'memberIDSuffix',
  'memberIDPrefix',
  'cardHolderFirstName',
  'cardHolderLastName',
  'cardHolderMiddleName',
  'groupNumber',
  'insuranceCardFront',
  'insuranceCardBack',
  'coverageID',
  'rxBin',
  'rxPCN',
  'rxGroup',
  'prefixMemberID',
  'suffixMemberID',
  'subscriberRelationship',
];
export const acceptanceInfoKeys = [
  'signatureUrl',
  'guardian',
  'acceptedTerms',
  'acceptedTerms2',
];

export const NoInsuranceInfoKeys = [
  'noInsuranceIdType',
  'noInsuranceIdNumber',
  'noInsuranceState',
  'noInsuranceAttempted',
];

export const PatientPhysicianInfoKeys = [
  'physicianName',
  'physicianPhoneNumber',
];
export const RequiredInsuranceFields = ['company', 'memberID'];

// Show the field as a required field
export const ShowAsRequiredFields = {
  firstName: true,
  lastName: true,
  middleName: false,
  guardianFirstName: true,
  guardianLastName: true,
  guardianMiddleName: false,
  isUnderNineteen: true,
  birthMonth: true,
  birthDay: true,
  birthYear: true,
  gender: true,
  mobileNo: true,
  email: true,
  confirmEmail: true,
  referredBy: false,
  homeAddress: true,
  aptUnit: false,
  city: true,
  state: true,
  county: true,
  zipCode: true,
  memberID: true,
  groupNumber: false,
  coverageID: false,
  company: true,
  race: true,
  ethnicity: true,
  primaryLanguage: true,
  otherInsuranceCompany: true,
  insurancePlanType: true,
  age: true,
  subscriberRelationship: true,
  cardHolderFirstName: true,
  cardHolderLastName: true,
  physicianName: false,
  physicianPhoneNumber: false,
  homePhoneNo: false,
};

export const MaxSignatureSize = 30000;

export const IdTypesOptions = [
  { label: getString('driversLicense'), value: 153940000 },
  { label: getString('otherStateId'), value: 153940001 },
];

export const StateDropDown = [
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DE', value: 'DE' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' },
];

export const YearList = () => {
  const baseYear = 1899;
  const currentYear = new Date().getUTCFullYear();
  const count = currentYear - baseYear;
  const years = new Array(count).fill().map((_, idx) => ({
    label: currentYear - idx,
    value: currentYear - idx,
  }));
  return years;
};

// smvs_language

export const LanguageOptions = [
  { label: 'Amharic', value: '153940000' },
  { label: 'Arabic', value: '153940001' },
  { label: 'ASL', value: '153940002' },
  { label: 'Burmese', value: '153940003' },
  { label: 'Cantonese', value: '153940004' },
  { label: 'Chuukese', value: '153940005' },
  { label: 'English', value: '153940037' },
  { label: 'Filipino/Pilipino', value: '153940006' },
  { label: 'French', value: '153940007' },
  { label: 'German', value: '153940008' },
  { label: 'Hindi', value: '153940009' },
  { label: 'Hmong', value: '153940010' },
  { label: 'Japanese', value: '153940011' },
  { label: 'Karen Languages', value: '153940012' },
  { label: 'Khmer/Cambodian', value: '153940038' },
  { label: 'Korean', value: '153940013' },
  { label: 'Lao', value: '153940014' },
  { label: 'Mandarin', value: '153940015' },
  { label: 'Marshallese', value: '153940016' },
  { label: 'Nepali', value: '153940017' },
  { label: 'Oromo', value: '153940018' },
  { label: 'Persian', value: '153940019' },
  { label: 'Portuguese', value: '153940020' },
  { label: 'Punjabi', value: '153940021' },
  { label: 'Romanian', value: '153940022' },
  { label: 'Russian', value: '153940023' },
  { label: 'Samoan', value: '153940024' },
  { label: 'Somali', value: '153940025' },
  { label: 'Spanish', value: '153940026' },
  { label: 'Swahili', value: '153940027' },
  { label: 'Tagalog', value: '153940028' },
  { label: 'Tamil', value: '153940029' },
  { label: 'Telugu', value: '153940030' },
  { label: 'Thai', value: '153940031' },
  { label: 'Tigrinya', value: '153940032' },
  { label: 'Urdu', value: '153940033' },
  { label: 'Ukrainian', value: '153940034' },
  { label: 'Vietnamese', value: '153940035' },
  { label: 'Other Languages', value: '153940036' },
];

export const appointmentStatus = {
  booked: 935000002,
  noShow: 935000006,
  fulfilled: 935000004,
  cancelled: 935000005,
  waitlist: 935000009,
};

export const CountyDropDown = {
  CA: [
    { label: 'Los Angeles', value: 'Los Angeles' },
    { label: 'San Diego', value: 'San Diego' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Riverside', value: 'Riverside' },
    { label: 'San Bernardino', value: 'San Bernardino' },
    { label: 'Santa Clara', value: 'Santa Clara' },
    { label: 'Alameda', value: 'Alameda' },
    { label: 'Sacramento', value: 'Sacramento' },
    { label: 'Contra Costa', value: 'Contra Costa' },
    { label: 'Fresno', value: 'Fresno' },
    { label: 'Kern', value: 'Kern' },
    { label: 'San Francisco', value: 'San Francisco' },
    { label: 'Ventura', value: 'Ventura' },
    { label: 'San Mateo', value: 'San Mateo' },
    { label: 'San Joaquin', value: 'San Joaquin' },
    { label: 'Stanislaus', value: 'Stanislaus' },
    { label: 'Sonoma', value: 'Sonoma' },
    { label: 'Tulare', value: 'Tulare' },
    { label: 'Santa Barbara', value: 'Santa Barbara' },
    { label: 'Solano', value: 'Solano' },
    { label: 'Monterey', value: 'Monterey' },
    { label: 'Placer', value: 'Placer' },
    { label: 'San Luis Obispo', value: 'San Luis Obispo' },
    { label: 'Santa Cruz', value: 'Santa Cruz' },
    { label: 'Merced', value: 'Merced' },
    { label: 'Marin', value: 'Marin' },
    { label: 'Butte', value: 'Butte' },
    { label: 'Yolo', value: 'Yolo' },
    { label: 'El Dorado', value: 'El Dorado' },
    { label: 'Imperial', value: 'Imperial' },
    { label: 'Shasta', value: 'Shasta' },
    { label: 'Madera', value: 'Madera' },
    { label: 'Kings', value: 'Kings' },
    { label: 'Napa', value: 'Napa' },
    { label: 'Humboldt', value: 'Humboldt' },
    { label: 'Nevada', value: 'Nevada' },
    { label: 'Sutter', value: 'Sutter' },
    { label: 'Mendocino', value: 'Mendocino' },
    { label: 'Yuba', value: 'Yuba' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Tehama', value: 'Tehama' },
    { label: 'San Benito', value: 'San Benito' },
    { label: 'Tuolumne', value: 'Tuolumne' },
    { label: 'Calaveras', value: 'Calaveras' },
    { label: 'Siskiyou', value: 'Siskiyou' },
    { label: 'Amador', value: 'Amador' },
    { label: 'Lassen', value: 'Lassen' },
    { label: 'Glenn', value: 'Glenn' },
    { label: 'Del Norte', value: 'Del Norte' },
    { label: 'Colusa', value: 'Colusa' },
    { label: 'Plumas', value: 'Plumas' },
    { label: 'Inyo', value: 'Inyo' },
    { label: 'Mariposa', value: 'Mariposa' },
    { label: 'Mono', value: 'Mono' },
    { label: 'Trinity', value: 'Trinity' },
    { label: 'Modoc', value: 'Modoc' },
    { label: 'Sierra', value: 'Sierra' },
    { label: 'Alpine', value: 'Alpine' },
  ],
  IL: [
    { label: 'Cook', value: 'Cook' },
    { label: 'DuPage', value: 'DuPage' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Will', value: 'Will' },
    { label: 'Kane', value: 'Kane' },
    { label: 'McHenry', value: 'McHenry' },
    { label: 'Winnebago', value: 'Winnebago' },
    { label: 'Madison', value: 'Madison' },
    { label: 'St. Clair', value: 'St. Clair' },
    { label: 'Champaign', value: 'Champaign' },
    { label: 'Sangamon', value: 'Sangamon' },
    { label: 'Peoria', value: 'Peoria' },
    { label: 'McLean', value: 'McLean' },
    { label: 'Rock Island', value: 'Rock Island' },
    { label: 'Tazewell', value: 'Tazewell' },
    { label: 'Kendall', value: 'Kendall' },
    { label: 'Kankakee', value: 'Kankakee' },
    { label: 'LaSalle', value: 'LaSalle' },
    { label: 'Macon', value: 'Macon' },
    { label: 'DeKalb', value: 'DeKalb' },
    { label: 'Vermilion', value: 'Vermilion' },
    { label: 'Williamson', value: 'Williamson' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Whiteside', value: 'Whiteside' },
    { label: 'Boone', value: 'Boone' },
    { label: 'Coles', value: 'Coles' },
    { label: 'Ogle', value: 'Ogle' },
    { label: 'Grundy', value: 'Grundy' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Macoupin', value: 'Macoupin' },
    { label: 'Stephenson', value: 'Stephenson' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Woodford', value: 'Woodford' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Livingston', value: 'Livingston' },
    { label: 'Fulton', value: 'Fulton' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Effingham', value: 'Effingham' },
    { label: 'Bureau', value: 'Bureau' },
    { label: 'Christian', value: 'Christian' },
    { label: 'Randolph', value: 'Randolph' },
    { label: 'McDonough', value: 'McDonough' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Iroquois', value: 'Iroquois' },
    { label: 'Saline', value: 'Saline' },
    { label: 'Jersey', value: 'Jersey' },
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Jo Daviess', value: 'Jo Daviess' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Edgar', value: 'Edgar' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Union', value: 'Union' },
    { label: 'Bond', value: 'Bond' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Piatt', value: 'Piatt' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'De Witt', value: 'De Witt' },
    { label: 'Richland', value: 'Richland' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Moultrie', value: 'Moultrie' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Massac', value: 'Massac' },
    { label: 'Washington', value: 'Washington' },
    { label: 'White', value: 'White' },
    { label: 'Mason', value: 'Mason' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Ford', value: 'Ford' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Cass', value: 'Cass' },
    { label: 'Menard', value: 'Menard' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Wabash', value: 'Wabash' },
    { label: 'Cumberland', value: 'Cumberland' },
    { label: 'Jasper', value: 'Jasper' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'Schuyler', value: 'Schuyler' },
    { label: 'Henderson', value: 'Henderson' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Edwards', value: 'Edwards' },
    { label: 'Alexander', value: 'Alexander' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'Pulaski', value: 'Pulaski' },
    { label: 'Stark', value: 'Stark' },
    { label: 'Gallatin', value: 'Gallatin' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Pope', value: 'Pope' },
    { label: 'Hardin', value: 'Hardin' },
  ],
  TX: [
    { label: 'Harris', value: 'Harris' },
    { label: 'Dallas', value: 'Dallas' },
    { label: 'Tarrant', value: 'Tarrant' },
    { label: 'Bexar', value: 'Bexar' },
    { label: 'Travis', value: 'Travis' },
    { label: 'Collin', value: 'Collin' },
    { label: 'Hidalgo', value: 'Hidalgo' },
    { label: 'El Paso', value: 'El Paso' },
    { label: 'Denton', value: 'Denton' },
    { label: 'Fort Bend', value: 'Fort Bend' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Williamson', value: 'Williamson' },
    { label: 'Cameron', value: 'Cameron' },
    { label: 'Nueces', value: 'Nueces' },
    { label: 'Brazoria', value: 'Brazoria' },
    { label: 'Bell', value: 'Bell' },
    { label: 'Galveston', value: 'Galveston' },
    { label: 'Lubbock', value: 'Lubbock' },
    { label: 'Webb', value: 'Webb' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'McLennan', value: 'McLennan' },
    { label: 'Smith', value: 'Smith' },
    { label: 'Brazos', value: 'Brazos' },
    { label: 'Hays', value: 'Hays' },
    { label: 'Ellis', value: 'Ellis' },
    { label: 'Midland', value: 'Midland' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Ector', value: 'Ector' },
    { label: 'Guadalupe', value: 'Guadalupe' },
    { label: 'Comal', value: 'Comal' },
    { label: 'Taylor', value: 'Taylor' },
    { label: 'Randall', value: 'Randall' },
    { label: 'Parker', value: 'Parker' },
    { label: 'Wichita', value: 'Wichita' },
    { label: 'Grayson', value: 'Grayson' },
    { label: 'Kaufman', value: 'Kaufman' },
    { label: 'Gregg', value: 'Gregg' },
    { label: 'Potter', value: 'Potter' },
    { label: 'Tom Green', value: 'Tom Green' },
    { label: 'Rockwall', value: 'Rockwall' },
    { label: 'Hunt', value: 'Hunt' },
    { label: 'Bowie', value: 'Bowie' },
    { label: 'Victoria', value: 'Victoria' },
    { label: 'Angelina', value: 'Angelina' },
    { label: 'Bastrop', value: 'Bastrop' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Liberty', value: 'Liberty' },
    { label: 'Henderson', value: 'Henderson' },
    { label: 'Coryell', value: 'Coryell' },
    { label: 'Walker', value: 'Walker' },
    { label: 'San Patricio', value: 'San Patricio' },
    { label: 'Harrison', value: 'Harrison' },
    { label: 'Wise', value: 'Wise' },
    { label: 'Nacogdoches', value: 'Nacogdoches' },
    { label: 'Starr', value: 'Starr' },
    { label: 'Hood', value: 'Hood' },
    { label: 'Maverick', value: 'Maverick' },
    { label: 'Anderson', value: 'Anderson' },
    { label: 'Hardin', value: 'Hardin' },
    { label: 'Van Zandt', value: 'Van Zandt' },
    { label: 'Rusk', value: 'Rusk' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Kerr', value: 'Kerr' },
    { label: 'Waller', value: 'Waller' },
    { label: 'Medina', value: 'Medina' },
    { label: 'Lamar', value: 'Lamar' },
    { label: 'Atascosa', value: 'Atascosa' },
    { label: 'Wilson', value: 'Wilson' },
    { label: 'Navarro', value: 'Navarro' },
    { label: 'Val Verde', value: 'Val Verde' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Burnet', value: 'Burnet' },
    { label: 'Wood', value: 'Wood' },
    { label: 'Kendall', value: 'Kendall' },
    { label: 'Caldwell', value: 'Caldwell' },
    { label: 'Erath', value: 'Erath' },
    { label: 'Wharton', value: 'Wharton' },
    { label: 'Chambers', value: 'Chambers' },
    { label: 'Upshur', value: 'Upshur' },
    { label: 'Jim Wells', value: 'Jim Wells' },
    { label: 'Cooke', value: 'Cooke' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Matagorda', value: 'Matagorda' },
    { label: 'Hopkins', value: 'Hopkins' },
    { label: 'Howard', value: 'Howard' },
    { label: 'Hill', value: 'Hill' },
    { label: 'Jasper', value: 'Jasper' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Fannin', value: 'Fannin' },
    { label: 'Hale', value: 'Hale' },
    { label: 'Titus', value: 'Titus' },
    { label: 'Bee', value: 'Bee' },
    { label: 'Kleberg', value: 'Kleberg' },
    { label: 'Cass', value: 'Cass' },
    { label: 'Austin', value: 'Austin' },
    { label: 'Palo Pinto', value: 'Palo Pinto' },
    { label: 'San Jacinto', value: 'San Jacinto' },
    { label: 'Grimes', value: 'Grimes' },
    { label: 'Uvalde', value: 'Uvalde' },
    { label: 'Gillespie', value: 'Gillespie' },
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Milam', value: 'Milam' },
    { label: 'Aransas', value: 'Aransas' },
    { label: 'Limestone', value: 'Limestone' },
    { label: 'Panola', value: 'Panola' },
    { label: 'Hockley', value: 'Hockley' },
    { label: 'Houston', value: 'Houston' },
    { label: 'Gray', value: 'Gray' },
    { label: 'Bandera', value: 'Bandera' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Willacy', value: 'Willacy' },
    { label: 'Tyler', value: 'Tyler' },
    { label: 'Moore', value: 'Moore' },
    { label: 'Hutchinson', value: 'Hutchinson' },
    { label: 'Colorado', value: 'Colorado' },
    { label: 'Llano', value: 'Llano' },
    { label: 'Lampasas', value: 'Lampasas' },
    { label: 'Gonzales', value: 'Gonzales' },
    { label: 'Gaines', value: 'Gaines' },
    { label: 'DeWitt', value: 'DeWitt' },
    { label: 'Lavaca', value: 'Lavaca' },
    { label: 'Jones', value: 'Jones' },
    { label: 'Frio', value: 'Frio' },
    { label: 'Freestone', value: 'Freestone' },
    { label: 'Montague', value: 'Montague' },
    { label: 'Deaf Smith', value: 'Deaf Smith' },
    { label: 'Bosque', value: 'Bosque' },
    { label: 'Eastland', value: 'Eastland' },
    { label: 'Burleson', value: 'Burleson' },
    { label: 'Young', value: 'Young' },
    { label: 'Andrews', value: 'Andrews' },
    { label: 'Falls', value: 'Falls' },
    { label: 'Leon', value: 'Leon' },
    { label: 'Scurry', value: 'Scurry' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Robertson', value: 'Robertson' },
    { label: 'Pecos', value: 'Pecos' },
    { label: 'Karnes', value: 'Karnes' },
    { label: 'Reeves', value: 'Reeves' },
    { label: 'Nolan', value: 'Nolan' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Trinity', value: 'Trinity' },
    { label: 'Zapata', value: 'Zapata' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Newton', value: 'Newton' },
    { label: 'Callahan', value: 'Callahan' },
    { label: 'Comanche', value: 'Comanche' },
    { label: 'Lamb', value: 'Lamb' },
    { label: 'Camp', value: 'Camp' },
    { label: 'Wilbarger', value: 'Wilbarger' },
    { label: 'Dawson', value: 'Dawson' },
    { label: 'Terry', value: 'Terry' },
    { label: 'Morris', value: 'Morris' },
    { label: 'Red River', value: 'Red River' },
    { label: 'Live Oak', value: 'Live Oak' },
    { label: 'Zavala', value: 'Zavala' },
    { label: 'Rains', value: 'Rains' },
    { label: 'Ward', value: 'Ward' },
    { label: 'Blanco', value: 'Blanco' },
    { label: 'Duval', value: 'Duval' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Sabine', value: 'Sabine' },
    { label: 'Dimmit', value: 'Dimmit' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Runnels', value: 'Runnels' },
    { label: 'Ochiltree', value: 'Ochiltree' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Parmer', value: 'Parmer' },
    { label: 'Stephens', value: 'Stephens' },
    { label: 'Brewster', value: 'Brewster' },
    { label: 'Somervell', value: 'Somervell' },
    { label: 'Jack', value: 'Jack' },
    { label: 'Archer', value: 'Archer' },
    { label: 'Yoakum', value: 'Yoakum' },
    { label: 'Mitchell', value: 'Mitchell' },
    { label: 'Coleman', value: 'Coleman' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'San Augustine', value: 'San Augustine' },
    { label: 'McCulloch', value: 'McCulloch' },
    { label: 'Winkler', value: 'Winkler' },
    { label: 'Castro', value: 'Castro' },
    { label: 'Goliad', value: 'Goliad' },
    { label: 'Swisher', value: 'Swisher' },
    { label: 'La Salle', value: 'La Salle' },
    { label: 'Dallam', value: 'Dallam' },
    { label: 'Childress', value: 'Childress' },
    { label: 'Refugio', value: 'Refugio' },
    { label: 'Brooks', value: 'Brooks' },
    { label: 'Bailey', value: 'Bailey' },
    { label: 'Presidio', value: 'Presidio' },
    { label: 'Garza', value: 'Garza' },
    { label: 'Carson', value: 'Carson' },
    { label: 'San Saba', value: 'San Saba' },
    { label: 'Crosby', value: 'Crosby' },
    { label: 'Lynn', value: 'Lynn' },
    { label: 'Floyd', value: 'Floyd' },
    { label: 'Haskell', value: 'Haskell' },
    { label: 'Hartley', value: 'Hartley' },
    { label: 'Martin', value: 'Martin' },
    { label: 'Hansford', value: 'Hansford' },
    { label: 'Wheeler', value: 'Wheeler' },
    { label: 'Delta', value: 'Delta' },
    { label: 'Jim Hogg', value: 'Jim Hogg' },
    { label: 'Mills', value: 'Mills' },
    { label: 'Crane', value: 'Crane' },
    { label: 'Hudspeth', value: 'Hudspeth' },
    { label: 'Kimble', value: 'Kimble' },
    { label: 'Mason', value: 'Mason' },
    { label: 'Hemphill', value: 'Hemphill' },
    { label: 'Hardeman', value: 'Hardeman' },
    { label: 'Fisher', value: 'Fisher' },
    { label: 'Sutton', value: 'Sutton' },
    { label: 'Reagan', value: 'Reagan' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Upton', value: 'Upton' },
    { label: 'Kinney', value: 'Kinney' },
    { label: 'Baylor', value: 'Baylor' },
    { label: 'Crockett', value: 'Crockett' },
    { label: 'Real', value: 'Real' },
    { label: 'Lipscomb', value: 'Lipscomb' },
    { label: 'Donley', value: 'Donley' },
    { label: 'Coke', value: 'Coke' },
    { label: 'Shackelford', value: 'Shackelford' },
    { label: 'Concho', value: 'Concho' },
    { label: 'Sherman', value: 'Sherman' },
    { label: 'Hall', value: 'Hall' },
    { label: 'Schleicher', value: 'Schleicher' },
    { label: 'Collingsworth', value: 'Collingsworth' },
    { label: 'Cochran', value: 'Cochran' },
    { label: 'Jeff Davis', value: 'Jeff Davis' },
    { label: 'Culberson', value: 'Culberson' },
    { label: 'Dickens', value: 'Dickens' },
    { label: 'Menard', value: 'Menard' },
    { label: 'Oldham', value: 'Oldham' },
    { label: 'Armstrong', value: 'Armstrong' },
    { label: 'Edwards', value: 'Edwards' },
    { label: 'Cottle', value: 'Cottle' },
    { label: 'Irion', value: 'Irion' },
    { label: 'Glasscock', value: 'Glasscock' },
    { label: 'Stonewall', value: 'Stonewall' },
    { label: 'Briscoe', value: 'Briscoe' },
    { label: 'Throckmorton', value: 'Throckmorton' },
    { label: 'Foard', value: 'Foard' },
    { label: 'Motley', value: 'Motley' },
    { label: 'Sterling', value: 'Sterling' },
    { label: 'Terrell', value: 'Terrell' },
    { label: 'Roberts', value: 'Roberts' },
    { label: 'McMullen', value: 'McMullen' },
    { label: 'Kent', value: 'Kent' },
    { label: 'Borden', value: 'Borden' },
    { label: 'Kenedy', value: 'Kenedy' },
    { label: 'King', value: 'King' },
    { label: 'Loving', value: 'Loving' },
  ],
  FL: [
    { label: 'Miami-Dade', value: 'Miami-Dade' },
    { label: 'Broward', value: 'Broward' },
    { label: 'Palm Beach', value: 'Palm Beach' },
    { label: 'Hillsborough', value: 'Hillsborough' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Pinellas', value: 'Pinellas' },
    { label: 'Duval', value: 'Duval' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Brevard', value: 'Brevard' },
    { label: 'Volusia', value: 'Volusia' },
    { label: 'Pasco', value: 'Pasco' },
    { label: 'Seminole', value: 'Seminole' },
    { label: 'Sarasota', value: 'Sarasota' },
    { label: 'Manatee', value: 'Manatee' },
    { label: 'Collier', value: 'Collier' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Osceola', value: 'Osceola' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Escambia', value: 'Escambia' },
    { label: 'St. Lucie', value: 'St. Lucie' },
    { label: 'Leon', value: 'Leon' },
    { label: 'Alachua', value: 'Alachua' },
    { label: 'St. Johns', value: 'St. Johns' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Okaloosa', value: 'Okaloosa' },
    { label: 'Hernando', value: 'Hernando' },
    { label: 'Bay', value: 'Bay' },
    { label: 'Charlotte', value: 'Charlotte' },
    { label: 'Santa Rosa', value: 'Santa Rosa' },
    { label: 'Martin', value: 'Martin' },
    { label: 'Indian River', value: 'Indian River' },
    { label: 'Citrus', value: 'Citrus' },
    { label: 'Sumter', value: 'Sumter' },
    { label: 'Flagler', value: 'Flagler' },
    { label: 'Highlands', value: 'Highlands' },
    { label: 'Nassau', value: 'Nassau' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'Columbia', value: 'Columbia' },
    { label: 'Walton', value: 'Walton' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Gadsden', value: 'Gadsden' },
    { label: 'Suwannee', value: 'Suwannee' },
    { label: 'Okeechobee', value: 'Okeechobee' },
    { label: 'Hendry', value: 'Hendry' },
    { label: 'Levy', value: 'Levy' },
    { label: 'DeSoto', value: 'DeSoto' },
    { label: 'Wakulla', value: 'Wakulla' },
    { label: 'Baker', value: 'Baker' },
    { label: 'Bradford', value: 'Bradford' },
    { label: 'Hardee', value: 'Hardee' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Taylor', value: 'Taylor' },
    { label: 'Holmes', value: 'Holmes' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Gilchrist', value: 'Gilchrist' },
    { label: 'Dixie', value: 'Dixie' },
    { label: 'Gulf', value: 'Gulf' },
    { label: 'Union', value: 'Union' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Glades', value: 'Glades' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Lafayette', value: 'Lafayette' },
    { label: 'Liberty', value: 'Liberty' },
  ],
  AZ: [
    { label: 'Maricopa', value: 'Maricopa' },
    { label: 'Pima', value: 'Pima' },
    { label: 'Pinal', value: 'Pinal' },
    { label: 'Yavapai', value: 'Yavapai' },
    { label: 'Yuma', value: 'Yuma' },
    { label: 'Mohave', value: 'Mohave' },
    { label: 'Coconino', value: 'Coconino' },
    { label: 'Cochise', value: 'Cochise' },
    { label: 'Navajo', value: 'Navajo' },
    { label: 'Apache', value: 'Apache' },
    { label: 'Gila', value: 'Gila' },
    { label: 'Santa Cruz', value: 'Santa Cruz' },
    { label: 'Graham', value: 'Graham' },
    { label: 'La Paz', value: 'La Paz' },
    { label: 'Greenlee', value: 'Greenlee' },
  ],
  NY: [
    { label: 'Kings', value: 'Kings' },
    { label: 'Queens', value: 'Queens' },
    { label: 'New York', value: 'New York' },
    { label: 'Suffolk', value: 'Suffolk' },
    { label: 'Bronx', value: 'Bronx' },
    { label: 'Nassau', value: 'Nassau' },
    { label: 'Westchester', value: 'Westchester' },
    { label: 'Erie', value: 'Erie' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Richmond', value: 'Richmond' },
    { label: 'Onondaga', value: 'Onondaga' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Rockland', value: 'Rockland' },
    { label: 'Albany', value: 'Albany' },
    { label: 'Dutchess', value: 'Dutchess' },
    { label: 'Oneida', value: 'Oneida' },
    { label: 'Saratoga', value: 'Saratoga' },
    { label: 'Niagara', value: 'Niagara' },
    { label: 'Broome', value: 'Broome' },
    { label: 'Ulster', value: 'Ulster' },
    { label: 'Rensselaer', value: 'Rensselaer' },
    { label: 'Schenectady', value: 'Schenectady' },
    { label: 'Chautauqua', value: 'Chautauqua' },
    { label: 'Oswego', value: 'Oswego' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Ontario', value: 'Ontario' },
    { label: 'St. Lawrence', value: 'St. Lawrence' },
    { label: 'Tompkins', value: 'Tompkins' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'Steuben', value: 'Steuben' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Chemung', value: 'Chemung' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Cayuga', value: 'Cayuga' },
    { label: 'Cattaraugus', value: 'Cattaraugus' },
    { label: 'Sullivan', value: 'Sullivan' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Livingston', value: 'Livingston' },
    { label: 'Herkimer', value: 'Herkimer' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Columbia', value: 'Columbia' },
    { label: 'Otsego', value: 'Otsego' },
    { label: 'Genesee', value: 'Genesee' },
    { label: 'Fulton', value: 'Fulton' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Tioga', value: 'Tioga' },
    { label: 'Chenango', value: 'Chenango' },
    { label: 'Cortland', value: 'Cortland' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Allegany', value: 'Allegany' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Orleans', value: 'Orleans' },
    { label: 'Wyoming', value: 'Wyoming' },
    { label: 'Essex', value: 'Essex' },
    { label: 'Seneca', value: 'Seneca' },
    { label: 'Schoharie', value: 'Schoharie' },
    { label: 'Lewis', value: 'Lewis' },
    { label: 'Yates', value: 'Yates' },
    { label: 'Schuyler', value: 'Schuyler' },
    { label: 'Hamilton', value: 'Hamilton' },
  ],
  WA: [
    { label: 'King', value: 'King' },
    { label: 'Pierce', value: 'Pierce' },
    { label: 'Snohomish', value: 'Snohomish' },
    { label: 'Spokane', value: 'Spokane' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Thurston', value: 'Thurston' },
    { label: 'Kitsap', value: 'Kitsap' },
    { label: 'Yakima', value: 'Yakima' },
    { label: 'Whatcom', value: 'Whatcom' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Skagit', value: 'Skagit' },
    { label: 'Cowlitz', value: 'Cowlitz' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Island', value: 'Island' },
    { label: 'Lewis', value: 'Lewis' },
    { label: 'Chelan', value: 'Chelan' },
    { label: 'Clallam', value: 'Clallam' },
    { label: 'Grays Harbor', value: 'Grays Harbor' },
    { label: 'Mason', value: 'Mason' },
    { label: 'Walla Walla', value: 'Walla Walla' },
    { label: 'Whitman', value: 'Whitman' },
    { label: 'Kittitas', value: 'Kittitas' },
    { label: 'Stevens', value: 'Stevens' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Okanogan', value: 'Okanogan' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Asotin', value: 'Asotin' },
    { label: 'Klickitat', value: 'Klickitat' },
    { label: 'Pacific', value: 'Pacific' },
    { label: 'Adams', value: 'Adams' },
    { label: 'San Juan', value: 'San Juan' },
    { label: 'Pend Oreille', value: 'Pend Oreille' },
    { label: 'Skamania', value: 'Skamania' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Ferry', value: 'Ferry' },
    { label: 'Wahkiakum', value: 'Wahkiakum' },
    { label: 'Columbia', value: 'Columbia' },
    { label: 'Garfield', value: 'Garfield' },
  ],
  NV: [
    { label: 'Clark', value: 'Clark' },
    { label: 'Washoe', value: 'Washoe' },
    { label: 'Carson City', value: 'Carson City' },
    { label: 'Lyon', value: 'Lyon' },
    { label: 'Elko', value: 'Elko' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Nye', value: 'Nye' },
    { label: 'Churchill', value: 'Churchill' },
    { label: 'Humboldt', value: 'Humboldt' },
    { label: 'White Pine', value: 'White Pine' },
    { label: 'Pershing', value: 'Pershing' },
    { label: 'Lander', value: 'Lander' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Mineral', value: 'Mineral' },
    { label: 'Storey', value: 'Storey' },
    { label: 'Eureka', value: 'Eureka' },
    { label: 'Esmeralda', value: 'Esmeralda' },
  ],
  MI: [
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Oakland', value: 'Oakland' },
    { label: 'Macomb', value: 'Macomb' },
    { label: 'Kent', value: 'Kent' },
    { label: 'Genesee', value: 'Genesee' },
    { label: 'Washtenaw', value: 'Washtenaw' },
    { label: 'Ingham', value: 'Ingham' },
    { label: 'Ottawa', value: 'Ottawa' },
    { label: 'Kalamazoo', value: 'Kalamazoo' },
    { label: 'Saginaw', value: 'Saginaw' },
    { label: 'Livingston', value: 'Livingston' },
    { label: 'Muskegon', value: 'Muskegon' },
    { label: 'St. Clair', value: 'St. Clair' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Berrien', value: 'Berrien' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Allegan', value: 'Allegan' },
    { label: 'Eaton', value: 'Eaton' },
    { label: 'Bay', value: 'Bay' },
    { label: 'Lenawee', value: 'Lenawee' },
    { label: 'Grand Traverse', value: 'Grand Traverse' },
    { label: 'Lapeer', value: 'Lapeer' },
    { label: 'Midland', value: 'Midland' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Van Buren', value: 'Van Buren' },
    { label: 'Isabella', value: 'Isabella' },
    { label: 'Shiawassee', value: 'Shiawassee' },
    { label: 'Marquette', value: 'Marquette' },
    { label: 'Ionia', value: 'Ionia' },
    { label: 'Montcalm', value: 'Montcalm' },
    { label: 'St. Joseph', value: 'St. Joseph' },
    { label: 'Barry', value: 'Barry' },
    { label: 'Tuscola', value: 'Tuscola' },
    { label: 'Cass', value: 'Cass' },
    { label: 'Newaygo', value: 'Newaygo' },
    { label: 'Hillsdale', value: 'Hillsdale' },
    { label: 'Branch', value: 'Branch' },
    { label: 'Mecosta', value: 'Mecosta' },
    { label: 'Sanilac', value: 'Sanilac' },
    { label: 'Gratiot', value: 'Gratiot' },
    { label: 'Chippewa', value: 'Chippewa' },
    { label: 'Houghton', value: 'Houghton' },
    { label: 'Delta', value: 'Delta' },
    { label: 'Wexford', value: 'Wexford' },
    { label: 'Emmet', value: 'Emmet' },
    { label: 'Huron', value: 'Huron' },
    { label: 'Clare', value: 'Clare' },
    { label: 'Mason', value: 'Mason' },
    { label: 'Alpena', value: 'Alpena' },
    { label: 'Oceana', value: 'Oceana' },
    { label: 'Charlevoix', value: 'Charlevoix' },
    { label: 'Dickinson', value: 'Dickinson' },
    { label: 'Cheboygan', value: 'Cheboygan' },
    { label: 'Gladwin', value: 'Gladwin' },
    { label: 'Iosco', value: 'Iosco' },
    { label: 'Otsego', value: 'Otsego' },
    { label: 'Manistee', value: 'Manistee' },
    { label: 'Roscommon', value: 'Roscommon' },
    { label: 'Osceola', value: 'Osceola' },
    { label: 'Antrim', value: 'Antrim' },
    { label: 'Menominee', value: 'Menominee' },
    { label: 'Leelanau', value: 'Leelanau' },
    { label: 'Ogemaw', value: 'Ogemaw' },
    { label: 'Benzie', value: 'Benzie' },
    { label: 'Kalkaska', value: 'Kalkaska' },
    { label: 'Arenac', value: 'Arenac' },
    { label: 'Gogebic', value: 'Gogebic' },
    { label: 'Missaukee', value: 'Missaukee' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Presque Isle', value: 'Presque Isle' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Iron', value: 'Iron' },
    { label: 'Mackinac', value: 'Mackinac' },
    { label: 'Alcona', value: 'Alcona' },
    { label: 'Montmorency', value: 'Montmorency' },
    { label: 'Alger', value: 'Alger' },
    { label: 'Baraga', value: 'Baraga' },
    { label: 'Oscoda', value: 'Oscoda' },
    { label: 'Schoolcraft', value: 'Schoolcraft' },
    { label: 'Luce', value: 'Luce' },
    { label: 'Ontonagon', value: 'Ontonagon' },
    { label: 'Keweenaw', value: 'Keweenaw' },
  ],
  MA: [
    { label: 'Middlesex', value: 'Middlesex' },
    { label: 'Worcester', value: 'Worcester' },
    { label: 'Suffolk', value: 'Suffolk' },
    { label: 'Essex', value: 'Essex' },
    { label: 'Norfolk', value: 'Norfolk' },
    { label: 'Bristol', value: 'Bristol' },
    { label: 'Plymouth', value: 'Plymouth' },
    { label: 'Hampden', value: 'Hampden' },
    { label: 'Barnstable', value: 'Barnstable' },
    { label: 'Hampshire', value: 'Hampshire' },
    { label: 'Berkshire', value: 'Berkshire' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Dukes', value: 'Dukes' },
    { label: 'Nantucket', value: 'Nantucket' },
  ],
  PA: [
    { label: 'Philadelphia', value: 'Philadelphia' },
    { label: 'Allegheny', value: 'Allegheny' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Bucks', value: 'Bucks' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Lancaster', value: 'Lancaster' },
    { label: 'Chester', value: 'Chester' },
    { label: 'York', value: 'York' },
    { label: 'Berks', value: 'Berks' },
    { label: 'Lehigh', value: 'Lehigh' },
    { label: 'Westmoreland', value: 'Westmoreland' },
    { label: 'Luzerne', value: 'Luzerne' },
    { label: 'Northampton', value: 'Northampton' },
    { label: 'Dauphin', value: 'Dauphin' },
    { label: 'Erie', value: 'Erie' },
    { label: 'Cumberland', value: 'Cumberland' },
    { label: 'Lackawanna', value: 'Lackawanna' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Butler', value: 'Butler' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Beaver', value: 'Beaver' },
    { label: 'Centre', value: 'Centre' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Schuylkill', value: 'Schuylkill' },
    { label: 'Lebanon', value: 'Lebanon' },
    { label: 'Cambria', value: 'Cambria' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Blair', value: 'Blair' },
    { label: 'Lycoming', value: 'Lycoming' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Northumberland', value: 'Northumberland' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Indiana', value: 'Indiana' },
    { label: 'Clearfield', value: 'Clearfield' },
    { label: 'Somerset', value: 'Somerset' },
    { label: 'Armstrong', value: 'Armstrong' },
    { label: 'Columbia', value: 'Columbia' },
    { label: 'Carbon', value: 'Carbon' },
    { label: 'Bradford', value: 'Bradford' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Venango', value: 'Venango' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Bedford', value: 'Bedford' },
    { label: 'Mifflin', value: 'Mifflin' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Huntingdon', value: 'Huntingdon' },
    { label: 'Union', value: 'Union' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'McKean', value: 'McKean' },
    { label: 'Susquehanna', value: 'Susquehanna' },
    { label: 'Tioga', value: 'Tioga' },
    { label: 'Snyder', value: 'Snyder' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Clarion', value: 'Clarion' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Elk', value: 'Elk' },
    { label: 'Wyoming', value: 'Wyoming' },
    { label: 'Juniata', value: 'Juniata' },
    { label: 'Montour', value: 'Montour' },
    { label: 'Potter', value: 'Potter' },
    { label: 'Fulton', value: 'Fulton' },
    { label: 'Forest', value: 'Forest' },
    { label: 'Sullivan', value: 'Sullivan' },
    { label: 'Cameron', value: 'Cameron' },
  ],
  OH: [
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Cuyahoga', value: 'Cuyahoga' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'Summit', value: 'Summit' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Lucas', value: 'Lucas' },
    { label: 'Butler', value: 'Butler' },
    { label: 'Stark', value: 'Stark' },
    { label: 'Lorain', value: 'Lorain' },
    { label: 'Mahoning', value: 'Mahoning' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Clermont', value: 'Clermont' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Trumbull', value: 'Trumbull' },
    { label: 'Medina', value: 'Medina' },
    { label: 'Licking', value: 'Licking' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Portage', value: 'Portage' },
    { label: 'Fairfield', value: 'Fairfield' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Wood', value: 'Wood' },
    { label: 'Richland', value: 'Richland' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Miami', value: 'Miami' },
    { label: 'Columbiana', value: 'Columbiana' },
    { label: 'Allen', value: 'Allen' },
    { label: 'Ashtabula', value: 'Ashtabula' },
    { label: 'Geauga', value: 'Geauga' },
    { label: 'Tuscarawas', value: 'Tuscarawas' },
    { label: 'Muskingum', value: 'Muskingum' },
    { label: 'Ross', value: 'Ross' },
    { label: 'Scioto', value: 'Scioto' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Erie', value: 'Erie' },
    { label: 'Belmont', value: 'Belmont' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Athens', value: 'Athens' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Sandusky', value: 'Sandusky' },
    { label: 'Huron', value: 'Huron' },
    { label: 'Pickaway', value: 'Pickaway' },
    { label: 'Union', value: 'Union' },
    { label: 'Seneca', value: 'Seneca' },
    { label: 'Ashland', value: 'Ashland' },
    { label: 'Darke', value: 'Darke' },
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Auglaize', value: 'Auglaize' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Holmes', value: 'Holmes' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Highland', value: 'Highland' },
    { label: 'Fulton', value: 'Fulton' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Preble', value: 'Preble' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Ottawa', value: 'Ottawa' },
    { label: 'Guernsey', value: 'Guernsey' },
    { label: 'Champaign', value: 'Champaign' },
    { label: 'Defiance', value: 'Defiance' },
    { label: 'Williams', value: 'Williams' },
    { label: 'Coshocton', value: 'Coshocton' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Morrow', value: 'Morrow' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Hardin', value: 'Hardin' },
    { label: 'Gallia', value: 'Gallia' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Hocking', value: 'Hocking' },
    { label: 'Van Wert', value: 'Van Wert' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Meigs', value: 'Meigs' },
    { label: 'Wyandot', value: 'Wyandot' },
    { label: 'Paulding', value: 'Paulding' },
    { label: 'Harrison', value: 'Harrison' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Noble', value: 'Noble' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Vinton', value: 'Vinton' },
  ],
  MN: [
    { label: 'Hennepin', value: 'Hennepin' },
    { label: 'Ramsey', value: 'Ramsey' },
    { label: 'Dakota', value: 'Dakota' },
    { label: 'Anoka', value: 'Anoka' },
    { label: 'Washington', value: 'Washington' },
    { label: 'St. Louis', value: 'St. Louis' },
    { label: 'Stearns', value: 'Stearns' },
    { label: 'Olmsted', value: 'Olmsted' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Wright', value: 'Wright' },
    { label: 'Carver', value: 'Carver' },
    { label: 'Sherburne', value: 'Sherburne' },
    { label: 'Blue Earth', value: 'Blue Earth' },
    { label: 'Rice', value: 'Rice' },
    { label: 'Crow Wing', value: 'Crow Wing' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Otter Tail', value: 'Otter Tail' },
    { label: 'Chisago', value: 'Chisago' },
    { label: 'Winona', value: 'Winona' },
    { label: 'Beltrami', value: 'Beltrami' },
    { label: 'Goodhue', value: 'Goodhue' },
    { label: 'Itasca', value: 'Itasca' },
    { label: 'Kandiyohi', value: 'Kandiyohi' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Mower', value: 'Mower' },
    { label: 'Isanti', value: 'Isanti' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Steele', value: 'Steele' },
    { label: 'McLeod', value: 'McLeod' },
    { label: 'Carlton', value: 'Carlton' },
    { label: 'Becker', value: 'Becker' },
    { label: 'Nicollet', value: 'Nicollet' },
    { label: 'Morrison', value: 'Morrison' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Freeborn', value: 'Freeborn' },
    { label: 'Cass', value: 'Cass' },
    { label: 'Pine', value: 'Pine' },
    { label: 'Le Sueur', value: 'Le Sueur' },
    { label: 'Mille Lacs', value: 'Mille Lacs' },
    { label: 'Lyon', value: 'Lyon' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Todd', value: 'Todd' },
    { label: 'Meeker', value: 'Meeker' },
    { label: 'Nobles', value: 'Nobles' },
    { label: 'Wabasha', value: 'Wabasha' },
    { label: 'Hubbard', value: 'Hubbard' },
    { label: 'Fillmore', value: 'Fillmore' },
    { label: 'Dodge', value: 'Dodge' },
    { label: 'Martin', value: 'Martin' },
    { label: 'Waseca', value: 'Waseca' },
    { label: 'Houston', value: 'Houston' },
    { label: 'Kanabec', value: 'Kanabec' },
    { label: 'Aitkin', value: 'Aitkin' },
    { label: 'Roseau', value: 'Roseau' },
    { label: 'Redwood', value: 'Redwood' },
    { label: 'Sibley', value: 'Sibley' },
    { label: 'Renville', value: 'Renville' },
    { label: 'Pennington', value: 'Pennington' },
    { label: 'Faribault', value: 'Faribault' },
    { label: 'Wadena', value: 'Wadena' },
    { label: 'Koochiching', value: 'Koochiching' },
    { label: 'Chippewa', value: 'Chippewa' },
    { label: 'Cottonwood', value: 'Cottonwood' },
    { label: 'Pope', value: 'Pope' },
    { label: 'Watonwan', value: 'Watonwan' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Yellow Medicine', value: 'Yellow Medicine' },
    { label: 'Stevens', value: 'Stevens' },
    { label: 'Rock', value: 'Rock' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Swift', value: 'Swift' },
    { label: 'Pipestone', value: 'Pipestone' },
    { label: 'Clearwater', value: 'Clearwater' },
    { label: 'Murray', value: 'Murray' },
    { label: 'Lac qui Parle', value: 'Lac qui Parle' },
    { label: 'Norman', value: 'Norman' },
    { label: 'Wilkin', value: 'Wilkin' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Mahnomen', value: 'Mahnomen' },
    { label: 'Cook', value: 'Cook' },
    { label: 'Big Stone', value: 'Big Stone' },
    { label: 'Kittson', value: 'Kittson' },
    { label: 'Red Lake', value: 'Red Lake' },
    { label: 'Lake of the Woods', value: 'Lake of the Woods' },
    { label: 'Traverse', value: 'Traverse' },
  ],
  VA: [
    { label: 'Fairfax', value: 'Fairfax' },
    { label: 'Prince William', value: 'Prince William' },
    { label: 'Virginia Beach', value: 'Virginia Beach' },
    { label: 'Loudoun', value: 'Loudoun' },
    { label: 'Chesterfield', value: 'Chesterfield' },
    { label: 'Henrico', value: 'Henrico' },
    { label: 'Norfolk', value: 'Norfolk' },
    { label: 'Chesapeake', value: 'Chesapeake' },
    { label: 'Arlington', value: 'Arlington' },
    { label: 'Richmond', value: 'Richmond' },
    { label: 'Newport News', value: 'Newport News' },
    { label: 'Alexandria', value: 'Alexandria' },
    { label: 'Stafford', value: 'Stafford' },
    { label: 'Hampton', value: 'Hampton' },
    { label: 'Spotsylvania', value: 'Spotsylvania' },
    { label: 'Albemarle', value: 'Albemarle' },
    { label: 'Hanover', value: 'Hanover' },
    { label: 'Roanoke', value: 'Roanoke' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Portsmouth', value: 'Portsmouth' },
    { label: 'Roanoke', value: 'Roanoke' },
    { label: 'Suffolk', value: 'Suffolk' },
    { label: 'Frederick', value: 'Frederick' },
    { label: 'Lynchburg', value: 'Lynchburg' },
    { label: 'Rockingham', value: 'Rockingham' },
    { label: 'Bedford', value: 'Bedford' },
    { label: 'Augusta', value: 'Augusta' },
    { label: 'James City', value: 'James City' },
    { label: 'Fauquier', value: 'Fauquier' },
    { label: 'York', value: 'York' },
    { label: 'Pittsylvania', value: 'Pittsylvania' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Campbell', value: 'Campbell' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Harrisonburg', value: 'Harrisonburg' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Culpeper', value: 'Culpeper' },
    { label: 'Charlottesville', value: 'Charlottesville' },
    { label: 'Shenandoah', value: 'Shenandoah' },
    { label: 'Tazewell', value: 'Tazewell' },
    { label: 'Manassas', value: 'Manassas' },
    { label: 'Danville', value: 'Danville' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Wise', value: 'Wise' },
    { label: 'Prince George', value: 'Prince George' },
    { label: 'Gloucester', value: 'Gloucester' },
    { label: 'Isle of Wight', value: 'Isle of Wight' },
    { label: 'Louisa', value: 'Louisa' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Halifax', value: 'Halifax' },
    { label: 'Pulaski', value: 'Pulaski' },
    { label: 'Botetourt', value: 'Botetourt' },
    { label: 'Accomack', value: 'Accomack' },
    { label: 'Amherst', value: 'Amherst' },
    { label: 'Petersburg', value: 'Petersburg' },
    { label: 'Smyth', value: 'Smyth' },
    { label: 'Mecklenburg', value: 'Mecklenburg' },
    { label: 'Caroline', value: 'Caroline' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Wythe', value: 'Wythe' },
    { label: 'Powhatan', value: 'Powhatan' },
    { label: 'Fredericksburg', value: 'Fredericksburg' },
    { label: 'Dinwiddie', value: 'Dinwiddie' },
    { label: 'Winchester', value: 'Winchester' },
    { label: 'Russell', value: 'Russell' },
    { label: 'Fluvanna', value: 'Fluvanna' },
    { label: 'King George', value: 'King George' },
    { label: 'Salem', value: 'Salem' },
    { label: 'Staunton', value: 'Staunton' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Page', value: 'Page' },
    { label: 'Fairfax', value: 'Fairfax' },
    { label: 'Prince Edward', value: 'Prince Edward' },
    { label: 'Goochland', value: 'Goochland' },
    { label: 'Rockbridge', value: 'Rockbridge' },
    { label: 'Hopewell', value: 'Hopewell' },
    { label: 'Waynesboro', value: 'Waynesboro' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Buchanan', value: 'Buchanan' },
    { label: 'New Kent', value: 'New Kent' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Southampton', value: 'Southampton' },
    { label: 'Westmoreland', value: 'Westmoreland' },
    { label: 'Patrick', value: 'Patrick' },
    { label: 'Radford', value: 'Radford' },
    { label: 'Colonial Heights', value: 'Colonial Heights' },
    { label: 'Buckingham', value: 'Buckingham' },
    { label: 'Manassas Park', value: 'Manassas Park' },
    { label: 'Bristol', value: 'Bristol' },
    { label: 'Giles', value: 'Giles' },
    { label: 'King William', value: 'King William' },
    { label: 'Brunswick', value: 'Brunswick' },
    { label: 'Grayson', value: 'Grayson' },
    { label: 'Appomattox', value: 'Appomattox' },
    { label: 'Floyd', value: 'Floyd' },
    { label: 'Nottoway', value: 'Nottoway' },
    { label: 'Alleghany', value: 'Alleghany' },
    { label: 'Williamsburg', value: 'Williamsburg' },
    { label: 'Nelson', value: 'Nelson' },
    { label: 'Dickenson', value: 'Dickenson' },
    { label: 'Clarke', value: 'Clarke' },
    { label: 'Falls Church', value: 'Falls Church' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Amelia', value: 'Amelia' },
    { label: 'Martinsville', value: 'Martinsville' },
    { label: 'Lunenburg', value: 'Lunenburg' },
    { label: 'Northumberland', value: 'Northumberland' },
    { label: 'Poquoson', value: 'Poquoson' },
    { label: 'Charlotte', value: 'Charlotte' },
    { label: 'Northampton', value: 'Northampton' },
    { label: 'Greensville', value: 'Greensville' },
    { label: 'Sussex', value: 'Sussex' },
    { label: 'Essex', value: 'Essex' },
    { label: 'Lancaster', value: 'Lancaster' },
    { label: 'Middlesex', value: 'Middlesex' },
    { label: 'Cumberland', value: 'Cumberland' },
    { label: 'Richmond', value: 'Richmond' },
    { label: 'Mathews', value: 'Mathews' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Rappahannock', value: 'Rappahannock' },
    { label: 'Lexington', value: 'Lexington' },
    { label: 'King and Queen', value: 'King and Queen' },
    { label: 'Charles City', value: 'Charles City' },
    { label: 'Surry', value: 'Surry' },
    { label: 'Galax', value: 'Galax' },
    { label: 'Buena Vista', value: 'Buena Vista' },
    { label: 'Bland', value: 'Bland' },
    { label: 'Covington', value: 'Covington' },
    { label: 'Emporia', value: 'Emporia' },
    { label: 'Craig', value: 'Craig' },
    { label: 'Bath', value: 'Bath' },
    { label: 'Norton', value: 'Norton' },
    { label: 'Highland', value: 'Highland' },
  ],
  UT: [
    { label: 'Salt Lake', value: 'Salt Lake' },
    { label: 'Utah', value: 'Utah' },
    { label: 'Davis', value: 'Davis' },
    { label: 'Weber', value: 'Weber' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Cache', value: 'Cache' },
    { label: 'Tooele', value: 'Tooele' },
    { label: 'Box Elder', value: 'Box Elder' },
    { label: 'Iron', value: 'Iron' },
    { label: 'Summit', value: 'Summit' },
    { label: 'Uintah', value: 'Uintah' },
    { label: 'Wasatch', value: 'Wasatch' },
    { label: 'Sanpete', value: 'Sanpete' },
    { label: 'Sevier', value: 'Sevier' },
    { label: 'Carbon', value: 'Carbon' },
    { label: 'Duchesne', value: 'Duchesne' },
    { label: 'San Juan', value: 'San Juan' },
    { label: 'Millard', value: 'Millard' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Juab', value: 'Juab' },
    { label: 'Emery', value: 'Emery' },
    { label: 'Grand', value: 'Grand' },
    { label: 'Kane', value: 'Kane' },
    { label: 'Beaver', value: 'Beaver' },
    { label: 'Garfield', value: 'Garfield' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Rich', value: 'Rich' },
    { label: 'Piute', value: 'Piute' },
    { label: 'Daggett', value: 'Daggett' },
  ],
  NC: [
    { label: 'Mecklenburg', value: 'Mecklenburg' },
    { label: 'Wake', value: 'Wake' },
    { label: 'Guilford', value: 'Guilford' },
    { label: 'Forsyth', value: 'Forsyth' },
    { label: 'Cumberland', value: 'Cumberland' },
    { label: 'Durham', value: 'Durham' },
    { label: 'Buncombe', value: 'Buncombe' },
    { label: 'Union', value: 'Union' },
    { label: 'New Hanover', value: 'New Hanover' },
    { label: 'Gaston', value: 'Gaston' },
    { label: 'Cabarrus', value: 'Cabarrus' },
    { label: 'Johnston', value: 'Johnston' },
    { label: 'Onslow', value: 'Onslow' },
    { label: 'Pitt', value: 'Pitt' },
    { label: 'Iredell', value: 'Iredell' },
    { label: 'Davidson', value: 'Davidson' },
    { label: 'Alamance', value: 'Alamance' },
    { label: 'Catawba', value: 'Catawba' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Randolph', value: 'Randolph' },
    { label: 'Rowan', value: 'Rowan' },
    { label: 'Robeson', value: 'Robeson' },
    { label: 'Harnett', value: 'Harnett' },
    { label: 'Brunswick', value: 'Brunswick' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Henderson', value: 'Henderson' },
    { label: 'Craven', value: 'Craven' },
    { label: 'Moore', value: 'Moore' },
    { label: 'Cleveland', value: 'Cleveland' },
    { label: 'Nash', value: 'Nash' },
    { label: 'Rockingham', value: 'Rockingham' },
    { label: 'Burke', value: 'Burke' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Caldwell', value: 'Caldwell' },
    { label: 'Wilson', value: 'Wilson' },
    { label: 'Surry', value: 'Surry' },
    { label: 'Chatham', value: 'Chatham' },
    { label: 'Carteret', value: 'Carteret' },
    { label: 'Wilkes', value: 'Wilkes' },
    { label: 'Rutherford', value: 'Rutherford' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Sampson', value: 'Sampson' },
    { label: 'Stanly', value: 'Stanly' },
    { label: 'Haywood', value: 'Haywood' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Pender', value: 'Pender' },
    { label: 'Granville', value: 'Granville' },
    { label: 'Duplin', value: 'Duplin' },
    { label: 'Lenoir', value: 'Lenoir' },
    { label: 'Columbus', value: 'Columbus' },
    { label: 'Watauga', value: 'Watauga' },
    { label: 'Hoke', value: 'Hoke' },
    { label: 'Edgecombe', value: 'Edgecombe' },
    { label: 'Halifax', value: 'Halifax' },
    { label: 'Beaufort', value: 'Beaufort' },
    { label: 'Stokes', value: 'Stokes' },
    { label: 'McDowell', value: 'McDowell' },
    { label: 'Richmond', value: 'Richmond' },
    { label: 'Vance', value: 'Vance' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Davie', value: 'Davie' },
    { label: 'Pasquotank', value: 'Pasquotank' },
    { label: 'Person', value: 'Person' },
    { label: 'Yadkin', value: 'Yadkin' },
    { label: 'Alexander', value: 'Alexander' },
    { label: 'Dare', value: 'Dare' },
    { label: 'Scotland', value: 'Scotland' },
    { label: 'Macon', value: 'Macon' },
    { label: 'Transylvania', value: 'Transylvania' },
    { label: 'Bladen', value: 'Bladen' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Ashe', value: 'Ashe' },
    { label: 'Currituck', value: 'Currituck' },
    { label: 'Anson', value: 'Anson' },
    { label: 'Hertford', value: 'Hertford' },
    { label: 'Martin', value: 'Martin' },
    { label: 'Caswell', value: 'Caswell' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Northampton', value: 'Northampton' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Bertie', value: 'Bertie' },
    { label: 'Yancey', value: 'Yancey' },
    { label: 'Avery', value: 'Avery' },
    { label: 'Mitchell', value: 'Mitchell' },
    { label: 'Swain', value: 'Swain' },
    { label: 'Chowan', value: 'Chowan' },
    { label: 'Perquimans', value: 'Perquimans' },
    { label: 'Pamlico', value: 'Pamlico' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Gates', value: 'Gates' },
    { label: 'Alleghany', value: 'Alleghany' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Camden', value: 'Camden' },
    { label: 'Jones', value: 'Jones' },
    { label: 'Graham', value: 'Graham' },
    { label: 'Hyde', value: 'Hyde' },
    { label: 'Tyrrell', value: 'Tyrrell' },
  ],
  MD: [
    { label: 'Montgomery', value: 'Montgomery' },
    { label: "Prince George's", value: "Prince George's" },
    { label: 'Baltimore', value: 'Baltimore' },
    { label: 'Baltimore', value: 'Baltimore' },
    { label: 'Anne Arundel', value: 'Anne Arundel' },
    { label: 'Howard', value: 'Howard' },
    { label: 'Harford', value: 'Harford' },
    { label: 'Frederick', value: 'Frederick' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Charles', value: 'Charles' },
    { label: 'Washington', value: 'Washington' },
    { label: "St. Mary's", value: "St. Mary's" },
    { label: 'Cecil', value: 'Cecil' },
    { label: 'Wicomico', value: 'Wicomico' },
    { label: 'Calvert', value: 'Calvert' },
    { label: 'Allegany', value: 'Allegany' },
    { label: 'Worcester', value: 'Worcester' },
    { label: "Queen Anne's", value: "Queen Anne's" },
    { label: 'Talbot', value: 'Talbot' },
    { label: 'Caroline', value: 'Caroline' },
    { label: 'Dorchester', value: 'Dorchester' },
    { label: 'Garrett', value: 'Garrett' },
    { label: 'Somerset', value: 'Somerset' },
    { label: 'Kent', value: 'Kent' },
  ],
  GA: [
    { label: 'Fulton', value: 'Fulton' },
    { label: 'Gwinnett', value: 'Gwinnett' },
    { label: 'Cobb', value: 'Cobb' },
    { label: 'DeKalb', value: 'DeKalb' },
    { label: 'Chatham', value: 'Chatham' },
    { label: 'Clayton', value: 'Clayton' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Forsyth', value: 'Forsyth' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Richmond', value: 'Richmond' },
    { label: 'Hall', value: 'Hall' },
    { label: 'Muscogee', value: 'Muscogee' },
    { label: 'Paulding', value: 'Paulding' },
    { label: 'Houston', value: 'Houston' },
    { label: 'Bibb', value: 'Bibb' },
    { label: 'Columbia', value: 'Columbia' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Coweta', value: 'Coweta' },
    { label: 'Clarke', value: 'Clarke' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Lowndes', value: 'Lowndes' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Newton', value: 'Newton' },
    { label: 'Bartow', value: 'Bartow' },
    { label: 'Whitfield', value: 'Whitfield' },
    { label: 'Floyd', value: 'Floyd' },
    { label: 'Walton', value: 'Walton' },
    { label: 'Rockdale', value: 'Rockdale' },
    { label: 'Dougherty', value: 'Dougherty' },
    { label: 'Glynn', value: 'Glynn' },
    { label: 'Barrow', value: 'Barrow' },
    { label: 'Bulloch', value: 'Bulloch' },
    { label: 'Troup', value: 'Troup' },
    { label: 'Walker', value: 'Walker' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Catoosa', value: 'Catoosa' },
    { label: 'Spalding', value: 'Spalding' },
    { label: 'Liberty', value: 'Liberty' },
    { label: 'Effingham', value: 'Effingham' },
    { label: 'Gordon', value: 'Gordon' },
    { label: 'Camden', value: 'Camden' },
    { label: 'Laurens', value: 'Laurens' },
    { label: 'Colquitt', value: 'Colquitt' },
    { label: 'Baldwin', value: 'Baldwin' },
    { label: 'Thomas', value: 'Thomas' },
    { label: 'Habersham', value: 'Habersham' },
    { label: 'Coffee', value: 'Coffee' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Tift', value: 'Tift' },
    { label: 'Murray', value: 'Murray' },
    { label: 'Oconee', value: 'Oconee' },
    { label: 'Bryan', value: 'Bryan' },
    { label: 'Ware', value: 'Ware' },
    { label: 'Harris', value: 'Harris' },
    { label: 'Lumpkin', value: 'Lumpkin' },
    { label: 'Pickens', value: 'Pickens' },
    { label: 'Gilmer', value: 'Gilmer' },
    { label: 'Sumter', value: 'Sumter' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Lee', value: 'Lee' },
    { label: 'White', value: 'White' },
    { label: 'Haralson', value: 'Haralson' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Jones', value: 'Jones' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Peach', value: 'Peach' },
    { label: 'Toombs', value: 'Toombs' },
    { label: 'Decatur', value: 'Decatur' },
    { label: 'Upson', value: 'Upson' },
    { label: 'Hart', value: 'Hart' },
    { label: 'Stephens', value: 'Stephens' },
    { label: 'Fannin', value: 'Fannin' },
    { label: 'Tattnall', value: 'Tattnall' },
    { label: 'Grady', value: 'Grady' },
    { label: 'Chattooga', value: 'Chattooga' },
    { label: 'Dawson', value: 'Dawson' },
    { label: 'Butts', value: 'Butts' },
    { label: 'Union', value: 'Union' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Crisp', value: 'Crisp' },
    { label: 'Emanuel', value: 'Emanuel' },
    { label: 'Burke', value: 'Burke' },
    { label: 'Mitchell', value: 'Mitchell' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'McDuffie', value: 'McDuffie' },
    { label: 'Meriwether', value: 'Meriwether' },
    { label: 'Dodge', value: 'Dodge' },
    { label: 'Worth', value: 'Worth' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Pierce', value: 'Pierce' },
    { label: 'Elbert', value: 'Elbert' },
    { label: 'Berrien', value: 'Berrien' },
    { label: 'Brantley', value: 'Brantley' },
    { label: 'Banks', value: 'Banks' },
    { label: 'Long', value: 'Long' },
    { label: 'Lamar', value: 'Lamar' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Appling', value: 'Appling' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Cook', value: 'Cook' },
    { label: 'Ben Hill', value: 'Ben Hill' },
    { label: 'Rabun', value: 'Rabun' },
    { label: 'Dade', value: 'Dade' },
    { label: 'Telfair', value: 'Telfair' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Brooks', value: 'Brooks' },
    { label: 'Jeff Davis', value: 'Jeff Davis' },
    { label: 'Oglethorpe', value: 'Oglethorpe' },
    { label: 'McIntosh', value: 'McIntosh' },
    { label: 'Screven', value: 'Screven' },
    { label: 'Jasper', value: 'Jasper' },
    { label: 'Dooly', value: 'Dooly' },
    { label: 'Macon', value: 'Macon' },
    { label: 'Charlton', value: 'Charlton' },
    { label: 'Bleckley', value: 'Bleckley' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Heard', value: 'Heard' },
    { label: 'Towns', value: 'Towns' },
    { label: 'Pulaski', value: 'Pulaski' },
    { label: 'Bacon', value: 'Bacon' },
    { label: 'Candler', value: 'Candler' },
    { label: 'Evans', value: 'Evans' },
    { label: 'Chattahoochee', value: 'Chattahoochee' },
    { label: 'Lanier', value: 'Lanier' },
    { label: 'Early', value: 'Early' },
    { label: 'Wilkes', value: 'Wilkes' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Irwin', value: 'Irwin' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Wilkinson', value: 'Wilkinson' },
    { label: 'Wilcox', value: 'Wilcox' },
    { label: 'Jenkins', value: 'Jenkins' },
    { label: 'Terrell', value: 'Terrell' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Seminole', value: 'Seminole' },
    { label: 'Atkinson', value: 'Atkinson' },
    { label: 'Twiggs', value: 'Twiggs' },
    { label: 'Taylor', value: 'Taylor' },
    { label: 'Turner', value: 'Turner' },
    { label: 'Wheeler', value: 'Wheeler' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Randolph', value: 'Randolph' },
    { label: 'Treutlen', value: 'Treutlen' },
    { label: 'Clinch', value: 'Clinch' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Talbot', value: 'Talbot' },
    { label: 'Stewart', value: 'Stewart' },
    { label: 'Miller', value: 'Miller' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Schley', value: 'Schley' },
    { label: 'Echols', value: 'Echols' },
    { label: 'Baker', value: 'Baker' },
    { label: 'Glascock', value: 'Glascock' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Webster', value: 'Webster' },
    { label: 'Quitman', value: 'Quitman' },
    { label: 'Taliaferro', value: 'Taliaferro' },
  ],
  MO: [
    { label: 'St. Louis', value: 'St. Louis' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'St. Charles', value: 'St. Charles' },
    { label: 'St. Louis', value: 'St. Louis' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Boone', value: 'Boone' },
    { label: 'Jasper', value: 'Jasper' },
    { label: 'Cass', value: 'Cass' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Platte', value: 'Platte' },
    { label: 'Buchanan', value: 'Buchanan' },
    { label: 'Christian', value: 'Christian' },
    { label: 'Cape Girardeau', value: 'Cape Girardeau' },
    { label: 'Cole', value: 'Cole' },
    { label: 'St. Francois', value: 'St. Francois' },
    { label: 'Newton', value: 'Newton' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Taney', value: 'Taney' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Pulaski', value: 'Pulaski' },
    { label: 'Camden', value: 'Camden' },
    { label: 'Callaway', value: 'Callaway' },
    { label: 'Phelps', value: 'Phelps' },
    { label: 'Butler', value: 'Butler' },
    { label: 'Pettis', value: 'Pettis' },
    { label: 'Howell', value: 'Howell' },
    { label: 'Webster', value: 'Webster' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Laclede', value: 'Laclede' },
    { label: 'Barry', value: 'Barry' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Lafayette', value: 'Lafayette' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Stone', value: 'Stone' },
    { label: 'Dunklin', value: 'Dunklin' },
    { label: 'Stoddard', value: 'Stoddard' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Audrain', value: 'Audrain' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Adair', value: 'Adair' },
    { label: 'Miller', value: 'Miller' },
    { label: 'Randolph', value: 'Randolph' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Saline', value: 'Saline' },
    { label: 'Ray', value: 'Ray' },
    { label: 'McDonald', value: 'McDonald' },
    { label: 'Nodaway', value: 'Nodaway' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Vernon', value: 'Vernon' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Wright', value: 'Wright' },
    { label: 'Ste. Genevieve', value: 'Ste. Genevieve' },
    { label: 'Cooper', value: 'Cooper' },
    { label: 'New Madrid', value: 'New Madrid' },
    { label: 'Andrew', value: 'Andrew' },
    { label: 'Pemiscot', value: 'Pemiscot' },
    { label: 'Dallas', value: 'Dallas' },
    { label: 'Bates', value: 'Bates' },
    { label: 'Moniteau', value: 'Moniteau' },
    { label: 'Dent', value: 'Dent' },
    { label: 'Macon', value: 'Macon' },
    { label: 'Livingston', value: 'Livingston' },
    { label: 'Gasconade', value: 'Gasconade' },
    { label: 'Cedar', value: 'Cedar' },
    { label: 'Osage', value: 'Osage' },
    { label: 'Mississippi', value: 'Mississippi' },
    { label: 'Ripley', value: 'Ripley' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'DeKalb', value: 'DeKalb' },
    { label: 'Bollinger', value: 'Bollinger' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Linn', value: 'Linn' },
    { label: 'Barton', value: 'Barton' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Oregon', value: 'Oregon' },
    { label: 'Ralls', value: 'Ralls' },
    { label: 'Iron', value: 'Iron' },
    { label: 'Howard', value: 'Howard' },
    { label: 'Grundy', value: 'Grundy' },
    { label: 'Lewis', value: 'Lewis' },
    { label: 'Hickory', value: 'Hickory' },
    { label: 'St. Clair', value: 'St. Clair' },
    { label: 'Ozark', value: 'Ozark' },
    { label: 'Caldwell', value: 'Caldwell' },
    { label: 'Maries', value: 'Maries' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Harrison', value: 'Harrison' },
    { label: 'Daviess', value: 'Daviess' },
    { label: 'Shannon', value: 'Shannon' },
    { label: 'Dade', value: 'Dade' },
    { label: 'Chariton', value: 'Chariton' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Gentry', value: 'Gentry' },
    { label: 'Reynolds', value: 'Reynolds' },
    { label: 'Sullivan', value: 'Sullivan' },
    { label: 'Carter', value: 'Carter' },
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Atchison', value: 'Atchison' },
    { label: 'Scotland', value: 'Scotland' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'Schuyler', value: 'Schuyler' },
    { label: 'Holt', value: 'Holt' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Worth', value: 'Worth' },
  ],
  HI: [
    { label: 'Honolulu', value: 'Honolulu' },
    { label: 'Hawaii', value: 'Hawaii' },
    { label: 'Maui', value: 'Maui' },
    { label: 'Kauai', value: 'Kauai' },
    { label: 'Kalawao', value: 'Kalawao' },
  ],
  IN: [
    { label: 'Marion', value: 'Marion' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Allen', value: 'Allen' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'St. Joseph', value: 'St. Joseph' },
    { label: 'Elkhart', value: 'Elkhart' },
    { label: 'Tippecanoe', value: 'Tippecanoe' },
    { label: 'Vanderburgh', value: 'Vanderburgh' },
    { label: 'Porter', value: 'Porter' },
    { label: 'Hendricks', value: 'Hendricks' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'LaPorte', value: 'LaPorte' },
    { label: 'Vigo', value: 'Vigo' },
    { label: 'Bartholomew', value: 'Bartholomew' },
    { label: 'Howard', value: 'Howard' },
    { label: 'Kosciusko', value: 'Kosciusko' },
    { label: 'Floyd', value: 'Floyd' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Boone', value: 'Boone' },
    { label: 'Warrick', value: 'Warrick' },
    { label: 'Dearborn', value: 'Dearborn' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Noble', value: 'Noble' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'DeKalb', value: 'DeKalb' },
    { label: 'Dubois', value: 'Dubois' },
    { label: 'Harrison', value: 'Harrison' },
    { label: 'LaGrange', value: 'LaGrange' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Cass', value: 'Cass' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Huntington', value: 'Huntington' },
    { label: 'Miami', value: 'Miami' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Steuben', value: 'Steuben' },
    { label: 'Whitley', value: 'Whitley' },
    { label: 'Gibson', value: 'Gibson' },
    { label: 'Jasper', value: 'Jasper' },
    { label: 'Daviess', value: 'Daviess' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Wabash', value: 'Wabash' },
    { label: 'Ripley', value: 'Ripley' },
    { label: 'Wells', value: 'Wells' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Jennings', value: 'Jennings' },
    { label: 'Decatur', value: 'Decatur' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Posey', value: 'Posey' },
    { label: 'Randolph', value: 'Randolph' },
    { label: 'White', value: 'White' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Starke', value: 'Starke' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Jay', value: 'Jay' },
    { label: 'Owen', value: 'Owen' },
    { label: 'Sullivan', value: 'Sullivan' },
    { label: 'Spencer', value: 'Spencer' },
    { label: 'Fulton', value: 'Fulton' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Parke', value: 'Parke' },
    { label: 'Rush', value: 'Rush' },
    { label: 'Fountain', value: 'Fountain' },
    { label: 'Vermillion', value: 'Vermillion' },
    { label: 'Tipton', value: 'Tipton' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Newton', value: 'Newton' },
    { label: 'Pulaski', value: 'Pulaski' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Blackford', value: 'Blackford' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Martin', value: 'Martin' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Union', value: 'Union' },
    { label: 'Ohio', value: 'Ohio' },
  ],
  WI: [
    { label: 'Milwaukee', value: 'Milwaukee' },
    { label: 'Dane', value: 'Dane' },
    { label: 'Waukesha', value: 'Waukesha' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Racine', value: 'Racine' },
    { label: 'Outagamie', value: 'Outagamie' },
    { label: 'Winnebago', value: 'Winnebago' },
    { label: 'Kenosha', value: 'Kenosha' },
    { label: 'Rock', value: 'Rock' },
    { label: 'Marathon', value: 'Marathon' },
    { label: 'Washington', value: 'Washington' },
    { label: 'La Crosse', value: 'La Crosse' },
    { label: 'Sheboygan', value: 'Sheboygan' },
    { label: 'Eau Claire', value: 'Eau Claire' },
    { label: 'Walworth', value: 'Walworth' },
    { label: 'Fond du Lac', value: 'Fond du Lac' },
    { label: 'St. Croix', value: 'St. Croix' },
    { label: 'Ozaukee', value: 'Ozaukee' },
    { label: 'Dodge', value: 'Dodge' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Manitowoc', value: 'Manitowoc' },
    { label: 'Wood', value: 'Wood' },
    { label: 'Portage', value: 'Portage' },
    { label: 'Sauk', value: 'Sauk' },
    { label: 'Chippewa', value: 'Chippewa' },
    { label: 'Columbia', value: 'Columbia' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Waupaca', value: 'Waupaca' },
    { label: 'Calumet', value: 'Calumet' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Barron', value: 'Barron' },
    { label: 'Dunn', value: 'Dunn' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Pierce', value: 'Pierce' },
    { label: 'Shawano', value: 'Shawano' },
    { label: 'Marinette', value: 'Marinette' },
    { label: 'Oconto', value: 'Oconto' },
    { label: 'Green', value: 'Green' },
    { label: 'Oneida', value: 'Oneida' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Vernon', value: 'Vernon' },
    { label: 'Trempealeau', value: 'Trempealeau' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Door', value: 'Door' },
    { label: 'Juneau', value: 'Juneau' },
    { label: 'Waushara', value: 'Waushara' },
    { label: 'Iowa', value: 'Iowa' },
    { label: 'Vilas', value: 'Vilas' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Kewaunee', value: 'Kewaunee' },
    { label: 'Taylor', value: 'Taylor' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Langlade', value: 'Langlade' },
    { label: 'Green Lake', value: 'Green Lake' },
    { label: 'Richland', value: 'Richland' },
    { label: 'Lafayette', value: 'Lafayette' },
    { label: 'Sawyer', value: 'Sawyer' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Washburn', value: 'Washburn' },
    { label: 'Ashland', value: 'Ashland' },
    { label: 'Marquette', value: 'Marquette' },
    { label: 'Burnett', value: 'Burnett' },
    { label: 'Bayfield', value: 'Bayfield' },
    { label: 'Rusk', value: 'Rusk' },
    { label: 'Price', value: 'Price' },
    { label: 'Buffalo', value: 'Buffalo' },
    { label: 'Forest', value: 'Forest' },
    { label: 'Pepin', value: 'Pepin' },
    { label: 'Iron', value: 'Iron' },
    { label: 'Menominee', value: 'Menominee' },
    { label: 'Florence', value: 'Florence' },
  ],
  CT: [
    { label: 'Fairfield', value: 'Fairfield' },
    { label: 'Hartford', value: 'Hartford' },
    { label: 'New Haven', value: 'New Haven' },
    { label: 'New London', value: 'New London' },
    { label: 'Litchfield', value: 'Litchfield' },
    { label: 'Middlesex', value: 'Middlesex' },
    { label: 'Tolland', value: 'Tolland' },
    { label: 'Windham', value: 'Windham' },
  ],
  TN: [
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Davidson', value: 'Davidson' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'Rutherford', value: 'Rutherford' },
    { label: 'Williamson', value: 'Williamson' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Sumner', value: 'Sumner' },
    { label: 'Sullivan', value: 'Sullivan' },
    { label: 'Wilson', value: 'Wilson' },
    { label: 'Blount', value: 'Blount' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Bradley', value: 'Bradley' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Sevier', value: 'Sevier' },
    { label: 'Maury', value: 'Maury' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'Anderson', value: 'Anderson' },
    { label: 'Robertson', value: 'Robertson' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Hamblen', value: 'Hamblen' },
    { label: 'Tipton', value: 'Tipton' },
    { label: 'Cumberland', value: 'Cumberland' },
    { label: 'Hawkins', value: 'Hawkins' },
    { label: 'Carter', value: 'Carter' },
    { label: 'Coffee', value: 'Coffee' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Roane', value: 'Roane' },
    { label: 'McMinn', value: 'McMinn' },
    { label: 'Dickson', value: 'Dickson' },
    { label: 'Loudon', value: 'Loudon' },
    { label: 'Gibson', value: 'Gibson' },
    { label: 'Bedford', value: 'Bedford' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Cheatham', value: 'Cheatham' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Campbell', value: 'Campbell' },
    { label: 'Dyer', value: 'Dyer' },
    { label: 'Cocke', value: 'Cocke' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Weakley', value: 'Weakley' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Rhea', value: 'Rhea' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Claiborne', value: 'Claiborne' },
    { label: 'Obion', value: 'Obion' },
    { label: 'Giles', value: 'Giles' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Henderson', value: 'Henderson' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'White', value: 'White' },
    { label: 'Lauderdale', value: 'Lauderdale' },
    { label: 'McNairy', value: 'McNairy' },
    { label: 'Hardin', value: 'Hardin' },
    { label: 'Hardeman', value: 'Hardeman' },
    { label: 'Hickman', value: 'Hickman' },
    { label: 'Macon', value: 'Macon' },
    { label: 'Grainger', value: 'Grainger' },
    { label: 'Overton', value: 'Overton' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'DeKalb', value: 'DeKalb' },
    { label: 'Smith', value: 'Smith' },
    { label: 'Union', value: 'Union' },
    { label: 'Humphreys', value: 'Humphreys' },
    { label: 'Fentress', value: 'Fentress' },
    { label: 'Unicoi', value: 'Unicoi' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Haywood', value: 'Haywood' },
    { label: 'Chester', value: 'Chester' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Bledsoe', value: 'Bledsoe' },
    { label: 'Sequatchie', value: 'Sequatchie' },
    { label: 'Crockett', value: 'Crockett' },
    { label: 'Cannon', value: 'Cannon' },
    { label: 'Stewart', value: 'Stewart' },
    { label: 'Grundy', value: 'Grundy' },
    { label: 'Meigs', value: 'Meigs' },
    { label: 'Lewis', value: 'Lewis' },
    { label: 'Decatur', value: 'Decatur' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Trousdale', value: 'Trousdale' },
    { label: 'Houston', value: 'Houston' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Moore', value: 'Moore' },
    { label: 'Van Buren', value: 'Van Buren' },
    { label: 'Pickett', value: 'Pickett' },
  ],
  NJ: [
    { label: 'Bergen', value: 'Bergen' },
    { label: 'Middlesex', value: 'Middlesex' },
    { label: 'Essex', value: 'Essex' },
    { label: 'Hudson', value: 'Hudson' },
    { label: 'Monmouth', value: 'Monmouth' },
    { label: 'Ocean', value: 'Ocean' },
    { label: 'Union', value: 'Union' },
    { label: 'Camden', value: 'Camden' },
    { label: 'Passaic', value: 'Passaic' },
    { label: 'Morris', value: 'Morris' },
    { label: 'Burlington', value: 'Burlington' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Somerset', value: 'Somerset' },
    { label: 'Gloucester', value: 'Gloucester' },
    { label: 'Atlantic', value: 'Atlantic' },
    { label: 'Cumberland', value: 'Cumberland' },
    { label: 'Sussex', value: 'Sussex' },
    { label: 'Hunterdon', value: 'Hunterdon' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Cape May', value: 'Cape May' },
    { label: 'Salem', value: 'Salem' },
  ],
  OR: [
    { label: 'Multnomah', value: 'Multnomah' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Clackamas', value: 'Clackamas' },
    { label: 'Lane', value: 'Lane' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Deschutes', value: 'Deschutes' },
    { label: 'Linn', value: 'Linn' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Yamhill', value: 'Yamhill' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Josephine', value: 'Josephine' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Umatilla', value: 'Umatilla' },
    { label: 'Klamath', value: 'Klamath' },
    { label: 'Coos', value: 'Coos' },
    { label: 'Columbia', value: 'Columbia' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Clatsop', value: 'Clatsop' },
    { label: 'Malheur', value: 'Malheur' },
    { label: 'Tillamook', value: 'Tillamook' },
    { label: 'Union', value: 'Union' },
    { label: 'Wasco', value: 'Wasco' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Hood River', value: 'Hood River' },
    { label: 'Crook', value: 'Crook' },
    { label: 'Curry', value: 'Curry' },
    { label: 'Baker', value: 'Baker' },
    { label: 'Morrow', value: 'Morrow' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Harney', value: 'Harney' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Wallowa', value: 'Wallowa' },
    { label: 'Gilliam', value: 'Gilliam' },
    { label: 'Sherman', value: 'Sherman' },
    { label: 'Wheeler', value: 'Wheeler' },
  ],
  OK: [
    { label: 'Oklahoma', value: 'Oklahoma' },
    { label: 'Tulsa', value: 'Tulsa' },
    { label: 'Cleveland', value: 'Cleveland' },
    { label: 'Canadian', value: 'Canadian' },
    { label: 'Comanche', value: 'Comanche' },
    { label: 'Rogers', value: 'Rogers' },
    { label: 'Payne', value: 'Payne' },
    { label: 'Wagoner', value: 'Wagoner' },
    { label: 'Pottawatomie', value: 'Pottawatomie' },
    { label: 'Creek', value: 'Creek' },
    { label: 'Muskogee', value: 'Muskogee' },
    { label: 'Garfield', value: 'Garfield' },
    { label: 'Grady', value: 'Grady' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Le Flore', value: 'Le Flore' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Carter', value: 'Carter' },
    { label: 'Osage', value: 'Osage' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Bryan', value: 'Bryan' },
    { label: 'Kay', value: 'Kay' },
    { label: 'Pittsburg', value: 'Pittsburg' },
    { label: 'Stephens', value: 'Stephens' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Sequoyah', value: 'Sequoyah' },
    { label: 'Mayes', value: 'Mayes' },
    { label: 'McClain', value: 'McClain' },
    { label: 'Okmulgee', value: 'Okmulgee' },
    { label: 'Pontotoc', value: 'Pontotoc' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'McCurtain', value: 'McCurtain' },
    { label: 'Ottawa', value: 'Ottawa' },
    { label: 'Caddo', value: 'Caddo' },
    { label: 'Custer', value: 'Custer' },
    { label: 'Garvin', value: 'Garvin' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Seminole', value: 'Seminole' },
    { label: 'Beckham', value: 'Beckham' },
    { label: 'Adair', value: 'Adair' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Woodward', value: 'Woodward' },
    { label: 'McIntosh', value: 'McIntosh' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Pawnee', value: 'Pawnee' },
    { label: 'Kingfisher', value: 'Kingfisher' },
    { label: 'Choctaw', value: 'Choctaw' },
    { label: 'Craig', value: 'Craig' },
    { label: 'Murray', value: 'Murray' },
    { label: 'Atoka', value: 'Atoka' },
    { label: 'Hughes', value: 'Hughes' },
    { label: 'Haskell', value: 'Haskell' },
    { label: 'Okfuskee', value: 'Okfuskee' },
    { label: 'Noble', value: 'Noble' },
    { label: 'Washita', value: 'Washita' },
    { label: 'Pushmataha', value: 'Pushmataha' },
    { label: 'Johnston', value: 'Johnston' },
    { label: 'Latimer', value: 'Latimer' },
    { label: 'Nowata', value: 'Nowata' },
    { label: 'Love', value: 'Love' },
    { label: 'Blaine', value: 'Blaine' },
    { label: 'Woods', value: 'Woods' },
    { label: 'Kiowa', value: 'Kiowa' },
    { label: 'Major', value: 'Major' },
    { label: 'Tillman', value: 'Tillman' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Greer', value: 'Greer' },
    { label: 'Cotton', value: 'Cotton' },
    { label: 'Alfalfa', value: 'Alfalfa' },
    { label: 'Coal', value: 'Coal' },
    { label: 'Beaver', value: 'Beaver' },
    { label: 'Dewey', value: 'Dewey' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Ellis', value: 'Ellis' },
    { label: 'Harper', value: 'Harper' },
    { label: 'Roger Mills', value: 'Roger Mills' },
    { label: 'Harmon', value: 'Harmon' },
    { label: 'Cimarron', value: 'Cimarron' },
  ],
  KY: [
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Kenton', value: 'Kenton' },
    { label: 'Boone', value: 'Boone' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Hardin', value: 'Hardin' },
    { label: 'Daviess', value: 'Daviess' },
    { label: 'Campbell', value: 'Campbell' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Bullitt', value: 'Bullitt' },
    { label: 'Christian', value: 'Christian' },
    { label: 'Oldham', value: 'Oldham' },
    { label: 'McCracken', value: 'McCracken' },
    { label: 'Pulaski', value: 'Pulaski' },
    { label: 'Laurel', value: 'Laurel' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Jessamine', value: 'Jessamine' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Boyd', value: 'Boyd' },
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Henderson', value: 'Henderson' },
    { label: 'Nelson', value: 'Nelson' },
    { label: 'Hopkins', value: 'Hopkins' },
    { label: 'Barren', value: 'Barren' },
    { label: 'Calloway', value: 'Calloway' },
    { label: 'Graves', value: 'Graves' },
    { label: 'Floyd', value: 'Floyd' },
    { label: 'Whitley', value: 'Whitley' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Greenup', value: 'Greenup' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Muhlenberg', value: 'Muhlenberg' },
    { label: 'Boyle', value: 'Boyle' },
    { label: 'Meade', value: 'Meade' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Carter', value: 'Carter' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Bell', value: 'Bell' },
    { label: 'Harlan', value: 'Harlan' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Woodford', value: 'Woodford' },
    { label: 'Grayson', value: 'Grayson' },
    { label: 'Taylor', value: 'Taylor' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Rowan', value: 'Rowan' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Ohio', value: 'Ohio' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Anderson', value: 'Anderson' },
    { label: 'Letcher', value: 'Letcher' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Allen', value: 'Allen' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Breckinridge', value: 'Breckinridge' },
    { label: 'Bourbon', value: 'Bourbon' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Adair', value: 'Adair' },
    { label: 'Hart', value: 'Hart' },
    { label: 'Harrison', value: 'Harrison' },
    { label: 'Spencer', value: 'Spencer' },
    { label: 'Simpson', value: 'Simpson' },
    { label: 'Russell', value: 'Russell' },
    { label: 'McCreary', value: 'McCreary' },
    { label: 'Garrard', value: 'Garrard' },
    { label: 'Mason', value: 'Mason' },
    { label: 'Rockcastle', value: 'Rockcastle' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Casey', value: 'Casey' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Knott', value: 'Knott' },
    { label: 'Union', value: 'Union' },
    { label: 'Pendleton', value: 'Pendleton' },
    { label: 'Fleming', value: 'Fleming' },
    { label: 'Trigg', value: 'Trigg' },
    { label: 'Estill', value: 'Estill' },
    { label: 'Larue', value: 'Larue' },
    { label: 'Lewis', value: 'Lewis' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Webster', value: 'Webster' },
    { label: 'Breathitt', value: 'Breathitt' },
    { label: 'Butler', value: 'Butler' },
    { label: 'Caldwell', value: 'Caldwell' },
    { label: 'Magoffin', value: 'Magoffin' },
    { label: 'Bath', value: 'Bath' },
    { label: 'Todd', value: 'Todd' },
    { label: 'Powell', value: 'Powell' },
    { label: 'Edmonson', value: 'Edmonson' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Martin', value: 'Martin' },
    { label: 'Green', value: 'Green' },
    { label: 'Owen', value: 'Owen' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Leslie', value: 'Leslie' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Metcalfe', value: 'Metcalfe' },
    { label: 'McLean', value: 'McLean' },
    { label: 'Livingston', value: 'Livingston' },
    { label: 'Crittenden', value: 'Crittenden' },
    { label: 'Gallatin', value: 'Gallatin' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Trimble', value: 'Trimble' },
    { label: 'Bracken', value: 'Bracken' },
    { label: 'Lyon', value: 'Lyon' },
    { label: 'Ballard', value: 'Ballard' },
    { label: 'Elliott', value: 'Elliott' },
    { label: 'Wolfe', value: 'Wolfe' },
    { label: 'Nicholas', value: 'Nicholas' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Cumberland', value: 'Cumberland' },
    { label: 'Menifee', value: 'Menifee' },
    { label: 'Fulton', value: 'Fulton' },
    { label: 'Carlisle', value: 'Carlisle' },
    { label: 'Hickman', value: 'Hickman' },
    { label: 'Owsley', value: 'Owsley' },
    { label: 'Robertson', value: 'Robertson' },
  ],
  CO: [
    { label: 'Denver', value: 'Denver' },
    { label: 'El Paso', value: 'El Paso' },
    { label: 'Arapahoe', value: 'Arapahoe' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Larimer', value: 'Larimer' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Boulder', value: 'Boulder' },
    { label: 'Weld', value: 'Weld' },
    { label: 'Pueblo', value: 'Pueblo' },
    { label: 'Mesa', value: 'Mesa' },
    { label: 'Broomfield', value: 'Broomfield' },
    { label: 'Garfield', value: 'Garfield' },
    { label: 'La Plata', value: 'La Plata' },
    { label: 'Eagle', value: 'Eagle' },
    { label: 'Fremont', value: 'Fremont' },
    { label: 'Montrose', value: 'Montrose' },
    { label: 'Summit', value: 'Summit' },
    { label: 'Delta', value: 'Delta' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Montezuma', value: 'Montezuma' },
    { label: 'Elbert', value: 'Elbert' },
    { label: 'Routt', value: 'Routt' },
    { label: 'Teller', value: 'Teller' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Chaffee', value: 'Chaffee' },
    { label: 'Otero', value: 'Otero' },
    { label: 'Pitkin', value: 'Pitkin' },
    { label: 'Park', value: 'Park' },
    { label: 'Gunnison', value: 'Gunnison' },
    { label: 'Alamosa', value: 'Alamosa' },
    { label: 'Grand', value: 'Grand' },
    { label: 'Las Animas', value: 'Las Animas' },
    { label: 'Archuleta', value: 'Archuleta' },
    { label: 'Moffat', value: 'Moffat' },
    { label: 'Prowers', value: 'Prowers' },
    { label: 'Rio Grande', value: 'Rio Grande' },
    { label: 'Yuma', value: 'Yuma' },
    { label: 'Clear Creek', value: 'Clear Creek' },
    { label: 'Conejos', value: 'Conejos' },
    { label: 'San Miguel', value: 'San Miguel' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Kit Carson', value: 'Kit Carson' },
    { label: 'Huerfano', value: 'Huerfano' },
    { label: 'Saguache', value: 'Saguache' },
    { label: 'Rio Blanco', value: 'Rio Blanco' },
    { label: 'Gilpin', value: 'Gilpin' },
    { label: 'Bent', value: 'Bent' },
    { label: 'Crowley', value: 'Crowley' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Ouray', value: 'Ouray' },
    { label: 'Custer', value: 'Custer' },
    { label: 'Phillips', value: 'Phillips' },
    { label: 'Costilla', value: 'Costilla' },
    { label: 'Baca', value: 'Baca' },
    { label: 'Sedgwick', value: 'Sedgwick' },
    { label: 'Cheyenne', value: 'Cheyenne' },
    { label: 'Dolores', value: 'Dolores' },
    { label: 'Kiowa', value: 'Kiowa' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Hinsdale', value: 'Hinsdale' },
    { label: 'Mineral', value: 'Mineral' },
    { label: 'San Juan', value: 'San Juan' },
  ],
  DC: [{ label: 'District of Columbia', value: 'District of Columbia' }],
  NM: [
    { label: 'Bernalillo', value: 'Bernalillo' },
    { label: 'Doña Ana', value: 'Doña Ana' },
    { label: 'Santa Fe', value: 'Santa Fe' },
    { label: 'Sandoval', value: 'Sandoval' },
    { label: 'San Juan', value: 'San Juan' },
    { label: 'Valencia', value: 'Valencia' },
    { label: 'McKinley', value: 'McKinley' },
    { label: 'Lea', value: 'Lea' },
    { label: 'Otero', value: 'Otero' },
    { label: 'Chaves', value: 'Chaves' },
    { label: 'Eddy', value: 'Eddy' },
    { label: 'Curry', value: 'Curry' },
    { label: 'Rio Arriba', value: 'Rio Arriba' },
    { label: 'Taos', value: 'Taos' },
    { label: 'San Miguel', value: 'San Miguel' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Cibola', value: 'Cibola' },
    { label: 'Luna', value: 'Luna' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Roosevelt', value: 'Roosevelt' },
    { label: 'Los Alamos', value: 'Los Alamos' },
    { label: 'Socorro', value: 'Socorro' },
    { label: 'Torrance', value: 'Torrance' },
    { label: 'Colfax', value: 'Colfax' },
    { label: 'Sierra', value: 'Sierra' },
    { label: 'Quay', value: 'Quay' },
    { label: 'Mora', value: 'Mora' },
    { label: 'Guadalupe', value: 'Guadalupe' },
    { label: 'Hidalgo', value: 'Hidalgo' },
    { label: 'Union', value: 'Union' },
    { label: 'Catron', value: 'Catron' },
    { label: 'De Baca', value: 'De Baca' },
    { label: 'Harding', value: 'Harding' },
  ],
  AL: [
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Mobile', value: 'Mobile' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Baldwin', value: 'Baldwin' },
    { label: 'Tuscaloosa', value: 'Tuscaloosa' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Houston', value: 'Houston' },
    { label: 'Etowah', value: 'Etowah' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Limestone', value: 'Limestone' },
    { label: 'Lauderdale', value: 'Lauderdale' },
    { label: 'St. Clair', value: 'St. Clair' },
    { label: 'Cullman', value: 'Cullman' },
    { label: 'Elmore', value: 'Elmore' },
    { label: 'Talladega', value: 'Talladega' },
    { label: 'DeKalb', value: 'DeKalb' },
    { label: 'Walker', value: 'Walker' },
    { label: 'Russell', value: 'Russell' },
    { label: 'Blount', value: 'Blount' },
    { label: 'Autauga', value: 'Autauga' },
    { label: 'Colbert', value: 'Colbert' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Coffee', value: 'Coffee' },
    { label: 'Dale', value: 'Dale' },
    { label: 'Chilton', value: 'Chilton' },
    { label: 'Tallapoosa', value: 'Tallapoosa' },
    { label: 'Dallas', value: 'Dallas' },
    { label: 'Covington', value: 'Covington' },
    { label: 'Escambia', value: 'Escambia' },
    { label: 'Chambers', value: 'Chambers' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Geneva', value: 'Geneva' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Barbour', value: 'Barbour' },
    { label: 'Clarke', value: 'Clarke' },
    { label: 'Winston', value: 'Winston' },
    { label: 'Randolph', value: 'Randolph' },
    { label: 'Bibb', value: 'Bibb' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Pickens', value: 'Pickens' },
    { label: 'Butler', value: 'Butler' },
    { label: 'Marengo', value: 'Marengo' },
    { label: 'Macon', value: 'Macon' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Cleburne', value: 'Cleburne' },
    { label: 'Hale', value: 'Hale' },
    { label: 'Lamar', value: 'Lamar' },
    { label: 'Crenshaw', value: 'Crenshaw' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Choctaw', value: 'Choctaw' },
    { label: 'Sumter', value: 'Sumter' },
    { label: 'Conecuh', value: 'Conecuh' },
    { label: 'Coosa', value: 'Coosa' },
    { label: 'Wilcox', value: 'Wilcox' },
    { label: 'Bullock', value: 'Bullock' },
    { label: 'Lowndes', value: 'Lowndes' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Greene', value: 'Greene' },
  ],
  RI: [
    { label: 'Providence', value: 'Providence' },
    { label: 'Kent', value: 'Kent' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Newport', value: 'Newport' },
    { label: 'Bristol', value: 'Bristol' },
  ],
  KS: [
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Sedgwick', value: 'Sedgwick' },
    { label: 'Shawnee', value: 'Shawnee' },
    { label: 'Wyandotte', value: 'Wyandotte' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Leavenworth', value: 'Leavenworth' },
    { label: 'Riley', value: 'Riley' },
    { label: 'Butler', value: 'Butler' },
    { label: 'Reno', value: 'Reno' },
    { label: 'Saline', value: 'Saline' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Finney', value: 'Finney' },
    { label: 'Cowley', value: 'Cowley' },
    { label: 'Harvey', value: 'Harvey' },
    { label: 'Ford', value: 'Ford' },
    { label: 'Geary', value: 'Geary' },
    { label: 'Miami', value: 'Miami' },
    { label: 'Lyon', value: 'Lyon' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Ellis', value: 'Ellis' },
    { label: 'McPherson', value: 'McPherson' },
    { label: 'Barton', value: 'Barton' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Pottawatomie', value: 'Pottawatomie' },
    { label: 'Sumner', value: 'Sumner' },
    { label: 'Seward', value: 'Seward' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Labette', value: 'Labette' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Dickinson', value: 'Dickinson' },
    { label: 'Atchison', value: 'Atchison' },
    { label: 'Neosho', value: 'Neosho' },
    { label: 'Osage', value: 'Osage' },
    { label: 'Bourbon', value: 'Bourbon' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Allen', value: 'Allen' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Nemaha', value: 'Nemaha' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Linn', value: 'Linn' },
    { label: 'Rice', value: 'Rice' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Pratt', value: 'Pratt' },
    { label: 'Cloud', value: 'Cloud' },
    { label: 'Wilson', value: 'Wilson' },
    { label: 'Coffey', value: 'Coffey' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Anderson', value: 'Anderson' },
    { label: 'Thomas', value: 'Thomas' },
    { label: 'Doniphan', value: 'Doniphan' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Kingman', value: 'Kingman' },
    { label: 'Russell', value: 'Russell' },
    { label: 'Wabaunsee', value: 'Wabaunsee' },
    { label: 'Pawnee', value: 'Pawnee' },
    { label: 'Ellsworth', value: 'Ellsworth' },
    { label: 'Mitchell', value: 'Mitchell' },
    { label: 'Greenwood', value: 'Greenwood' },
    { label: 'Gray', value: 'Gray' },
    { label: 'Sherman', value: 'Sherman' },
    { label: 'Ottawa', value: 'Ottawa' },
    { label: 'Stevens', value: 'Stevens' },
    { label: 'Harper', value: 'Harper' },
    { label: 'Morris', value: 'Morris' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Norton', value: 'Norton' },
    { label: 'Phillips', value: 'Phillips' },
    { label: 'Rooks', value: 'Rooks' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Republic', value: 'Republic' },
    { label: 'Barber', value: 'Barber' },
    { label: 'Stafford', value: 'Stafford' },
    { label: 'Meade', value: 'Meade' },
    { label: 'Haskell', value: 'Haskell' },
    { label: 'Kearny', value: 'Kearny' },
    { label: 'Smith', value: 'Smith' },
    { label: 'Osborne', value: 'Osborne' },
    { label: 'Chautauqua', value: 'Chautauqua' },
    { label: 'Woodson', value: 'Woodson' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Rush', value: 'Rush' },
    { label: 'Jewell', value: 'Jewell' },
    { label: 'Ness', value: 'Ness' },
    { label: 'Edwards', value: 'Edwards' },
    { label: 'Decatur', value: 'Decatur' },
    { label: 'Trego', value: 'Trego' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Morton', value: 'Morton' },
    { label: 'Cheyenne', value: 'Cheyenne' },
    { label: 'Gove', value: 'Gove' },
    { label: 'Chase', value: 'Chase' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'Elk', value: 'Elk' },
    { label: 'Graham', value: 'Graham' },
    { label: 'Sheridan', value: 'Sheridan' },
    { label: 'Kiowa', value: 'Kiowa' },
    { label: 'Rawlins', value: 'Rawlins' },
    { label: 'Wichita', value: 'Wichita' },
    { label: 'Stanton', value: 'Stanton' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Hodgeman', value: 'Hodgeman' },
    { label: 'Comanche', value: 'Comanche' },
    { label: 'Wallace', value: 'Wallace' },
    { label: 'Lane', value: 'Lane' },
    { label: 'Greeley', value: 'Greeley' },
  ],
  NE: [
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Lancaster', value: 'Lancaster' },
    { label: 'Sarpy', value: 'Sarpy' },
    { label: 'Hall', value: 'Hall' },
    { label: 'Buffalo', value: 'Buffalo' },
    { label: 'Dodge', value: 'Dodge' },
    { label: 'Scotts Bluff', value: 'Scotts Bluff' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Platte', value: 'Platte' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Cass', value: 'Cass' },
    { label: 'Dawson', value: 'Dawson' },
    { label: 'Gage', value: 'Gage' },
    { label: 'Saunders', value: 'Saunders' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Dakota', value: 'Dakota' },
    { label: 'Seward', value: 'Seward' },
    { label: 'Otoe', value: 'Otoe' },
    { label: 'Saline', value: 'Saline' },
    { label: 'York', value: 'York' },
    { label: 'Box Butte', value: 'Box Butte' },
    { label: 'Custer', value: 'Custer' },
    { label: 'Red Willow', value: 'Red Willow' },
    { label: 'Colfax', value: 'Colfax' },
    { label: 'Holt', value: 'Holt' },
    { label: 'Cheyenne', value: 'Cheyenne' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'Phelps', value: 'Phelps' },
    { label: 'Cuming', value: 'Cuming' },
    { label: 'Dawes', value: 'Dawes' },
    { label: 'Cedar', value: 'Cedar' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Keith', value: 'Keith' },
    { label: 'Butler', value: 'Butler' },
    { label: 'Richardson', value: 'Richardson' },
    { label: 'Merrick', value: 'Merrick' },
    { label: 'Thurston', value: 'Thurston' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Pierce', value: 'Pierce' },
    { label: 'Nemaha', value: 'Nemaha' },
    { label: 'Kearney', value: 'Kearney' },
    { label: 'Burt', value: 'Burt' },
    { label: 'Howard', value: 'Howard' },
    { label: 'Antelope', value: 'Antelope' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Stanton', value: 'Stanton' },
    { label: 'Cherry', value: 'Cherry' },
    { label: 'Dixon', value: 'Dixon' },
    { label: 'Fillmore', value: 'Fillmore' },
    { label: 'Boone', value: 'Boone' },
    { label: 'Sheridan', value: 'Sheridan' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Thayer', value: 'Thayer' },
    { label: 'Morrill', value: 'Morrill' },
    { label: 'Furnas', value: 'Furnas' },
    { label: 'Nuckolls', value: 'Nuckolls' },
    { label: 'Valley', value: 'Valley' },
    { label: 'Chase', value: 'Chase' },
    { label: 'Kimball', value: 'Kimball' },
    { label: 'Nance', value: 'Nance' },
    { label: 'Webster', value: 'Webster' },
    { label: 'Harlan', value: 'Harlan' },
    { label: 'Sherman', value: 'Sherman' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Perkins', value: 'Perkins' },
    { label: 'Hitchcock', value: 'Hitchcock' },
    { label: 'Pawnee', value: 'Pawnee' },
    { label: 'Frontier', value: 'Frontier' },
    { label: 'Greeley', value: 'Greeley' },
    { label: 'Gosper', value: 'Gosper' },
    { label: 'Garfield', value: 'Garfield' },
    { label: 'Boyd', value: 'Boyd' },
    { label: 'Dundy', value: 'Dundy' },
    { label: 'Garden', value: 'Garden' },
    { label: 'Deuel', value: 'Deuel' },
    { label: 'Rock', value: 'Rock' },
    { label: 'Sioux', value: 'Sioux' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Hayes', value: 'Hayes' },
    { label: 'Wheeler', value: 'Wheeler' },
    { label: 'Keya Paha', value: 'Keya Paha' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Banner', value: 'Banner' },
    { label: 'Hooker', value: 'Hooker' },
    { label: 'Thomas', value: 'Thomas' },
    { label: 'Loup', value: 'Loup' },
    { label: 'Blaine', value: 'Blaine' },
    { label: 'Arthur', value: 'Arthur' },
    { label: 'McPherson', value: 'McPherson' },
  ],
  DE: [
    { label: 'New Castle', value: 'New Castle' },
    { label: 'Sussex', value: 'Sussex' },
    { label: 'Kent', value: 'Kent' },
  ],
  SC: [
    { label: 'Greenville', value: 'Greenville' },
    { label: 'Richland', value: 'Richland' },
    { label: 'Charleston', value: 'Charleston' },
    { label: 'Horry', value: 'Horry' },
    { label: 'Spartanburg', value: 'Spartanburg' },
    { label: 'Lexington', value: 'Lexington' },
    { label: 'York', value: 'York' },
    { label: 'Berkeley', value: 'Berkeley' },
    { label: 'Anderson', value: 'Anderson' },
    { label: 'Beaufort', value: 'Beaufort' },
    { label: 'Aiken', value: 'Aiken' },
    { label: 'Dorchester', value: 'Dorchester' },
    { label: 'Florence', value: 'Florence' },
    { label: 'Pickens', value: 'Pickens' },
    { label: 'Sumter', value: 'Sumter' },
    { label: 'Lancaster', value: 'Lancaster' },
    { label: 'Orangeburg', value: 'Orangeburg' },
    { label: 'Oconee', value: 'Oconee' },
    { label: 'Greenwood', value: 'Greenwood' },
    { label: 'Darlington', value: 'Darlington' },
    { label: 'Laurens', value: 'Laurens' },
    { label: 'Kershaw', value: 'Kershaw' },
    { label: 'Georgetown', value: 'Georgetown' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Chesterfield', value: 'Chesterfield' },
    { label: 'Newberry', value: 'Newberry' },
    { label: 'Colleton', value: 'Colleton' },
    { label: 'Clarendon', value: 'Clarendon' },
    { label: 'Chester', value: 'Chester' },
    { label: 'Williamsburg', value: 'Williamsburg' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Dillon', value: 'Dillon' },
    { label: 'Jasper', value: 'Jasper' },
    { label: 'Union', value: 'Union' },
    { label: 'Edgefield', value: 'Edgefield' },
    { label: 'Marlboro', value: 'Marlboro' },
    { label: 'Abbeville', value: 'Abbeville' },
    { label: 'Fairfield', value: 'Fairfield' },
    { label: 'Barnwell', value: 'Barnwell' },
    { label: 'Saluda', value: 'Saluda' },
    { label: 'Hampton', value: 'Hampton' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Bamberg', value: 'Bamberg' },
    { label: 'McCormick', value: 'McCormick' },
    { label: 'Allendale', value: 'Allendale' },
  ],
  IA: [
    { label: 'Polk', value: 'Polk' },
    { label: 'Linn', value: 'Linn' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Black Hawk', value: 'Black Hawk' },
    { label: 'Woodbury', value: 'Woodbury' },
    { label: 'Dubuque', value: 'Dubuque' },
    { label: 'Story', value: 'Story' },
    { label: 'Pottawattamie', value: 'Pottawattamie' },
    { label: 'Dallas', value: 'Dallas' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Clinton', value: 'Clinton' },
    { label: 'Muscatine', value: 'Muscatine' },
    { label: 'Cerro Gordo', value: 'Cerro Gordo' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Des Moines', value: 'Des Moines' },
    { label: 'Jasper', value: 'Jasper' },
    { label: 'Webster', value: 'Webster' },
    { label: 'Wapello', value: 'Wapello' },
    { label: 'Sioux', value: 'Sioux' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Boone', value: 'Boone' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Plymouth', value: 'Plymouth' },
    { label: 'Bremer', value: 'Bremer' },
    { label: 'Mahaska', value: 'Mahaska' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Buchanan', value: 'Buchanan' },
    { label: 'Jones', value: 'Jones' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Winneshiek', value: 'Winneshiek' },
    { label: 'Buena Vista', value: 'Buena Vista' },
    { label: 'Henry', value: 'Henry' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Cedar', value: 'Cedar' },
    { label: 'Poweshiek', value: 'Poweshiek' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Clayton', value: 'Clayton' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Dickinson', value: 'Dickinson' },
    { label: 'Hardin', value: 'Hardin' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Tama', value: 'Tama' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Iowa', value: 'Iowa' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Floyd', value: 'Floyd' },
    { label: 'Page', value: 'Page' },
    { label: 'Mills', value: 'Mills' },
    { label: 'Hamilton', value: 'Hamilton' },
    { label: 'Kossuth', value: 'Kossuth' },
    { label: 'Butler', value: 'Butler' },
    { label: 'Harrison', value: 'Harrison' },
    { label: "O'Brien", value: "O'Brien" },
    { label: 'Allamakee', value: 'Allamakee' },
    { label: 'Cass', value: 'Cass' },
    { label: 'Wright', value: 'Wright' },
    { label: 'Appanoose', value: 'Appanoose' },
    { label: 'Union', value: 'Union' },
    { label: 'Grundy', value: 'Grundy' },
    { label: 'Chickasaw', value: 'Chickasaw' },
    { label: 'Lyon', value: 'Lyon' },
    { label: 'Shelby', value: 'Shelby' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Louisa', value: 'Louisa' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Guthrie', value: 'Guthrie' },
    { label: 'Mitchell', value: 'Mitchell' },
    { label: 'Winnebago', value: 'Winnebago' },
    { label: 'Keokuk', value: 'Keokuk' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Sac', value: 'Sac' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Humboldt', value: 'Humboldt' },
    { label: 'Emmet', value: 'Emmet' },
    { label: 'Clarke', value: 'Clarke' },
    { label: 'Howard', value: 'Howard' },
    { label: 'Palo Alto', value: 'Palo Alto' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Davis', value: 'Davis' },
    { label: 'Monona', value: 'Monona' },
    { label: 'Lucas', value: 'Lucas' },
    { label: 'Decatur', value: 'Decatur' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Worth', value: 'Worth' },
    { label: 'Van Buren', value: 'Van Buren' },
    { label: 'Adair', value: 'Adair' },
    { label: 'Fremont', value: 'Fremont' },
    { label: 'Ida', value: 'Ida' },
    { label: 'Pocahontas', value: 'Pocahontas' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Taylor', value: 'Taylor' },
    { label: 'Osceola', value: 'Osceola' },
    { label: 'Audubon', value: 'Audubon' },
    { label: 'Ringgold', value: 'Ringgold' },
    { label: 'Adams', value: 'Adams' },
  ],
  ID: [
    { label: 'Ada', value: 'Ada' },
    { label: 'Canyon', value: 'Canyon' },
    { label: 'Kootenai', value: 'Kootenai' },
    { label: 'Bonneville', value: 'Bonneville' },
    { label: 'Bannock', value: 'Bannock' },
    { label: 'Twin Falls', value: 'Twin Falls' },
    { label: 'Bingham', value: 'Bingham' },
    { label: 'Bonner', value: 'Bonner' },
    { label: 'Nez Perce', value: 'Nez Perce' },
    { label: 'Latah', value: 'Latah' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Elmore', value: 'Elmore' },
    { label: 'Jerome', value: 'Jerome' },
    { label: 'Cassia', value: 'Cassia' },
    { label: 'Payette', value: 'Payette' },
    { label: 'Blaine', value: 'Blaine' },
    { label: 'Minidoka', value: 'Minidoka' },
    { label: 'Gem', value: 'Gem' },
    { label: 'Idaho', value: 'Idaho' },
    { label: 'Gooding', value: 'Gooding' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Fremont', value: 'Fremont' },
    { label: 'Shoshone', value: 'Shoshone' },
    { label: 'Boundary', value: 'Boundary' },
    { label: 'Owyhee', value: 'Owyhee' },
    { label: 'Teton', value: 'Teton' },
    { label: 'Valley', value: 'Valley' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Benewah', value: 'Benewah' },
    { label: 'Clearwater', value: 'Clearwater' },
    { label: 'Lemhi', value: 'Lemhi' },
    { label: 'Power', value: 'Power' },
    { label: 'Boise', value: 'Boise' },
    { label: 'Caribou', value: 'Caribou' },
    { label: 'Bear Lake', value: 'Bear Lake' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Oneida', value: 'Oneida' },
    { label: 'Custer', value: 'Custer' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Lewis', value: 'Lewis' },
    { label: 'Butte', value: 'Butte' },
    { label: 'Camas', value: 'Camas' },
    { label: 'Clark', value: 'Clark' },
  ],
  LA: [
    { label: 'East Baton Rouge', value: 'East Baton Rouge' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Orleans', value: 'Orleans' },
    { label: 'St. Tammany', value: 'St. Tammany' },
    { label: 'Caddo', value: 'Caddo' },
    { label: 'Lafayette', value: 'Lafayette' },
    { label: 'Calcasieu', value: 'Calcasieu' },
    { label: 'Ouachita', value: 'Ouachita' },
    { label: 'Livingston', value: 'Livingston' },
    { label: 'Tangipahoa', value: 'Tangipahoa' },
    { label: 'Rapides', value: 'Rapides' },
    { label: 'Bossier', value: 'Bossier' },
    { label: 'Ascension', value: 'Ascension' },
    { label: 'Terrebonne', value: 'Terrebonne' },
    { label: 'Lafourche', value: 'Lafourche' },
    { label: 'St. Landry', value: 'St. Landry' },
    { label: 'Iberia', value: 'Iberia' },
    { label: 'Acadia', value: 'Acadia' },
    { label: 'Vermilion', value: 'Vermilion' },
    { label: 'St. Martin', value: 'St. Martin' },
    { label: 'St. Charles', value: 'St. Charles' },
    { label: 'St. Mary', value: 'St. Mary' },
    { label: 'Vernon', value: 'Vernon' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Washington', value: 'Washington' },
    { label: 'St. Bernard', value: 'St. Bernard' },
    { label: 'St. John the Baptist', value: 'St. John the Baptist' },
    { label: 'Avoyelles', value: 'Avoyelles' },
    { label: 'Webster', value: 'Webster' },
    { label: 'Natchitoches', value: 'Natchitoches' },
    { label: 'Beauregard', value: 'Beauregard' },
    { label: 'Evangeline', value: 'Evangeline' },
    { label: 'Iberville', value: 'Iberville' },
    { label: 'Jefferson Davis', value: 'Jefferson Davis' },
    { label: 'De Soto', value: 'De Soto' },
    { label: 'West Baton Rouge', value: 'West Baton Rouge' },
    { label: 'Allen', value: 'Allen' },
    { label: 'Morehouse', value: 'Morehouse' },
    { label: 'Sabine', value: 'Sabine' },
    { label: 'Plaquemines', value: 'Plaquemines' },
    { label: 'Assumption', value: 'Assumption' },
    { label: 'Union', value: 'Union' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Pointe Coupee', value: 'Pointe Coupee' },
    { label: 'St. James', value: 'St. James' },
    { label: 'Richland', value: 'Richland' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Concordia', value: 'Concordia' },
    { label: 'East Feliciana', value: 'East Feliciana' },
    { label: 'Claiborne', value: 'Claiborne' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'West Feliciana', value: 'West Feliciana' },
    { label: 'LaSalle', value: 'LaSalle' },
    { label: 'Winn', value: 'Winn' },
    { label: 'Bienville', value: 'Bienville' },
    { label: 'Madison', value: 'Madison' },
    { label: 'West Carroll', value: 'West Carroll' },
    { label: 'St. Helena', value: 'St. Helena' },
    { label: 'Caldwell', value: 'Caldwell' },
    { label: 'Catahoula', value: 'Catahoula' },
    { label: 'Red River', value: 'Red River' },
    { label: 'East Carroll', value: 'East Carroll' },
    { label: 'Cameron', value: 'Cameron' },
    { label: 'Tensas', value: 'Tensas' },
  ],
  NH: [
    { label: 'Hillsborough', value: 'Hillsborough' },
    { label: 'Rockingham', value: 'Rockingham' },
    { label: 'Merrimack', value: 'Merrimack' },
    { label: 'Strafford', value: 'Strafford' },
    { label: 'Grafton', value: 'Grafton' },
    { label: 'Cheshire', value: 'Cheshire' },
    { label: 'Belknap', value: 'Belknap' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Sullivan', value: 'Sullivan' },
    { label: 'Coos', value: 'Coos' },
  ],
  AR: [
    { label: 'Pulaski', value: 'Pulaski' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Sebastian', value: 'Sebastian' },
    { label: 'Faulkner', value: 'Faulkner' },
    { label: 'Saline', value: 'Saline' },
    { label: 'Craighead', value: 'Craighead' },
    { label: 'Garland', value: 'Garland' },
    { label: 'White', value: 'White' },
    { label: 'Lonoke', value: 'Lonoke' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Pope', value: 'Pope' },
    { label: 'Crawford', value: 'Crawford' },
    { label: 'Crittenden', value: 'Crittenden' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Miller', value: 'Miller' },
    { label: 'Mississippi', value: 'Mississippi' },
    { label: 'Baxter', value: 'Baxter' },
    { label: 'Union', value: 'Union' },
    { label: 'Independence', value: 'Independence' },
    { label: 'Boone', value: 'Boone' },
    { label: 'Hot Spring', value: 'Hot Spring' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'St. Francis', value: 'St. Francis' },
    { label: 'Cleburne', value: 'Cleburne' },
    { label: 'Poinsett', value: 'Poinsett' },
    { label: 'Ouachita', value: 'Ouachita' },
    { label: 'Columbia', value: 'Columbia' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Hempstead', value: 'Hempstead' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Yell', value: 'Yell' },
    { label: 'Conway', value: 'Conway' },
    { label: 'Ashley', value: 'Ashley' },
    { label: 'Polk', value: 'Polk' },
    { label: 'Phillips', value: 'Phillips' },
    { label: 'Drew', value: 'Drew' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Arkansas', value: 'Arkansas' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Randolph', value: 'Randolph' },
    { label: 'Sharp', value: 'Sharp' },
    { label: 'Sevier', value: 'Sevier' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Cross', value: 'Cross' },
    { label: 'Van Buren', value: 'Van Buren' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Izard', value: 'Izard' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Howard', value: 'Howard' },
    { label: 'Stone', value: 'Stone' },
    { label: 'Little River', value: 'Little River' },
    { label: 'Fulton', value: 'Fulton' },
    { label: 'Desha', value: 'Desha' },
    { label: 'Bradley', value: 'Bradley' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Chicot', value: 'Chicot' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Nevada', value: 'Nevada' },
    { label: 'Prairie', value: 'Prairie' },
    { label: 'Cleveland', value: 'Cleveland' },
    { label: 'Searcy', value: 'Searcy' },
    { label: 'Newton', value: 'Newton' },
    { label: 'Dallas', value: 'Dallas' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Lafayette', value: 'Lafayette' },
    { label: 'Woodruff', value: 'Woodruff' },
    { label: 'Calhoun', value: 'Calhoun' },
  ],
  AK: [
    { label: 'Anchorage', value: 'Anchorage' },
    { label: 'Matanuska-Susitna', value: 'Matanuska-Susitna' },
    { label: 'Fairbanks North Star', value: 'Fairbanks North Star' },
    { label: 'Kenai Peninsula', value: 'Kenai Peninsula' },
    { label: 'Juneau', value: 'Juneau' },
    { label: 'Bethel', value: 'Bethel' },
    { label: 'Ketchikan Gateway', value: 'Ketchikan Gateway' },
    { label: 'Kodiak Island', value: 'Kodiak Island' },
    { label: 'Nome', value: 'Nome' },
    { label: 'North Slope', value: 'North Slope' },
    { label: 'Valdez-Cordova', value: 'Valdez-Cordova' },
    { label: 'Sitka', value: 'Sitka' },
    { label: 'Kusilvak', value: 'Kusilvak' },
    { label: 'Northwest Arctic', value: 'Northwest Arctic' },
    { label: 'Southeast Fairbanks', value: 'Southeast Fairbanks' },
    { label: 'Prince of Wales-Hyder', value: 'Prince of Wales-Hyder' },
    { label: 'Aleutians West', value: 'Aleutians West' },
    { label: 'Yukon-Koyukuk', value: 'Yukon-Koyukuk' },
    { label: 'Dillingham', value: 'Dillingham' },
    { label: 'Aleutians East', value: 'Aleutians East' },
    { label: 'Petersburg', value: 'Petersburg' },
    { label: 'Haines', value: 'Haines' },
    { label: 'Wrangell', value: 'Wrangell' },
    { label: 'Denali', value: 'Denali' },
    { label: 'Hoonah-Angoon', value: 'Hoonah-Angoon' },
    { label: 'Lake and Peninsula', value: 'Lake and Peninsula' },
    { label: 'Skagway', value: 'Skagway' },
    { label: 'Bristol Bay', value: 'Bristol Bay' },
    { label: 'Yakutat', value: 'Yakutat' },
  ],
  ME: [
    { label: 'Cumberland', value: 'Cumberland' },
    { label: 'York', value: 'York' },
    { label: 'Penobscot', value: 'Penobscot' },
    { label: 'Kennebec', value: 'Kennebec' },
    { label: 'Androscoggin', value: 'Androscoggin' },
    { label: 'Aroostook', value: 'Aroostook' },
    { label: 'Oxford', value: 'Oxford' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Somerset', value: 'Somerset' },
    { label: 'Knox', value: 'Knox' },
    { label: 'Waldo', value: 'Waldo' },
    { label: 'Sagadahoc', value: 'Sagadahoc' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Piscataquis', value: 'Piscataquis' },
  ],
  MS: [
    { label: 'Hinds', value: 'Hinds' },
    { label: 'Harrison', value: 'Harrison' },
    { label: 'DeSoto', value: 'DeSoto' },
    { label: 'Rankin', value: 'Rankin' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Lee', value: 'Lee' },
    { label: 'Lauderdale', value: 'Lauderdale' },
    { label: 'Forrest', value: 'Forrest' },
    { label: 'Jones', value: 'Jones' },
    { label: 'Lamar', value: 'Lamar' },
    { label: 'Lowndes', value: 'Lowndes' },
    { label: 'Pearl River', value: 'Pearl River' },
    { label: 'Lafayette', value: 'Lafayette' },
    { label: 'Oktibbeha', value: 'Oktibbeha' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Warren', value: 'Warren' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Pike', value: 'Pike' },
    { label: 'Alcorn', value: 'Alcorn' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Panola', value: 'Panola' },
    { label: 'Bolivar', value: 'Bolivar' },
    { label: 'Pontotoc', value: 'Pontotoc' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Neshoba', value: 'Neshoba' },
    { label: 'Leflore', value: 'Leflore' },
    { label: 'Yazoo', value: 'Yazoo' },
    { label: 'Union', value: 'Union' },
    { label: 'Copiah', value: 'Copiah' },
    { label: 'Tate', value: 'Tate' },
    { label: 'Scott', value: 'Scott' },
    { label: 'Simpson', value: 'Simpson' },
    { label: 'Sunflower', value: 'Sunflower' },
    { label: 'Prentiss', value: 'Prentiss' },
    { label: 'Marion', value: 'Marion' },
    { label: 'George', value: 'George' },
    { label: 'Itawamba', value: 'Itawamba' },
    { label: 'Coahoma', value: 'Coahoma' },
    { label: 'Leake', value: 'Leake' },
    { label: 'Tippah', value: 'Tippah' },
    { label: 'Newton', value: 'Newton' },
    { label: 'Grenada', value: 'Grenada' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Tishomingo', value: 'Tishomingo' },
    { label: 'Covington', value: 'Covington' },
    { label: 'Attala', value: 'Attala' },
    { label: 'Stone', value: 'Stone' },
    { label: 'Winston', value: 'Winston' },
    { label: 'Holmes', value: 'Holmes' },
    { label: 'Chickasaw', value: 'Chickasaw' },
    { label: 'Jasper', value: 'Jasper' },
    { label: 'Smith', value: 'Smith' },
    { label: 'Clarke', value: 'Clarke' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Walthall', value: 'Walthall' },
    { label: 'Tallahatchie', value: 'Tallahatchie' },
    { label: 'Greene', value: 'Greene' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Amite', value: 'Amite' },
    { label: 'Yalobusha', value: 'Yalobusha' },
    { label: 'Perry', value: 'Perry' },
    { label: 'Jefferson Davis', value: 'Jefferson Davis' },
    { label: 'Noxubee', value: 'Noxubee' },
    { label: 'Carroll', value: 'Carroll' },
    { label: 'Montgomery', value: 'Montgomery' },
    { label: 'Tunica', value: 'Tunica' },
    { label: 'Kemper', value: 'Kemper' },
    { label: 'Webster', value: 'Webster' },
    { label: 'Claiborne', value: 'Claiborne' },
    { label: 'Wilkinson', value: 'Wilkinson' },
    { label: 'Humphreys', value: 'Humphreys' },
    { label: 'Choctaw', value: 'Choctaw' },
    { label: 'Benton', value: 'Benton' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Quitman', value: 'Quitman' },
    { label: 'Sharkey', value: 'Sharkey' },
    { label: 'Issaquena', value: 'Issaquena' },
  ],
  SD: [
    { label: 'Minnehaha', value: 'Minnehaha' },
    { label: 'Pennington', value: 'Pennington' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Brookings', value: 'Brookings' },
    { label: 'Codington', value: 'Codington' },
    { label: 'Meade', value: 'Meade' },
    { label: 'Lawrence', value: 'Lawrence' },
    { label: 'Yankton', value: 'Yankton' },
    { label: 'Davison', value: 'Davison' },
    { label: 'Beadle', value: 'Beadle' },
    { label: 'Hughes', value: 'Hughes' },
    { label: 'Union', value: 'Union' },
    { label: 'Oglala Lakota', value: 'Oglala Lakota' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Roberts', value: 'Roberts' },
    { label: 'Butte', value: 'Butte' },
    { label: 'Todd', value: 'Todd' },
    { label: 'Charles Mix', value: 'Charles Mix' },
    { label: 'Custer', value: 'Custer' },
    { label: 'Turner', value: 'Turner' },
    { label: 'Hutchinson', value: 'Hutchinson' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Bon Homme', value: 'Bon Homme' },
    { label: 'Fall River', value: 'Fall River' },
    { label: 'Moody', value: 'Moody' },
    { label: 'Spink', value: 'Spink' },
    { label: 'Hamlin', value: 'Hamlin' },
    { label: 'Dewey', value: 'Dewey' },
    { label: 'McCook', value: 'McCook' },
    { label: 'Day', value: 'Day' },
    { label: 'Tripp', value: 'Tripp' },
    { label: 'Walworth', value: 'Walworth' },
    { label: 'Brule', value: 'Brule' },
    { label: 'Kingsbury', value: 'Kingsbury' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Deuel', value: 'Deuel' },
    { label: 'Gregory', value: 'Gregory' },
    { label: 'Corson', value: 'Corson' },
    { label: 'Edmunds', value: 'Edmunds' },
    { label: 'Lyman', value: 'Lyman' },
    { label: 'Clark', value: 'Clark' },
    { label: 'Bennett', value: 'Bennett' },
    { label: 'Hanson', value: 'Hanson' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Hand', value: 'Hand' },
    { label: 'Stanley', value: 'Stanley' },
    { label: 'Douglas', value: 'Douglas' },
    { label: 'Perkins', value: 'Perkins' },
    { label: 'Ziebach', value: 'Ziebach' },
    { label: 'Aurora', value: 'Aurora' },
    { label: 'Sanborn', value: 'Sanborn' },
    { label: 'Potter', value: 'Potter' },
    { label: 'Faulk', value: 'Faulk' },
    { label: 'McPherson', value: 'McPherson' },
    { label: 'Miner', value: 'Miner' },
    { label: 'Mellette', value: 'Mellette' },
    { label: 'Buffalo', value: 'Buffalo' },
    { label: 'Haakon', value: 'Haakon' },
    { label: 'Jerauld', value: 'Jerauld' },
    { label: 'Campbell', value: 'Campbell' },
    { label: 'Hyde', value: 'Hyde' },
    { label: 'Harding', value: 'Harding' },
    { label: 'Sully', value: 'Sully' },
    { label: 'Jones', value: 'Jones' },
  ],
  WV: [
    { label: 'Kanawha', value: 'Kanawha' },
    { label: 'Berkeley', value: 'Berkeley' },
    { label: 'Monongalia', value: 'Monongalia' },
    { label: 'Cabell', value: 'Cabell' },
    { label: 'Wood', value: 'Wood' },
    { label: 'Raleigh', value: 'Raleigh' },
    { label: 'Harrison', value: 'Harrison' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Putnam', value: 'Putnam' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Marion', value: 'Marion' },
    { label: 'Fayette', value: 'Fayette' },
    { label: 'Ohio', value: 'Ohio' },
    { label: 'Wayne', value: 'Wayne' },
    { label: 'Greenbrier', value: 'Greenbrier' },
    { label: 'Preston', value: 'Preston' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Marshall', value: 'Marshall' },
    { label: 'Hancock', value: 'Hancock' },
    { label: 'Randolph', value: 'Randolph' },
    { label: 'Jackson', value: 'Jackson' },
    { label: 'Mineral', value: 'Mineral' },
    { label: 'Mason', value: 'Mason' },
    { label: 'Nicholas', value: 'Nicholas' },
    { label: 'Upshur', value: 'Upshur' },
    { label: 'Mingo', value: 'Mingo' },
    { label: 'Hampshire', value: 'Hampshire' },
    { label: 'Brooke', value: 'Brooke' },
    { label: 'Boone', value: 'Boone' },
    { label: 'Wyoming', value: 'Wyoming' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'McDowell', value: 'McDowell' },
    { label: 'Morgan', value: 'Morgan' },
    { label: 'Taylor', value: 'Taylor' },
    { label: 'Barbour', value: 'Barbour' },
    { label: 'Lewis', value: 'Lewis' },
    { label: 'Wetzel', value: 'Wetzel' },
    { label: 'Braxton', value: 'Braxton' },
    { label: 'Roane', value: 'Roane' },
    { label: 'Hardy', value: 'Hardy' },
    { label: 'Monroe', value: 'Monroe' },
    { label: 'Summers', value: 'Summers' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Ritchie', value: 'Ritchie' },
    { label: 'Tyler', value: 'Tyler' },
    { label: 'Clay', value: 'Clay' },
    { label: 'Doddridge', value: 'Doddridge' },
    { label: 'Pocahontas', value: 'Pocahontas' },
    { label: 'Webster', value: 'Webster' },
    { label: 'Gilmer', value: 'Gilmer' },
    { label: 'Pleasants', value: 'Pleasants' },
    { label: 'Calhoun', value: 'Calhoun' },
    { label: 'Pendleton', value: 'Pendleton' },
    { label: 'Tucker', value: 'Tucker' },
    { label: 'Wirt', value: 'Wirt' },
  ],
  ND: [
    { label: 'Cass', value: 'Cass' },
    { label: 'Burleigh', value: 'Burleigh' },
    { label: 'Grand Forks', value: 'Grand Forks' },
    { label: 'Ward', value: 'Ward' },
    { label: 'Williams', value: 'Williams' },
    { label: 'Stark', value: 'Stark' },
    { label: 'Morton', value: 'Morton' },
    { label: 'Stutsman', value: 'Stutsman' },
    { label: 'Richland', value: 'Richland' },
    { label: 'Rolette', value: 'Rolette' },
    { label: 'McKenzie', value: 'McKenzie' },
    { label: 'Ramsey', value: 'Ramsey' },
    { label: 'Walsh', value: 'Walsh' },
    { label: 'Barnes', value: 'Barnes' },
    { label: 'Mountrail', value: 'Mountrail' },
    { label: 'McLean', value: 'McLean' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Traill', value: 'Traill' },
    { label: 'Pembina', value: 'Pembina' },
    { label: 'Benson', value: 'Benson' },
    { label: 'Bottineau', value: 'Bottineau' },
    { label: 'McHenry', value: 'McHenry' },
    { label: 'Ransom', value: 'Ransom' },
    { label: 'Dickey', value: 'Dickey' },
    { label: 'Dunn', value: 'Dunn' },
    { label: 'Sioux', value: 'Sioux' },
    { label: 'Pierce', value: 'Pierce' },
    { label: 'LaMoure', value: 'LaMoure' },
    { label: 'Wells', value: 'Wells' },
    { label: 'Sargent', value: 'Sargent' },
    { label: 'Cavalier', value: 'Cavalier' },
    { label: 'Emmons', value: 'Emmons' },
    { label: 'Foster', value: 'Foster' },
    { label: 'Bowman', value: 'Bowman' },
    { label: 'Nelson', value: 'Nelson' },
    { label: 'McIntosh', value: 'McIntosh' },
    { label: 'Hettinger', value: 'Hettinger' },
    { label: 'Kidder', value: 'Kidder' },
    { label: 'Renville', value: 'Renville' },
    { label: 'Griggs', value: 'Griggs' },
    { label: 'Grant', value: 'Grant' },
    { label: 'Divide', value: 'Divide' },
    { label: 'Adams', value: 'Adams' },
    { label: 'Eddy', value: 'Eddy' },
    { label: 'Towner', value: 'Towner' },
    { label: 'Burke', value: 'Burke' },
    { label: 'Logan', value: 'Logan' },
    { label: 'Golden Valley', value: 'Golden Valley' },
    { label: 'Oliver', value: 'Oliver' },
    { label: 'Steele', value: 'Steele' },
    { label: 'Sheridan', value: 'Sheridan' },
    { label: 'Billings', value: 'Billings' },
    { label: 'Slope', value: 'Slope' },
  ],
  VT: [
    { label: 'Chittenden', value: 'Chittenden' },
    { label: 'Rutland', value: 'Rutland' },
    { label: 'Washington', value: 'Washington' },
    { label: 'Windsor', value: 'Windsor' },
    { label: 'Franklin', value: 'Franklin' },
    { label: 'Windham', value: 'Windham' },
    { label: 'Addison', value: 'Addison' },
    { label: 'Bennington', value: 'Bennington' },
    { label: 'Caledonia', value: 'Caledonia' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Orleans', value: 'Orleans' },
    { label: 'Lamoille', value: 'Lamoille' },
    { label: 'Grand Isle', value: 'Grand Isle' },
    { label: 'Essex', value: 'Essex' },
  ],
  MT: [
    { label: 'Yellowstone', value: 'Yellowstone' },
    { label: 'Missoula', value: 'Missoula' },
    { label: 'Gallatin', value: 'Gallatin' },
    { label: 'Flathead', value: 'Flathead' },
    { label: 'Cascade', value: 'Cascade' },
    { label: 'Lewis and Clark', value: 'Lewis and Clark' },
    { label: 'Ravalli', value: 'Ravalli' },
    { label: 'Silver Bow', value: 'Silver Bow' },
    { label: 'Lake', value: 'Lake' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Hill', value: 'Hill' },
    { label: 'Park', value: 'Park' },
    { label: 'Glacier', value: 'Glacier' },
    { label: 'Big Horn', value: 'Big Horn' },
    { label: 'Jefferson', value: 'Jefferson' },
    { label: 'Custer', value: 'Custer' },
    { label: 'Sanders', value: 'Sanders' },
    { label: 'Fergus', value: 'Fergus' },
    { label: 'Richland', value: 'Richland' },
    { label: 'Roosevelt', value: 'Roosevelt' },
    { label: 'Carbon', value: 'Carbon' },
    { label: 'Stillwater', value: 'Stillwater' },
    { label: 'Beaverhead', value: 'Beaverhead' },
    { label: 'Rosebud', value: 'Rosebud' },
    { label: 'Deer Lodge', value: 'Deer Lodge' },
    { label: 'Dawson', value: 'Dawson' },
    { label: 'Madison', value: 'Madison' },
    { label: 'Valley', value: 'Valley' },
    { label: 'Powell', value: 'Powell' },
    { label: 'Blaine', value: 'Blaine' },
    { label: 'Teton', value: 'Teton' },
    { label: 'Pondera', value: 'Pondera' },
    { label: 'Broadwater', value: 'Broadwater' },
    { label: 'Chouteau', value: 'Chouteau' },
    { label: 'Toole', value: 'Toole' },
    { label: 'Musselshell', value: 'Musselshell' },
    { label: 'Mineral', value: 'Mineral' },
    { label: 'Phillips', value: 'Phillips' },
    { label: 'Sweet Grass', value: 'Sweet Grass' },
    { label: 'Sheridan', value: 'Sheridan' },
    { label: 'Granite', value: 'Granite' },
    { label: 'Fallon', value: 'Fallon' },
    { label: 'Liberty', value: 'Liberty' },
    { label: 'Wheatland', value: 'Wheatland' },
    { label: 'Judith Basin', value: 'Judith Basin' },
    { label: 'Meagher', value: 'Meagher' },
    { label: 'McCone', value: 'McCone' },
    { label: 'Daniels', value: 'Daniels' },
    { label: 'Powder River', value: 'Powder River' },
    { label: 'Carter', value: 'Carter' },
    { label: 'Prairie', value: 'Prairie' },
    { label: 'Wibaux', value: 'Wibaux' },
    { label: 'Garfield', value: 'Garfield' },
    { label: 'Golden Valley', value: 'Golden Valley' },
    { label: 'Treasure', value: 'Treasure' },
    { label: 'Petroleum', value: 'Petroleum' },
  ],
  WY: [
    { label: 'Laramie', value: 'Laramie' },
    { label: 'Natrona', value: 'Natrona' },
    { label: 'Campbell', value: 'Campbell' },
    { label: 'Sweetwater', value: 'Sweetwater' },
    { label: 'Fremont', value: 'Fremont' },
    { label: 'Albany', value: 'Albany' },
    { label: 'Sheridan', value: 'Sheridan' },
    { label: 'Park', value: 'Park' },
    { label: 'Teton', value: 'Teton' },
    { label: 'Uinta', value: 'Uinta' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Carbon', value: 'Carbon' },
    { label: 'Converse', value: 'Converse' },
    { label: 'Goshen', value: 'Goshen' },
    { label: 'Big Horn', value: 'Big Horn' },
    { label: 'Sublette', value: 'Sublette' },
    { label: 'Platte', value: 'Platte' },
    { label: 'Johnson', value: 'Johnson' },
    { label: 'Washakie', value: 'Washakie' },
    { label: 'Crook', value: 'Crook' },
    { label: 'Weston', value: 'Weston' },
    { label: 'Hot Springs', value: 'Hot Springs' },
    { label: 'Niobrara', value: 'Niobrara' },
  ],
  AS: [
    { label: 'Eastern District', value: 'Eastern District' },
    { label: "Manu'a District", value: "Manu'a District" },
    { label: 'Rose Atoll', value: 'Rose Atoll' },
    { label: 'Swains Island', value: 'Swains Island' },
    { label: 'Western District', value: 'Western District' },
  ],
  GU: [{ label: 'Guam', value: 'Guam' }],
  MP: [
    {
      label: 'Northern Islands Municipality',
      value: 'Northern Islands Municipality',
    },
    { label: 'Rota Municipality', value: 'Rota Municipality' },
    { label: 'Saipan Municipality', value: 'Saipan Municipality' },
    { label: 'Tinian Municipality', value: 'Tinian Municipality' },
  ],
  PR: [
    { label: 'Adjuntas Municipality', value: 'Adjuntas Municipality' },
    { label: 'Aguada Municipality', value: 'Aguada Municipality' },
    { label: 'Aguadilla Municipality', value: 'Aguadilla Municipality' },
    {
      label: 'Aguas Buenas Municipality',
      value: 'Aguas Buenas Municipality',
    },
    { label: 'Aibonito Municipality', value: 'Aibonito Municipality' },
    { label: 'A�asco Municipality', value: 'A�asco Municipality' },
    { label: 'Arecibo Municipality', value: 'Arecibo Municipality' },
    { label: 'Arroyo Municipality', value: 'Arroyo Municipality' },
    {
      label: 'Barceloneta Municipality',
      value: 'Barceloneta Municipality',
    },
    {
      label: 'Barranquitas Municipality',
      value: 'Barranquitas Municipality',
    },
    { label: 'Bayam�n Municipality', value: 'Bayam�n Municipality' },
    { label: 'Cabo Rojo Municipality', value: 'Cabo Rojo Municipality' },
    { label: 'Camuy Municipality', value: 'Camuy Municipality' },
    { label: 'Can�vanas Municipality', value: 'Can�vanas Municipality' },
    { label: 'Carolina Municipality', value: 'Carolina Municipality' },
    { label: 'Cata�o Municipality', value: 'Cata�o Municipality' },
    { label: 'Cayey Municipality', value: 'Cayey Municipality' },
    { label: 'Ceiba Municipality', value: 'Ceiba Municipality' },
    { label: 'Ciales Municipality', value: 'Ciales Municipality' },
    { label: 'Cidra Municipality', value: 'Cidra Municipality' },
    { label: 'Coamo Municipality', value: 'Coamo Municipality' },
    { label: 'Comer�o Municipality', value: 'Comer�o Municipality' },
    { label: 'Corozal Municipality', value: 'Corozal Municipality' },
    { label: 'Culebra Municipality', value: 'Culebra Municipality' },
    { label: 'Dorado Municipality', value: 'Dorado Municipality' },
    { label: 'Fajardo Municipality', value: 'Fajardo Municipality' },
    { label: 'Florida Municipality', value: 'Florida Municipality' },
    { label: 'Gu�nica Municipality', value: 'Gu�nica Municipality' },
    { label: 'Guayama Municipality', value: 'Guayama Municipality' },
    { label: 'Guayanilla Municipality', value: 'Guayanilla Municipality' },
    { label: 'Guaynabo Municipality', value: 'Guaynabo Municipality' },
    { label: 'Gurabo Municipality', value: 'Gurabo Municipality' },
    { label: 'Hatillo Municipality', value: 'Hatillo Municipality' },
    {
      label: 'Hormigueros Municipality',
      value: 'Hormigueros Municipality',
    },
    { label: 'Humacao Municipality', value: 'Humacao Municipality' },
    { label: 'Isabela Municipality', value: 'Isabela Municipality' },
    { label: 'Jayuya Municipality', value: 'Jayuya Municipality' },
    { label: 'Juana D�az Municipality', value: 'Juana D�az Municipality' },
    { label: 'Juncos Municipality', value: 'Juncos Municipality' },
    { label: 'Lajas Municipality', value: 'Lajas Municipality' },
    { label: 'Lares Municipality', value: 'Lares Municipality' },
    { label: 'Las Mar�as Municipality', value: 'Las Mar�as Municipality' },
    {
      label: 'Las Piedras Municipality',
      value: 'Las Piedras Municipality',
    },
    { label: 'Lo�za Municipality', value: 'Lo�za Municipality' },
    { label: 'Luquillo Municipality', value: 'Luquillo Municipality' },
    { label: 'Manat� Municipality', value: 'Manat� Municipality' },
    { label: 'Maricao Municipality', value: 'Maricao Municipality' },
    { label: 'Maunabo Municipality', value: 'Maunabo Municipality' },
    { label: 'Mayag�ez Municipality', value: 'Mayag�ez Municipality' },
    { label: 'Moca Municipality', value: 'Moca Municipality' },
    { label: 'Morovis Municipality', value: 'Morovis Municipality' },
    { label: 'Naguabo Municipality', value: 'Naguabo Municipality' },
    { label: 'Naranjito Municipality', value: 'Naranjito Municipality' },
    { label: 'Orocovis Municipality', value: 'Orocovis Municipality' },
    { label: 'Patillas Municipality', value: 'Patillas Municipality' },
    { label: 'Pe�uelas Municipality', value: 'Pe�uelas Municipality' },
    { label: 'Ponce Municipality', value: 'Ponce Municipality' },
    {
      label: 'Quebradillas Municipality',
      value: 'Quebradillas Municipality',
    },
    { label: 'Rinc�n Municipality', value: 'Rinc�n Municipality' },
    { label: 'R�o Grande Municipality', value: 'R�o Grande Municipality' },
    {
      label: 'Sabana Grande Municipality',
      value: 'Sabana Grande Municipality',
    },
    { label: 'Salinas Municipality', value: 'Salinas Municipality' },
    { label: 'San Germ�n Municipality', value: 'San Germ�n Municipality' },
    { label: 'San Juan Municipality', value: 'San Juan Municipality' },
    {
      label: 'San Lorenzo Municipality',
      value: 'San Lorenzo Municipality',
    },
    {
      label: 'San Sebasti�n Municipality',
      value: 'San Sebasti�n Municipality',
    },
    {
      label: 'Santa Isabel Municipality',
      value: 'Santa Isabel Municipality',
    },
    { label: 'Toa Alta Municipality', value: 'Toa Alta Municipality' },
    { label: 'Toa Baja Municipality', value: 'Toa Baja Municipality' },
    {
      label: 'Trujillo Alto Municipality',
      value: 'Trujillo Alto Municipality',
    },
    { label: 'Utuado Municipality', value: 'Utuado Municipality' },
    { label: 'Vega Alta Municipality', value: 'Vega Alta Municipality' },
    { label: 'Vega Baja Municipality', value: 'Vega Baja Municipality' },
    { label: 'Vieques Municipality', value: 'Vieques Municipality' },
    { label: 'Villalba Municipality', value: 'Villalba Municipality' },
    { label: 'Yabucoa Municipality', value: 'Yabucoa Municipality' },
    { label: 'Yauco Municipality', value: 'Yauco Municipality' },
  ],
  UM: [
    { label: 'Baker Island', value: 'Baker Island' },
    { label: 'Howland Island', value: 'Howland Island' },
    { label: 'Jarvis Island', value: 'Jarvis Island' },
    { label: 'Johnston Atoll', value: 'Johnston Atoll' },
    { label: 'Kingman Reef', value: 'Kingman Reef' },
    { label: 'Midway Atoll', value: 'Midway Atoll' },
    { label: 'Navassa Island', value: 'Navassa Island' },
    { label: 'Palmyra Atoll', value: 'Palmyra Atoll' },
    { label: 'Wake Island', value: 'Wake Island' },
  ],
  VI: [
    { label: 'Saint Croix Island', value: 'Saint Croix Island' },
    { label: 'Saint John Island', value: 'Saint John Island' },
    { label: 'Saint Thomas Island', value: 'Saint Thomas Island' },
  ],
};

export const ConsentedBy = {
  PATIENT: '153940000',
  GUARDIAN: '153940001',
};

export const ConsentedByOptions = [
  {
    label: () => getString('patient'),
    value: ConsentedBy.PATIENT,
  },
  {
    label: () => getString('guardian'),
    value: ConsentedBy.GUARDIAN,
  },
];
