import { useState } from 'react';
import { getString } from 'util/lang';

import Button from 'components/common/Button';
import { ConfirmModal } from 'components/common/ConfirmModal';

export const RemoveIneligiblePatient = ({
  removeCurrentPatient,
  currentPatientIndex,
}) => {
  const [showRemovePatientModal, setShowRemovePatientModal] = useState(false);

  const handleRemovePatient = () => {
    setShowRemovePatientModal(true);
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <Button
          label={getString('removePatientText')}
          title={getString('removePatientText')}
          onClick={handleRemovePatient}
          isEnabled={true}
          dataqa="go-to-screening"
          className="btn btn-primary col-9-sm"
        />
      </div>
      <ConfirmModal
        show={showRemovePatientModal}
        onCancel={() => {
          setShowRemovePatientModal(false);
        }}
        onConfirm={() => {
          removeCurrentPatient(currentPatientIndex);
        }}
        headerContent={
          <div>{getString('removePatientConfirmationQuestionTitle')}</div>
        }
        bodyContent={
          <div className="mb-10x">
            {getString('removePatientConfirmationQuestionText')}
          </div>
        }
        cancelText="No, Go Back"
        confirmText="Yes, Remove"
      />
    </>
  );
};
