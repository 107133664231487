import React from 'react';

import { getString } from 'util/lang';
import { getDaysInMonth } from 'util/DateAndTime';
import {
  MonthList,
  ShowAsRequiredFields,
  YearList,
} from 'constants/patientInfo';

import SelectBox from 'components/common/SelectBox';

/**
 * @param {{
 *  birthYear: string
 *  birthMonth: string
 *  birthDay: string
 *  onChangeInput: ((key: 'birthYear' | 'birthMonth' | 'birthDay', value: string) => void)
 * }} props
 * @returns
 */
const PrescreeningForm = (props) => {
  const { onChangeInput, birthYear, birthDay, birthMonth } = props;

  return (
    <>
      <h2 className="title--normal"> {getString('prescreeningHeading')} </h2>
      <p className="mb-10x"> {getString('prescreeningSubHeading')}</p>
      <h3 className="title--small">{getString('prescreeningDOBHeading')}</h3>

      <div className="patient-form-group border-rounded">
        <div className="row mb-10x">
          <div className="col-4 fs-mask">
            <SelectBox
              label={getString('year')}
              id="birthYear"
              defaultValue={getString('birthYear')}
              onChange={(e) => onChangeInput('birthYear', e.target.value)}
              value={birthYear}
              required={ShowAsRequiredFields['birthYear']}
              options={YearList()}
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              id="birthMonth"
              onChange={(e) => onChangeInput('birthMonth', e.target.value)}
              defaultValue={getString('birthMonth')}
              label={getString('birthMonth')}
              value={birthMonth}
              options={MonthList}
              required={ShowAsRequiredFields['birthMonth']}
              dataqa="prescreening-birthmonth"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              id="birthDay"
              onChange={(e) => onChangeInput('birthDay', e.target.value)}
              defaultValue={getString('day')}
              label={getString('day')}
              value={birthDay}
              options={getDaysInMonth(birthMonth, birthYear)}
              required={ShowAsRequiredFields['birthDay']}
              dataqa="prescreening-birthday"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescreeningForm;
