import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import * as routes from '../constants/routes/covidRoutes';

import RescheduleRoute from './Reschedule';
import Success from '../components/COVID/views/Success';
import ContactUs from '../components/COVID/views/ContactUs';
import PrivacyPolicy from '../components/COVID/views/PrivacyPolicy';

import HelpCenter from '../components/COVID/views/LandingPage/HelpCenter';
import WelcomePage from '../components/COVID/views/LandingPage/WelcomePage';
import PatientInfoWrapper from '../components/COVID/views/PaymentInfo';
import PatientInformation from '../components/COVID/views/PatientInformation';
import GroupAppointmentOptionPage from 'components/COVID/views/GroupAppointment';
import NewClinicAndTiming from '../components/COVID/views/NewClinicAndTiming';
import AddPatients from '../components/COVID/views/AddPatients';
import PatientEligibility from '../components/COVID/views/PatientEligibility';
import SelectSecondaryService from 'components/COVID/views/SelectSecondaryService';
import {
  ConfirmationPage,
  RescheduleConfirmationPage,
} from '../components/COVID/views/Confirmation';
import CancelPage from '../components/COVID/views/Success/container/CancelPage';
import SecurityCheck from '../components/COVID/views/SecurityCheck/container/SecurityCheck';
import SelectMultiService from 'components/COVID/views/SelectMultiService';
import SelectSubservice from 'components/COVID/views/SelectSubservice';
import SelectLocation from 'components/COVID/views/SelectLocation';
import NotEligible from 'components/COVID/views/NotEligibile';
import { GroupCancelVerify } from 'components/COVID/views/GroupAppointment/cancel/GroupCancelVerify';
import { GroupPendingCancelVerify } from 'components/COVID/views/GroupAppointment/cancel/GroupPendingCancelVerify';

import TestReport from 'components/COVID/views/TestReport';
import Prescreening from 'components/COVID/views/Prescreening';
import EditService from 'components/COVID/views/EditService';
import {
  RescheduleVerify as FollowupRescheduleVerify,
  RescheduleLocation as FollowupRescheduleLocation,
  RescheduleDateTime as FollowupRescheduleDateTime,
  RescheduleScreening as FollowupRescheduleScreening,
  ReschedulePatientInfo as FollowupReschedulePatientInfo,
  ReschedulePaymentInfo as FollowupReschedulePaymentInfo,
  RescheduleConfirmation as FollowupRescheduleConfirmation,
  RescheduleSuccess as FollowupRescheduleSuccess,
  FollowupCancel,
  FollowupLate,
  RegisterVerify as FollowupRegisterVerify,
  RegisterScreening as FollowupRegisterScreening,
  RegisterPatientInfo as FollowupRegisterPatientInfo,
  RegisterPaymentInfo as FollowupRegisterPaymentInfo,
  RegisterConfirmation as FollowupRegisterConfirmation,
  RegisterSuccess as FollowupRegisterSuccess,
} from 'components/COVID/views/Followup';
import {
  FollowupRescheduleRoute,
  RedirectToRescheduleVerify,
  RedirectToRegisterVerify,
  FollowupRegisterRoute,
} from './FollowupRoute';
import { GroupEmailRegisterRoute, GroupRescheduleRoute } from './GroupRoute';

import { ConfirmFollowUp } from 'components/COVID/views/ConfirmFollowUp';
import WaitlistInformation from 'components/COVID/views/Waitlist/WaitlistInformation';
import WaitlistSuccess from 'components/COVID/views/Waitlist/WaitlistSuccess';
import {
  UnsubscribePage,
  ResubscribePage,
} from 'components/COVID/views/Unsubscribe';
import { GroupRegisterVerify } from 'components/COVID/views/GroupAppointment/register/GroupRegisterVerify';
import { GroupRegisterScreening } from '../components/COVID/views/GroupAppointment/register/GroupScreening';
import { GroupRegisterPatientInfo } from '../components/COVID/views/GroupAppointment/register/GroupPatientInfo';
import { GroupRegisterConfirmation } from '../components/COVID/views/GroupAppointment/register/GroupRegisterConfirmation';
import { GroupAppointmentConfirmationLate } from '../components/COVID/views/GroupAppointment/Late';
import { GroupEmailRegisterSuccess } from '../components/COVID/views/GroupAppointment/register/GroupRegisterSuccess';
import { GroupRescheduleVerify } from '../components/COVID/views/GroupAppointment/reschedule/GroupRescheduleVerify';
import { GroupRescheduleTypeSelection } from '../components/COVID/views/GroupAppointment/reschedule/GroupRescheduleTypeSelection';
import { GroupRescheduleLocation } from '../components/COVID/views/GroupAppointment/reschedule/GroupRescheduleLocation';
import { GroupRescheduleDateTime } from '../components/COVID/views/GroupAppointment/reschedule/GroupRescheduleDateTime';
import { GroupRescheduleConfirmation } from '../components/COVID/views/GroupAppointment/reschedule/GroupRescheduleConfirmation';
import { GroupRescheduleSuccess } from '../components/COVID/views/GroupAppointment/reschedule/GroupRescheduleSuccess';
import { GroupCancelTypeSelection } from 'components/COVID/views/GroupAppointment/cancel/GroupCancelTypeSelection';
import DMEVerify from 'components/COVID/views/DME/container/DMEVerify';
import {
  EditDeliveryPacketPage,
  ViewDeliveryPacketPage,
} from 'components/COVID/views/DME/container/DeliveryPacket';
import {
  EditDeliveryTicketPage,
  ViewDeliveryTicketPage,
} from 'components/COVID/views/DME/container/DeliveryTicket';
import DMEProtectedRoute from 'components/COVID/views/DME/middlewares/DMEProtectedRoute';
import { DmeDocument } from 'components/DME/Documentation/container/DmeDocument';
import TestReportPdfView from 'components/COVID/views/TestReport/container/TestReportPdfView';
import DeliveryPacketPdfView from 'components/COVID/views/DME/container/DeliveryPacketPdfView';
import DeliveryTicketPdfView from 'components/COVID/views/DME/container/DeliveryTicketPdfView';

/**
 * App Router.
 * The <Route> paths are public routes
 * THe <ProtectedRoutes> requires the value of normalFlow (state) of parent app to be true
 */
function CovidAppRouter() {
  return (
    <Router>
      <Switch>
        {/* static pages */}
        <Route path={routes.WELCOME} exact component={WelcomePage} />
        <Route path={routes.HELP_CENTER} exact component={HelpCenter} />
        <Route path={routes.CONTACT_US} exact component={ContactUs} />
        <Route path={routes.PRIVACY_POLICY} exact component={PrivacyPolicy} />
        {/* <Route path={routes.WAIT_LIST} exact component={WaitListContainer} /> */}
        {/* <Route path={routes.WAIT_LIST_ORGANIZATION_SPECIFIC} exact component={WaitListContainer} /> */}
        <Route
          path={routes.MULTISERVICE}
          exact
          component={SelectMultiService}
        />
        <Route
          path={routes.MULTISERVICE_ORGANIZATION_SPECIFIC}
          exact
          component={SelectMultiService}
        />
        <Route
          path={routes.SECONDARY_SERVICE}
          exact
          component={SelectSecondaryService}
        />
        <Route
          path={routes.SECONDARY_SERVICE_ORGANIZATION_SPECIFIC}
          exact
          component={SelectSecondaryService}
        />
        <Route path={routes.PRESCREENING} exact component={Prescreening} />
        <Route
          path={routes.PRESCREENING_ORGANIZATION_SPECIFIC}
          exact
          component={Prescreening}
        />
        <Route path={routes.NOTELIGIBLE} exact component={NotEligible} />
        <Route
          path={routes.NOTELIGIBLE_ORGANIZATION_SPECIFIC}
          exact
          component={NotEligible}
        />
        <Route
          path={routes.SUBSERVICELIST}
          exact
          component={SelectSubservice}
        />
        <Route
          path={routes.SUBSERVICELIST_ORGANIZATION_SPECIFIC}
          exact
          component={SelectSubservice}
        />
        <Route path={routes.LOCATION} exact component={SelectLocation} />
        <Route
          path={routes.LOCATION_ORGANIZATION_SPECIFIC}
          exact
          component={SelectLocation}
        />
        <Route path={routes.HOME} exact component={PatientEligibility} />
        <Route
          path={routes.HOME_ORGANIZATION_SPECIFIC}
          exact
          component={PatientEligibility}
        />
        <Route
          path={routes.PATIENT_INFO}
          exact
          component={PatientInformation}
        />
        <Route
          path={routes.PATIENT_INFO_ORGANIZATION_SPCIFIC}
          exact
          component={PatientInformation}
        />
        <Route
          path={routes.CLINIC_AND_TIMING}
          exact
          component={NewClinicAndTiming}
        />
        <Route
          path={routes.CLINIC_AND_TIMING_ORGANIZATION_SPECIFIC}
          exact
          component={NewClinicAndTiming}
        />
        <Route path={routes.ADD_PATIENTS} exact component={AddPatients} />
        <Route
          path={routes.ADD_PATIENTS_ORGANIZATION_SPECIFIC}
          exact
          component={AddPatients}
        />
        <Route path={routes.CONFIRMATION} exact component={ConfirmationPage} />
        <Route
          path={routes.CONFIRMATION_ORGANIZATION_SPECIFIC}
          exact
          component={ConfirmationPage}
        />
        <Route path={routes.SUCCESS} exact component={Success} />
        <Route
          path={routes.SUCCESS_PAGE_ORGANIZATION_SPECIFIC}
          exact
          component={Success}
        />
        <Route path={routes.VERIFY} exact component={SecurityCheck} />
        <Route path={routes.CANCEL} exact component={CancelPage} />
        <Route path={routes.EDIT_SERVICES} exact component={EditService} />
        <Route
          path="/paymentInfo/:organizationId?"
          exact
          component={PatientInfoWrapper}
        />
        <Route
          path="/waitlist/screening/:organizationId?"
          exact
          component={WaitlistInformation}
        />
        <Route
          path="/waitlist/success/:organizationId?"
          exact
          component={WaitlistSuccess}
        />
        {/* Reschedule Routes */}
        <RescheduleRoute
          path={routes.RESCHEDULE_SUCCESS_PAGE}
          exact
          component={Success}
        />
        <RescheduleRoute
          path={routes.RESCHEDULE_SUCCESS_PAGE_ORGANIZATION_SPECIFIC}
          exact
          component={Success}
        />
        <RescheduleRoute
          path={routes.RESCHEDULE_CONFIRMATION}
          exact
          component={RescheduleConfirmationPage}
        />
        <RescheduleRoute
          path={routes.RESCHEDULE_CONFIRMATION_ORGANIZATION_SPECIFIC}
          exact
          component={RescheduleConfirmationPage}
        />
        <RescheduleRoute
          path={routes.RESCHEDULE_CLINIC_AND_TIMING}
          exact
          component={NewClinicAndTiming}
        />
        <RescheduleRoute
          path={routes.RESCHEDULE_CLINIC_AND_TIMING_ORGANIZATION_SPECIFIC}
          exact
          component={NewClinicAndTiming}
        />
        <Route path={routes.COVID_REPORT} exact component={TestReport} />
        <Route
          path={routes.COVID_REPORT_PRINT}
          exact
          component={TestReportPdfView}
        />
        <RescheduleRoute
          path={routes.COVID_REPORT_ORGANIZATION_SPECIFIC}
          exact
          component={TestReport}
        />
        {/* Followup registration routes */}
        <Route
          path="/followup/:appointmentId/complete/verify/:organizationId?"
          exact
          component={FollowupRegisterVerify}
        />
        <FollowupRegisterRoute
          path="/followup/:appointmentId/complete/screening/:organizationId?"
          exact
          component={FollowupRegisterScreening}
        />
        <FollowupRegisterRoute
          path="/followup/:appointmentId/complete/patientInfo/:organizationId?"
          exact
          component={FollowupRegisterPatientInfo}
        />
        <FollowupRegisterRoute
          path="/followup/:appointmentId/complete/paymentInfo/:organizationId?"
          exact
          component={FollowupRegisterPaymentInfo}
        />
        <FollowupRegisterRoute
          path="/followup/:appointmentId/complete/confirmation/:organizationId?"
          exact
          component={FollowupRegisterConfirmation}
        />
        <FollowupRegisterRoute
          path="/followup/:appointmentId/complete/success/:organizationId?"
          exact
          component={FollowupRegisterSuccess}
        />
        {/* default route for followup registration */}
        <Route
          path="/followup/:appointmentId/complete/:organizationId?"
          component={RedirectToRegisterVerify}
        />
        {/* Followup routes */}
        <Route
          path="/followup/:appointmentId/reschedule/verify/:organizationId?"
          exact
          component={FollowupRescheduleVerify}
        />
        <FollowupRescheduleRoute
          path="/followup/:appointmentId/reschedule/location/:organizationId?"
          exact
          component={FollowupRescheduleLocation}
        />
        <FollowupRescheduleRoute
          path="/followup/:appointmentId/reschedule/schedule/:organizationId?"
          exact
          component={FollowupRescheduleDateTime}
        />
        <FollowupRescheduleRoute
          path="/followup/:appointmentId/reschedule/screening/:organizationId?"
          exact
          component={FollowupRescheduleScreening}
        />
        <FollowupRescheduleRoute
          path="/followup/:appointmentId/reschedule/patientInfo/:organizationId?"
          exact
          component={FollowupReschedulePatientInfo}
        />
        <FollowupRescheduleRoute
          path="/followup/:appointmentId/reschedule/paymentInfo/:organizationId?"
          exact
          component={FollowupReschedulePaymentInfo}
        />
        <FollowupRescheduleRoute
          path="/followup/:appointmentId/reschedule/confirmation/:organizationId?"
          exact
          component={FollowupRescheduleConfirmation}
        />
        <FollowupRescheduleRoute
          path="/followup/:appointmentId/reschedule/success/:organizationId?"
          exact
          component={FollowupRescheduleSuccess}
        />
        {/* default route for followup reschedule */}
        <Route
          path="/followup/:appointmentId/reschedule/:organizationId?"
          component={RedirectToRescheduleVerify}
        />
        <Route
          path="/followup/:appointmentId/confirm/:secretKey/:organizationId?"
          component={ConfirmFollowUp}
        />
        {/* cancel followup appointment */}
        <Route
          path="/followup/:appointmentId/cancel/:organizationId?"
          exact
          component={FollowupCancel}
        />
        {/* followup late route */}
        <Route
          path="/followup/:appointmentId/late/:organizationId?"
          exact
          component={FollowupLate}
        />
        {/* Group Appointment Routes */}
        <Route
          path="/groupoption/:organizationId?"
          exact
          component={GroupAppointmentOptionPage}
        />
        {/*Group appointment - email registration flow routes*/}
        <Route
          path="/group/:groupAppointmentId/complete/verify/:organizationId?"
          exact
          component={GroupRegisterVerify}
        />
        <Route
          path="/group/:appointmentId/late/:organizationId?"
          exact
          component={GroupAppointmentConfirmationLate}
        />
        <GroupEmailRegisterRoute
          path="/group/:appointmentId/complete/screening/:organizationId?"
          exact
          component={GroupRegisterScreening}
        />
        <GroupEmailRegisterRoute
          path="/group/:appointmentId/complete/patientInfo/:organizationId?"
          exact
          component={GroupRegisterPatientInfo}
        />
        <GroupEmailRegisterRoute
          path="/group/:appointmentId/complete/confirmation/:organizationId?"
          exact
          component={GroupRegisterConfirmation}
        />
        <GroupEmailRegisterRoute
          path="/group/:appointmentId/complete/success/:organizationId?"
          exact
          component={GroupEmailRegisterSuccess}
        />
        {/*Group appointment - reschedule flow routes*/}
        <Route
          path="/group/:groupAppointmentId/reschedule/verify/:organizationId?"
          exact
          component={GroupRescheduleVerify}
        />
        <GroupRescheduleRoute
          path="/group/:appointmentId/reschedule/selection/:organizationId?"
          exact
          component={GroupRescheduleTypeSelection}
        />
        <GroupRescheduleRoute
          path="/group/:appointmentId/reschedule/location/:organizationId?"
          exact
          component={GroupRescheduleLocation}
        />
        <GroupRescheduleRoute
          path="/group/:appointmentId/reschedule/schedule/:organizationId?"
          exact
          component={GroupRescheduleDateTime}
        />
        <GroupRescheduleRoute
          path="/group/:appointmentId/reschedule/confirmation/:organizationId?"
          exact
          component={GroupRescheduleConfirmation}
        />
        <GroupRescheduleRoute
          path="/group/:appointmentId/reschedule/success/:organizationId?"
          exact
          component={GroupRescheduleSuccess}
        />
        <Route
          path="/group/:groupId/cancel/verify/pending/:organizationId?"
          exact
          component={GroupPendingCancelVerify}
        />
        <Route
          path="/group/:groupId/cancel/verify/:organizationId?"
          exact
          component={GroupCancelVerify}
        />
        <Route
          path="/group/canceltype"
          exact
          component={GroupCancelTypeSelection}
        />
        {/* unsubscribe */}
        <Route
          path="/unsubscribe/:contactId"
          exact
          component={UnsubscribePage}
        />
        {/* resubscribe */}
        <Route
          path="/resubscribe/:contactId"
          exact
          component={ResubscribePage}
        />
        {/* DME Verify */}
        <Route
          path={`${routes.DME_VERIFY}/:productOrderId/:deliveryTicketId?`}
          component={DMEVerify}
        />
        {/* DME Delivery Ticket */}
        {/*TODO: Edit and Pdf view deprecated for packet and ticket,
        Remove code during v2 implementation, while configuring respective read and write role based on type of user */}
        <DMEProtectedRoute
          path={`${routes.DME_DELIVERY_TICKET}/:productOrderId/:deliveryTicketId/edit`}
          component={EditDeliveryTicketPage}
        />
        <DMEProtectedRoute
          path={`${routes.DME_DELIVERY_TICKET}/:productOrderId/:deliveryTicketId`}
          component={ViewDeliveryTicketPage}
        />
        <DMEProtectedRoute
          exact
          path={`${routes.DME_DELIVERY_TICKET_PRINT}/:productOrderId/:deliveryTicketId`}
          component={DeliveryTicketPdfView}
        />
        {/* DME Delivery Packet */}
        <DMEProtectedRoute
          path={`${routes.DME_DELIVERY_PACKET}/:productOrderId/edit`}
          component={EditDeliveryPacketPage}
        />
        <DMEProtectedRoute
          path={`${routes.DME_DELIVERY_PACKET}/:productOrderId`}
          component={ViewDeliveryPacketPage}
        />
        <DMEProtectedRoute
          path={`${routes.DME_DELIVERY_PACKET_PRINT}/:productOrderId`}
          component={DeliveryPacketPdfView}
        />
        {/* Note: /document is an entry point via email notifcation which will
        esentially render the same esignature component */}
        <DMEProtectedRoute
          path={`${routes.DME_DOCUMENT}/:productOrderId`}
          component={DmeDocument}
        />
        {/* default route */}
        <Redirect to={routes.SERVICE} />
      </Switch>
    </Router>
  );
}

export default CovidAppRouter;
