import { forwardRef } from 'react';

const SelectBox = forwardRef((props, ref) => {
  const {
    id,
    defaultValue,
    onChange,
    onBlur = () => {},
    value,
    label,
    options = [],
    selectBoxClassName = false,
    hasError = false,
    required = false,
    solo = false,
    isDisabled,
    dataqa,
  } = props;

  return (
    <div className={`form-group mb-0x ${hasError ? ' has-error' : ''}`}>
      <label
        className={'d-inline-block mb-2x h5 text-demibold printLabel'}
        htmlFor={id}
      >
        <label className="" id="" />
        {label}
      </label>
      <select
        name={id}
        id={id}
        className={`form-control p-4x ${solo ? 'solo' : ''} ${selectBoxClassName ? selectBoxClassName : ''} printInput`}
        value={!value ? defaultValue : value}
        onChange={onChange}
        onBlur={(e) => onBlur(id, e.target.value)}
        ref={ref}
        disabled={isDisabled}
        data-qa={dataqa}
      >
        {!isDisabled && defaultValue && (
          <option disabled selected hidden value={defaultValue}>
            {defaultValue}
          </option>
        )}
        {options.length &&
          options.map(({ label, value }) => {
            return (
              <option value={value} key={label}>
                {typeof label === 'function' ? label() : label}
              </option>
            );
          })}
      </select>
    </div>
  );
});

export default SelectBox;
