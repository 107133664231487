import { useCallback } from 'react';
import c from 'classnames';
import styles from './radioSelectServiceTypeTab.module.scss';
import Collapsible from 'react-collapsible';

/**
 *
 * @param {{
 *  id: string,
 *  title: string
 *  active?: boolean
 *  onChangeServiceType: (id: string) => void
 * }} props
 * @returns
 */
const RadioServiceTypeTab = (props) => {
  const { id, onChange, selectService, isOpen } = props;

  const onClick = useCallback(() => {
    onChange(id);
  }, [id, onChange]);

  return (
    <div className={c(styles.radioMainContainer)}>
      <Collapsible
        trigger={<TriggerItem {...props} />}
        onOpening={onClick}
        open={isOpen}
      >
        {isOpen ? selectService : null}
      </Collapsible>
    </div>
  );
};

export default RadioServiceTypeTab;

const TriggerItem = (props) => (
  <div className={c('custom-radio', styles.radioServiceTypeTab)}>
    <input
      id={props.id}
      name={props.name}
      type="radio"
      className={c('custom-radio-input')}
      onChange={() => 2}
      checked={props.selected}
      data-qa={`radioServiceTypeTab-${props.id}`}
      autoComplete="off"
    />
    <label htmlFor={props.id}>
      <div>
        <div
          className={c(styles.radioServiceTypeTabLabel, {
            [styles.radioServiceTypeLabelBold]: props.selected,
            [styles.radioServiceTypeLabelLight]: !props.selected,
          })}
        >
          {props.title}
        </div>
      </div>
    </label>
  </div>
);
