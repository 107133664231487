export const mapUsefulLinkToApplicationList = (linkList, appSettingsList) => {
  linkList.forEach((app) => {
    const appLinkName = app?.linkName;

    if (!!appLinkName) {
      app['link'] = appSettingsList[`${appLinkName}`];
    }
  });

  return linkList;
};
