import React, { useContext, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import config from '../../../config';
import FormErrors from '../../common/FormErrors';
import { getString } from 'util/lang';
import { DetailContext } from '../../../context';
import { usePrevious } from '../../../hooks/usePrevious';

const ReCaptcha = (props) => {
  const { onCaptchaChange, isCaptchaValid, isLoading } = props;
  const context = useContext(DetailContext);

  const languageCode = context.details.appLanguageCode;

  const prevLanguageCode = usePrevious(languageCode);

  useEffect(() => {
    if (!prevLanguageCode) {
      return;
    }

    if (prevLanguageCode !== languageCode) {
      onCaptchaChange(null);
    }
  }, [languageCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section
      className={isLoading ? 'd-none' : 'section__margin captcha__section'}
    >
      <ReCAPTCHA
        key={languageCode}
        sitekey={config.captchaKey}
        onChange={(captchaValue) => onCaptchaChange(captchaValue)}
        type={'image'}
        hl={languageCode}
      />
      {!isCaptchaValid && (
        <FormErrors errors={{ captcha: getString('reCaptchaMessage') }} />
      )}
    </section>
  );
};

export default ReCaptcha;
