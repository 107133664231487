export const regimenTypes = {
  ONE_DOSE_SERIES: 153940000,
  TWO_DOSE_SERIES: 153940001,
};

export const regimentDose = {
  FIRST_DOSE_ONLY: 153940000,
  SECOND_DOSE_ONLY: 153940001,
  BOTH_DOSES: 153940002,
};

export const regimenViewType = {
  SINGLE_DOSE_VIEW: 'SINGLE_DOSE_VIEW',
  DOUBLE_DOSE_VIEW: 'DOUBLE_DOSE_VIEW',
};
