import config from '../config';
import * as http from '../util/http';

/**
 * // TODO: maybe put shared types somewhere outside ?
 * @typedef {import('../../../apiv2/src/application/entities/app-setting').AppSettings} AppSettings
 *
 * @returns {Promise<AppSettings>}
 */
export async function fetchAppSettings() {
  const { data: resp } = await http.get(config.entity.appSettings);

  if (!resp?.data?.success) {
    console.log('[INVALID_CONFIG]', resp?.data?.error);
  }
  return resp?.data;
}
