import { useRef, useEffect } from 'react';
import RadioServiceTypeTab from './RadioSelectServiceTypeTab';
import c from 'classnames';
import styles from './radioSelectServiceType.module.scss';

export const RadioSelectServiceType = (props) => {
  const {
    serviceTypeList,
    selectedServiceTypeId,
    onServiceTypeChange,
    collapsibleSelectService,
  } = props;

  const onChangeServiceType = (id) => {
    onServiceTypeChange(id);
  };

  const el = useRef(null);
  useEffect(() => {
    const element = el?.current;
    const alertButtonMainShadowElement = document.getElementById(
      'alertButtonMainShadow',
    );

    if (!(element && alertButtonMainShadowElement)) return;

    const observer = new ResizeObserver(() => {
      // HACK!! adding dynamic shadow using only react needed much state passing,
      // so, using DOM selectors directly
      if (element.clientHeight < element.scrollHeight) {
        alertButtonMainShadowElement.classList.add('alertButtonMainShadow');
        return;
      }

      alertButtonMainShadowElement.classList.remove('alertButtonMainShadow');
    });

    observer.observe(element);
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
  }, []);

  return (
    <div className={c(styles.radioSelectServiceTypePosRel)}>
      <div
        ref={el}
        className={c(
          styles.radioSelectServiceType,
          styles.radioSelectServiceTypePosAbsExpand,
        )}
      >
        {serviceTypeList.map((serviceType) => (
          <RadioServiceTypeTab
            key={serviceType.id}
            id={serviceType.id}
            name={serviceType.name}
            title={serviceType.name}
            onChange={onChangeServiceType}
            selected={selectedServiceTypeId === serviceType.id}
            selectService={collapsibleSelectService}
            isOpen={serviceType.id === selectedServiceTypeId}
          />
        ))}
      </div>
    </div>
  );
};
