import { CovidPageNames } from './pages';

import APP_LANGUAGES from './appLanguages';
import { ConsentedBy } from './patientInfo';

export const PatientDefaultInfo = {
  firstName: '',
  lastName: '',
  middleName: '',
  birthMonth: '',
  birthDay: '',
  birthYear: '',
  gender: null,
  race: null,
  ethnicity: null,
  mobileNo: '',
  email: '',
  aptUnit: '',
  city: '',
  state: '',
  county: '',
  zipCode: '',
  homeAddress: '',
  referredBy: '',
  confirmEmail: '',
  confirmationCode: null,
  phoneOptOut: false,
  emailOptOut: false,
  emailNotificationConsent: false,
  smsNotificationConsent: true,
  marketingNotificationConsent: true,
  iisConsent: true,
  primaryLanguage: '153940037',
  guardianFirstName: '',
  guardianMiddleName: '',
  guardianLastName: '',
  physicianName: '',
  physicianPhoneNumber: '',
};

export const DefaultPatientRecordInfo = {
  isRecordVerified: false,
  isExistingPatient: false,
  patientId: null,
  firstName: '',
  lastName: '',
  middleName: '',
  birthMonth: '',
  birthDay: '',
  birthYear: '',
  mobileNo: '',
  email: '',
};

export const InitialInsuranceState = {
  company: '',
  memberID: '',
  memberIDPrefix: '',
  memberIDSuffix: '',
  subscriberRelationship: '',
  cardHolderFirstName: '',
  cardHolderLastName: '',
  cardHolderMiddleName: '',
  insuranceCardFront: '',
  insuranceCardBack: '',
  groupNumber: '',
  coverageID: '',
  paymentType: null,
  insuranceType: null,
};

export const IntialAppStateCovid = {
  appointmentBeingRescheduled: {},
  rescheduleClinicId: null,
  firstPageLoaded: false,
  payerlistFetched: false,
  payerList: [],
  isScreeningQuestionFetching: false,
  isEligibilityQuestionFetching: false,
  setupPhase: true,
  normalFlow: false,
  loading: false,
  isSignatureCanvasOpen: false,
  clinicToClinicDays: {},
  // clinics: [],
  counties: [],
  states: [],
  secondVaccineQuestions: {
    firstVaccineDate: null,
    vaccineTaken: null,
    locationOfFirstVaccine: '',
  },
  isServiceListFetching: null,
  serviceSelection: {
    serviceList: [],
    selectedService: null, // service type
    selectedVaccine: null, // service
    selectedSubservice: null,
    filteredSubservice: null,
    locationSearchTerm: '',
    selectedClinic: null,
  },
  appointments: [],
  commonAppointmentDetails: [{ consentedBy: ConsentedBy.PATIENT }],
  appointmentDetails: [],
  acceptanceInfo: [
    {
      signatureUrl: '',
      compressedSignature: '',
      guardian: '',
      acceptedTerms: {},
    },
  ],
  availableInsuranceTypes: [],
  insuranceInfo: [{ ...InitialInsuranceState }],
  noInsuranceInfo: [
    {
      noInsuranceIdType: '',
      noInsuranceIdNumber: '',
      noInsuranceState: 'WA',
      noInsuranceAttempted: false,
    },
  ],
  pageComplete: {
    [CovidPageNames.BeforeApproval]: true,
    [CovidPageNames.PatientInfo]: false,
    [CovidPageNames.SelectClinicAndTiming]: false,
    [CovidPageNames.Confirmation]: false,
  },
  groupPendingPatientInfo: [],
  patientInfo: [{ ...PatientDefaultInfo }],
  patientHealthAnswers: [{}],
  patientPrescreeningAnswers: {},
  currentPatientIndex: 0,
  allowReschedule: true,
  accessPage: {},
  isAppSettingSet: false,
  isInValidConfig: false,
  eligibilityAlert: {
    show: false,
    success: false,
  },
  // screeningQuestions: '',
  // preScreeningQuestions: '',
  preScreeningBirthYear: '',
  preScreeningBirthMonth: '',
  preScreeningBirthDay: '',
  // eligibilityQuestions: '',
  eligibilityResponse: [{}],
  selfDeclaration: [
    {
      firstName: '',
      lastName: '',
      middleName: '',
    },
  ],
  isAgree: false,
  isFirstAgree: [false],
  captchaResponseKey: null,
  isInfoPageInvalid: false,
  appSettings: {
    noInsuranceEnabled: false,
    isCreditCardAccepted: false,
    isAppSettingsSet: false,
  },
  vaccineList: [],
  selectedVaccine: '',
  selectedVaccineService: '',
  isSecurityChecked: false,
  appLanguageCode: APP_LANGUAGES.english,
  // localizedWebContents: {
  //   about: '',
  //   appointmentSuccessMessage: '',
  //   eligibilityText: '',
  //   noClinicMessage: '',
  //   screeningInformation: '',
  //   termsAndCondition: '',
  //   insuranceMessage: '',
  //   noInsuranceMessage: '',
  // },
  registrationCode: null,
  isUserRegistered: false,
  isServiceAndClinicSelected: false,
  appointmentSuccess: false,
  patientRecordInfo: [{ ...DefaultPatientRecordInfo }],

  insuranceVerified: false,

  //multi service
  serviceTypes: { byId: {}, allIds: [] },
  services: { byId: {}, allIds: [] },
  subServices: { byId: {}, allIds: [] },
  selectedServices: [], // { serviceId, serviceTypeId, subServiceId }[]

  // Group Appointment
  isGroupAppointment: false,
  isIndividualReschedule: false,

  location: { byId: {}, allIds: [] },
  selectedLocationId: null,
  localizedWebContents: [],
  screeningQuestions: [],
  eligibilityQuestions: [],
  phicureResponse: [],
  insuranceCoverageList: [[]],

  followupConfirmationInfo: {
    appointmentStatus: null,
  },
  security: {
    token: undefined,
  },
};
