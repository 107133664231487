import PharmacyInfo from '../../common/PharmaInfo/PharmacyInfo';
import styles from './BillOfRightsAndResponsibilities.module.scss';
import { getString } from 'util/lang';
import FormHeader from '../../common/FormHeader/FormHeader';

const LABEL_PREFIX = 'dme.deliveryPacketForm.billOfRightsAndResponsibilities';

const BillOfRightsAndResponsibilities = (props) => {
  const { organizationDetail } = props;

  const rightsData = getString(`${LABEL_PREFIX}.rights.data`);

  const companyName = organizationDetail.organizationName;
  const phoneNumber = organizationDetail.organizationContactPhone;

  const responsibilitiesData = JSON.parse(
    getString(`${LABEL_PREFIX}.responsibilities.data`, {
      companyName: companyName,
    }),
  );

  return (
    <div className={'d-flex flex-column'}>
      <FormHeader text={getString(`${LABEL_PREFIX}.title`)} />

      <PharmacyInfo className="mt-6x" />

      <p className={styles.desc}>{getString(`${LABEL_PREFIX}.description`)}</p>

      <div className={`d-flex ${styles.rightsAndResponsibilities}`}>
        <div>
          <h3>{getString(`${LABEL_PREFIX}.rights.title`)}</h3>
          <ul>
            {rightsData.map((right, index) => (
              <li key={index}>{right}</li>
            ))}
          </ul>
        </div>
        <div>
          <h3>{getString(`${LABEL_PREFIX}.responsibilities.title`)}</h3>
          <ul>
            {responsibilitiesData.map((responsibility, index) => (
              <li key={index}>{responsibility}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.afterHourServices.title`)}</h3>
        <p>
          {getString(`${LABEL_PREFIX}.afterHourServices.description`, {
            companyName: companyName,
            phoneNumber: phoneNumber,
          })}
        </p>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.compliantProcedure.title`)}</h3>
        <p>
          {getString(`${LABEL_PREFIX}.compliantProcedure.description`, {
            companyName: companyName,
          })}
        </p>
      </div>
    </div>
  );
};

export default BillOfRightsAndResponsibilities;
