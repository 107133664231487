import { useCallback } from 'react';

import { useDetailContext } from 'context';
import * as appointmentService from 'services/appointment';

export const useAppointmentCancel = () => {
  const { setDetails } = useDetailContext();
  const cancelAppointment = useCallback(
    async (activityId, captchaResponseKey, accessToken) => {
      setDetails({ loading: true });
      await appointmentService.cancelAppointment(
        activityId,
        captchaResponseKey,
        accessToken,
      );
      setDetails({ loading: false });
    },
    [setDetails],
  );

  const cancelGroupAppointment = useCallback(
    async (
      groupAppointmentId,
      captchaResponseKey,
      isGroupAppointment,
      accessToken,
    ) => {
      setDetails({ loading: true });
      await appointmentService.cancelGroupAppointment(
        groupAppointmentId,
        { captchaResponseKey, isCancelGroup: isGroupAppointment },
        accessToken,
      );
      setDetails({ loading: false });
    },
    [setDetails],
  );

  return { cancelAppointment, cancelGroupAppointment };
};
