import config from '../config';
import { keyBy } from 'lodash';
import * as http from 'util/http';
import { getViewTypeByRegimen } from './healthcareServices';

/**
 * @typedef { import("types").ServiceType } ServiceType
 *
 * @param {string} languageId
 * @param {string} [organizationId]
 */
export const getAllServices = async (
  languageId,
  organizationId,
  skipScheduleCheck = false,
) => {
  const url = `${config.entity.service}/${languageId}`;
  const {
    data: { data: serviceTypes },
  } = await http.get(url, {
    params: {
      organizationId,
      skipScheduleCheck: skipScheduleCheck === true ? 'true' : undefined,
    },
  });
  const sortedServiceTypes = serviceTypes.sort((a, b) => a.order - b.order);

  return formatServicesForState(sortedServiceTypes);
};

/**
 * @typedef { import("types").Clinic } Clinic
 *
 * @param {Clinic} locations
 */
export const formatLocationDetailForState = (locations) => {
  const location = {
    byId: keyBy(locations, 'id'),
    allIds: locations.map((l) => l.id),
  };

  return location;
};

export const formatServicesForState = (sortedServiceTypes) => {
  let subserviceList = { byId: {}, allIds: [] };
  let serviceList = { byId: {}, allIds: [] };
  let serviceTypeList = { byId: {}, allIds: [] };

  // Filter out serviceTypes, services without children
  const validServiceTypes = sortedServiceTypes
    .map((serviceType) => ({
      ...serviceType,
      services: serviceType.services.filter(
        (service) => service.subServices.length > 0,
      ),
    }))
    .filter((serviceType) => serviceType.services.length > 0);

  validServiceTypes.forEach((serviceType) => {
    if (!serviceType?.id) {
      return;
    }
    const services = serviceType.services || [];

    serviceTypeList.byId[serviceType.id] = {
      ...serviceType,
      services: services.map((service) => service.id),
    };
    serviceTypeList.allIds.push(serviceType.id);

    services.forEach((service) => {
      if (!service?.id) {
        return;
      }

      const subServices = service.subServices || [];

      //sometimes there might be more than one subservices with default status set
      //likely due to data setup
      const defaultSubServiceIds = subServices
        .filter((subService) => subService.defaultStatus)
        .map((subService) => subService.id);

      //other subservice ids whose default status are not set
      const otherSubserviceIds = subServices
        .filter((subService) => !subService.defaultStatus)
        .map((subService) => subService.id);

      serviceList.byId[service.id] = {
        ...service,
        serviceTypeId: serviceType.id,
        subServices: [...defaultSubServiceIds, ...otherSubserviceIds],
      };
      serviceList.allIds.push(service?.id);

      const serviceRegimenTypeValue = mapRegimenTypes[service.regimenType];

      subServices.forEach((subService) => {
        if (!subService?.id) {
          return;
        }

        const serviceRegimenDoseValue =
          mapRegimenDoses[subService.serviceRegimenDose];
        const firstAppointmentStatusValue =
          mapAppointmentStatuses[subService.firstAppointmentStatus];
        const secondAppointmentStatusValue =
          mapAppointmentStatuses[subService.secondAppointmentStatus];

        subserviceList.byId[subService.id] = {
          ...subService,
          serviceName: service.name,
          serviceId: service.id,
          serviceTypeId: serviceType.id,
          serviceViewType: getViewTypeByRegimen(
            serviceRegimenDoseValue,
            serviceRegimenTypeValue,
          ),
          firstAppointmentStatus: firstAppointmentStatusValue,
          secondAppointmentStatus: secondAppointmentStatusValue,
          // copy these valies to subService
          isInsuranceEnabled: service.isInsuranceEnabled,
          isNoInsuranceEnabled: service.isNoInsuranceEnabled,
        };
        subserviceList.allIds.push(subService.id);
      });
    });
  });

  return {
    serviceTypes: serviceTypeList,
    services: serviceList,
    subServices: subserviceList,
  };
};

const mapRegimenDoses = {
  FIRST_DOSE_ONLY: 153940000,
  SECOND_DOSE_ONLY: 153940001,
  BOTH_DOSES: 153940002,
};

const mapAppointmentStatuses = {
  PROPOSED: 935000000,
  PENDING: 935000001,
  BOOKED: 935000002,
  ARRIVED: 935000003,
  FULFILLED: 935000004,
  CANCELLED: 935000005,
  NO_SHOW: 935000006,
  ENTERED_IN_ERROR: 935000007,
  CHECKED_IN: 935000008,
  WAITLIST: 935000009,
};

const mapRegimenTypes = {
  ONE_DOSE_SERIES: 153940000,
  TWO_DOSE_SERIES: 153940001,
};
