import { useCallback, useMemo, useState } from 'react';
import { useSelectionsContext } from 'context';
import { useFilterValidSecondaryServices } from 'context/useFilterValidSecondaryServices';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import { getString } from 'util/lang';
import Button from 'components/common/Button';
import SelectedServiceMessageBox from '../presentation/SelectedServiceMessageBox';
import SelectMultiServiceForm from '../../SelectMultiService/presentation/SelectMultiServiceForm';
import { useResetAppState } from 'context/useResetAppState';
import { changeTitle } from 'util/siteInfo';

const SecondaryMultiServiceContainer = () => {
  changeTitle('Secondary Service');
  const { selectedServices, setSelectedServices } = useSelectionsContext();
  const getSecondaryServiceTypes = useFilterValidSecondaryServices();
  const nav = useNavigationHelper();
  const resetAppState = useResetAppState();

  const serviceTypes = useMemo(
    () =>
      selectedServices?.[0]
        ? getSecondaryServiceTypes(selectedServices[0])
        : [],
    [selectedServices, getSecondaryServiceTypes],
  );

  const [_selection, setSelection] = useState(() =>
    selectedServices[1] && selectedServices[1]?.serviceTypeId
      ? {
          serviceTypeId: selectedServices[1].serviceTypeId,
          serviceId: selectedServices[1].serviceId,
        }
      : { serviceTypeId: serviceTypes[0]?.id, serviceId: null },
  );

  const selection = useMemo(() => {
    const selectedServiceType = _selection.serviceTypeId
      ? serviceTypes.find(
          (serviceType) => serviceType.id === _selection.serviceTypeId,
        )
      : serviceTypes[0];
    const selectedService = (() => {
      if (!selectedServiceType) {
        return null;
      }
      if (_selection.serviceId) {
        return selectedServiceType.services.find(
          (service) => service.id === _selection.serviceId,
        );
      }
      if (selectedServiceType.services.length === 1) {
        return selectedServiceType.services[0];
      }
      return null;
    })();
    return {
      serviceTypeId: selectedServiceType?.id ?? _selection.serviceTypeId,
      serviceId: selectedService?.id ?? _selection.serviceId,
    };
  }, [_selection, serviceTypes]);

  const onSelectServiceType = useCallback((serviceTypeId) => {
    setSelection((selection) => ({
      ...selection,
      serviceTypeId,
      serviceId: null,
    }));
  }, []);

  const onSelectService = useCallback((serviceId) => {
    setSelection((selection) => ({
      ...selection,
      serviceId,
    }));
  }, []);

  const allowedToSubmit = useMemo(
    () =>
      selection.serviceTypeId && // service type is selected
      selection.serviceId && // service is selected
      serviceTypes.some(
        (serviceType) =>
          serviceType.id === selection.serviceTypeId && // selected service type is valid
          serviceType.services.some(
            (service) => service.id === selection.serviceId,
          ), // selected service is valid
      ),
    [selection.serviceTypeId, selection.serviceId, serviceTypes],
  );

  const onSubmit = useCallback(() => {
    if (!allowedToSubmit) {
      return;
    }

    const newSelectedServices = [
      {
        serviceTypeId: selectedServices[0].serviceTypeId,
        serviceId: selectedServices[0].serviceId,
      },
      {
        serviceTypeId: selection.serviceTypeId,
        serviceId: selection.serviceId,
      },
    ];

    // ensure any remaining old data is reset
    resetAppState(({ serviceTypes, services, subServices }) => ({
      serviceTypes,
      services,
      subServices,
    }));
    setSelectedServices(newSelectedServices);

    // NOTE: do the forking into ineligible page, clinics, etc in sub service selection page
    nav.toPreScreening();
  }, [
    allowedToSubmit,
    nav,
    setSelectedServices,
    resetAppState,
    selectedServices,
    selection,
  ]);

  const onChangeService = useCallback(() => {
    // ensure only primary service remains selected
    setSelectedServices([selectedServices[0]]);

    nav.resetToFirstPage();
  }, [setSelectedServices, selectedServices, nav]);

  const primaryServiceName = selectedServices[0]?.service?.name;

  if (!selectedServices || selectedServices.length === 0) {
    nav.resetToFirstPage();
    setSelectedServices([]);

    return null;
  }

  return (
    <PageWrapper>
      <BackToPage text={getString('backToPreviousPage')} onClick={nav.goBack} />
      <h2 className="title--normal">
        {getString('secondaryMultiPageHeading')}{' '}
      </h2>
      <p className="mb-10x">{getString('secondaryMultiPageSubheading')} </p>
      <SelectedServiceMessageBox
        selectedService={primaryServiceName}
        onChangeService={onChangeService}
      />
      <SelectMultiServiceForm
        serviceSelection={selection}
        serviceTypes={serviceTypes}
        onSelectService={onSelectService}
        onSelectServiceType={onSelectServiceType}
      />
      <Button
        isEnabled={allowedToSubmit}
        label={getString('prescreeningButtonLabel')}
        onClick={onSubmit}
        className="btn btn-primary btn-block mt-5x"
      />
    </PageWrapper>
  );
};

export default SecondaryMultiServiceContainer;
