import React from 'react';
import moment from 'moment';
import Timeslot from './Timeslot';
import { getString } from 'util/lang';

import cls from 'classnames';
import styles from './AvailableTimeslots.module.scss';
import { useTeantTimezoneContext } from 'context';
import AlertMessage from 'components/COVID/common/AlertMessage';

/**
 * @typedef {import('types').TimeSlot} TimeSlot
 *
 * @typedef {{
 *    availableTimeSlots: TimeSlot[]
 *    selectedDate: string
 *    selectedTimeSlot: TimeSlot
 *    onTimeSelect: (timeSlot: TimeSlot) => void
 *    slotRef?: React.LegacyRef
 *    dataqa: string
 * }} Props
 *
 * @param {Props} props
 * @returns
 */
const AvailableTimeslots = (props) => {
  const {
    availableTimeSlots = [],
    selectedDate,
    selectedTimeSlot,
    onTimeSelect,
    slotRef,
    dataqa,
  } = props;

  const tenantTimezone = useTeantTimezoneContext();

  // TODO: save 'mmm dd, yyyy' as a constant
  const formattedDate = moment(selectedDate).format('MMMM DD, YYYY');

  const midDayTime = '12:00:00';

  const morningTimeSlots = [];
  const eveningTimeSlots = [];

  availableTimeSlots.forEach((timeSlot) => {
    if (timeSlot.start_time <= midDayTime) {
      morningTimeSlots.push(timeSlot);
    } else {
      eveningTimeSlots.push(timeSlot);
    }
  });

  return (
    <div className={cls('col-7-md', styles.availableTimeSlots)} ref={slotRef}>
      <h3 className="select-time-title">
        {getString('appointmentsForDate', formattedDate)}
      </h3>
      {tenantTimezone?.short && (
        <AlertMessage
          className="mb-4x ml-2x mr-2x"
          isVisible={true}
          type="info"
          message={
            <div>
              {getString('appointmentTimezoneInfo')}
              &nbsp;
              <b>{tenantTimezone.short} time zone.</b>
            </div>
          }
        />
      )}
      <div className="select-time-wrapper location-time-wrapper">
        {morningTimeSlots.length > 0 && (
          <div className={styles.timeSlotGroup}>
            {getString('morningShift')}
          </div>
        )}
        {morningTimeSlots.map((timeSlot, index) => (
          <span key={index}>
            <Timeslot
              time={timeSlot.start_time}
              slotCount={timeSlot.available_capacity}
              isSelected={selectedTimeSlot === timeSlot}
              timeSlot={timeSlot}
              onTimeSelect={onTimeSelect}
              dataqa={`${dataqa}-${index}`}
            />
          </span>
        ))}
        {eveningTimeSlots.length > 0 && (
          <div className={styles.timeSlotGroup}>
            {getString('afternoonShift')}
          </div>
        )}
        {eveningTimeSlots.map((timeSlot, index) => (
          <span key={index}>
            <Timeslot
              time={timeSlot.start_time}
              slotCount={timeSlot.available_capacity}
              isSelected={selectedTimeSlot === timeSlot}
              timeSlot={timeSlot}
              onTimeSelect={onTimeSelect}
              dataqa={`${dataqa}-${index}`}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default AvailableTimeslots;
