import { FiCheck } from 'vyaguta-icons/fi';
import { useNavigationHelper } from 'hooks/useNavigationHelper';

import * as DateTimeUtil from 'util/DateAndTime';
import Button from 'components/common/Button';
import { getString } from 'util/lang';
import ConfirmationCode from '../../../common/ConfirmationCode';
import NoConfirmationContent from '../presentation/NoConfirmationContent';
import { useSelectionsContext } from 'context';

import { GoogleMapUrl } from '../../../../../constants/success';

import { compareStrings } from 'util/string';

import { useDetailContext } from 'context';

const APPOINTMENT_STATUS = {
  PROPOSED: 'PROPOSED',
  PENDING: 'PENDING',
  BOOKED: 'BOOKED',
  ARRIVED: 'ARRIVED',
  FULFILLED: 'FULFILLED',
  CANCELLED: 'CANCELLED',
  NO_SHOW: 'NO_SHOW',
  ENTERED_IN_ERROR: 'ENTERED_IN_ERROR',
  CHECKED_IN: 'CHECKED_IN',
  WAITLIST: 'WAITLIST',
};

const FollowupConfirmationContent = () => {
  const context = useDetailContext();
  const nav = useNavigationHelper();

  const {
    patientInfo,
    appointments,
    followupConfirmationInfo,
    location,
    selectedLocationId,
    appSettings,
  } = context.details;

  const { organizationContactPhone, organizationContactEmail } = appSettings;
  const appointmentStatus = followupConfirmationInfo.appointmentStatus;

  const { selectedServices } = useSelectionsContext();

  const getServiceNames = () => {
    const serviceNames = selectedServices.map((service) => {
      const subServiceName = service.subService.name;
      const isNotSure =
        compareStrings(subServiceName, 'i am not sure') ||
        compareStrings(subServiceName, "i'm not sure");
      return isNotSure ? service.service.name : subServiceName;
    });

    return serviceNames;
  };

  const bookAnotherAppointment = () => {
    context.resetForNewPatient();

    nav.resetToFirstPage();
  };

  switch (appointmentStatus) {
    case APPOINTMENT_STATUS.CANCELLED:
      return (
        <NoConfirmationContent
          message={getString('followupCancelledMessage', {
            serviceName: (
              <strong className="color-black-base" tabIndex="0">
                {getServiceNames().length > 1 ? (
                  <>{getServiceNames().join(' and ')} </>
                ) : (
                  <>{getServiceNames()[0]} </>
                )}
              </strong>
            ),
          })}
          contactEmail={organizationContactEmail}
          contactPhone={organizationContactPhone}
          goToHomePage={bookAnotherAppointment}
        />
      );

    case APPOINTMENT_STATUS.BOOKED:
      const showConfirmationCode = patientInfo.confirmationCode;

      const { firstAppointment } = appointments[0];

      const appointmentTime = DateTimeUtil.convertTo12HourTimeFormatWithAmPm(
        firstAppointment.time,
      );
      const appointmentDate = DateTimeUtil.dateFormatShort(
        firstAppointment.date,
      );

      const locationInfo = location.byId[selectedLocationId];
      const locationLink = `${GoogleMapUrl}${locationInfo?.latitude},${locationInfo?.longitude}`;
      const clinic = [
        locationInfo?.name,
        locationInfo?.address,
        locationInfo?.city,
        locationInfo?.state,
        locationInfo?.postalCode,
      ]
        .filter((i) => i)
        .join(', ');

      return (
        <div className="success">
          <section className="selected-details section__margin">
            <div className="border border-radius section__container p-0x">
              {showConfirmationCode && (
                <>
                  <FiCheck
                    size={24}
                    className="success-check success-check--covid success-check--green mt-9x"
                  />
                  <ConfirmationCode code={patientInfo.confirmationCode} />
                </>
              )}
              {firstAppointment && (
                <div className="success-content border-bottom">
                  <p
                    tabIndex="0"
                    className="success-message success-message--covid"
                  >
                    {getString('appointmentFollowupConfirmationMessage', {
                      serviceName: (
                        <strong className="color-black-base" tabIndex="0">
                          {getServiceNames().length > 1 ? (
                            <>{getServiceNames().join(' and ')} </>
                          ) : (
                            <>{getServiceNames()[0]} </>
                          )}
                        </strong>
                      ),
                      dateAndTime: (
                        <>
                          <strong tabIndex="0" className="color-grey-text">
                            {getString(
                              'dateAtTime',
                              appointmentDate,
                              appointmentTime,
                            )}
                          </strong>
                        </>
                      ),
                      location: (
                        <a
                          href={locationLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-bold link-noUnderline"
                        >
                          {clinic}
                        </a>
                      ),
                    })}
                  </p>
                  <br></br>
                  <p className="success-message success-message--covid">
                    {getString('appointmentFollowupConfirmationNoticeMessage')}
                  </p>
                </div>
              )}
              <div className="success-action-covid followup">
                <Button
                  isEnabled
                  label={getString('bookAnother')}
                  onClick={bookAnotherAppointment}
                  className="btn btn--large btn-primary--outline"
                  dataqa="book-another-appt-btn"
                />
                <Button
                  isEnabled
                  label={getString('cancelAppointment')}
                  className="btn btn--large btn-danger--outline"
                  onClick={() => {}}
                  dataqa="cancel-appt-btn"
                />
              </div>
            </div>
          </section>
        </div>
      );

    default:
      return (
        <NoConfirmationContent
          message={getString('appointmentFollowupConfirmationInvalid')}
          contactEmail={organizationContactEmail}
          contactPhone={organizationContactPhone}
          goToHomePage={bookAnotherAppointment}
        />
      );
  }
};

export default FollowupConfirmationContent;
