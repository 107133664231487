// NOTE: The key of the question is the field name in the database.

export const answerKey = {
  yes: 'Yes',
  no: 'No',
  none: 'None',
};
export const passCriteria = [
  {
    key: answerKey.yes,
    value: 'YES',
  },
  {
    key: answerKey.no,
    value: 'NO',
  },
  {
    key: answerKey.none,
    value: 'NONE',
  },
];

export const CanvasDimensions = {
  dynamicsWidth: 347,
  dynamicsHeight: 152,
  portalWidth: 690,
  portalHeight: 97,
  scale: 2.5,
};
