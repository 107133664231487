import { useDetailContext } from 'context';
import { useCallback } from 'react';

export const useSecurityToken = () => {
  const { details, setDetails } = useDetailContext();

  const setSecurityToken = useCallback(
    (token) => {
      setDetails((state) => ({ security: { ...state.security, token } }));
    },
    [setDetails],
  );

  const resetSecurityToken = useCallback(() => {
    setSecurityToken(undefined);
  }, [setSecurityToken]);

  return {
    securityToken: details.security.token,
    setSecurityToken,
    resetSecurityToken,
  };
};
