import React, { Fragment } from 'react';
import parse from 'html-react-parser';

const EligibilityInfo = ({ eligibilityInformation }) => {
  if (!eligibilityInformation.length) return null;

  return (
    <section
      tabIndex="0"
      className="flu-shot-info section__margin border-radius border"
    >
      <h4>Eligibility Information</h4>
      {eligibilityInformation.map((et, idx) => (
        <Fragment key={idx}>
          {eligibilityInformation.length > 1 && (
            <h6 className="service-name">{et?.service}</h6>
          )}
          <div className="info-section">{parse(et?.eligibilityText)}</div>
        </Fragment>
      ))}
    </section>
  );
};

export default EligibilityInfo;
