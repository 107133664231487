import React from 'react';
import { getFormattedFullName } from '../../../../../util/TextFormat';
import FormInput from '../../../../common/FormInput';
import { getString } from 'util/lang';

const SelfDeclaration = (props) => {
  const {
    selfDeclarationHandler,
    firstName,
    lastName,
    middleName,
    isAgree,
    handleAgreement,
    disabledFieldStatus,
  } = props;
  return (
    <>
      <div className="patient-form-group border-rounded">
        <div className="row">
          <div className="col-4-md">
            <div className="form-group mb-0x">
              <FormInput
                onChange={selfDeclarationHandler}
                name="firstName"
                value={firstName}
                disableInput={disabledFieldStatus?.firstName}
                type="text"
                className="form-control fs-exclude"
                id="firstName"
                required
                label={getString('firstName')}
                data-qa="self-declaration-fname"
              />
            </div>
          </div>
          <div className="col-4-md">
            <div className="form-group mb-0x">
              <FormInput
                onChange={selfDeclarationHandler}
                name="middleName"
                value={middleName}
                disableInput={disabledFieldStatus?.middleName}
                type="text"
                className="form-control fs-exclude"
                id="middleName"
                label={getString('middleName')}
                data-qa="self-declaration-mname"
              />
            </div>
          </div>
          <div className="col-4-md">
            <div className="form-group mb-0x">
              <FormInput
                onChange={selfDeclarationHandler}
                name="lastName"
                value={lastName}
                disableInput={disabledFieldStatus?.lastName}
                type="text"
                className="form-control fs-exclude "
                inputClassName="border-radius--bottomRight"
                id="lastName"
                required
                label={getString('lastName')}
                data-qa="self-declaration-lname"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="custom-check px-0x my-4x terms-condition-check">
        <input
          data-qa="self-declaration-checkbox"
          autoComplete="off"
          type="checkbox"
          id="terms-conditions"
          checked={isAgree}
          onChange={handleAgreement}
        />
        <label htmlFor="terms-conditions">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
          {getString(
            'eligibleAssessmentMessage',
            <div
              className="color-primary-base font-weight-bold d-inline-block fs-exclude"
              style={{ fontWeight: 'bold' }}
              data-qa="eligibility-assessment-message"
            >
              {getFullName(firstName, lastName, middleName)}
            </div>,
          )}
        </label>
      </div>
    </>
  );
};

const getFullName = (firstName, lastName, middleName) => {
  if (!!firstName || !!lastName) {
    return getFormattedFullName({ firstName, middleName, lastName });
  }

  return (
    <div
      className="d-inline-block"
      style={{
        textDecoration: 'underline',
        color: 'white',
        borderBottom: '1px solid #515151',
      }}
    >
      <span className="d-inline-block" style={{ visibility: 'hidden' }}>
        placeholder
      </span>
    </div>
  );
};

export default SelfDeclaration;
