import React, { useEffect, useState } from 'react';

import { ApplicationCard } from './ApplicationCard';

import { applicationList } from '../../constants/applicationList';
import { mapUsefulLinkToApplicationList } from '../../helper/getAppLinks';

export const ApplicationList = (props) => {
  const { usefulLinks } = props;

  const [appList, setAppList] = useState([]);

  useEffect(() => {
    const appList = mapUsefulLinkToApplicationList(
      applicationList,
      usefulLinks,
    );
    setAppList(appList);
  }, []);

  return (
    <div className="applinks__list-wrapper">
      <div class="row">
        {!!appList.length &&
          appList.map((app) => {
            return <ApplicationCard application={app} />;
          })}
      </div>
    </div>
  );
};
