import React from 'react';
import { Link } from 'react-router-dom';

import { signeticBlackLogo } from 'assets/images';

import { getString } from 'util/lang';
import {
  CONTACT_US,
  PRIVACY_POLICY,
} from '../../../constants/routes/covidRoutes';
import cls from 'classnames';

const Footer = (props) => {
  const date = new Date();

  return (
    <footer className={cls('footer', props?.className)}>
      <div className="container-lg">
        <div className="footer__wrap">
          <div className="footer__logo">
            <a href="https://signetic.com" target="_blank" rel="noreferrer">
              <img
                className="footer__logo__image"
                src={signeticBlackLogo}
                alt="signetic_logo"
              />
            </a>
            <span className="footer__logo__date">
              &copy; {date.getFullYear()}{' '}
            </span>
          </div>

          <div className="footer__links">
            <Link
              className="mr-8x"
              to={{ pathname: CONTACT_US, search: window.location.search }}
            >
              {getString('contactUs')}
            </Link>
            <Link
              to={{ pathname: PRIVACY_POLICY, search: window.location.search }}
            >
              {getString('privacyPolicy')}
            </Link>{' '}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
