import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { Success } from 'components/COVID/views/Success/container/Success';
import { changeTitle } from 'util/siteInfo';

export const RegisterSuccess = () => {
  changeTitle('Follow Up | Success');
  return (
    <PageWrapper>
      <Success />
    </PageWrapper>
  );
};
