import { getString } from "util/lang";
import { Text, View } from "@react-pdf/renderer"

import { useStyles } from "../ABNFormPdf.styles";

export const ABNFormLegalNotice = () => {
    const styles = useStyles;
    return (
        <>
            <View>
                <Text style={[styles.accessibilityNotice, styles.fontWt700]}>
                    {getString('dme.documentation.abnForm.signatureLegalNote')}
                </Text>
            </View >
            <View style={styles.legalNotice}>
                <Text style={styles.legalText}>
                    {getString("dme.documentation.abnForm.legalNote")}
                </Text>
            </View>
        </>
    )
}