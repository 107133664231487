import { useMemo } from 'react';

import EmptyTimeslots from '../../NewClinicAndTiming/presentation/EmptyTimeslots';
import Calendar from 'components/common/Calendar';
import AvailableTimeslots from './AvailableTimeslots';
import CalendarLegend from 'components/common/Calendar/CalendarLegend';
import ActivityIndicator from 'components/common/ActivityIndicator';
import { extractDateFromDateObject } from 'util/DateAndTime';

/**
 * @typedef {{
 *  doseNumber: string
 *  defaultOpen: boolean
 *  availableDates: Date[]
 *  selectedDate: string
 *  selectedTime: string
 *  onDateSelect: string
 *  onTimeSelect: (timeslot: TimeSlot) => void
 *  timeslots: TimeSlot[]
 *  isDisabled: boolean
 *  slotRef: React.Ref
 *  calendarRef: React.Ref
 *  isRescheduleable: boolean
 *  setIsSecondDoseViewOpen: string
 *  isFetchingBlocks: string
 * }} Props
 *
 * @param {Props} props
 * @returns
 */
const DTAccordion = (props) => {
  const {
    isFetchingBlocks,
    slotRef,
    availableDates,
    bookingBlocks,
    selectedDate,
    selectedBookingBlock,
    onDateSelect,
    onTimeSelect,

    defaultOpen: isOpen,
    isDisabled = false,

    dataqaCalendar,
    dataqaTimeSlots,
  } = props;

  const includeDates = useMemo(() => {
    return availableDates.map((date) => extractDateFromDateObject(date));
  }, [availableDates]);
  const selected = useMemo(() => {
    return extractDateFromDateObject(selectedDate);
  }, [selectedDate]);

  return (
    <div>
      <div
        className={`accordion ${isOpen ? 'accordion--open' : ''} date-time-container`}
      >
        <div className="accordion__content">
          <div className="row">
            <div className="col-5-md">
              <div className="calendar calendar-v2">
                {!isDisabled && (
                  <Calendar
                    dataqa={dataqaCalendar}
                    includeDates={includeDates}
                    selected={selected}
                    onChange={onDateSelect}
                  />
                )}
                <CalendarLegend />
              </div>
            </div>
            {isFetchingBlocks ? (
              <div className="mx-auto">
                <ActivityIndicator />
              </div>
            ) : selectedDate ? (
              <AvailableTimeslots
                availableTimeSlots={bookingBlocks}
                selectedDate={selectedDate}
                selectedTimeSlot={selectedBookingBlock}
                onTimeSelect={onTimeSelect}
                slotRef={slotRef}
                dataqa={dataqaTimeSlots}
              />
            ) : (
              <EmptyTimeslots />
            )}
            {/* TODO: Handle cases with availableTimeslots = 0 */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DTAccordion;
