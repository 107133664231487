import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDetailContext, usePatientInfo } from 'context';
import { useSecurityToken } from 'context/useSecurityToken';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useConfirmModal } from 'components/common/ConfirmModal';
import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { SecurityCheck } from '../../SecurityCheck/SecurityCheck';
import { useSelectedServiceNames } from 'context/useSelectedServiceNames';
import { useFollowupStatusCheck } from 'context/useFollowupStatusCheck';
import { AppointmentStatuses } from 'constants/appointment';
import { InvalidAppointmentMessage } from '../../../common/InvalidAppointmentMessage';
import { changeTitle } from 'util/siteInfo';

export const RescheduleVerify = () => {
  changeTitle('Verify');
  const { appointmentId, organizationId } = useParams();
  const { setSecurityToken, resetSecurityToken } = useSecurityToken();
  const { rescheduleDataSetup, setRescheduleAppointment, setDetails } =
    useDetailContext();
  const serviceNames = useSelectedServiceNames();
  const nav = useNavigationHelper();
  const [isLoading, setIsLoading] = useState(false);
  const { ConfirmModal, confirm } = useConfirmModal();
  const [isResheduleValid, setIsResheduleValid] = useState(true);
  const [dataSetupCompleted, setDataSetupCompleted] = useState(false);

  const followupStatus = useFollowupStatusCheck(appointmentId);

  const { currentPatientInfo } = usePatientInfo();

  const onSecurityVerified = useCallback(
    async ({ token, details: patientInfo }) => {
      try {
        setIsLoading(true);
        setSecurityToken(token);
        setDetails((details) => ({
          patientInfo: [
            {
              ...currentPatientInfo,
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
              birthMonth: patientInfo.birthMonth,
              birthDay: patientInfo.birthDay,
              birthYear: patientInfo.birthYear,
            },
          ],
          selfDeclaration: [
            {
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
            },
          ],
        }));
        // fetch and setup data

        if (!dataSetupCompleted) {
          // if user verifies, then cancels then verifies again, dont do the reschedule setup again
          const result = await rescheduleDataSetup(
            organizationId,
            appointmentId,
          );
          if (!result) {
            // handle error
            throw new Error('Failed to setup reschedule');
          }
          setDataSetupCompleted(true);
        }

        const appointment = await new Promise((resolve) => {
          setDetails(({ appointments }) => {
            resolve(appointments[0]);
            return {};
          });
        });

        setIsLoading(false);

        const validReschedule = [
          AppointmentStatuses.PENDING,
          AppointmentStatuses.BOOKED,
        ].includes(appointment.appointmentStatus);
        setIsResheduleValid(validReschedule);

        if (!validReschedule) {
          return;
        }

        const confirmResult = await confirm();

        if (!confirmResult.confirm) {
          return;
        }

        if (appointment.completed) {
          await setRescheduleAppointment(appointmentId);
          nav.toRescheduleAppointment(appointmentId);
          return;
        }

        nav.toFollowup.reschedule.location(appointmentId);
      } catch (error) {
        console.error(error);
        error.message && toast.error({ title: '', message: error.message });
        resetSecurityToken();
      } finally {
        setIsLoading(false);
      }
    },
    [
      nav,
      confirm,
      setSecurityToken,
      resetSecurityToken,
      appointmentId,
      organizationId,
      rescheduleDataSetup,
      setDetails,
      dataSetupCompleted,
      setRescheduleAppointment,
    ],
  );

  return (
    <PageWrapper bgGrey>
      {isResheduleValid && !followupStatus.error ? (
        <SecurityCheck
          loading={isLoading || !followupStatus.loaded}
          activityId={appointmentId}
          onSecurityVerified={onSecurityVerified}
          headerMsg={getString('followupRescheduleVerificationHeader')}
          alertMsg={getString('followupRescheduleVerificationAlert')}
        />
      ) : (
        <InvalidAppointmentMessage />
      )}
      <ConfirmModal
        headerContent={getString('followupRescheduleConfirmHeader')}
        bodyContent={getString(
          'followupRescheduleConfirmMessage',
          <>{serviceNames}</>,
        )}
        cancelText={getString('followupRescheduleNo')}
        confirmText={getString('followupRescheduleYes')}
      />
    </PageWrapper>
  );
};
