import React from 'react';
import { Link } from 'react-router-dom';

import { getString } from 'util/lang';
import Modal from 'components/COVID/common/Modal';
import { CONTACT_US } from 'constants/routes/covidRoutes';

const ConflictModal = ({ closeConflictModal }) => {
  return (
    <Modal close={closeConflictModal}>
      <div className="modal__wrapper open">
        <div className="modal__content">
          <div className="modal__header pb-5x">
            <h4 className="color-primary-base mb-0x">
              {getString('stayWithUs')}
            </h4>
          </div>
          <div className="modal__body date-time-pick vaccine-appointment">
            <p>{getString('slotTaken')}</p>
            <div className={'row justify-content mb-5x ml-0x mr-0x'}>
              <p>{getString('bookingTroubleQuestion')} &nbsp;</p>
              <Link data-qa="contact-us-conflict" to={CONTACT_US}>
                <div className="btn btn--small btn-primary">
                  {getString('contactUs')}
                </div>
              </Link>
            </div>
          </div>
          <div className="modal__footer border-top py-4x">
            <div className="d-flex justify-content-end">
              <div
                data-qa="confick-ok"
                className="action-buttons"
                onClick={closeConflictModal}
              >
                <div className="btn btn--large btn-primary--outline">
                  {getString('ok')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConflictModal;
