import React from 'react';
import { UiLocationPoint } from 'vyaguta-icons/ui';

import { getString } from 'util/lang';
import Button from 'components/common/Button';

const LocationFilter = (props) => {
  const {
    searchTerm,
    onSearchTermChange,
    onSearch,
    searchContainer,
    searchInput,
    searchButton,
  } = props;

  const handleSearchTermChange = (e) => {
    onSearchTermChange(e.currentTarget.value);
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div data-qa={searchContainer} className="col fs-mask ms-location-search">
        <div className="form-group">
          <input
            type="text"
            id="location"
            className="form-control form-control-w-icon text-ellipses label-placeholder--focus border-radius--left"
            autoComplete=""
            value={searchTerm}
            onChange={handleSearchTermChange}
            onKeyDown={handleEnterPress}
            data-qa="desktop-search-location"
          />
          {!searchTerm && (
            <label
              htmlFor="location"
              className="label-placeholder text-ellipses"
            >
              <span className="text-bold color-black-text">
                {getString('locationSearchPlaceholder')}
              </span>
              , {getString('preferredLocation')}
            </label>
          )}
          <div className="form-control-icon">
            <UiLocationPoint size={22} className="color-primary-base" />
          </div>
        </div>
      </div>
      <div className="col d-none d-sm-block search-button__wrapper">
        <Button
          className="btn btn-primary btn-block ms-search-button ms-search-button--sm ms-search-button--rounded w-100-sm"
          onClick={onSearch}
          label={getString('search')}
          isEnabled={true}
          dataqa="location-search"
        />
      </div>
    </div>
  );
};

export default LocationFilter;
