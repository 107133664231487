import React, { useContext } from 'react';

import FormInput from '../../../../common/FormInput';
import SelectBox from '../../../../common/SelectBox';
import FormErrors from '../../../../common/FormErrors';
import AlertMessage from '../../../common/AlertMessage';
import {
  GenderOptions,
  MonthList,
  DayList,
  GroupPatientBasicInfoKeys,
  ShowAsRequiredFields,
  RaceOptions,
  EthnicityOptions,
  YearList,
  LanguageOptions,
} from '../../../../../constants/patientInfo';
import { isLeapYear } from '../../../../../util/DateAndTime';
import { getString } from '../../../../../util/lang';

const GroupPatientBasicInfo = (props) => {
  const {
    onChangeInput,
    patientInfo,
    errors,
    isInsuranceValidationLoading,
    isIISConsentEnabled,
  } = props;

  const errorSet = React.useMemo(() => {
    const errorSet = {};
    [...GroupPatientBasicInfoKeys].forEach((key) => {
      if (errors[key]) {
        errorSet[key] = errors[key];
      }
    });
    return errorSet;
  }, [errors]);

  const getDaysInMonth = (birthMonth, birthYear) => {
    const TwentyNineDayMonths = ['2'];
    const ThirtyDayMonths = ['4', '6', '9', '11'];
    if (TwentyNineDayMonths.includes(birthMonth)) {
      if (!isLeapYear(birthYear)) {
        return DayList.slice(0, 28);
      }
      return DayList.slice(0, 29);
    } else if (ThirtyDayMonths.includes(birthMonth)) {
      return DayList.slice(0, 30);
    }
    return DayList;
  };

  return (
    <section className="form-patient-info section__margin ">
      <h2 tabIndex="0">{getString('patientInfo')}</h2>
      <div className="patient-form-group border-rounded">
        <div className="row">
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('firstName')}
              id="firstName"
              onChange={onChangeInput}
              value={patientInfo.firstName}
              hasError={!!errorSet['firstName']}
              required={ShowAsRequiredFields['firstName']}
              disableInput={isInsuranceValidationLoading}
              data-qa="first-name"
            />
          </div>
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('middleName')}
              id="middleName"
              onChange={onChangeInput}
              value={patientInfo.middleName}
              hasError={!!errorSet['middleName']}
              required={ShowAsRequiredFields['middleName']}
              data-qa="middle-name"
              disableInput={isInsuranceValidationLoading}
            />
          </div>
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('lastName')}
              id="lastName"
              onChange={onChangeInput}
              value={patientInfo.lastName}
              hasError={!!errorSet['lastName']}
              required={ShowAsRequiredFields['lastName']}
              disableInput={isInsuranceValidationLoading}
              data-qa="last-name"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              label={getString('year')}
              id="birthYear"
              defaultValue={getString('birthYear')}
              onChange={(e) => onChangeInput('birthYear', e.target.value)}
              value={patientInfo.birthYear}
              hasError={!!errorSet['birthYear']}
              required={ShowAsRequiredFields['birthYear']}
              options={YearList()}
              isDisabled={isInsuranceValidationLoading}
              dataqa="birth-year-info-page"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              id="birthMonth"
              onChange={(e) => onChangeInput('birthMonth', e.target.value)}
              defaultValue={getString('birthMonth')}
              label={getString('birthMonth')}
              value={patientInfo.birthMonth}
              options={MonthList}
              hasError={!!errorSet['birthMonth']}
              required={ShowAsRequiredFields['birthMonth']}
              isDisabled={isInsuranceValidationLoading}
              dataqa="birth-month-info-page"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              id="birthDay"
              onChange={(e) => onChangeInput('birthDay', e.target.value)}
              defaultValue={getString('day')}
              label={getString('day')}
              value={patientInfo.birthDay}
              options={getDaysInMonth(
                patientInfo.birthMonth,
                patientInfo.birthYear,
              )}
              hasError={!!errorSet['birthDay']}
              required={ShowAsRequiredFields['birthDay']}
              isDisabled={isInsuranceValidationLoading}
              dataqa="birth-day-info-page"
            />
          </div>

          <div className="col-4-md fs-mask">
            <SelectBox
              id="ethnicity"
              onChange={(e) => onChangeInput('ethnicity', e.target.value)}
              label={getString('ethnicity')}
              defaultValue=""
              value={patientInfo.ethnicity}
              options={EthnicityOptions}
              hasError={!!errorSet['ethnicity']}
              required={ShowAsRequiredFields['ethnicity']}
              dataqa="ethnicity-info-page"
            />
          </div>

          <div className="col-8-md fs-mask">
            <SelectBox
              id="race"
              onChange={(e) => onChangeInput('race', e.target.value)}
              label={getString('race')}
              defaultValue=""
              value={patientInfo.race}
              options={RaceOptions}
              hasError={!!errorSet['race']}
              required={ShowAsRequiredFields['race']}
              isDisabled={isInsuranceValidationLoading}
              dataqa="race-info-page"
            />
          </div>
          <div className="col-4-md fs-mask">
            <SelectBox
              id="gender"
              onChange={(e) => onChangeInput('gender', e.target.value)}
              defaultValue={getString('gender')}
              label={getString('gender')}
              value={patientInfo.gender}
              options={GenderOptions}
              hasError={!!errorSet['gender']}
              required={ShowAsRequiredFields['gender']}
              isDisabled={isInsuranceValidationLoading}
              dataqa="gender-info-page"
            />
          </div>
          <div className="col-8-md fs-mask">
            <SelectBox
              id="primaryLanguage"
              onChange={(e) => onChangeInput('primaryLanguage', e.target.value)}
              defaultValue=""
              label={getString('primaryLanguage')}
              value={patientInfo.primaryLanguage}
              options={LanguageOptions}
              hasError={!!errorSet['primaryLanguage']}
              required={ShowAsRequiredFields['primaryLanguage']}
              dataqa="primary-language-info-page"
            />
          </div>
          <div className="col-12-md fs-mask">
            <FormInput
              label={getString('referringOrganization')}
              id="referredBy"
              onChange={onChangeInput}
              value={patientInfo.referredBy}
              hasError={!!errorSet['referredBy']}
              required={ShowAsRequiredFields['referredBy']}
              data-qa="referred-by-info-page"
            />
          </div>
        </div>
        {patientInfo.emailOptOut && (
          <div className="py-3x alert__wrapper--white">
            <AlertMessage
              type="warning"
              message={getString('optOutEmail')}
              className="mx-5x"
              isVisible={patientInfo.emailOptOut}
              dataqa="email-opt-alert-info-page"
            />
          </div>
        )}
      </div>
      <FormErrors errors={errorSet} />
      <AlertMessage
        type="info"
        message={getString('iisConsentInfo')}
        className="mt-5x"
        isVisible={isIISConsentEnabled}
        dataqa="iis-consent-alert-info-page"
      />
    </section>
  );
};

export default GroupPatientBasicInfo;
