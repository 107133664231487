import React, { useContext } from 'react';
import { DetailContext } from '../../../../../context';
import { getString } from 'util/lang';

const ContactInfo = () => {
  const appContext = useContext(DetailContext);

  const { appSettings } = appContext.details;
  const orgName = appSettings.organizationName;
  const orgContactEmail = appSettings.organizationContactEmail;
  const orgContactPhone = appSettings.organizationContactPhone;

  return (
    <div className="main-wrapper">
      <div className="container mt-10x mb-10x">
        <section className="flu-shot-info section__margin border-radius border">
          <h5 tabIndex="0" className="section__title">
            {getString('contactUs')}
          </h5>
          <p tabIndex="0">{getString('highVolumeMessage', orgName)}</p>

          <p tabIndex="0">{getString('existingInquiry')}</p>
          <p tabIndex="0">
            <strong>{getString('contactLabel')}: </strong>
            {orgContactEmail && (
              <a href={`mailto:${orgContactEmail}`}>{orgContactEmail}</a>
            )}
            {orgContactEmail && orgContactPhone && <span>{', '}</span>}
            {orgContactPhone && (
              <a href={`tel:${orgContactPhone}`}>{orgContactPhone}</a>
            )}
          </p>
        </section>
      </div>
    </div>
  );
};

export default ContactInfo;
