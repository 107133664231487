import React from 'react';
import cls from 'classnames';

import ActivityIndicator from './ActivityIndicator';

/**
 * @typedef {{
 *  onClick?: React.MouseEventHandler<HTMLElement>
 *  label: string
 *  isEnabled?: boolean
 *  isLoading?: boolean
 *  className?: string
 *  title: string
 *  dataqa?: string
 *  outline?: boolean
 *  icon?: ReactElement
 *  classNames?: {
 *    button?: string
 *    ActivityIndicator?: string
 *  }
 * }} Props
 *
 * @param {Props} props
 */
const Button = (props) => {
  const {
    onClick,
    label,
    isEnabled,
    isLoading = false,
    className = null,
    title,
    dataqa,
    outline,
    icon,
    classNames = {},
  } = props;
  const allowClick = !isLoading && isEnabled;

  return (
    <button
      className={cls({
        // TODO: make this uniform
        'btn btn-primary btn-block': !className,
        [className]: !!className,

        'btn--disabled': !allowClick,
        'btn-primary--outline': outline,
        [classNames.button]: classNames.button,
      })}
      title={title}
      onClick={allowClick ? onClick : () => {}}
      disabled={!allowClick}
      data-qa={dataqa}
    >
      {!!icon && icon}
      {!isLoading && <span className="m-0x">{label}</span>}
      {isLoading && (
        <ActivityIndicator
          className={cls('loader--button', classNames.ActivityIndicator)}
          marginBottom={false}
        />
      )}
    </button>
  );
};

export default Button;
