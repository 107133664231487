export const supplierStandards = [
  'A supplier must be in compliance with all applicable Federal and State licensure and regulatory requirements and cannot contract with an individual or entity to provide licensed services.',
  'A supplier must provide complete and accurate information on the DMEPOS supplier application. Any changes to this information must be reported to the National Supplier Clearinghouse within 30 days.',
  'An authorized individual (one whose signature is binding) must sign the application for billing privileges.',
  'A supplier must fill orders from its own inventory, or must contract with other companies for the purchase of items necessary to fill the order. A supplier may not contract with any entity that is currently excluded from the Medicare program, any State health care programs, or from any other Federal procurement or non-procurement programs.',
  'A supplier must advise beneficiaries that they may rent or purchase inexpensive or routinely purchased durable medical equipment, and of the purchase option for capped rental equipment.',
  'A supplier must notify beneficiaries of warranty coverage and honor all warranties under applicable State law, and repair or replace free of charge Medicare covered items that are under warranty.',
  'A supplier must maintain a physical facility on an appropriate site. This standard requires that the location is accessible to the public and staffed during posted hours of business. The location must be at least 200 square feet and contain space for storing records.',
  'A supplier must permit CMS, or its agents to conduct on-site inspections to ascertain the supplier’s compliance with these standards. The supplier location must be accessible to beneficiaries during reasonable business hours, and must maintain a visible sign and posted hours of operation.',
  'A supplier must maintain a primary business telephone listed under the name of the business in a local directory or a toll-free number available through directory assistance. The exclusive use of a beeper, answering machine, answering service, or cell phone during posted business hours is prohibited.',
  'A supplier must have comprehensive liability insurance in the amount of at least $300,000 that covers both the supplier’s place of business and all customers and employees of the supplier. If the supplier manufactures its own items, this insurance must also cover product liability and completed operations.',
  'A supplier must agree not to initiate telephone contact with beneficiaries, with a few exceptions allowed. This standard prohibits suppliers from contacting a Medicare beneficiary based on a physician’s oral order unless an exception applies.',
  'A supplier is responsible for delivery and must instruct beneficiaries on the use of Medicare covered items, and maintain proof of delivery.',
  'A supplier must answer questions and respond to complaints of beneficiaries, and maintain documentation of such contacts.',
  'A supplier must maintain and replace at no charge or repair directly, or through a service contract with another company, Medicare-covered items it has rented to beneficiaries.',
  'A supplier must accept returns of substandard (less than full quality for the particular item) or unsuitable items (inappropriate for the beneficiary at the time it was fitted and rented or sold) from beneficiaries.',
  'A supplier must disclose these supplier standards to each beneficiary to whom it supplies a Medicare-covered item.',
  'A supplier must disclose to the government any person having ownership, financial, or control interest in the supplier.',
  'A supplier must not convey or reassign a supplier number; i.e., the supplier may not sell or allow another entity to use its Medicare billing number.',
  'A supplier must have a complaint resolution protocol established to address beneficiary complaints that relate to these standards. A record of these complaints must be maintained at the physical facility.',
  'Complaint records must include: the name, address, telephone number, and health insurance claim number of the beneficiary, a summary of the complaint, and any actions taken to resolve it.',
  'A supplier must agree to furnish CMS any information required by the Medicare statute and implementing regulations.',
  'All suppliers must be accredited by a CMS-approved accreditation organization in order to receive and retain a supplier billing number. The accreditation must indicate the specific products and services, for which the supplier is accredited in order for the supplier to receive payment of those specific products and services (except for certain exempt pharmaceuticals). Implementation Date - October 1, 2009',
  'All suppliers must notify their accreditation organization when a new DMEPOS location is opened.',
  'All supplier locations, whether owned or subcontracted, must meet the DMEPOS quality standards and be separately accredited in order to bill Medicare.',
  'All suppliers must disclose upon enrollment all products and services, including the addition of new product lines for which they are seeking accreditation.',
  'Must meet the surety bond requirements specified in 42 C.F.R. 424.57(c). Implementation date- May 4, 2009',
  'A supplier must obtain oxygen from a state-licensed oxygen supplier.',
  'A supplier must maintain ordering and referring documentation consistent with provisions found in 42 C.F.R. 424.516(f).',
  'DMEPOS suppliers are prohibited from sharing a practice location with certain other Medicare providers and suppliers.',
  'DMEPOS suppliers must remain open to the public for a minimum of 30 hours per week with certain exceptions.',
];
