import React from 'react';
import { getString } from 'util/lang';
import styles from './InvalidConfigurationRedirect.module.scss';
import cls from 'classnames';
import { NavBar } from 'components/common/NavBar';
import { msBanner } from 'assets/images';
import Footer from 'components/COVID/common/footer';

const InvalidConfigurationRedirect = () => {
  return (
    <div className="landing-page-container">
      <NavBar displayNavLinks={false} isLandingPage />

      <div className="nlanding-page">
        <div className="container-lg">
          <div className="nlanding-page__container">
            <div className="nlanding-page__brand-image">
              <img
                src={msBanner}
                alt="Multi Service"
                className="multi-service-img"
              />
            </div>
            <div className="nlanding-page__form-container">
              <div className={cls('nlanding-page__brand', styles.pageMain)}>
                <h1
                  className={cls(
                    'nlanding-page__brand-title',
                    styles.pageBrandTitle,
                  )}
                >
                  <span
                    className={cls(
                      'nlanding-page__brand-title__placeholder',
                      styles.pageBrandTitlePlaceholder,
                    )}
                  >
                    {getString('invalidConfigTitle')}
                  </span>
                </h1>
                <p
                  className={cls(
                    'nlanding-page__brand-helper',
                    styles.pageHelperInfo,
                  )}
                >
                  {getString('invalidConfigSubText')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default InvalidConfigurationRedirect;
