import { useCallback, useRef, useState } from 'react';
import c from 'classnames';

import styles from './serviceTypeTab.module.scss';

import { serviceGroups, serviceGroupIcons } from './serviceGroup';
import { handleSpaceOrEnterKey } from 'util/handleSpaceOrEnterKey';

/**
 *
 * @param {{
 *  id: string,
 *  title: string
 *  serviceGroup: string
 *  active?: boolean
 *  onChangeServiceType: (id: string) => void
 * }} props
 * @returns
 */
const ServiceTypeTab = (props) => {
  const { id, title, serviceGroup, active, onChangeServiceType } = props;
  const typeOfService = serviceGroups[serviceGroup]?.type;
  const activeIcon = serviceGroupIcons[typeOfService]?.activeIcon;
  const inActiveIcon = serviceGroupIcons[typeOfService]?.inactiveIcon;

  const [isPointed, setIsPointed] = useState(false);

  const onHover = () => {
    setIsPointed(true);
  };

  const onHoverOver = () => {
    setIsPointed(false);
  };

  const onClick = useCallback(() => {
    onChangeServiceType(id);
  }, [id, onChangeServiceType]);

  const titleRef = useRef(null);
  const isServiceTabElipsisActive = isEllipsisActive(titleRef.current);

  return (
    <div
      className={c(styles.serviceTypeTab, active && styles.active)}
      onMouseOver={onHover}
      onMouseLeave={onHoverOver}
      onClick={onClick}
      onKeyDown={(e) => {
        handleSpaceOrEnterKey(e, onClick);
      }}
      tabIndex={0}
    >
      <div className={styles.serviceTypeTabIcon}>
        {active || isPointed ? activeIcon : inActiveIcon}
      </div>
      <div ref={titleRef} className={styles.serviceTypeTabTitle}>
        {title}
      </div>
      {isServiceTabElipsisActive && (
        <div className={styles.tooltip}>{title}</div>
      )}
    </div>
  );
};

export default ServiceTypeTab;

function isEllipsisActive(element) {
  if (element?.clientHeight < element?.scrollHeight) {
    const style = element?.currentStyle || window.getComputedStyle(element);

    return style.textOverflow === 'ellipsis';
  }
  return false;
}
