import React from 'react';
import ClinicMap from './ClinicMap';
import Modal from 'components/COVID/common/Modal';

const MapModal = (props) => {
  const { closeMapModal, ...clinicMapProps } = props;
  return (
    <Modal
      close={closeMapModal}
      containerClassName={`map-modal modal__map modal--lg`}
      headerContent={null}
    >
      <div className="modal__body modal__map__body">
        <ClinicMap {...clinicMapProps} isModal />
      </div>
    </Modal>
  );
};

export default MapModal;
