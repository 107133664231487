import React from 'react';

import * as routes from 'constants/routes/covidRoutes';
import { HelpCenterAccordion } from '../presentation/HelpCenterAccordion';

import { graidentLeftArrow } from 'assets/images';

export const HelpCenterContent = () => {
  const emailSignetic = 'support@signetic.com';

  return (
    <div className="help-center__wrapper">
      <div className="row">
        <div className="col-5-md mb-4x-xs mb-4x-sm">
          <div className="help-center__contents">
            <a href={routes.WELCOME}>
              <div className="btn">
                <span className="left-arrow-icon">
                  <img src={graidentLeftArrow} alt="graident_left_arrow" />
                </span>
                Back to Application Hub
              </div>
            </a>
            <div className="title">Help center</div>
            <div className="text">
              We have collected some FAQs that might answer most of your
              questions. Can't find the answer here? Contact your customer
              success representative.
            </div>
            <a href={`mailto:${emailSignetic}`}>
              <div className="link">{emailSignetic}</div>
            </a>
          </div>
        </div>
        <div className="col-7-md mb-4x-xs mb-4x-sm">
          <HelpCenterAccordion />
        </div>
      </div>
    </div>
  );
};
