import React from 'react';

import NavBar from '../../../../COVID/common/navbar';
import Footer from '../../../../COVID/common/footer';
import ContactInfo from '../presentation/ContactInfo';
import { changeTitle } from 'util/siteInfo';

const ContactUs = () => {
  changeTitle('Contact Us');
  return (
    <>
      <NavBar displayNavLinks={false} />
      <ContactInfo />
      <Footer />
    </>
  );
};
export default ContactUs;
