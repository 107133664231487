import React from 'react';

import { getString } from 'util/lang';

const EmailNotice = ({ email }) => {
  return (
    <p tabIndex="0" className="success-confirm-mail">
      {email
        ? getString('emailNotice', { email: <b>{email}</b> })
        : getString('emailNoticeNoEmail')}
    </p>
  );
};

export default EmailNotice;
