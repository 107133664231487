/**
 *
 * @param {*} clinics
 * @param {*} searchTerm
 * @returns filtered list of clincs based on the search term
 */
export const searchLocationBySearchTerm = (clinics, searchTerm) => {
  if (!searchTerm) return clinics;
  if (!clinics.length) return [];
  const keyword = searchTerm.toLowerCase();

  const filteredClinics = clinics.filter((clinic) => {
    if (
      clinic.msemr_addresscity.toLowerCase().includes(keyword) ||
      clinic.msemr_addressline1.toLowerCase().includes(keyword) ||
      clinic.msemr_addressstate.toLowerCase().includes(keyword) ||
      clinic.postalCode.toLowerCase().includes(keyword)
    ) {
      return true;
    }
    return false;
  });
  return filteredClinics;
};
