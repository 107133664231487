import { getString } from 'util/lang';
import { Text, View } from '@react-pdf/renderer';

import { useStyles } from '../ABNFormPdf.styles';

export const ABNFormAdditionalInfo = ({
  abnData,
  additionalInformation = '',
}) => {
  const styles = useStyles;
  return (
    <>
      <View>
        <Text style={[styles.label, styles.mb1, styles.fontWt700]}>
          {getString(`dme.documentation.abnForm.fields.additionalInfoLabel`)}
        </Text>
        <Text style={[styles.textArea, styles.table]}>
          {additionalInformation}
        </Text>
      </View>
      <View style={styles.notice} break>
        <View style={styles.row}>
          <Text>
            <Text style={styles.fontWt700}>
              {getString(`${abnData}.additionalInfoBold`)}
            </Text>
            <Text style={styles.fontWt400}>
              {getString(`${abnData}.additionalInfo`)}
            </Text>
            <Text style={styles.fontWt700}>
              {getString(`${abnData}.additionalInfoNumber`)}
            </Text>
          </Text>
        </View>
        <Text>{getString('dme.documentation.abnForm.signatureInfo')}</Text>
      </View>
    </>
  );
};
