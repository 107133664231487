import {
  proofOfDelivery,
  questionnaireKeys,
} from '../validation/AddDeliveryTicket';
import {
  BeneficiaryRelation,
  BeneficiaryRelationValue,
} from 'constants/productOrder';

export const initialQuestionnaireData = [
  {
    id: 1,
    checked: '',
  },
  {
    id: 2,
    checked: '',
    [questionnaireKeys.datePatientGotTheEquipment]: '',
    [questionnaireKeys.whoPaidForIt]: '',
  },
  {
    id: 3,
    checked: '',
    [questionnaireKeys.supplyRemainDays]: '',
  },
  {
    id: 4,
    checked: '',
  },
  {
    id: 5,
    checked: '',
  },
  {
    id: 6,
    checked: '',
    [questionnaireKeys.agencyName]: '',
    [questionnaireKeys.agencyPhoneNumber]: '',
  },
  {
    id: 7,
    checked: '',
    [questionnaireKeys.providerName]: '',
    [questionnaireKeys.providerPhoneNumber]: '',
    [questionnaireKeys.itemReceived]: '',
    [questionnaireKeys.dateReceived]: '',
    [questionnaireKeys.stillHasTheItem]: '',
    [questionnaireKeys.dateReturned]: '',
    [questionnaireKeys.returnReason]: '',
    [questionnaireKeys.sixtyDaysBreakNeeded]: '',
    [questionnaireKeys.agencyName]: '',
  },
];
export const initialProofOfDeliveryData = {
  [proofOfDelivery.checklist]: [],
  [proofOfDelivery.recipientName]: '',
  [proofOfDelivery.relationshipToBeneficiary]: BeneficiaryRelation.find(
    (data) => data.value === BeneficiaryRelationValue.SELF,
  )?.value,
  [proofOfDelivery.reasonBeneficiaryIsUnableToSign]: '',
  [proofOfDelivery.date]: '',
};
