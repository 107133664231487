import React, { useState, useEffect, useCallback } from 'react';

import CONFIG from 'config';
import { useDetailContext } from 'context';
import regexPattern from 'constants/regexPattern';
import { usePrevious } from 'hooks/usePrevious';
import * as toast from 'util/toast';
import { getString } from 'util/lang';
import { getFormattedFullName } from 'util/TextFormat';
import objectUtils from 'util/objectUtils';

import Button from 'components/common/Button';
import FormInput from 'components/common/FormInput';
import FormErrors from 'components/common/FormErrors';
import ActivityIndicator from 'components/common/ActivityIndicator';

import * as securityCheckService from 'services/securityVerification';
import ReCaptcha from 'components/COVID/common/ReCaptcha';
import AlertMessage from 'components/COVID/common/AlertMessage';
import { validateSecurityForm } from '../validation/SecurityCheckServiceCodeValidation';

/**
 * @typedef {{
 *  token: string
 *  details: {
 *    firstName: string
 *    lastName: string
 *    middleName: string
 *    emailAddress: string
 *    verificationCode: string
 *  }
 * }} SecurityVerifiedParams
 *
 * @typedef {{
 *  activityId: string
 *  headerMsg: string
 *  alertMsg: string
 *  onSecurityVerified: (params: SecurityVerifiedParams) => void
 *  loading?: boolean
 * }} Props
 *
 * @param {Props} props
 */
export const SecurityCheckServiceCode = (props) => {
  const {
    onSecurityVerified,
    activityId,
    headerMsg = getString('registrationVerifyTitle'),
    alertMsg = getString('securityCheckAlert'),
    loading = false,
  } = props;
  const {
    details: { appLanguageCode },
  } = useDetailContext();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isUserValid, setIsUserValid] = useState(true);
  const [captcha, setCaptcha] = useState(null);
  const [isCaptchaAttempted, setIsCaptchaAttempted] = useState(false);

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    confirmationCode: '',
  });

  const prevLanguageCode = usePrevious(appLanguageCode);

  useEffect(() => {
    if (!prevLanguageCode) {
      return;
    }

    if (prevLanguageCode !== appLanguageCode && !objectUtils.isEmpty(errors)) {
      validateFormValues(formValues);
    }
  }, [appLanguageCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormValueChange = useCallback((key, value) => {
    if (
      (key === 'firstName' || key === 'lastName' || key === 'middleName') &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    )
      return;
    const formattedValue = formatFormInput(key, value);
    setFormValues((formValues) => ({
      ...formValues,
      [key]: formattedValue,
    }));
    setErrors((errors) => clearError(key, errors));
    setIsUserValid(true);
  }, []);

  const validateFormValues = useCallback((formValues) => {
    const { errors, isValid } = validateSecurityForm(formValues);
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  }, []);

  const submitSecurityCheck = useCallback(async () => {
    if (CONFIG.captchaEnabled && !captcha) {
      setIsCaptchaAttempted(true);
      return;
    }
    try {
      setIsLoading(true);
      const isFormValid = validateFormValues(formValues);
      const isCaptchaValid = CONFIG.captchaEnabled ? !!captcha : true;
      if (!isFormValid || !isCaptchaValid) {
        return;
      }

      const securityResponse =
        await securityCheckService.verifySecurityQuestionsGroupRegistration(
          activityId,
          {
            ...formValues,
            id: activityId,
            captcha,
          },
        );

      if (!securityResponse?.data?.isDetailsValid) {
        setIsUserValid(false);
        CONFIG.captchaEnabled && window.grecaptcha.reset();
        setCaptcha(null);
        setIsCaptchaAttempted(false);
        return;
      }

      const patientFullName = getFormattedFullName({
        firstName: formValues.firstName,
        middleName: formValues.middleName,
        lastName: formValues.lastName,
      });

      onSecurityVerified({
        token: securityResponse.data.token,
        details: {
          ...formValues,
          name: patientFullName,
        },
        appointmentId: securityResponse?.data?.appointmentId,
      });
    } catch (error) {
      console.log(error);
      toast.error({
        title: '',
        message: getString('errorMessage'),
      });
      CONFIG.captchaEnabled && window.grecaptcha.reset();
    } finally {
      setIsLoading(false);
    }
  }, [activityId, captcha, formValues, onSecurityVerified, validateFormValues]);

  if (isLoading || loading) {
    return <ActivityIndicator className="loader--clinic" />;
  }

  return (
    <section className="form-patient-info section__margin reschedule__wrapper">
      <h2 tabIndex="0">{headerMsg}</h2>
      <AlertMessage
        isVisible
        type="info"
        className="mb-6x mt-4x"
        message={alertMsg}
      />
      <div className="patient-form-group">
        <div className="row">
          <div className="col-4-md">
            <FormInput
              label={getString('firstName')}
              id="firstName"
              onChange={handleFormValueChange}
              value={formValues.firstName}
              hasError={!!errors['firstName']}
              required={true}
              data-qa="firstname-security-check"
            />
          </div>
          <div className="col-4-md">
            <FormInput
              label={getString('middleName')}
              id="middleName"
              onChange={handleFormValueChange}
              value={formValues.middleName}
              hasError={!!errors['middleName']}
              required={false}
              data-qa="middlename-security-check"
            />
          </div>
          <div className="col-4-md">
            <FormInput
              label={getString('lastName')}
              id="lastName"
              onChange={handleFormValueChange}
              value={formValues.lastName}
              hasError={!!errors['lastName']}
              required={true}
              data-qa="lastname-security-check"
            />
          </div>
          <div className="col-6-md">
            <FormInput
              label={getString('email')}
              id={'email'}
              onChange={handleFormValueChange}
              value={formValues.email}
              hasError={!!errors['email']}
              required={true}
              data-qa="email-security-check"
            />
          </div>
          <div className="col-6-md">
            <FormInput
              label={getString('groupVerificationCode')}
              id={'confirmationCode'}
              onChange={handleFormValueChange}
              value={formValues.confirmationCode}
              hasError={!!errors['confirmationCode']}
              required={true}
              data-qa="group-confirmation-security-check"
            />
          </div>
        </div>
      </div>
      <FormErrors errors={errors} />
      <br />
      {CONFIG.captchaEnabled && (
        <ReCaptcha
          isLoading={false}
          onCaptchaChange={setCaptcha}
          isCaptchaValid={isCaptchaAttempted ? !!captcha : true}
        />
      )}
      <div className="mt-4x">
        <Button
          onClick={submitSecurityCheck}
          label={getString('submit')}
          isEnabled={!isLoading}
          isLoading={isLoading}
          dataqa="submit-security-check"
        />
      </div>
      <AlertMessage
        isVisible={!isUserValid}
        type="danger"
        className="mb-6x mt-6x"
        message={getString('securityCheckValidationAlert')}
        dataqa="alert-security-check"
      />
    </section>
  );
};

const clearError = (key, { [key]: _, ...restErrors }) => restErrors;

const formatFormInput = (key, value) => {
  switch (key) {
    case 'firstName':
    case 'middleName':
    case 'lastName':
      return value.trimStart().replace(/  +/g, ' ');
    case 'confirmationCode':
      return value.trim().substring(0, 6);
    default:
      return value;
  }
};
