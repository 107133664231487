import { useCallback } from 'react';
import {
  useDetailContext,
  useGroupAppointmentContext,
  useSelectionsContext,
} from 'context';
import * as appointmentService from 'services/appointment';

export const useGroupAppointmentReschedule = () => {
  const { setDetails } = useDetailContext();

  const { isCounsellingSelected } = useSelectionsContext();

  const { groupAppointmentId, isIndividualReschedule } =
    useGroupAppointmentContext();

  const rescheduleAppointment = useCallback(async () => {
    const {
      appointments,
      security: { token },
      captchaResponseKey,
    } = await new Promise((resolve) => {
      setDetails((details) => {
        resolve(details);
        return {};
      });
    });

    const rescheduleAppointmentList = appointments
      .map(({ firstAppointment, secondAppointment }) => [
        firstAppointment,
        secondAppointment,
      ])
      .flat()
      .filter((appointment) => appointment?.canBeRescheduled); // NOTE: this might be problematic

    return appointmentService.groupRescheduleAppointments(
      rescheduleAppointmentList,
      captchaResponseKey,
      isCounsellingSelected,
      token,
      groupAppointmentId,
      isIndividualReschedule,
    );
  }, [setDetails, isCounsellingSelected]);

  return { rescheduleAppointment };
};
