import {
  DeliveryMethodValue,
  getDeliveryMethodLabel,
} from '../../../adapters/orders.adapter';
import styles from './DeliveryInfo.module.scss';
import { getString } from 'util/lang';

const LABEL_PREFIX = 'dme.deliveryTicket';
export const deliveryMethodAttributes = {
  [DeliveryMethodValue.PICK_UP]: ['deliveryMethod', 'deliveryDate'],
  [DeliveryMethodValue.DIRECT_DELIVERY]: [
    'deliveryMethod',
    'deliveryAddress',
    'deliveryDate',
  ],
  [DeliveryMethodValue.VIA_SHIPPING]: [
    'deliveryMethod',
    'trackingNumber',
    'shippingCompany',
    'shippingAddress',
    'deliveryDate',
  ],
  [DeliveryMethodValue.TO_NURSING_FACILITY]: [
    'deliveryMethod',
    'deliveryAddress',
    'deliveryDate',
  ],
};

const DeliveryInfo = ({ data, className }) => {
  // Render Attribute and the corresponding values
  const renderAttribute = (labelKey, value) => {
    if (!value) return null;
    return (
      <p>
        <div className={styles.key}>
          {getString(`${LABEL_PREFIX}.deliveryInfo.${labelKey}`)}:{' '}
        </div>
        <span className={styles.value}>
          {labelKey === 'deliveryMethod'
            ? getDeliveryMethodLabel(value)
            : value}
        </span>
      </p>
    );
  };
  // Extract Renderable attributes based on deliveryMethod
  const attributes = deliveryMethodAttributes[data.deliveryMethod] || [];

  return (
    <div className={`${styles.pharmaInfo} ${className}`}>
      <h3>{getString(`${LABEL_PREFIX}.deliveryInfo.title`)}</h3>
      {attributes.map((attr) => renderAttribute(attr, data[attr]))}
    </div>
  );
};

export default DeliveryInfo;
