import { FiChevronLeft, FiChevronRight } from 'vyaguta-icons/fi';
import { getString } from 'util/lang';
import Button from 'components/common/Button';
import classNames from 'classnames';
import { componentKeys } from '../../../../container/DmeDocument';

export const ABNFormPagination = ({
  goBack,
  isLastDocument,
  nextAbnLabel,
  previousLabel,
  handleSubmission,
}) => {
  return (
    <div className={classNames('pagination')}>
      {previousLabel && (
        <div className="text-left flex-grow">
          <p className="d-flex align-items-center">
            <FiChevronLeft size={12} color="#757575" />
            <span
              className="color-grey-dark cursor-pointer ml-1x"
              onClick={goBack}
            >
              {getString('dme.documentation.pagination.previous')}
            </span>
          </p>
          {previousLabel && (
            <Button
              className="btn btn--text link-pagination"
              label={
                previousLabel === componentKeys.termAndCondition
                  ? getString('dme.documentation.pagination.termsAndConditions')
                  : previousLabel === componentKeys.esignature
                    ? getString(
                        'dme.documentation.pagination.electronicSignature',
                      )
                    : previousLabel
              }
              onClick={goBack}
              isEnabled
            />
          )}
        </div>
      )}

      <div className={classNames('text-right', !previousLabel && 'flex-grow')}>
        {isLastDocument ? (
          <Button
            label={'Submit'}
            className="btn btn-primary btn--fixedWidth"
            onClick={handleSubmission}
            isEnabled
          />
        ) : (
          <div>
            <p className="d-flex justify-content-end align-items-center" onClick={handleSubmission}>
              <span className="color-grey-dark cursor-pointer mr-1x">
                {getString('dme.documentation.pagination.next')}
              </span>
              <FiChevronRight size={12} color="#757575" />
            </p>
            <Button
              className="btn btn--text link-pagination text-right"
              label={nextAbnLabel}
              onClick={handleSubmission}
              isEnabled
            />
          </div>
        )}
      </div>
    </div>
  );
};
