import { useCallback, useMemo } from 'react';
import { useDetailContext, useGroupAppointmentContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { makeGroupRescheduleNavLinks } from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import ConfirmationAndSubmission from '../../Confirmation/container/Confirmation';
import { useGroupAppointmentReschedule } from '../../../../../context/useGroupAppointmentReschedule';

export const GroupRescheduleConfirmation = () => {
  const nav = useNavigationHelper();
  const { appointmentId, organizationId } = useAppParams();

  const {
    details: { pageComplete },
  } = useDetailContext();

  const { rescheduleAppointment } = useGroupAppointmentReschedule();
  const { setAllPatientInfo, currentPatientInfo } =
    useGroupAppointmentContext();

  const confirmAndSubmit = useCallback(async () => {
    const rescheduleResponse = await rescheduleAppointment();
    const { patientInfo: patientInfoResponse } = rescheduleResponse.data;

    let updatedPatientInfo = [{ ...currentPatientInfo }]; //initial position reserved for currentPatient
    patientInfoResponse.forEach((eachResponse) => {
      if (
        eachResponse.firstname.toLowerCase() ===
          currentPatientInfo.firstName.toLowerCase() &&
        eachResponse.lastname.toLowerCase() ===
          currentPatientInfo.lastName.toLowerCase() &&
        (eachResponse.middlename || '').toLowerCase() ===
          (currentPatientInfo.middleName || '').toLowerCase()
      ) {
        updatedPatientInfo[0] = {
          ...updatedPatientInfo[0],
          confirmationCode: eachResponse.confirmationCode,
        };
        return;
      }

      updatedPatientInfo.push({
        firstName: eachResponse.firstname,
        middleName: eachResponse?.middlename || '',
        lastName: eachResponse.lastname,
        confirmationCode: eachResponse.confirmationCode,
      });
    });

    setAllPatientInfo(updatedPatientInfo);
  }, [rescheduleAppointment, appointmentId]);

  const toNextPage = useCallback(() => {
    nav.toGroup.reschedule.success(appointmentId);
  }, [nav, appointmentId]);

  const goBack = useCallback(() => nav.goBack(), [nav]);

  const navLinks = useMemo(
    () =>
      makeGroupRescheduleNavLinks(pageComplete, appointmentId, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ConfirmationAndSubmission
        confirmAndSubmit={confirmAndSubmit}
        toNextPage={toNextPage}
        goBack={goBack}
        disableEditService={true}
      />
    </PageWrapper>
  );
};
