import { ConfirmModal } from '../../../../common/ConfirmModal';
import React from 'react';
import styles from './AddPatientsConfirmationModal.module.scss';

export const AddPatientsConfirmationModal = ({
  onCancel,
  onConfirm,
  headerContentText,
  bodyContentText,
  cancelText,
  confirmText,
  show = false,
}) => {
  return (
    <ConfirmModal
      show={show}
      onCancel={onCancel}
      onConfirm={onConfirm}
      headerContent={
        <div className={styles.ModalHeaderContent}>{headerContentText}</div>
      }
      bodyContent={<div className="mb-10x">{bodyContentText}</div>}
      cancelText={cancelText}
      confirmText={confirmText}
    />
  );
};
