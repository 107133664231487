import { getString } from 'util/lang';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getDisplayDate } from 'util/DateAndTime';

const DMESignaturePdfView = ({
  title,
  style,
  signature,
  signedDate,
  showDate = false,
}) => {
  return (
    <View
      style={{
        ...style,
        ...styles.dmeSignWrap,
        border: showDate ? '1px solid #E1DFDD' : 'none',
        paddingTop: showDate ? 12 : 0,
        paddingRight: showDate ? 10 : 0,
        paddingLeft: showDate ? 10 : 0,
      }}
    >
      <Text style={{ ...styles.title, fontSize: showDate ? 9 : 11 }}>
        {title}
      </Text>
      <View style={styles.dmeSign}>
        <View style={{ ...styles.fieldWrap, ...style }}>
          <Text style={styles.fieldText}>{signature}</Text>
        </View>
        {!!showDate && (
          <View style={styles.dmeDateWrap}>
            <Text style={styles.dmeDate}>
              {getString(`dme.deliveryTicket.proofOfAcknowledgement.date`)}:
            </Text>
            <Text style={styles.dmeDateStr}>{getDisplayDate(signedDate)}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dmeSignWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 9,
    fontWeight: 400,
    color: '#605E5C',
    marginTop: 8,
  },
  dmeSign: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 0px',
  },
  dmeSignCanvas: {
    backgroundColor: '#F3F3F4',
    width: 250,
    height: 80,
  },
  dmeDateWrap: {
    width: 180,
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '0.5px solid #E1DFDD',
  },
  dmeDate: {
    fontSize: 9,
    fontWeight: 800,
    color: '#323130',
    marginLeft: 10,
    marginTop: 4,
  },
  dmeDateStr: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    marginLeft: 10,
    marginTop: 5,
  },
  fieldWrap: {
    paddingHorizontal: 3,
    borderBottom: '1px solid #323130',
    minWidth: 100,
    maxWidth: 200,
    minHeight: 15,
  },
  fieldText: {
    fontSize: 9,
    fontWeight: 400,
    color: '#323130',
    paddingTop: 3,
  },
});

export default DMESignaturePdfView;
