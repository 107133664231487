import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { SelectLocation } from '../../SelectLocation/SelectLocation';
import { changeTitle } from 'util/siteInfo';

export const RescheduleLocation = () => {
  changeTitle('Location');
  const { appointmentId } = useParams();
  const nav = useNavigationHelper();

  const toNextPage = useCallback(() => {
    nav.toFollowup.reschedule.schedule(appointmentId);
  }, [nav, appointmentId]);

  return (
    <PageWrapper
      container="full"
      classNames={{ mainWrapper: 'main-wrapper-sm' }}
    >
      <SelectLocation
        disableEdit
        toNextPage={toNextPage}
        preserveAppointmentState
      />
    </PageWrapper>
  );
};
