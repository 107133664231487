import * as http from '../util/http';
import config from '../config';

/**
 * Validate for insurance eligibily
 *
 */
export async function validateInsuranceEligibility(formValues) {
  try {
    const {
      data: { data },
    } = await http.post(config.entity.eligibility, formValues);

    return data;
  } catch (err) {
    throw err;
  }
}

export async function validateInsuranceEligibilityForMultiple(requestPayloads) {
  try {
    const requestCache = {};

    const responses = await Promise.all(
      requestPayloads.map(async (payload) => {
        if (!payload) {
          return null;
        }

        const typeOfServiceCode = payload.typeOfServiceCode;
        // ensure only 1 request is sent per service code
        if (!requestCache[typeOfServiceCode]) {
          requestCache[typeOfServiceCode] = http.post(
            config.entity.eligibility,
            payload,
          );
        }
        const response = await requestCache[typeOfServiceCode];
        return response.data.data;
      }),
    );    
    
    // ECBYPASS: Temporary change to allow the users to bypass EC 
    // Remove below if condition to remove bypass
    if (config.allowEligibilityBypass) {
      return responses.map((response) => ({
        ...response,
        editInfo: []
      }))
    }

    return responses;
  } catch (err) {
    throw err;
  }
}
