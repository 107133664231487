import React from 'react';

import { getString } from 'util/lang';
import { joinValues } from 'util/string';
import styles from './ItemTable.module.scss';
import LineBreak from '../../common/LineBreak/LineBreak';

const LABEL_PREFIX = 'dme.deliveryTicket';

export const deliveryTableHeadList = [
  {
    key: 'hcpcs',
    val: getString(`${LABEL_PREFIX}.itemTableHead.hcpcs`),
  },
  {
    key: 'lotOrSerialNo',
    val: getString(`${LABEL_PREFIX}.itemTableHead.lotOrSerialNo`),
  },
  {
    key: 'purchaseType',
    val: getString(`${LABEL_PREFIX}.itemTableHead.purchaseType`),
  },
  {
    key: 'warranty',
    val: getString(`${LABEL_PREFIX}.itemTableHead.warranty`),
  },
  {
    key: 'qty',
    val: getString(`${LABEL_PREFIX}.itemTableHead.quantity`),
  },
  {
    key: 'unitPrice',
    val: getString(`${LABEL_PREFIX}.itemTableHead.billedPrice`),
  },
];

export const tableHeadList = deliveryTableHeadList;

/**
 * Item Table Component - Delivery Ticket - DME
 *
 * @param {array} tableData
 * @param {func} removeRow
 */
const ItemTable = ({
  tableData,
  productOrder,
  isEdit,
  setDeliveryTicketHandler,
}) => {
  return (
    <div className={`${styles.itemTableWrap} d-flex mt-6x`}>
      <table className={styles.itemTable}>
        <thead className={styles.tableHeader}>
          <tr>
            {deliveryTableHeadList.map((tHead, index) => (
              <th className={styles.itemTableHead} key={tHead.key + index}>
                {tHead.val}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((rowData, index) => (
            <React.Fragment key={index}>
              <tr className={styles.itemTableData}>
                <td className={styles.itemTableData}>
                  {rowData.hcpcsCode
                    ? joinValues(
                        [rowData.hcpcsCode, rowData.hcpcsDescription],
                        ' - ',
                      )
                    : productOrder.productName}
                </td>
                <td className={styles.itemTableData}>{rowData.serialNumber}</td>
                <td className={styles.itemTableData}>{rowData.purchaseType}</td>
                <td className={styles.itemTableData}>
                  <div className="d-flex ">
                    <input
                      id={'warrantyInMonths'}
                      className="border-none border-bottom col-2 px-1x"
                      value={rowData.warrantyInMonths}
                      disabled={!isEdit}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (parseInt(value) > 11) return;
                        setDeliveryTicketHandler(
                          tableData.map((docDetail, docKey) => {
                            if (docKey === index) {
                              return {
                                ...docDetail,
                                warrantyInMonths: value,
                              };
                            }
                            return docDetail;
                          }),
                          'orderItemData',
                        );
                      }}
                      type="number"
                      solo
                      fullWidth
                      disableInput={false}
                    />{' '}
                    <span>Months</span>
                  </div>
                  <br></br>
                  <div className="d-flex ">
                    <input
                      className="col-2 border-none border-bottom px-1x"
                      id={'warrantyInYears'}
                      value={rowData.warrantyInYears}
                      disabled={!isEdit}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length > 2) return;
                        setDeliveryTicketHandler(
                          tableData.map((docDetail, docKey) => {
                            if (docKey === index) {
                              return {
                                ...docDetail,
                                warrantyInYears: value,
                              };
                            }
                            return docDetail;
                          }),
                          'orderItemData',
                        );
                      }}
                      type="number"
                      solo
                      fullWidth
                      disableInput={false}
                    />{' '}
                    <span>Years</span>
                  </div>
                </td>
                <td className={styles.itemTableData}>{rowData.quantity}</td>
                <td className={styles.itemTableData}>
                  $ {rowData.billedPrice}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <LineBreak />
    </div>
  );
};

export default ItemTable;
