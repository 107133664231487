import React from 'react';
import { getString } from 'util/lang';

/**
 * @typedef {import('types').Clinic} Clinic
 *
 * @param {{
 *  selectedClinic: Clinic
 * }} props
 * @returns
 */
const SelectedClinic = (props) => {
  const { selectedClinic } = props;
  if (!selectedClinic) return null;

  const { name, address, city } = selectedClinic;

  return (
    <div className="section__container">
      <div
        className="selected-date"
        aria-label={`${getString('selectedClinic')} ${name}`}
      >
        <h3 className="mb-0x-md">{getString('selectedClinic')}</h3>
        <div className={`border border-radius p-4x text-left w-100-sm`}>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="clinic__name color-primary-base text-demibold mb-1x">
                {name}
              </div>
              <div className="clinic__location small text-medium color-grey-text-light">
                {address} {city}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedClinic;
