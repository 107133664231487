import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Unsubscribe.module.scss';
import cls from 'classnames';

import { updatePatientNotificationPreferences } from 'services/contact';
import * as toast from 'util/toast';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { ActivityIndicator } from 'components/common/ActivityIndicator';
import { getString } from 'util/lang';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { InfoWithContact } from 'components/common/InfoWithContact';
import { FiInfo } from 'vyaguta-icons/fi';
import { useAppSettings } from 'context';

export const UnsubscribePage = ({ resubscribe = false }) => {
  const nav = useNavigationHelper();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const { contactId } = useParams();

  const [hash, type] = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const hash = searchParams.get('hash');
    const type = searchParams.get('type');

    return [hash, type];
  }, []);

  useEffect(() => {
    if (!contactId || !hash || !['email', 'sms'].includes(type)) {
      setIsValid(false);
      setIsLoading(false);
      return;
    }

    const doUpdatePreferences = async () => {
      setIsLoading(true);
      try {
        await updatePatientNotificationPreferences(
          contactId,
          hash,
          type === 'email' ? { email: !!resubscribe } : { sms: !!resubscribe },
        );
      } catch (e) {
        setIsValid(false);
        toast.error({
          title: '',
          message: e.message,
        });
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    doUpdatePreferences();
  }, [contactId, hash, type, resubscribe]);

  const toResubscribe = useCallback(() => {
    nav.toResubscribe(contactId, hash, type, { replace: true });
  }, [nav, contactId, hash, type]);

  return (
    <PageWrapper bgGrey>
      {!isLoading && !isValid ? (
        <Failure />
      ) : (
        <div className={styles.container}>
          {isLoading ? (
            <Loading />
          ) : resubscribe ? (
            <SubscribedAgain type={type} />
          ) : (
            <Unsubscribed type={type} onSubscribeAgain={toResubscribe} />
          )}
        </div>
      )}
    </PageWrapper>
  );
};

export const ResubscribePage = () => <UnsubscribePage resubscribe />;

const Loading = () => {
  return (
    <div className={styles.emptyLoading}>
      <ActivityIndicator />
    </div>
  );
};

const Unsubscribed = ({ type, onSubscribeAgain }) => {
  return (
    <>
      <div className={styles.top}>
        <div className={styles.iconCircle}>
          <EmailOffOutline />
        </div>

        <div className={styles.textMain}>{getString('unsubscribeSuccess')}</div>

        <div className={styles.textSub}>
          {type === 'email'
            ? getString('unsubscribeEmailText')
            : getString('unsubscribeSMSText')}
        </div>
      </div>
      <div className={styles.line} />

      <div className={styles.textReSubscribe}>
        {getString('unsubscribeAccident')}{' '}
        <button className={styles.link} onClick={onSubscribeAgain} tabIndex="0">
          {getString('unsubscribeSubscribeAgain')}
        </button>
      </div>
    </>
  );
};

const SubscribedAgain = ({ type }) => {
  return (
    <>
      <div className={styles.top}>
        <div className={styles.iconCircle}>
          <EmailCheckOutline />
        </div>

        <div className={styles.textMain}>
          {getString('unsubscribeWelcomeBack')}
        </div>

        <div className={styles.textSub}>
          {type === 'email'
            ? getString('unsubscribeWelcomeBackEmail')
            : getString('unsubscribeWelcomeBackSMS')}
        </div>
      </div>
    </>
  );
};

const Failure = () => {
  const { organizationContactPhone, organizationContactEmail } =
    useAppSettings();
  const organizationMessages = [
    { title: 'Phone number', message: organizationContactPhone },
    { title: 'Email address', message: organizationContactEmail },
  ];

  return (
    <InfoWithContact
      icon={<FiInfo size={44} color="#0259A8" className="mb-4x" />}
      contactInfoMessages={organizationMessages}
      contents={getString('unsubscribeFailure')}
    />
  );
};

const EmailOffOutline = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.4375 40.3125L4.5 3.1875L2.0625 5.625L4.875 8.4375C4.3125 9.1875 3.75 10.125 3.75 11.25V33.75C3.75 35.8125 5.4375 37.5 7.5 37.5H33.9375L39 42.5625L41.4375 40.3125ZM7.5 33.75V15L18 21.5625L30.1875 33.75H7.5ZM17.25 11.25L13.5 7.5H37.5C39.5625 7.5 41.25 9.1875 41.25 11.25V33.75C41.25 34.125 41.25 34.6875 41.0625 35.0625L37.5 31.5V15L27.375 21.375L25.125 19.125L37.5 11.25H17.25Z"
      fill="#0259A8"
    />
  </svg>
);

const EmailCheckOutline = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.375 35.625C24.375 34.9875 24.45 34.3687 24.5438 33.75H7.5V15L22.5 24.375L37.5 15V24.5438C38.85 24.7688 40.1063 25.2375 41.25 25.8938V11.25C41.25 9.1875 39.5625 7.5 37.5 7.5H7.5C5.4375 7.5 3.75 9.1875 3.75 11.25V33.75C3.75 35.8125 5.4375 37.5 7.5 37.5H24.5438C24.45 36.8813 24.375 36.2625 24.375 35.625ZM37.5 11.25L22.5 20.625L7.5 11.25H37.5ZM33.2812 41.55L28.125 35.925L30.3 33.75L33.2812 36.7313L40.0125 30L42.1875 32.6437L33.2812 41.55Z"
      fill="#0259A8"
    />
  </svg>
);
