import LocalizedStrings from 'react-localization';

import english from 'lang/en';
import spanish from 'lang/es';
import chineseSimplified from 'lang/zh-CN';

import APP_LANGUAGES from '../constants/appLanguages';

/**
 * Please note that the value of the key must match the name of files stored in /src/lang/
 * and value from "smvs_code" that comes from dynamics side,
 * which is fetched from function {@link fetchLanguages}
 */

// export is required because webpack doesnt initialize non-exported const
// when parsing js file
export const strings = new LocalizedStrings({
  // first item in the object is the default language
  // for us it is english
  [APP_LANGUAGES.english]: english,
  [APP_LANGUAGES.spanish]: spanish,
  [APP_LANGUAGES.chineseSimplified]: chineseSimplified,
});

export const getString = (key, ...args) =>
  args.length
    ? strings.formatString(strings.getString(key), ...args)
    : strings.getString(key);

// this function is used to update the language for the static strings used in application
export const updateLanguage = (language) => strings.setLanguage(language);

export const getLanguageIdByLanguageCode = (languageCode, languages) => {
  const selectedLanguage = languages.find(
    (language) => language.code === languageCode,
  );

  return selectedLanguage?.languageCodeId;
};
