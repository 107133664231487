import React from 'react';
import cls from 'classnames';

/**
 *
 * @param {{
 *  messages: { title: string, message: string }[]
 *  className?: string
 * }} props
 * @returns
 */
const MessageListBox = (props) => {
  const { messages, className } = props;

  return (
    <div className={cls('alert notice-content', className)}>
      {messages?.map((message, idx) => {
        return (
          <div key={idx} className="d-flex alert-message-list">
            <div className="alert__message">
              <span>
                <b>{message.title} :</b> {message.message}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageListBox;
