import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { iconRadioCheck, iconRadioUnCheck } from 'assets/images';

const YesNoRadioPdfView = ({ checked = false, style }) => {
  return (
    <View style={{ ...styles.container, ...style }}>
      <View style={{ ...styles.radioWrap, marginRight: 12 }}>
        {checked ? (
          <Image
            src={iconRadioCheck}
            alt="checkbox-checked"
            style={styles.img}
          />
        ) : (
          <Image
            src={iconRadioUnCheck}
            alt="checkbox-unchecked"
            style={styles.img}
          />
        )}
        <Text style={styles.radioText}>Yes</Text>
      </View>
      <View style={styles.radioWrap}>
        {!checked ? (
          <Image
            src={iconRadioCheck}
            alt="checkbox-checked"
            style={styles.img}
          />
        ) : (
          <Image
            src={iconRadioUnCheck}
            alt="checkbox-unchecked"
            style={styles.img}
          />
        )}
        <Text style={styles.radioText}>No</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  radioWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  img: {
    height: 14,
    width: 14,
  },
  radioText: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    marginLeft: 5,
  },
});

export default YesNoRadioPdfView;
