export const CURRENT_PAGE_STATUS = 'PAGE_STATUS';
export const CLINICS = 'CLINICS';
export const CLINIC_DAYS = 'CLINIC_DAYS';
export const APPOINTMENTS = 'APPOINTMENTS';
export const SELECTED_CLINIC_WITH_VACCINES = 'SELECTED_CLINIC_WITH_VACCINE';
export const MAP_STATUS = 'MAP_STATUS';
export const IS_ELIGIBLE_AGE = 'IS_ELIGIBLE_AGE';
export const DOSE_VIEW = 'DOSE_VIEW';

export const ServiceGroupMap = {
  covidVaccine: 'COVID19_VACCINE',
  pointOfCareTesting: 'POINT_OF_CARE_TESTING',
  commonDiseaseVaccine: 'COMMON_DISEASE_VACCINE',
  travelVaccine: 'TRAVEL_VACCINE',
  counselling: 'COUNSELLING',
};
