import React from 'react';
import { getString } from 'util/lang';
import { FiCheck } from 'vyaguta-icons/fi';

/**
 * Input component
 * @param {string} type: input type
 * @param {string} id: input id
 * @param {string} label: label for input
 * @param {function} onChange: callback on input change
 * @param {string} value: value of input field
 * @param {bool} hasError: Validity of input
 * @param {bool} required: Show * to indicate as 'required'
 */
const TextArea = (props) => {
  const {
    id,
    inputmode,
    label,
    onChange,
    value,
    hasError = false,
    required = false,
    disablePaste = false,
    isVisible = true,
    disableInput,
    solo = false,
    textareaClassName = false,
    onBlur = () => {},
    isLabelSuperscripted = false,
    rows = 10,
    cols = 15,
    ...otherProps
  } = props;
  const labelClassName =
    'label-placeholder' +
    (required ? ' label-placeholder--required' : '') +
    (value || isLabelSuperscripted ? ' label-placeholder--input-filled' : '');

  const displayStyle = isVisible
    ? { display: 'block', width: '100%' }
    : { display: 'none' };

  return (
    <div
      className={`form-group mb-0x${hasError ? ' has-error' : ''}`}
      style={displayStyle}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <textarea
          rows={rows}
          cols={cols}
          {...{ id, inputMode: inputmode }}
          disabled={disableInput}
          className={`form-control ${solo ? 'solo' : ''} ${textareaClassName ? textareaClassName : ''}`}
          style={{ resize: 'vertical', minHeight: '50px' }}
          onChange={(e) => onChange(id, e.target.value, e)}
          onBlur={(e) => onBlur(id, e.target.value)}
          onPaste={
            disablePaste
              ? (e) => {
                  e.preventDefault();
                  return false;
                }
              : null
          }
          value={value ? value : ''}
          {...otherProps}
        />
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      </form>
    </div>
  );
};

export default TextArea;
