import { getString } from "util/lang"

export const ABNFormFooter = () => {
    return (
        <>
            <p className="title mb-4x">
                {getString('dme.documentation.abnForm.signatureLegalNote')}
            </p>
            <p className="desc--sm mb-0x">
                {getString('dme.documentation.abnForm.legalNote')}
            </p>
        </>
    )
}