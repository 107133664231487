import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiMapPin } from 'vyaguta-icons/fi';
import cls from 'classnames';

import styles from './SelectLocation.module.scss';

import {
  useAppSettings,
  useDetailContext,
  useLocationContext,
  useSelectionsContext,
} from 'context';
import { useResetAppState } from 'context/useResetAppState';
import { MAP_VISIBLITY } from 'constants/mapSettings';
import { CovidPageNames } from 'constants/pages';
import { handleError } from 'util/errorHandler';
import { getString } from 'util/lang';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import * as clinicService from 'services/clinic';
import Button from 'components/common/Button';
import { NoAppointmentInformation } from 'components/COVID/common/NoAppointmentInformation';
import SelectedServicesListBox from 'components/COVID/common/SelectedServicesListBox';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import RefreshDataButton from 'components/COVID/common/RefreshDataButton';
import { checkWaitlistStatus } from '../SelectService/helpers/waitlistHelper';
import { searchLocationBySearchTerm } from './helpers/locationHelpers';
import MobileClinicSearchBar from './presentation/MobileClinicSearchBar';
import DesktopClinicSearchBar from './presentation/DesktopClinicSearchBar';
import SelectLocationSkeletonLoader from './presentation/SelectLocationSkeletonLoader';
import ClinicCard from './presentation/ClinicCard';
import WaitlistNotice from '../Waitlist/WaitlistNotice';
import ClinicMap from './presentation/ClinicMap';
import MapModal from './presentation/MapModal';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import WAIT_LIST_STATUS from 'constants/waitlistStatus';

export const SelectLocation = ({
  disableEdit = false,
  toNextPage,
  preserveAppointmentState = false,
}) => {
  const { organizationId } = useParams();
  const { setPageComplete, setIsServiceAndClinicSelected } = useDetailContext();
  const {
    organizationContactPhone,
    organizationContactEmail,
    mapVisibility,
    globalServiceStatus: globalWaitlistStatus,
  } = useAppSettings();
  const nav = useNavigationHelper();
  const resetAppState = useResetAppState();
  const {
    selectedLocationId,
    setSelectedLocationId,
    locations,
    setLocations,
    getLocationById,
  } = useLocationContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [showMobileMap, setShowMobileMap] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [locationId, setLocationId] = useState(selectedLocationId);

  const selectedLocation = useMemo(
    () => getLocationById(locationId),
    [getLocationById, locationId],
  );

  const {
    selectedServices,
    isCounsellingSelected,
    services: allServices,
  } = useSelectionsContext();

  const serviceWaitlistStatus = () => {
    if (isCounsellingSelected)
      return selectedServices[0].service.waitlistStatus;
    else
      return selectedServices.some((service) => service.service.waitlistStatus);
  };

  const isWaitlistWhenNoAppointment = checkWaitlistStatus(
    globalWaitlistStatus,
    serviceWaitlistStatus(),
  );
  const isWaitlistOnly =
    globalWaitlistStatus === WAIT_LIST_STATUS.ENABLED &&
    serviceWaitlistStatus();

  const selectedSubServicesIds = useMemo(() => {
    if (selectedServices && selectedServices.length <= 0) {
      return [];
    }

    if (isCounsellingSelected) {
      return [selectedServices[0].subServiceId];
    }

    return selectedServices.map((selection) => selection.subServiceId);
  }, [selectedServices, isCounsellingSelected]);

  const getClinicList = useCallback(
    async (searchTerm, subServiceIds) => {
      try {
        setIsLoading(true);

        const allClinics = await clinicService.getAllLocationsFromSubScheduling(
          subServiceIds,
          organizationId,
          false,
          serviceWaitlistStatus() &&
            globalWaitlistStatus != WAIT_LIST_STATUS.DISABLED,
        );

        const filteredClinics = searchLocationBySearchTerm(
          allClinics,
          searchTerm,
        );
        return filteredClinics;
      } catch (error) {
        handleError(error);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId],
  );

  const filterFilteredLocations = useCallback(async () => {
    const filteredLocations = await getClinicList(
      searchTerm,
      selectedSubServicesIds,
    );

    setLocations(filteredLocations); // TODO - multiservice-state - refactor in rescheduling/waitlist

    const newSelectedLocation =
      filteredLocations.find((location) => location.id === locationId) ||
      filteredLocations[0];

    // select default clinic if there is only one clinic
    if (newSelectedLocation) {
      setLocationId(newSelectedLocation.id); // TODO - multiservice-state
    }
  }, [
    searchTerm,
    selectedSubServicesIds,
    setLocations,
    getClinicList,
    locationId,
    setLocationId,
  ]);

  useEffect(() => {
    filterFilteredLocations();
  }, []); // run once on load

  const onSearchTermChange = setSearchTerm;

  const onLocationChange = useCallback(
    (clinic) => {
      setLocationId(clinic?.id); // TODO - multiservice-state
    },
    [setLocationId],
  );

  const onSearch = filterFilteredLocations;

  const onRefresh = filterFilteredLocations;

  const onSubmit = useCallback(() => {
    if (selectedLocationId !== locationId) {
      // if selected location is changed, reset future state
      resetAppState((state) => ({
        serviceTypes: state.serviceTypes,
        services: state.services,
        subServices: state.subServices,
        patientInfo: state.patientInfo,
        selectedServices: state.selectedServices,
        location: state.location,
        security: state.security,
        selfDeclaration: state.selfDeclaration,
        isGroupAppointment: state.isGroupAppointment,
        ...(preserveAppointmentState
          ? { appointments: state.appointments }
          : {}),
      }));
    }
    setSelectedLocationId(locationId);
    setIsServiceAndClinicSelected(true);
    setPageComplete({
      [CovidPageNames.BeforeApproval]: true,
    });
    toNextPage();
  }, [
    resetAppState,
    setSelectedLocationId,
    setIsServiceAndClinicSelected,
    locationId,
    selectedLocationId,
    setPageComplete,
    toNextPage,
    preserveAppointmentState,
  ]);

  const toggleMobileMap = useCallback(() => {
    setShowMobileMap((showMobileMap) => !showMobileMap);
  }, []);

  const toggleMapVisibility = useCallback(() => {
    setIsMapModalOpen((isMapModalOpen) => !isMapModalOpen);
  }, []);

  const mode = isLoading
    ? 'LOADING'
    : isWaitlistOnly
      ? 'WAITLIST'
      : locations.length > 0
        ? 'LOCATIONS'
        : isWaitlistWhenNoAppointment
          ? 'WAITLIST'
          : 'NO_APPOINTMENT';

  const showClincList = mode === 'LOCATIONS';
  const showNoAppointmentsNotice = mode === 'NO_APPOINTMENT';
  const showContinueButton = mode === 'LOCATIONS';
  const showWaitlist = mode === 'WAITLIST';

  const shouldShowMap =
    mapVisibility === MAP_VISIBLITY.SHOW &&
    !showNoAppointmentsNotice &&
    !showWaitlist;

  const noAppointmentInformationMessages = useMemo(
    () => [
      { title: 'Phone number', message: organizationContactPhone },
      { title: 'Email address', message: organizationContactEmail },
    ],
    [organizationContactPhone, organizationContactEmail],
  );

  return (
    <>
      <div className="multi-service">
        <MobileClinicSearchBar
          selectedServices={selectedServices}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          onSearch={onSearch}
          showMobileMap={showMobileMap}
          toggleMobileMap={toggleMobileMap}
          mapVisibility={mapVisibility}
        />
        <DesktopClinicSearchBar
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          onSearch={onSearch}
        />
        <div className="multi-service-clinic">
          <section
            className={cls(
              'section__margin d-flex flex-column flex-grow',
              styles.clinicList,
            )}
          >
            <div className="container-lg d-flex flex-column flex-grow">
              <div
                className={cls({
                  'row flex-grow': 1,
                  'justify-content-center': !(
                    mapVisibility === MAP_VISIBLITY.SHOW && shouldShowMap
                  ),
                })}
              >
                {isLoading ? (
                  <SelectLocationSkeletonLoader shouldShowMap={shouldShowMap} />
                ) : (
                  <>
                    <div
                      className={cls(
                        shouldShowMap ? 'col-6-lg' : 'col-8-lg',
                        'pr-4x-lg d-flex flex-column flex-grow',
                      )}
                    >
                      <div className="choose-clinic-location__container d-flex flex-column flex-grow">
                        {showClincList && (
                          <>
                            {searchTerm ? (
                              <h3 className="mb-5x mt-4x" tabIndex="0">
                                <BackToPage
                                  text={getString('backToPreviousPage')}
                                  onClick={nav.goBack}
                                />
                                {getString(
                                  'clinicsForServiceAndLocation',
                                  <span
                                    className="color-primary-base"
                                    data-qa="selected-subservice-search"
                                  >
                                    {selectedServices
                                      .map(
                                        (eachService) =>
                                          eachService.service.name,
                                      )
                                      .join(', ')}
                                  </span>,
                                  <span
                                    className="color-primary-base"
                                    data-qa="searched-term"
                                  >
                                    {searchTerm}
                                  </span>,
                                )}
                              </h3>
                            ) : (
                              <h3
                                className={cls('mb-5x mt-4x', styles.backBtn)}
                                tabIndex="0"
                              >
                                <BackToPage
                                  text={getString('backToPreviousPage')}
                                  onClick={nav.goBack}
                                />
                              </h3>
                            )}

                            <SelectedServicesListBox
                              className="mb-8x"
                              selectedServices={selectedServices.map(
                                (eachService) => eachService.service.name,
                              )}
                              onClick={nav.toEditService}
                              disableEdit={disableEdit}
                            />

                            <div className="d-flex flex-wrap justify-content-between mb-2x mr-3x-lg">
                              <p
                                className={cls(
                                  'mb-3x mb-0x-md',
                                  styles.infoText,
                                )}
                              >
                                {getString(
                                  'searchClinicCount',
                                  locations.length,
                                  locations.length > 1 ? 's' : '',
                                )}
                              </p>
                              <div className="d-flex justify-content-end mb-3x mb-0x-sm mr-2x">
                                {mapVisibility ===
                                  MAP_VISIBLITY.ALLOW_TOGGLE && (
                                  <button
                                    className="btn btn--plain refresh-clinic btn__refresh mr-6x-lg mr-4x d-lg-flex d-none"
                                    onClick={toggleMapVisibility}
                                    data-qa="map-visibility"
                                  >
                                    <FiMapPin size={16} className="mr-2x" />
                                    Show Map
                                  </button>
                                )}
                                <RefreshDataButton
                                  label={getString('refreshData')}
                                  handleRefresh={onRefresh}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div
                          className={cls(
                            'choose-clinic-location',
                            'custom-scrollbar',
                            'mr-2x-lg',
                            !showClincList && 'center-align',
                          )}
                        >
                          <div className="d-flex flex-wrap mb-4x">
                            {showClincList &&
                              locations.map((location, index) => (
                                <ClinicCard
                                  key={index}
                                  clinicDetails={location}
                                  locationId={location.id}
                                  isChecked={
                                    !!selectedLocation &&
                                    selectedLocation.id === location.id
                                  }
                                  onLocationChange={onLocationChange}
                                />
                              ))}
                            {showWaitlist && (
                              <>
                                <h3
                                  className={cls('mb-5x', styles.backBtn)}
                                  tabIndex="0"
                                >
                                  <BackToPage
                                    text={getString('backToPreviousPage')}
                                    onClick={nav.goBack}
                                  />
                                </h3>
                                <WaitlistNotice />
                              </>
                            )}
                            {showNoAppointmentsNotice && (
                              <>
                                <h3
                                  className={cls('mb-5x', styles.backBtn)}
                                  tabIndex="0"
                                >
                                  <BackToPage
                                    text={getString('backToPreviousPage')}
                                    onClick={nav.goBack}
                                  />
                                </h3>

                                <NoAppointmentInformation
                                  messages={noAppointmentInformationMessages}
                                  btnTitle={getString('selectDifferentService')}
                                  informationSectionTitle={getString(
                                    'noClinicsAvailable',
                                  )}
                                  informationSectionDescription={getString(
                                    'noClinicsAvailableDescription',
                                  )}
                                  extraDetailSectionTitle={getString(
                                    'clinicContactInformation',
                                  )}
                                  onClick={nav.toEditService}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {showContinueButton && (
                        <div className="choose-clinic-location-button scroll-shadow">
                          <Button
                            isEnabled={selectedLocation}
                            label={getString('continue')}
                            onClick={onSubmit}
                            dataqa="go-to-eligibility"
                          />
                        </div>
                      )}
                    </div>

                    {shouldShowMap && (
                      <div className="clinic-map col-6-lg d-none d-lg-block pl-0x-lg">
                        <ClinicMap
                          clinics={locations}
                          selectedClinic={selectedLocation}
                          onLocationChange={onLocationChange}
                          onSubmit={onSubmit}
                          gotoWaitlist={nav.toWaitlist}
                          showWaitlistNotice={showWaitlist}
                          showNoAppointmentsNotice={showNoAppointmentsNotice}
                        />
                      </div>
                    )}
                  </>
                )}

                {/* Map view for mobile version */}
                {showMobileMap && (
                  <div className="clinic-map d-block d-lg-none">
                    <ClinicMap
                      clinics={locations}
                      selectedClinic={selectedLocation}
                      onLocationChange={onLocationChange}
                      onSubmit={onSubmit}
                      gotoWaitlist={nav.toWaitlist}
                      showWaitlistNotice={showWaitlist}
                      showNoAppointmentsNotice={showNoAppointmentsNotice}
                    />
                  </div>
                )}

                {isMapModalOpen && (
                  <MapModal
                    closeMapModal={toggleMapVisibility}
                    clinics={locations}
                    selectedClinic={selectedLocation}
                    onLocationChange={onLocationChange}
                    onSubmit={onSubmit}
                    gotoWaitlist={nav.toWaitlist}
                    showWaitlistNotice={showWaitlist}
                    showNoAppointmentsNotice={showNoAppointmentsNotice}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

const SelectLocationPage = () => {
  const nav = useNavigationHelper();
  return (
    <PageWrapper
      container="full"
      classNames={{ mainWrapper: 'main-wrapper-sm' }}
    >
      <SelectLocation toNextPage={nav.toSelectTiming} />
    </PageWrapper>
  );
};

export default SelectLocationPage;
