import styles from './FormHeader.module.scss';

const FormHeader = ({ text }) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center text-center text-uppercase ${styles.headerWrap}`}
    >
      <h2>{text}</h2>
    </div>
  );
};

export default FormHeader;
