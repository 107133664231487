import { iconPatient } from '../../../../../assets/images';
import React from 'react';
import styles from './PatientFormActiveHeader.module.scss';
import cls from 'classnames';
import { getString } from '../../../../../util/lang';

export const PatientFormActiveHeader = ({
  onClear,
  patientInfo,
  patientIndex,
  optionalSubtitle,
  onFocus,
}) => {
  return (
    <div
      className={cls(styles.FlexJustifyContainer, 'mb-1x')}
      onFocus={onFocus}
    >
      <div className={styles.PatientHeaderMainContainer}>
        <div className={styles.HeaderRow}>
          <img
            src={iconPatient}
            alt="patient-info"
            className={styles.PatientIcon}
          />
          <div className={styles.HeaderRowJustify}>
            <div
              className={cls(
                styles.PatientIconText,
                styles.FlexJustifyContainer,
              )}
            >
              <span className="ml-2x">
                {`${patientInfo?.firstName || ''} ${
                  patientInfo?.middleName
                    ? patientInfo.middleName +
                      ' ' +
                      (patientInfo?.lastName || '')
                    : patientInfo?.lastName || ''
                }`}
              </span>
              <span>
                <button
                  id={`clr-btn-${patientIndex}`} // this id will be used in the onBlur event of the input form
                  className={cls('btn btn--plain', styles.PatientInfoClearBtn)}
                  onClick={onClear}
                >
                  <span>{getString('clear')}</span>
                </button>
              </span>
            </div>
          </div>
        </div>
        {optionalSubtitle && (
          <div className={cls(styles.PatientHeaderOptionalSubtitle, 'ml-7x')}>
            {' '}
            {optionalSubtitle}
          </div>
        )}
      </div>
    </div>
  );
};
