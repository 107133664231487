import React from 'react';
import { getString } from 'util/lang';

const CalendarLegend = () => {
  return (
    <div className="calendar-day-status pt-4x pb-8x mr-auto">
      <div className="calendar-day-available mb-4x">
        <span className="day-status">XX</span>
        <span>{getString('calendarAvailable')}</span>
      </div>
      <div className="calendar-day-unavailable">
        <span className="day-status">XX</span>
        <span>{getString('calendarUnavailable')}</span>
      </div>
    </div>
  );
};

export default CalendarLegend;
