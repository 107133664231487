import React from 'react';

import { getString } from 'util/lang';
import Button from './Button';

const CancelledMessage = ({
  reBook,
  title,
  message,
  badge,
  showBookAppointmentButton,
}) => {
  return (
    <div className="success-content border-bottom">
      {badge}
      {title && <h3 tabIndex="0">{title}</h3>}
      {message && (
        <p tabIndex="0" className="mb-0x-md">
          {message}
        </p>
      )}
      {showBookAppointmentButton && (
        <div className="success-action-covid">
          <Button
            title={getString('bookAppointmentTitle')}
            isEnabled
            onClick={reBook}
            className="btn btn--large btn-primary--outline"
            isActive
            label={getString('bookAppointment')}
          />
        </div>
      )}
    </div>
  );
};

export default CancelledMessage;
