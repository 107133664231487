import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import LineBreakPdfView from '../../common/LineBreak/LineBreakPdfView';
import { questionnaireKeys } from '../../../validation/AddDeliveryTicket';
import YesNoRadioPdfView from '../YesNoButtons/YesNoRadioPdfView';
import RadioCheckBoxPdfView from '../YesNoButtons/RadioCheckBoxPdfView';
import { QuestionnaireRadioOption } from '../../../constants/RadioCheckOptions';

const Field = ({ val, style = {} }) => (
  <View style={{ ...styles.fieldWrap, ...style }}>
    <Text style={styles.fieldText}>{val}</Text>
  </View>
);

const QuestionnairePdfView = ({ questionnaire }) => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={styles.left}>
          <RadioCheckBoxPdfView
            options={QuestionnaireRadioOption}
            selectedValue={questionnaire[0]?.checked}
          />
        </View>
        <View style={styles.right}>
          <Text style={styles.rightText}>
            Was the patient informed of their right to rent or purchase
            Inexpensive & Routinely Purchased Items?
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.left}>
          <View style={styles.left}>
            <RadioCheckBoxPdfView
              options={QuestionnaireRadioOption}
              selectedValue={questionnaire[1]?.checked}
            />
          </View>
        </View>
        <View style={styles.right}>
          <Text style={styles.rightText}>
            Is the item provided above to be used with Medicare covered
            equipment? If YES,
          </Text>
          <View style={styles.fieldContainer}>
            <Text style={styles.rightText}>
              On what date did the patient get the equipment?{' '}
            </Text>
            <Field
              val={
                questionnaire[1]?.[questionnaireKeys.datePatientGotTheEquipment]
              }
            />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.rightText}>& Who paid for it? </Text>
            <Field val={questionnaire[1]?.[questionnaireKeys.whoPaidForIt]} />
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.left}>
          <View style={styles.left}>
            <RadioCheckBoxPdfView
              options={QuestionnaireRadioOption}
              selectedValue={questionnaire[2]?.checked}
            />
          </View>
        </View>
        <View style={styles.right}>
          <View style={styles.fieldContainer}>
            <Text style={styles.rightText}>
              If this is a refill, is the patient’s current supply nearly
              exhausted?  Approx. how many days supply remains?:
            </Text>
            <Field
              val={questionnaire[2]?.[questionnaireKeys.supplyRemainDays]}
              style={{ marginLeft: 52 }}
            />
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.left}>
          <View style={styles.left}>
            <RadioCheckBoxPdfView
              options={QuestionnaireRadioOption}
              selectedValue={questionnaire[3]?.checked}
            />
          </View>
        </View>
        <View style={styles.right}>
          <Text style={styles.rightText}>
            Has the patient’s information changed since registration?  If YES,
            complete a new registration form.
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.left}>
          <View style={styles.left}>
            <RadioCheckBoxPdfView
              options={QuestionnaireRadioOption}
              selectedValue={questionnaire[4]?.checked}
            />
          </View>
        </View>
        <View style={styles.right}>
          <Text style={styles.rightText}>
            If the item is an orthotic, did it require custom fitting by a
            licensed/certified individual?
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.left}>
          <View style={styles.left}>
            <RadioCheckBoxPdfView
              options={QuestionnaireRadioOption}
              selectedValue={questionnaire[5]?.checked}
            />
          </View>
        </View>
        <View style={styles.right}>
          <Text style={styles.rightText}>
            Is the patient enrolled in Home Health or Hospice services?
          </Text>
          <View style={styles.fieldContainer}>
            <Text style={styles.rightText}>Agency Name:</Text>{' '}
            <Field val={questionnaire[5]?.[questionnaireKeys.agencyName]} />
            <Text style={styles.rightText}>Phone No:</Text>{' '}
            <Field
              val={questionnaire[5]?.[questionnaireKeys.agencyPhoneNumber]}
            />
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.left}>
          <View style={styles.left}>
            <RadioCheckBoxPdfView
              options={QuestionnaireRadioOption}
              selectedValue={questionnaire[6]?.checked}
            />
          </View>
        </View>
        <View style={styles.right}>
          <Text style={styles.rightText}>
            Has the patient ever received same or similar equipment?  If YES,
            complete information listed below.
          </Text>
          <View style={styles.fieldContainer}>
            <Text style={styles.rightText}>Who was the Provider?:</Text>{' '}
            <Field val={questionnaire[6]?.[questionnaireKeys.providerName]} />
            <Text style={styles.rightText}>Phone No:</Text>{' '}
            <Field
              val={questionnaire[6]?.[questionnaireKeys.providerPhoneNumber]}
            />
          </View>

          <View style={styles.fieldContainer}>
            <Text style={styles.rightText}>Item Received:</Text>{' '}
            <Field val={questionnaire[6]?.[questionnaireKeys.itemReceived]} />
            <Text style={styles.rightText}>Date Received:</Text>{' '}
            <Field val={questionnaire[6]?.[questionnaireKeys.dateReceived]} />
          </View>
          <View style={{ ...styles.fieldContainer, alignItems: 'flex-end' }}>
            <Text style={styles.rightText}>Do you still have this item?</Text>{' '}
            <YesNoRadioPdfView
              checked={questionnaire[6]?.stillHasTheItem}
              style={{ paddingHorizontal: 5, marginBottom: -2, paddingTop: 2 }}
            />
            <Text style={{ ...styles.rightText }}>Date Returned: </Text>{' '}
            <Field val={questionnaire[6]?.[questionnaireKeys.dateReturned]} />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.rightText}>Why was it returned?</Text>{' '}
            <Field val={questionnaire[6]?.[questionnaireKeys.returnReason]} />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.rightText}>
              Was there a 60 day break in need?
            </Text>{' '}
            <Field val={questionnaire[6]?.[questionnaireKeys.agencyName]} />
          </View>
        </View>
      </View>
      <LineBreakPdfView />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  left: {
    flex: 3,
    marginTop: 4,
  },
  right: {
    flex: 9,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rightText: {
    fontSize: 9,
    fontWeight: 400,
    color: '#323130',
    marginTop: 4,
  },
  img: {
    height: 14,
    width: 14,
  },
  radioWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
  },
  radioTextWrap: {
    minWidth: 315,
  },
  radioText: {
    fontSize: 10,
    fontWeight: 400,
    color: '#3B3A39',
    marginLeft: 5,
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldWrap: {
    paddingHorizontal: 3,
    borderBottom: '1px solid #323130',
    minWidth: 100,
    maxWidth: 200,
    minHeight: 15,
  },
  fieldText: {
    fontSize: 9,
    fontWeight: 400,
    color: '#323130',
    paddingTop: 3,
  },
});

export default QuestionnairePdfView;
