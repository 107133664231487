import React from 'react';
import 'moment/locale/es';
import moment from 'moment';
import { withRouter } from 'react-router';

import * as stateService from 'services/state';
import * as countyService from 'services/county';
import * as languagesService from 'services/languages';
import * as organizationService from 'services/accounts';
import * as appSettingsService from 'services/appSettings';

import { changeApplicationInfo } from '../../util/siteInfo';
import {
  convertUTCToDateObject,
  extractTimeFromDateObject,
  setDateTimeLocale,
} from '../../util/DateAndTime';
import { getLanguageIdByLanguageCode, updateLanguage } from '../../util/lang';

import APP_LANGUAGES from 'constants/appLanguages';
import { getSearchQueryValue } from 'util/queryParser';
import { replaceArrayElementByIndex } from 'util/array';
import { CovidPageNames } from '../../constants/pages';
import DoseNumber from '../../constants/covid/doseNumber';
import { IntialAppStateCovid } from '../../constants/app';
import * as routes from '../../constants/routes/covidRoutes';
import { AvailableInsuranceCoverageIndices } from '../../constants/patientInfo';

// Using clone of initial app state to solve issue of context retaining values
const InitialState = JSON.parse(JSON.stringify(IntialAppStateCovid));

moment.locale('en');

/**
 * param {String} path
 * returns {String}
 * The function takes in a path and returns the organization id
 */

function getOrganizationId(path) {
  const splitPath = path.split('/');

  if (splitPath.length < 2) return '';

  const orgId = splitPath[splitPath.length - 1];
  return orgId;
}

/**
 * Covid app Conponent.
 */
export class CovidApp extends React.Component {
  constructor() {
    super();

    this.state = { ...IntialAppStateCovid };
  }

  componentDidMount() {
    this.backListener = this.props.history.listen((location, action) => {
      // organizationId is '' if organization id is not specified
      const organizationId = getOrganizationId(location.pathname);
      if (location.pathname.includes('/success/') && action === 'POP') {
        this.setState({ ...InitialState, appSettings: this.state.appSettings });
        const route = `${routes.SERVICE}/${organizationId}`;
        this.props.history.replace({
          pathname: route,
          search: window.location.search,
        });
      }
    });
  }

  componentWillUnmount() {
    this.backListener();
  }

  setIsServiceAndClinicSelected = (value) => {
    this.setState({
      isServiceAndClinicSelected: value,
    });
  };

  /**
   * Set normalFlow to true allowing access to protected routes
   */
  setNormalFlow = () => {
    this.setState({
      normalFlow: true,
    });
  };

  /**
   * Set insuranceVerified value after validation of insurance info
   * @param {{bool}} val
   */
  setInsuranceVerified = (val = false) => {
    this.setState({ insuranceVerified: val });
  };

  /**
   * Change status of page. (if completed or not)
   *
   * @param {bool} status : Status of the page.
   */
  setPageComplete = (status) => {
    this.setState((state) => ({
      pageComplete: { ...state.pageComplete, ...status },
    }));
  };

  setDetails = (details) => {
    return this.setState(details);
  };

  setSelectedVaccine = (vaccine) => {
    this.setState({
      selectedVaccine: vaccine,
    });
  };

  setSelectedVaccineService = (service) => {
    this.setState({
      selectedVaccineService: service,
    });
  };

  registerUser = (regCode) => {
    this.setState({
      registrationCode: regCode,
      isUserRegistered: true,
    });
  };

  setAppointmentSuccess = (value) => {
    this.setState({
      appointmentSuccess: value,
    });
  };

  setIsGroupAppointment = (value) => {
    this.setState({
      isGroupAppointment: value,
    });
  };

  setFilteredSubservice = (filteredsubService) => {
    this.setState({
      serviceSelection: {
        ...this.state.serviceSelection,
        selectedVaccine: {
          ...this.state.serviceSelection.selectedVaccine,
          services: filteredsubService,
        },
      },
    });
  };

  setSelectedService = (service) => {
    const updatedServiceSelection = {
      ...this.state.serviceSelection,
      selectedService: service,
    };
    this.setState({ serviceSelection: updatedServiceSelection });
  };

  setSelectedVaccineForService = (vaccine, cb) => {
    this.setState(
      (state) => ({
        serviceSelection: {
          ...state.serviceSelection,
          selectedVaccine: vaccine,
        },
      }),
      cb,
    );
  };

  setSelectedSubservice = (subservice) => {
    const updatedServiceSelection = {
      ...this.state.serviceSelection,
      selectedSubservice: subservice,
    };
    this.setState({ serviceSelection: updatedServiceSelection });
  };

  setLocationSearchTerm = (locationSearchTerm) => {
    const updatedServiceSelection = {
      ...this.state.serviceSelection,
      locationSearchTerm,
    };
    this.setState({ serviceSelection: updatedServiceSelection });
  };

  setSelectedClinic = (clinic) => {
    const updatedServiceSelection = {
      ...this.state.serviceSelection,
      selectedClinic: clinic,
    };
    this.setState({ serviceSelection: updatedServiceSelection });
  };

  /**
    * Used by: AcknowlegeAndInfo
    * Description:
      Set values when radio buttons are clicked.
    * @param {object} : A key value pair of radio input
    * key: key to which radio button input must map to
    * value: value of radio button input
    *
    - Initially all the values are false
    - Values are mapped by keys
    - If answer to any question with alert set to true is true,
      prevent patient from submitting by setting allowFlu shot to false and show alert to true
    - If all the acknowledgement questions are not answered,
      isAcknowledgementFilled is set to false, preventing submit
   */
  onHealthQuestionAnswerChange = (obj) => {
    this.setState((state) => {
      const patientHealthAnswers = state.patientHealthAnswers.slice();
      patientHealthAnswers[this.state.currentPatientIndex] = {
        ...patientHealthAnswers[this.state.currentPatientIndex],
        ...obj,
      };
      return {
        patientHealthAnswers: patientHealthAnswers,
      };
    });
  };

  onPreScreeningAnswersChange = (obj) => {
    this.setState({
      patientPrescreeningAnswers: {
        ...this.state.patientPrescreeningAnswers,
        ...obj,
      },
    });
  };

  onSelfDeclarationChange = (key, value) => {
    const startTrimed = value.trimStart();
    value = startTrimed.replace(/  +/g, ' ');

    this.setState(
      (state) => {
        const selfDeclaration = state.selfDeclaration.slice();
        selfDeclaration[this.state.currentPatientIndex] = {
          ...selfDeclaration[this.state.currentPatientIndex],
          [key]: value,
        };

        const patientInfo = state.patientInfo.slice();
        patientInfo[this.state.currentPatientIndex] = {
          ...patientInfo[this.state.currentPatientIndex],
          [key]: value,
        };
        return {
          selfDeclaration: selfDeclaration,
          patientInfo: patientInfo,
        };
      },
      () => {
        const { firstName, lastName } =
          this.state.selfDeclaration[this.state.currentPatientIndex];
        if (!firstName || !lastName) {
          const currentPath = window.location.pathname;
          if (
            this.state.pageComplete[CovidPageNames.PatientEligibility] &&
            currentPath === routes.HOME
          ) {
            this.setPageComplete({
              [CovidPageNames.PatientInfo]: false,
              [CovidPageNames.Confirmation]: false,
            });
          }
        }
      },
    );
  };

  handleAgreement = (e) => {
    this.setState({
      isAgree: e.target.checked,
    });
  };

  handleFirstAgreement = (value) => {
    const updatedIsFirst = replaceArrayElementByIndex(
      this.state.isFirstAgree,
      this.state.currentPatientIndex,
      value,
    );
    this.setState({
      isFirstAgree: updatedIsFirst,
    });
  };

  /**
   * Set the status of loading
   *
   * @param {bool} val : Loading status
   */
  setLoading = (val) => {
    this.setState({
      loading: val,
    });
  };

  /**
   * Set the status of loading for date time
   *
   * @param {bool} val : Loading status
   */
  setDateTimeLoading = (val) => {
    this.setState({
      dateTimeLoading: val,
    });
  };

  getAppSettings = () => {
    return this.state.appSettings;
  };

  setAppSettings = (val = {}) => {
    this.setState(
      {
        appSettings: {
          ...this.state.appSettings,
          ...val,
        },
      },
      () => {
        const { faviconUrl, pageTitle } = val;
        changeApplicationInfo(faviconUrl, pageTitle);
      },
    );
  };

  // Note, for postServiceDataSetup the first payerlist contains all possible payerlist used to get allAvailableInsuranceType
  // later in PaymentInfo user selected payerlist is contained
  setPayerlist = (
    list = [],
    insuranceIndex = AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
  ) => {
    const payerList = [...list];
    this.setState({
      payerList: replaceArrayElementByIndex(
        this.state.payerList,
        insuranceIndex,
        payerList,
      ),
      payerlistFetched: true,
    });
  };

  getPayerlist = (
    insuranceIndex = AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
  ) => {
    return this.state.payerList[insuranceIndex] || [];
  };

  setClinicList = (clinics) => {
    this.setState({
      clinics,
    });
  };

  saveEligibilityResponse = (currentPatientEligibilityResponse) => {
    const eligibilityResponse = this.state.eligibilityResponse.slice();
    eligibilityResponse[this.state.currentPatientIndex] = {
      ...eligibilityResponse[this.state.currentPatientIndex],
      ...currentPatientEligibilityResponse,
    };
    this.setState({ eligibilityResponse });
  };

  setSecurityChecked = (isSecurityChecked) => {
    this.setState({
      isSecurityChecked: isSecurityChecked,
    });
  };

  //#region API calls

  preServiceDataSetup = async (organizationId, appointmentId) => {
    const organizationObj = await this.fetchOrganizationDetail(organizationId);
    this.setState({
      organizationObj,
      isOrganizationDetailsSet: true,
    });
  };

  /**
   * Set up clinic, appointment and appointment days.
   *
   * @param {string} organizationId : organization id
   * @param {string} appointmentId : appointment id
   */
  postServiceDataSetup = async (organizationId, appointmentId) => {
    try {
      if (!this.state.setupPhase) return;

      // const { serviceCategoryId } = this.state.serviceSelection.selectedVaccine;
      const clinics = this.state.clinics;

      this.setLoading(true);
      this._initialFetchPayerList();

      const appointmentPromise = this.fetchAppointment(appointmentId);

      const [appointments] = await Promise.all([appointmentPromise]);

      const finalAppointmentData = appointments.map((appointment) => {
        return this.createInitialAppointmentObject(appointment, clinics);
      });

      const firstAppointment = finalAppointmentData.find(
        (appointment) =>
          appointment.smvs_appointment_dose_number === DoseNumber.FirstDose,
      );
      const secondAppointment = finalAppointmentData.find(
        (appointment) =>
          appointment.smvs_appointment_dose_number === DoseNumber.SecondDose,
      );

      const firstAppointmentObj = firstAppointment
        ? {
            date: convertUTCToDateObject(
              firstAppointment.smvs_appointmentdatetime,
            ),
            time: extractTimeFromDateObject(
              firstAppointment.smvs_appointmentdatetime,
            ),
            activityid: firstAppointment.activityid,
            clinic: firstAppointment.clinic,
            doseNumber: DoseNumber.FirstDose,
            fulfilled: firstAppointment.fulfilled,
            cancelled: firstAppointment.cancelled,
            noShow: firstAppointment.noShow,
            appointmentUUID: firstAppointment.smvs_scheduling_id,
            canBeRescheduled: !(
              firstAppointment.noShow ||
              firstAppointment.fulfilled ||
              firstAppointment.cancelled
            ),
          }
        : null;

      const secondAppointmentObj = secondAppointment
        ? {
            date: convertUTCToDateObject(
              secondAppointment.smvs_appointmentdatetime,
            ),
            time: extractTimeFromDateObject(
              secondAppointment.smvs_appointmentdatetime,
            ),
            activityid: secondAppointment.activityid,
            clinic: secondAppointment.clinic,
            doseNumber: DoseNumber.SecondDose,
            fulfilled: secondAppointment.fulfilled,
            cancelled: secondAppointment.cancelled,
            noShow: secondAppointment.noShow,
            appointmentUUID: secondAppointment.smvs_scheduling_id,
            canBeRescheduled: !(
              secondAppointment.noShow ||
              secondAppointment.fulfilled ||
              secondAppointment.cancelled
            ),
          }
        : null;

      const appointmentBeingRescheduled = finalAppointmentData.find(
        (appointment) => appointment.activityid === appointmentId,
      );

      //NOTE: commenting selected vaccine might cause problem for rescheduling

      this.setState(
        (state) => {
          const patientInfo = state.patientInfo.slice();
          patientInfo[state.currentPatientIndex] = {
            ...patientInfo[state.currentPatientIndex],
            confirmationCode:
              appointments[0]?.msemr_ActorPatient_msemr_appointmentemr
                ?.smvs_confirmationcode,
          };
          return {
            rescheduleClinicId: secondAppointment?.clinic?.msemr_locationid,
            organizationId,
            appointmentBeingRescheduled,
            // appointments: {
            //   firstAppointment: firstAppointmentObj ?? state.appointments.firstAppointment,
            //   secondAppointment: secondAppointmentObj ?? state.appointments.secondAppointment,
            // },
            patientInfo: patientInfo,
            setupPhase: false,
          };
        },
        () => this.setLoading(false),
      );
    } catch (e) {
      console.log('postServiceDataSetup', e);
      this.setLoading(false);
    }
  };

  /**
   * Fetch localized web contents, elligiblity questions, screening questions and services
   *
   */
  waitlistDataSetup = async () => {
    try {
      this.setLoading(true);

      //TODO: Later remove this code
      // const languageCode = this.getCurrentLanguageCode();
      // const { serviceCategoryId } = this.state.serviceSelection.selectedVaccine;

      // const updateContentPromise = await this.updateContentWithSelectedLanguage(languageCode, serviceCategoryId);
      // FIXME: its already bene awaited ... what ???
      // await Promise.all([updateContentPromise]);

      this.setState(
        {
          setupPhase: false,
        },
        () => this.setLoading(false),
      );
    } catch (e) {
      console.log('waitlistDataSetup', e);
      this.setLoading(false);
    }
  };

  fetchAppConfigurations = async () => {
    try {
      const appSettingsPromise = appSettingsService.fetchAppSettings();
      const languagesPromise = languagesService.fetchLanguages();

      const [appSettings, languages] = await Promise.all([
        appSettingsPromise,
        languagesPromise,
      ]);

      const defaultLanguageId = getLanguageIdByLanguageCode(
        APP_LANGUAGES.english,
        languages,
      );

      let appSettingObj = {
        ...appSettings?.data,
        languages,
        isAppSettingsSet: true,
      };

      if (!appSettings?.success) {
        appSettingObj = { ...appSettingObj, isInValidConfig: true };
      }

      this.setAppSettings(appSettingObj);

      this.setState({
        defaultLanguageId,
      });
    } catch (err) {
      this.setAppSettings({
        isAppSettingsSet: true,
        isError: true,
      });
    }
  };

  fetchStatesAndCounties = async () => {
    try {
      const countyPromise = countyService.fetchCounties();
      const statePromise = stateService.fetchStates();
      const [counties, states] = await Promise.all([
        countyPromise,
        statePromise,
      ]);

      this.setState({
        counties,
        states,
      });
    } catch (err) {
      this.setAppSettings({
        isAppSettingsSet: true,
        isError: true,
      });
    }
  };

  fetchOrganizationDetail = async (organizationId) => {
    try {
      return await organizationService.getById(organizationId);
    } catch (ex) {
      console.error(ex);
      return;
    }
  };

  updateSelections = (serviceList) => {
    const updatedSelectedService = serviceList.find(
      (service) =>
        service.smvs_service_typeid ===
        this.state.serviceSelection.selectedService.smvs_service_typeid,
    );

    if (this.state.serviceSelection.selectedVaccine) {
      const selectedVaccine = this.state.serviceSelection.selectedVaccine;
      const updatedSelectedVaccine = updatedSelectedService.vaccines.find(
        (vaccine) =>
          vaccine.serviceCategoryId === selectedVaccine.serviceCategoryId,
      );
      this.setSelectedVaccineForService(updatedSelectedVaccine);
      const selectedSubSevice = this.state.selectedVaccine;
      const updatedSelectedSubService = updatedSelectedVaccine.services.find(
        (subService) => subService.value === selectedSubSevice.value,
      );
      if (updatedSelectedSubService) {
        this.setSelectedVaccine(updatedSelectedSubService);
      }
    }
    this.setSelectedService(updatedSelectedService);
    return updatedSelectedService;
  };

  /**
   * Find the clinic day for the selected appointment date and time.
   *
   * @param {array} clinicDays : List of clinic days
   * @param {string} appointmentDate : Date of the appointemnt.
   * @param {string} appointmentTime : Time of the appointment.
   */

  findClinicDayForAppointment = (
    clinicDays,
    appointmentDate,
    appointmentTime,
  ) => {
    return clinicDays.find((clinicDay) => {
      const isTimeAvailable =
        clinicDay.clinicDate.getTime() === appointmentDate.getTime();
      const slotTime = clinicDay.timeslots.find(
        (slot) => slot.time === appointmentTime,
      );
      return isTimeAvailable && slotTime;
    });
  };

  /**
   * Find selected vaccine details by serviceId
   *
   * @param {*} subServiceId : Id of health care service
   * @param {*} vaccineList : List of all avialable services
   */
  findSelectedSubServiceById = (subServiceId, vaccineList) => {
    if (!subServiceId) return '';
    let selectedVaccine = {};
    vaccineList.forEach((vaccine) => {
      vaccine.services.forEach((service) => {
        if (service.value === subServiceId) {
          selectedVaccine = service;
        }
      });
    });
    return selectedVaccine;
  };

  /**
   * Find selected vaccine details by serviceCategoryId
   *
   * @param {*} serviceId : Id of health care service
   * @param {*} vaccineList : List of all avialable services
   */
  findSelectedServiceCategoryById = (serviceCategoryId, vaccineList) => {
    if (!serviceCategoryId) return '';
    let selectedVaccine = {};
    vaccineList.forEach((vaccine) => {
      if (vaccine.serviceCategoryId === serviceCategoryId) {
        selectedVaccine = vaccine;
      }
    });
    return selectedVaccine;
  };

  /**
   * Find selected service details by serviceId
   *
   * @param {*} serviceCategoryId : Id of health care service
   * @param {*} serviceList : List of all avialable services
   */
  findSelectedServiceById = (serviceCategoryId, serviceList) => {
    if (!serviceCategoryId) return '';
    let selectedService = {};
    serviceList.forEach((service) => {
      service.vaccines.forEach((vaccine) => {
        if (vaccine.serviceCategoryId === serviceCategoryId) {
          selectedService = service;
        }
      });
    });
    return selectedService;
  };

  /**
   * Find selected service details by serviceId
   *
   * @param {*} clinicId : List of all avialable clinics
   * @param {*} clinicList : Id of health care service
   */
  findSelectedClinicById = (clinicId, clinicList) => {
    if (!clinicId) return '';
    let selectedClinic = {};
    clinicList.forEach((clinic) => {
      if (clinic.msemr_locationid === clinicId) {
        selectedClinic = clinic;
      }
    });
    return selectedClinic;
  };

  disableNavForSuccessPage = () => {
    this.setPageComplete({
      [CovidPageNames.BeforeApproval]: false,
      [CovidPageNames.SelectClinicAndTiming]: false,
      [CovidPageNames.PatientEligibility]: false,
      [CovidPageNames.PatientInfo]: false,
      [CovidPageNames.Confirmation]: false,
    });
  };

  /**
   * Set the app state to an initial state for new patient flow
   */
  resetForNewPatient = () => {
    const newState = {
      ...InitialState,
      appSettings: this.state.appSettings,
      states: this.state.states,
      counties: this.state.counties,
    };
    this.setState(newState);
  };

  resetPageComplete = () => {
    this.setPageComplete({
      [CovidPageNames.BeforeApproval]: true,
      [CovidPageNames.SelectClinicAndTiming]: false,
      [CovidPageNames.PatientEligibility]: false,
      [CovidPageNames.PatientInfo]: false,
      [CovidPageNames.Confirmation]: false,
    });
  };

  updateSelectedLanguageCode = (selectedLanguageCode) => {
    const languageCode = selectedLanguageCode || this.getCurrentLanguageCode();

    const codeQuery = getSearchQueryValue('c');
    const langQuery = `?lang=${languageCode}`;

    let searchQuery = codeQuery ? `${langQuery}&c=${codeQuery}` : langQuery;

    this.props.history.push({
      pathname: window.location.pathname,
      search: searchQuery,
    });

    this.setState({ appLanguageCode: languageCode });

    setDateTimeLocale(languageCode);

    updateLanguage(languageCode);
  };

  getCurrentLanguageCode = () => {
    const queryPrams = new URLSearchParams(this.props.location.search);
    const languageCodeInQueryParams = queryPrams.get('lang');

    if (languageCodeInQueryParams) {
      const availableLanguages = this.state.appSettings.languages;
      const isLanguageCodeParamsValid = availableLanguages.some(
        (language) => language.code === languageCodeInQueryParams,
      );

      if (isLanguageCodeParamsValid) {
        return languageCodeInQueryParams;
      }
    }

    return this.state.appLanguageCode;
  };

  /**
   * Render life cycle method.
   */
  render() {
    // CovidApp is deprecated; use App.js
    return null;
  }
}

export default withRouter(CovidApp);
