export const serviceGroups = {
  COVID19_VACCINE: { label: 'Covid 19 Vaccine', type: 'VACCINE' },
  POINT_OF_CARE_TESTING: { label: 'Point of Care Testing', type: 'POC' },
  COMMON_DISEASE_VACCINE: { label: 'Common Disease Vaccine', type: 'VACCINE' },
  TRAVEL_VACCINE: { label: 'Travel Vaccine', type: 'VACCINE' },
  COUNSELLING: { label: 'Assessment and Intervention', type: 'AAI' },
};

// TODO: use style/color to handle active/inactive icons instead of copy
export const serviceGroupIcons = {
  VACCINE: {
    activeIcon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#0259A8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.33 14.4054C29.5879 14.6634 29.5879 15.0815 29.33 15.3394C29.0721 15.5973 28.654 15.5973 28.3961 15.3394L27.9288 14.8721L25.5942 17.2067L27.4621 19.0747C27.72 19.3326 27.72 19.7508 27.4621 20.0087C27.2042 20.2666 26.7861 20.2666 26.5282 20.0087L25.5944 19.0749L17.6555 27.0139C16.6981 27.9714 15.299 28.2179 14.1141 27.7537L13.4529 28.4149C13.107 28.7608 12.6172 28.8747 12.1765 28.7569L10.6508 30.2826C10.3929 30.5405 9.9748 30.5405 9.71688 30.2826C9.45896 30.0247 9.45896 29.6066 9.71688 29.3486L11.2428 27.8227C11.1253 27.3821 11.2393 26.8926 11.5849 26.547L12.2461 25.8858C11.7817 24.7008 12.0283 23.3015 12.9858 22.344L20.9246 14.405L19.9905 13.4708C19.7326 13.2129 19.7326 12.7948 19.9905 12.5368C20.2483 12.2789 20.6665 12.2789 20.9244 12.5368L22.7924 14.4048L25.1269 12.0702L24.6603 11.6035C24.4023 11.3456 24.4023 10.9274 24.6603 10.6695C24.9181 10.4116 25.3363 10.4116 25.5942 10.6695L29.33 14.4054ZM23.5995 15.212L25.9341 12.8774L27.1216 14.0649L24.787 16.3996L23.5995 15.212ZM21.7318 15.2122L24.7872 18.2677L22.2725 20.7824L19.217 17.7269L21.7318 15.2122ZM21.3386 21.7164L19.9376 23.1175L18.8768 22.0566C18.6188 21.7987 18.2007 21.7987 17.9428 22.0566C17.6849 22.3145 17.6849 22.7327 17.9428 22.9906L19.0036 24.0514L18.07 24.9852L17.009 23.9242C16.7511 23.6663 16.3329 23.6663 16.075 23.9242C15.8171 24.1821 15.8171 24.6003 16.075 24.8582L17.136 25.9192L16.8484 26.2068C16.2216 26.8335 15.3056 26.9947 14.5301 26.6903L13.3094 25.4695C13.005 24.694 13.1662 23.778 13.7929 23.1512L18.2831 18.6609L21.3386 21.7164ZM12.3762 27.3722C12.381 27.366 12.3863 27.3599 12.3921 27.3541L12.8624 26.8838C12.9021 26.928 12.9432 26.9714 12.9858 27.0139C13.0283 27.0565 13.0718 27.0976 13.1161 27.1374L12.6457 27.6078C12.6399 27.6136 12.6338 27.6189 12.6274 27.6237C12.5986 27.5725 12.5624 27.5243 12.5188 27.4807C12.4753 27.4372 12.4273 27.4011 12.3762 27.3722Z"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#0259A8"
        />
      </svg>
    ),
    inactiveIcon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.33 14.4054C29.5879 14.6634 29.5879 15.0815 29.33 15.3394C29.0721 15.5973 28.654 15.5973 28.3961 15.3394L27.9288 14.8721L25.5942 17.2067L27.4621 19.0747C27.72 19.3326 27.72 19.7508 27.4621 20.0087C27.2042 20.2666 26.7861 20.2666 26.5282 20.0087L25.5944 19.0749L17.6555 27.0139C16.6981 27.9714 15.299 28.2179 14.1141 27.7537L13.4529 28.4149C13.107 28.7608 12.6172 28.8747 12.1765 28.7569L10.6508 30.2826C10.3929 30.5405 9.9748 30.5405 9.71688 30.2826C9.45896 30.0247 9.45896 29.6066 9.71688 29.3486L11.2428 27.8227C11.1253 27.3821 11.2393 26.8926 11.5849 26.547L12.2461 25.8858C11.7817 24.7008 12.0283 23.3015 12.9858 22.344L20.9246 14.405L19.9905 13.4708C19.7326 13.2129 19.7326 12.7948 19.9905 12.5368C20.2483 12.2789 20.6665 12.2789 20.9244 12.5368L22.7924 14.4048L25.1269 12.0702L24.6603 11.6035C24.4023 11.3456 24.4023 10.9274 24.6603 10.6695C24.9181 10.4116 25.3363 10.4116 25.5942 10.6695L29.33 14.4054ZM23.5995 15.212L25.9341 12.8774L27.1216 14.0649L24.787 16.3996L23.5995 15.212ZM21.7318 15.2122L24.7872 18.2677L22.2725 20.7824L19.217 17.7269L21.7318 15.2122ZM21.3386 21.7164L19.9376 23.1175L18.8768 22.0566C18.6188 21.7987 18.2007 21.7987 17.9428 22.0566C17.6849 22.3145 17.6849 22.7327 17.9428 22.9906L19.0036 24.0514L18.07 24.9852L17.009 23.9242C16.7511 23.6663 16.3329 23.6663 16.075 23.9242C15.8171 24.1821 15.8171 24.6003 16.075 24.8582L17.136 25.9192L16.8484 26.2068C16.2216 26.8335 15.3056 26.9947 14.5301 26.6903L13.3094 25.4695C13.005 24.694 13.1662 23.778 13.7929 23.1512L18.2831 18.6609L21.3386 21.7164ZM12.3762 27.3722C12.381 27.366 12.3863 27.3599 12.3921 27.3541L12.8624 26.8838C12.9021 26.928 12.9432 26.9714 12.9858 27.0139C13.0283 27.0565 13.0718 27.0976 13.1161 27.1374L12.6457 27.6078C12.6399 27.6136 12.6338 27.6189 12.6274 27.6237C12.5986 27.5725 12.5624 27.5243 12.5188 27.4807C12.4753 27.4372 12.4273 27.4011 12.3762 27.3722Z"
          fill="#0259A8"
        />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#0259A8"
        />
      </svg>
    ),
    listIcon: (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8449 4.14154C16.0512 4.34787 16.0512 4.68238 15.8449 4.88872C15.6386 5.09501 15.3041 5.09506 15.0977 4.88872L14.7239 4.51485L12.8562 6.38258L14.3505 7.87695C14.5569 8.08329 14.5569 8.41779 14.3505 8.62413C14.1443 8.83047 13.8097 8.83047 13.6034 8.62413L12.8564 7.87708L6.50531 14.2283C5.7394 14.9943 4.62009 15.1915 3.67218 14.8201L3.14316 15.3491C2.86651 15.6258 2.47466 15.717 2.12207 15.6227L0.901524 16.8433C0.695231 17.0496 0.360725 17.0496 0.154387 16.8433C-0.0519524 16.6369 -0.0519524 16.3024 0.154387 16.0961L1.37511 14.8753C1.2811 14.5229 1.37233 14.1313 1.6488 13.8548L2.17777 13.3258C1.80628 12.3778 2.00353 11.2584 2.76948 10.4924L9.12058 4.14117L8.37326 3.39385C8.16693 3.18751 8.16693 2.85301 8.37326 2.64667C8.57956 2.44033 8.91411 2.44034 9.1204 2.64667L10.6148 4.14103L12.4824 2.27333L12.1091 1.89998C11.9028 1.69365 11.9028 1.35912 12.1091 1.15279C12.3154 0.946465 12.6499 0.946465 12.8562 1.15279L15.8449 4.14154ZM11.2605 4.78676L13.1281 2.91908L14.0782 3.86913L12.2105 5.73686L11.2605 4.78676ZM9.76631 4.78694L12.2107 7.23136L10.1989 9.24315L7.75452 6.79873L9.76631 4.78694ZM9.45176 9.99033L8.33098 11.1111L7.4823 10.2625C7.27596 10.0561 6.94145 10.0561 6.73512 10.2625C6.52878 10.4688 6.52878 10.8033 6.73512 11.0096L7.5838 11.8583L6.83685 12.6053L5.98807 11.7566C5.78173 11.5502 5.44722 11.5502 5.24088 11.7566C5.03459 11.9629 5.03459 12.2974 5.24088 12.5037L6.08966 13.3525L5.85958 13.5826C5.35819 14.084 4.62539 14.2129 4.00496 13.9694L3.02838 12.9928C2.78487 12.3724 2.91382 11.6396 3.41521 11.1381L7.00739 7.54591L9.45176 9.99033ZM2.28188 14.515C2.28571 14.51 2.28996 14.5051 2.29453 14.5005L2.67081 14.1242C2.70259 14.1596 2.73547 14.1943 2.76948 14.2283C2.80355 14.2624 2.83829 14.2953 2.87372 14.3271L2.49744 14.7034C2.49278 14.708 2.4879 14.7123 2.48283 14.7162C2.45977 14.6752 2.43077 14.6366 2.39589 14.6017C2.3611 14.5669 2.3227 14.538 2.28188 14.515Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  POC: {
    activeIcon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#0259A8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4868 11.2192L21.6101 10.7466L21.0332 11.6932C20.2539 11.4515 19.3738 11.7494 18.9458 12.4466L16.2854 16.7808C15.7955 17.579 16.0838 18.5984 16.9293 19.0576L17.4284 19.3286L17.0301 20.058L18.603 20.8172L18.9613 20.1611L19.3538 20.3742C20.1994 20.8334 21.282 20.5585 21.7719 19.7603L23.4906 16.9603C24.9982 17.7818 26.0379 18.6991 26.5955 19.7482C27.0649 20.6311 27.2192 21.6575 26.9595 22.8899C26.1171 22.4486 25.1484 22.1975 24.1176 22.1975C22.1943 22.1975 20.4865 23.0721 19.4132 24.4244L12.3038 20.6284L11.7198 21.596L18.8034 25.3781C18.4392 26.1031 18.2353 26.9143 18.2353 27.7707C18.2353 28.1524 18.2758 28.5252 18.3529 28.8853H10.5882C10.2634 28.8853 10 29.1349 10 29.4427C10 29.7505 10.2634 30 10.5882 30H29.5105C29.8254 29.3173 30 28.5633 30 27.7707C30 26.1013 29.2253 24.6033 27.9979 23.5819C28.4458 21.9504 28.3185 20.5115 27.6463 19.2468C26.9491 17.9352 25.7016 16.8801 24.0821 15.9967L24.4324 15.4261C24.8088 14.8128 24.7258 14.0689 24.2836 13.549L24.9079 12.5244L24.0207 12.0462L24.7508 10.8048L23.2038 10L22.4868 11.2192ZM17.3061 17.3351L19.9665 13.0009C20.1298 12.7348 20.4907 12.6432 20.7726 12.7963L23.197 14.1129C23.4789 14.2659 23.5749 14.6057 23.4116 14.8718L20.7512 19.206C20.5879 19.4721 20.2271 19.5637 19.9452 19.4106L17.5208 18.094C17.2389 17.941 17.1428 17.6012 17.3061 17.3351ZM19.5597 28.8853C19.4632 28.5298 19.4118 28.1565 19.4118 27.7707C19.4118 27.1132 19.5619 26.489 19.8316 25.9271L22.6575 27.436L23.2415 26.4684L20.4494 24.9776C21.3119 23.962 22.6347 23.3121 24.1176 23.3121C26.7166 23.3121 28.8235 25.3083 28.8235 27.7707C28.8235 28.1565 28.7721 28.5298 28.6756 28.8853H19.5597Z"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#0259A8"
        />
      </svg>
    ),
    inactiveIcon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4868 11.2192L21.6101 10.7466L21.0332 11.6932C20.2539 11.4515 19.3738 11.7494 18.9458 12.4466L16.2854 16.7808C15.7955 17.579 16.0838 18.5984 16.9293 19.0576L17.4284 19.3286L17.0301 20.058L18.603 20.8172L18.9613 20.1611L19.3538 20.3742C20.1994 20.8334 21.282 20.5585 21.7719 19.7603L23.4906 16.9603C24.9982 17.7818 26.0379 18.6991 26.5955 19.7482C27.0649 20.6311 27.2192 21.6575 26.9595 22.8899C26.1171 22.4486 25.1484 22.1975 24.1176 22.1975C22.1943 22.1975 20.4865 23.0721 19.4132 24.4244L12.3038 20.6284L11.7198 21.596L18.8034 25.3781C18.4392 26.1031 18.2353 26.9143 18.2353 27.7707C18.2353 28.1524 18.2758 28.5252 18.3529 28.8853H10.5882C10.2634 28.8853 10 29.1349 10 29.4427C10 29.7505 10.2634 30 10.5882 30H29.5105C29.8254 29.3173 30 28.5633 30 27.7707C30 26.1013 29.2253 24.6033 27.9979 23.5819C28.4458 21.9504 28.3185 20.5115 27.6463 19.2468C26.9491 17.9352 25.7016 16.8801 24.0821 15.9967L24.4324 15.4261C24.8088 14.8128 24.7258 14.0689 24.2836 13.549L24.9079 12.5244L24.0207 12.0462L24.7508 10.8048L23.2038 10L22.4868 11.2192ZM17.3061 17.3351L19.9665 13.0009C20.1298 12.7348 20.4907 12.6432 20.7726 12.7963L23.197 14.1129C23.4789 14.2659 23.5749 14.6057 23.4116 14.8718L20.7512 19.206C20.5879 19.4721 20.2271 19.5637 19.9452 19.4106L17.5208 18.094C17.2389 17.941 17.1428 17.6012 17.3061 17.3351ZM19.5597 28.8853C19.4632 28.5298 19.4118 28.1565 19.4118 27.7707C19.4118 27.1132 19.5619 26.489 19.8316 25.9271L22.6575 27.436L23.2415 26.4684L20.4494 24.9776C21.3119 23.962 22.6347 23.3121 24.1176 23.3121C26.7166 23.3121 28.8235 25.3083 28.8235 27.7707C28.8235 28.1565 28.7721 28.5298 28.6756 28.8853H19.5597Z"
          fill="#0259A8"
        />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#0259A8"
        />
      </svg>
    ),
    listIcon: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.98941 0.975337L9.28809 0.597264L8.82659 1.35458C8.20311 1.1612 7.49901 1.39953 7.15666 1.9573L5.02833 5.42464C4.63638 6.06324 4.86701 6.87871 5.54344 7.24605L5.94273 7.46291L5.62409 8.04636L6.8824 8.65375L7.16903 8.12884L7.48301 8.29934C8.15948 8.66668 9.0256 8.44683 9.41755 7.80823L10.7925 5.5682C11.9986 6.22544 12.8303 6.95932 13.2764 7.79855C13.6519 8.50488 13.7753 9.32601 13.5676 10.3119C12.8937 9.95891 12.1187 9.758 11.2941 9.758C9.75544 9.758 8.38922 10.4576 7.53059 11.5395L1.84301 8.50274L1.37581 9.27683L7.04273 12.3025C6.75134 12.8825 6.58823 13.5315 6.58823 14.2166C6.58823 14.5219 6.62066 14.8202 6.68235 15.1083H0.470588C0.210682 15.1083 0 15.3079 0 15.5541C0 15.8004 0.210682 16 0.470588 16H15.6084C15.8603 15.4539 16 14.8507 16 14.2166C16 12.881 15.3802 11.6827 14.3983 10.8655C14.7566 9.56031 14.6548 8.4092 14.117 7.39746C13.5592 6.34814 12.5613 5.50404 11.2656 4.79736L11.5459 4.34085C11.8471 3.85023 11.7807 3.25515 11.4269 2.83916L11.9264 2.01955L11.2166 1.63692L11.8007 0.643807L10.563 0L9.98941 0.975337ZM5.84489 5.86809L7.97322 2.40071C8.10386 2.18786 8.39256 2.11456 8.61807 2.23703L10.5576 3.29032C10.7831 3.41276 10.86 3.6846 10.7293 3.89745L8.60099 7.36478C8.47035 7.57768 8.18165 7.65098 7.95614 7.5285L6.01661 6.47521C5.79111 6.35278 5.71426 6.08094 5.84489 5.86809ZM7.64776 15.1083C7.57059 14.8238 7.52941 14.5252 7.52941 14.2166C7.52941 13.6906 7.64955 13.1912 7.86532 12.7417L10.126 13.9488L10.5932 13.1747L8.35953 11.9821C9.04955 11.1696 10.1078 10.6497 11.2941 10.6497C13.3733 10.6497 15.0588 12.2466 15.0588 14.2166C15.0588 14.5252 15.0177 14.8238 14.9405 15.1083H7.64776Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  AAI: {
    inactiveIcon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6923 11C11.31 11 11 11.314 11 11.7013V17.078C11 19.939 13.1141 22.2998 15.8462 22.6449V23.3897C15.8462 26.4882 18.3258 29 21.3846 29C24.4434 29 26.9231 26.4882 26.9231 23.3897V22.5999C28.1175 22.2885 29 21.1903 29 19.8832C29 18.3339 27.7602 17.078 26.2308 17.078C24.7014 17.078 23.4615 18.3339 23.4615 19.8832C23.4615 21.1903 24.3441 22.2885 25.5385 22.5999V23.3897C25.5385 25.7135 23.6787 27.5974 21.3846 27.5974C19.0905 27.5974 17.2308 25.7135 17.2308 23.3897V22.6449C19.9628 22.2998 22.0769 19.939 22.0769 17.078V11.7013C22.0769 11.314 21.767 11 21.3846 11H19.0769C18.6946 11 18.3846 11.314 18.3846 11.7013C18.3846 12.0886 18.6946 12.4026 19.0769 12.4026H20.6923V17.078C20.6923 19.4018 18.8326 21.2857 16.5385 21.2857C14.2444 21.2857 12.3846 19.4018 12.3846 17.078V12.4026H14C14.3824 12.4026 14.6923 12.0886 14.6923 11.7013C14.6923 11.314 14.3824 11 14 11H11.6923ZM26.2308 18.4806C26.9955 18.4806 27.6154 19.1085 27.6154 19.8832C27.6154 20.6578 26.9955 21.2857 26.2308 21.2857C25.4661 21.2857 24.8462 20.6578 24.8462 19.8832C24.8462 19.1085 25.4661 18.4806 26.2308 18.4806Z"
          fill="#0259A8"
        />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#0259A8"
        />
      </svg>
    ),
    activeIcon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#0259A8" />
        <path
          d="M11.6923 11C11.31 11 11 11.314 11 11.7013V17.078C11 19.939 13.1141 22.2998 15.8462 22.6449V23.3897C15.8462 26.4882 18.3258 29 21.3846 29C24.4434 29 26.9231 26.4882 26.9231 23.3897V22.5999C28.1175 22.2885 29 21.1903 29 19.8832C29 18.3339 27.7602 17.078 26.2308 17.078C24.7014 17.078 23.4615 18.3339 23.4615 19.8832C23.4615 21.1903 24.3441 22.2885 25.5385 22.5999V23.3897C25.5385 25.7135 23.6787 27.5974 21.3846 27.5974C19.0905 27.5974 17.2308 25.7135 17.2308 23.3897V22.6449C19.9628 22.2998 22.0769 19.939 22.0769 17.078V11.7013C22.0769 11.314 21.767 11 21.3846 11H19.0769C18.6946 11 18.3846 11.314 18.3846 11.7013C18.3846 12.0886 18.6946 12.4026 19.0769 12.4026H20.6923V17.078C20.6923 19.4018 18.8326 21.2857 16.5385 21.2857C14.2444 21.2857 12.3846 19.4018 12.3846 17.078V12.4026H14C14.3824 12.4026 14.6923 12.0886 14.6923 11.7013C14.6923 11.314 14.3824 11 14 11H11.6923ZM26.2308 18.4806C26.9955 18.4806 27.6154 19.1085 27.6154 19.8832C27.6154 20.6578 26.9955 21.2857 26.2308 21.2857C25.4661 21.2857 24.8462 20.6578 24.8462 19.8832C24.8462 19.1085 25.4661 18.4806 26.2308 18.4806Z"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#0259A8"
        />
      </svg>
    ),
    listIcon: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.35389 0.799805C1.04801 0.799805 0.800049 1.05099 0.800049 1.36084V5.66217C0.800049 7.95097 2.49132 9.83963 4.67697 10.1157V10.7115C4.67697 13.1903 6.66069 15.1998 9.10774 15.1998C11.5548 15.1998 13.5385 13.1903 13.5385 10.7115V10.0798C14.494 9.83064 15.2 8.952 15.2 7.90633C15.2 6.66692 14.2082 5.66219 12.9847 5.66219C11.7611 5.66219 10.7693 6.66692 10.7693 7.90633C10.7693 8.952 11.4753 9.83064 12.4308 10.0798V10.7115C12.4308 12.5706 10.943 14.0777 9.10774 14.0777C7.27246 14.0777 5.78466 12.5706 5.78466 10.7115V10.1157C7.97031 9.83963 9.66159 7.95097 9.66159 5.66217V1.36084C9.66159 1.05099 9.41362 0.799805 9.10774 0.799805H7.26159C6.95571 0.799805 6.70774 1.05099 6.70774 1.36084C6.70774 1.67069 6.95571 1.92187 7.26159 1.92187H8.55389V5.66217C8.55389 7.52127 7.0661 9.02837 5.23082 9.02837C3.39553 9.02837 1.90774 7.52127 1.90774 5.66217V1.92187H3.20005C3.50593 1.92187 3.75389 1.67069 3.75389 1.36084C3.75389 1.05099 3.50593 0.799805 3.20005 0.799805H1.35389ZM12.9847 6.78426C13.5964 6.78426 14.0924 7.28663 14.0924 7.90633C14.0924 8.52603 13.5964 9.0284 12.9847 9.0284C12.3729 9.0284 11.877 8.52603 11.877 7.90633C11.877 7.28663 12.3729 6.78426 12.9847 6.78426Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  OTHERS: {
    activeIcon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#0259A8" />
        <g clip-path="url(#clip0_3505_210531)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.7381 11.7461H12.2619C11.977 11.7461 11.746 11.9771 11.746 12.262V27.7382C11.746 28.0231 11.977 28.254 12.2619 28.254H27.7381C28.023 28.254 28.254 28.0231 28.254 27.7382V12.262C28.254 11.9771 28.023 11.7461 27.7381 11.7461ZM12.2619 10.7144C11.4072 10.7144 10.7143 11.4073 10.7143 12.262V27.7382C10.7143 28.5929 11.4072 29.2858 12.2619 29.2858H27.7381C28.5929 29.2858 29.2857 28.5929 29.2857 27.7382V12.262C29.2857 11.4073 28.5929 10.7144 27.7381 10.7144H12.2619Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9683 25.1583V14.8408H21.0318V25.1583H18.9683Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.1587 21.0327L14.8412 21.0325L14.8413 18.969L25.1588 18.9693L25.1587 21.0327Z"
            fill="white"
          />
        </g>
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#0259A8"
        />
        <defs>
          <clipPath id="clip0_3505_210531">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(10 10)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    inactiveIcon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3365_209630)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.7381 11.7461H12.2619C11.977 11.7461 11.746 11.9771 11.746 12.262V27.7382C11.746 28.0231 11.977 28.254 12.2619 28.254H27.7381C28.023 28.254 28.254 28.0231 28.254 27.7382V12.262C28.254 11.9771 28.023 11.7461 27.7381 11.7461ZM12.2619 10.7144C11.4072 10.7144 10.7143 11.4073 10.7143 12.262V27.7382C10.7143 28.5929 11.4072 29.2858 12.2619 29.2858H27.7381C28.5929 29.2858 29.2857 28.5929 29.2857 27.7382V12.262C29.2857 11.4073 28.5929 10.7144 27.7381 10.7144H12.2619Z"
            fill="#0259A8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9683 25.1583V14.8408H21.0318V25.1583H18.9683Z"
            fill="#0259A8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.1587 21.0327L14.8412 21.0325L14.8413 18.969L25.1588 18.9693L25.1587 21.0327Z"
            fill="#0259A8"
          />
        </g>
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#0259A8"
        />
        <defs>
          <clipPath id="clip0_3365_209630">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(10 10)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    listIcon: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1905 1.39669H1.80951C1.58158 1.39669 1.39681 1.58146 1.39681 1.80938V14.1903C1.39681 14.4183 1.58158 14.603 1.80951 14.603H14.1905C14.4184 14.603 14.6032 14.4183 14.6032 14.1903V1.80938C14.6032 1.58145 14.4184 1.39669 14.1905 1.39669ZM1.80951 0.571289C1.12573 0.571289 0.571411 1.1256 0.571411 1.80938V14.1903C0.571411 14.8741 1.12573 15.4284 1.80951 15.4284H14.1905C14.8743 15.4284 15.4286 14.8741 15.4286 14.1903V1.80938C15.4286 1.1256 14.8743 0.571289 14.1905 0.571289H1.80951Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
};
