import React from 'react';
import { getString } from 'util/lang';
import { FiCheck } from 'vyaguta-icons/fi';
import AlertMessage from 'components/COVID/common/AlertMessage';

const NotificationPreferences = (props) => {
  const { patientInfo, onChange, clientName } = props;

  return (
    <>
      <h3 className="mb-0x">{getString('notificationPreferences')}</h3>
      <div className="row">
        <div className="custom-check mb-3x pt-3x col-3-md mr-3x">
          <input
            tabIndex="0"
            autoComplete="chrome-off"
            type="checkbox"
            checked={patientInfo.smsNotificationConsent || false}
            id="smsNotificationConsent"
            data-qa="sms-notification-consent"
            onChange={(e) =>
              onChange('smsNotificationConsent', e.target.checked)
            }
          />
          <label htmlFor="smsNotificationConsent">
            <FiCheck /> {getString('smsConsentTitle')}
          </label>
        </div>
        <div className={`custom-check mb-3x pt-3x col-4-md }`}>
          <input
            tabIndex="0"
            autoComplete="chrome-off"
            type="checkbox"
            checked={patientInfo.emailNotificationConsent || false}
            id="emailNotificationConsent"
            data-qa="email-notification-consent"
            onChange={(e) =>
              onChange('emailNotificationConsent', e.target.checked)
            }
          />
          <label htmlFor="emailNotificationConsent">
            <FiCheck /> {getString('emailConsentTitle')}
          </label>
        </div>
      </div>

      <AlertMessage
        type="info"
        message={
          <div>
            {patientInfo.smsNotificationConsent ||
            patientInfo.emailNotificationConsent ? (
              <>
                <div className="mb-4x">
                  {getString('notificationPreferencesMessage1')}
                </div>
                <div className="custom-check custom-check--disabled my-2x mr-3x color-info">
                  <label>
                    <FiCheck />{' '}
                    {getString('notificationPreferencesConsentDefault')}
                  </label>
                </div>
                <div className="custom-check my-2x mr-3x mb-4x color-info">
                  <input
                    tabIndex="0"
                    autoComplete="chrome-off"
                    type="checkbox"
                    checked={patientInfo.marketingNotificationConsent || false}
                    id="marketingNotificationConsent"
                    data-qa="marketing-notification-consent"
                    onChange={(e) =>
                      onChange('marketingNotificationConsent', e.target.checked)
                    }
                  />
                  <label htmlFor="marketingNotificationConsent">
                    <FiCheck />{' '}
                    {getString('notificationPreferencesConsentMarketing')}
                  </label>
                </div>
              </>
            ) : null}

            <div>
              <strong>{clientName}</strong>{' '}
              {getString('notificationPreferencesMessage2')}
            </div>
          </div>
        }
        className="mt-2x"
        dataqa="notification-consent-alert"
        isVisible={true}
      />
    </>
  );
};

export default NotificationPreferences;
