const MapMarkerIcon = (props) => {
  return (
    <svg
      onClick={props.onClick}
      className={props.className}
      width={props.size}
      height={props.size}
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.65398 24.5262L5.65331 24.5255C3.14656 22.0187 1.73828 18.6189 1.73828 15.0738C1.73828 11.5287 3.14656 8.12881 5.65331 5.62206C8.16007 3.11531 11.56 1.70703 15.105 1.70703C18.6501 1.70703 22.05 3.11531 24.5568 5.62206C27.0522 8.1176 28.4542 11.5023 28.4542 15.0315C28.4542 18.5607 27.0522 21.9453 24.5567 24.4408L24.5554 24.4422L15.5649 33.4666L15.5633 33.4682C15.4989 33.5331 15.4222 33.5847 15.3377 33.6199C15.2533 33.6551 15.1627 33.6732 15.0712 33.6732C14.9797 33.6732 14.8891 33.6551 14.8046 33.6199C14.7201 33.5847 14.6435 33.5331 14.579 33.4682L14.5768 33.4659L5.65398 24.5262Z"
        fill="#DB4211"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="15.1291" cy="15.0979" r="5.92598" fill="#300000" />
    </svg>
  );
};

export default MapMarkerIcon;
