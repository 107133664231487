import React from 'react';
import DatePicker from 'react-datepicker';

const DateInput = (props) => {
  const {
    id,
    value,
    required = false,
    disableInput = false,
    handleDateChange,
    hasError = false,
    isVisible = true,
    solo = false,
    label,
  } = props;
  const displayStyle = isVisible
    ? { display: 'block', width: '100%' }
    : { display: 'none' };
  const labelClassName =
    'label-placeholder' +
    (required ? ' label-placeholder--required' : '') +
    (value && !disableInput ? ' label-placeholder--input-filled' : '');

  // Disallow keyboard input
  const disableAction = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className={`form-group mb-0x${hasError ? ' has-error' : ''} calendar calendar-v2`}
      style={displayStyle}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <DatePicker
          id={id}
          name={id}
          selected={value}
          maxDate={new Date()}
          disabled={disableInput}
          className={`form-control ${solo ? 'solo' : ''}`}
          onChange={(date) => handleDateChange(id, date)}
          onChangeRaw={(e) => disableAction(e)}
          disabledKeyboardNavigation={true}
        />
        {!disableInput && (
          <label className={labelClassName} htmlFor={id}>
            {label}
          </label>
        )}
      </form>
    </div>
  );
};

export default DateInput;
