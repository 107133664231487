import styles from './YesNoCheckboxes.module.scss'; // Assuming styles are defined in a CSS module

/**
 * Generic RadioOptions component to render dynamic radio buttons.
 * @param {string} name - The name of the radio group.
 * @param {Array<{id: string, label: string, value: any}>} options - Array of radio option objects.
 * @param {any} selectedValue - The currently selected value.
 * @param {function} onChange - Handler for when a radio option is selected.
 * @param {boolean} disabled - Optional prop to disable the radio buttons.
 */
const RadioCheckBox = ({
  name,
  options,
  selectedValue,
  onChange,
  disabled = false,
}) => {
  return (
    <div className={styles.radioWrap}>
      {options.map((option) => (
        <span key={option.id} className={styles.radio}>
          <input
            autoComplete="off"
            type="radio"
            name={name}
            value={option.value}
            disabled={disabled}
            checked={selectedValue === option.value}
            className={styles.radioInput}
            onChange={() => onChange(option.value)}
          />
          <label htmlFor={option.id}>
            <span className={styles.radioText}>{option.label}</span>
          </label>
        </span>
      ))}
    </div>
  );
};

export default RadioCheckBox;
