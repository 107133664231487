import React from 'react';
import parse from 'html-react-parser';
import { FiCheck } from 'vyaguta-icons/fi';

import { getString } from 'util/lang';

const TermsAndConditions = (props) => {
  const { onChange, value, localizedWebContents = [] } = props;

  const dataTargetTagName = 'data-qa-patient-info-terms-and-condition';

  const { termsAndCondition = {}, termsAndConditionsAcceptance = {} } =
    localizedWebContents[0];
  return (
    <section>
      <h2>{getString('termsAndCondition')}</h2>
      <div className="terms-conditions section__margin border border-radius">
        {termsAndCondition || termsAndConditionsAcceptance ? (
          <form className="terms-conditions__section">
            {termsAndCondition && (
              <div className="terms-condition-list">
                {parse(termsAndCondition)}
              </div>
            )}
            {!!termsAndConditionsAcceptance.length && (
              <div
                data-dyn-count={termsAndConditionsAcceptance.length}
                data-dyn-target-name={dataTargetTagName}
                className="terms-condition-check"
              >
                {termsAndConditionsAcceptance.map((acceptanceItem, index) => {
                  const isLastItem =
                    index === termsAndConditionsAcceptance.length - 1;
                  return (
                    <div
                      key={acceptanceItem.id}
                      className={`custom-check ${!isLastItem ? 'mb-3x' : ''}`}
                    >
                      <input
                        type="checkbox"
                        id={acceptanceItem.id}
                        onChange={(e) =>
                          onChange(acceptanceItem.id, e.target.checked)
                        }
                        checked={value[acceptanceItem.id]}
                        data-qa={`terms-condition-${index}`}
                      />
                      <label id="term-label" htmlFor={acceptanceItem.id}>
                        <FiCheck />
                        {parse(acceptanceItem.label)}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </form>
        ) : null}
      </div>
    </section>
  );
};

export default TermsAndConditions;
