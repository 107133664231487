import { useDetailContext } from 'context';
import styles from './PharmacyInfo.module.scss';
import { joinValues } from 'util/string';
import { contactMasking } from 'util/contactFormat';

const PharmacyInfo = ({ className }) => {
  const context = useDetailContext();
  const {
    organizationName,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    organizationContactPhone,
  } = context.getAppSettings();

  return (
    <div className={`${styles.pharmaInfo} ${className}`}>
      <h3>{organizationName}</h3>
      <p>
        {joinValues(
          [billingAddress, billingCity, billingState, billingZipCode],
          ', ',
        )}
      </p>
      <p>{contactMasking(organizationContactPhone)}</p>
    </div>
  );
};

export default PharmacyInfo;
