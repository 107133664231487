import { useCallback, useMemo } from 'react';
import { useApplicationContext, useDetailContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import {
  makeFollowupRegisterNavLinks,
  makeFollowupRegisterNavLinksWithNoPaymentOption,
} from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { ScreeningAndEligibility } from '../../PatientEligibility/container/PatientEligibilityWrapper';
import { changeTitle } from 'util/siteInfo';

export const RegisterScreening = () => {
  changeTitle('Follow Up | Screening');
  const {
    details: { pageComplete, selectedServices, selectedLocationId },
  } = useDetailContext();

  const { isPaymentOptionEnabled } = useApplicationContext();

  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();

  const toNextPage = useCallback(() => {
    nav.toFollowup.register.patientInfo(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      isPaymentOptionEnabled
        ? makeFollowupRegisterNavLinks(
            pageComplete,
            appointmentId,
            organizationId,
          )
        : makeFollowupRegisterNavLinksWithNoPaymentOption(
            pageComplete,
            appointmentId,
            organizationId,
          ),
    [organizationId, pageComplete, appointmentId],
  );

  if (!selectedServices.length && !selectedLocationId) {
    nav.resetToFirstPage();

    return null;
  }

  const goBack = () => nav.goBack();

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ScreeningAndEligibility
        isSelfDeclarationDisabled={true}
        organizationId={organizationId}
        id={appointmentId}
        goBack={goBack}
        toNextPage={toNextPage}
        disableEdit={true}
        disabledFieldStatus={{
          firstName: true,
          middleName: true,
          lastName: true,
        }}
      />
    </PageWrapper>
  );
};
