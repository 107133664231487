export function isTrueOrNull(value) {
  if (value === true || value === null) {
    return true;
  }

  return false;
}

export function isFalseOrUndefined(value) {
  if (value === false || value === undefined) {
    return true;
  }

  return false;
}
