const language = {
  continue: `继续`,
  continueTitle: `按enter键继续下一步`,
  confirm: `确认`,
  ok: `OK`,
  yes: `是`,
  no: `否`,
  or: `或者`,
  contactUs: `联系我们`,
  organizationLogo: `组织的徽标`,
  refresh: `刷新`,
  select: `选择`,
  submit: `提交`,
  cancel: `取消`,
  submitTitle: `按Enter键提交您的表格 `,
  submitAndContinue: `提交并继续`,
  errorMessage: `出了点问题，请稍后再试。`,
  notify: `通知我`,
  pleaseWaitLoading: `请稍候`,
  error: `错误`,
  highRequestError: `似乎有太多人正在尝试预约。 请在2分钟后重试。`,
  clearingSessionHeader: `清空您的内容`,
  clearingSessionDescription: `您已停止操作一段时间，为了安全起见，我们将很快清空您的内容。`,
  stay: `不要离开`,
  loading: `加载中 `,
  optOut: `不参与`,
  personalIdInfo: `#个人ID信息#`,
  doseNumberFirst: `第一`,
  doseNumberSecond: `第二`,
  firstDose: `第一剂`,
  secondDose: `第二剂`,
  dateAtTime: `{0}的{1}`,
  firstName: `名字`,
  middleName: `中间名`,
  lastName: `姓`,
  emailAddress: `电子邮寄地址`,
  emailAddressConfirm: `确认电子邮件地址`,
  insurance: `#保险#`,
  insuranceCompany: `#健康保险公司#`,
  insuranceCompanyName: `#保险公司名称#`,
  memberId: `#会员ID#`,
  groupNumber: `#组号#`,
  rxBin: `接收箱`,
  rxPCN: `接收 PCN`,
  rxGroup: `接收組`,
  checkInsurance: `#查看保险信息#`,
  idType: `#证件类型#`,
  idNumber: `#身份证号码#`,
  noId: `#我没有身份证#`,
  homeAddress: `地址`,
  aptUnit: `公寓/单位`,
  city: `城市`,
  state: `州`,
  zip: `邮政编码`,
  birthMonth: `出生月`,
  day: `出生日`,
  birthDay: `出生日期`,
  year: `出生年份`,
  birthYear: `出生年份`,
  ethnicity: `族裔`,
  race: `种族`,
  gender: `性别`,
  primaryLanguage: `主要語言（包括ASL美國手語）`,
  mobileNumber: `手机号码`,
  referringOrganization: `转介组织`,
  phoneNumber: `电话号码`,
  age: `年龄`,
  firstNameRequired: `名字不能留空`,
  firstNameValidationMessage: `名字只能包含大写（A-Z）、小写（A-Z）、单引号、破折号、句号和空格`,
  middleNameValidationMessage: `中间名只能包含大写（A-Z）、小写（A-Z）、单引号、破折号、句号和空格`,
  lastNameRequired: ` 姓氏不能留空`,
  lastNameValidationMessage: `姓氏只能包含大写（A-Z）、小写（A-Z）、单引号、破折号、句号和空格`,
  emailRequired: `电子邮件地址不能留空`,
  emailRequired2: `电子邮件地址必须填写`,
  emailValidationMessage: `电子邮件地址无效`,
  emailValidationMessageNoMatch: `您提供的电子邮件地址与确认电子邮件地址上填写的不符`,
  confirmEmailRequired: `确认电子邮件地址不能留空`,
  confirmEmailValidationMessage: `确认电子邮件地址无效`,
  insuranceDetailsRequired: `#必须从给定列表中选择保险公司，或选择“无保险”选项#`,
  insuranceNameRequired: `#请提供保险公司名称#`,
  memberIdRequired: `#会员编号不能为空#`,
  memberIdValidationMessage: `#会员ID必须为数字或字母数字（可能包含特殊字符）#`,
  insuranceIdTypeRequired: `#ID类型不能为空#`,
  insuranceIdNumberRequired: `#识别号不能为空#`,
  insuranceIdNumberValidationMessage: `#识别码必须是数字或字母数字（可以包含特殊字符）#`,
  insuranceStateRequired: `#状态不能为空#`,
  insuranceValidationMessageNotOnList: `#必须从给定列表中选择保险公司，或选择“无保险”选项#`,
  checkEligibility: `检查资格`,
  pharmacyInsuranceExtraInfoText: '如果您的卡有以下任何信息，请在下方提供',
  isNotEligibleInsuranceMessage: `患者不符合保险资格。请编辑信息并重新提交`,
  isEligibleInsuranceMessage: `患者有资格获得保险`,
  monthRequired: `请选择月份`,
  dayRequired: `请选择一天`,
  dayValidationMessage: `天数必须在1至31之间 `,
  yearRequired: `请选择年份`,
  yearValidationMessage: `年份不能早于1900年`,
  genderRequired: `性别不能留空`,
  mobileNumberRequired: `手机号码不能留空`,
  mobileNumberValidationMessage: `仅以数字输入手机号码`,
  mobileNumberValidationMessageMin: `手机号码必须至少包含10位数字 `,
  mobileNumberValidationMessageMax: `手机号码只能包含10位数字`,
  raceRequired: `种族不能留空`,
  ethnicityRequired: `族裔不能留空`,
  referralValidationMessage: `转介人名称不能超过100个字符`,
  aptUnitValidationMessage: `公寓/单元无效`,
  cityRequired: `城市不能留空`,
  cityValidationMessage: `城市名称无效`,
  stateRequired: `州不能留空`,
  zipRequired: `邮编编码不能留空 `,
  zipValidationMessageMin: `邮政编码必须包含5位数字 `,
  zipValidationMessageMax: `邮政编码只能包含5位数字`,
  zipValidationMessage: `邮政编码必须是数字`,
  homeAddressRequired: `家庭地址不能留空`,
  homeAddressValidationMessage: `家庭地址无效`,
  ageRequired: `年龄须填`,
  ageValidationMessage: `年龄必须是数字`,
  ageValidationMessagePositiveNumber: `年龄必须是正数 `,
  ageValidationMessageMin: `目前我们不向16岁以下的人提供COVID-19疫苗接种`,
  ageValidationMessageMax: `目前我們不向150歲以上的人提供COVID-19疫苗接种 `,
  ageValidationMessageUnder19: `目前我们不向未满19岁的人提供COVID-19疫苗接种 `,
  optOutEmail: `如果您不向我们提供您的电子邮件地址，您将无法通过电子邮件获得您的预约确认。`,
  optOutPhone: `如果您不向我们提供您的电话号码，您将无法收到您的预约的短信确认。`,
  optOutPhoneAndEmail: `如果您不向我们提供您的电子邮件地址和电话号码，您将无法更新您的预约。如需更新您的预约，请联系我们。`,
  patientEligibility: `患者资格`,
  info: `健康筛查`,
  clinicAndTiming: `诊所&时间安排`,
  patientInfoAndConfirm: `患者信息`,
  selectClinicAndTiming: `选择诊所和时间`,
  genderMale: `男性`,
  genderFemale: `女性`,
  genderTrans: `跨性别`,
  genderNonBinary: `非二进制`,
  genderOther: `其他/我不想说`,
  raceAsian: `亚裔`,
  raceNative: `美国印第安人或阿拉斯加原住民`,
  raceBlack: `黑人或非裔美国人`,
  racePacific: `夏威夷原住民或其他太平洋岛民`,
  raceWhite: `白人`,
  raceOther: `其他种族`,
  raceNoPreference: `我不想说`,
  ethnicityHispanic: `西班牙裔/拉丁裔`,
  ethnicityNotHispanic: `非西班牙裔/拉丁裔`,
  ethnicityNoPreference: `我不想说`,
  driversLicense: `#驾驶执照#`,
  otherStateId: `#其他州ID#`,
  januaryShort: `1 月`,
  februaryShort: `2 月`,
  marchShort: `3月`,
  aprilShort: `4月`,
  mayShort: `5月`,
  juneShort: `6月`,
  julyShort: `7月`,
  augustShort: `8月`,
  septemberShort: `9月`,
  octoberShort: `10月`,
  novemberShort: `11 月`,
  decemberShort: `12 月`,
  signatureHelp: `使用鼠标或触摸屏在下面的方框中添加签名。`,
  signaturePlaceholder: `点击添加签名`,
  signatureUnder18Title: `监护人签名`,
  patientSignature: `患者签名`,
  signatureTooLong: `签名过长`,
  noAppointmentsAvailable: `没有预约`,
  nAppointmentsAvailable: `可预约`,
  confirmationCodeHeading: `您的确认号码`,
  confirmationCodeMessage: `请带上此号码，以便我们的工作人员能迅速为您办理登记手续。`,
  privacyPolicy: `隐私政策`,
  poweredBy: `。。。提供技术支持`,
  signeticMVS: `Signetic为西雅图的大规模疫苗接种站点提供技术支持 `,
  reCaptchaMessage: `请勾选上面的方框继续`,
  noSlotsAvailable: `没有预约名额`,
  oneSlotAvailable: `有一个名额`,
  nSlotsAvailable: `有{0} 个名额`,
  errorBookingHeader: `请不要离开`,
  stayWithUs: `请不要离开`,
  slotTaken: `抱歉，这个名额刚已被占用了。请尝试预约另一个时段。`,
  bookingTroubleQuestion: `预约有困难吗？`,
  confirmAndSubmit: `确认并提交`,
  appointmentBookProgress: `我们正在为您安排预约，请稍候。。。`,
  rescheduleAppointmentTitle: `请按enter键重新预约`,
  appointmentSelected: `选择的预约时间是`,
  appointmentSelectedSingleDose: `您选择的预约时间是`,
  appointmentCancelledMessage: `您的预约已被取消。 如果您想进行另一个预约，请点击下面的按钮。`,
  bookAppointment: `进行预约`,
  bookAppointmentTitle: `按enter键另外预约`,
  mapAlt: `地图`,
  selectedClinicWithAppointment: `您选择的预约诊所`,
  doubleDoseVaccineAdministrationMessage: `第一剂和第二剂疫苗应在同一诊所接种 `,
  vaccineDose: `疫苗剂量 `,
  currentClinics: `附近现有的诊所`,
  viewMap: `在地图中查看`,
  secondVaccineAppointmentInfo: `第二剂疫苗接种只能在第一剂接种的{ 0 }天之后才可进行，请确保您同时做好两个预约。我们无法保证可以重新安排预约。`,
  singleVaccineAppointmentInfo: `请确保您做好预约，我们无法保证可以重新安排预约。`,
  dateFetchProgress: `我们正在搜索可用日期，请稍候。。。`,
  noDatesAvailableSingleDose: `没有可选用的日期，请选择其他诊所。`,
  noDatesAvailableDoubleDose: `没有接种第一剂的可选用日期，请选择其他诊所。`,
  firstAppointmentDate: `您的第一个预约日期是{0}的{1} `,
  doubleDosePickerMessage: `请为您的第一剂疫苗预约选择附近的诊所、日期和时间。`,
  appointmentsEmpty: `没有预约 `,
  registerVaccineHeader: `疫苗预约注册`,
  registerVaccineMessage: `请选择不同的按钮来查看所有可选用的预约`,
  scheduleAppointmentHeader: `预约的详细信息`,
  chooseAppointmentDateTime: `选择预约的日期和时间`,
  chooseAppointmentDateTimeTitle: `请按enter键选择预约的日期和时间 `,
  selectedClinic: `您选择的诊所是`,
  submitAppointmentMessage: `您还没有完成，您仍然需要确认并提交才能安排预约。`,
  updateAppointmentMessage: `请在您方便时更新您的预约详细信息。`,
  selectedAppointment: `您的{0} 预约日期和时间是`,
  guardianHeader: `如果未满18岁，父母或监护人必须在上面签字`,
  guardianName: `父母/监护人姓名`,
  informationHeader: `重要信息`,
  highVolumeMessage: `感谢您关注{0}的COVID-19诊所。 目前我们正在处理大量的请求，并将按收到请求的顺序答复查询。`,
  existingInquiry: `如果您要查询现有的预约，我们将尽快与您联系。`,
  contactLabel: `联系人`,
  allAcceptedAlertMessage: `让我们为您接种疫苗！ `,
  availability: `可选用的预约`,
  screeningQuestions: `筛选问题`,
  selfDeclaration: `自我申报标准`,
  selfDeclarationAlert: `请填写自我申报标准，继续进行注册。`,
  eligibleAssessmentMessage: `我，{0}证明我有资格参加此阶段的疫苗接种，并同意仅在我满足上述所有条件时才能继续注册。`,
  contactUsSuccessMessage: `我们会尽快回复您。`,
  contactDetailsHeader: `请提供您的联系方式，我们将尽快与您联系。`,
  noAppointments: `没有预约`,
  oneAppointment: `一个预约`,
  nAppointments: `{0}个预约`,
  noClinics: `没有诊所`,
  oneClinic: `一个诊所`,
  nClinics: `{0} 个诊所`,
  siteAvailabilityHeader: `可预约的疫苗接种站点`,
  siteAvailabilityMessage: `目前附近有{0}个{1}可选用。`,
  eligibilityQuestionsHeader: `资格筛查问题`,
  eligibilityQuestionsMessage: `你是否属于下列任何一类有资格接种疫苗的人？`,
  notifyFailure: `通知失败 `,
  provideContactInfoAlert: `向我们提供您的联系信息，以便接收短信和电子邮件通知，以及预约管理工具。`,
  underEighteenAlert: `目前我们不向未满18岁的人提供COVID-19疫苗接种`,
  underSixteenAlert: `目前我们不向未满16岁的人提供COVID-19疫苗接种`,
  detailsConfirmAlert: `请确认您的所有详细信息正确无误，然后继续。`,
  selectClinicAndTimingButton: `选择诊所和时间`,
  selectClinicAndTimingButtonTitle: `按Enter键提供诊所和时间信息 `,
  creditCardInfo: `#信用卡资料#`,
  creditCardUnavailable: `#我们目前不接受信用卡，但是很快！点击通知我。#`,
  notifyCardPaymentTitle: `#按Enter即可通知我有关信用卡付款的信息#`,
  notificationConsent: `通过勾选此框，我同意与我的预约有关的所有{0}。`,
  notificationConsentLink: `数字通信条款`,
  notificationConsentModalHeader: `《数字通信条款》`,
  notificationConsentModalMessageA: `A.我同意{0}和/或其承包商通过电子邮件和/或标准短信与我沟通我的预约。会产生标准短信费用。`,
  notificationConsentModalMessageB: `B.通过电子邮件和短信发送的通信未加密。我了解未加密电子邮件和标准短信的风险，并在此允许通过电子邮件和标准短信发送我的预约信息。`,
  patientAddress: `患者住址`,
  patientInfo: `患者信息`,
  guardianInfo: `监护人信息`,
  guardianInfoAlert: `如果您未满19岁，我们需要收集有关您的监护人的信息。`,
  guardianConsent: `我已被授权同意上述患者接受此疫苗。 我要求将疫苗接种给上述患者。 我了解，患者应在接受疫苗后在疫苗位置停留15至30分钟，以监测可能发生的与疫苗相关的直接反应和副作用，并在需要时接受医疗干预。`,
  here: 'here',
  paymentType: `#付款方式#`,
  paymentInfoInsurance: `#{0}或任何健康保险公司都不会向您收取COVID-19疫苗接种费用。但是，我们会收集保险信息以确保为未投保患者提供资金。#`,
  paymentInfoNoInsurance: `#我们有你覆盖。让我们为您接种疫苗。#`,
  creditCard: `#信用卡#`,
  noInsurance: `#没有保险#`,
  insuranceValidationAlert: `#我们无法验证您的保险。请检查您使用的保险当前有效，并输入正确的详细信息。#`,
  correctInfoContinueAlert: `#如果您认为您的信息正确无误，请继续注册并带上您的保险卡。#`,
  insuranceInfoConfirmedText: `#保险信息已被确认并确认为有效。#`,
  costOfService: '服务成本',
  payAtTimeOfService: '在服务时付款',
  factSheet: `{0} ( 紧急使用授权 )说明书`,
  termsAndCondition: `条款与条件`,
  termsAndConditionType1: `我已阅读{0}，并同意其条款和条件。 我想授权。`,
  termsAndConditionType2: `我已经收到有关{0}的信息。`,
  cdcVsafe: `CDC（疾病控制与预防中心）的VSafe计划 `,
  securityCheckHeader: `请填写以下信息以便重新安排/取消您的预约`,
  securityCheckAlert: `我们需要这些信息来核实您的身份。`,
  securityCheckValidationAlert: `您提供的信息与您的预约注册不符。`,
  emailNotice: `也请查看确认短信和电子邮件以获取更多信息 `,
  appointmentIdNotFound: `找不到预约ID`,
  appointmentIdEmptyCancellation: `缺少操作ID，不能取消预约`,
  print: `打印`,
  appointmentCancellationHeader: `您确定要取消预约吗？`,
  appointmentCancellationDescription: `请注意，取消预约将删除您提供的所有信息。`,
  cancelAppointment: `取消预约`,
  doNotCancel: `不要取消`,
  bookAnother: `再次预约`,
  singleDoseCancellationDetailsAppointment: `......的预约 `,
  doubleDoseCancellationDetailsAppointment: `第{0}剂的预约`,
  appointmentWithVaccineCancelled: `{0}疫苗接种预约已被取消 `,
  confirmReschedule: `确认重新预约 `,
  singleDoseReschedule: `安排另外一个预约`,
  doubleDoseReschedule: `重新预约第{0} 剂`,
  vaccineAppointmentReschedule: `{0}疫苗接种预约？`,
  waitListedDescription: `已将您列入了于{1} 在{2}接种{0} 的候补名单`,
  dose: `剂`,
  singleDoseAppointmentConfirmation: `Your {vaccineName} vaccine appointment has been confirmed for {patientName} on {dateAndTime} at {location}`,
  doubleDoseAppointmentConfirmation: `Your {doseNumber} dose of {vaccineName} vaccine appointment has been confirmed for {patientName} on {dateAndTime} at {location}`,
  rescheduleButton: `重新预约`,
  rescheduleModalMessageFirstDose: `请注意，重新安排第一剂疫苗接种的预约也将自动重新安排您的第二剂的预约。`,
  rescheduleModalMessageSecondDose: `请注意，只有在完成第一剂疫苗接种28天后才需要重新安排第二剂的预约。  `,
  rescheduleModalAlert: `只有在第一剂({1})接种{0}天后才能进行第二剂的预约。`,
  waitListNotice: `根据我们网站的规定，您已经被列入第二剂疫苗的候补名单。 一旦我们能够确认您的第二剂疫苗接种预约，我们将尽快与您联系。 感谢您的耐心等待。`,
  underMaintenance: `正在维护`,
  underMaintenanceHeader: `抱歉。我们正在{0}`,
  upTimeMessage: `我们正在努力使网站{0}重新运作`,
  underMaintenanceContactInfo: `如有任何疑问，请{0}`,
  asSoon: `尽快`,
  emailUs: `发送电子邮件至`,
  uptimeByTimeDate: `在 {0} {1} 前`,
  callUsAt: `call us at`,
  clinicNotFoundForAppointment: `找不到此预约的诊所 `,
  appointmentsTaken: `很遗憾，所有名额已满。`,
  waitListSignUpMessage: `请登记加入我们的候补名单。加入候补名单后，当有预约可以安排时，系统会通知您，并为您提供进行预约的说明。`,
  joinWaitList: `加入候补名单`,
  appointmentsUnavailable: `没有预约`,
  appointmentsTakenDescription: `抱歉，所有名额已满。请尝试另一站点或加入我们的候补名单。加入候补名单后，当有预约可以安排时，系统会通知您，并为您提供进行预约的说明。`,
  availabilityAllCaps: `名额`,
  noClinicsAvailable: `所有站点的名额已满`,
  appointmentsBooked: `我们所有的预约已满`,
  appointmentsBookedDescription: `当有疫苗可用时，我们将推出新的预约。请稍后再来预约。有关详细信息，请{0}`,
  appointmentsBookedDescriptionNotify: `当有疫苗可用时，我们将推出新的预约。加入我们的候补名单，当有预约名额时，系统会通知您，并为您提供进行预约的说明。`,
  contactUsSmallCase: `联系我们`,
  contactMethod: `联系方式`,
  contactMethodDescription: `您希望我们用哪种联系方式通知您进行预约？`,
  waitListInfoHeader: `疫苗接种部署`,
  waitListInfoSubHeader: `{0}正在部署整个地区的大规模疫苗推广。`,
  waitListInfoDescription: `我们的所有预约名额已满。当有更多疫苗可用时，我们将推出新的预约。不用担心，您仍然可以加入我们的候补名单，当有预约名额时，系统会通知您，并为您提供进行预约的说明。`,
  aboutYouHeader: `介绍一下你自己`,
  fillDetails: `请在下面填写您的详细信息。`,
  waitListed: `已加入候补名单`,
  waitListAddedMessage: `您已加成功加入候补名单`,
  waitListAddedDescription: `当我们能确认您的接种预约时，我们会立刻通知您。感谢您的耐心。`,
  getStarted: `开始吧`,
  codeRegistration: `注册`,
  codeRegistrationHeader: `该注册页面专门预留给有邀请的人。如果您使用的是8位数字的密码，请在下面的方框中输入该密码，并开始注册。`,
  singUseCodeSuccessMessage: `注册成功！您现在可以安排您的接种预约了。请注意，此邀请代码只能用于为一个患者安排疫苗。`,
  codeRegistrationValidationMessage: `该代码不能含有特殊字符，请检查并重试。`,
  codeRegistrationValidationMessageLength: `验证码必须为8个字符长，请检查并重试。`,
  prepaidByOrganization: '{0} 组织已经为此服务付款。',
};

export default language;
