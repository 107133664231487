import React from 'react';
import ActivityIndicator from './ActivityIndicator';

function FullPageLoader() {
  return (
    <div
      className="maintenance-page d-flex flex-column justify-content-between"
      style={{ height: '100vh' }}
    >
      <ActivityIndicator />
    </div>
  );
}

export default FullPageLoader;
