import React, { useContext, useEffect } from 'react';
import { NavigationContext } from './context';
import styles from './SideNavBar.module.scss';

export const Menu = (props) => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(`Menu Should be used as child of SideNavBar`);
  }

  const { label, menuIndex, children } = props;

  const {
    state: { activeMenuIndex, visited, allowNavigation },
    dispatch,
  } = context;

  useEffect(() => {
    dispatch({
      type: 'MAX_MENU_INDEX',
      payload: {
        menuIndex,
      },
    });
  }, []);

  if (!label || isNaN(menuIndex)) {
    throw new Error('label or subMenuIndex missing in Menu Component');
  }

  const isActive = activeMenuIndex === menuIndex;
  const isClickable = allowNavigation || visited?.includes(`${menuIndex}`);

  const switchMenu = () => {
    if (isClickable) {
      dispatch({
        type: 'CHANGE_MENU',
        payload: {
          activeMenuIndex: menuIndex,
        },
      });
    }
  };

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        parentIndex: menuIndex,
      });
    });
  };

  return (
    <div className={styles['side-navbar-item']}>
      <div
        className={`${styles['side-navbar-item__collapsible']} ${
          isActive ? styles['side-navbar-item__collapsible--active'] : ''
        } ${isClickable ? styles['side-navbar-item__collapsible--clickable'] : ''}`}
        aria-disabled={isClickable}
        onClick={switchMenu}
      >
        <span
          className={`${styles['side-navbar-item__collapsible-text']} ${
            isActive ? styles['side-navbar-item__collapsible-text--active'] : ''
          }`}
        >
          {label}
        </span>
        <div
          className={`${isActive ? styles['side-navbar-item__collapsible-right-arrow'] : ''}`}
        ></div>
      </div>
      {isActive && (
        <div className={styles['side-navbar-item__sub-menu']}>
          {renderChildren()}
        </div>
      )}
    </div>
  );
};
