import { PageWrapper } from 'components/COVID/common/PageWrapper';
import {
  GROUP_RESCHEDULE_CANCEL_SELECTION_OPTIONS,
  GroupOption,
} from '../cancel/component/GroupOption';
import {
  useAppointmentContext,
  useGroupAppointmentContext,
} from '../../../../../context';
import { AppointmentStatuses } from '../../../../../constants/appointment';
import { getString } from '../../../../../util/lang';
import { useState } from 'react';
import { useNavigationHelper } from '../../../../../hooks/useNavigationHelper';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

export const GroupRescheduleTypeSelection = () => {
  const { appointmentId } = useParams();
  const nav = useNavigationHelper();
  const { setIndividualReschedule, setIsGroupAppointment } =
    useGroupAppointmentContext();

  const toNextPage = useCallback(() => {
    nav.toGroup.reschedule.location(appointmentId);
  }, [nav, appointmentId]);

  const defaultSelectionForIndividual =
    GROUP_RESCHEDULE_CANCEL_SELECTION_OPTIONS[0].name;

  const [option, setCheckedOption] = useState(defaultSelectionForIndividual);

  const onClick = () => {
    const isNotGroupAppointmentReschedule =
      option === defaultSelectionForIndividual;
    setIndividualReschedule(isNotGroupAppointmentReschedule);
    setIsGroupAppointment(!isNotGroupAppointmentReschedule);
    toNextPage();
  };

  const handleRadioChange = (e) => {
    setCheckedOption(e.target.name);
  };

  const { appointments } = useAppointmentContext();

  const isEnabled =
    appointments[0].firstAppointment.appointmentStatus ===
    AppointmentStatuses.BOOKED;

  const alertMessage =
    option === defaultSelectionForIndividual
      ? getString('groupRescheduleALertForIndividual')
      : getString('groupRescheduleAlertForGroup');

  return (
    <PageWrapper container="full" classNames={{ mainWrapper: 'container' }}>
      <GroupOption
        onClick={onClick}
        handleRadioChange={handleRadioChange}
        isEnabled={isEnabled}
        buttonText={getString('rescheduleGroupBtnText')}
        option={option}
        alertMessage={alertMessage}
        header={getString('groupRescheduleSelectionTypeTitle')}
      />
    </PageWrapper>
  );
};
