import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiCheck } from 'vyaguta-icons/fi';

import CONFIG from 'config';
import { useDetailContext, usePatientInfo } from 'context';
import { useSecurityToken } from 'context/useSecurityToken';
import { useResetAppState } from 'context/useResetAppState';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useConfirmModal } from 'components/common/ConfirmModal';
import Button from 'components/common/Button';
import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { SecurityCheck } from '../SecurityCheck/SecurityCheck';
import { useAppointmentCancel } from 'context/useAppointmentCancel';
import { useSelectedServiceNames } from 'context/useSelectedServiceNames';
import { useFollowupStatusCheck } from 'context/useFollowupStatusCheck';
import { AppointmentStatuses } from 'constants/appointment';
import { InvalidAppointmentMessage } from '../../common/InvalidAppointmentMessage';
import { changeTitle } from 'util/siteInfo';

export const FollowupCancel = () => {
  changeTitle('Follow Up | Cancel');
  const { appointmentId, organizationId } = useParams();
  const { setSecurityToken, resetSecurityToken } = useSecurityToken();
  const { rescheduleDataSetup, setDetails } = useDetailContext();
  const serviceNames = useSelectedServiceNames();
  const { cancelAppointment } = useAppointmentCancel();
  const resetAppState = useResetAppState();
  const nav = useNavigationHelper();
  const { ConfirmModal, confirm } = useConfirmModal();

  const [isLoading, setIsLoading] = useState(false);
  const [dataSetupCompleted, setDataSetupCompleted] = useState(false);
  const [cancelComplete, setCancelComplete] = useState(false);
  const [isCancelValid, setIsCancelValid] = useState(true);

  const followupStatus = useFollowupStatusCheck(appointmentId);

  const { currentPatientInfo } = usePatientInfo();

  const onSecurityVerified = useCallback(
    async ({ token, details: patientInfo }) => {
      try {
        setIsLoading(true);
        setSecurityToken(token);
        setDetails((details) => ({
          patientInfo: [
            {
              ...currentPatientInfo,
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
              birthMonth: patientInfo.birthMonth,
              birthDay: patientInfo.birthDay,
              birthYear: patientInfo.birthYear,
            },
          ],
        }));
        // fetch and setup data

        if (!dataSetupCompleted) {
          // if user verifies, then cancels then verifies again, dont do the reschedule setup again
          const result = await rescheduleDataSetup(
            organizationId,
            appointmentId,
          );
          if (!result) {
            // handle error
            throw new Error(
              'Failed to setup data for follow-up appointment cancellation.',
            );
          }
          setDataSetupCompleted(true);
        }

        const appointment = await new Promise((resolve) => {
          setDetails(({ appointments }) => {
            resolve(appointments[0]);
            return {};
          });
        });

        setIsLoading(false);

        const isValid = [
          AppointmentStatuses.PENDING,
          AppointmentStatuses.BOOKED,
        ].includes(appointment.appointmentStatus);
        setIsCancelValid(isValid);

        if (!isValid) {
          return;
        }

        const confirmResult = await confirm();

        if (!confirmResult.confirm) {
          return;
        }

        setIsLoading(true);
        await cancelAppointment(
          appointmentId,
          confirmResult.captchaResponseKey,
          token,
        );

        setCancelComplete(true);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        error.message && toast.error({ title: '', message: error.message });
        resetSecurityToken();
      } finally {
        setIsLoading(false);
      }
    },
    [
      confirm,
      setSecurityToken,
      resetSecurityToken,
      appointmentId,
      organizationId,
      rescheduleDataSetup,
      setDetails,
      dataSetupCompleted,
      cancelAppointment,
    ],
  );

  const onBookAnotherAppointment = useCallback(() => {
    resetAppState();
    nav.resetToFirstPage();
  }, [nav, resetAppState]);

  return (
    <PageWrapper>
      {cancelComplete ? (
        <CancelSuccess
          serviceNames={<>{serviceNames}</>}
          onBookAnotherAppointment={onBookAnotherAppointment}
        />
      ) : isCancelValid ? (
        <SecurityCheck
          loading={isLoading || !followupStatus.loaded}
          activityId={appointmentId}
          onSecurityVerified={onSecurityVerified}
          headerMsg={getString('followupRescheduleVerificationHeader')}
          alertMsg={getString('followupRescheduleVerificationAlert')}
        />
      ) : (
        <InvalidAppointmentMessage />
      )}

      <ConfirmModal
        captchaEnabled={CONFIG.captchaEnabled}
        headerContent={getString('appointmentCancellationHeader')}
        bodyContent={getString('appointmentCancellationDescription')}
        cancelText={getString('doNotCancel')}
        confirmText={getString('cancelAppointment')}
      />
    </PageWrapper>
  );
};

const CancelSuccess = ({ serviceNames, onBookAnotherAppointment }) => {
  return (
    <div className="success">
      <section className="selected-details section__margin">
        <div className="border border-radius section__container p-0x">
          <FiCheck
            size={24}
            className="success-check success-check--covid mt-9x"
          />
          <div className="success-content border-bottom">
            <p tabIndex="0" className="mb-0x-md">
              {getString('cancelFollowupAppointmentMessage', serviceNames)}
            </p>
            <div className="success-action-covid">
              <Button
                title={getString('bookAppointmentTitle')}
                isEnabled
                onClick={onBookAnotherAppointment}
                className="btn btn--large btn-primary--outline"
                isActive
                label={getString('bookAppointment')}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
