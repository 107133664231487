import React, { useContext, useEffect } from 'react';
import { NavigationContext } from './context';
import styles from './SideNavBar.module.scss';

export const SubMenu = (props) => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(`SubMenu Should be used as child of Menu`);
  }

  const { label, parentIndex, subMenuIndex } = props;

  const {
    state: { activeMenuIndex, activeSubMenuIndex, visited, allowNavigation },
    dispatch,
  } = context;

  useEffect(() => {
    dispatch({
      type: 'MAX_SUBMENU_INDEX',
      payload: {
        subMenuIndex,
      },
    });
  }, []);

  if (!label || isNaN(subMenuIndex)) {
    throw new Error('label or subMenuIndex missing in SubMenu Component');
  }

  const isActive =
    activeMenuIndex === parentIndex && activeSubMenuIndex === subMenuIndex;
  const isClickable =
    allowNavigation || visited?.includes(`${parentIndex}${subMenuIndex}`);

  const switchSubMenu = () => {
    if (isClickable) {
      dispatch({
        type: 'CHANGE_SUB_MENU',
        payload: {
          activeSubMenuIndex: subMenuIndex,
        },
      });
    }
  };

  return (
    <div
      className={`${styles['side-navbar-item__sub-menu-item']} ${
        isActive ? styles['side-navbar-item__sub-menu-item--active'] : ''
      } ${isClickable ? styles['side-navbar-item__sub-menu-item--clickable'] : ''}`}
      onClick={switchSubMenu}
    >
      <span
        className={`${styles['side-navbar-item__sub-menu-item-text']} ${
          isActive ? styles['side-navbar-item__sub-menu-item-text--active'] : ''
        }`}
      >
        {label}
      </span>
    </div>
  );
};
