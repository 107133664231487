import CONFIG from '../../config';
import { testReportApi } from '../../api/index.js';

const testReportEndpoints = testReportApi
  .enhanceEndpoints({
    addTagTypes: ['TestReport'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTestReport: builder.query({
        query: (appointmentId) => CONFIG.entity.getTestReport(appointmentId),
        transformResponse: (response) => {
          if (response.success) {
            return response.data;
          } else {
            return null;
          }
        },
        transformErrorResponse: (response) => {
          return {
            error: response.data,
            errorMessage: response?.data?.message,
          };
        },
      }),
    }),
  });

export const { useGetTestReportQuery } = testReportEndpoints;
