import React from 'react';

import { Banner } from '../../common/Banner';
import { Navbar } from '../../common/Navbar';

import { HelpCenterContent } from './HelpCenterContent';

const HelpCenter = () => {
  return (
    <div className="welcome-page">
      <div className="container-lg">
        <Navbar />
        <Banner />
        <HelpCenterContent />
      </div>
    </div>
  );
};

export default HelpCenter;
