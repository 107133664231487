import React from 'react';
import { v4 as uuid4 } from 'uuid';
import config from '../config';

const {
  MAX_API_CALLS,
  MAX_SESSION_TIME_SECONDS,
  MAPBOX_AUTOFILL_SESSION_KEY,
  MAPBOX_AUTOFILL_SUGGEST_API_URL,
  MAPBOX_AUTOFILL_RETRIEVE_API_URL,
  MAX_SESSION_TIME_BUFFER_SECONDS,
  DEFAULT_SUGGEST_OPTIONS: defaultOptions,
} = config.mapboxAutofillConfig;

const mapToParams = (params) => {
  const queryParams = Object.keys(params).reduce((acc, key) => {
    if (params[key]) {
      acc.push(`${key}=${params[key]}`);
    }
    return acc;
  }, []);
  return queryParams.join('&');
};

const encodeAddress = (address) => {
  const { homeAddress = '', city = '', state = '', zipCode = '' } = address;
  let query = '';
  if (homeAddress) query += `${homeAddress}`;
  if (city) query += `, ${city}`;
  if (state) query += `, ${state}`;
  if (zipCode) query += `, ${zipCode}`;
  return encodeURIComponent(query);
};

/**
 * @param {Object} suggestion
 * @param {string} suggestion.postcode
 * @param {string} suggestion.place
 * @param {string} suggestion.region_code
 * @param {string} suggestion.address_line1
 * @param {string} suggestion.district
 * @returns {Object} formatted suggestion
 * @property {string} formattedSuggestion.zipCode
 * @property {string} formattedSuggestion.city
 * @property {string} formattedSuggestion.state
 * @property {string} formattedSuggestion.county
 * @property {string} formattedSuggestion.homeAddress
 * @property {string} formattedSuggestion.id
 */
const formatSuggestions = (suggestion) => {
  const id = suggestion.id ?? '';
  const actionId = suggestion?.action?.id ?? '';
  let zipCode = suggestion.postcode ?? '';
  let city = suggestion.place
    ? suggestion.place.replace('City', '').trim()
    : '';
  let state = suggestion.region_code ?? '';
  let county = suggestion.district
    ? suggestion.district.replace(' County', '')
    : '';
  let homeAddress = suggestion.address_line1 ?? '';

  return { zipCode, city, state, county, homeAddress, id, actionId };
};

/**
 * @returns {Object} session
 * @property {string} session.sessionToken
 * @property {string} session.timestamp
 * @property {string} session.apiCallCount
 *
 */
const getSavedSession = () => {
  const savedSession = localStorage.getItem(MAPBOX_AUTOFILL_SESSION_KEY);
  if (!savedSession) return null;
  try {
    const {
      sessionToken = uuid4(),
      timestamp = null,
      apiCallCount = 0,
    } = JSON.parse(savedSession);
    return { sessionToken, timestamp: new Date(timestamp), apiCallCount };
  } catch (error) {
    return null;
  }
};

/**
 * @param {Object} session
 * @param {string} session.sessionToken
 * @param {string} session.timestamp
 * @param {string} session.apiCallCount
 * @returns {Object} session
 *
 */
const saveSession = (session) => {
  let savedSession = getSavedSession();
  if (!savedSession) savedSession = {};
  savedSession = { ...savedSession, ...session };
  localStorage.setItem(
    MAPBOX_AUTOFILL_SESSION_KEY,
    JSON.stringify(savedSession),
  );
  return savedSession;
};

/**
 * @returns {Object} addressSearch
 * @property {Function} addressSearch.suggest - Returns an array of suggestions based on the provided address
 *
 * @example
 * const { suggest } = useAddressSearch();
 * const address = { homeAddress: '123 Main St', city: 'San Francisco', state: 'CA', zipCode: '94105' };
 * const suggestions = await suggest(address);
 */
const useAddressSearch = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Returns a new session token if the current session has expired or the max number of API calls has been reached
   * @returns {string} sessionToken
   *
   **/
  const checkSession = React.useCallback(() => {
    const savedSession = getSavedSession();
    if (!savedSession) {
      const token = uuid4();
      const now = new Date();
      saveSession({ sessionToken: token, timestamp: now, apiCallCount: 1 });
      return token;
    }
    if (
      savedSession.apiCallCount >= MAX_API_CALLS ||
      (savedSession.timestamp &&
        savedSession.timestamp.getTime() + MAX_SESSION_TIME_SECONDS * 1000 <
          new Date().getTime() + MAX_SESSION_TIME_BUFFER_SECONDS * 1000)
    ) {
      const token = uuid4();
      const now = new Date();
      saveSession({ sessionToken: token, timestamp: now, apiCallCount: 1 });
      return token;
    }
    saveSession({
      ...savedSession,
      apiCallCount: savedSession.apiCallCount + 1,
    });
    return savedSession.sessionToken;
  }, []);

  /**
   * @param {Object} address
   * @param {string} address.homeAddress
   * @param {string} address.city
   * @param {string} address.state
   * @param {string} address.zipCode
   *
   * @returns {Promise<Object[]>} suggestions
   * @property {string} suggestions.zipCode
   * @property {string} suggestions.city
   * @property {string} suggestions.state
   * @property {string} suggestions.county
   * @property {string} suggestions.homeAddress
   * @property {string} suggestions.id
   *
   */
  const suggest = async (address, options = {}) => {
    if (!address) return null;
    try {
      setIsLoading(true);
      const data = await fetch(
        `${MAPBOX_AUTOFILL_SUGGEST_API_URL}${encodeAddress(address)}?${mapToParams(
          {
            access_token: config.mapBoxKey,
            session_token: checkSession(),
            ...{ ...defaultOptions, ...options },
          },
        )}`,
      )
        .then((res) => res.json())
        .finally(() => setIsLoading(false));
      return data.suggestions.map(formatSuggestions);
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  /**
   * @param {string} actionId
   *
   * @returns {Promise<Object>} response
   * @property {Object[]} address.features
   *
   */
  const retrieve = async (actionId) => {
    if (!actionId) return null;
    try {
      setIsLoading(true);
      const data = await fetch(
        `${MAPBOX_AUTOFILL_RETRIEVE_API_URL}${actionId}?${mapToParams({
          access_token: config.mapBoxKey,
          session_token: checkSession(),
        })}`,
      )
        .then((res) => {
          return res.json();
        })
        .finally(() => setIsLoading(false));
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  return { suggest, isLoading, retrieve };
};

export { useAddressSearch };
