import React from 'react';
import cls from 'classnames';
import Footer from './footer';
import { NavBar } from 'components/common/NavBar';
import SkipToMainContent from './SkipToMainContent';
import styles from './PageWrapper.module.scss';
import { useAppSettings, useDetailContext } from 'context';

/**
 * @typedef {import('components/common/NavBar/navLinks').NavLinkConfig} NavLinkConfig
 *
 * @typedef {{
 *  showNavMenu?: boolean
 *  navLinks: NavLinkConfig[]
 *  ContentWrapper: React.ComponentType
 *  container?: 'full' | 'fixed'
 *  classNames?: {
 *    mainWrapper?: string
 *  }
 * bgGrey?: boolean
 * }} Props
 *
 * @type {React.FC<React.PropsWithChildren<Props>>}
 */
export const PageWrapper = ({
  children,
  showNavMenu = false,
  ContentWrapper = MainContentWrapper,
  container = 'fixed',
  classNames,
  navLinks = [],
  bgGrey = false,
  isGroupContainer = false,
}) => {
  const mainContentRef = React.useRef(null);
  const {
    details: { loading },
  } = useDetailContext();
  const { logoUrl } = useAppSettings();

  return (
    <>
      <SkipToMainContent mainContentRef={mainContentRef} />
      <NavBar
        displayNavLinks={showNavMenu}
        logo={logoUrl}
        navLinks={navLinks}
        loading={loading}
      />
      <ContentWrapper
        contentRef={mainContentRef}
        container={container}
        isGroupContainer={isGroupContainer}
        classNames={{ mainWrapper: classNames?.mainWrapper }}
        bgGrey={bgGrey}
      >
        {children}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export const MainContentWrapper = ({
  children,
  contentRef,
  container,
  isGroupContainer,
  classNames,
  bgGrey,
}) => (
  <div
    className={cls(
      'main-wrapper',
      styles.PageWrapper,
      bgGrey && styles.bgGrey,
      classNames?.mainWrapper,
    )}
    ref={contentRef}
  >
    {container === 'full' ? (
      children
    ) : (
      <div className={isGroupContainer ? 'group-container' : 'container'}>
        {children}
      </div>
    )}
  </div>
);
