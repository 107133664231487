import * as Yup from 'yup';
import { getString } from 'util/lang';
import regexPattern from 'constants/regexPattern';

export const validateSecurityForm = (formValues) => {
  let errors = {};
  let isValid = true;

  Object.keys(formValues).forEach(async (key) => {
    let value = formValues[key];
    try {
      SecurityCheckYupSchema()[key].validateSync(value);
      errors[key] = null;
    } catch (error) {
      isValid = false;
      errors[key] = error.message;
    }
  });

  return {
    errors,
    isValid,
  };
};

const SecurityCheckYupSchema = () => ({
  firstName: Yup.string()
    .trim()
    .required(getString('firstNameRequired'))
    .matches(regexPattern.nameField, getString('firstNameValidationMessage')),
  lastName: Yup.string()
    .trim()
    .required(getString('lastNameRequired'))
    .matches(regexPattern.nameField, getString('lastNameValidationMessage')),
  middleName: Yup.string()
    .trim()
    .matches(regexPattern.nameField, {
      message: getString('middleNameValidationMessage'),
      excludeEmptyString: true,
    }),

  email: Yup.string()
    .required(getString('emailRequired'))
    .email(getString('validEmailRequired')),

  confirmationCode: Yup.string()
    .required(getString('confirmationCodeRequired'))
    .matches(/^([0-9a-zA-Z]{6})$/, getString('confirmationCode')),
});
