import { StyleSheet, Text, View } from '@react-pdf/renderer';

const PharmacyInfoPdfView = ({ name, address, contact }) => (
  <View style={styles.infoWrap}>
    <Text style={styles.h3}>{name}</Text>
    <Text style={styles.p}>{address}</Text>
    <Text style={styles.p}>{contact}</Text>
  </View>
);

const styles = StyleSheet.create({
  infoWrap: {
    padding: '8px 12px',
    backgroundColor: '#FAF9F8',
    border: '0.5px solid #E1DFDD',
    gap: '8px',
    borderRadius: 4,
  },
  h3: {
    fontSize: 10,
    fontWeight: 700,
  },
  p: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
  },
});

export default PharmacyInfoPdfView;
