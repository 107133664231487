import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useDetailContext } from 'context';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import React from 'react';
import { getString } from 'util/lang';
import { changeTitle } from 'util/siteInfo';
import { FiCheck } from 'vyaguta-icons/fi';

const WaitlistSuccess = () => {
  changeTitle('Wait List');
  const nav = useNavigationHelper();
  const {
    details: { selectedServices },
  } = useDetailContext();

  if (!selectedServices.length) {
    nav.resetToFirstPage();

    return null;
  }

  const goToHomePage = () => nav.resetToFirstPage();

  return (
    <PageWrapper bgGrey={true}>
      <div className="card__container">
        <div className="card card__success">
          <div className="card--content">
            <FiCheck className="success-check success-check--green m-auto" />
            <h3 className="mt-9x-md my-3x">
              {getString('waitlistSuccessTitle')}
            </h3>
            <p>{getString('waitlistSuccessInfo')}</p>
            <button
              className="btn btn-primary btn-80 mt-3x"
              onClick={goToHomePage}
            >
              {getString('waitlistReturnButton')}
            </button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default WaitlistSuccess;
