import React, { useMemo, useState, useEffect, useRef } from 'react';

import FormInput from 'components/common/FormInput';
import NotificationPreferences from 'components/COVID/views/PatientInformation/presentation/NotificationPreferences';
import IISConsent from 'components/COVID/views/PatientInformation/presentation/IISConsent';
import DropSelect from '../DropSelect';

import { ShowAsRequiredFields } from 'constants/patientInfo';
import { getString } from 'util/lang';
import styles from './groupContact.module.scss';
import { ContactInfoKeys } from 'constants/patientInfo';
import FormErrors from 'components/common/FormErrors';
import * as Regex from 'util/regex';
import cls from 'classnames';

const OPTIONS = {
  INDIVIDUAL: 'individual',
  GROUP: 'group',
};

export const GroupContact = (props) => {
  const {
    patientInfo = {},
    errors = {},
    isIISConsentEnabled = true,
    onPatientInfoChange,
    validateEmailOnBlur,
    validateConfirmEmailOnBlur,
    contactList = [],
    onGroupContactSelection,
    clientName,
  } = props;

  const [patientInfoState, setPatientInfoState] = useState({});

  const [contactOption, setContactOption] = useState();

  const [selectedContact, setSelectedContact] = useState({ ...contactList[0] });

  // this is used for detecting the patientInfo props when it is available
  // in props for the first time
  const patientInfoLoadedRef = useRef({ patientInfoLoaded: false });

  const errorSet = React.useMemo(() => {
    const errorSet = {};
    [...ContactInfoKeys].forEach((key) => {
      if (errors[key]) {
        errorSet[key] = errors[key];
      }

      if (patientInfo.emailOptOut) {
        errorSet.confirmEmail = null;
      }
      if (isIISConsentEnabled && !patientInfo.iisConsent) {
        errorSet.njisValidation = getString('njisValidationMessage');
      }
    });
    return errorSet;
  }, [errors]);

  useEffect(() => {
    // logic for the default radio button option selection
    if (
      !patientInfoLoadedRef.current.patientInfoLoaded &&
      Object.keys(patientInfo).length
    ) {
      patientInfoLoadedRef.current.patientInfoLoaded = true;
      const matchFromGroup = contactList.find((c) => {
        return (
          c.email === patientInfo.email && c.mobileNo === patientInfo.mobileNo
        );
      });
      if (matchFromGroup) {
        setContactOption(OPTIONS.GROUP);
        setSelectedContact(matchFromGroup);
      } else if (patientInfo.mobileNo || patientInfo.email) {
        setContactOption(OPTIONS.INDIVIDUAL);
      } else {
        setContactOption(OPTIONS.INDIVIDUAL);
      }
    }

    setPatientInfoState({ ...patientInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo]);

  const dropSelectOptions = useMemo(
    () =>
      contactList.map((c) => {
        return {
          label: c.name,
          value: c,
        };
      }),
    [contactList],
  );

  const onContactOptionChange = (e) => {
    const value = e.target.value;
    if (value) {
      setContactOption(value);
      if (typeof onGroupContactSelection === 'function') {
        let options = {
          email: '',
          mobileNo: '',
        };
        if (value === OPTIONS.GROUP) {
          options = selectedContact;
        }
        onGroupContactSelection(options);
      }
    }
  };

  const onGroupContactChange = (option) => {
    if (option) {
      setSelectedContact(option);

      if (typeof onGroupContactSelection === 'function') {
        onGroupContactSelection(option);
      }
    }
  };

  const onInputChange = (key, value, event) => {
    if (key === 'mobileNo') {
      if (value.length < 12) {
        value = value.replaceAll('-', '');
      }
      if (!Regex.isNumber(value)) {
        return;
      }
    }

    setPatientInfoState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
    if (typeof onPatientInfoChange === 'function') {
      onPatientInfoChange(key, value, event);
    }
  };

  const validateEmail = () => {
    if (typeof validateEmailOnBlur === 'function') {
      validateEmailOnBlur();
    }
  };

  const validateConfirmEmail = () => {
    if (typeof validateConfirmEmailOnBlur === 'function') {
      validateConfirmEmailOnBlur();
    }
  };

  return (
    <>
      <section className={`${styles['patient-contact-paper']}`}>
        {/* radio group option */}
        <div
          className={cls(
            `${styles['patient-contact-options__item']}`,
            'custom-radio',
          )}
        >
          <input
            id="contact__group"
            type="radio"
            name="group__contact"
            className="custom-radio-input"
            value={OPTIONS.GROUP}
            checked={contactOption === 'group'}
            onChange={onContactOptionChange}
          />
          <label
            htmlFor="contact__group"
            className={`${styles['contact-option-label__text']}`}
          >
            {getString('useSameContactFromGroup', {
              patientName: (
                <span>
                  &nbsp;
                  <span
                    htmlFor="contact__group"
                    className={`${styles['patient-contact-selected-name']} ${
                      dropSelectOptions.length > 1
                        ? styles['patient-contact-selected-name-underline']
                        : ''
                    }`}
                  >
                    {selectedContact.name}
                  </span>
                </span>
              ),
            })}
          </label>
          &nbsp;
          <DropSelect
            options={dropSelectOptions}
            onChange={onGroupContactChange}
          />
        </div>

        {/* radio group option content */}
        <div
          className={`${styles['patient-contact-options__item-group__content']}`}
        >
          {selectedContact.mobileNo && (
            <span
              className={`${styles['patient-contact-options__item-group__content--mobile']}`}
            >
              {getString('groupMobileNumberLabel')}:{' '}
              <span
                className={`${styles['patient-contact-options__item-group__content--mobile-text']}`}
              >
                {selectedContact.mobileNo}
              </span>
            </span>
          )}
          {selectedContact.email && (
            <span
              className={`${styles['patient-contact-options__item-group__content--email']} ${
                selectedContact.mobileNo
                  ? styles[
                      'patient-contact-options__item-group__content--email-border'
                    ]
                  : ''
              }`}
            >
              {getString('groupEmailLabel')}:{' '}
              <span
                className={`${styles['patient-contact-options__item-group__content--email-text']}`}
              >
                {selectedContact.email}
              </span>
            </span>
          )}
        </div>

        {/* radio individual option */}
        <div
          className={cls(
            `${styles['patient-contact-options__item']}`,
            'custom-radio',
          )}
        >
          <input
            id="contact__individual"
            type="radio"
            name="group__contact"
            className="custom-radio-input"
            value={OPTIONS.INDIVIDUAL}
            checked={contactOption === 'individual'}
            onChange={onContactOptionChange}
          />
          <label
            htmlFor="contact__individual"
            className={`${styles['contact-option-label__text']}`}
          >
            {getString('useDifferentContactFromGroup')}
          </label>
        </div>

        {/* radio individual content option */}
        {contactOption === 'individual' && (
          <div
            className={`${styles['patient-contact-options__item-individual__content']}`}
          >
            <div
              className={`${styles['patient-form-group']} patient-form-group`}
            >
              <div className={`row`}>
                <div className="col-12-md">
                  <FormInput
                    label={getString('mobileNumber')}
                    id="mobileNo"
                    type="tel"
                    inputClassName={`${styles['input-mobile-border']}`}
                    onChange={onInputChange}
                    value={patientInfoState.mobileNo}
                    hasError={!!errors['mobileNo']}
                    required={ShowAsRequiredFields['mobileNo']}
                    aria-label="mobile number"
                    data-qa="mobile-info-page"
                  />
                </div>
                <div className="col-6-md">
                  <FormInput
                    label={getString('emailAddress')}
                    id="email"
                    type="email"
                    inputClassName={`${styles['input-email-address-border']}`}
                    inputAutoComplete={'off'}
                    disablePaste={true}
                    onChange={onInputChange}
                    value={patientInfoState.email}
                    hasError={!!errors['email']}
                    data-qa="email-info-page"
                    onBlur={validateEmail}
                    required={
                      patientInfoState.emailNotificationConsent
                        ? ShowAsRequiredFields['email']
                        : false
                    }
                  />
                </div>
                <div className="col-6-md">
                  <FormInput
                    label={getString('emailAddressConfirm')}
                    id="confirmEmail"
                    type="email"
                    inputClassName={`${styles['input-confirm-email-address-border']}`}
                    disablePaste={true}
                    onChange={onInputChange}
                    value={patientInfoState.confirmEmail}
                    hasError={!!errors['confirmEmail']}
                    data-qa="email-confirm-info-page"
                    onBlur={validateConfirmEmail}
                    required={
                      patientInfoState.emailNotificationConsent
                        ? ShowAsRequiredFields['confirmEmail']
                        : false
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-3x">
              {isIISConsentEnabled && (
                <IISConsent
                  isConsentAgree={
                    isIISConsentEnabled ? patientInfoState.iisConsent : false
                  }
                  onConsentChange={onInputChange}
                  dataqa="iis-consent"
                />
              )}
              <NotificationPreferences
                patientInfo={patientInfoState}
                onChange={onInputChange}
                clientName={clientName}
              />
            </div>
          </div>
        )}
      </section>
      <FormErrors errors={errorSet} />
    </>
  );
};
