import * as Yup from 'yup';
import * as Regex from '../../../../../util/regex';
import regexPattern from '../../../../../constants/regexPattern';
import { getString } from '../../../../../util/lang';

export const validateSecurityForm = (formValues) => {
  let errors = {};
  let isValid = true;

  Object.keys(formValues).forEach(async (key) => {
    let value = formValues[key];
    try {
      SecurityCheckYupSchema()[key].validateSync(value);
      errors[key] = null;
    } catch (error) {
      isValid = false;
      errors[key] = error.message;
    }
  });

  return {
    errors,
    isValid,
  };
};

const YearRegx = Regex.ValidBirthYear();

const SecurityCheckYupSchema = () => ({
  firstName: Yup.string()
    .trim()
    .required(getString('firstNameRequired'))
    .matches(regexPattern.nameField, getString('firstNameValidationMessage')),
  lastName: Yup.string()
    .trim()
    .required(getString('lastNameRequired'))
    .matches(regexPattern.nameField, getString('lastNameValidationMessage')),
  middleName: Yup.string()
    .trim()
    .matches(regexPattern.nameField, {
      message: getString('middleNameValidationMessage'),
      excludeEmptyString: true,
    }),
  birthMonth: Yup.string().required(getString('monthRequired')),

  birthDay: Yup.string()
    .required(getString('dayRequired'))
    .matches(/^([1-2]?[0-9]|3[0-1])$/, getString('dayValidationMessage')),

  birthYear: Yup.string()
    .required(getString('yearRequired'))
    .matches(YearRegx, getString('yearValidationMessage')),
});
