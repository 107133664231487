import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useVerifyUserMutation } from 'redux/dme/dme.api';
import { useNavigationHelper } from 'hooks/useNavigationHelper';

import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { formatFormInput } from 'util/formatFormInput';
import { storeDataToSessionStorage } from 'util/sessionStorage';

import Button from 'components/common/Button';
import FormInput from 'components/common/FormInput';
import FormErrors from 'components/common/FormErrors';
import ReCaptcha from 'components/COVID/common/ReCaptcha';
import AlertMessage from 'components/COVID/common/AlertMessage';
import ActivityIndicator from 'components/common/ActivityIndicator';
import SelectBox from 'components/common/SelectBox';
import DmeHeader from '../presentation/common/DmeHeader/DmeHeader';

import { validateSecurityForm } from '../../SecurityCheck/container/SecurityCheckValidation';
import { getDaysInMonth } from '../func';

import { DME_TOKEN } from 'constants/dme';
import regexPattern from 'constants/regexPattern';
import { DayList, MonthList, YearList } from 'constants/patientInfo';

import { REDIRECTED_TO_DME_VERIFY_FROM } from '../constants/redirectedToDMEVerifyFrom';

import CONFIG from 'config';

import { convertUserInfoObjectToDynamicsFormat } from 'services/securityVerification';

const DMEVerify = () => {
  const params = useParams();
  const nav = useNavigationHelper();

  const [verifyUser] = useVerifyUserMutation();

  const [productOrderId, from, deliveryTicketId] = useMemo(() => {
    const productOrderId = params?.productOrderId;
    const deliveryTicketId = params?.deliveryTicketId;
    const searchParams = new URLSearchParams(window.location.search);
    const from = searchParams.get('from');
    return [productOrderId, from, deliveryTicketId];
  }, [params]);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(null);
  const [isUserValid, setIsUserValid] = useState(true);
  const [isCaptchaAttempted, setIsCaptchaAttempted] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    birthMonth: '',
    birthDay: '',
    birthYear: '',
  });

  const handleFormValueChange = (key, value) => {
    if (
      (key === 'firstName' || key === 'lastName' || key === 'middleName') &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    )
      return;
    const formattedValue = formatFormInput(key, value);
    setFormValues((formValues) => ({
      ...formValues,
      [key]: formattedValue,
    }));
    setErrors((errors) => clearError(key, errors));
    setIsUserValid(true);
  };

  const clearError = (key, { [key]: _, ...restErrors }) => restErrors;

  const handleError = (message) => {
    toast.error({
      title: '',
      message: message || 'An error occurred.',
    });
    if (CONFIG.captchaEnabled) {
      window.grecaptcha.reset();
    }
  };

  const submitSecurityCheck = async () => {
    if (CONFIG.captchaEnabled && !captcha) {
      setIsCaptchaAttempted(true);
      return;
    }
    try {
      setIsLoading(true);
      const { errors, isValid } = validateSecurityForm(formValues);

      const isCaptchaValid = CONFIG.captchaEnabled ? !!captcha : true;

      if (!isValid || !isCaptchaValid) {
        return setErrors(errors);
      }

      const userInfo = convertUserInfoObjectToDynamicsFormat({
        ...formValues,
        captcha,
      });
      const verificationDetails = await verifyUser({
        ...userInfo,
        productOrderId,
      });

      const token = verificationDetails?.data?.data?.token;
      const requestSuccess = verificationDetails?.data?.success;

      if (Boolean(requestSuccess) && token?.length) {
        storeDataToSessionStorage(DME_TOKEN, token);
        if (CONFIG.captchaEnabled) {
          window.grecaptcha.reset();
        }
        setCaptcha(null);

        switch (from) {
          case REDIRECTED_TO_DME_VERIFY_FROM.DELIVERY_PACKET:
            nav.dme.deliveryPacket.view(productOrderId);
            break;

          case REDIRECTED_TO_DME_VERIFY_FROM.DELIVERY_TICKET:
            nav.dme.deliveryTicket.view(productOrderId, deliveryTicketId);
            break;

          case REDIRECTED_TO_DME_VERIFY_FROM.DOCUMENT:
            nav.dme.document.view(productOrderId);
            break;

          default:
            break;
        }
      } else if (requestSuccess) {
        setIsUserValid(false);
      } else {
        handleError(verificationDetails?.error?.data?.message);
      }
    } catch (error) {
      handleError(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <ActivityIndicator className="loader--clinic" />;
  }

  return (
    <div>
      <DmeHeader headerText="" hideSubHeader={true} isEdit={true} />
      <section className="group-container dme-verify__wrap">
        <h2>{getString('dme.verifyTitle')}</h2>
        <AlertMessage
          isVisible
          type="info"
          className="mb-6x mt-4x"
          message={getString('securityCheckAlert')}
        />
        <div className="row patient-form-group">
          <div className="col-4-md">
            <FormInput
              id="firstName"
              label={getString('firstName')}
              value={formValues.firstName}
              type="text"
              onChange={handleFormValueChange}
              hasError={!!errors['firstName']}
              required
            />
          </div>
          <div className="col-4-md">
            <FormInput
              id="middleName"
              label={getString('middleName')}
              value={formValues.middleName}
              type="text"
              onChange={handleFormValueChange}
              hasError={!!errors['middleName']}
              required={false}
            />
          </div>
          <div className="col-4-md">
            <FormInput
              label={getString('lastName')}
              id="lastName"
              onChange={handleFormValueChange}
              value={formValues.lastName}
              type="text"
              hasError={!!errors['lastName']}
              required={true}
            />
          </div>
          <div className="col-4">
            <SelectBox
              label={getString('year')}
              id="birthYear"
              defaultValue={getString('birthYear')}
              onChange={(e) =>
                handleFormValueChange('birthYear', e.target.value)
              }
              value={formValues.birthYear}
              hasError={!!errors['birthYear']}
              required={true}
              options={YearList()}
            />
          </div>
          <div className="col-4">
            <SelectBox
              id="birthMonth"
              onChange={(e) =>
                handleFormValueChange('birthMonth', e.target.value)
              }
              defaultValue={getString('birthMonth')}
              label={getString('birthMonth')}
              value={formValues.birthMonth}
              options={MonthList}
              hasError={!!errors['birthMonth']}
              required={true}
            />
          </div>
          <div className="col-4">
            <SelectBox
              id="birthDay"
              onChange={(e) =>
                handleFormValueChange('birthDay', e.target.value)
              }
              defaultValue={getString('birthDay')}
              label={getString('birthDay')}
              value={formValues.birthDay}
              options={getDaysInMonth(
                formValues.birthMonth,
                formValues.birthYear,
                DayList,
              )}
              hasError={!!errors['birthDay']}
              required={true}
            />
          </div>
        </div>
        <FormErrors errors={errors} />
        <br />
        {CONFIG.captchaEnabled && (
          <ReCaptcha
            isLoading={false}
            onCaptchaChange={setCaptcha}
            isCaptchaValid={isCaptchaAttempted ? !!captcha : true}
          />
        )}
        <div className="mt-4x">
          <Button
            onClick={submitSecurityCheck}
            label={getString('submit')}
            isEnabled={!isLoading}
            isLoading={isLoading}
          />
          <AlertMessage
            isVisible={!isUserValid}
            type="danger"
            className="mb-6x mt-6x"
            message={getString('securityCheckValidationAlertDme')}
            dataqa="alert-security-check"
          />
        </div>
      </section>
    </div>
  );
};

export default DMEVerify;
