import { useParams } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';

import { DetailContext } from 'context';
import { getLanguageIdByLanguageCode } from 'util/lang';

import SelectMultiService from './container/SelectMultiService';
import HomePageSkeletonScreen from '../HomePageSkeletonScreen';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { usePreServiceDataSetup } from 'context/usePreServiceDataSetup';
import { changeTitle } from 'util/siteInfo';

const SelectMultiServiceWrapper = () => {
  changeTitle('Home');
  const { organizationId } = useParams();
  const { resetToFirstPage } = useNavigationHelper();
  const context = useContext(DetailContext);
  const { details, setAppointmentSuccess, fetchServices } = context;
  const preServiceDataSetup = usePreServiceDataSetup();

  const { organizationObj, isOrganizationDetailsSet, appLanguageCode } =
    details;

  // Single use code
  const { isGlobalSingleUseCodeEnabled } = details.appSettings;
  const isOrgSingleUseCodeEnabled =
    organizationObj && organizationObj.isSingleUseCodeEnabled;
  const showSingleUseCodeView = !!organizationId
    ? isOrgSingleUseCodeEnabled
    : isGlobalSingleUseCodeEnabled;

  const languages = details.appSettings.languages;
  const languageId = getLanguageIdByLanguageCode(appLanguageCode, languages);

  useEffect(() => {
    preServiceDataSetup(organizationId);
    setAppointmentSuccess(false);
  }, []);

  useEffect(() => {
    const _fetchServices = async () => {
      const success = await fetchServices(languageId, organizationId);
      if (!success && organizationId) {
        resetToFirstPage(false);
      }
    };
    if (languageId) _fetchServices();
  }, [fetchServices, languageId, organizationId, resetToFirstPage]);

  return isOrganizationDetailsSet ? (
    <SelectMultiService
      organizationId={organizationId}
      organizationDetails={organizationObj}
      showSingleUseCodeView={showSingleUseCodeView}
    />
  ) : (
    <HomePageSkeletonScreen />
  );
};

export default SelectMultiServiceWrapper;
