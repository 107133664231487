import { getString } from 'util/lang';
import { Text, View } from '@react-pdf/renderer';

import { useStyles } from '../ABNFormPdf.styles';

export const ABNFormHeader = ({
  notifier,
  patientFirstname,
  patientMiddleName,
  patientLastName,
  identificationNumber,
}) => {
  console.log({ notifier });
  const styles = useStyles;
  return (
    <View style={styles.header}>
      <View style={styles.row}>
        <Text style={styles.label}>
          {getString('dme.documentation.abnForm.fields.notifierLabel')}
        </Text>
        <Text style={styles.input}>{notifier}</Text>
      </View>
      <View style={styles.row}>
        <View style={styles.row}>
          <Text style={styles.label}>
            {getString('dme.documentation.abnForm.fields.patientNameLabel')}
          </Text>
          <Text style={styles.input}>
            {`${patientFirstname} ${
              patientMiddleName ? `${patientMiddleName} ` : ''
            }${patientLastName}`}
          </Text>
        </View>
        <View style={[styles.row, styles.ml2]}>
          <Text style={styles.label}>
            {getString(
              'dme.documentation.abnForm.fields.identificationNumberLabel',
            )}
          </Text>
          <Text style={styles.input}>
            {identificationNumber ? identificationNumber : ''}
          </Text>
        </View>
      </View>
    </View>
  );
};
