import { useCallback, useEffect, useMemo } from 'react';
import { useDetailContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { makeGroupRegisterNavLinks } from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { ScreeningAndEligibility } from '../../PatientEligibility/container/PatientEligibilityWrapper';

export const GroupRegisterScreening = () => {
  const {
    details: {
      pageComplete,
      selectedServices,
      selectedLocationId,
      patientInfo,
    },
  } = useDetailContext();

  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();

  const toNextPage = useCallback(() => {
    nav.toGroup.register.patientInfo(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      makeGroupRegisterNavLinks(pageComplete, appointmentId, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  if (!selectedServices.length && !selectedLocationId) {
    nav.resetToFirstPage();

    return null;
  }

  const goBack = () => nav.goBack();

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ScreeningAndEligibility
        isSelfDeclarationDisabled={false}
        organizationId={organizationId}
        id={appointmentId}
        goBack={goBack}
        toNextPage={toNextPage}
        disableEdit={true}
      />
    </PageWrapper>
  );
};
