import { useCallback, useEffect, useMemo, useState } from 'react';

import { msBanner } from 'assets/images';

import CodeRegistration from '../../SelectService/container/CodeRegistration';
import NavBar from 'components/COVID/common/navbar';
import Footer from 'components/COVID/common/footer';
import { isOrganizationLiveOrActive } from 'util/organization';
import ActivityIndicator from 'components/common/ActivityIndicator';
import NoAppointmentsNotice from '../../SelectService/presentation/NoAppointmentsNotice';
import { NoServicesNotice } from '../../SelectService/presentation/NoServiceNotice';
import LandingPageBrand from '../../../common/LandingPageBrand';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import RadioSelectMultiServiceForm from '../presentation/RadioSelectMultiServiceForm';
import {
  useAppSettings,
  useDetailContext,
  useSelectionsContext,
} from 'context';
import { useFilterValidSecondaryServices } from 'context/useFilterValidSecondaryServices';
import { usePrimaryServiceTypes } from 'context/usePrimaryServiceTypes';
import { useResetAppState } from 'context/useResetAppState';
import { AlertedSubmitButton } from '../../../common/AlertedSubmitButton';
// import { GroupAppointmentCard } from '../presentation/GroupAppointmentCard';

const SelectMultiService = (props) => {
  const { organizationId, organizationDetails, showSingleUseCodeView } = props;
  const { organizationContactEmail, organizationContactPhone } =
    useAppSettings();

  const {
    details: {
      isServiceListFetching: isPageLoading,
      isUserRegistered,
      appLanguageCode,
      services,
    },
    registerUser,
  } = useDetailContext();

  const { setSelectedServices, selectedServices } = useSelectionsContext();
  const serviceTypes = usePrimaryServiceTypes();
  const getSecondaryServiceTypes = useFilterValidSecondaryServices();
  const resetAppState = useResetAppState();

  const [_selection, setSelection] = useState(() =>
    selectedServices[0]
      ? {
          serviceTypeId: selectedServices[0].serviceTypeId,
          serviceId: selectedServices[0].serviceId,
        }
      : {
          serviceTypeId: serviceTypes[0]?.id,
          serviceId: null,
        },
  );

  const selection = useMemo(() => {
    const selectedServiceType = _selection.serviceTypeId
      ? serviceTypes.find(
          (serviceType) => serviceType.id === _selection.serviceTypeId,
        )
      : null; // serviceTypes[0] for default selection;
    const selectedService = (() => {
      if (!selectedServiceType) {
        return null;
      }
      if (_selection.serviceId) {
        return selectedServiceType.services.find(
          (service) => service.id === _selection.serviceId,
        );
      }
      if (selectedServiceType.services.length === 1) {
        return selectedServiceType.services[0];
      }
      return null;
    })();
    return {
      serviceTypeId: selectedServiceType?.id ?? _selection.serviceTypeId,
      serviceId: selectedService?.id ?? _selection.serviceId,
    };
  }, [_selection, serviceTypes]);

  const onSelectService = useCallback((serviceId) => {
    setSelection((selection) => ({ ...selection, serviceId }));
  }, []);

  const onSelectServiceType = useCallback((serviceTypeId) => {
    setSelection((selection) => ({
      ...selection,
      serviceTypeId,
      serviceId: null,
    }));
  }, []);

  const [isOrgLive, setIsOrgLive] = useState(false);
  const [isWaitlistEnabled, setIsWaitlistEnabled] = useState(false);

  useEffect(() => {
    const isOrgLiveOrActive = isOrganizationLiveOrActive(organizationDetails);
    setIsOrgLive(isOrgLiveOrActive);
  }, [organizationDetails]);

  const nav = useNavigationHelper();

  const gotoWaitlist = async () => {
    nav.toWaitlist();
  };

  const isSubmitEnabled = selection.serviceTypeId && selection.serviceId;

  const hasSecondaryServiceTypes = useMemo(() => {
    return isSubmitEnabled && getSecondaryServiceTypes(selection).length > 0;
  }, [isSubmitEnabled, selection, getSecondaryServiceTypes]);

  const onSelectSecondaryService = useCallback(() => {
    setSelectedServices([selection]);

    nav.toSecondaryService();
  }, [setSelectedServices, selection, nav]);

  const onContinueWithSingleService = useCallback(() => {
    const newSelectedServices = [
      {
        serviceTypeId: selection.serviceTypeId,
        serviceId: selection.serviceId,
      },
    ];

    // ensure any remaining old data is reset
    resetAppState(({ serviceTypes, services, subServices }) => ({
      serviceTypes,
      services,
      subServices,
    }));
    setSelectedServices(newSelectedServices);
    const groupAppointmentStatus =
      services.byId[selection.serviceId].groupAppointmentStatus;
    if (groupAppointmentStatus) {
      nav.toGroupAppointmentOption();
    } else {
      nav.toPreScreening();
    }
  }, [setSelectedServices, selection, nav, resetAppState]);

  const areServicesNotReady =
    isPageLoading || !isOrgLive || serviceTypes.length === 0;

  return (
    <div className="landing-page-container">
      <NavBar displayNavLinks={false} />

      <div className="nlanding-page">
        <div className="container-lg container-flex-column nlanding-page__container-lg">
          {showSingleUseCodeView && !isUserRegistered ? (
            <div className="container mt-10x">
              <CodeRegistration
                registerUser={registerUser}
                organizationId={organizationId}
                appLanguageCode={appLanguageCode}
              />
            </div>
          ) : (
            <div className="nlanding-page__container">
              <div className="nlanding-page__brand-image">
                <img
                  src={msBanner}
                  alt="Multi Service"
                  className="multi-service-img"
                />
              </div>

              <div className="nlanding-page__form-container">
                <div className="landing-page-section">
                  <LandingPageBrand showSubText={serviceTypes.length > 0} />
                  {isPageLoading ? (
                    <div className="p-20x">
                      <ActivityIndicator />
                    </div>
                  ) : !isOrgLive ? (
                    <NoAppointmentsNotice />
                  ) : serviceTypes.length === 0 ? (
                    <NoServicesNotice
                      email={organizationContactEmail}
                      phone={organizationContactPhone}
                    />
                  ) : (
                    <>
                      <RadioSelectMultiServiceForm
                        serviceTypes={serviceTypes}
                        serviceSelection={selection}
                        onSelectService={onSelectService}
                        onSelectServiceType={onSelectServiceType}
                      />
                    </>
                  )}
                </div>

                {/* {!areServicesNotReady && <GroupAppointmentCard />} */}

                {!areServicesNotReady && (
                  <AlertedSubmitButton
                    hasSecondaryServiceTypes={hasSecondaryServiceTypes}
                    onSelectSecondaryService={onSelectSecondaryService}
                    isSubmitEnabled={isSubmitEnabled}
                    isWaitlistEnabled={isWaitlistEnabled}
                    gotoWaitlist={gotoWaitlist}
                    onContinueWithSingleService={onContinueWithSingleService}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer className="nlanding-page__nfooter" />
    </div>
  );
};

export default SelectMultiService;
