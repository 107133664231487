import React from 'react';

import { HelpfulResourceList } from './HelpfulResourceList';

export const HelpfulResourceListContainer = (props) => {
  const { usefulLinks } = props;

  return (
    <div className="helpful-resources__container">
      <div className="welcome-page__title">Helpful Resources</div>
      <HelpfulResourceList usefulLinks={usefulLinks} />
    </div>
  );
};
