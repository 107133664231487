import { getString } from 'util/lang';
import FormInput from 'components/common/FormInput';

const LABEL_PREFIX = 'dme.deliveryTicket.consent';

export const IndividualResponsible = ({
  isEdit,
  setDeliveryTicketHandler,
  setupSignatureData,
  formErrors,
}) => {
  return (
    <div className="mb-6x">
      <div className="row">
        <h3 className="ml-3x">
          {getString(`${LABEL_PREFIX}.individualResponsibleTitle`)}
        </h3>
        <div className="col-12">
          <h3 className="title title--small mb-2x">
            {getString(`${LABEL_PREFIX}.fullName`)}
          </h3>
          <div className="col-6 px-0x">
            <FormInput
              id="setupSignatureData"
              labelClassName="label-placeholder label-placeholder--required"
              label={getString(`${LABEL_PREFIX}.fullName`)}
              value={setupSignatureData ?? ''}
              hasError={!!formErrors?.['setupSignatureData']}
              solo
              required
              disabled={!isEdit}
              onChange={(_id, value) =>
                setDeliveryTicketHandler(value, 'setupSignatureData')
              }
            />
          </div>
          <p className="desc desc--xs color-grey-text-light mt-1x mb-0x">
            {getString(`${LABEL_PREFIX}.disclaimer`)}
          </p>
        </div>
      </div>
    </div>
  );
};
