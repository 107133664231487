import { useCallback, useEffect, useMemo } from 'react';

import { useApplicationContext, useDetailContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { usePatientInfo } from '../../PatientInformation/hooks/usePatientInfo';

import { PageWrapper } from 'components/COVID/common/PageWrapper';
import {
  makeFollowupRegisterNavLinks,
  makeFollowupRegisterNavLinksWithNoPaymentOption,
} from 'components/common/NavBar/navLinks';
import PatientInformation from '../../PatientInformation/container/PatientInformation';
import { changeTitle } from 'util/siteInfo';

export const RegisterPatientInfo = () => {
  changeTitle('Follow Up | Patient Information');
  const {
    details: { pageComplete },
    getContactByAppointmentId,
  } = useDetailContext();

  const { isPaymentOptionEnabled } = useApplicationContext();

  const { PatientInfoProps } = usePatientInfo();

  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();

  useEffect(() => {
    getContactByAppointmentId(appointmentId);
  }, [getContactByAppointmentId, appointmentId]);

  const toNextPage = useCallback(() => {
    isPaymentOptionEnabled
      ? nav.toFollowup.register.paymentInfo(appointmentId)
      : nav.toFollowup.register.confirmation(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      isPaymentOptionEnabled
        ? makeFollowupRegisterNavLinks(
            pageComplete,
            appointmentId,
            organizationId,
          )
        : makeFollowupRegisterNavLinksWithNoPaymentOption(
            pageComplete,
            appointmentId,
            organizationId,
          ),
    [organizationId, pageComplete, appointmentId],
  );

  const goBack = () => nav.goBack();

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <PatientInformation
        {...PatientInfoProps}
        confirmEditService={false}
        organizationId={organizationId}
        goBack={goBack}
        toNextPage={toNextPage}
        disableEdit={true}
        isFollowup={true}
        disabledFieldStatus={{
          firstName: true,
          middleName: true,
          lastName: true,
          birthYear: true,
          birthMonth: true,
          birthDay: true,
        }}
      />
    </PageWrapper>
  );
};
