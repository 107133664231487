import { getString } from "util/lang";
import { Text, View } from "@react-pdf/renderer";

import { useStyles } from "../ABNFormPdf.styles";

export const ABNFormFooter = () => {
    const styles = useStyles;
    return (
        <View style={[styles.footer, styles.row, styles.justifyBetween]}>
            <Text>{getString('dme.documentation.abnForm.footerNoteRight')}</Text>
            <Text>{getString('dme.documentation.abnForm.footerNoteLeft')}</Text>
        </View>
    )
}