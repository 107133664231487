import React, { useEffect } from 'react';
import cls from 'classnames';
import { FiCheck } from 'vyaguta-icons/fi';
import { NavLink, useRouteMatch, withRouter } from 'react-router-dom';

import { getString } from 'util/lang';
import { CovidPageNames } from '../../../../constants/pages';
import * as routes from '../../../../constants/routes/covidRoutes';
import LanguageSelect from '../LanguageSelect';
import { useResetAppState } from '../../../../context/useResetAppState';

/**
 * @param {{bool}} prevPageComplete : it also verifies if access to this page
 * eg if page 1 is complete, access to page 2 is allowed
 * @param {{int}} pageNumber : page number to show
 * @param {{string}} label : title of page
 * @param {{string}} to : route to page
 * @param {{bool}} complete: to show check mark on page nav
 */
const NavTab = (props) => {
  const { pageNumber, label, complete, to, prevPageComplete, loading, dataqa } =
    props;
  const routeMatch = useRouteMatch(to);
  let content = pageNumber;

  useEffect(() => {
    // scroll and snap selected nav to view
    if (routeMatch) {
      let scrollWrapper = document.querySelector('.node__wrapper');
      let scrollElemtr = document.querySelector('.nav__link--active');

      if (scrollElemtr) {
        setTimeout(() => {
          let scroller = scrollElemtr.parentElement;
          scrollWrapper.scrollLeft = scroller && scroller.offsetLeft;
        }, 10);
      }
    }
  }, [routeMatch]);

  if (complete) {
    content = <FiCheck size={14} className="icon" />;
  }

  if (loading) {
    return (
      <div className="nav__node">
        <div
          className={`nav__link${complete ? ' nav__link--complete' : ''} nav__link--disabled`}
        >
          <span data-qa={dataqa}>{content}</span>
          {label}
        </div>
      </div>
    );
  }

  return (
    <div className="nav__node">
      {prevPageComplete && (
        <NavLink
          to={{ pathname: to, search: window.location.search }}
          className={`nav__link${complete ? ' nav__link--complete' : ''}`}
          activeClassName="nav__link--active"
        >
          <span data-qa={dataqa}>{content}</span>
          {label}
        </NavLink>
      )}
      {!prevPageComplete && (
        <div
          className={`nav__link${complete ? ' nav__link--complete' : ''} nav__link--disabled`}
        >
          <span data-qa={dataqa}>{content}</span>
          {label}
        </div>
      )}
    </div>
  );
};

const NavBar = ({
  pageComplete,
  rescheduleComponent = false,
  navRef,
  displayNavLinks = true,
  logo,
  match,
  loading,
  isLandingPage,
}) => {
  const { id: activityId, organizationId } = match.params;
  const homeRoute = `${routes.SERVICE}${!!organizationId ? '/' + organizationId : ''}`;
  const resetAppState = useResetAppState();

  const refreshPage = () => {
    resetAppState();

    const queryParams = window.location.search;
    window.location.href = homeRoute + queryParams;
  };

  if (rescheduleComponent) {
    return (
      <>
        <div className="navbar__header" ref={navRef}>
          {!!logo && (
            <h1 className="navbar__logo text-center">
              <img
                src={logo}
                onClick={refreshPage}
                alt={getString('organizationLogo')}
                style={{ cursor: 'pointer' }}
              />
            </h1>
          )}
        </div>
        <nav className="navbar navbar--sticky">
          <div className="nav">
            <div className="d-flex justify-content-center-md node__wrapper">
              <NavTab
                to={`${routes.RESCHEDULE_CLINIC_AND_TIMING_ROOT}/${activityId}${
                  organizationId ? '/' + organizationId : ''
                }`}
                prevPageComplete={true}
                complete={false}
                label={getString('scheduleAppointment')}
                pageNumber="1"
                dataqa="reschedule-schedule"
              />
              <NavTab
                to={`${routes.RESCHEDULE_CONFIRMATION_ROOT}/${activityId}${organizationId ? '/' + organizationId : ''}`}
                prevPageComplete={true}
                complete={false}
                label={getString('confirmation')}
                loading={loading}
                pageNumber="2"
                dataqa="reschedule-confirm"
              />
            </div>
          </div>
        </nav>
      </>
    );
  }

  return (
    <>
      <div
        className={cls(
          'navbar__header',
          isLandingPage && 'navbar__header--landing-page',
        )}
      >
        <div className="navbar__logo text-center">
          {!!logo && (
            <>
              <div className="navbar__logo text-center">
                <img
                  src={logo}
                  onClick={refreshPage}
                  alt={getString('organizationLogo')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </>
          )}
        </div>
        <LanguageSelect />
      </div>
      {displayNavLinks && (
        <nav className="navbar navbar--sticky" ref={navRef}>
          <div className="nav">
            <div className="d-flex justify-content-center-lg node__wrapper">
              <NavTab
                to={`${routes.CLINIC_AND_TIMING}${organizationId ? '/' + organizationId : ''}`}
                pageNumber="1"
                complete={pageComplete[CovidPageNames.SelectClinicAndTiming]}
                prevPageComplete={pageComplete[CovidPageNames.BeforeApproval]}
                label={getString('scheduleAppointment')}
                loading={false}
                dataqa="nav-schedule"
              />
              <NavTab
                to={`${routes.HOME}${organizationId ? '/' + organizationId : ''}`}
                pageNumber="2"
                complete={pageComplete[CovidPageNames.PatientEligibility]}
                prevPageComplete={
                  pageComplete[CovidPageNames.SelectClinicAndTiming]
                }
                label={getString('patientEligibility')}
                loading={loading}
                dataqa="nav-eligibility"
              />
              <NavTab
                to={`${routes.PATIENT_INFO}${organizationId ? '/' + organizationId : ''}`}
                pageNumber="3"
                complete={pageComplete[CovidPageNames.PatientInfo]}
                prevPageComplete={
                  pageComplete[CovidPageNames.PatientEligibility]
                }
                label={getString('patientInfoAndConfirm')}
                loading={loading}
                dataqa="nav-patient-info"
              />
              <NavTab
                to={`${routes.CONFIRMATION}${organizationId ? '/' + organizationId : ''}`}
                pageNumber="4"
                complete={pageComplete[CovidPageNames.Confirmation]}
                prevPageComplete={pageComplete[CovidPageNames.PatientInfo]}
                label={getString('confirmation')}
                loading={loading}
                dataqa="nav-confirmation"
              />
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default withRouter(NavBar);
