/**
 * Takes a 10 digit number and formats it as a phone number
 * (XXX) XXX-XXXX
 * @param {number} number - 10 digit phone number
 * @returns {string} formatted phone number
 */
export const contactMasking = (number) => {
  if (!number) return;

  const str = number.toString();

  // Regex to check if the string is already in (XXX) XXX-XXXX format
  const formattedPhoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

  if (formattedPhoneRegex.test(str)) {
    return str; // Return the string as is if it's already formatted
  }

  if (str.length !== 10) {
    return str; // Return the string as is if it's not exactly 10 digits
  }

  const areaCode = str.slice(0, 3);
  const centralOfficeCode = str.slice(3, 6);
  const lineNumber = str.slice(6);

  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};

/**
 * Formats a 9-digit ZIP code into the XXXXX-XXXX format.
 *
 * @param {number|string} zipcode - The 9-digit ZIP code to format.
 * @returns {string} The formatted ZIP code in XXXXX-XXXX format.
 *
 */
export const zipCodeMasking = (zipcode) => {
  if (!zipcode) return;

  const str = zipcode.toString();

  // Regex to check if the string is already in XXXXX-XXXX format
  const formattedZipRegex = /^\d{5}-\d{4}$/;

  if (formattedZipRegex.test(str)) {
    return str; // Return the string as is if it's already formatted
  }

  if (str.length <= 5) {
    return str; // Return the string as is if it has 5 or fewer digits
  }
  const primary = str.slice(0, 5);
  const extension = str.slice(5);
  return `${primary}-${extension}`;
};
