import {
  DME_DOCUMENT,
  DME_DELIVERY_PACKET,
  DME_DELIVERY_TICKET,
  DME_DELIVERY_PACKET_PRINT,
  DME_DELIVERY_TICKET_PRINT,
} from 'constants/routes/covidRoutes';
import { REDIRECTED_TO_DME_VERIFY_FROM } from '../constants/redirectedToDMEVerifyFrom';

const getRedirectedToDMEVerifyFrom = (path: string): string | null => {
  switch (true) {
    case path.startsWith(DME_DELIVERY_TICKET) ||
      path.startsWith(DME_DELIVERY_TICKET_PRINT):
      return REDIRECTED_TO_DME_VERIFY_FROM.DELIVERY_TICKET;

    case path.startsWith(DME_DELIVERY_PACKET) ||
      path.startsWith(DME_DELIVERY_PACKET_PRINT):
      return REDIRECTED_TO_DME_VERIFY_FROM.DELIVERY_PACKET;

    case path.startsWith(DME_DOCUMENT):
      return REDIRECTED_TO_DME_VERIFY_FROM.DOCUMENT;

    default:
      return null;
  }
};

export default getRedirectedToDMEVerifyFrom;
