import { useDetailContext } from 'context';
import styles from './DmeHeader.module.scss';
import { FiChevronLeft, FiChevronRight, FiMenu } from 'vyaguta-icons/fi';
import Button from 'components/common/Button';
import { iconPrintPdf, iconShare } from 'assets/images';

const sideNavItems = [
  {
    key: 'delivery_ticket',
    value: 'Delivery Ticket',
  },
  {
    key: 'authorization_form',
    value: 'Authorization Form',
  },
  {
    key: 'bill_of_right_and_responsibilities',
    value: 'Bill of Right and Responsibilities',
  },
  {
    key: 'notice_of_privacy_practices',
    value: 'Notice of privacy practices',
  },
  {
    key: 'education_booklet',
    value: 'Education booklet',
  },
  {
    key: 'general_home_safety_information',
    value: 'General Home Safety Information',
  },
  {
    key: 'medicare_DME/POS_supplier_standard',
    value: 'Medicare DME/POS supplier standard',
  },
  {
    key: 'equipment_operating_instruction',
    value: 'Equipment Operating instruction',
  },
];

const SideNav = ({ isOpen, setIsOpen }) => (
  <div className={`d-flex flex-column ${styles.sideNavWrap}`}>
    <div>
      <SideNavToggle isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
    <ul>
      {sideNavItems.map((item) => (
        <li key={item.key}>{item.value}</li>
      ))}
    </ul>
  </div>
);

const SideNavToggle = ({ isOpen, setIsOpen }) => (
  <button
    className={`d-flex justify-content-start align-items-center cursor-pointer ${styles.sideNavToggle}`}
    onClick={() => setIsOpen(!isOpen)}
  >
    <FiMenu size={16} />
    {isOpen ? <span>Hide Menu</span> : <span>Overview</span>}
  </button>
);

const PrevNextButtons = () => (
  <div className={`d-flex justify-content-end ${styles.dmePrevNextBtn}`}>
    <button className="d-flex justify-content-start align-items-center cursor-pointer">
      <FiChevronLeft size={20} />
      <span>Previous</span>
    </button>
    <button className="d-flex justify-content-end align-items-center cursor-pointer">
      <span>Next</span>
      <FiChevronRight size={20} />
    </button>
  </div>
);

const DmeHeader = ({
  headerText,
  hideSubHeader = false,
  handlePrint,
  handleSharePdf,
  isSharePdfLoading = false,
}) => {
  const { logoUrl, organizationName } = useDetailContext().getAppSettings();

  return (
    <div className="d-flex flex-column">
      <div className={styles.dmeHeaderTop}>
        <div className="group-container d-flex justify-content-between align-items-center">
          <div className={styles.dmeLogoWrapper}>
            <img
              src={logoUrl}
              alt={organizationName}
              className={styles.dmeLogo}
            />
          </div>
          <h1>{headerText}</h1>
          <div className={styles.buttonGroup}>
            {handlePrint && (
              <Button
                title="Print PDF"
                isEnabled
                onClick={handlePrint}
                className={`btn btn-primary--outline ${styles.button}`}
                isActive
                label="Print PDF"
                icon={
                  <img
                    src={iconPrintPdf}
                    alt="print-pdf"
                    className={styles.buttonIcon}
                  />
                }
              />
            )}
            {handleSharePdf && (
              <Button
                title="Share PDF"
                isEnabled={!isSharePdfLoading}
                onClick={handleSharePdf}
                className={`btn btn-primary--outline ${styles.button}`}
                isActive
                label="Share PDF"
                icon={
                  <img
                    src={iconShare}
                    alt="share-pdf"
                    className={styles.buttonIcon}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
      {/* {
                !hideSubHeader && (
                    <div className={`d-flex ${styles.dmeSubHeader}`}>
                        <SideNavToggle isOpen={isOpen} setIsOpen={setIsOpen} />
                        <PrevNextButtons />
                        {
                            isOpen && (
                                <SideNav isOpen={isOpen} setIsOpen={setIsOpen} />
                            )
                        }
                    </div>
                )
            } */}
    </div>
  );
};

export default DmeHeader;
