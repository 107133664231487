import { useCallback, useEffect, useMemo } from 'react';

import { useDetailContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { usePatientInfo } from '../../PatientInformation/hooks/usePatientInfo';

import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { makeFollowupRegisterNavLinks } from 'components/common/NavBar/navLinks';
import PaymentInfo from '../../PaymentInfo/container/PaymentInfo';
import { changeTitle } from 'util/siteInfo';

export const RegisterPaymentInfo = () => {
  changeTitle('Follow Up | Payment Information');
  const {
    details: { pageComplete },
    getContactByAppointmentId,
  } = useDetailContext();

  const { PatientInfoProps } = usePatientInfo();

  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();
  const toNextPage = useCallback(() => {
    nav.toFollowup.register.confirmation(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      makeFollowupRegisterNavLinks(pageComplete, appointmentId, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  const goBack = () => nav.goBack();

  useEffect(() => {
    getContactByAppointmentId(appointmentId);
  }, [getContactByAppointmentId, appointmentId]);

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <PaymentInfo
        {...PatientInfoProps}
        confirmEditService={false}
        organizationId={organizationId}
        goBack={goBack}
        toNextPage={toNextPage}
        disableEdit={true}
        isFollowup={true}
        disabledFieldStatus={{
          firstName: true,
          middleName: true,
          lastName: true,
          birthYear: true,
          birthMonth: true,
          birthDay: true,
        }}
      />
    </PageWrapper>
  );
};
