import React from 'react';
import Skeleton from 'react-loading-skeleton';

const HomePageSkeletonScreen = () => {
  return (
    <div
      className="skeleton-screen"
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
    >
      <div className="navbar__header" style={{ marginBottom: 'auto' }}>
        <div className="navbar__logo text-center">
          <Skeleton height={40} width={200} />
        </div>
      </div>

      <div className="nlanding-page">
        <div className="container-lg">
          <div className="nlanding-page__container">
            <div className="nlanding-page__brand-image">
              <Skeleton height={734} />
            </div>

            <div className="nlanding-page__form-container">
              <div className="nlanding-page__brand">
                <h1 className="nlanding-page__brand-title">
                  <span className="nlanding-page__brand-title__placeholder">
                    <Skeleton />
                  </span>
                  <Skeleton />
                </h1>
                <p className="nlanding-page__brand-helper">
                  <Skeleton />
                </p>
              </div>
              <div className="service-selection border-rounded mb-4x">
                <Skeleton height={150} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer" style={{ marginTop: 'auto' }}>
        <div className="container-lg">
          <div className="footer__wrap">
            <div className="footer__logo">
              <div className="footer__logo__image">
                <Skeleton height={36} width={65} />
              </div>
              <div className="footer__logo__date">
                <Skeleton height={24} width={54} />
              </div>
            </div>

            <div className="footer__links">
              <a href="" className="mr-8x">
                <Skeleton height={24} width={100} />
              </a>
              <a href="">
                <Skeleton height={24} width={104} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePageSkeletonScreen;
