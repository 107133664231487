import { getString } from 'util/lang';
import PharmacyInfo from '../../common/PharmaInfo/PharmacyInfo';
import { supplierStandards } from '../../../constants/supplierStandard';
import styles from './SupplierStandard.module.scss';
import FormHeader from '../../common/FormHeader/FormHeader';

const LABEL_PREFIX = 'dme.deliveryPacketForm.supplierStandard';

const SupplierStandard = () => {
  return (
    <div className={`group-container ${styles.standardWrap}`}>
      <FormHeader text={getString(`${LABEL_PREFIX}.title`)} />
      <PharmacyInfo className="mt-6x" />
      <div>
        <p className={styles.standardNotes}>
          {getString(`${LABEL_PREFIX}.note`)}
        </p>
      </div>
      <ul className={styles.standardUl}>
        {supplierStandards.map((supplierStandard, key) => (
          <li key={key} className={styles.standardLi}>
            {supplierStandard}
          </li>
        ))}
      </ul>
      <div className={styles.lineBreak}></div>
      <div className={styles.footer}>
        <p className="mb-0">{getString(`${LABEL_PREFIX}.organization.name`)}</p>
        <p className="mb-0">
          {' '}
          {getString(`${LABEL_PREFIX}.organization.label`)}
        </p>
        <p className="mb-0">
          {getString(`${LABEL_PREFIX}.organization.address`)}
        </p>
        <p className="mb-0">
          {getString(`${LABEL_PREFIX}.organization.designation`)}
        </p>
      </div>
    </div>
  );
};

export default SupplierStandard;
