import React, { useContext, useEffect } from 'react';

import Router from './CovidRoutes';
import { DetailContext } from '../context';
import UnderMaintenance from '../components/COVID/views/UnderMaintenance';
import HomePageSkeletonScreen from '../components/COVID/views/HomePageSkeletonScreen';
import InvalidConfigurationRedirect from 'components/COVID/views/InvalidConfigurationRedirect/InvalidConfigurationRedirect';

function RouterRegisterer() {
  const context = useContext(DetailContext);

  useEffect(() => {
    context.fetchAppConfigurations();
    context.fetchStatesAndCounties();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    logoUrl,
    isUnderMaintenance,
    organizationContactEmail,
    organizationContactPhone,
    organizationName,
    isAppSettingsSet,
    isInValidConfig,
    isError,
    upTime,
  } = context.details.appSettings;

  if (!isAppSettingsSet) {
    return <HomePageSkeletonScreen />;
  }

  if (isInValidConfig) {
    return <InvalidConfigurationRedirect />;
  }

  if (isUnderMaintenance || isError) {
    return (
      <UnderMaintenance
        logo={logoUrl}
        email={organizationContactEmail}
        organizationName={organizationName}
        phoneNo={organizationContactPhone}
        upTime={upTime}
      />
    );
  }

  return <Router />;
}

export default RouterRegisterer;
