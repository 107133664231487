import React, { useState } from 'react';
import { FiSearch } from 'vyaguta-icons/fi';
import SyringeIcon from 'svg-images/SyringeIcon';

import { getString } from 'util/lang';
import LocationFilter from './LocationFilter';
import Button from 'components/common/Button';
import { MAP_VISIBLITY } from 'constants/mapSettings';

const MobileClinicSearchBar = (props) => {
  const {
    selectedServices,
    searchTerm,
    onSearchTermChange,
    onSearch,
    showMobileMap,
    toggleMobileMap,
    mapVisibility,
  } = props;
  const [showSubMenu, setShowSubMenu] = useState(false);

  const showMap =
    mapVisibility === MAP_VISIBLITY.SHOW ||
    mapVisibility === MAP_VISIBLITY.ALLOW_TOGGLE;

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  const handleSearchClick = () => {
    onSearch();
    toggleSubMenu();
  };

  return (
    <React.Fragment>
      <div
        className={`multiservice-search d-lg-none ${showMobileMap ? 'multiservice-search-fixed' : ''}`}
      >
        <div className="container">
          <div className="d-flex">
            <div
              className="patient-form-group ms-location-search "
              onClick={() => toggleSubMenu()}
            >
              <div className="form-group">
                <button className="form-control form-control-w-icon text-ellipses text-left">
                  {selectedServices
                    .map((eachService) => eachService.service.name)
                    .join(', ')}
                </button>
                <div className="form-control-icon">
                  <SyringeIcon size={22} className="color-primary-base" />
                </div>
                <div className="form-control-dropdown">
                  <FiSearch size={20} />
                </div>
              </div>
            </div>
            {showMap && (
              <Button
                isEnabled={true}
                label={showMobileMap ? getString('list') : getString('map')}
                onClick={toggleMobileMap}
                className="btn btn-primary--inverse text-uppercase ml-3x"
                dataqa="map-toggle"
              />
            )}
          </div>
        </div>
        {showSubMenu && (
          <div className={'container-lg d-lg-none mt-4x'}>
            <div className="patient-form-group mb-10x-sm mb-6x">
              <div className="row">
                <div className="col-8-lg">
                  <LocationFilter
                    searchTerm={searchTerm}
                    onSearchTermChange={onSearchTermChange}
                    onSearch={onSearch}
                    searchContainer="mobile-ms-location-search-container"
                    searchInput="mobile-ms-location-search-input"
                    searchButton="mobile-ms-location-search-button"
                  />
                </div>
              </div>
            </div>

            <Button
              isEnabled={true}
              label={getString('search')}
              onClick={handleSearchClick}
              className="btn btn-primary btn-block ms-search-button w-100-sm d-block d-sm-none mb-2x"
              dataqa="mobile-clinics-search"
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default MobileClinicSearchBar;
