import config from '../config';
import * as http from '../util/http';
import * as dateUtil from '../util/DateAndTime';
export { createAppointment } from './create-appointment';
export { updateAppointment } from './update-appointment';

export async function getAppointmentById(appointmentId, accessToken) {
  const url = config.entity.appointmentById(appointmentId);

  const { data } = await http.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data.data.map((appointment) => {
    return {
      date: new Date(appointment.smvs_appintmentdate),
      time: dateUtil.convertTo12HourTimeFormat(
        appointment.smvs_appointmenttime,
      ),
      ...appointment,
    };
  });
}

export async function confirmAppointmentForFollowUp(appointmentId, secretKey) {
  const url = config.entity.confirmFollowupAppointment(appointmentId);

  const { data } = await http.patch(url, { secretKey });

  return data.data;
}

export async function uploadFile(activityid, fieldName, fileData, token) {
  const form = new FormData();
  form.append('activityId', activityid);
  form.append('fieldName', fieldName);
  form.append('file', fileData);

  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  };

  await http.patch(config.entity.uploadFile(activityid), form, headers);
}

export async function uploadInsuranceFile(
  insuranceId,
  insuranceFront,
  insuranceBack,
  token,
) {
  const form = new FormData();
  form.append('insurance_front', insuranceFront);
  form.append('insurance_back', insuranceBack);

  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  };

  await http.patch(
    config.entity.uploadInsuranceFiles(insuranceId),
    form,
    headers,
  );
}

export async function rescheduleAppointments(
  flatAppointments,
  captchaResponseKey,
  primaryServiceCheckOnly,
  accessToken,
) {
  const { end_time, ...bookingBlockInput } =
    flatAppointments[0]?.bookingBlock || {};

  const blockDetail = {
    serviceCodes: flatAppointments.map(({ serviceCode }) => serviceCode),
    venueCode: flatAppointments[0].venueCode,
    date: flatAppointments[0]?.date,
    bookingBlock: bookingBlockInput,
    primaryServiceCheckOnly,
  };

  const primaryAppointment = flatAppointments[0];
  if (!primaryAppointment) {
    throw new Error('no appointments provided to rescheduleAppointments');
  }

  const primaryAppointmentId = primaryAppointment.activityid;
  const appointmentDateTime = dateUtil.convertDateAndTimeToUTC(
    primaryAppointment.date,
    primaryAppointment.time,
  );

  const url = config.entity.rescheduleAppointmentById(primaryAppointmentId);

  const { data } = await http.post(
    url,
    { appointmentDateTime, blockDetail, captchaResponseKey },
    {
      Authorization: `Bearer ${accessToken}`,
    },
  );

  return data;
}

export async function groupRescheduleAppointments(
  flatAppointments,
  captchaResponseKey,
  primaryServiceCheckOnly,
  accessToken,
  groupAppointmentId,
  isIndividualReschedule = false,
) {
  if (!groupAppointmentId) {
    throw new Error('Cannot reschedule group appointment');
  }

  const primaryAppointment = flatAppointments[0];

  if (!primaryAppointment) {
    throw new Error('No appointments provided to rescheduleAppointments');
  }

  const { end_time, ...bookingBlockInput } =
    primaryAppointment.bookingBlock || {};

  const blockDetail = {
    serviceCodes: flatAppointments.map(({ serviceCode }) => serviceCode),
    venueCode: primaryAppointment.venueCode,
    date: primaryAppointment.date,
    bookingBlock: bookingBlockInput,
    primaryServiceCheckOnly,
  };

  const primaryAppointmentId = primaryAppointment.activityid;
  const appointmentDateTime = dateUtil.convertDateAndTimeToUTC(
    primaryAppointment.date,
    primaryAppointment.time,
  );

  const url =
    config.entity.rescheduleGroupAppointmentByGroupAppointmentId(
      groupAppointmentId,
    );

  const requestPayload = isIndividualReschedule
    ? {
        appointmentDateTime,
        blockDetail,
        captchaResponseKey,
        isIndividualReschedule: true,
      }
    : {
        appointmentDateTime,
        blockDetail,
        captchaResponseKey,
        isIndividualReschedule: false,
      };
  const { data } = await http.post(url, requestPayload, {
    Authorization: `Bearer ${accessToken}`,
  });

  return data;
}

export function cancelAppointment(activityId, captchaResponseKey, accessToken) {
  const url = config.entity.appointmentById(activityId);

  return http.remove(
    url,
    { captchaResponseKey: captchaResponseKey },
    {
      Authorization: `Bearer ${accessToken}`,
    },
  );
}

export function cancelGroupAppointment(activityId, data, accessToken) {
  const url = config.entity.appointmentByGroup(activityId);
  return http.remove(url, data, {
    Authorization: `Bearer ${accessToken}`,
  });
}

export async function getContactByAppointmentId(appointmentId, accessToken) {
  const url = config.entity.getContactByAppointmentId(appointmentId);

  const { data } = await http.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data.data;
}

/**
 * @typedef {{
 *  followup: boolean
 *  late: boolean
 *  subServiceIds?: string[]
 * }} FollowupStatus
 *
 * @param {string} appointmentId
 * @returns {Promise<FollowupStatus>}
 */
export async function checkFollowupStatus(appointmentId) {
  const url = config.entity.followupStatus(appointmentId);

  const { data } = await http.get(url);

  return data.data;
}

/**
 *
 * @param appointmentId
 * @returns {Promise<*>}
 * NOTE: the status: PENDING in the response of this request is different from booking status
 */
export async function checkGroupAppointmentStatus(appointmentId) {
  const url = config.entity.groupAppointmentStatus(appointmentId);

  const { data } = await http.get(url);

  return data.data;
}
