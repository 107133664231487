/**
 * Helper function to determine if any of the selected services 
 * has the `collectRxDetails` property set to `true`.
 * 
 * @param {Array} selectedServices 
 * 
 * @returns {boolean} - Returns `true` if any service has `collectRxDetails` set to `true`, otherwise `false`.
 * 
 * Usage:
 * const collectRxDetails = checkMultiserviceRxDetails(selectedServices);
 */
const checkMultiserviceRxDetails = (selectedServices) => {
  return (
    selectedServices?.some(
      (services) => services.service?.collectRxDetails === true
    ) || false
  );
};

export default checkMultiserviceRxDetails;
