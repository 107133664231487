import { uniqBy } from 'lodash';
import config from 'config';
import * as http from 'util/http';
import { termsAndConditonsAcceptanceParser } from 'util/templateParser';
import { getString } from 'util/lang';
import { generateFixedUUID } from 'util/uuid';

export const mapTermsAndConditionAcceptance = (data, selectedService) => ({
  ...data,
  termsAndConditionsAcceptance: termsAndConditonsAcceptanceParser(
    data.termsAndConditionsAcceptance,
    selectedService,
  ),
});

export async function fetchLocalizedWebContents(selectedLanguageId, serviceId) {
  const url = `${config.entity.localizedWebContents}/${serviceId}/${selectedLanguageId}`;
  const { data } = await http.get(url);

  return data.data;
}

export function addFactSheetUrlToTAC(localizedWebContents, selectedServices) {
  if (!localizedWebContents || !localizedWebContents.length) return null;

  const factsheetLinks = selectedServices.map((ss, idx) => {
    const factsheetLink =
      ss.factsheetUrl &&
      getString(
        'iHaveRead',
        `<a href="${ss.factsheetUrl}" target="_blank" rel="noopener noreferrer">
          ${ss.name} Fact Sheet
        </a>`,
      );
    if (factsheetLink)
      return {
        id: `${generateFixedUUID(factsheetLink)}_${ss.id}`,
        label: factsheetLink,
      };
    else return null;
  });

  const tacAcceptance = (
    localizedWebContents[0].termsAndConditionsAcceptance || []
  )
    .map((item) => {
      // if label includes these terms, replace the entry with appropriate text
      if (item.label.includes('{{primaryfactsheet}}')) {
        return factsheetLinks[0] ?? null;
      }
      if (item.label.includes('{{secondaryfactsheet}}')) {
        return factsheetLinks.slice(1);
      }
      return item;
    })
    .flat()
    .filter((i) => i);

  localizedWebContents[0] = {
    ...localizedWebContents[0],
    termsAndConditionsAcceptance: uniqBy(tacAcceptance, 'id'),
  };

  return localizedWebContents;
}
