import * as http from '../util/http';
import config from '../config';

/**
 * Verify if registration code is valid
 *
 */
export async function verifyRegistrationCode(formValues) {
  const { data } = await http.post(config.entity.codeRegistration, formValues);
  return data;
}
