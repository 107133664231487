import React from 'react';

export const SubServiceRadioInput = ({
  value,
  name,
  selected,
  dataqa,
  title,
  notice,
  onChange,
}) => {
  return (
    <div className="custom-radio mb-6x">
      <input
        id={value}
        name={name}
        type="radio"
        className="custom-radio-input"
        onChange={onChange}
        checked={selected}
        data-qa={dataqa}
        autoComplete="off"
      />
      <label htmlFor={value}>
        <div>
          <div className="sub-title">{title}</div>
          <div>{notice}</div>
        </div>
      </label>
    </div>
  );
};
