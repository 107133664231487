import moment from 'moment';

// Get list of days for single dose vaccines
export const getSingleDoseDays = (clinicDays) => {
  if (!clinicDays) return [];
  const singleDoseDays = clinicDays
    .filter((day) => {
      const isSlotAvailable = checkSlotAvailability(day);
      return isSlotAvailable;
    })
    .map((day) => day.clinicDate);
  return singleDoseDays;
};

// Get list of days for single dose vaccines
/**
 *
 * @param {{clinicDate: Date}[]} clinicDays
 */
export const getSingleSlotsDays = (clinicDays) => {
  if (!clinicDays || !clinicDays.length) return [];
  const singleDoseDays = clinicDays.map((day) => day.clinicDate);
  return singleDoseDays;
};

// Get list of days for first dose of double dose vaccines
export const getFirstDoseDays = (clinicDays, vaccineDetails) => {
  if (!clinicDays) return [];
  const dayDifference = getDayDifferenceArray(vaccineDetails);
  const firstDoseDays = clinicDays
    .filter((day) => {
      const firstDateMoment = moment(day.clinicDate);
      if (day.smvs_version === null) {
        const isSlotAvailable = checkSlotAvailability(day);
        const secondDoseClinicDay = clinicDays.find((date) => {
          const expectedSecondDateMoment = moment(date.clinicDate);
          const dateDiff = expectedSecondDateMoment.diff(
            firstDateMoment,
            'days',
          );
          if (dayDifference.includes(dateDiff) && isSlotAvailable) {
            return !!date;
          }
          return false;
        });
        return (
          !!secondDoseClinicDay &&
          day.smvs_eligible_for_first_appointment &&
          isSlotAvailable
        );
      } else if (
        (day.smvs_version === 1 || day.smvs_version === 3) &&
        day._smvs_associated_first_appointmentid_value === null
      ) {
        const secondDoseClinicDay = clinicDays.find((date) => {
          return (
            day.msemr_slotid === date._smvs_associated_first_appointmentid_value
          );
        });
        const isFirstSlotAvailable = checkSlotAvailability(day);
        const isSecondSlotAvailable =
          secondDoseClinicDay && checkSlotAvailability(secondDoseClinicDay);
        const isBothSlotAvailable =
          isFirstSlotAvailable && isSecondSlotAvailable;
        return !!secondDoseClinicDay && isBothSlotAvailable;
      }
      return false;
    })
    .map((day) => day.clinicDate);
  return firstDoseDays;
};

// Get list of days for first dose of double dose vaccines
export const getFirstSlotDays = (clinicDays, vaccineDetails) => {
  if (!clinicDays) return [];
  const dayDifference = getDayDifferenceArray(vaccineDetails);
  const firstDoseDays = clinicDays
    .filter((day) => {
      const firstDateMoment = moment(day.clinicDate);
      const isSlotAvailable = day.block_count > 0;
      const secondDoseClinicDay = clinicDays.find((date) => {
        const expectedSecondDateMoment = moment(date.clinicDate);
        const dateDiff = expectedSecondDateMoment.diff(firstDateMoment, 'days');
        if (dayDifference.includes(dateDiff) && isSlotAvailable) {
          return !!date;
        }
        return false;
      });
      return !!secondDoseClinicDay && isSlotAvailable;
    })
    .map((day) => day.clinicDate);
  return firstDoseDays;
};

// Get list of days for second dose of double dose vaccines
export const getSecondSlotDays = (
  clinicDays,
  firstDoseDate,
  firstClinicDay,
  vaccineDetails,
) => {
  if (!clinicDays || !firstDoseDate || !firstClinicDay) return [];
  const dayDifference = getDayDifferenceArray(vaccineDetails);
  const actualDate = moment(firstDoseDate).toDate();
  const baseDate = moment(actualDate).add(Math.min(...dayDifference), 'days');
  const endDate = moment(actualDate).add(Math.max(...dayDifference), 'days');
  const secondDoseDays = clinicDays
    .filter((clinicDate) => {
      const candidateDate = moment(clinicDate.clinicDate).startOf('day');
      const secondDateExists =
        candidateDate.isSameOrAfter(baseDate) &&
        candidateDate.isSameOrBefore(endDate);
      const isSlotAvailable = clinicDate.block_count > 0;
      return !!secondDateExists && isSlotAvailable;
    })
    .sort((a, b) => {
      if (a.clinicDate > b.clinicDate) {
        return 1;
      } else {
        return -1;
      }
    });
  const secondDoseDates = secondDoseDays.map((date) => date.clinicDate);
  return secondDoseDates;
};

export const getDefaultDayForSecondSlotDays = (slotDate) => {
  if (!slotDate) {
    return [];
  }
  const actualDate = moment(slotDate).toDate();
  return actualDate;
};

// Get list of days for second dose of double dose vaccines
export const getSecondDoseDays = (
  clinicDays,
  firstDoseDate,
  firstClinicDay,
  vaccineDetails,
) => {
  if (!clinicDays || !firstDoseDate || !firstClinicDay) return [];
  const dayDifference = getDayDifferenceArray(vaccineDetails);
  const actualDate = moment(firstDoseDate).toDate();
  const baseDate = moment(actualDate).add(Math.min(...dayDifference), 'days');
  const endDate = moment(actualDate).add(Math.max(...dayDifference), 'days');
  const secondDoseDays = clinicDays
    .filter((clinicDate) => {
      const candidateDate = moment(clinicDate.clinicDate).startOf('day');
      if (clinicDate.smvs_version === null) {
        const secondDateExists =
          candidateDate.isSameOrAfter(baseDate) &&
          candidateDate.isSameOrBefore(endDate);
        const isSlotAvailable =
          !!secondDateExists && checkSlotAvailability(clinicDate);
        return !!secondDateExists && isSlotAvailable;
      } else if (
        clinicDate.smvs_version === 1 ||
        clinicDate.smvs_version === 3
      ) {
        const isSecondDoseClinicDay =
          clinicDate._smvs_associated_first_appointmentid_value ===
          firstClinicDay.msemr_slotid;
        const isSlotAvailable =
          !!isSecondDoseClinicDay && checkSlotAvailability(clinicDate);
        return isSecondDoseClinicDay && isSlotAvailable;
      }
      return false;
    })
    .sort((a, b) => {
      if (a.clinicDate > b.clinicDate) {
        return 1;
      } else {
        return -1;
      }
    });
  const secondDoseDates = secondDoseDays.map((date) => date.clinicDate);
  return secondDoseDates;
};

export const getClinicDayForDate = (clinicDays, date) => {
  if (!clinicDays || !date) return null;
  const dateMoment = moment(date).format('YYYY-MM-DD');
  const clinicDayMatch = clinicDays.find((clinicDay) => {
    const candidateDateMoment = moment(clinicDay.clinicDate).format(
      'YYYY-MM-DD',
    );
    return dateMoment === candidateDateMoment;
  });
  return clinicDayMatch;
};

// Check if slots are available for clinic day
const checkSlotAvailability = (day) => {
  const totaSlotCount = day.timeslots.reduce((totalSlots, timeslot) => {
    return totalSlots + timeslot.slotAvailable;
  }, 0);
  const isSlotAvailable = totaSlotCount !== 0;
  return isSlotAvailable;
};

// Get array of allowed day difference days
const getDayDifferenceArray = (vaccineDetails) => {
  const { dayGap, positiveBuffer, negativeBuffer } = vaccineDetails;
  let differenceArray = [];
  differenceArray.push(dayGap);
  // Adding negative buffer days
  for (let i = 1; i <= negativeBuffer; i++) {
    differenceArray.push(dayGap - i);
  }
  // Adding positive buffer days
  for (let i = 1; i <= positiveBuffer; i++) {
    differenceArray.push(dayGap + i);
  }
  return differenceArray;
};

export function pushDisableTimeBlocksToLastIndexOfArray(slots) {
  if (!slots || !slots.length) {
    return [];
  }
  const unavailableTimeBlocks = [];
  const availableTimeBlocks = [];
  slots.forEach((timeBlock) => {
    if (timeBlock.available_capacity > 0) {
      availableTimeBlocks.push(timeBlock);
    } else if (timeBlock.available_capacity === 0) {
      unavailableTimeBlocks.push(timeBlock);
    }
  });

  return [...availableTimeBlocks, ...unavailableTimeBlocks];
}

export function filterAvailableTimeBlocks(timeBlocks) {
  return timeBlocks.filter((timeBlock) => timeBlock.available_capacity > 0);
}

export function findSelectedTimeBlock(timeBlocks, selectedTime) {
  return timeBlocks.find((timeBlock) => timeBlock.start_time === selectedTime);
}

export function sortSlotsByTime(slots) {
  return slots.sort((a, b) => {
    const aSlot = moment(a.start_time, 'HH:mm:ss');
    const bSlot = moment(b.start_time, 'HH:mm:ss');

    return aSlot.diff(bSlot);
  });
}
