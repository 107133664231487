import React from 'react';

import Question from './Questions';
import AlertMessage from '../../../common/AlertMessage';
import AlertSection from './AlertSection';
import ActivityIndicator from '../../../../common/ActivityIndicator';
import { getString } from 'util/lang';

const Acknowledgement = (props) => {
  const {
    answers,
    onAnswerChange,
    onAdditionalInfoChange,
    showAllAcceptedAlert,
    questions,
    loading,
  } = props;
  const Questions =
    questions &&
    questions.map((question, index) => {
      return (
        <Question
          id={question.id}
          dataqa={`${index}-screening`}
          label={question.id}
          key={question.id}
          answer={answers[question.id]}
          onChange={onAnswerChange}
          question={question.question}
          questionType={question.smvs_question_type} // FIXME: smvs_question_type field missing
          onAdditionalInfoChange={onAdditionalInfoChange}
          enableAdditionalQuestion={question.enableAdditionalQuestion}
          controlType={question.controlType}
          additionalInfoCriteria={question.additionalInfoCriteria}
          additionalQuestionLabel={question.additionalQuestionLabel}
          lastItem={index === questions.length - 1}
        />
      );
    });

  if (loading) {
    return (
      <section className="section__margin">
        <h2> {getString('screeningQuestions')} </h2>
        <div className="section__container border-radius border">
          <ActivityIndicator className="loader--clinic" />
        </div>
      </section>
    );
  }
  return (
    <section className="acknowledgement">
      <div>
        {!!questions.length && (
          <>
            <form>
              <div className="row fs-exclude">{Questions}</div>
            </form>
            <AlertSection answers={answers} questions={questions} />
            <AlertMessage
              type="success"
              message={getString('allAcceptedAlertMessage')}
              isVisible={showAllAcceptedAlert}
              className="mb-5x mt-5x"
              dataqa="success-screening"
            />
          </>
        )}
      </div>
    </section>
  );
};

export default Acknowledgement;
