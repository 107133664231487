import config from '../config';
import * as http from '../util/http';
import { intersectionBy } from 'lodash';

export const InsuranceTypeMap = {
  153940000: 'MEDICARE',
  153940001: 'MEDICARE_ADVANTAGE',
  153940002: 'COMMERCIAL',
  153940003: 'MEDICAID',
  153940004: 'MILITARY_AND_VETERANS'
};

/**
 * Fetch insurance company list
 *
 * @param {string} serviceId : guid
 * @param {keyof InsuranceTypeMap} insuranceTypeCode
 * @returns {import('types').InsuranceOrganization[]} data: List of insurance companies and their ids
 */
export async function fetchPayerListForSingleService(
  serviceId,
  insuranceTypeCode,
) {
  // TODO: use insuranceType directly during/after create application conversion
  const insuranceType = InsuranceTypeMap[insuranceTypeCode];

  if (insuranceTypeCode && !insuranceType) {
    console.error('invalid insurance type', insuranceTypeCode);
  }

  const url = `${config.entity.payerlist}/${serviceId}${insuranceType ? `?insuranceType=${insuranceType}` : ''}`;
  const { data } = await http.get(url);

  return data.data;
}

/**
 * Fetches the list of insurance companies.
 * 
 * @deprecated This method is no longer in use. The payer list is now fetched from the `./insuranceDetail.js` file.
 * 
 * @description
 * 
 * **Old Implementation:**
 * - Insurance data used to be fetched directly from a service and displayed along with pharmacy insurance.
 * 
 * **New Implementation:**
 * - There is no longer a direct link between insurance and the service.
 * - The payer list is now maintained in `smvs_insurance_details` (My Payers List).
 * - Insurance details are fetched from My Payers List, and eligibility checks are performed based on the setup in this list.
 * 
 * @param {string[]} serviceIds - A list of GUIDs representing the services.
 * @param {keyof InsuranceTypeMap} insuranceTypeCode - The insurance type code.
 */

export async function fetchPayerList(serviceIds, insuranceTypeCode) {
  const payerLists = await Promise.all(
    serviceIds.map((serviceId) =>
      fetchPayerListForSingleService(serviceId, insuranceTypeCode),
    ),
  );

  const commonPayerList = intersectionBy(...payerLists, 'guid');

  return commonPayerList;
}
