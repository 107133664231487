import config from '../config';
import * as http from '../util/http';
import {
  convertAppointmentObjectToDynamicsFormat,
  convertPatientInfoObjectToDynamicsFormat,
  getCreateOrUpdateAppointmentPayload,
} from './create-update-appointment';

export async function updateAppointment(params) {
  const { accessToken, appointmentId, ...appointmentParams } = params;

  const {
    appointments,
    patientInfo,
    captchaResponseKey,
    screeningAnswers,
    selfDeclaration,
    eligibilityResponse,
    blockDetail,
  } = getCreateOrUpdateAppointmentPayload({
    ...appointmentParams,
    getPayloadForUpdate: true,
  });
  patientInfo.patientId = appointmentParams.patientRecordInfo.patientId;

  const { data } = await http.patch(
    config.entity.updateAppointment(appointmentId),
    {
      appointments,
      patientInfo,
      captchaResponseKey,
      screeningAnswers,
      selfDeclaration,
      eligibilityResponse,
      blockDetail,
    },
    {
      Authorization: `Bearer ${accessToken}`,
    },
  );

  return data.data;
}

/**
 * Email registration confirmation for group appointment.
 * ConfirmGroupAppointment updates an existing appointment which is in pending state.
 * Group registration with email links sent has null appointments created
 * Upon email confirmation the null appointments are updated with actual values, else
 * the appointment remains in pending state.
 */
export async function confirmGroupAppointment({
  appointments,
  patientInfo,
  eligibilityResponse,
  screeningAnswers,
  organizationId,
  captchaResponseKey,
  selfDeclaration,
  selections,
  selectedLanguageId,
  requiresRegistration,
  registrationCode,
  patientRecordInfo,
  clinic,
  phicureResponse,
  insuranceCoverageList,
  localizedWebContents,
  primaryServiceCheckOnly,
  accessToken,
  appointmentId,
}) {
  patientInfo.mobileNo = patientInfo.mobileNo.replaceAll('-', '');
  patientInfo.physicianPhoneNumber =
    patientInfo.physicianPhoneNumber.replaceAll('-', '');
  patientInfo.patientId = patientRecordInfo.patientId;

  // separator @@
  const termsAndConditionContents = localizedWebContents.map((content) => {
    return `
      ${content.termsAndCondition}
  
      @@
  
      ${content.termsAndConditionsAcceptance.map((item) => item.label).join('\n')}
      `;
  });

  const requestAppointments = appointments.map(
    ({ first: firstAppointment, second: secondAppointment }, idx) =>
      firstAppointment
        ? convertAppointmentObjectToDynamicsFormat({
            patientInfo,
            subService: selections[idx].subService,
            appointment: firstAppointment,
            organizationId,
            appointmentStatus:
              selections[idx].subService.firstAppointmentStatus,
            languageId: selectedLanguageId,
            clinic,
            serviceType: selections[idx].serviceType,
            iisConsent: patientInfo.iisConsent,
            phicureResponse: phicureResponse[idx],
            insuranceCoverage: insuranceCoverageList[idx],
            patientConsentData: termsAndConditionContents[0],
          })
        : secondAppointment
          ? convertAppointmentObjectToDynamicsFormat({
              patientInfo,
              subService: selections[idx].subService,
              appointment: secondAppointment,
              organizationId,
              appointmentStatus:
                selections[idx].subService.secondAppointmentStatus,
              languageId: selectedLanguageId,
              clinic,
              serviceType: selections[idx].serviceType,
              iisConsent: patientInfo.iisConsent,
              phicureResponse: phicureResponse[idx],
              insuranceCoverage: insuranceCoverageList[idx],
              patientConsentData:
                termsAndConditionContents.length === 1
                  ? termsAndConditionContents[0]
                  : termsAndConditionContents[idx],
            })
          : null,
  );

  const patientInfoObj = convertPatientInfoObjectToDynamicsFormat(
    patientInfo,
    requiresRegistration,
    registrationCode,
  );

  const { data } = await http.patch(
    config.entity.confirmGroupAppointment(appointmentId),
    {
      appointments: requestAppointments,
      patientInfo: patientInfoObj,
      captchaResponseKey,
      screeningAnswers,
      selfDeclaration,
      eligibilityResponse,
    },
    {
      Authorization: `Bearer ${accessToken}`,
    },
  );

  return data.data;
}
