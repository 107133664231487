import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { Success } from 'components/COVID/views/Success/container/Success';
import { useGroupAppointmentContext } from '../../../../../context';
import { useEffect } from 'react';

export const GroupEmailRegisterSuccess = () => {
  const { setIsGroupAppointment } = useGroupAppointmentContext();

  useEffect(() => {
    setIsGroupAppointment(true); // show success page of group appointment to handle proper navigation
  }, []);

  return (
    <PageWrapper>
      <Success />
    </PageWrapper>
  );
};
