import { registerLocale } from 'react-datepicker';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import CONFIG from './config';
import APP_LANGUAGES from './constants/appLanguages';

import './assets/sass/style.scss';

import es from 'date-fns/locale/es';
import zhcn from 'date-fns/locale/zh-CN';

import 'moment/locale/es';
import 'moment/locale/zh-cn';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: CONFIG.appInsightInstrumentationKey,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

// Register locale values for react-datepicker
registerLocale(APP_LANGUAGES.spanish, es);
registerLocale(APP_LANGUAGES.chineseSimplified, zhcn);
