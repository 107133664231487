import React from 'react';

/**
 *
 * @param {{
 *  messages: string[]
 *  className?: string
 * }} props
 * @returns
 */
const MessageBulletListBox = (props) => {
  const { messages, className } = props;

  return (
    <div className={`alert alert--list notice-content ${className}`}>
      <ul>
        {messages.map((eachMessage, idx) => {
          return (
            <li key={idx} className={'mt-1x mb-1x'}>
              <div>{eachMessage.message}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MessageBulletListBox;
