import * as routes from 'constants/routes/covidRoutes';

import {
  managerPortal,
  patientPortal,
  staffPortal,
  dashboard,
  billingOperationsPortal,
} from 'assets/images';

export const applicationList = [
  {
    img_src: managerPortal,
    subtitle: 'Manager Portal',
    description:
      'Add pop-up clinics, appointment schedules, and other clinic management tasks.',
    linkName: 'managerApp',
  },
  {
    img_src: staffPortal,
    subtitle: 'Staff Portal',
    description:
      'Create walk-in appointments and record details of patient visits.',
    linkName: 'staffApp',
  },
  {
    img_src: patientPortal,
    subtitle: 'Patient Portal',
    description: 'Let your patient register for your services in advance.',
    link: routes.HOME,
  },
  {
    img_src: dashboard,
    subtitle: 'BI Dashboard',
    description:
      'Broad summary of insurance claims, track scheduled appointment, plan your day, and get overview of your  immunization submission to state registry.',
    linkName: 'dashboard',
  },
  {
    img_src: billingOperationsPortal,
    subtitle: 'Billing Operations Portal',
    description:
      'Optimizes claim management processes and boosts operational efficiency in billing for operators.',
    linkName: 'billingOperationsPortal',
  },
];
