export const arrayMove = (selectedArray, fromIndex, toIndex) => {
  const newArray = [...selectedArray];
  const element = newArray[fromIndex];
  newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, element);

  return newArray;
};

export const sortObjectArraybyField = (unsortedArray, fieldName) => {
  const sortedArray = unsortedArray.sort((a, b) => {
    return a[fieldName] - b[fieldName];
  });
  return sortedArray;
};

export const sortObjectArraybyStringField = (unsortedArray, fieldName) => {
  const sortedArray = unsortedArray.sort((a, b) => {
    let fa = a[fieldName].toLowerCase(),
      fb = b[fieldName].toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};

export const mergeArryaOfObjects = (firstArray, secondArray, key) => {
  const mergedArray = [...firstArray];
  secondArray.forEach((secondArrayItem) => {
    const foundIndex = mergedArray.findIndex(
      (mergedArrayItem) => mergedArrayItem[key] === secondArrayItem[key],
    );
    if (foundIndex !== -1) {
      mergedArray[foundIndex] = secondArrayItem;
    }
  });
  return mergedArray;
};

export const mergeArrayOfArraysByKey = (arrayOfArrays, key) => {
  if (!arrayOfArrays.length) return [];

  const mergedMap = new Map();
  arrayOfArrays.flat().forEach((obj) => {
    const id = obj[key];
    mergedMap.set(id, { ...mergedMap.get(id), ...obj });
  });

  return Array.from(mergedMap.values());
};

export const moveItemToPosition = (list, position, item) => {
  list.splice(position, 0, ...list.splice(list.indexOf(item), 1));
  return list;
};

export function getUniqueArrayOfLiterals(itemArray) {
  if (!itemArray || itemArray.length < 0) {
    return [];
  }
  return [...new Set(itemArray)];
}

export function replaceArrayElementByIndex(itemArray, index, payload) {
  const itemArrayCopy = itemArray.slice();
  itemArrayCopy[index] = payload;
  return itemArrayCopy;
}

export function removeArrayElementByIndex(
  itemArray,
  index,
  maintainLength = false,
  appendPayload,
) {
  if (itemArray?.length < 1) return itemArray;

  const returnData = itemArray.slice().filter((_, idx) => idx !== index);
  if (!maintainLength) return returnData;

  const lengthDifference = itemArray.length - returnData.length;

  return [...returnData, ...getIterable(lengthDifference, appendPayload)];
}

export function removeElementFromArray(
  itemToRemove,
  itemArray = [],
  maintainLength = false,
  appendPayload,
) {
  const returnData = itemArray.filter((e) => e !== itemToRemove);
  if (!maintainLength) return returnData;

  const lengthDifference = itemArray.length - returnData.length;

  return [...returnData, ...getIterable(lengthDifference, appendPayload)];
}

/**
 *
 * @param itemArray
 * @param map - an object whose key represent the array's index
 *              and value represent the value at the array's position at index
 * @returns {*[]}
 */
export function replaceArrayElementsByIndex(itemArray, map = {}) {
  if (!itemArray) return [];
  const itemArrayCopy = itemArray.slice();

  for (const eachIndex of Object.keys(map)) {
    itemArrayCopy[eachIndex] = map[eachIndex];
  }

  return itemArrayCopy;
}

export function getIterable(noOfItems, fillItem = null) {
  return Array.from({ length: noOfItems }, () => fillItem);
}

/**
 *
 * @param arrayOfItems
 */
export function unique(arrayOfItems = []) {
  return Array.from(new Set(arrayOfItems));
}
