import React from 'react';
import { testResult } from 'constants/testReport';
import pdfReport from 'assets/images/pdf_report.jpg';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FiCheck, FiDownload } from 'vyaguta-icons/fi';

import ReportLoader from './ReportLoader';
import PositiveNotice from './PositiveNotice';
import InconclusiveNotice from './InconclusiveNotice';
import TestReportDocument from './TestReportDocument';

import Footer from 'components/COVID/common/footer';
import Navbar from 'components/COVID/common/navbar/navbar';
import { formatDateToShortMonthNumericDay } from 'util/DateAndTime';
import { routes } from 'hooks/useNavigationHelper';

const TestDetails = ({ testPerformedName, testPerformedResult }) => {
  return (
    <div className="my-6x">
      <p className="text-left">
        Test Performed Name:{' '}
        <span className="text-demibold ml-2x">{testPerformedName}</span>
      </p>
      <p className="text-left">
        Test result:
        {testPerformedResult === testResult.POSITIVE && (
          <span className="badge badge--danger ml-2x">Positive</span>
        )}
        {testPerformedResult === testResult.NEGATIVE && (
          <span className="badge badge--success ml-2x">Negative</span>
        )}
        {testPerformedResult === testResult.INCONCLUSIVE && (
          <span className="badge badge--warning ml-2x">Inconclusive</span>
        )}
      </p>
    </div>
  );
};

const ReportPage = (props) => {
  const {
    isLoading,
    testReportResponse,
    appointmentId,
    billingAddress,
    organizationName,
  } = props;

  const openPdf = () => {
    window.open(routes.printTestReport(appointmentId), '_blank');
  };

  return (
    <>
      <div style={{ minHeight: '850px' }}>
        <Navbar displayNavLinks={false} logo={props.logoUrl} />
        <div className="card__container px-0x px-4x-sm mb-12x">
          {!isLoading ? (
            <div className="card card__success p-0x">
              <div className="d-flex align-items-center-sm p-5x">
                <FiCheck className="success-check success-check--sm success-check--green mr-6x mb-0x flex-no-shrink" />
                <h3 className="mb-0x text-left">Your test result is ready.</h3>
              </div>
              <div className="d-flex pr-5x-sm flex-wrap">
                <div className="d-flex flex-column flex-2 mt-2x mt-0x-sm">
                  <div className="px-5x">
                    <p className="text-left">
                      Name:
                      <span
                        className="text-demibold ml-2x"
                        style={{ textTransform: 'capitalize' }}
                      >{`${
                        testReportResponse?.firstName
                      } ${testReportResponse?.middleName ?? ''} ${testReportResponse?.lastName}`}</span>
                    </p>
                    <p className="text-left">
                      Tested on:
                      <span className="text-demibold ml-2x">
                        {formatDateToShortMonthNumericDay(
                          testReportResponse?.testResultDate,
                        )}
                      </span>
                    </p>
                    {testReportResponse?.testResults?.map(
                      (testDetails, index) => {
                        return (
                          <>
                            {index !== 0 && <hr />}
                            <TestDetails
                              key={index}
                              testPerformedResult={
                                testDetails.testPerformedResult
                              }
                              testPerformedName={testDetails.testPerformedName}
                              index={index}
                              className="my-4x"
                            />
                          </>
                        );
                      },
                    )}
                  </div>
                  {testReportResponse?.testResults.some(
                    (test) =>
                      test.testPerformedResult !== testResult.INCONCLUSIVE,
                  ) && <hr className="my-6x" />}
                  <div className="d-none d-sm-block">
                    {testReportResponse?.testResults.some(
                      (test) =>
                        test.testPerformedResult === testResult.POSITIVE,
                    ) && <PositiveNotice />}
                  </div>
                </div>
                {testReportResponse?.testResults.some(
                  (test) =>
                    test.testPerformedResult !== testResult.INCONCLUSIVE,
                ) && (
                  <div className="pdf-report-thumbnail flex-no-shrink d-flex flex-column pr-5x pl-5x pl-0x-sm pr-0x-sm">
                    <div className="d-flex align-items-center justify-content-end color-primary-base cursor-pointer mb-6x mb-0x-sm order-2 order-1-sm">
                      <FiDownload className="body2" />
                      <PDFDownloadLink
                        document={
                          <TestReportDocument
                            organizationName={organizationName}
                            billingAddress={billingAddress}
                            testReportResponse={testReportResponse}
                          />
                        }
                        fileName={`${appointmentId}.pdf`}
                      >
                        {({ loading }) =>
                          loading ? (
                            'Loading ...'
                          ) : (
                            <p className="mb-0x ml-3x text-medium small">
                              Download PDF
                            </p>
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                    <div
                      className="img--bordered mt-0x mt-4x-sm mb-6x order-1 order-2-sm"
                      onClick={openPdf}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src={pdfReport}
                        className={'img-report'}
                        alt={'pdf report'}
                      />
                    </div>
                  </div>
                )}
                <div className="d-block d-sm-none">
                  <hr className="mb-6x mt-0x" />
                  {testReportResponse?.testResults.some(
                    (test) => test.testPerformedResult === testResult.POSITIVE,
                  ) && <PositiveNotice />}
                </div>
              </div>
              {testReportResponse?.testResults.some(
                (test) => test.testPerformedResult === testResult.INCONCLUSIVE,
              ) && <InconclusiveNotice />}
            </div>
          ) : (
            <ReportLoader />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReportPage;
