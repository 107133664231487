import { getString } from 'util/lang';
import FormInput from 'components/common/FormInput';

export const ABNFormTable = ({
  abnData,
  reason,
  estimatedCost,
  productHcpcs,
}) => {
  const title = getString(abnData)?.title;
  return (
    <table className="table table--bordered table--colored mb-6x">
      <thead>
        <tr>
          <th>D.</th>
          <th>
            {getString('dme.documentation.abnForm.fields.reasonLabel', {
              title,
            })}
          </th>
          <th>
            {getString('dme.documentation.abnForm.fields.estimatedCostLabel')}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{productHcpcs}</td>
          <td>
            <FormInput
              className="p-2x w-full border-none "
              value={reason}
              disabled
            />
          </td>
          <td>
            <FormInput
              className="p-2x w-full border-none "
              value={estimatedCost ? `$ ${estimatedCost}` : ''}
              disabled
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
