import React from 'react';
import DTAccordion from './DTAccordion';

const SingleDosePicker = (props) => {
  return (
    <DTAccordion
      {...props}
      defaultOpen={false}
      dataqaCalendar="single-dose-calendar"
      dataqaTimeSlots="single-dose-time-slot"
    />
  );
};

export default SingleDosePicker;
