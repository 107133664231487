import * as Regex from 'util/regex';
import { dashMobileFormat, limitChars } from 'util/TextFormat';

/**
 * Format input if needed, based on the keys
 * If key has an invalid input, returns null
 * @param {string} key
 * @param {string} value
 */
export const formatFormInput = (key, value) => {
  switch (key) {
    case 'mobileNo':
    case 'homePhoneNo':
      if (!Regex.IsMobileNumber(value)) return;
      value = limitChars(value, 12);
      value = dashMobileFormat(value);
      break;
    case 'physicianPhoneNumber':
      if (!Regex.IsMobileNumber(value)) return;
      value = limitChars(value, 12);
      value = dashMobileFormat(value);
      break;
    case 'birthYear':
      if (isNaN(value)) return;
      break;
    case 'memberID':
      value = value.trimStart();
      value = value.toUpperCase();
      value = value.replace(/ +/g, '');
      value = limitChars(value, 100);
      break;
    case 'groupNumber':
      value = limitChars(value, 100);
      break;
    case 'zipCode':
      if (!Regex.isNumber(value)) return;
      value = limitChars(value, 5);
      break;
    case 'firstName':
      value = value.trimStart();
      value = value.replace(/  +/g, ' ');
      value = limitChars(value, 30);
      break;
    case 'middleName':
      value = value.trimStart();
      value = value.replace(/  +/g, ' ');
      value = limitChars(value, 30);
      break;
    case 'lastName':
      const startTrimed = value.trimStart();
      value = startTrimed.replace(/  +/g, ' ');
      value = limitChars(value, 30);
      break;
    case 'guardianFirstName':
      value = value.trimStart();
      value = value.replace(/  +/g, ' ');
      value = limitChars(value, 30);
      break;
    case 'guardianMiddleName':
      value = value.trimStart();
      value = value.replace(/  +/g, ' ');
      value = limitChars(value, 30);
      break;
    case 'guardianLastName':
      value = value.trimStart();
      value = value.replace(/  +/g, ' ');
      value = limitChars(value, 30);
      break;
    case 'referredBy':
      value = limitChars(value, 100);
      break;
    case 'homeAddress':
      value = limitChars(value, 100);
      break;
    case 'city':
      value = limitChars(value, 100);
      break;
    case 'rxBin':
      if (!Regex.isNumber(value)) return;
      value = limitChars(value, 6);
      break;
    default:
      break;
  }
  return value;
};
