import React, { useState } from 'react';

/**
 * @typedef {{
 *  id: string
 *  defaultValue: string
 *  value: string
 *  onChange: React.ChangeEventHandler<select>
 *  label: string
 *  options: {label: () => string, value: string}[]
 *  hasError?: boolean
 *  required?: boolean
 *  isDisabled?: boolean
 *  dataqa: string
 *  className?: string
 * }} Props
 *
 * @type {React.FC<Props>}
 */
const SelectBox = React.forwardRef((props, ref) => {
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const {
    id,
    defaultValue,
    onChange,
    value,
    label,
    options = [],
    inputClassName = false,
    hasError = false,
    required = false,
    solo = false,
    isDisabled,
    dataqa,
  } = props;
  const labelClassName =
    'label-placeholder' +
    (required ? ' label-placeholder--required' : '') +
    (value || areOptionsVisible ? ' label-placeholder--input-filled' : '');

  return (
    <div
      className={`form-group${hasError ? ' has-error' : ''}`}
      onBlur={(e) => setAreOptionsVisible(false)}
      onFocus={(e) => setAreOptionsVisible(true)}
    >
      <select
        name={id}
        id={id}
        className={`form-control ${solo ? 'solo' : ''} ${inputClassName ? inputClassName : ''}`}
        value={!value ? defaultValue : value}
        onChange={onChange}
        ref={ref}
        disabled={isDisabled}
        data-qa={dataqa}
      >
        <option disabled className="d-none" value={defaultValue} />
        {options.length &&
          options.map(({ label, value }, index) => {
            return (
              <option value={value} key={index}>
                {typeof label === 'function' ? label() : label}
              </option>
            );
          })}
      </select>
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
    </div>
  );
});

export default SelectBox;
