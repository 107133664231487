import React from 'react';
import parse from 'html-react-parser';

const Notice = ({ noticeMessage }) => {
  return (
    <div className="alert alert--warning success-notice mt-0x">
      {parse(noticeMessage)}
    </div>
  );
};

export default Notice;
