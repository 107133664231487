import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config';
import { getDataFromSessionStorage } from 'util/sessionStorage';
import { DME_TOKEN } from 'constants/dme';
import { APPOINTMENT_TOKEN } from 'constants/appointment';

export const createBaseQuery = ({
  baseUrl = BASE_URL,
  tokenKey = 'DEFAULT_TOKEN',
  retries = 0,
}) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = getDataFromSessionStorage(tokenKey);

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });

  if (retries) {
    return retry(baseQuery, { maxRetries: retries - 1 });
  }

  return baseQuery;
};

const api = (tokenKey) => {
  return createApi({
    baseQuery: createBaseQuery({ baseUrl: BASE_URL, tokenKey: tokenKey }),
    endpoints: () => ({}),
    reducerPath: `${tokenKey.toLowerCase()}-api`,
  });
};

export const dmeApi = api(DME_TOKEN);
export const testReportApi = api(APPOINTMENT_TOKEN);
