import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getString } from 'util/lang';

const PatientDetailsPdfView = ({ fullname, gender, birthDate }) => (
  <View style={styles.section}>
    <Text style={styles.sectionTitle}>{getString('patientDetails')}</Text>
    <View style={styles.sectionContent}>
      <View style={styles.detailsRow}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>{getString('name')}</Text>
          <Text style={[styles.detailsValue, styles.capitalize]}>
            {fullname}
          </Text>
        </View>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>{getString('gender')}</Text>
          <Text style={styles.detailsValue}>{gender}</Text>
        </View>
      </View>
      <View style={styles.detailsRow}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>{getString('dob')}</Text>
          <Text style={styles.detailsValue}>{birthDate}</Text>
        </View>
      </View>
    </View>
  </View>
);

const styles = StyleSheet.create({
  section: {
    border: '1px solid #757575',
    marginBottom: 16,
  },

  sectionTitle: {
    fontWeight: 700,
    backgroundColor: '#dddddd',
    padding: '8px 12px',
  },

  sectionContent: {
    padding: 12,
  },

  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  detailsItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    minWidth: 200,
    marginBottom: 6,
    flex: 1,
  },

  detailsLabel: {
    fontWeight: 800,
    minWidth: 120,
    paddingRight: 12,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
});

export default PatientDetailsPdfView;
