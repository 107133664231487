import React from 'react';
import { AddPatientButton } from '../../../../common/IconButton';
import Button from '../../../../common/Button';
import styles from './AddPatientConfirmationSection.module.scss';
import { getString } from '../../../../../util/lang';
import cls from 'classnames';

export const AddPatientConfirmationButtons = ({
  className,
  continueButtonTitle,
  isAddButtonActive,
  isContinueButtonActive,
  addNewPatientForm,
  onContinue,
}) => {
  return (
    <div className={cls(className, styles.AddPatientConfirmationMain)}>
      <AddPatientButton
        label={getString('addPatients')}
        title={getString('addPatients')}
        onClick={addNewPatientForm}
        isEnabled={isAddButtonActive}
        classNames={{ button: 'mr-11x' }}
        dataqa="btn-add-patients"
      />

      <Button
        label={continueButtonTitle}
        title={continueButtonTitle}
        onClick={onContinue}
        isEnabled={isContinueButtonActive}
        dataqa="btn-continue"
      />
    </div>
  );
};
