import { pdf } from "@react-pdf/renderer";

// Function to generate PDF Blob from a document component
export const generatePdfBlob = async (doc) => {
    try {
        const asPdf = pdf([]); // Create an instance of the PDF generator
        asPdf.updateContainer(doc); // Update with the document
        return await asPdf.toBlob(); // Generate the blob
    } catch (error) {
        console.error('Error generating PDF blob:', error);
        throw new Error('Failed to generate PDF');
    }
};

// Function to trigger download of the PDF file
export const downloadFile = (blob, filename) => {
    try {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename; // Name of the downloaded file
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up after download
    } catch (error) {
        console.error('Error downloading file:', error);
        throw new Error('Failed to download file');
    }
};