import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { iconCheckboxChecked, iconCheckboxUnchecked } from 'assets/images';
import LineBreakPdfView from '../../common/LineBreak/LineBreakPdfView';
import { documentKeyToQuestionMap } from './DocumentCheckList';
import { initialDocumentValues } from './DocumentCheckList';

const DocumentCheckListPdfView = ({ documentData = initialDocumentValues }) => (
  <View style={styles.container}>
    <View style={styles.wrap}>
      <Text style={styles.title}>Documents Given to the Patient:</Text>
      <View style={styles.checkboxes}>
        {documentData?.map((documentD) => {
          const { key, checked } = documentD;
          const question = documentKeyToQuestionMap[key];

          return (
            <View key={key} style={styles.checkbox}>
              {checked ? (
                <Image
                  src={iconCheckboxChecked}
                  alt="checkbox-checked"
                  style={styles.img}
                />
              ) : (
                <Image
                  src={iconCheckboxUnchecked}
                  alt="checkbox-unchecked"
                  style={styles.img}
                />
              )}
              <Text style={styles.p}>{question}</Text>
            </View>
          );
        })}
      </View>
    </View>
    <LineBreakPdfView />
  </View>
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
  },
  checkbox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
  },
  img: {
    height: 13,
    width: 13,
  },
  p: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    marginLeft: 5,
  },
});

export default DocumentCheckListPdfView;
