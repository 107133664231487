import { useCallback, useMemo } from 'react';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { getString } from 'util/lang';
import { ClinicAndTiming } from '../../NewClinicAndTiming/ClinicAndTiming';
import {
  makeFollowupRescheduleNavLinks,
  makeGroupRegisterNavLinks,
  makeGroupRescheduleNavLinks,
} from 'components/common/NavBar/navLinks';
import { useAppParams } from 'context/useAppParams';
import { useDetailContext } from 'context';

export const GroupRescheduleDateTime = () => {
  const {
    details: { pageComplete },
  } = useDetailContext();
  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();

  const toNextPage = useCallback(() => {
    nav.toGroup.reschedule.confirmation(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      makeGroupRescheduleNavLinks(pageComplete, appointmentId, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ClinicAndTiming
        disableEdit
        toNextPage={toNextPage}
        toNextPageLabel={getString('continue')}
      />
    </PageWrapper>
  );
};
