import { getString } from "util/lang";
import { Image, Text, View } from "@react-pdf/renderer"

import { useStyles } from "../ABNFormPdf.styles";
import { iconCheckbokCheckedBlack, iconCheckbokUncheckedBlack } from "assets/images";

export const ABNFormOptions = ({ abnData, primaryPayerName, option1 = true, option2 = false, option3 = true, }) => {
    const styles = useStyles;
    return (
        <View>
            <Text style={styles.sectionTitle}>
                {getString('dme.documentation.abnForm.needToKnow')}
            </Text>
            {getString('dme.documentation.abnForm.needToKnowPoints').map((point, index) => (
                <Text style={styles.optionText}>
                    • {point}
                </Text>
            ))}
            <View style={styles.row}>
                <Text style={styles.noteOptions}>Note: </Text>
                <Text style={[styles.noteDesc]}>
                    {getString(`${abnData}.needToKnowNote`)}
                </Text>
            </View>

            <View style={[styles.optionsTable, styles.table]}>
                <View style={styles.optionsTableHeader}>
                    <Text style={styles.fontWt700}>{getString('dme.documentation.abnForm.fields.optionsLabel')} :</Text>
                    <Text style={[styles.fontWt600, styles.ml2]}>
                        {getString('dme.documentation.abnForm.fields.optionsNote')}
                    </Text>
                </View>
                <View style={[styles.cellSm, styles.borderRight0]}>
                    <View style={[styles.row, styles.alignCenter]}>
                        <View style={styles.img}>
                            {option1 ? <Image
                                src={iconCheckbokCheckedBlack}
                                alt="checkbox-checked"
                            /> :
                                <Image
                                    src={iconCheckbokUncheckedBlack}
                                    alt="checkbox-checked"
                                />}
                        </View>
                        <View style={[styles.row, styles.flex3]}>
                            <Text style={styles.checkboxLabel}>{getString('dme.documentation.abnForm.fields.option1Label')}</Text>
                            <Text style={styles.checkboxDescription}>
                                {getString(`${abnData}.option1`, { primaryPayerName })}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.row, styles.alignCenter]}>
                        <View style={styles.img}>
                            {option2 ?
                                <Image
                                    src={iconCheckbokCheckedBlack}
                                    alt="checkbox-checked"
                                /> :
                                <Image
                                    src={iconCheckbokUncheckedBlack}
                                    alt="checkbox-checked"
                                />}
                        </View>
                        <Text style={styles.checkboxLabel}>{getString('dme.documentation.abnForm.fields.option2Label')}</Text>
                        <Text style={styles.checkboxDescription}>
                            {getString(`${abnData}.option2`, { primaryPayerName })}
                        </Text>
                    </View>
                    <View style={[styles.row, styles.alignCenter]}>
                        <View style={styles.img}>
                            {option3 ? <Image
                                src={iconCheckbokCheckedBlack}
                                alt="checkbox-checked"
                            /> :
                                <Image
                                    src={iconCheckbokUncheckedBlack}
                                    alt="checkbox-checked"
                                />}
                        </View>
                        <Text style={styles.checkboxLabel}>{getString('dme.documentation.abnForm.fields.option3Label')}</Text>
                        <Text style={styles.checkboxDescription}>
                            {getString(`${abnData}.option3`)}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
}