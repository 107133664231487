import React, { useContext } from 'react';
import { NavigationContext } from './context';
import styles from './SideNavBar.module.scss';

export const Tabs = (props) => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(`Tabs Should be used as child of SideNavBar`);
  }

  const { children } = props;

  return <div className={styles['side-navbar-tabs']}>{children}</div>;
};
