import React from 'react';

export const NavigationContext = React.createContext(null);
NavigationContext.displayName = 'Group-Navigation';

export const NavigationReducer = (prevState, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      let newState = { ...prevState };

      const activeMenuIndex = action.payload.activeMenuIndex ?? 0;
      const activeSubMenuIndex = action.payload.activeSubMenuIndex ?? 0;

      newState = {
        ...newState,
        activeMenuIndex,
        activeSubMenuIndex,
        visited: [
          `${activeMenuIndex}`,
          `${activeMenuIndex}${activeSubMenuIndex}`,
        ],
      };

      if (action.payload.allowNavigation !== null) {
        newState = {
          ...newState,
          allowNavigation: action.payload.allowNavigation,
        };
      }

      return newState;
    }

    case 'MAX_MENU_INDEX': {
      let newState = { ...prevState };

      if (action.payload.menuIndex) {
        newState = {
          ...newState,
          menuLength: Math.max(
            prevState.menuLength ?? 0,
            action.payload.menuIndex + 1,
          ),
        };
      }
      return newState;
    }

    case 'MAX_SUBMENU_INDEX': {
      let newState = { ...prevState };

      if (action.payload.subMenuIndex) {
        newState = {
          ...newState,
          subMenuLength: Math.max(
            prevState.subMenuLength ?? 0,
            action.payload.subMenuIndex + 1,
          ),
        };
      }
      return newState;
    }

    case 'CHANGE_SUB_MENU': {
      const nextSubMenuIndex = action.payload.activeSubMenuIndex;
      const nextSubMenuVisit = `${prevState.activeMenuIndex}${action.payload.activeSubMenuIndex}`;
      let visited = [...prevState.visited];
      const visitedIndex = prevState.visited.findIndex(
        (v) => v === nextSubMenuVisit,
      );
      if (visitedIndex === -1) {
        visited.push(nextSubMenuVisit);
      }
      const newState = {
        ...prevState,
        activeSubMenuIndex: action.payload.activeSubMenuIndex,
        subMenuIndex: nextSubMenuIndex,
        visited,
      };
      return newState;
    }

    case 'CHANGE_MENU': {
      const nextMenuVisit = `${action.payload.activeMenuIndex}`;
      const nextSubMenuVisit = `${nextMenuVisit}0`;
      let visited = [...prevState.visited];
      const visitedIndex = prevState.visited.findIndex(
        (v) => v === nextMenuVisit,
      );
      const visitedSubIndex = prevState.visited.findIndex(
        (v) => v === nextSubMenuVisit,
      );
      if (visitedIndex === -1) {
        visited.push(nextMenuVisit);
      }
      if (visitedSubIndex === -1) {
        visited.push(nextSubMenuVisit);
      }

      const newState = {
        ...prevState,
        activeMenuIndex: action.payload.activeMenuIndex,
        activeSubMenuIndex: 0,
        visited,
      };

      return newState;
    }

    case 'NEXT_MENU': {
      if (prevState.activeSubMenuIndex < prevState.subMenuLength - 1) {
        // changing submenu
        const nextSubMenuIndex = prevState.activeSubMenuIndex + 1;
        const nextSubMenuVisit = `${prevState.activeMenuIndex}${nextSubMenuIndex}`;
        let visited = [...prevState.visited];
        const visitedIndex = prevState.visited.findIndex(
          (v) => v === nextSubMenuVisit,
        );
        if (visitedIndex === -1) {
          visited.push(nextSubMenuVisit);
        }
        return {
          ...prevState,
          activeSubMenuIndex: nextSubMenuIndex,
          visited,
        };
      }

      if (prevState.activeMenuIndex < prevState.menuLength - 1) {
        // changing menu
        const nextMenuVisit = `${prevState.activeMenuIndex + 1}`;
        const nextSubMenuVisit = `${nextMenuVisit}0`;

        let visited = [...prevState.visited];
        const visitedIndex = prevState.visited.findIndex(
          (v) => v === nextMenuVisit,
        );
        const visitedSubIndex = prevState.visited.findIndex(
          (v) => v === nextSubMenuVisit,
        );
        if (visitedIndex === -1) {
          visited.push(nextMenuVisit);
        }
        if (visitedSubIndex === -1) {
          visited.push(nextSubMenuVisit);
        }

        return {
          ...prevState,
          activeMenuIndex: prevState.activeMenuIndex + 1,
          activeSubMenuIndex: 0,
          visited,
        };
      }
      // invalid state
      return { ...prevState };
    }

    case 'REDUCE_MENU_SUBMENU_INDEX': {
      if (prevState.activeMenuIndex < prevState.menuLength) {
        const currentMenuVisit = `${prevState.activeMenuIndex}`;
        const currentSubMenuVisit = `${currentMenuVisit}0`;
        const currentNextSubMenuVisit = `${currentMenuVisit}1`;

        let visited = [...prevState.visited];
        visited = visited.filter(
          (v) =>
            ![
              currentSubMenuVisit,
              currentMenuVisit,
              currentNextSubMenuVisit,
            ].includes(v),
        );

        return {
          ...prevState,
          activeMenuIndex: prevState.activeMenuIndex - 1,
          activeSubMenuIndex: 0,
          visited,
        };
      }
      return { ...prevState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const GoToNextNavigation = (dispatch) => {
  dispatch({
    type: 'NEXT_MENU',
  });
};

export const GoToPreviousNavigation = (dispatch) => {
  dispatch({
    type: 'REDUCE_MENU_SUBMENU_INDEX',
  });
};
