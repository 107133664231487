/**
 * Check if year is between 1900 and current year
 */
export const ValidBirthYear = () => {
  const CurrentYear = new Date().getFullYear().toString();
  const prevDecade = parseInt(CurrentYear[2], 10) - 1;
  return new RegExp(
    `^(19[0-9]{2}|20[0-${prevDecade}][0-9]|20${CurrentYear[2]}[0-${CurrentYear[3]}])$`,
  );
};

/**
 * @param {string} value
 * Non alphabet characters only
 */
export const IsMobileNumber = (value) => {
  const regex = RegExp(`^[^a-z,A-Z]*$`);

  return regex.test(value);
};

export const isNumber = (value) => {
  const regex = /^[0-9]*$/gm;

  return regex.test(value);
};

export const isEmail = (email) => {
  const regex = /^(?=.{1,256})(?=.{1,64}@.{1,255}$)[^\s@]+@[^\s@]+\.[^\s@]+$/i;
  return regex.test(email);
};
