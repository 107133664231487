import React from 'react';
import { FiCheck } from 'vyaguta-icons/fi';
import { getString } from 'util/lang';

const IISConsent = (props) => {
  const { isConsentAgree = false, onConsentChange, dataqa } = props;

  return (
    <div className="custom-check mb-3x pt-3x">
      <input
        tabIndex="0"
        autoComplete="chrome-off"
        type="checkbox"
        checked={isConsentAgree}
        id="iisConsent"
        onChange={(e) => onConsentChange('iisConsent', e.target.checked)}
        data-qa={dataqa}
      />
      <label htmlFor="iisConsent">
        <FiCheck /> {getString('iisConsent', '')}
      </label>
    </div>
  );
};

export default IISConsent;
