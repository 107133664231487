import { useMemo } from 'react';
import { useDetailContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { makeWaitlistNavLinks } from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { ScreeningAndEligibility } from '../../PatientEligibility/container/PatientEligibilityWrapper';
import { changeTitle } from 'util/siteInfo';

const WaitlistInformation = () => {
  changeTitle('Wait List');
  const {
    details: { pageComplete, selectedServices },
  } = useDetailContext();

  const { organizationId } = useAppParams();

  const nav = useNavigationHelper();

  const toNextPage = nav.toWaitlist.success();

  const navLinks = useMemo(
    () => makeWaitlistNavLinks(pageComplete, organizationId),
    [organizationId, pageComplete],
  );

  if (!selectedServices.length) {
    nav.resetToFirstPage();

    return null;
  }

  const goBack = () => nav.goBack();

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ScreeningAndEligibility
        isSelfDeclarationDisabled={true}
        organizationId={organizationId}
        goBack={goBack}
        toNextPage={toNextPage}
        disableEdit={false}
        isPatientInfoDisabled={false}
        showSelfDeclaration={false}
        isWaitlist={true}
      />
    </PageWrapper>
  );
};

export default WaitlistInformation;
