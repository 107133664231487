import React from 'react';
import { FiChevronDown } from 'vyaguta-icons/fi';

import styles from './select.module.scss';

export const DropSelect = (props) => {
  const { options, onChange } = props;
  const [showOptions, setShowOptions] = React.useState(false);

  const handleOutSideClick = (e) => {
    if (!e.target.dataset.value) {
      setShowOptions(false);
    }
  };

  React.useEffect(() => {
    if (showOptions) {
      document.addEventListener('click', handleOutSideClick);
      return () => {
        document.removeEventListener('click', handleOutSideClick);
      };
    }
  }, [showOptions]);

  const onDropDownClick = (e) => {
    e.stopPropagation();
    setShowOptions(!showOptions);
  };

  const onClickHandler = (e) => {
    e.stopPropagation();
    setShowOptions(false);
    const value = e.target.dataset.value;
    const option = options.find((c, i) => `${i}-${c.label}` === value);
    onChange && onChange(option.value);
  };

  if (options.length < 2) {
    return null;
  }

  return (
    <div className={styles['custom-select']}>
      <FiChevronDown
        size={20}
        className={styles['custom-select-dropdown']}
        onClick={onDropDownClick}
      />
      {showOptions && (
        <div
          className={styles['custom-select-options']}
          onClick={onClickHandler}
        >
          {options.map((c, index) => {
            return (
              <span
                className={styles['custom-select-options__item']}
                key={`${index}-${c.label}`}
                data-value={`${index}-${c.label}`}
              >
                {c.label}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};
