const language = {
  continue: `Continuar`,
  continueTitle: `Presione la tecla ENTER o RETURN para continuar al siguiente paso`,
  confirm: `Confirmar`,
  ok: `Bien`,
  yes: `Sí`,
  no: `No`,
  or: `o`,
  contactUs: `Contáctenos`,
  organizationLogo: `Logotipo de la organización`,
  refresh: `Refrescar la página`,
  select: `Seleccionar`,
  submit: `Enviar`,
  cancel: `Cancelar`,
  submitTitle: `Presione la tecla ENTER o RETURN para enviar su formulario`,
  submitAndContinue: `Enviar y continuar`,
  errorMessage: `Algo salió mal. Inténtelo de nuevo más tarde.`,
  notify: `Notificarme`,
  pleaseWaitLoading: `Espere por favor...`,
  error: `Error`,
  highRequestError: `Parece que muchas personas están tratando de obtener una cita. Intente de nuevo después de 2 minutos.`,
  clearingSessionHeader: `Cerrar su sesión`,
  clearingSessionDescription: `No ha habido actividad por un tiempo, por motivo de seguridad, cerraremos su sesión en breve.`,
  stay: `Quedarse`,
  loading: `Cargando`,
  optOut: `Exclusión`,
  personalIdInfo: `Información de identificación personal`,
  doseNumberFirst: `1º`,
  doseNumberSecond: `2º`,
  singleDose: 'Una sola dosis',
  firstDose: `1era dosis`,
  secondDose: `2da dosis`,
  dateAtTime: `{0} en {1}`,
  firstName: `Primer nombre`,
  middleName: `Segundo nombre`,
  lastName: `Apellido(s)`,
  emailAddress: `Dirección de correo electrónico`,
  emailAddressConfirm: `confirmar la dirección de correo electrónico`,
  subscriberRelationship: 'Realización a asegurado',
  memberIdSuffix: 'Sufijo miembro',
  memberIdPrefix: 'Prefijo miembro',
  insurance: `Seguro`,
  insuranceCompany: `Compañía de seguro de salud`,
  insuranceCompanyName: `Nombre de la compañía de seguros`,
  memberId: `Identificación de miembro`,
  memberIdMedicare: 'Identificación de miembro (MBI)',
  medicarePartA: 'Parte A/B:',
  medicarePartD: 'Parte D:',
  groupNumber: `Número de grupo`,
  rxBin: 'Papelera Rx',
  rxPCN: 'PCN Rx',
  rxGroup: 'Grupo Rx ',
  id: 'IDENTIFICACIÓN',
  issuer: 'Editor',
  checkInsurance: `Consultar la información del seguro`,
  idType: `tipo de identificación`,
  idNumber: `Número de identificación`,
  noId: `No tengo cédula`,
  homeAddress: `Dirección de su casa`,
  aptUnit: `Departamento o unidad`,
  city: `Ciudad`,
  state: `Estado`,
  county: `Condado`,
  ethnicity: `Etnia`,
  race: `Raza`,
  gender: `Género`,

  // Subscriber Relationship
  relationshipSelf: 'Uno mismo',
  relationshipSpouse: 'Esposa',
  relationshipChild: 'Niño/Dependiente',
  relationShipOther: 'Otra',

  //Card Holder Details
  primaryCardHolderHeading: 'Detalles del titular principal de la tarjeta',
  cardHolderFirstName: 'Primer nombre',
  cardHolderMiddleName: 'Segundo nombre',
  cardHolderLastName: 'Apellido',
  cardHolderToolTipDetail:
    'Persona cuyo nombre aparece en la tarjeta del seguro y es responsable de pagar el seguro de salud',

  //Insurance Card Image
  insuranceCardHeading: 'Cargar tarjeta de seguro',
  insuranceCardSubHeading:
    'No es necesario cargar imágenes de su tarjeta, pero ayudará a acelerar el proceso de registro durante su visita.',
  cardLabelFront: 'Frente de la tarjeta',
  cardLabelBack: 'Parte posterior de la tarjeta',
  insuranceCardValidation: 'Tipo de archivo no admitido, cargue JPG, PNG o PDF',
  insuranceCardLimitValidation:
    'Tamaño de archivo demasiado grande. Cargue un archivo de hasta 2 MB de tamaño',
  imageUploaderLabel: 'Arrastre y suelte para cargar o buscar archivos',
  imageUploaderLabelSeparator: 'o',
  imageUploaderSupportedType: 'Archivos compatibles: PNG, JPG, PDF',
  // Insurance Type
  insuranceTypeMedicare: 'Medicare',
  insuranceTypeMedicareAdvantage: 'Medicare Advantage',
  insuranceTypeCommercial: 'Commercial',
  insuranceTypeMedicaid: 'Medicaid',

  // Insurance Plan Type
  insurancePlanTypePPO: 'PPO',
  insurancePlanTypeHMO: 'HMO',
  insurancePlanTypeOthers: 'Otras',
  insurancePlanTypeDontKnow: 'No sé',

  primaryLanguage: `primer idioma (incluye lenguaje de señas norteamericano)`,
  mobileNumber: `Número de teléfono móvil o celular`,
  referringOrganization: `Organización que dio la recomendación`,
  phoneNumber: `Número de teléfono`,
  age: `Edad`,
  firstNameRequired: `La sección del primer nombre no puede estar vacía`,
  firstNameValidationMessage: `El primer nombre solo puede tener mayúsculas (A-Z), minúsculas (A-Z), una comilla, guíon, punto y espacio`,
  middleNameValidationMessage: `El segundo nombre solo puede tenermayúsculas (A-Z), minúsculas (A-Z), una comilla, guión, punto y espacio`,
  lastNameRequired: `La sección del apellido no puede estar vacía`,
  lastNameValidationMessage: `El apellido solo puede tener mayúsculas (A-Z), minúsculas (A-Z), una comilla, guión, punto y espacio`,
  nameCharacterValidationMessage:
    'El nombre solo puede tener mayúsculas (A-Z), minúsculas (A-Z), una comilla, guíon, punto y espacio',
  nameSpaceValidationMessage:
    'El nombre solo puede tener un espacio simple entre el nombre, el segundo nombre y el apellido',
  nameRepeatValidationMessage:
    'El nombre no puede tener múltiples ocurrencias de la misma letra',
  emailRequired: `La sección del correo electrónico no puede estar vacía`,
  rxBinRequired: 'Rx Bin no puede estar vacía',
  rxBinLengthValidationMessage: 'Rx Bin debe ser un número de 6 dígitos',
  rxBinValidationMessage: 'Rx Bin debe ser un número',
  rxPCNRequired: 'Rx PCN no puede estar vacía',
  rxGroupRequired: 'El grupo Rx no puede estar vacío',
  emailValidationMessage: `El correo electrónico no es válido`,
  emailValidationMessageNoMatch: `El correo electrónico que proporcionó no coincide con el correo electrónico en la sección para confirmar el correo electrónico.`,
  confirmEmailValidationMessage: `La confirmación del correo electrónico no es válida`,
  insuranceDetailsRequired: `La compañía de seguros debe seleccionarse de la lista dada o seleccionar la opción "Sin seguro"`,
  insuranceNameRequired: `Proporcione el nombre de la compañía de seguros`,
  insuranceEligibilityMessage:
    'Para continuar programando su cita, deberá verificar su seguro. Ingrese la información de su seguro y luego haga clic en el botón {0} a continuación.',
  memberIdRequired: `La identificación de miembro no puede estar vacía`,
  memberIdValidationMessage: `La identificación de miembro debe ser numérica o alfanumérica (puede incluir caracteres especiales)`,
  insuranceIdTypeRequired: `El tipo de identificación no puede estar vacío`,
  insuranceIdNumberRequired: `El número de identificación no puede estar vacío`,
  insuranceIdNumberValidationMessage: `El número de identificación debe ser numérico o alfanumérico (puede incluir caracteres especiales)`,
  insuranceTypeRequired:
    'El tipo de seguro debe seleccionarse antes de completar los datos del seguro',
  insurancePlanTypeRequired: 'Proporcione el tipo de plan de seguro',
  checkEligibility: 'Comprobar elegibilidad',
  pharmacyInsuranceExtraInfoText:
    'Si su tarjeta tiene alguno de los siguientes datos, indíquelo a continuación',
  pharmacyInsuranceEligibilityMessage:
    'El seguro de farmacia no necesita ser verificado. Continúe programando su cita.',
  eligibilityAPIErrorMessage:
    'Algo salió mal al verificar la elegibilidad. Por favor, inténtelo de nuevo más tarde.',
  isNotEligibleInsuranceMessage:
    'El paciente no es elegible para el seguro. Edite la información y vuelva a enviarla.',
  insuranceEligibleParamMessage:
    'Su información no coincide con los registros proporcionados por su compañía de seguros. Verifique y corrija estos para continuar reservando su cita: {0}',
  name: 'Nombre',
  dob: 'Fecha de nacimiento',
  policyNumber: 'Identificación de miembro',
  insuranceEligibilityLoading:
    'Espere mientras verificamos su elegibilidad para el seguro...',
  insuranceValidationTimeRemainingMessage:
    '¡Lo siento! Ha alcanzado el límite de verificación de elegibilidad del seguro, intente nuevamente después de {0}',
  isEligibleInsuranceMessage:
    '¡Excelente! Su seguro cubre este servicio, sujeto a su deducible y copagos. Continúe reservando su cita.',
  timeUpInsuranceMessage:
    'Puede verificar su elegibilidad de seguro ahora. Cambie la información de su seguro y haga clic en el botón {0}.',
  insuranceStateRequired: `El estado no puede estar vacío`,
  insuranceValidationMessageNotOnList: `La compañía de seguros debe seleccionarse de la lista dada o seleccionar la opción "Sin seguro"`,
  monthRequired: `Seleccione el mes`,
  dayRequired: `Seleccione el día`,
  dayValidationMessage: `El día debe ser entre el 1 y el 31`,
  yearRequired: `Seleccione el año`,
  yearValidationMessage: `El año no debe ser antes del 1900 o en el futuro.`,
  genderRequired: `El campo de género no puede estar vacío`,
  mobileNumberRequired: `El campo del número de teléfono móvil o celular no puede estar vacío.`,
  mobileNumberValidationMessage: `Ingrese el número de teléfono móvil utilizando números solamente`,
  mobileNumberValidationMessageMin: `El número de teléfono móvil debe contener al menos 10 digitos`,
  mobileNumberValidationMessageMax: `El número de teléfono móvil debe contener 10 digitos solamente`,
  raceRequired: `El campo sobre raza no puede estar vacío.`,
  ethnicityRequired: `El campo sobre su etnia no puede estar vacío.`,
  referralValidationMessage: `El nombre de la persona que da referencias no puede exceder los 100 caracteres`,
  aptUnitValidationMessage: `Número de dept/unidad no es válido`,
  cityRequired: `El campo de la ciudad no puede estar vacío.`,
  cityValidationMessage: `El nombre de la ciudad no es válido`,
  stateRequired: `El campo del código postal no puede estar vacío`,
  countyRequired: `El campo del condado no puede estar vacío`,
  zipRequired: `La sección del código postal no puede estar vacía`,
  zipValidationMessageMin: `El código postal debe contener 5 dígitos`,
  zipValidationMessageMax: `El código postal debe contener solo 5 números`,
  zipValidationMessage: `El código postal debe ser un número`,
  homeAddressRequired: `El campo del domicilio no puede estar vacío`,
  njisValidationMessage:
    'Por favor acepte compartir su información de vacunación con el Sistema de Información de Vacunación de New Jersey (NJIIS).',
  homeAddressValidationMessage: ` no es válida`,
  addressFilledTypeRequired: `El tipo de dirección completa no puede estar vacío`,
  ageRequired: `El domicilio no es válido`,
  ageValidationMessage: `La edad debe ser un número`,
  ageValidationMessagePositiveNumber: `La edad debe ser un número positivo`,
  ageValidationMessageMin: `No estamos aplicando la vacuna contra el COVID-19 a las personas menores de 12 años en estos momentos.`,
  ageValidationMessageMax: `No estamos aplicando la vacuna contra el COVID-19 a las personas que tienen más de 150 años en estos momentos.`,
  ageValidationMessageUnder19: `No estamos aplicando la vacuna contra el COVID-19 a las personas menores de 19 años en estos momentos.`,
  optOutEmail: `Si no nos proporciona su correo electrónico, no recibirá mensajes confirmando la reservación de su cita. `,
  optOutPhone: `Si no nos proporciona su número de teléfono, no recibirá mensajes de texto confirmando la reservación de su cita. `,
  optOutPhoneAndEmail: `Si no nos proporciona su correo electrónico y su número de teléfono, no podrá actualizar la reservación de su cita. Contáctenos si necesita actualizar su cita`,
  patientEligibility: `Cumplimiento de requisitos del paciente`,
  info: `Evaluación de salud`,
  clinicAndTiming: `Clínica, fecha y hora`,
  patientInfoAndConfirm: `Información del paciente`,
  selectClinicAndTiming: `Seleccione la clínica y hora`,
  confirmation: 'Confirmación',
  genderMale: `Hombre`,
  genderFemale: `Mujer`,
  genderTrans: `Transgénero`,
  genderNonBinary: `No binario`,
  genderOther: `Otro / preferiría no decir`,
  raceAsian: `Asiático`,
  raceNative: `Indio americano o nativo de Alaska`,
  raceBlack: `Negro o afroamericano`,
  racePacific: `Nativo de Hawái u otra isla del Pacífico`,
  raceWhite: `Blanco`,
  raceOther: `Otra raza`,
  raceNoPreference: `Prefiero no decir`,
  ethnicityHispanic: `Hispana(o) o latina(o)`,
  ethnicityNotHispanic: `No soy hispana(o) o latina(o)`,
  ethnicityNoPreference: `Prefiero no decir`,
  driversLicense: `Licencia de conducir`,
  otherStateId: `Otra identificación del estado`,
  januaryShort: `Enero`,
  februaryShort: `Febrero`,
  marchShort: `Marzo`,
  aprilShort: `Abril`,
  mayShort: `Mayo`,
  juneShort: `Junio`,
  julyShort: `Julio`,
  augustShort: `Agosto`,
  septemberShort: `Septiembre`,
  octoberShort: `octubre`,
  novemberShort: `Noviembre`,
  decemberShort: `Diciembre`,
  signatureHelp: `Use su mouse o pantalla táctil para agregar su firma en el recuadro de abajo.`,
  signaturePlaceholder: `Pulse para agregar una firma`,
  signatureUnder18Title: `Firma del tutor`,
  patientSignature: `Firma del/la paciente`,
  signatureTooLong: `La firma es demasiado larga`,
  noAppointmentsAvailable: `No hay citas disponibles`,
  nAppointmentsAvailable: `Citas disponibles`,
  confirmationCodeHeading: `Su código de confirmación`,
  confirmationCodeMessage: `Por favor, lleve este código a su cita para que nuestro personal pueda registrarle rápidamente.`,
  privacyPolicy: `Política de privacidad`,
  poweredBy: `Desarrolado por`,
  signeticMVS: `Signetic MVS`,
  reCaptchaMessage: `Para continuar, marque la casilla de arriba `,
  noSlotsAvailable: `No hay espacios disponibles`,
  oneSlotAvailable: `Hay 1 espacio disponible`,
  nSlotsAvailable: `Hay {0} espacios disponibles`,
  errorBookingHeader: `Permanezca en espera`,
  stayWithUs: `Permanezca en espera`,
  slotTaken: `Lo sentimos, ese espacio acaba de ser tomado. Intente reservar su cita en otro lugar.`,
  bookingTroubleQuestion: `¿Está teniendo problemas para reservar una cita?`,
  confirmAndSubmit: `Confirmar y enviar`,
  appointmentBookProgress: `Por favor, espere mientras reservamos sus citas...`,
  rescheduleAppointmentTitle: `Presione la tecla ENTER o RETURN para reprogramar su cita`,
  appointmentSelected: `la cita seleccionada será en`,
  appointmentSelectedSingleDose: `Su cita será en`,
  appointmentCancelledMessage: `Su cita ya fue cancelada. Haga clic en el botón de abajo si desea reservar otra cita.`,
  bookAppointment: `Reservar una cita`,
  bookAppointmentTitle: `Presione ingresar(ENTER) para reservar otra cita`,
  mapAlt: `Mapa`,
  selectedClinicWithAppointment: `La clínica que seleccionó para su cita.`,
  doubleDoseVaccineAdministrationMessage: `Tanto la primera como la segunda dosis de la vacuna deben administrarse en la misma clínica.`,
  vaccineDose: `Dosis de la vacuna`,
  currentClinics: `Las clínicas actuales cerca`,
  viewMap: `Ver en el mapa`,
  secondVaccineAppointmentInfo: `La segunda cita para la vacuna solo está disponible {0} días después de recibir la primera dosis. Por favor, asegúrese de poder llegar a las dos citas ya que no hay garantía de que podamos darle otra fecha`,
  singleVaccineAppointmentInfo: `Por favor, asegúrese de poder llegar a la cita. No hay garantía de que podamos darle otra fecha`,
  dateFetchProgress: `Por favor, espere mientras buscamos fechas disponibles...`,
  noDatesAvailableSingleDose: `No hay fechas disponibles. Seleccione una clínica diferente.`,
  noDatesAvailableDoubleDose: `No hay citas disponibles para la primera dosis. Seleccione una clínica diferente.`,
  firstAppointmentDate: `La fecha de su primera cita es {0} en {1}`,
  doubleDosePickerMessage: `Seleccione la clínica, la fecha y la hora cercanas para su primera cita de la vacuna.`,
  appointmentsEmpty: `No hay citas disponibles`,
  registerVaccineHeader: `Inscríbase para hacer una cita para la vacuna`,
  registerServiceHeader: 'Registra tu cita',
  registerVaccineMessage: `Seleccione los diferentes botones para ver todas las citas disponibles`,
  scheduleAppointmentHeader: `Detalles para reservar una cita`,
  chooseAppointmentDateTime: `Elija la fecha y la hora de la cita`,
  chooseAppointmentDateTimeTitle: `Presione la tecla ENTER o RETURN para elegir la fecha y la hora de la cita`,
  selectedClinic: `Su clínica seleccionada es`,
  submitAppointmentMessage: `Aún no termina, todavía debe confirmar y enviar la información para reservar su cita.`,
  updateAppointmentMessage: `Por favor, actualice los detalles de su cita a su conveniencia.`,
  selectedAppointment: `La fecha y hora de su {0} cita es`,
  guardianHeader: `Si tiene menos de 18 años, la madre, el padre o tutor deben firmar arriba`,
  guardianName: `Nombre de la madre/padre/tutor`,
  informationHeader: `Información importante`,
  highVolumeMessage: `Gracias por su interés en las clínicas COVID-19 de {0}. En estos momentos, estamos experimentando un alto volumen de solicitudes y responderemos a las consultas en el orden en que se reciban.`,
  existingInquiry: `Si está buscando información sobre una cita ya existente, le contactaremos lo antes posible.`,
  contactLabel: `Contacto`,
  allAcceptedAlertMessage: `¡Vamos a registrarte!`,
  availability: `Disponibilidad`,
  screeningQuestions: `Preguntas de evaluación`,
  selfDeclaration: `Criterios de auto declaración`,
  selfDeclarationAlert: `Responda a los criterios de declaración de conformidad para avanzar en el proceso de inscripción.`,
  eligibleAssessmentMessage: `Yo, {0}, doy fe de que soy elegible para los servicios seleccionados y acepto continuar con el registro solo si cumplo con todos los criterios anteriores.`,
  contactUsSuccessMessage: `Nos pondremos en contacto con usted en breve.`,
  contactDetailsHeader: `Proporcione sus datos de contacto y nos comunicaremos pronto con usted`,
  noAppointments: `No hay citas`,
  oneAppointment: `1 cita`,
  nAppointments: `{0} citas`,
  noClinics: `No hay clínicas `,
  oneClinic: `1 clínica`,
  nClinics: `{0} clínicas `,
  siteAvailabilityHeader: `Citas disponibles en los sitios`,
  siteAvailabilityMessage: `En estos momentos, hay {0} clínicas cercanas con {1} citas disponibles.`,
  eligibilityQuestionsHeader: `Preguntas de verificación`,
  eligibilityQuestionsMessage: `¿Pertenece a cualquiera de las siguientes categorías que tienen derecho a recibir el servicio?`,
  notifyFailure: `No se pudo notificar `,
  provideContactInfoAlert: `Proporcione sus datos de contacto para recibir una notificación por mensaje de texto y correo electrónico sobre su cita.`,
  underEighteenAlert: `Actualmente, no estamos aplicando vacunas contra el COVID-19 a las personas menores de 18 años.`,
  underMinimumAgeAlert: `Actualmente, no estamos aplicando vacunas contra el COVID-19 a las personas menores de 12 años.`,
  detailsConfirmAlert: `Para continuar, confirme que todos sus datos estén correctos.`,
  selectClinicAndTimingButton: `Seleccione la clínica y la hora.`,
  selectClinicAndTimingButtonTitle: `Presione la tecla ENTER o RETURN para proporcionar el nombre de la clínica y la hora`,
  creditCardInfo: `Información de la tarjeta de crédito`,
  creditCardUnavailable: `No aceptamos tarjetas de crédito en este momento, ¡pero lo haremos pronto! Haz clic en Notificarme.`,
  notifyCardPaymentTitle: `Presiona enter para que me notifiquen sobre el pago con tarjeta de crédito`,
  notificationConsent: `Al marcar esta casilla, acepto todos los {0} relacionados con mi cita.`,
  notificationConsentLink: `términos para la comunicación digital`,
  notificationConsentModalHeader: `Términos para la comunicación digital`,
  notificationConsentModalMessageA: `A. Doy mi consentimiento para que {0} y/o sus empleados independientes se comuniquen conmigo por correo electrónico y/o mensajes de texto con respecto a mis citas. Se aplicarán las tarifas estándar de mensajes de texto.`,
  notificationConsentModalMessageB: `B. La información enviada por correo electrónico y mensajes de texto no están cifrados. Entiendo el riesgo asociado con mensajes de correo electrónico y de texto estándar no cifrados y, por lo tanto, doy permiso para enviar la información de mi cita a través de correo electrónico y mensajes de texto estándar.`,
  patientAddress: `Dirección del paciente`,
  patientInfo: `Información del paciente`,
  guardianInfo: `Información del tutor`,
  guardianInfoAlert: `Si tiene menos de 19 años, debemos recopilar información sobre su tutor. El Distrito Escolar de Seattle, en asociación con Seattle Fire, ha hecho esto. Ver su información Vea su información {here}.`,
  guardianConsent:
    'Estoy autorizado a dar mi consentimiento para que el paciente nombrado arriba reciba esta vacuna. Solicito que se le administre la vacuna al paciente mencionado anteriormente. Entiendo que el paciente debe permanecer en el lugar de la vacuna durante 15 a 30 minutos después de recibir la vacuna para ser monitoreado por posibles reacciones y efectos secundarios inmediatos relacionados con la vacuna y recibir intervención médica si es necesario.',
  here: 'aquí',
  belowNineteenAlert: `Si tienes menos de 19 años de edad, necesitamos obtener información sobre tu tutor. `,
  paymentType: `Tipo de pago`,
  paymentInfoInsurance: `Ni {0} ni ninguna compañía de seguros médicos le cobrará por su vacuna COVID-19. Sin embargo, recopilamos información sobre seguros para garantizar la financiación de los pacientes sin seguro.`,
  paymentInfoNoInsurance: `Te tenemos cubierto. Vamos a vacunarlo.`,
  creditCard: `Tarjeta de crédito`,
  noInsurance: `Sin seguro`,
  insuranceValidationAlert: `No pudimos validar su seguro. Compruebe que está utilizando un seguro que está activo actualmente e ingresó los detalles correctos.`,
  correctInfoContinueAlert: `Si cree que su información es correcta, continúe registrándose y traiga su tarjeta de seguro a su cita.`,
  insuranceInfoConfirmedText: `La información del seguro ha sido confirmada y verificada como válida.`,
  costOfService: 'Costo del Servicio: ',
  payAtTimeOfService: 'Pagar en el momento del servicio',
  factSheet: `{0} Hoja informativa de la Autorización de uso de emergencia`,
  termsAndCondition: `Términos y condiciones`,
  termsAndConditionType1: `He leído {0} y acepto los términos y condiciones. Quiero autorizar.`,
  termsAndConditionType2: `He recibido información sobre los {0}.`,
  cdcVsafe: `Programa VSafe de los CDC`,
  securityCheckHeader: `Llene la información a continuación para cambiar la fecha/cancelar su cita`,
  securityCheckAlert: `Necesitamos tener esta información para verificar su identidad.`,
  securityCheckValidationAlert: `La información que ha proporcionado no coincide con la de su cita.`,
  emailNotice: `También verifique el texto de confirmación y correo electrónico para obtener más información`,
  appointmentIdNotFound: `No se pudo encontrar el número de identifcación de la cita`,
  appointmentIdEmptyCancellation: `No se puede cancelar la función de actividad faltante`,
  print: `Imprimir`,
  appointmentCancellationHeader: `¿Está seguro(a) de que quiere cancelar su cita?`,
  appointmentCancellationDescription: `Tenga en cuenta que al cancelar su cita se perderá toda la información que ha proporcionado.`,
  cancelAppointment: `Cancelar cita`,
  doNotCancel: `No cancelar`,
  bookAnother: `Reservar otra cita`,
  singleDoseCancellationDetailsAppointment: `La cita para`,
  doubleDoseCancellationDetailsAppointment: `La cita para la {0} dosis de`,
  appointmentWithVaccineCancelled: `la {0} cita de la vacuna ha sido cancelada`,
  confirmReschedule: `Confirme el cambio de fecha de su cita`,
  singleDoseReschedule: '¿Cambio de fecha para la cita de la {0}?',
  firstDoseReschedule:
    '¿Cambio de fecha para la cita de la primera dosis de {0}?',
  secondDoseReschedule:
    '¿Cambio de fecha para la cita de la segunda dosis de {0}?',
  waitListedDescription: `Ha sido agregadoa(a) a la lista de espera para el {0} en {1} en {2}`,
  dose: `dosis`,
  singleDoseAppointmentConfirmation: `Se confirma su cita para la vacuna {vaccineName} para {patientName} el {dateAndTime} en {location}`,
  doubleDoseAppointmentConfirmation: `Se confirmó su cita para la {doseNumber} dosis de la vacuna {vaccineName} para {patientName} el {dateAndTime} en {location}`,
  rescheduleButton: `Cambiar la fecha`,
  rescheduleModalMessageFirstDose: `Tenga en cuenta que cambiar la fecha de la primera cita también cambiará automáticamente su segunda dosis de vacunas.`,
  rescheduleModalMessageSecondDose: `Tenga en cuenta que debe reprogramar su segunda cita para la vacuna solo después de completar los28 días de su primera dosis.`,
  rescheduleModalAlert: `La segunda cita de la vacuna solo está disponible después de {0} días de la primera dosis ({1}).`,
  waitListNotice: `Ha sido agregado(a) a la lista de espera para su segunda vacuna como una política establecida por nuestro lugar. Nos pondremos en contacto con usted tan pronto como podamos confirmar su segunda cita para la vacuna. Gracias por su paciencia.`,
  underMaintenance: `En mantenimiento`,
  underMaintenanceHeader: `Lo siento, estamos {0}`,
  upTimeMessage: `Estamos trabajando para que funcione el sitio {0}`,
  underMaintenanceContactInfo: `Para cualquier pregunta, por favor {0}`,
  asSoon: `lo antes posible.`,
  emailUs: `Envíenos un correo electrónico a`,
  uptimeByTimeDate: `por {0} {1}.`,
  callUsAt: `call us at`,
  clinicNotFoundForAppointment: `No se encontró la clínica para esta cita.`,
  welcomeTo: 'Bienvenida a',
  welcomeSubText: 'Encuentre y reserve una cita cerca de usted.',
  appointmentsTaken: `Desafortunadamente, ya se llenaron todos los espacios.`,
  waitListSignUpMessage: `Inscríbase a nuestra lista de espera. Al inscribirse en la lista, se le notificará cuando haya una cita disponible y para que se le den las instrucciones para reservar su cita.`,
  joinWaitList: `Anótese en nuestra lista de espera`,
  waitListAdditionalInfo: `Seleccione otra vacuna o únase a nuestra lista de espera. Al unirse a nuestra lista de espera, se le notificará cuando haya una cita disponible para que la programe y se le proporcionarán las instrucciones para programar su cita.`,
  guardianFirstNameRequired: `El nombre del tutor no puede estar vacío`,
  guardianLastNameRequired: `El apellido del tutor no puede estar vacío`,
  waitlistSuccessTitle: `¡Excelente! Ha sido agregado a la lista de espera.`,
  waitlistSuccessInfo: `Lo agregamos a la lista de espera y le enviaremos una notificación tan pronto como tengamos la vacuna.`,
  waitlistReturnButton: `Ir a casa`,
  appointmentsUnavailable: `No hay citas disponibles`,
  appointmentsTakenDescription: `Desafortunadamente, ya se llenaron todos los espacios. Por favor, intente buscar cita en otro lugar o inscríbase a nuestra lista de espera. Al inscribirse a la lista, se le notificará cuando haya una cita disponible y se le darán instrucciones para reservar su cita.`,
  availabilityAllCaps: `DISPONIBILIDAD`,
  noClinicsAvailable: `No hay clínicas disponibles`,
  appointmentsBooked: `Ya están reservadas todas nuestras citas.`,
  appointmentsBookedDescription: `Programaremos más citas a medida que hayan más vacunas disponibles. Regrese después para reservar sus citas. Para obtener más información por favor {0}`,
  appointmentsBookedDescriptionNotify: `Programaremos más citas a medida que hayan más vacunas disponibles. Inscríbase en nuestra lista de espera, y se le notificará cuando haya una cita disponible y se le darán instrucciones para reservar su cita.`,
  contactUsSmallCase: `Contáctenos`,
  contactMethod: `Método de contacto`,
  contactMethodDescription: `¿De qué forma desea que le contactemos para programar una cita?`,
  waitListInfoHeader: `Coordinación de la vacunación`,
  waitListInfoSubHeader: `{0} está coordinando el lanzamiento de la vacunación masiva en toda la zona.`,
  waitListInfoDescription: `Se han agotado todas las citas. Publicamos nuevas citas a medida que haya más vacunas disponibles. No se preocupe, puede anotarse en nuestra lista de espera. Se le notificará cuando haya una cita disponible y se le darán instrucciones para programar la cita.`,
  aboutYouHeader: `Háblenos sobre usted`,
  fillDetails: `Ingrese sus datos a continuación.`,
  waitListed: `Lista de espera`,
  waitListAddedMessage: `Ha sido agregado a la lista de espera.`,
  waitListAddedDescription: `Nos comunicaremos con usted tan pronto como podamos confirmar su cita para recibir la vacuna. Gracias por su paciencia.`,
  getStarted: `Empezar`,
  codeRegistration: `Registro`,
  codeRegistrationHeader: `Esta página de registro está reservada para aquellos que tienen invitacion. Si tiene un código de 8 dígitos, ingréselo en el cuadro a continuación para  que pueda comenzar.`,
  singUseCodeSuccessMessage: `¡Bien hecho! Ahora puede prograr su cita para recibir la vacuna. Tenga en cuenta que este código de invitación solo puede usarse para programar una cita para un paciente. `,
  codeRegistrationValidationMessage: `El código no puede contener caracteres especiales, revíselo e inténtelo de nuevo.`,
  codeRegistrationValidationMessageLength: `El código debe contener 8 caracteres, revíselo e inténtelo de nuevo.`,
  hideDetails: 'Ocultar ubicaciones',
  showDetails: 'Mostrar ubicaciones',
  signatureRequired: 'Se requiere su firma',
  cancelAppointmentMessage:
    'Su cita ha sido cancelada. Haga clic en el botón de abajo si desea reservar otra cita.',
  noShowMessage:
    'Su cita ha sido cancelada. Haga clic en el botón de abajo si desea reservar otra cita.',
  frownAlt: 'Fruncir el ceño',
  hurrayAlt: '¡Viva!',
  weMissedYou: '¡Le echamos de menos!',
  vaccinated: '¡Viva! Ya recibió la vacuna',
  vaccinatedMessage:
    'Aunque ya recibió la vacuna, continúe utilizando una mascarilla y siga todas las precauciones de seguridad necesarias al salir',
  fulfilled: 'Cumplida',
  cancelled: 'Cancelada',
  noShow: 'Perdida',
  secondCancelled: 'Está cancelada su segunda cita',
  prepaidByOrganization:
    'Por este servicio el pago ya ha sido realizado por la organización {0}.',

  // Patient Info --> Physician Info
  physicianName: 'Nombre',
  physicianPhoneNumber: 'Número de teléfono',
  physicianInfoHeading: 'Información del médico de atención primaria',
  physicianInfoSubHeading:
    'Proporcione los detalles de su médico de atención primaria para ayudarnos a coordinar adecuadamente con su PCP.',

  // Address Validation
  enterAddressManually: 'Click here to enter the address manually',
  couldNotFindAddressInfo:
    'No pudimos verificar esta dirección. Por favor, compruebe que su información es correcta antes de continuar.',
  didYouMean: '¿Querías decir?',
  confirmAddress: '¿Confirmar dirección?',
  goBackToEdit: 'volver a editar',
  youEnteredAddress: 'Ingresaste la siguiente dirección',
  youEntered: 'Entraste',
  suggestedAddress: 'Actualizar con dirección sugerida',
  updateSuggestedAddress: 'Update with suggested address',
  useUserProvidedAddress: 'Usar la dirección que proporcioné',
  useOriginalAddress: 'Usar la dirección original ingresada',
  // Services
  subserviceSelectionNotice:
    'Algunas vacunas son series de múltiples inyecciones o dosis. Tener esta información nos ayudará a preparar las tomas.',

  // Email Validations Message
  emailRequired2: 'La direccion de correo electronico es un campo requerido.',
  confirmEmailRequired:
    'Confirme que la dirección de correo electrónico es un campo obligatorio y debe ser similar a la dirección de correo electrónico.',
  validEmailRequired: 'Añade una dirección de correo electrónico válida.',
  validConfirmEmailRequired:
    'Agregue una dirección de correo electrónico de confirmación válida.',

  emailMatchRequired: 'Email and confirm email do not match.',
  // Services
  // Location first Clinic and Timing + Confirmation
  backToClinicAndVaccine: 'Volver a la selección de clínica y vacuna',
  fetchingAvailableDates: 'Buscando fechas disponibles',
  continueToPaymentPage: 'Enviar y continuar',
  continueToConfirmation: 'Continuar a la página de confirmación',
  appointmentsForDate: 'Citas para {0}',
  selectClinic: 'Seleccione una clínica',
  searchClinics: 'Buscar Clínicas',
  accordionGeneralLabel: 'Seleccione una fecha y hora para su cita',
  accordionFirstLabel: 'Seleccione una fecha y hora para su primera cita',
  accordionSecondLabel: 'Seleccione una fecha y hora para su segunda cita',
  accordionSelectionLabel: 'Su {0} cita  será el {1}',
  accordionSelectionLabelFulfilled: 'Su {0} cita  será el {1}',
  emptyTimeslotsLabel: 'Seleccione la fecha primero',
  emptyTimeslotsDescription:
    'Haga clic en una fecha disponible en el calendario para ver las horas',
  clinicCount: 'Se ha(n) encontrado {0} clínica(s) en su zona',
  selectVaccine: 'Seleccione una servicio',
  unavailableVaccine: 'Esta vacuna no está disponible en este centro',
  confirmAppointmentDetails: 'Confirme los detalles de su cita',
  positiveConfirmation: 'Sí, seguro',
  changeClinicConfirmation: '¿Seguro que quiere cambiar la clínica?',
  changeClinicWarning:
    'Al cambiar de clínica, se borrará su selección de vacuna y tendrá que hacer otra cita.',
  changeVaccineConfirmation: '¿Seguro que quiere cambiar la vacuna?',
  changeVaccineWarning:
    'Al cambiar el tipo de vacuna, tendrá que hacer otra cita.',
  changeDateConfirmation: '¿Seguro que quiere cambiar la fecha de la cita?',
  changeDateWarning:
    'Al cambiar la fecha u hora de la cita, tendrá que hacer otra cita.',
  serviceLabel: 'Servicio',
  serviceType: 'Tipo de servicio',
  vaccineType: 'Tipo de Servicio',
  appointmentType: 'Tipo de cita',
  editVaccineType: 'Cambiar tipo de Servicio',
  vaccineInformation: 'Información sobre la vacuna',
  calendarAvailable: 'Días disponibles',
  calendarUnavailable: 'Días no disponibles',
  selectFirstDose: 'Seleccione un espacio para la primera dosis',

  // Multiservice
  welcomeText: 'Bienvenido/a a',
  brandName: 'Managed Vaccination Solution',
  locationSearchPlaceholder: 'Todas las ubicaciones',
  preferredLocation:
    'Filtrar por ubicación (escriba su ciudad, calle o código postal)',
  servicePlaceholder: 'Seleccionar tipo de cita',
  vaccinePlaceholder: 'Seleccione un servicio',
  subservicePlaceholder: 'Especificar la dosis/servicio para la cita',
  serviceFirstPlaceholder: 'Por favor seleccione la cita primero',
  vaccineFirstPlaceholder: 'Por favor seleccione el servicio primero',
  clinicsForService: 'Clínicas de {0}',
  clinicsForServiceAndLocation: 'Clínicas de {0} en {1}',
  searchClinicCount: 'Encontramos {0} resultado(s)',
  refreshData: 'Actualizar datos',
  search: 'Buscar',
  emptyClinicsText: 'No se encontró ninguna clínica en esta zona',
  emptyClinicsTryAgain: 'Haga otra búsqueda con otro servicio o ubicación',
  list: 'Lista',
  map: 'Mapa',
  cancelMessage: '¿Seguro que desea cancelar su solicitud?',
  cancelWarning:
    'ESe borrará toda la información que proporcionó y tendrá que empezar de nuevo para reservar otra cita.',
  vaccinationTypeLabel: 'Tipo de vacuna',
  testTypeLabel: 'Tipo de prueba',
  locationLabel: 'Ubicación',
  scheduleAppointment: 'Hacer una cita',
  selectedVaccineAria: 'Su vacuna seleccionada es {0}',
  selectedAppointmentAria: 'El tipo de cita seleccionada es {0}',
  subServiceSelectionLabel: 'Seleccione el tipo de servicio para la vacuna {0}',

  // PatientInfo --> Notification Preferences
  notificationPreferences: 'Preferencias de notificación',
  emailConsentTitle: 'Correo electrónico',
  smsConsentTitle: 'SMS de texto',
  notificationPreferencesMessage1: 'Al marcar las casillas,',
  notificationPreferencesConsentDefault:
    'Acepto recibir detalles de citas, recordatorios y resultados en mi correo electrónico y número de teléfono móvil proporcionados anteriormente.',
  notificationPreferencesConsentMarketing:
    'Acepto recibir mensajes informativos, como notificaciones de clínicas de vacunación estacionales o servicios clínicos que puedan ser apropiados para mí.',
  notificationPreferencesMessage2:
    'se adhiere a los estándares de HIPAA y desea comunicarse con usted por correo electrónico y sms de texto con respecto a la confirmación de citas, recordatorios, resultados de pruebas. Tenga en cuenta que el correo electrónico y los mensajes de texto pueden no ser completamente seguros y, al marcar las casillas, acepta recibir mensajes de esta manera.',

  // Back Button Texts
  backToLocationSelectionPage: 'Volver a la página de selección de la clínica',
  backToPatientEligibilityPage:
    'Volver a la página de elegibilidad del paciente',
  backToEligibilityAndHealthScreeningPage:
    'Volver a la página de exámenes de salud',
  backToPatientInfoPage: 'Volver a la página de información del paciente',
  backToScheduleAppointmentPage: 'Volver a la página de programación de citas',
  backToServiceSelectionPage: 'Volver a la página de selección de servicios',
  backToPreviousPage: 'Volver a la página anterior',

  // Booster shot
  isOldPatientConfirmation:
    '¿Ha recibido algún servicio de nuestra parte antes?',
  enterResponseText: 'Ingrese su respuesta',

  iisConsent:
    'Al marcar esta casilla, acepto compartir mi información de vacunación con el Sistema de información de vacunación de Nueva Jersey (NJIIS).',
  iisConsentInfo:
    'Si tiene menos de 7 años, la información de su vacuna debe enviarse al Sistema de Información de Vacunación.',
  //Covid Result
  covidresultHeader:
    'Por favor complete el siguiente formulario para continuar',
  covidresultAlert: 'Necesitamos esta información para verificar su identidad.',
  //not eligible page
  notQualifiedForSubserviceMessage1: '¡Ups! No califica para el servicio.',
  ageLimitPresent: 'Hay un límite de edad y no calificas para el',
  forFurtherInfo: 'Para más información por favor',
  bookDifferentService: 'Reserva otro servicio >',

  //Prescreening
  prescreeningHeading: 'Cuéntanos un poco sobre ti.',
  prescreeningSubHeading:
    'Antes de comenzar a reservar su cita, necesitamos verificar algunas cosas.',
  prescreeningDOBHeading: 'Introduzca su fecha de nacimiento',
  prescreeningButtonLabel: 'Continuar',

  pastAppointmentDateMessage:
    'Lamentamos informarle que ya pasó la fecha de la cita. No obstante, puedes reservar tu cita a través de nuestro sistema de registro o llamar a la clínica.',
};

export default language;
