import ReactDOM from 'react-dom';
import { FiX } from 'vyaguta-icons/fi';
import React, { useState, useEffect, useRef } from 'react';
import cls from 'classnames';
import { handleSpaceOrEnterKey } from 'util/handleSpaceOrEnterKey';

const Modal = ({
  children,
  close,
  containerClassName,
  headerContent,
  isClosable = true,
  dialogAriaTitle = 'Dialog aria title',
  dialogAriaDescription = 'Dialog Description',
  modalHeaderClassName = '',
  popupTitle = '',
}) => {
  // isOpen is to animate the modal.
  const [isOpen, setIsOpen] = useState(false);
  const dialogFocusRef = useRef(null);
  /**
   * Hides the body scroll when modal is opened.
   */
  useEffect(() => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
    dialogFocusRef && dialogFocusRef.current.focus();
    return () => {
      setIsOpen(false);
      document.body.style.overflow = 'unset';
    };
  }, []);

  const modal = (
    <div
      className="modal"
      role="dialog"
      aria-labelledby={dialogAriaTitle}
      aria-describedby={dialogAriaDescription}
    >
      <div
        tabIndex="0"
        className="modal__backdrop"
        onClick={close}
        ref={dialogFocusRef}
      />
      <div
        className={`modal__wrapper ${isOpen && 'open'} ${containerClassName}`}
      >
        <div className="modal__content">
          <div className={cls(modalHeaderClassName, 'modal__header')}>
            {headerContent}
            {isClosable && (
              <button
                className="modal__close-btn"
                title={popupTitle || 'Close this popup'}
                data-qa="close-modal"
              >
                <FiX
                  onClick={close}
                  tabIndex={0}
                  onKeyDown={(e) => handleSpaceOrEnterKey(e, close)}
                />
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );

  return <> {ReactDOM.createPortal(modal, document.body)} </>;
};

export default Modal;
