const ClinicIcon = (props) => {
  return (
    <svg
      className={props.className}
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.7068 2.2932C12.614 2.20026 12.5038 2.12652 12.3825 2.07621C12.2612 2.0259 12.1311 2 11.9998 2C11.8685 2 11.7384 2.0259 11.6171 2.07621C11.4958 2.12652 11.3856 2.20026 11.2928 2.2932L2.29279 11.2932C2.15298 11.4331 2.05777 11.6112 2.0192 11.8052C1.98064 11.9991 2.00044 12.2002 2.07611 12.3829C2.15178 12.5656 2.27992 12.7217 2.44433 12.8316C2.60874 12.9415 2.80204 13.0002 2.99979 13.0002H3.99979V20.0002C3.99979 21.1032 4.89679 22.0002 5.99979 22.0002H17.9998C19.1028 22.0002 19.9998 21.1032 19.9998 20.0002V13.0002H20.9998C21.1978 13.0006 21.3914 12.9423 21.5561 12.8324C21.7208 12.7226 21.8492 12.5664 21.925 12.3835C22.0007 12.2006 22.0204 11.9993 21.9816 11.8052C21.9427 11.6111 21.8471 11.4329 21.7068 11.2932L12.7068 2.2932V2.2932ZM18.0008 20.0002H5.99979V10.4142L11.9998 4.4142L17.9998 10.4142V15.0002L18.0008 20.0002Z" />
      <path d="M13 10H11V13H8V15H11V18H13V15H16V13H13V10Z" />
    </svg>
  );
};

export default ClinicIcon;
