import { Document, Page, StyleSheet, View, Font } from '@react-pdf/renderer';

import DmeHeaderPdfView from '../presentation/common/DmeHeader/DmeHeaderPdfView';
import { initialQuestionnaireData } from '../constants/initialDeliveryTicketValues';
import PharmacyInfoPdfView from '../presentation/common/PharmaInfo/PharmaInfoPdfView';
import PatientInfoPdfView from '../presentation/common/PatientInfo/PatientInfoPdfView';
import ItemTablePdfView from '../presentation/DeliveryTicket/ItemTable/ItemTablePdfView';
import DMESignaturePdfView from '../presentation/common/DMESignature/DMESignaturePdfView';
import DeliveryInfoPdfView from '../presentation/common/DeliveryInfo/DeliveryInfoPdfView';
import QuestionnairePdfView from '../presentation/DeliveryTicket/Questionnaire/QuestionnairePdfView';
import DocumentCheckListPdfView from '../presentation/DeliveryTicket/DocumentCheckList/DocumentCheckListPdfView';
import ProductSettingAndTestingFormPdfView from '../presentation/DeliveryTicket/ProductSettingAndTestingForm/ProductSettingAndTestingFormPdfView';
import ProofOfDeliveryAcknowledgementPdfView from '../presentation/DeliveryTicket/ProofOfDeliveryAcknowledgement/ProofOfDeliveryAcknowledgementPdfView';
import HomeAssessmentAndSafetyPdfView from '../presentation/DeliveryTicket/HomeAssessmentAndSafety/HomeAssessmentAndSafetyPdfView';

import { getString } from 'util/lang';
import { joinValues } from 'util/string';
import { contactMasking } from 'util/contactFormat';
import { printFontBoldCDN, printFontFamily } from 'constants/dme';

const LABEL_PREFIX = 'dme';

const DeliveryTicketPdfView = ({
  logoUrl,
  organizationName,
  organizationContactPhone,
  billingAddress,
  billingCity,
  billingState,
  billingZipCode,
  deliveryTicketResponse,
}) => {
  Font.register({
    family: printFontFamily,
    fonts: [
      {
        src: printFontBoldCDN,
        fontWeight: 400,
      },
      {
        src: printFontBoldCDN,
        fontWeight: 700,
      },
    ],
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <DmeHeaderPdfView
          logoUrl={logoUrl}
          organizationName={organizationName}
          title={getString(`${LABEL_PREFIX}.deliveryTicket.title`)}
        />
        <View style={{ marginTop: 12 }} />
        <PharmacyInfoPdfView
          name={organizationName}
          address={joinValues(
            [billingAddress, billingCity, billingState, billingZipCode],
            ', ',
          )}
          contact={contactMasking(organizationContactPhone)}
        />
        <View style={styles.row}>
          <PatientInfoPdfView
            name={deliveryTicketResponse?.patientData?.name}
            dob={deliveryTicketResponse?.patientData?.dob}
            phone={deliveryTicketResponse?.patientData?.phone}
          />
          <DeliveryInfoPdfView data={deliveryTicketResponse?.deliveryInfo} />
        </View>
        <ItemTablePdfView tableData={deliveryTicketResponse?.orderItemData} />
        <QuestionnairePdfView
          questionnaire={
            deliveryTicketResponse?.questionnaireData ??
            initialQuestionnaireData
          }
        />
        <DocumentCheckListPdfView
          documentData={deliveryTicketResponse?.documentsData}
        />
        <HomeAssessmentAndSafetyPdfView
          homeAssessmentData={deliveryTicketResponse?.homeAssessmentData}
        />
        <ProductSettingAndTestingFormPdfView
          settings={deliveryTicketResponse?.settingAndTestingData?.settings}
          tests={deliveryTicketResponse?.settingAndTestingData?.tests}
        />
        <DMESignaturePdfView
          title={getString(
            `${LABEL_PREFIX}.deliveryTicket.setupSignature.title`,
          )}
          style={{
            marginTop: 10,
          }}
          signature={deliveryTicketResponse?.setupSignatureData}
        />
        <ProofOfDeliveryAcknowledgementPdfView
          proofOfDeliveryData={deliveryTicketResponse?.proofOfDeliveryData}
          consent={deliveryTicketResponse?.consent}
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    display: 'block',
    height: '100%',
    width: '100%',
    padding: '20px',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
});

export default DeliveryTicketPdfView;
