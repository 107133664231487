import React from 'react';
import { getString } from 'util/lang';
import { FiCheck } from 'vyaguta-icons/fi';
import SelectBox from '../../../../common/SelectBox';
import FormInput from '../../../../common/FormInput';
import FormErrors from '../../../../common/FormErrors';
import {
  StateDropDown,
  IdTypesOptions,
  NoInsuranceInfoKeys,
} from '../../../../../constants/patientInfo';

const NoInsuranceForm = (props) => {
  const { onChange, noInsuranceInfo, errors } = props;
  const errorSet = {};
  NoInsuranceInfoKeys.forEach((key) => {
    if (errors[key]) {
      errorSet[key] = errors[key];
    }
  });

  return (
    <section className="form-patient-address">
      <h3 className="mt-2x">{getString('personalIdInfo')}</h3>
      <div className="patient-form-group p-0x">
        <div className="row fs-exclude">
          <div className="col-12-md">
            <SelectBox
              id="noInsuranceIdType"
              defaultValue=""
              options={IdTypesOptions}
              onChange={(e) => onChange('noInsuranceIdType', e.target.value)}
              label={getString('idType')}
              value={noInsuranceInfo.noInsuranceIdType}
              hasError={
                !noInsuranceInfo.noInsuranceAttempted &&
                !!errorSet['noInsuranceIdType']
              }
              required={!noInsuranceInfo.noInsuranceAttempted}
              dataqa="no-insurance-type"
            />
          </div>
          <div className="col-12-md">
            <FormInput
              label={getString('idNumber')}
              id="noInsuranceIdNumber"
              name="noInsuranceIdNumber"
              onChange={onChange}
              value={noInsuranceInfo.noInsuranceIdNumber}
              hasError={
                !noInsuranceInfo.noInsuranceAttempted &&
                !!errorSet['noInsuranceIdNumber']
              }
              required={!noInsuranceInfo.noInsuranceAttempted}
              data-qa="no-insurance-id-number"
            />
          </div>
          <div className="col-12-md">
            <SelectBox
              id="noInsuranceState"
              defaultValue=""
              options={StateDropDown}
              onChange={(e) => onChange('noInsuranceState', e.target.value)}
              label={getString('state')}
              value={noInsuranceInfo.noInsuranceState}
              hasError={
                !noInsuranceInfo.noInsuranceAttempted &&
                !!errorSet['noInsuranceState']
              }
              required={!noInsuranceInfo.noInsuranceAttempted}
              dataqa="no-insurance-state"
            />
          </div>
          <div className="col-12-md">
            <div
              data-qa="patient-info-insuranceAttempted"
              className="payment-status custom-check"
            >
              <input
                autoComplete="off"
                type="checkbox"
                id="noInsuranceAttempted"
                onChange={(e) =>
                  onChange(e.currentTarget.id, e.currentTarget.checked)
                }
                value={noInsuranceInfo.noInsuranceAttempted}
                checked={noInsuranceInfo.noInsuranceAttempted}
                data-qa="no-insurance-attempted-checkbox"
              />
              <label htmlFor="noInsuranceAttempted">
                <FiCheck />
                {getString('noId')}
              </label>
            </div>
          </div>
        </div>
      </div>
      <FormErrors errors={!noInsuranceInfo.noInsuranceAttempted && errorSet} />
    </section>
  );
};

export default NoInsuranceForm;
