import { useParams } from 'react-router-dom';
import { isMobile, isAndroid } from 'react-device-detect';
import React, { useContext, useEffect } from 'react';
import { latoBold, latoRegular } from 'assets/fonts/fonts';
import {
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';

import { DetailContext, useAppSettings } from 'context';
import SecurityCheck from '../../SecurityCheck/container/SecurityCheck';
import { useGetTestReportQuery } from 'redux/test-report/testReport.api';

import Button from 'components/common/Button';
import Navbar from 'components/COVID/common/navbar/navbar';
import FullPageLoader from 'components/common/FullPageLoader';
import TestReportDocument from '../presentation/TestReportDocument';

import * as toast from 'util/toast';
import { getString } from 'util/lang';
import { APPOINTMENT_TOKEN } from 'constants/appointment';
import {
  getDataFromSessionStorage,
  storeDataToSessionStorage,
} from 'util/sessionStorage';

const TestReportPdfView = () => {
  const { id } = useParams();

  const appContext = useContext(DetailContext);
  const { organizationName, billingAddress } = useAppSettings();
  const { isSecurityChecked, setSecurityChecked, setSecurityToken, details } =
    appContext;

  const {
    data: testReportResponse,
    isError: isTestReportError,
    isLoading: isTestReportLoading,
    error,
  } = useGetTestReportQuery(id, {
    skip: !isSecurityChecked, // Skip the query if security is not checked
  });

  useEffect(() => {
    const token = getDataFromSessionStorage(APPOINTMENT_TOKEN);
    if (token) {
      setSecurityChecked(true);
    }
  }, []);

  useEffect(() => {
    if (isTestReportError) {
      setSecurityChecked(false);
      toast.error({
        title: 'Error',
        message: error?.errorMessage || getString('errorMessage'),
      });
    }
  }, [isSecurityChecked, testReportResponse, isTestReportError, error]);

  const confirmSecurity = (checked, { token }) => {
    setSecurityChecked(checked);
    storeDataToSessionStorage(APPOINTMENT_TOKEN, token);
    setSecurityToken(token);
  };

  if (!isSecurityChecked) {
    return (
      <SecurityCheck
        confirmSecurity={confirmSecurity}
        formType={'covidresult'}
      />
    );
  }

  if (isTestReportLoading) {
    return <FullPageLoader />;
  }

  if (isMobile && isAndroid) {
    return (
      <>
        <Navbar displayNavLinks={false} logo={details.appSettings.logoUrl} />
        <div
          style={{ height: '80vh' }}
          className="d-flex justify-content-center align-items-center flex-column"
        >
          <p className=" d-flex my-4x mx-4x text-center">
            {getString('downloadPdf', 'test report')}
          </p>
          <PDFDownloadLink
            document={
              <TestReportDocument
                organizationName={organizationName}
                billingAddress={billingAddress}
                testReportResponse={testReportResponse}
              />
            }
            fileName={`${id}.pdf`}
          >
            {({ loading }) =>
              loading ? (
                <FullPageLoader />
              ) : (
                <Button
                  isEnabled={isSecurityChecked}
                  label={getString('download')}
                  dataqa="goto-download-btn"
                />
              )
            }
          </PDFDownloadLink>
        </div>
      </>
    );
  }

  return (
    <PDFViewer style={styles.pdfViewer}>
      <TestReportDocument
        organizationName={organizationName}
        billingAddress={billingAddress}
        testReportResponse={testReportResponse}
      />
    </PDFViewer>
  );
};

Font.register({
  family: 'Lato',
  fonts: [
    { src: latoBold, fontWeight: 'bold' },
    { src: latoRegular, fontWeight: 'normal' },
  ],
});
const styles = StyleSheet.create({
  pdfViewer: {
    height: '99vh',
    width: '100%',
  },
});

export default TestReportPdfView;
