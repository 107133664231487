import React, { useEffect } from 'react';

import InformationSection from '../../ConfirmAndSubmit/InformationSection';
import Acknowledgement from '../../HealthScreening/presentation/Acknowledgement';
import { RemoveIneligiblePatient } from './RemoveIneligible';

import { answerKey } from 'constants/confirm';
import { CHOICE } from 'constants/screeningQuestions';

const HealthScreening = (props) => {
  // Effect on Component Mount
  useEffect(() => {
    window.scrollTo(0, 0);
    const answers =
      props.details.patientHealthAnswers[props.currentPatientIndex] ?? {};
    checkIfAllHealthQuestionsAreAnswered(answers);
  }, []);

  const onAdditionalInfoChange = (id, newValue) => {
    const questionId = id.split('additional-screening-')[1];
    const additionalInfo = newValue;
    let answers =
      props.details.patientHealthAnswers[props.details.currentPatientIndex] ??
      {};
    answers[questionId] = { ...answers[questionId], additionalInfo };
    props.onHealthQuestionAnswerChange({
      [questionId]: { ...answers[questionId], additionalInfo },
    });
    checkIfAllHealthQuestionsAreAnswered(answers);
  };

  const onSelectChange = (e, id) => {
    const value = e.target.value;
    let answers =
      props.details.patientHealthAnswers[props.details.currentPatientIndex] ??
      {};
    answers[id] = { ...answers[id], value };
    props.onHealthQuestionAnswerChange({ [id]: { ...answers[id], value } });
    checkIfAllHealthQuestionsAreAnswered(answers);
  };

  /**
   * Check if all health questions are answered
   *
   * @param {object} answers: Answers
   */
  const checkIfAllHealthQuestionsAreAnswered = (answers) => {
    let showAlertFlag = false;
    let allowHealthServiceFlag = true;

    const questions = props.screeningQuestions;

    if (questions.length === 0) {
      props.setAllowHealthService(true);
      return;
    }

    if (Object.keys(answers).length < questions.length) {
      allowHealthServiceFlag = false;
    } else {
      Object.keys(answers).forEach((answerId) => {
        const question = questions.find((question) => question.id === answerId);

        const isQuestionsWithErrors = question.errorType === 'ERROR';
        const isQuestionsWithWarnings = question.errorType === 'WARNING';

        if (!isQuestionsWithErrors && !isQuestionsWithWarnings) {
          return;
        }

        const answer = answers[answerId];

        if (isQuestionsWithErrors) {
          if (
            question.enableAdditionalQuestion === CHOICE.Yes &&
            question.additionalInfoCriteria === CHOICE[answer.value]
          ) {
            const isAdditionalInfoInvalid = (() => {
              if (answer.additionalInfo) {
                return false;
              }
              return true;
            })();
            if (isAdditionalInfoInvalid) {
              showAlertFlag = true;
              allowHealthServiceFlag = false;
            }
          } else {
            const isAnswerInvalid = (() => {
              switch (answer.value) {
                case answerKey.yes:
                  return question.eligibilityCriteria === 'YES';
                case answerKey.no:
                  return question.eligibilityCriteria === 'NO';
                default:
                  return null;
              }
            })();

            if (isAnswerInvalid) {
              showAlertFlag = true;
              allowHealthServiceFlag = false; // disable submit.
            }
          }
        }

        if (isQuestionsWithWarnings) {
          if (
            question.enableAdditionalQuestion === CHOICE.Yes &&
            question.additionalInfoCriteria === CHOICE[answer.value] &&
            !answer.additionalInfo
          ) {
            allowHealthServiceFlag = false;
            showAlertFlag = true;
            return;
          }
        }
      });
    }

    props.setShowAlert(showAlertFlag);
    props.setAllowHealthService(allowHealthServiceFlag);
  };

  const {
    screeningQuestions,
    showAlert,
    currentPatientIndex,
    isGroupAppointment,
  } = props;
  const { isScreeningQuestionFetching, patientHealthAnswers } = props.details;
  const patientResponse =
    patientHealthAnswers[props.details.currentPatientIndex] ?? {};

  return (
    <React.Fragment>
      <div>
        {screeningQuestions && (
          <>
            <Acknowledgement
              answers={patientResponse}
              showAlert={props.showAlert}
              questions={screeningQuestions}
              showAllAcceptedAlert={props.allowHealthService}
              onAnswerChange={onSelectChange}
              onAdditionalInfoChange={onAdditionalInfoChange}
              loading={isScreeningQuestionFetching}
            />
            <InformationSection
              answers={patientHealthAnswers}
              questions={screeningQuestions}
              defaultWarnings={[]}
            />
            {isGroupAppointment && showAlert && (
              <RemoveIneligiblePatient
                removeCurrentPatient={props.removeCurrentPatient}
                currentPatientIndex={currentPatientIndex}
              />
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default HealthScreening;
