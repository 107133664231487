import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

/**
 * Renders pdf header with logo and form title
 *
 * @param {string} logoUrl
 * @param {string} title
 * @param {string} organizationName
 */
const DmeHeaderPdfView = ({ logoUrl, title, organizationName }) => (
  <View style={styles.wrap}>
    <View style={styles.titleWrap}>
      <Text style={styles.title}>{title}</Text>
    </View>
    <Image src={logoUrl} style={styles.logo} alt={organizationName} />
  </View>
);

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
    height: 60,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid #E1DFDD',
  },
  titleWrap: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 12,
    fontWeight: 800,
    color: '#07426F',
  },
  logo: {
    height: 50,
    minWidth: 50,
    width: 'auto',
    backgroundColor: '#E1DFDD',
  },
});

export default DmeHeaderPdfView;
