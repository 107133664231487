import React from 'react';
import { Link } from 'react-router-dom';

import { getString } from 'util/lang';
import WarningIcon from 'svg-images/WarningIcon';
import { CONTACT_US } from 'constants/routes/covidRoutes';

const NoAppointmentsNotice = () => {
  return (
    <div className="section__nosites">
      <div className="section__nosites__info">
        <WarningIcon />
        <h2>{getString('noClinicsAvailable')}</h2>
      </div>
      <p className="text-center label">
        <b>{getString('appointmentsBooked')}</b>{' '}
        {getString(
          'appointmentsBookedDescription',
          <Link to={{ pathname: CONTACT_US, search: window.location.search }}>
            {getString('contactUsSmallCase')}
          </Link>,
        )}
      </p>
    </div>
  );
};

export default NoAppointmentsNotice;
