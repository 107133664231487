import config from '../config';
import * as http from '../util/http';

/**
 * Fetch patient Eligibility  questions
 *
 * @returns {array} data: List of patient Eligibility question
 */
export async function fetchEligibilityQuestions(
  selectedLanguageId,
  subserviceId,
) {
  const url = `${config.entity.eligibilityQuestions}/${subserviceId}/${selectedLanguageId}`;
  const { data } = await http.get(url);
  return data.data;
}
