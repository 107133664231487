import { useCallback, useMemo } from 'react';
import { useApplicationContext, useDetailContext } from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import {
  makeFollowupRegisterNavLinks,
  makeFollowupRegisterNavLinksWithNoPaymentOption,
} from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import ConfirmationAndSubmission from 'components/COVID/views/Confirmation/container/Confirmation';
import { useAppointmentSubmission } from 'context/useAppointmentSubmission';
import { changeTitle } from 'util/siteInfo';

export const RegisterConfirmation = () => {
  changeTitle('Follow Up | Confirmation');
  const {
    details: { pageComplete },
  } = useDetailContext();
  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();
  const { submitAppointment } = useAppointmentSubmission();
  const { isPaymentOptionEnabled } = useApplicationContext();

  const toNextPage = useCallback(() => {
    nav.toFollowup.register.success(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      isPaymentOptionEnabled
        ? makeFollowupRegisterNavLinks(
            pageComplete,
            appointmentId,
            organizationId,
          )
        : makeFollowupRegisterNavLinksWithNoPaymentOption(
            pageComplete,
            appointmentId,
            organizationId,
          ),
    [organizationId, pageComplete, appointmentId],
  );

  const goBack = useCallback(() => nav.goBack(), [nav]);

  const updateAppointment = useCallback(() => {
    return submitAppointment(appointmentId, true);
  }, [submitAppointment, appointmentId]);

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ConfirmationAndSubmission
        confirmAndSubmit={updateAppointment}
        toNextPage={toNextPage}
        goBack={goBack}
        disableEditSchedule
        disableEditService
      />
    </PageWrapper>
  );
};
