import { getString } from 'util/lang';

// Get string wrt. count of slots available
export const getSlotCountString = (slotCount) => {
  // if (!slotCount) return;
  switch (slotCount) {
    case 0:
      return getString('noSlotsAvailable');
    case 1:
      return getString('oneSlotAvailable');
    default:
      return getString('nSlotsAvailable', slotCount);
  }
};
