import React from 'react';
import LabelledFormInput from 'components/common/FormInput/LabelledFormInput';
import LabelledDateInput from 'components/common/DateInput/LabelledDateInput';

const CONTROL_TYPES = {
  TEXT: 'text',
  DATE: 'date',
  NUMBER: 'number', // NOTE: this type is not defined in server or dataverse
};

const AdditionalScreeningInput = (props) => {
  const { qid, type, questionLabel, value, handleChange } = props;
  const controlType = CONTROL_TYPES[type];

  if (controlType === 'date') {
    return (
      <LabelledDateInput
        id={`additional-screening-${qid}`}
        value={value}
        handleDateChange={handleChange}
        required={true}
        solo={true}
        label={questionLabel}
      />
    );
  }

  return (
    <LabelledFormInput
      id={`additional-screening-${qid}`}
      name={`additional-screening-${qid}`}
      type={controlType}
      solo={true}
      value={value}
      required={true}
      label={questionLabel}
      className="form-control"
      onChange={handleChange}
      ß
    />
  );
};

export default AdditionalScreeningInput;
