import { useEffect, useState } from 'react';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { checkFollowupStatus } from 'services/appointment';

export const useFollowupStatusCheck = (appointmentId, redirect = true) => {
  const nav = useNavigationHelper();
  const [status, setStatus] = useState({
    loaded: false,
    isFollowup: false,
    ok: false,
    error: false,
  });

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const followupStatus = await checkFollowupStatus(appointmentId);

        setStatus({
          loaded: true,
          isFollowup: followupStatus.followup,
          ok: !followupStatus.late,
          error: false,
        });

        if (followupStatus.late && redirect) {
          nav.toFollowup.late(appointmentId, followupStatus.subServiceIds);
        }
      } catch (error) {
        console.error(error);
        setStatus({
          loaded: true,
          isFollowup: false,
          ok: false,
          error: true,
        });
      }
    };

    checkStatus();
  }, [appointmentId, nav, redirect]);

  return status;
};
