import { useCallback, useMemo } from 'react';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useAppSettings, useSelectionsContext } from 'context';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import NotEligibleComponent from '../presentation/NotEligibleComponent';

const NotEligible = (props) => {
  const nav = useNavigationHelper();
  const { selectedServices } = useSelectionsContext();
  const { organizationContactPhone, organizationContactEmail } =
    useAppSettings();

  const serviceNames = useMemo(
    () => selectedServices.map(({ service }) => service.name),
    [selectedServices],
  );

  const goToHomePage = useCallback(() => {
    // resetToFirstPage expects first param as optional org id
    // passing this directly to onClick handler passes the event object as org id
    // causing [object Object] to be passed
    nav.resetToFirstPage();
  }, [nav]);

  if (!selectedServices.length) {
    nav.resetToFirstPage();
  }

  return (
    <PageWrapper>
      <NotEligibleComponent
        serviceNames={serviceNames}
        goToHomePage={goToHomePage}
        contactEmail={organizationContactEmail}
        contactPhone={organizationContactPhone}
      />
    </PageWrapper>
  );
};

export default NotEligible;
