import React from 'react';

const PositiveNotice = () => {
  return (
    <div className="px-5x pb-6x">
      <p className="text-left label">
        Since, you have been tested{' '}
        <span className="text-demibold">Positive</span> , please make sure you
        follow things mentioned in the list below.
      </p>
      <div className="alert alert--warning py-4x px-10x">
        <ul className="list__bullet">
          <li>Stay in isolation for atleast 7 days</li>
          <li>Alert those you came in close contact with</li>
          <li>Alert your doctor</li>
          <li>Properly monitor your symptoms</li>
        </ul>
      </div>
    </div>
  );
};

export default PositiveNotice;
