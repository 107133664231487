import { useDetailContext, useSelectionsContext } from 'context';
import { getLanguageIdByLanguageCode } from 'util/lang';
import * as appointmentService from 'services/appointment';
import {
  InsuranceCardLogicalName,
  PaymentOptions,
} from 'constants/patientInfo';
import { replaceArrayElementByIndex } from 'util/array';
import { confirmGroupAppointment } from '../services/update-appointment';
import {
  createBatchAppointments,
  getDataRequests,
} from '../services/create-appointment';
import { omit } from 'lodash';
import { getBirthDateString } from '../util/string';

const uploadFile = async (activityId, cardType, card, token) => {
  if (card) {
    await appointmentService.uploadFile(activityId, cardType, card, token);
  }
};

/**
 * Appointment submission method for group, changes may have to be made in useAppointmentSubmission accordingly
 * @param emailConfirmation
 * @returns {{submitAppointment: (function(*=, *=): Promise<void>)}}
 */
export const useEmailConfirmationForGroupAppointment = (
  emailConfirmation = false,
) => {
  const { setDetails } = useDetailContext();

  const { selectedServices, selectedClinic, isCounsellingSelected } =
    useSelectionsContext();

  const submitAppointment = async (
    appointmentId = null,
    noBlockDetails = false,
  ) => {
    const detailsPromise = new Promise((resolve) => {
      setDetails((details) => {
        resolve(details);
        return {};
      });
    });

    if (!appointmentId) {
      return;
    }

    const {
      appointments,
      patientInfo,
      patientHealthAnswers,
      currentPatientIndex,
      organizationId,
      appointmentDetails: serviceWiseAppointmentDetails,
      captchaResponseKey,
      selfDeclaration,
      isAgree,
      eligibilityResponse,
      appLanguageCode,
      organizationObj,
      appSettings,
      states,
      counties,
      registrationCode,
      patientRecordInfo,
      eligibilityQuestions,
      screeningQuestions,
      phicureResponse,
      insuranceCoverageList: allInsuranceCoverageList,
      localizedWebContents,
      security: { token },
    } = await detailsPromise;

    // Update state and county id with names
    const patientInfoRequest = { ...patientInfo[currentPatientIndex] };
    patientInfoRequest.state =
      states.find((state) => state.value === patientInfoRequest.state)?.label ||
      '';
    patientInfoRequest.county =
      counties.find((county) => county.value === patientInfoRequest.county)
        ?.label || '';
    const insuranceCoverageList = allInsuranceCoverageList[currentPatientIndex];

    const patientRecordInfoRequest = {
      ...patientRecordInfo[currentPatientIndex],
    };

    const selectedLanguageId = getLanguageIdByLanguageCode(
      appLanguageCode,
      appSettings.languages,
    );

    const { isGlobalSingleUseCodeEnabled } = appSettings;
    const isOrgSingleUseCodeEnabled =
      organizationObj && organizationObj.isSingleUseCodeEnabled;
    const requiresRegistration =
      isGlobalSingleUseCodeEnabled || isOrgSingleUseCodeEnabled;

    const requestAppointments = appointments.map(
      ({ firstAppointment, secondAppointment }, idx) => {
        const firstAppointmentRequest = firstAppointment
          ? {
              ...firstAppointment,
              ...serviceWiseAppointmentDetails[idx],
            }
          : null;

        const secondAppointmentRequest = secondAppointment
          ? {
              ...secondAppointment,
              ...serviceWiseAppointmentDetails[idx],
            }
          : null;

        return {
          first: firstAppointmentRequest,
          second: secondAppointmentRequest,
        };
      },
    );

    const requestEligibilityResponses = eligibilityQuestions.map((questions) =>
      questions.map((question) => ({
        id: question.id,
        value: eligibilityResponse[currentPatientIndex][question.id],
      })),
    );

    const requestScreeningAnswers = screeningQuestions.map((questions) =>
      questions.map((question) => ({
        id: question.id,
        value: patientHealthAnswers[currentPatientIndex][question.id]?.value,
        additionalInfo:
          patientHealthAnswers[currentPatientIndex][question.id]
            ?.additionalInfo,
      })),
    );

    const requestPhicureResponse = phicureResponse[currentPatientIndex];
    const requestSelfDeclaration = selfDeclaration[currentPatientIndex];

    const appointmentDetails = await confirmGroupAppointment({
      appointments: requestAppointments,
      patientInfo: patientInfoRequest,
      eligibilityResponse: requestEligibilityResponses,
      screeningAnswers: requestScreeningAnswers,
      organizationId,
      captchaResponseKey,
      selfDeclaration: { ...requestSelfDeclaration, isAgree },
      selections: selectedServices,
      clinic: selectedClinic,
      selectedLanguageId,
      requiresRegistration,
      registrationCode,
      patientRecordInfo: patientRecordInfoRequest,
      phicureResponse: requestPhicureResponse,
      insuranceCoverageList,
      localizedWebContents,
      primaryServiceCheckOnly: isCounsellingSelected,
      accessToken: token,
      appointmentId,
      noBlockDetails,
    });

    const {
      appointments: responseAppointments,
      patientInfo: patientInfoResponse,
    } = appointmentDetails;

    const appointmentIdsForCardUpload = responseAppointments
      .map(({ id: activityId }, idx) =>
        serviceWiseAppointmentDetails[idx].paymentType ===
        PaymentOptions.Insurance
          ? activityId
          : null,
      )
      .filter((i) => i);

    const { insuranceCardFront, insuranceCardBack } =
      serviceWiseAppointmentDetails[0] ?? {};

    // TODO: await
    appointmentIdsForCardUpload.forEach((activityId) => {
      uploadFile(
        activityId,
        InsuranceCardLogicalName.InsuranceCardFront,
        insuranceCardFront,
        appointmentDetails?.token,
      );
      uploadFile(
        activityId,
        InsuranceCardLogicalName.InsuranceCardBack,
        insuranceCardBack,
        appointmentDetails?.token,
      );
    });

    const newStateAppointments = appointments.map(
      ({ firstAppointment, secondAppointment }, idx) => {
        const appointment = responseAppointments[idx];
        return {
          firstAppointment: firstAppointment
            ? {
                ...firstAppointment,
                activityid: appointment.id,
                appointmentUUID: appointment.scheduleAppointmentId,
                canBeRescheduled: true,
              }
            : null,
          secondAppointment: secondAppointment
            ? {
                ...secondAppointment,
                activityid: appointment.id,
                appointmentUUID: appointment.scheduleAppointmentId,
                canBeRescheduled: true,
              }
            : null,
        };
      },
    );

    setDetails({
      appointments: newStateAppointments,
      patientInfo: replaceArrayElementByIndex(
        patientInfo,
        currentPatientIndex,
        {
          ...patientInfo[currentPatientIndex],
          confirmationCode: patientInfoResponse.confirmationCode,
        },
      ),
    });
  };

  return { submitAppointment };
};

/**
 * Normal flow to create group appointment.
 * @returns {{submitAppointment: (function(): Promise<void>)}}
 */
export const useBatchAppointmentSubmission = () => {
  const { setDetails } = useDetailContext();

  const { selectedServices, selectedClinic, isCounsellingSelected } =
    useSelectionsContext();

  const submitAppointment = async () => {
    const detailsPromise = new Promise((resolve) => {
      setDetails((details) => {
        resolve(details);
        return {};
      });
    });

    const {
      appointments,
      patientInfo,
      patientHealthAnswers,
      organizationId,
      commonAppointmentDetails,
      appointmentDetails: serviceWiseAppointmentDetails,
      captchaResponseKey,
      selfDeclaration,
      isAgree,
      eligibilityResponse,
      appLanguageCode,
      organizationObj,
      appSettings,
      states,
      counties,
      registrationCode,
      patientRecordInfo: allPatientRecordInfo,
      eligibilityQuestions,
      screeningQuestions,
      phicureResponse,
      insuranceCoverageList: allInsuranceCoverageList,
      localizedWebContents,
      groupPendingPatientInfo,
      security: { token },
      pendingPatientRecord = [],
    } = await detailsPromise;

    // Update state and county id with names
    let nonPendingAppointmentDetails = patientInfo.map(
      (_, currentPatientIndex) => {
        const patientInfoRequest = patientInfo[currentPatientIndex];
        const insuranceCoverageList =
          allInsuranceCoverageList[currentPatientIndex] ?? [];
        patientInfoRequest.state =
          states.find((state) => state.value === patientInfoRequest.state)
            ?.label || '';
        patientInfoRequest.county =
          counties.find((county) => county.value === patientInfoRequest.county)
            ?.label || '';
        const patientRecordInfo = allPatientRecordInfo[currentPatientIndex];
        const selectedLanguageId = getLanguageIdByLanguageCode(
          appLanguageCode,
          appSettings.languages,
        );

        const { isGlobalSingleUseCodeEnabled } = appSettings;
        const isOrgSingleUseCodeEnabled =
          organizationObj && organizationObj.isSingleUseCodeEnabled;
        const requiresRegistration =
          isGlobalSingleUseCodeEnabled || isOrgSingleUseCodeEnabled;
        const appointmentDetailForPatient =
          commonAppointmentDetails[currentPatientIndex];

        const requestAppointments = appointments.map(
          ({ firstAppointment, secondAppointment }, idx) => {
            const firstAppointmentRequest = firstAppointment
              ? {
                  ...firstAppointment,
                  ...appointmentDetailForPatient,
                }
              : null;

            const secondAppointmentRequest = secondAppointment
              ? {
                  ...secondAppointment,
                  ...appointmentDetailForPatient,
                }
              : null;

            return {
              first: firstAppointmentRequest,
              second: secondAppointmentRequest,
            };
          },
        );

        const requestEligibilityResponses = eligibilityQuestions.map(
          (questions) =>
            questions.map((question) => ({
              id: question.id,
              value: eligibilityResponse[currentPatientIndex][question.id],
            })),
        );

        const requestScreeningAnswers = screeningQuestions.map((questions) =>
          questions.map((question) => ({
            id: question.id,
            value:
              patientHealthAnswers[currentPatientIndex][question.id]?.value,
            additionalInfo:
              patientHealthAnswers[currentPatientIndex][question.id]
                ?.additionalInfo,
          })),
        );

        const requestPhicureResponse = phicureResponse[currentPatientIndex];
        const requestSelfDeclaration = selfDeclaration[currentPatientIndex];

        const appointmentDetails = getDataRequests({
          appointments: requestAppointments,
          patientInfo: patientInfoRequest,
          eligibilityResponse: requestEligibilityResponses,
          screeningAnswers: requestScreeningAnswers,
          organizationId,
          captchaResponseKey,
          selfDeclaration: { ...requestSelfDeclaration, isAgree },
          selections: selectedServices,
          clinic: selectedClinic,
          selectedLanguageId,
          requiresRegistration,
          registrationCode,
          patientRecordInfo,
          phicureResponse: requestPhicureResponse,
          insuranceCoverageList,
          localizedWebContents,
          primaryServiceCheckOnly: isCounsellingSelected,
          sendEmail: false, // request payload for users that fill their information
        });
        return appointmentDetails;
      },
    );

    const firstPatientDetail = nonPendingAppointmentDetails[0];
    const blockDetail = firstPatientDetail.blockDetail; //block detail is the same for all patient

    nonPendingAppointmentDetails = nonPendingAppointmentDetails.map(
      (eachAppointmentPatientDetail) =>
        omit(eachAppointmentPatientDetail, [
          'blockDetail',
          'captchaResponseKey',
        ]),
    );

    const pendingAppointmentDetails = groupPendingPatientInfo.map(
      (eachPendingPatient, eachPendingPatientIndex) => {
        const firstAppointmentDetails = firstPatientDetail.appointments[0];

        // NOTE: may have to iterate over each appointment for multiservice
        const appointments = [
          {
            smvs_appointmentdatetime:
              firstAppointmentDetails.smvs_appointmentdatetime, // common data
            smvs_associatelclinicid:
              firstAppointmentDetails.smvs_associatelclinicid,
            smvs_appointment_dose_number:
              firstAppointmentDetails.smvs_appointment_dose_number,
            smvs_associatedserviceid:
              firstAppointmentDetails.smvs_associatedserviceid,
            smvs_associated_language_code:
              firstAppointmentDetails.smvs_associated_language_code,
          },
        ];

        const recordInfo = {
          isExistingPatient:
            pendingPatientRecord[eachPendingPatientIndex]?.isExistingPatient ||
            false,
          patientId:
            pendingPatientRecord[eachPendingPatientIndex]?.patientId || null,
        };

        const birthDate = getBirthDateString(
          eachPendingPatient.birthDay,
          eachPendingPatient.birthMonth,
          eachPendingPatient.birthYear,
        );

        const patientInfo = {
          lastname: eachPendingPatient.lastName,
          firstname: eachPendingPatient.firstName,
          middlename: eachPendingPatient.middleName || '',
          birthdate: birthDate,
          emailaddress1: eachPendingPatient.email,
          mobilephone: eachPendingPatient?.mobileNo?.replaceAll('-', ''),
        };

        return {
          appointments,
          patientInfo,
          recordInfo,
          sendEmail: true,
        };
      },
    );

    const allRequestPayload = {
      appointmentPatientInfo: nonPendingAppointmentDetails.concat(
        pendingAppointmentDetails,
      ),
      blockDetail,
      captchaResponseKey,
    };

    const allResponsePayload = await createBatchAppointments(allRequestPayload);

    const {
      appointments: responseAppointments,
      patientInfo: patientInfoResponse,
    } = allResponsePayload;

    // TODO - await
    const insuranceUploads = patientInfo.map(
      (eachPatientInfo, currentPatientIndex) => {
        const foundPatient = patientInfoResponse.find((patientResponseData) => {
          return (
            patientResponseData?.firstname?.toLowerCase() ===
              eachPatientInfo?.firstName?.toLowerCase() &&
            (patientResponseData?.middlename?.toLowerCase() || '') ===
              (eachPatientInfo?.middleName?.toLowerCase() || '') &&
            patientResponseData?.lastname?.toLowerCase() ===
              eachPatientInfo?.lastName?.toLowerCase()
          );
        });

        const foundAppointment = responseAppointments.find(
          (eachResponseAppointment) =>
            eachResponseAppointment.patientId === foundPatient?.patientId,
        );

        if (!foundAppointment) {
          return [];
        }

        // NOTE - for multiservice, will have to use appointmentDetails, instead of common appointment details instead
        // see useAppointmentSubmission.js
        const { insuranceCardFront, insuranceCardBack } =
          commonAppointmentDetails[currentPatientIndex] ?? {};

        let frontInsuranceRequest;
        if (insuranceCardFront) {
          frontInsuranceRequest = uploadFile(
            foundAppointment.id,
            InsuranceCardLogicalName.InsuranceCardFront,
            insuranceCardFront,
            allResponsePayload?.token,
          );
        }

        let backInsuranceRequest;
        if (insuranceCardBack) {
          backInsuranceRequest = uploadFile(
            foundAppointment.id,
            InsuranceCardLogicalName.InsuranceCardBack,
            insuranceCardBack,
            allResponsePayload?.token,
          );
        }

        return [frontInsuranceRequest, backInsuranceRequest];
      },
    );

    const newStateAppointments = appointments.map(
      ({ firstAppointment, secondAppointment }, idx) => {
        const appointment = responseAppointments[idx];
        return {
          firstAppointment: firstAppointment
            ? {
                ...firstAppointment,
                groupAppointmentId: appointment.groupAppointmentId,
                appointmentUUID: appointment.scheduleAppointmentId,
                canBeRescheduled: true,
              }
            : null,
          secondAppointment: secondAppointment
            ? {
                ...secondAppointment,
                groupAppointmentId: appointment.groupAppointmentId,
                appointmentUUID: appointment.scheduleAppointmentId,
                canBeRescheduled: true,
              }
            : null,
        };
      },
    );

    if (insuranceUploads && insuranceUploads.length > 0) {
      await Promise.all(insuranceUploads.flat()); // prevent user to see success page before all cards have been uploaded
    }

    const updatedPatientInfo = [...patientInfo].map((patient, i) => {
      return {
        ...patient,
        confirmationCode: patientInfoResponse.find(
          (p) =>
            p?.firstname?.toLowerCase() === patient?.firstName?.toLowerCase() &&
            p?.lastname?.toLowerCase() === patient?.lastName.toLowerCase() &&
            (p?.middlename?.toLowerCase() || '') ===
              (patient?.middleName?.toLowerCase() || ''),
        )?.confirmationCode,
      };
    });

    setDetails({
      appointments: newStateAppointments,
      patientInfo: updatedPatientInfo,
    });
  };

  return { submitAppointment };
};
