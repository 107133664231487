import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'components/common/Button';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useDetailContext } from 'context';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { ServiceAccordion } from '../presentation/ServiceAccordion';
import {
  composeFilters,
  excludeDoubleDose,
  filterByDefaultStatus,
  filterSubservicesByAge,
  passThrough,
} from 'util/serviceFilter';
import { getString } from 'util/lang';
import { SubServiceSelector } from '../presentation/SubServiceSelector';
import { ServiceGroupMap } from 'constants/clinicAndTimingActions';
import APP_LANGUAGES from 'constants/appLanguages';

const VACCINE_SERVICE_GROUPS = [
  ServiceGroupMap.covidVaccine,
  ServiceGroupMap.travelVaccine,
  ServiceGroupMap.commonDiseaseVaccine,
];

/**
 * @typedef {import('types').Service} Service
 * @typedef {import('types').SubService} SubService
 */

const useSubServiceSelectionContext = () => {
  const {
    details: {
      selectedServices,
      services,
      subServices,
      serviceTypes,
      patientInfo: allPatientInfo,
      currentPatientIndex,
    },
    setDetails,
  } = useDetailContext();

  const patientInfo = allPatientInfo[currentPatientIndex];

  const selectedServicesWithSubServices = useMemo(() => {
    const filterEligibleSubServices = composeFilters(
      selectedServices.length > 1 ? excludeDoubleDose : passThrough,
      filterSubservicesByAge(patientInfo),
      filterByDefaultStatus,
    );
    const filterSelectableSubServices = composeFilters(
      selectedServices.length > 1 ? excludeDoubleDose : passThrough,
      filterSubservicesByAge(patientInfo),
    );

    return selectedServices.map(({ serviceId }) => {
      const service = services.byId[serviceId];
      const allSubServices = service.subServices
        .map((subServiceId) => subServices.byId[subServiceId])
        .filter((i) => i);

      // subServices that can be selected
      const validSubServices = filterSelectableSubServices(allSubServices);
      // selectable sub services, without 'default' options ('I am not sure')
      const eligibleSubServices = filterEligibleSubServices(allSubServices);
      const isEligible = eligibleSubServices.length > 0;
      const diplayingSubService =
        eligibleSubServices.length === 1 ? eligibleSubServices : allSubServices;

      return {
        ...service,
        serviceType: serviceTypes.byId[service.serviceTypeId],
        // all subservices that can be displayed, selected, including 'I am not sure'
        subServices: validSubServices,
        // sub subserviecs that are valid, does not include 'I am not sure', and can abe auto-selected
        eligibleSubServices,
        isEligible,
        diplayingSubService,
      };
    });
  }, [
    selectedServices,
    patientInfo,
    services.byId,
    subServices.byId,
    serviceTypes.byId,
  ]);

  /**
   * @param {{serviceTypeId, serviceId, subServiceId}[]} selectedServices
   */
  const setSelectedServices = useCallback(
    (selectedServices) => {
      setDetails({ selectedServices });
    },
    [setDetails],
  );

  return {
    selectedServicesWithSubServices,
    selectedServices,
    setSelectedServices,
  };
};

export const SubServiceSelection = () => {
  const {
    selectedServicesWithSubServices,
    selectedServices,
    setSelectedServices,
  } = useSubServiceSelectionContext();
  const {
    details: { appLanguageCode },
  } = useDetailContext();

  const nav = useNavigationHelper();

  const [serviceSelections, setServiceSelections] = useState(() =>
    selectedServices.map(({ serviceTypeId, serviceId, subServiceId }, idx) => {
      const service = selectedServicesWithSubServices[idx];

      const selectedSubServiceId =
        service.eligibleSubServices.length === 1
          ? service.eligibleSubServices[0].id // auto select first subservice if only 1
          : subServiceId;

      const isSelectedSubServiceValid =
        selectedSubServiceId &&
        service.subServices.some(
          (subService) => subService.id === selectedSubServiceId,
        );

      return {
        serviceTypeId,
        serviceId,
        subServiceId: isSelectedSubServiceValid ? selectedSubServiceId : null,
      };
    }),
  );

  const services = useMemo(() => {
    const services = serviceSelections.map(({ subServiceId }, idx) => {
      return {
        ...selectedServicesWithSubServices[idx],
        selectedSubServiceId: subServiceId,
      };
    });

    return services;
  }, [selectedServicesWithSubServices, serviceSelections]);

  const allSubServicesSelected = services.every(
    (service) => !service.isEligible || service.selectedSubServiceId != null,
  );

  const someServicesIneligible = services.some(
    (service) => !service.isEligible,
  );

  const isInitialized = useRef(false);
  useEffect(() => {
    if (isInitialized.current) {
      return;
    }

    // only run this once, so we dont auto navigate if the conditions match due to user changes
    isInitialized.current = true;

    const allServicesWithOneEligible = services.every(
      (service) =>
        service.isEligible && service.eligibleSubServices.length === 1,
    );

    if (allServicesWithOneEligible) {
      // auto-select single selectable item item
      const updatedSelectedServices = selectedServices.map(
        (selection, idx) => ({
          ...selection,
          subServiceId: serviceSelections[idx].subServiceId,
        }),
      );
      setSelectedServices(updatedSelectedServices);

      // go to next page
      nav.toSelectLocation({ replace: true });
      return;
    }

    const allServicesIneligible = services.every(
      (service) => !service.isEligible,
    );

    if (allServicesIneligible) {
      nav.toNotEligible({ replace: true });
      return;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // NOTE: this useEffect should only be run ONCE at the beginning, so disable exhaustive deps

  const allowContinue = !someServicesIneligible && allSubServicesSelected;
  const allowContinueWithIneligible =
    someServicesIneligible && allSubServicesSelected;
  const allowEditService = someServicesIneligible;

  const onRemoveService = useCallback(
    (idx) => {
      setServiceSelections([
        ...serviceSelections.slice(0, idx),
        ...serviceSelections.slice(idx + 1),
      ]);
    },
    [serviceSelections],
  );

  const onSelectSubService = useCallback(
    (idx, subServiceId) => {
      setServiceSelections([
        ...serviceSelections.slice(0, idx),
        { ...serviceSelections[idx], subServiceId },
        ...serviceSelections.slice(idx + 1),
      ]);
    },
    [serviceSelections],
  );

  const onClickSearchClinics = useCallback(() => {
    setSelectedServices(serviceSelections);

    nav.toSelectLocation();
  }, [serviceSelections, setSelectedServices, nav]);

  const onContinueWithIneligible = useCallback(() => {
    const eligibleServiceIds = new Set(
      services
        .filter((service) => service.isEligible)
        .map((service) => service.id),
    );

    const updatedSelectedServices = serviceSelections.filter(({ serviceId }) =>
      eligibleServiceIds.has(serviceId),
    );

    setSelectedServices(updatedSelectedServices);

    nav.toSelectLocation();
  }, [serviceSelections, setSelectedServices, nav, services]);

  const onChangeService = nav.toEditService;

  const singleServiceName = useMemo(() => {
    if (!services.length) {
      return '';
    }
    const serviceName = services[0].name;
    const serviceGroup = services[0].serviceType.serviceGroup;
    if (
      appLanguageCode === APP_LANGUAGES.english &&
      VACCINE_SERVICE_GROUPS.includes(serviceGroup)
    ) {
      return serviceName.toLowerCase().includes('vaccine')
        ? serviceName
        : `${serviceName} Vaccine`;
    }
    return serviceName;
  }, [services, appLanguageCode]);

  if (!selectedServices.length) {
    nav.resetToFirstPage();
    return null;
  }

  return (
    <PageWrapper>
      <BackToPage text="Back to Previous Page" onClick={nav.goBack} />

      <TitleText>
        {services.length === 1
          ? getString('whichServiceWouldYouLikeToBook', singleServiceName)
          : `${services.length} ${getString('servicesInThisAppointmentPlural')}`}
      </TitleText>
      <SubTitleText>{getString('subserviceSelectionNotice')}</SubTitleText>

      {services.length === 1 ? (
        <SubServiceSelector
          service={services[0]}
          onSelectSubService={(subServiceId) =>
            onSelectSubService(0, subServiceId)
          }
        />
      ) : (
        <ServiceAccordion
          services={services}
          onRemoveService={onRemoveService}
          onSelectSubService={onSelectSubService}
        />
      )}

      {someServicesIneligible ? (
        <>
          <Button
            classNames={{ button: 'mb-4x' }}
            onClick={onContinueWithIneligible}
            isEnabled={allowContinueWithIneligible}
            label={getString('continue')}
          />
          <Button
            onClick={onChangeService}
            isEnabled={allowEditService}
            outline
            label={getString('changeService')}
          />
        </>
      ) : (
        <Button
          onClick={onClickSearchClinics}
          isEnabled={allowContinue}
          label={getString('searchClinics')}
        />
      )}
    </PageWrapper>
  );
};

const TitleText = ({ children }) => (
  <div className="title--normal">{children}</div>
);

const SubTitleText = ({ children }) => <div className="mb-5x">{children}</div>;
