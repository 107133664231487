import React from 'react';
import NavBar from './navbar';

import { DetailContext } from '../../../../context/index';

const NavBarContainer = (props) => {
  return (
    <DetailContext.Consumer>
      {({ pageComplete, getAppSettings, details: { loading } }) => {
        const appSettings = getAppSettings();
        const logo = appSettings && appSettings.logoUrl;
        return (
          <NavBar
            pageComplete={pageComplete}
            logo={logo}
            loading={loading}
            {...props}
          />
        );
      }}
    </DetailContext.Consumer>
  );
};

export default NavBarContainer;
