import React from 'react';
import CONFIG from '../../../../../config';
import Button from '../../../../common/Button';
import ReCaptcha from '../../../common/ReCaptcha';
import Cleave from 'cleave.js/react';
import { getString } from '../../../../../util/lang';

const CodeRegistration = (props) => {
  const {
    registrationCode,
    handleRegCodeChange,
    handleRegCodeSubmit,
    registrationCodeErrors,
    isLoading,
    isCaptchaValid,
    handleCaptchaChange,
  } = props;
  const hasErrors = registrationCodeErrors['registrationCode'];

  return (
    <section className="section__margin">
      <div className="section__container border-radius border">
        <p className="text-center">{getString('codeRegistrationHeader')}</p>
        <div className="single-use__code">
          <Cleave
            value={registrationCode}
            onChange={(e) => handleRegCodeChange(e.target.value)}
            options={{ delimiters: ['-'], blocks: [4, 4] }}
            className={`single-use__input ${hasErrors && 'single-use__input--error'}`}
            placeholder="xxxx-xxxx"
          />
          {hasErrors && (
            <p className="single-use__error">
              {registrationCodeErrors['registrationCode']}
            </p>
          )}
          {CONFIG.captchaEnabled && (
            <ReCaptcha
              isLoading={false}
              onCaptchaChange={handleCaptchaChange}
              isCaptchaValid={isCaptchaValid}
            />
          )}
          <Button
            label={getString('getStarted')}
            onClick={(e) => handleRegCodeSubmit(e.target.value)}
            isEnabled={!!registrationCode}
            isLoading={isLoading}
            title={getString('getStarted')}
          />
        </div>
      </div>
    </section>
  );
};

export default CodeRegistration;
