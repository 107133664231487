import React, { useCallback, useMemo, useState } from 'react';

import Modal from 'components/COVID/common/Modal';
import Button from 'components/common/Button';
import ReCaptcha from 'components/COVID/common/ReCaptcha';

/**
 *
 * @param {{
 *  onCancel: () => void
 *  onConfirm: (captcha?: string) => void
 *  show?: boolean
 *  captchaEnabled?: boolean
 *  headerContent: string
 *  bodyContent: string
 *  cancelText: string
 *  confirmText: string
 * }} props
 * @returns
 */
export const ConfirmModal = (props) => {
  const {
    show = false,
    onCancel,
    onConfirm,
    captchaEnabled = false,
    headerContent,
    bodyContent,
    cancelText,
    confirmText,
  } = props;
  const [captchaResponseKey, setCaptchaResponseKey] = useState('');
  const [isCaptchaAttempted, setIsCaptchaAttempted] = useState(false);

  const handleConfirmButtonClick = useCallback(() => {
    if (captchaEnabled && !captchaResponseKey) {
      setIsCaptchaAttempted(true);
      return;
    }
    onConfirm(captchaResponseKey);
  }, [captchaResponseKey, captchaEnabled, onConfirm]);

  if (!show) {
    return null;
  }

  return (
    <Modal
      close={onCancel}
      containerClassName="confirmation-modal"
      headerContent={headerContent}
    >
      <div className="modal__body">{bodyContent}</div>
      {captchaEnabled && (
        <ReCaptcha
          onCaptchaChange={setCaptchaResponseKey}
          isCaptchaValid={isCaptchaAttempted ? !!captchaResponseKey : true}
        />
      )}
      <div className="modal__footer text-right border-top d-flex justify-content-between flex-wrap">
        <Button
          label={cancelText}
          className="btn btn--large btn-primary--outline"
          isEnabled
          onClick={onCancel}
          dataqa="close-cancel-modal"
        />
        <Button
          label={confirmText}
          className="btn btn--large btn-primary"
          isEnabled
          onClick={handleConfirmButtonClick}
          dataqa="ok-cancel-modal"
        />
      </div>
    </Modal>
  );
};

/**
 * @typedef {'captchaEnabled' | 'headerContent' | 'bodyContent' | 'cancelText' | 'confirmText'} ConfigFields
 * @typedef {Partial<Pick<React.ComponentProps<ConfirmModal>, ConfigFields>>} ConfirmConfig
 * @typedef {{ confirm: boolean, captchaResponseKey?: string }} ConfirmResponse
 */

export const useConfirmModal = () => {
  const [show, setShow] = useState(false);
  const [onCancel, setOnCancel] = useState(() => {});
  const [onSuccess, setOnSucess] = useState(() => {});
  const [config, setConfig] = useState({});

  /**
   * @type {(config: ConfirmConfig) => Promise<ConfirmResponse>}
   */
  const confirm = useCallback(async (config) => {
    return new Promise((resolve) => {
      setOnCancel(() => () => {
        setShow(false);
        resolve({ confirm: false });
      });
      setOnSucess(() => (captchaResponseKey) => {
        setShow(false);
        resolve({ confirm: true, captchaResponseKey });
      });

      setConfig(config ?? {});
      setShow(true);
    });
  }, []);

  const component = useMemo(() => {
    /** @param {Partial<React.ComponentProps<ConfirmModal>>} props */
    return (props) => (
      <ConfirmModal
        show={show}
        onCancel={onCancel}
        onConfirm={onSuccess}
        headerContent={config.headerContent || props.headerContent}
        bodyContent={config.bodyContent || props.bodyContent}
        cancelText={config.cancelText || props.cancelText}
        confirmText={config.confirmText || props.confirmText}
        captchaEnabled={config.captchaEnabled || props.captchaEnabled}
      />
    );
  }, [show, onCancel, onSuccess, config]);

  return {
    ConfirmModal: component,
    confirm,
  };
};
