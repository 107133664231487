import { getString } from "util/lang"
import FormInput from "components/common/FormInput"

export const ABNFormHeader = ({ abnData, notifier = "Parlin Pharmacy, 4517 Washington Ave. Manchester, Kentucky 39495, (307) 555-0133", patientFirstname = "Lara", patientMiddleName, patientLastName = "West", identificationNumber = "123456789" }) => {
    return (
        <div className=" border-bottom pb-6x">
            <h3 className='heading'>{getString('dme.documentation.abnForm.title')}</h3>
            <div className="patient-form-group border-roundedx">
                <div className="row">
                    <div className="col-12">
                        <FormInput
                            labelClassName="label-placeholder"
                            label={getString('dme.documentation.abnForm.fields.notifierLabel')}
                            value={notifier}
                            disabled
                        />
                    </div>
                    <div className="col-4">
                        <FormInput
                            labelClassName="label-placeholder"
                            label={getString('dme.documentation.abnForm.fields.patientFirstNameLabel')}
                            value={patientFirstname}
                            required={true}
                            disabled
                        />
                    </div>
                    <div className="col-4">
                        <FormInput
                            labelClassName="label-placeholder"
                            label={getString('dme.documentation.abnForm.fields.patientMiddleNameLabel')}
                            value={patientMiddleName}
                            disabled
                        />
                    </div>
                    <div className="col-4">
                        <FormInput
                            labelClassName="label-placeholder"
                            label={getString('dme.documentation.abnForm.fields.patientLastNameLabel')}
                            value={patientLastName}
                            disabled
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            labelClassName="label-placeholder"
                            label={getString('dme.documentation.abnForm.fields.identificationNumberLabel')}
                            value={identificationNumber}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}