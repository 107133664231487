import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiChevronDown } from 'vyaguta-icons/fi';
import cls from 'classnames';
import { uniqueId } from 'lodash';

import styles from './ServiceAccordion.module.scss';
import { SubServiceRadioInput } from './SubserviceRadioInput';
import { getString } from 'util/lang';

const { default: Collapsible } = require('react-collapsible');

/**
 * @typedef {{
 *  id: string
 *  name: string
 *  notice: string
 * }} SubService
 *
 * @typedef {{
 *  id: string
 *  name: string
 *  notice: string
 *  isEligible: boolean
 *  selectedSubServiceId?: string
 *  subServices: SubService[]
 * }} ServiceWithSubServices
 *
 * @typedef {{
 *  services: ServiceWithSubServices[]
 *  onSelectSubService: (idx: number, subServiceId: string) => void
 *  onRemoveService: (idx: number) => void
 * }} Props
 *
 * @param {Props} props
 */
export const ServiceAccordion = (props) => {
  const { services, onRemoveService, onSelectSubService } = props;

  const [isOpen, setIsOpen] = useState({});
  const [componentId] = useState(() => uniqueId());

  const openAccordion = useCallback(
    (id) => {
      setIsOpen({ ...isOpen, [id]: true });
    },
    [isOpen],
  );
  const closeAccordion = useCallback(
    (id) => {
      setIsOpen({ ...isOpen, [id]: false });
    },
    [isOpen],
  );

  // reset open status if services gets changed
  useEffect(() => {
    setIsOpen((isOpen) => ({
      ...Object.fromEntries(services.map((_, idx) => [idx, true])),
      ...isOpen,
    }));
  }, [services]);

  const primaryServiceEligible = services[0].isEligible;

  return (
    <div>
      {services.map((service, idx) => {
        const openable = service.subServices.length > 1 && service.isEligible;
        const open =
          (service.subServices.length > 1 && isOpen[idx]) ||
          !service.isEligible;
        const triggerDisabled = !openable || !service.isEligible;

        return (
          <Collapsible
            key={idx}
            trigger={
              <ServiceAccordionHeader
                service={service}
                open={open}
                openable={openable}
                removable={idx > 0 && primaryServiceEligible}
                onClickRemove={() => onRemoveService(idx)}
              />
            }
            open={open}
            onTriggerClosing={() => closeAccordion(idx)}
            onTriggerOpening={() => openAccordion(idx)}
            triggerDisabled={triggerDisabled}
          >
            {service.isEligible ? (
              <div className={styles.subServiceList}>
                {service.diplayingSubService.map(
                  (subService, subServiceIdx) => (
                    <SubServiceRadioInput
                      key={subService.id}
                      dataqa={`service-${idx}-${subServiceIdx}`}
                      name={`${componentId}-${idx}`}
                      value={`${componentId}-${idx}-${subServiceIdx}`}
                      title={subService.name}
                      notice={subService.notice}
                      onChange={() => {
                        onSelectSubService(idx, subService.id);
                      }}
                      selected={service.selectedSubServiceId === subService.id}
                    />
                  ),
                )}
              </div>
            ) : (
              <NotQualifiedMessage service={service} />
            )}
          </Collapsible>
        );
      })}
    </div>
  );
};

/**
 * @param {{
 *  service: ServiceWithSubServices
 *  open: boolean
 *  openable: boolean
 *  removable: boolean
 *  onClickRemove: (serviceId: string) => void
 * }} props
 */
const ServiceAccordionHeader = (props) => {
  const { service, open, openable, removable, onClickRemove } = props;

  const { name } = service;

  const _onClickRemove = useCallback(
    (e) => {
      e.stopPropagation();
      onClickRemove();
    },
    [onClickRemove],
  );

  const subService = useMemo(() => {
    const selectedSubService = service.subServices.find(
      (subService) => subService.id === service.selectedSubServiceId,
    );

    return service.subServices.length > 1 ? selectedSubService : undefined;
  }, [service.subServices, service.selectedSubServiceId]);

  const largeHeader = !open && service.subServices.length !== 1;

  return (
    <div
      className={cls(
        styles.ServiceAccordionHeader,
        open && styles.open,
        largeHeader && styles.large,
      )}
    >
      <div className={styles.text}>
        <div className={styles.serviceText}>{name}</div>
        {!open && subService && (
          <div className={styles.subServiceText}>{subService.name}</div>
        )}
      </div>
      {removable && (
        <button tabIndex={0} onClick={_onClickRemove} className={styles.remove}>
          {getString('remove')}
        </button>
      )}
      {openable && <FiChevronDown className={styles.arrow} />}
    </div>
  );
};

/**
 *
 * @param {{
 *  service: ServiceWithSubServices
 * }} props
 * @returns
 */
const NotQualifiedMessage = (props) => (
  <div className={styles.notQualified}>
    {getString('notQualifiedForService', props.service.name)}
  </div>
);
