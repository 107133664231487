import axios from 'axios';

const _checkDebugEnabled = () => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get('_MVS_DEBUG') === 'true') {
    window._MVS_DEBUG = true;
  }
};
_checkDebugEnabled();

const withDebugHeaders = (params = {}) => {
  if (window._MVS_DEBUG === true) {
    return {
      ...params,
      headers: {
        ...params.headers,
        'mvs-debug': 'true',
      },
    };
  }
  return params;
};

/**
 * Send a get request to fetch data.
 *
 * @type {axios['get']}
 */
export function get(url, params = {}) {
  return axios.get(url, withDebugHeaders(params));
}

/**
 * Send a post request.
 *
 * @param {string} url : Url to send.
 * @param {object} data
 */
export function post(url, data, customHeaders = {}) {
  return axios.post(
    url,
    data,
    withDebugHeaders({
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
    }),
  );
}

export function patch(url, data, customHeaders) {
  return axios.patch(
    url,
    data,
    withDebugHeaders({
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
    }),
  );
}

/**
 *  @param {string} url : Url to send request
 *  @param {object} data
 */
export function remove(url, data, customHeaders) {
  return axios.delete(
    url,
    withDebugHeaders({
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
      data: data,
    }),
  );
}
