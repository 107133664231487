import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { getString } from 'util/lang';
import { useDetailContext } from 'context';

import styles from './ConfirmFollowUp.module.scss';
import ActivityIndicator from 'components/common/ActivityIndicator';
import FollowupConfirmationContent from '../presentation/FollowupConfirmationContent';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useFollowupStatusCheck } from 'context/useFollowupStatusCheck';
import { useAppParams } from 'context/useAppParams';
import { changeTitle } from 'util/siteInfo';

const ConfirmFollowUp = () => {
  changeTitle('Follow Up | Confirm');
  const { appointmentId, organizationId } = useAppParams();
  const { secretKey } = useParams();

  const {
    details: { loading },
    confirmFollowupDataSetup,
  } = useDetailContext();

  const followupStatus = useFollowupStatusCheck(appointmentId);

  useEffect(() => {
    if (followupStatus.loaded && followupStatus.ok) {
      confirmFollowupDataSetup(appointmentId, secretKey, organizationId);
    }
  }, [
    appointmentId,
    secretKey,
    confirmFollowupDataSetup,
    followupStatus.loaded,
    followupStatus.ok,
    organizationId,
  ]);

  return (
    <PageWrapper classNames={{ mainWrapper: styles.bgGrey }}>
      {loading || !followupStatus.loaded ? (
        <ActivityIndicator
          className="loader--clinic border border-radius section__container p-0x"
          message={getString('pleaseWaitLoading')}
        />
      ) : (
        <FollowupConfirmationContent />
      )}
    </PageWrapper>
  );
};

export default ConfirmFollowUp;
