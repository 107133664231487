import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';
import { deliveryMethodAttributes } from './DeliveryInfo';
import { getDeliveryMethodLabel } from '../../../adapters/orders.adapter';

const LABEL_PREFIX = 'dme.deliveryTicket';

const DeliveryInfoPdfView = ({ data }) => {
  // Render Attribute and corresponding values
  const renderAttribute = (labelKey, value) => {
    if (!value) return null;
    return (
      <Text style={styles.attributeText}>
        {getString(`${LABEL_PREFIX}.deliveryInfo.${labelKey}`)}:{' '}
        {labelKey === 'deliveryMethod' ? getDeliveryMethodLabel(value) : value}
      </Text>
    );
  };

  // Extract renderable attributes based on deliveryMethod
  const attributes = deliveryMethodAttributes[data.deliveryMethod] || [];

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {getString(`${LABEL_PREFIX}.deliveryInfo.title`)}
      </Text>
      {attributes.map((attr) => renderAttribute(attr, data[attr]))}
    </View>
  );
};

// PDF styles
const styles = StyleSheet.create({
  container: {
    padding: '8px 12px',
    backgroundColor: '#FAF9F8',
    border: '0.5px solid #E1DFDD',
    borderRadius: 4,
    marginTop: 10,
    flex: 1,
  },
  title: {
    fontSize: 10,
    fontWeight: 700,
    color: '#323130',
    marginBottom: 8,
  },
  attributeText: {
    fontSize: 10,
    color: '#3B3A39',
    marginBottom: 4,
  },
});

export default DeliveryInfoPdfView;
