const smoothScrollTo = (ref) => {
  ref?.current &&
    ref.current.scrollIntoView({
      behavior: 'smooth',
    });
};

const SCROLL_TRANSITION_TIME = 300;

/**
 *
 * @param {React.RefObject<HTMLElement> | null} ref
 * @param {number} [offsetTop]
 */
const windowScrollTo = (ref, offsetTop = 0) => {
  setTimeout(() => {
    if (!ref?.current) return;
    const element = ref.current.getBoundingClientRect().top + window.scrollY;
    window.scroll({ top: element + offsetTop, behavior: 'smooth' });
  }, SCROLL_TRANSITION_TIME);
};
// eslint-disable-next-line import/no-anonymous-default-export

/**
 * Animation to error
 */
export const animateToFormError = () => {
  setTimeout(() => {
    const errorField = document.getElementsByClassName('has-error')[0];
    if (!errorField) {
      return;
    }

    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = errorField.getBoundingClientRect();
    const offset = elemRect.top - bodyRect?.top; // Calculating the offset of the error field relative to the body

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: offset - 200, // To ensure the error field is not positioned too close to the top of the viewport for better visibility
    });
  });
};

export default { smoothScrollTo, windowScrollTo, animateToFormError };
