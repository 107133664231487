import { useDetailContext } from 'context';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useCallback } from 'react';
import * as organizationService from 'services/accounts';

export const usePreServiceDataSetup = () => {
  const { setDetails } = useDetailContext();
  const nav = useNavigationHelper();

  const preServiceDataSetup = useCallback(
    async (organizationId) => {
      try {
        const organizationObj =
          await organizationService.getById(organizationId);
        setDetails({
          organizationObj,
          isOrganizationDetailsSet: true,
        });
      } catch (error) {
        console.error(error);
        setDetails({
          organizationObj: null,
          isOrganizationDetailsSet: true,
        });
        nav.resetToFirstPage(false);
      }
    },
    [nav, setDetails],
  );

  return preServiceDataSetup;
};
