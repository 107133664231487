import React from 'react';

import FormInput from '../../../../common/FormInput';
import SelectBox from '../../../../common/SelectBox';
import FormErrors from '../../../../common/FormErrors';
import { HomeAddressAutofill } from './HomeAddressAutofill';
import {
  ShowAsRequiredFields,
  PatientAddressInfoKeys,
} from '../../../../../constants/patientInfo';
import { getString } from 'util/lang';

const PatientAddressInfo = (props) => {
  const {
    onChangeInput,
    stateOptions,
    countyOptions,
    patientInfo,
    errors = {},
    isInsuranceValidationLoading,
    onAddressSelect,
  } = props;
  const [isAllFieldsEnabled, setIsAllFieldsEnabled] = React.useState(
    !!patientInfo?.homeAddress,
  );

  const errorSet = React.useMemo(() => {
    const tempErrorSet = {};
    PatientAddressInfoKeys.forEach((key) => {
      if (errors[key]) {
        tempErrorSet[key] = errors[key];
      }
    });
    return tempErrorSet;
  }, [errors]);

  const handleAddressSelect = (selectedAddress) => {
    if (!selectedAddress) return;
    setIsAllFieldsEnabled(true);
    onAddressSelect(selectedAddress);
  };

  const handleClickAddressManual = () => {
    setIsAllFieldsEnabled(true);
  };

  React.useEffect(() => {
    if (
      !!patientInfo?.homeAddress &&
      (!!patientInfo?.zipCode || !!patientInfo?.city)
    ) {
      setIsAllFieldsEnabled(true);
    }
  }, [
    patientInfo?.homeAddress,
    patientInfo?.zipCode,
    patientInfo?.city,
    patientInfo?.state,
  ]);

  return (
    <>
      <div className="patient-form-group border-rounded">
        <div className="row">
          <div className="col-12-md fs-mask">
            <HomeAddressAutofill
              id="homeAddress"
              value={patientInfo?.homeAddress}
              onAddressSelect={handleAddressSelect}
              onChange={onChangeInput}
              hasError={!!errorSet['homeAddress']}
              required={ShowAsRequiredFields['homeAddress']}
              disableInput={isInsuranceValidationLoading}
            />
          </div>
          {isAllFieldsEnabled && (
            <>
              <div className="col-6-md fs-mask">
                <FormInput
                  label={getString('aptUnit')}
                  id="aptUnit"
                  onChange={onChangeInput}
                  value={patientInfo.aptUnit}
                  hasError={!!errorSet['aptUnit']}
                  required={ShowAsRequiredFields['aptUnit']}
                  data-qa="apt-unit"
                />
              </div>
              <div className="col-6-md">
                <FormInput
                  label={getString('city')}
                  id="city"
                  onChange={onChangeInput}
                  value={patientInfo.city}
                  hasError={!!errorSet['city']}
                  required={ShowAsRequiredFields['city']}
                  disableInput={isInsuranceValidationLoading}
                  data-qa="city"
                />
              </div>
              <div className="col-4-md">
                <SelectBox
                  id="state"
                  defaultValue=""
                  options={stateOptions}
                  onChange={(e) => onChangeInput('state', e.target.value)}
                  label={getString('state')}
                  value={patientInfo.state}
                  hasError={!!errorSet['state']}
                  required={ShowAsRequiredFields['state']}
                  disableInput={isInsuranceValidationLoading}
                  dataqa="state"
                />
              </div>
              <div className="col-4-md">
                <SelectBox
                  id="county"
                  defaultValue={''}
                  options={countyOptions}
                  onChange={(e) => onChangeInput('county', e.target.value)}
                  label={getString('county')}
                  value={patientInfo.county}
                  hasError={!!errorSet['county']}
                  required={ShowAsRequiredFields['county']}
                  disableInput={isInsuranceValidationLoading}
                  dataqa="county"
                />
              </div>
              <div className="col-4-md">
                <FormInput
                  label={getString('zip')}
                  id="zipCode"
                  inputMode="numeric"
                  onChange={onChangeInput}
                  value={patientInfo.zipCode}
                  hasError={!!errorSet['zipCode']}
                  required={ShowAsRequiredFields['zipCode']}
                  disableInput={isInsuranceValidationLoading}
                  dataqa="zip"
                />
              </div>
            </>
          )}
        </div>
      </div>
      {!isAllFieldsEnabled && (
        <div className="manual-address-link" onClick={handleClickAddressManual}>
          {getString('enterAddressManually')}
        </div>
      )}
      <FormErrors errors={errorSet} />
    </>
  );
};

export default PatientAddressInfo;
