import React, { useState, useEffect, useMemo } from 'react';

import { getString } from 'util/lang';
import { handleError } from 'util/errorHandler';
import PAGE_STATUS from 'constants/clinicAndTimingPageStatus';
import CUSTOM_ERROR_CODE from 'constants/customHttpErrorCode';

import Button from 'components/common/Button';
import ReCaptcha from 'components/COVID/common/ReCaptcha';
import AlertMessage from 'components/COVID/common/AlertMessage';

import LoadingModal from '../presentation/LoadingModal';
import ConflictModal from '../presentation/conflictModal';
import SelectedClinic from '../presentation/SelectedClinic';
import VaccineInformation from '../presentation/VaccineInformation';
import ActivityIndicator from 'components/common/ActivityIndicator';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useAppointmentReschedule } from 'context/useAppointmentReschedule';
import {
  useAppSettings,
  useDetailContext,
  useSelectionsContext,
} from 'context';
import { useAppParams } from 'context/useAppParams';
import { makeRescheduleNavLinks } from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { changeTitle } from 'util/siteInfo';

const CONFLICT_RETRY_MAX = 3;

const RescheduleConfirmation = () => {
  const [captchaKey, setCaptchaKey] = useState(null);
  const [conflictRetryCount, setConflictRetryCount] = useState(0);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [isConflictModalOpen, setIsConflictModalOpen] = useState(false);
  const [isCaptchaAttempted, setIsCaptchaAttempted] = useState(false);

  const { appointmentId } = useAppParams();

  const nav = useNavigationHelper();

  const { selectedServices, selectedClinic } = useSelectionsContext();

  const {
    details: {
      appointments,
      firstPageLoaded: isFirstPageLoaded,
      isVaccineListFetching: isLanguageUpdating,
    },
    setDetails,
    disableNavForSuccessPage,
    setSecurityChecked,
    setSecurityToken,
  } = useDetailContext();

  const { isCaptchaEnabled } = useAppSettings();

  const { rescheduleAppointment } = useAppointmentReschedule();

  const { firstAppointment } = appointments[0];
  const isAppointmentReadyForReschedule = firstAppointment?.bookingBlock;

  useEffect(() => {
    if (conflictRetryCount > CONFLICT_RETRY_MAX) {
      setIsConflictModalOpen(true);
    }
  }, [conflictRetryCount, isFirstPageLoaded]);

  const onCaptchaChange = (captchaResponseKey) => {
    setCaptchaKey(captchaResponseKey);
  };

  const onDateTimeEdit = () => {
    nav.toRescheduleAppointment(appointmentId, {
      state: {
        redirectView: PAGE_STATUS.SELECT_DATE_AND_TIME,
      },
    });
  };

  const confirmAndRescheduleAppointment = async () => {
    if (isCaptchaEnabled && !captchaKey) {
      setIsCaptchaAttempted(true);
      return;
    }
    setIsLoadingModalOpen(true);
    try {
      await setDetails({ captchaResponseKey: captchaKey });
      await rescheduleAppointment(captchaKey);
      setSecurityChecked(false);
      setSecurityToken(undefined);

      disableNavForSuccessPage();
      onCaptchaChange(null);
      setIsLoadingModalOpen(false);
      nav.toSuccess();
    } catch (error) {
      isCaptchaEnabled && window.grecaptcha.reset();
      onCaptchaChange(null);
      setIsLoadingModalOpen(false);
      const errorCode = error?.response?.data?.error?.code;
      if (errorCode === CUSTOM_ERROR_CODE.NO_SLOT_AVAILABLE) {
        setIsConflictModalOpen(true);
        setConflictRetryCount(conflictRetryCount + 1);
        return;
      } else {
        handleError(error);
      }
    }
  };

  const closeConflictModal = () => {
    setIsConflictModalOpen(false);
    setConflictRetryCount(0);
  };

  const gotoSchedulePage = () => {
    closeConflictModal();

    nav.toRescheduleAppointment(appointmentId);
  };

  if (!isFirstPageLoaded) {
    nav.resetToFirstPage();
    return null;
  }

  return (
    <>
      <section className="schedule-appointment section__margin">
        <section className="border-radius border">
          {isLanguageUpdating ? (
            <div className="section__container py-26x">
              <ActivityIndicator />
            </div>
          ) : (
            <React.Fragment>
              <SelectedClinic selectedClinic={selectedClinic} />
              <hr />
              <VaccineInformation
                appointments={appointments}
                onDateTimeEdit={onDateTimeEdit}
                selectedServices={selectedServices}
              />
            </React.Fragment>
          )}
        </section>
      </section>
      <AlertMessage
        isVisible
        type="warning"
        className="mb-6x"
        message={getString('updateAppointmentMessage')}
      />
      {isCaptchaEnabled && (
        <ReCaptcha
          onCaptchaChange={onCaptchaChange}
          isCaptchaValid={isCaptchaAttempted ? !!captchaKey : true}
        />
      )}
      <Button
        onClick={confirmAndRescheduleAppointment}
        label={getString('confirmAndSubmit')}
        title={
          !isAppointmentReadyForReschedule
            ? getString('rescheduleInformation')
            : getString('submitTitle')
        }
        isEnabled={!isLanguageUpdating && isAppointmentReadyForReschedule}
        dataqa="confirm-and-submit-btn"
      />
      {isLoadingModalOpen && <LoadingModal isClosable={false} />}
      {isConflictModalOpen && (
        <ConflictModal
          closeConflictModal={closeConflictModal}
          gotoSchedulePage={gotoSchedulePage}
        />
      )}
    </>
  );
};

export default RescheduleConfirmation;

export const RescheduleConfirmationPage = () => {
  changeTitle('Confirmation');
  const nav = useNavigationHelper();
  const { organizationId, appointmentId } = useAppParams();
  const {
    details: { selectedLocationId, selectedServices },
  } = useDetailContext();

  const appContext = useDetailContext();
  const {
    details,
    setDetails,
    disableNavForSuccessPage,
    setSecurityChecked,
    setSecurityToken,
  } = appContext;
  const { reschedule } = details;

  const { selectedClinic: selectedLocation } = useSelectionsContext();

  const navLinks = useMemo(
    () => makeRescheduleNavLinks(appointmentId, organizationId),
    [organizationId, appointmentId],
  );

  if (!selectedServices.length && !selectedLocationId) {
    nav.resetToFirstPage();

    return null;
  }

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <RescheduleConfirmation
        appointments={details.appointments}
        selectedVaccine={details.selectedVaccine}
        organizationId={details.organizationId}
        isFirstPageLoaded={details.firstPageLoaded}
        isLanguageUpdating={details.isVaccineListFetching}
        disableNavForSuccessPage={disableNavForSuccessPage}
        setDetails={setDetails}
        setSecurityChecked={setSecurityChecked}
        setSecurityToken={setSecurityToken}
        activityId={details.appointmentBeingRescheduled.activityid}
        isSecurityChecked={details.isSecurityChecked}
        serviceSelection={details.serviceSelection}
        selectedLocation={selectedLocation}
        selectedServices={selectedServices}
        reschedule={reschedule}
      />
    </PageWrapper>
  );
};
