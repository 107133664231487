import React, { useEffect, useState } from 'react';

import { HelpfulResource } from './HelpfulResource';
import { mapUsefulLinkToApplicationList } from '../../helper/getAppLinks';
import { helpfulResourceList } from '../../constants/helpfulResourceList';

export const HelpfulResourceList = (props) => {
  const { usefulLinks } = props;

  const [resourceList, setResourceList] = useState([]);

  useEffect(() => {
    const resourceList = mapUsefulLinkToApplicationList(
      helpfulResourceList,
      usefulLinks,
    );
    setResourceList(resourceList);
  }, []);

  return (
    <div className="helpful-resources__buttons-wrapper flex-column">
      {!!resourceList.length &&
        resourceList.map((resource) => {
          return <HelpfulResource resource={resource} />;
        })}
    </div>
  );
};
