import React from 'react';
import { getString } from 'util/lang';
import cls from 'classnames';

import AlertMessage from './AlertMessage';
import Button from '../../common/Button';
import styles from './AlertedSubmitButton.module.scss';
import SecondaryServiceInfo from '../views/SelectMultiService/presentation/SecondaryServiceInfo';

export const AlertedSubmitButton = (props) => {
  const {
    hasSecondaryServiceTypes,
    onSelectSecondaryService,
    isSubmitEnabled,
    isWaitlistEnabled,
    gotoWaitlist,
    onContinueWithSingleService,
  } = props;
  return (
    <div className={styles.alertedButtonMainWrapper}>
      <div className={styles.alertedButtonMain} id="alertButtonMainShadow">
        {isSubmitEnabled && isWaitlistEnabled ? (
          <>
            <AlertMessage
              type="info"
              message={
                getString('appointmentsTaken') +
                getString('waitListAdditionalInfo')
              }
              isVisible={isSubmitEnabled}
              dataqa="waitlist-info"
            />
            <Button
              isEnabled={isSubmitEnabled}
              label={getString('joinWaitList')}
              onClick={gotoWaitlist}
              dataqa="join-waitlist"
              className={cls('btn btn-primary btn-block')}
            />
          </>
        ) : (
          <div>
            <Button
              isEnabled={isSubmitEnabled}
              label={getString('continue')}
              onClick={onContinueWithSingleService}
              dataqa="go-to-prescreening"
              className={cls('btn btn-primary btn-block')}
            />
            <SecondaryServiceInfo
              onClickAddService={onSelectSecondaryService}
              isVisible={hasSecondaryServiceTypes}
            />
          </div>
        )}
      </div>
    </div>
  );
};
