import React from 'react';
import { FiAlertTriangle, FiCheck, FiInfo } from 'vyaguta-icons/fi';

const alertTypeToIconMap = {
  success: <FiCheck size={20} className="mr-4x" />,
  danger: <FiAlertTriangle size={24} strokeWidth={1.5} className="mr-4x" />,
  warning: <FiAlertTriangle size={24} strokeWidth={1.5} className="mr-4x" />,
  info: <FiInfo size={24} strokeWidth={1.5} className="mr-4x" />,
};

/* Alert message for radio button forms */
const AlertList = (props) => {
  const { messages, type, className, isVisible } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`alert alert--message alert--${type} ${className}`}>
      {messages.map((message, idx) => {
        return (
          <div key={idx} className="d-flex alert-message-list">
            <span className="alert__icon">{alertTypeToIconMap[type]}</span>
            <div className="alert__message">{message}</div>
          </div>
        );
      })}
    </div>
  );
};

export default AlertList;
