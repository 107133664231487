import RadioCheckBox from '../YesNoButtons/RadioCheckBox';

import { questionnaireKeys } from '../../../validation/AddDeliveryTicket';
import LineBreak from '../../common/LineBreak/LineBreak';

import styles from './Questionnaire.module.scss';
import { MAX_DATE, MIN_DATE } from '../../../constants/date';
import { QuestionnaireRadioOption } from '../../../constants/RadioCheckOptions';
import YesNoCheckboxes from '../YesNoButtons/YesNoCheckboxes';

// considering min width 961px for desktop
const minWidth = 15;
const maxWidth = 28;

const getInputWidth = (length = 0) => {
  if (length < minWidth) {
    return `${minWidth}ch`;
  }

  if (length >= maxWidth) {
    return `${maxWidth}ch`;
  }

  return `${length + 1}ch`;
};

/**
 * Questionnaire Component - Delivery Ticket - DME
 * @param {array} questionnaire
 * @param {func} setQuestionnaire
 */
const Questionnaire = ({
  questionnaire,
  isEdit,
  formErrors,
  setDeliveryTicketHandler,
}) => {
  const onCheckHandler = (id, checked) => {
    setDeliveryTicketHandler(
      questionnaire.map((question) => {
        if (id === question.id) {
          return {
            ...question,
            checked,
          };
        }
        return question;
      }),
      'questionnaireData',
    );
  };

  const inputChangeHandler = (event, id) => {
    const key = event.target.id;
    const value = event.target.value;
    const tempQuestionnaire = [...questionnaire];
    tempQuestionnaire[id] = {
      ...tempQuestionnaire[id],
      [key]: value,
    };
    setDeliveryTicketHandler([...tempQuestionnaire], 'questionnaireData');
  };

  return (
    <div className="delivery-ticket__questionnaire">
      <div className="questionnaire-item d-flex gap-4">
        <div className="questionnaire-item__left">
          <RadioCheckBox
            name="rentOrPurchase"
            options={QuestionnaireRadioOption}
            disabled={!isEdit}
            selectedValue={questionnaire[0]?.checked}
            onChange={(checked) =>
              onCheckHandler(questionnaire[0]?.id, checked)
            }
          />
        </div>
        <div className="questionnaire-item__right">
          <p>
            Was the patient informed of their right to rent or purchase
            Inexpensive & Routinely Purchased Items?
          </p>
        </div>
      </div>

      <div className="questionnaire-item d-flex gap-4">
        <div className="questionnaire-item__left">
          <RadioCheckBox
            name="equipmentUsage"
            options={QuestionnaireRadioOption}
            disabled={!isEdit}
            selectedValue={questionnaire[1]?.checked}
            onChange={(checked) =>
              onCheckHandler(questionnaire[1]?.id, checked)
            }
          />
        </div>
        <div className="questionnaire-item__right">
          <p>
            Is the item provided above to be used with Medicare covered
            equipment? If YES,
          </p>
          <p>
            On what date did the patient get the equipment?{' '}
            <input
              type="date"
              id={questionnaireKeys.datePatientGotTheEquipment}
              className={`border-none border-bottom p-2x ${
                formErrors?.[1]?.[questionnaireKeys.datePatientGotTheEquipment]
                  ? `${styles.errorInput} has-error`
                  : ''
              }`}
              disabled={!isEdit}
              value={
                questionnaire[1]?.[
                  questionnaireKeys.datePatientGotTheEquipment
                ] || ''
              }
              onChange={(event) => inputChangeHandler(event, 1)}
              max={MAX_DATE}
              min={MIN_DATE}
            />{' '}
            & Who paid for it?{' '}
            <input
              type="text"
              id={questionnaireKeys.whoPaidForIt}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={questionnaire[1]?.[questionnaireKeys.whoPaidForIt] || ''}
              style={{
                width: getInputWidth(
                  questionnaire[1]?.[questionnaireKeys.whoPaidForIt]?.length,
                ),
              }}
              onChange={(event) => inputChangeHandler(event, 1)}
            />
          </p>
        </div>
      </div>

      <div className="questionnaire-item d-flex gap-4">
        <div className="questionnaire-item__left">
          <RadioCheckBox
            name="isRefill"
            options={QuestionnaireRadioOption}
            disabled={!isEdit}
            selectedValue={questionnaire[2]?.checked}
            onChange={(checked) =>
              onCheckHandler(questionnaire[2]?.id, checked)
            }
          />
        </div>
        <div className="questionnaire-item__right">
          <p>
            If this is a refill, is the patient’s current supply nearly
            exhausted? Approx. how many days supply remains?{' '}
            <input
              type="text"
              id={questionnaireKeys.supplyRemainDays}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={
                questionnaire[2]?.[questionnaireKeys.supplyRemainDays] || ''
              }
              style={{
                width: getInputWidth(
                  questionnaire[2]?.[questionnaireKeys.supplyRemainDays]
                    ?.length,
                ),
              }}
              onChange={(event) => inputChangeHandler(event, 2)}
            />
          </p>
        </div>
      </div>

      <div className="questionnaire-item d-flex gap-4">
        <div className="questionnaire-item__left">
          <RadioCheckBox
            name="informationChanged"
            options={QuestionnaireRadioOption}
            disabled={!isEdit}
            selectedValue={questionnaire[3]?.checked}
            onChange={(checked) =>
              onCheckHandler(questionnaire[3]?.id, checked)
            }
          />
        </div>
        <div className="questionnaire-item__right">
          <p>
            Has the patient’s information changed since registration?  If YES,
            complete a new registration form.
          </p>
        </div>
      </div>
      <div className="questionnaire-item d-flex gap-4">
        <div className="questionnaire-item__left">
          <RadioCheckBox
            name="orthoticFitting"
            options={QuestionnaireRadioOption}
            disabled={!isEdit}
            selectedValue={questionnaire[4]?.checked}
            onChange={(checked) =>
              onCheckHandler(questionnaire[4]?.id, checked)
            }
          />
        </div>
        <div className="questionnaire-item__right">
          <p>
            If the item is an orthotic, did it require custom fitting by a
            licensed/certified individual?
          </p>
        </div>
      </div>
      <div className="questionnaire-item d-flex gap-4">
        <div className="questionnaire-item__left">
          <RadioCheckBox
            name="homeHealthEnrolled"
            options={QuestionnaireRadioOption}
            disabled={!isEdit}
            selectedValue={questionnaire[5]?.checked}
            onChange={(checked) =>
              onCheckHandler(questionnaire[5]?.id, checked)
            }
          />
        </div>
        <div className="questionnaire-item__right">
          <p>Is the patient enrolled in Home Health or Hospice services?</p>
          <p>
            Agency Name:{' '}
            <input
              type="text"
              id={questionnaireKeys.agencyName}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={questionnaire[5]?.[questionnaireKeys.agencyName] || ''}
              style={{
                width: getInputWidth(
                  questionnaire[5]?.[questionnaireKeys.agencyName]?.length,
                ),
              }}
              onChange={(event) => inputChangeHandler(event, 5)}
            />{' '}
            Phone No:{' '}
            <input
              type="number"
              id={questionnaireKeys.agencyPhoneNumber}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={
                questionnaire[5]?.[questionnaireKeys.agencyPhoneNumber] || ''
              }
              onChange={(event) => {
                if (event.target.value.length > 10) return;
                inputChangeHandler(event, 5);
              }}
            />
          </p>
        </div>
      </div>
      <div className="questionnaire-item d-flex gap-4">
        <div className="questionnaire-item__left">
          <RadioCheckBox
            name="hadSimilarEquipmenet"
            options={QuestionnaireRadioOption}
            disabled={!isEdit}
            selectedValue={questionnaire[6]?.checked}
            onChange={(checked) =>
              onCheckHandler(questionnaire[6]?.id, checked)
            }
          />
        </div>
        <div className="questionnaire-item__right">
          <p>
            Has the patient ever received same or similar equipment? If YES,
          </p>
          <p>
            Who was the Provider?:{' '}
            <input
              type="text"
              id={questionnaireKeys.providerName}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={questionnaire[6]?.[questionnaireKeys.providerName] || ''}
              style={{
                width: getInputWidth(
                  questionnaire[6]?.[questionnaireKeys.providerName]?.length,
                ),
              }}
              onChange={(event) => inputChangeHandler(event, 6)}
            />{' '}
            Phone No:{' '}
            <input
              type="number"
              id={questionnaireKeys.providerPhoneNumber}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={
                questionnaire[6]?.[questionnaireKeys.providerPhoneNumber] || ''
              }
              onChange={(event) => {
                if (event.target.value.length > 10) return;
                inputChangeHandler(event, 6);
              }}
            />
          </p>
          <p>
            Item Received:{' '}
            <input
              type="text"
              id={questionnaireKeys.itemReceived}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={questionnaire[6]?.[questionnaireKeys.itemReceived] || ''}
              style={{
                width: getInputWidth(
                  questionnaire[6]?.[questionnaireKeys.itemReceived]?.length,
                ),
              }}
              onChange={(event) => inputChangeHandler(event, 6)}
            />{' '}
            Date Received:{' '}
            <input
              type="date"
              id={questionnaireKeys.dateReceived}
              className={`border-none border-bottom p-2x ${formErrors?.[6]?.[questionnaireKeys.dateReceived] ? `${styles.errorInput} has-error` : ''}`}
              disabled={!isEdit}
              value={questionnaire[6]?.[questionnaireKeys.dateReceived] || ''}
              onChange={(event) => inputChangeHandler(event, 6)}
              max={MAX_DATE}
              min={MIN_DATE}
            />
          </p>
          <p>
            Do you still have this item?{' '}
            <YesNoCheckboxes
              name="stillHasTheItem"
              yesKey="stillHasTheItemYes"
              noKey="stillHasTheItemNo"
              disabled={!isEdit}
              checked={questionnaire[6]?.stillHasTheItem}
              onCheck={(checked) =>
                setDeliveryTicketHandler(
                  questionnaire.map((question, index) => {
                    if (index === 6) {
                      return {
                        ...question,
                        stillHasTheItem: checked,
                      };
                    }
                    return question;
                  }),
                  'questionnaireData',
                )
              }
            />{' '}
            Date Returned:{' '}
            <input
              type="date"
              id={questionnaireKeys.dateReturned}
              className={`border-none border-bottom p-2x ${formErrors?.[6]?.[questionnaireKeys.dateReturned] ? `${styles.errorInput} has-error` : ''}`}
              disabled={!isEdit}
              value={questionnaire[6]?.dateReturned || ''}
              onChange={(event) => inputChangeHandler(event, 6)}
              max={MAX_DATE}
              min={MIN_DATE}
            />
          </p>
          <p>
            Why was it returned?{' '}
            <input
              type="text"
              id={questionnaireKeys.returnReason}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={questionnaire[6]?.[questionnaireKeys.returnReason] || ''}
              style={{
                width: getInputWidth(
                  questionnaire[6]?.[questionnaireKeys.returnReason]?.length,
                ),
              }}
              onChange={(event) => inputChangeHandler(event, 6)}
            />{' '}
            Was there a 60 day break in need?
            <input
              type="text"
              id={questionnaireKeys.sixtyDaysBreakNeeded}
              className="border-none border-bottom p-2x"
              disabled={!isEdit}
              value={
                questionnaire[6]?.[questionnaireKeys.sixtyDaysBreakNeeded] || ''
              }
              onChange={(event) => inputChangeHandler(event, 6)}
            />
          </p>
        </div>
      </div>

      <LineBreak />
    </div>
  );
};
export default Questionnaire;
