import React from 'react';
import { getString } from 'util/lang';

const WaitlistNotice = (props) => {
  const { gotoWaitlist } = props;

  return (
    <div className="section__empty">
      {/* @multilanguage */}
      <h3 className="mb-4x">{getString('appointmentsEmpty')}</h3>
      <p className="color-grey-text700">
        <span className="text-bold">{getString('appointmentsTaken')}</span>
        {getString('waitListAdditionalInfo')}
      </p>
      <button
        className="btn btn-primary btn-block mt-5x"
        onClick={() => gotoWaitlist()}
      >
        {getString('joinWaitList')}
      </button>
    </div>
  );
};

export default WaitlistNotice;
