import React, {
  useReducer,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { NavigationReducer, NavigationContext } from './context';
import { PatientIcon, LeftArrow } from 'components/icons';

import styles from './SideNavBar.module.scss';

export const SideNavBar = (props) => {
  const { children, options = {} } = props;
  const [state, dispatch] = useReducer(NavigationReducer, {});

  React.useEffect(() => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        activeMenuIndex: options?.menuIndex,
        activeSubMenuIndex: options?.subMenuIndex,
        allowNavigation:
          options.allowNavigation === null ||
          options.allowNavigation === undefined
            ? false
            : options.allowNavigation,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavigationContext.Provider value={{ state, dispatch }}>
      {children && children}
    </NavigationContext.Provider>
  );
};

export const Navigations = (props) => {
  const context = useContext(NavigationContext);
  const [showNav, setShowNav] = useState(false);
  const navRef = useRef(null);

  const handleOutSideClick = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setShowNav(false);
    }
  };

  useEffect(() => {
    if (showNav) {
      document.addEventListener('click', handleOutSideClick);
      return () => {
        document.removeEventListener('click', handleOutSideClick);
      };
    }
  }, [showNav]);

  const handleNavigation = () => {
    setShowNav(!showNav);
  };

  const closeNavBar = () => {
    setShowNav(false);
  };

  if (!context) {
    throw new Error(`Menu Should be used as child of SideNavBar`);
  }

  const { children } = props;

  return (
    <div className={styles['side-navbar']} ref={navRef}>
      <div className={styles['side-navbar-header']}>
        <div className={styles['side-navbar-header__icon']}>
          <PatientIcon />
        </div>
        <div className={styles['side-navbar-header__text']}>Patients List</div>
      </div>

      <div
        className={styles['side-navbar-responsive-header--collapsed']}
        onClick={handleNavigation}
      >
        <div
          className={styles['side-navbar-responsive-header--collapsed__box']}
        >
          <PatientIcon
            className={
              styles['side-navbar-responsive-header--collapsed__box-icon']
            }
          />
          <div
            className={
              styles[
                'side-navbar-responsive-header--collapsed__box-patient-count'
              ]
            }
          >
            {context?.state?.menuLength}
          </div>
        </div>
      </div>

      <div className={styles['side-navbar-content']}>
        {children && children}
      </div>

      <div
        className={`${styles['side-navbar-responsive-content']} ${
          showNav ? '' : styles['side-navbar-responsive-content-close']
        }`}
      >
        <div className={styles['side-navbar-responsive-header--expanded']}>
          <div
            className={
              styles['side-navbar-responsive-header--expanded__box-circle']
            }
            onClick={closeNavBar}
            data-element="nav-close-button"
          >
            <LeftArrow
              className={
                styles[
                  'side-navbar-responsive-header--expanded__box-circle-icon'
                ]
              }
            />
          </div>
          <div className={styles['side-navbar-header__text']}>
            Patients List
          </div>
        </div>
        {children && children}
      </div>
      <div className={styles['side-navbar-mobile-footer']}></div>
    </div>
  );
};
