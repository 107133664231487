import { getString } from 'util/lang';
import { useNavigationHelper } from '../../../../../hooks/useNavigationHelper';
import { PageWrapper } from '../../../common/PageWrapper';
import React, { useMemo } from 'react';
import {
  makeDefaultNavLinks,
  makeGroupAppointmentNavLinks,
} from '../../../../common/NavBar/navLinks';
import {
  useDetailContext,
  useGroupAppointmentContext,
  useSelectionsContext,
} from '../../../../../context';
import { useAppParams } from '../../../../../context/useAppParams';
import { AddPatientConfirmationButtons } from '../presentation/AddPatientConfirmationSection';
import AddSinglePatientInfoInput from '../presentation/AddSinglePatientInfoInput';
import BackToPage from '../../../common/BackToPage/BackToPage';
import { AddPatientInformation } from '../presentation/AddPatientInformation';
import { useState } from 'react';
import { AddPatientsConfirmationModal } from '../presentation/AddPatientsConfirmationModal';
import { CovidPageNames } from '../../../../../constants/pages';
import { PatientDefaultInfo } from 'constants/app';
import {
  isPatientBirthInformationPartiallyFilled,
  isPatientBirthInformationPartiallyFilledOrEmpty,
} from '../../../../../util/string';

const AddPatients = ({
  className,
  patientInfo,
  patientIndex,
  setState,
  removeSelectedPatientForm,
  setFormHasErrors,
  optionalSubtitle,
  selectedServices,
}) => {
  return (
    <div className={className}>
      <AddSinglePatientInfoInput
        patientInfo={patientInfo}
        patientIndex={patientIndex}
        setState={setState}
        removeSelectedPatientForm={removeSelectedPatientForm}
        setFormHasErrors={setFormHasErrors}
        optionalSubtitle={optionalSubtitle}
        selectedServices={selectedServices}
      />
    </div>
  );
};

// return local state object for rendering info in add patient forms {1: ..., ...., 5: {}}
const getLocalStatePatientInfo = (patientsInfo) => {
  const localStateObject = {};
  patientsInfo.forEach((patient) => {
    localStateObject[patient.idx + 1] = {
      firstName: patient.firstName,
      lastName: patient.lastName,
      middleName: patient.middleName,
      email: patient.email,
      mobileNo: patient.mobileNo,
      sendEmail: !!patient.email,
      birthDay: patient.birthDay,
      birthMonth: patient.birthMonth,
      birthYear: patient.birthYear,
    };
  });
  return localStateObject;
};

export const AddPatientsPage = () => {
  const {
    details: {
      pageComplete,
      isServiceAndClinicSelected,
      patientInfo,
      groupPendingPatientInfo,
    },
    setDetails,
    setPageComplete,
  } = useDetailContext();
  const nav = useNavigationHelper();
  const { selectedServices } = useSelectionsContext();
  const toNextPage = () => nav.toPatientEligibility();
  const [showSchedulePatient1Modal, setShowSchedulePatient1Modal] =
    useState(false);

  const { isGroupAppointment } = useGroupAppointmentContext();

  const MAX_ALLOWED_INFO = 5;

  const { organizationId, appointmentId } = useAppParams();
  const [formHasErrors, setFormHasErrors] = useState(false);
  const isPatientInfoInitialized = !!(
    patientInfo[0]?.firstName ||
    patientInfo[0]?.lastName ||
    patientInfo?.middlename
  );

  const [localState, setLocalState] = useState(
    isPatientInfoInitialized
      ? getLocalStatePatientInfo([...patientInfo, ...groupPendingPatientInfo])
      : { 1: {} },
  );

  const navLinks = useMemo(
    () =>
      isGroupAppointment
        ? makeGroupAppointmentNavLinks(pageComplete, organizationId)
        : makeDefaultNavLinks(pageComplete, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  const getAvailablePatientIndices = () => Object.keys(localState);
  const getMaxIndex = () =>
    Math.max(...getAvailablePatientIndices().map((e) => Number(e)));
  const isMaxNumOfFormsReached = () => {
    return getMaxIndex() === MAX_ALLOWED_INFO;
  };

  /**
   *
   * @param {*} allPatientInfo Local State Object retrieved from form
   * @desc Based on sendEmail add patients into either patientInfo or groupPendingPatientInfo state
   *
   */

  const updateGlobalState = (allPatientInfo) => {
    const globalPatientInfo = [];
    const globalSelfDeclaration = [];
    const globalGroupPendingPatientInfo = [];

    Object.values(allPatientInfo).forEach((patient, idx) => {
      if (patient.sendEmail) {
        globalGroupPendingPatientInfo.push({ ...patient, idx });
      } else {
        const currentPatientInfoData = patientInfo.find(
          (p) =>
            p.firstName === patient.firstName &&
            p.lastName === patient.lastName,
        );
        const patientsInfo = {
          ...PatientDefaultInfo,
          ...currentPatientInfoData,
          ...patient,
          idx,
        };

        globalPatientInfo.push(patientsInfo);

        globalSelfDeclaration.push({
          firstName: patient.firstName,
          lastName: patient.lastName,
          middleName: patient.middleName,
        });
      }
    });

    setDetails({
      patientInfo: globalPatientInfo,
      selfDeclaration: globalSelfDeclaration,
      groupPendingPatientInfo: globalGroupPendingPatientInfo,
    });
  };

  const arePatientInfoFormsFilled = (patientInfoIndices = []) => {
    return patientInfoIndices
      .map((eachKey) => {
        const eachPatientInfo = localState[eachKey];
        const sendEmail = eachPatientInfo?.sendEmail;

        const contactInformation = sendEmail ? eachPatientInfo?.email : true;
        const birthInformation = sendEmail
          ? !isPatientBirthInformationPartiallyFilled(eachPatientInfo)
          : !isPatientBirthInformationPartiallyFilledOrEmpty(eachPatientInfo);

        return (
          !!eachPatientInfo?.firstName &&
          !!eachPatientInfo?.lastName &&
          birthInformation &&
          !!contactInformation
        );
      })
      .every((e) => e === true);
  };

  const addNewPatientForm = () => {
    if (isMaxNumOfFormsReached()) return;
    setLocalState({ ...localState, [getMaxIndex() + 1]: {} });
  };

  const removeSelectedPatientForm = (patientIndex) => {
    // left shift all the data in the local state
    const availableIndices = getAvailablePatientIndices().map((e) => Number(e));
    if (availableIndices.length === 1) {
      setLocalState({ 1: {} });
      return;
    }

    let newState = {};
    for (let eachIndex = 0; eachIndex < availableIndices.length; eachIndex++) {
      const index = availableIndices[eachIndex];
      if (index < patientIndex) {
        newState = { ...newState, [index]: localState[index] };
      } else if (index > patientIndex) {
        newState = { ...newState, [index - 1]: localState[index] }; //left shift the state
      }
    }

    setLocalState({ ...newState });
  };

  const onGroupScheduleConfirm = (allPatientInfo) => {
    updateGlobalState(allPatientInfo);

    // Set to individual appointment if only single person details is available
    if (Object.keys(allPatientInfo).length === 1) {
      setDetails({ isGroupAppointment: false });
    } else {
      setDetails({ isGroupAppointment: true });
    }

    setPageComplete({
      [CovidPageNames.AddPatients]: true,
    });
    toNextPage();
  };

  if (!isServiceAndClinicSelected && !isGroupAppointment) {
    nav.resetToFirstPage();
    return null;
  }

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <BackToPage text="Back to Previous Page" onClick={nav.goBack} />
      <AddPatientInformation />
      {getAvailablePatientIndices().map((patientIndex) => {
        return (
          <AddPatients
            className="pb-10x"
            patientInfo={localState[patientIndex]}
            setState={(patientInfo) =>
              setLocalState({ ...localState, [patientIndex]: patientInfo })
            }
            patientIndex={patientIndex}
            removeSelectedPatientForm={() =>
              removeSelectedPatientForm(Number(patientIndex))
            }
            setFormHasErrors={setFormHasErrors}
            optionalSubtitle={
              !arePatientInfoFormsFilled(['1'])
                ? getString('firstPatientNotice')
                : null
            }
            selectedServices={selectedServices}
          />
        );
      })}

      <AddPatientConfirmationButtons
        continueButtonTitle={
          getAvailablePatientIndices().length === 1
            ? getString('schedulePatient1Only')
            : getString('continueScheduling')
        }
        addNewPatientForm={addNewPatientForm}
        isAddButtonActive={!isMaxNumOfFormsReached()}
        isContinueButtonActive={
          arePatientInfoFormsFilled(getAvailablePatientIndices()) &&
          !formHasErrors
        }
        onContinue={() => {
          getAvailablePatientIndices().length > 1
            ? onGroupScheduleConfirm(localState)
            : setShowSchedulePatient1Modal(true);
        }}
      />

      <AddPatientsConfirmationModal
        show={
          getAvailablePatientIndices().length === 1 && showSchedulePatient1Modal
        }
        headerContentText={getString('schedulePatient1Only')}
        bodyContentText={getString('schedulePatient1OnlyInfo')}
        confirmText={getString('schedulePatient1OnlyConfirm')}
        cancelText={getString('addAnotherPatient')}
        onConfirm={() => onGroupScheduleConfirm(localState)}
        onCancel={() => {
          addNewPatientForm();
          setShowSchedulePatient1Modal(false);
        }}
      />
    </PageWrapper>
  );
};
