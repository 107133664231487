import React from 'react';
import { iconClock } from 'assets/images';
import { getString } from 'util/lang';

const EmptyTimeslots = () => (
  <div className="col-7-md d-flex justify-content-center align-items-center">
    <div className="calendar-empty-state text-center py-12x ">
      {/* TODO: Use icon instead of image */}
      <img src={iconClock} alt="Icon clock" className="mb-4x" />
      <h3>{getString('emptyTimeslotsLabel')}</h3>
      <p className="col-8-md mx-auto">
        {getString('emptyTimeslotsDescription')}
      </p>
    </div>
  </div>
);

export default EmptyTimeslots;
