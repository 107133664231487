import React from 'react';
import { Link } from 'react-router-dom';

const LinkToPage = (props) => {
  const {
    enabled,
    label,
    to,
    setPageComplete,
    pageName,
    onClick,
    className,
    title,
    dataqa,
  } = props;

  const onLinkClick = () => {
    if (onClick) {
      onClick();
    }
    setPageComplete({
      [pageName]: true,
    });
  };

  if (enabled) {
    return (
      <Link
        className={className ? className : 'btn btn-primary btn-block'}
        to={{ pathname: to, search: window.location.search }}
        onClick={onLinkClick}
        title={title}
        data-qa={dataqa}
      >
        {label}
      </Link>
    );
  }

  return (
    <div
      className="btn btn-primary btn-block btn--disabled"
      data-qa={`${dataqa}-disabled`}
    >
      {label}
    </div>
  );
};

export default LinkToPage;
