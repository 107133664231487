import React, { useContext } from 'react';
import { NavigationContext } from './context';
import styles from './SideNavBar.module.scss';

export const Panel = (props) => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(`Panel Should be used as child of SideNavBar`);
  }
  const {
    state: { activeMenuIndex, activeSubMenuIndex },
  } = context;

  const { children, menuIndex, subMenuIndex } = props;

  if (isNaN(menuIndex) || isNaN(subMenuIndex)) {
    throw new Error('label or subMenuIndex missing in Panel Component');
  }

  const isVisible =
    menuIndex === activeMenuIndex && subMenuIndex === activeSubMenuIndex;
  if (!isVisible) {
    return null;
  }

  return <div className={styles['side-navbar-item__panel']}>{children}</div>;
};
