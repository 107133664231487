import React from 'react';
import { getString } from 'util/lang';
import MessageListBox from 'components/COVID/common/MessageListBox';
import Button from './Button';

import styles from './InfoWithContact.module.scss';
import { ActivityIndicatorOverlay } from './ActivityIndicator';

/**
 *
 * @param {{
 *  isLoading?: boolean
 *  mainBtnTitle?: string
 *  mainBtnLabel?: string
 *  onBtnClick?: () => void
 *  contactInfoMessages: {title: string, message: string}[]
 *  icon: JSX.Element
 *  contents: JSX.Element
 * }} props
 */
export const InfoWithContact = ({
  isLoading = false,
  mainBtnLabel,
  mainBtnTitle,
  onBtnClick,
  contactInfoMessages,
  icon,
  contents,
}) => {
  return (
    <div className={styles.mainContainer}>
      <div className="success-content">
        {icon}
        <p tabIndex="0" className="mb-0x-md">
          {contents}
        </p>
        {onBtnClick && (
          <div className="success-action-covid">
            <Button
              title={mainBtnTitle ?? getString('bookAppointmentTitle')}
              isEnabled
              onClick={onBtnClick}
              className="btn btn--large btn-primary"
              isActive
              label={mainBtnLabel ?? getString('bookAppointment')}
            />
          </div>
        )}
      </div>
      <div className={styles.contactInfoContainer}>
        <div className="section-title">{getString('contactInformation')}:</div>

        <MessageListBox messages={contactInfoMessages} />
      </div>
      {isLoading && <ActivityIndicatorOverlay />}
    </div>
  );
};
