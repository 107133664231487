import React, { useEffect } from 'react';

import ActivityIndicator from 'components/common/ActivityIndicator';
import SkeletonLoadingContainer from 'components/common/SkeletonLoadingContainer';

import EligibilityQuestion from '../presentation/EligibilityQuestion';

import { getString } from 'util/lang';

const PatientEligibility = (props) => {
  const {
    details,
    finalEligibilityQuestions,
    eligibilityQuestionResponse,
    onChangeEligibilityQuestion,
    setEligibilityQuestionResponse,
  } = props;
  const {
    loading,
    currentPatientIndex,
    eligibilityResponse,
    isEligibilityQuestionFetching,
  } = details;

  useEffect(() => {
    setEligibilityQuestionResponse(
      eligibilityResponse[currentPatientIndex] ?? {},
    );
  }, [currentPatientIndex]);

  const generalContent = () => {
    return (
      <React.Fragment>
        <React.Fragment>
          <SkeletonLoadingContainer loading={isEligibilityQuestionFetching}>
            {!!finalEligibilityQuestions.length && (
              <EligibilityQuestion
                eligibilityQuestions={finalEligibilityQuestions}
                eligibilityQuestionCheck={eligibilityQuestionResponse}
                onChangeEligibilityQuestion={onChangeEligibilityQuestion}
              />
            )}
          </SkeletonLoadingContainer>
        </React.Fragment>
      </React.Fragment>
    );
  };

  const loadingComponent = () => {
    return (
      <div className="border border-radius section__container">
        <ActivityIndicator
          className="loader--clinic"
          message={getString('loading')}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="clinic-and-timing">
        {loading ? loadingComponent() : generalContent()}
      </div>
    </div>
  );
};

export default PatientEligibility;
