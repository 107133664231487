import React, { useState, useEffect, useMemo } from 'react';

import {
  useDetailContext,
  useGroupAppointmentContext,
  useApplicationContext,
  useSelectionsContext,
} from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';

import { CovidPageNames } from 'constants/pages';
import AlertMessage from '../../../common/AlertMessage';
import regexPattern from 'constants/regexPattern';

import { toast } from 'util/toast';
import DateTimeUtils from 'util/DateAndTime';
import { limitChars } from 'util/TextFormat';
import { mergeArrayOfArraysByKey } from 'util/array';
import { getLanguageIdByLanguageCode, getString } from 'util/lang';

import {
  ValidatePatientInfo,
  validateGuardianInfo,
} from '../../PatientInformation/container/PatientInfoValidation';

import Button from 'components/common/Button';
import PatientEligibility from './PatientEligibility';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import {
  makeDefaultNavLinks,
  makeGroupAppointmentNavLinks,
  makeDefaultNavLinksWithNoPaymentOption,
} from 'components/common/NavBar/navLinks';
import CancelAppointment from 'components/COVID/common/CancelAppointment';
import { ServicesInfoContainer } from 'components/COVID/common/ServicesInfo';
import SkeletonLoadingContainer from 'components/common/SkeletonLoadingContainer';

import AboutTenant from '../presentation/AboutTenant';
import {
  SideNavBar,
  Navigations,
  Menu,
  SubMenu,
  Panel,
  Tabs,
} from '../presentation/SideNavBar';
import PatientInfoWrapper from 'components/COVID/views/PaymentInfo';
import HealthScreening from '../presentation/HealthScreening';
import EligibilityInfo from '../presentation/EligibilityInfo';
import PatientInformation from '../presentation/PatientInformation';
import SelfDeclaration from '../../HealthScreening/presentation/SelfDeclaration';
import VaccineInformation from '../../HealthScreening/presentation/VaccineInformation';
import { GroupAppointmentwrapper } from './GroupAppointmentWrapper';
import styles from './PatientEligibilityWrapper.module.scss';

import { v4 as uuidV4 } from 'uuid';
import { changeTitle } from 'util/siteInfo';

export const useScreeningAndEligibility = () => {
  const {
    details,
    setDetails,
    fetchLocalizedWebContents,
    fetchEligibilityQuestions,
    fetchScreeningQuestions,
    setPageComplete,
    onSelfDeclarationChange,
    onChangeEligibilityQuestion,
    onHealthQuestionAnswerChange,
    registerUser,
    saveEligibilityResponse,
    handleFirstAgreement,
    postServiceDataSetup,
    waitlistPatient,
  } = useDetailContext();

  const [allowHealthService, setAllowHealthService] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { selectedServices, selectedLocationId, appLanguageCode, appSettings } =
    details;

  const languages = appSettings.languages;
  const languageId = getLanguageIdByLanguageCode(appLanguageCode, languages);

  useEffect(() => {
    if (languageId && selectedServices.length) {
      fetchLocalizedWebContents(languageId);
      fetchEligibilityQuestions(languageId);
      fetchScreeningQuestions(languageId);
    }
  }, [languageId, selectedServices, selectedLocationId]);

  useEffect(() => {
    if (details && details.screeningQuestions?.length === 0) {
      setAllowHealthService(false);
    }
  }, [details.screeningQuestions]);

  const setFirstPageLoaded = () => {
    setDetails({ firstPageLoaded: true });
  };

  const setPagesInComplete = () => {
    setPageComplete({
      [CovidPageNames.PatientEligibility]: false,
      [CovidPageNames.PatientInfo]: false,
      [CovidPageNames.Confirmation]: false,
    });
  };

  const setAddPatientPageIncomplete = () => {
    setPageComplete({
      [CovidPageNames.AddPatients]: false,
    });
  };

  const saveInfo = async (
    eligibilityQuestions,
    selfDeclarationCheck,
    isGroupAppointment,
  ) => {
    await saveEligibilityResponse(eligibilityQuestions);
    await handleFirstAgreement(selfDeclarationCheck);

    // Updates from patientInfo as the component is shared in case of group
    if (!isGroupAppointment) {
      setPageComplete({
        [CovidPageNames.PatientEligibility]: true,
      });
    }
  };

  const setSetupPhase = (isSetupPhase) => {
    setDetails({ setupPhase: isSetupPhase });
  };

  return {
    saveInfo: saveInfo,
    waitlistPatient: waitlistPatient,
    details: details,
    ScreeningAndEligibilityProps: {
      onSelfDeclarationChange: onSelfDeclarationChange,
      onChangeEligibilityQuestion: onChangeEligibilityQuestion,
      setFirstPageLoaded: setFirstPageLoaded,
      postServiceDataSetup: postServiceDataSetup,
      setPagesInComplete: setPagesInComplete,
      registerUser: registerUser,
      setSetupPhase: setSetupPhase,
      allowHealthService: allowHealthService,
      showAlert: showAlert,
      setShowAlert: setShowAlert,
      setAllowHealthService: setAllowHealthService,
      onHealthQuestionAnswerChange: onHealthQuestionAnswerChange,
      setAddPatientPageIncomplete: setAddPatientPageIncomplete,
    },
  };
};

export const contactTypes = {
  email: 'email',
  mobileNo: 'mobileNo',
  emailAndMobileNo: 'emailAndMobileNo',
};

/**
 *
 * @desc ScreeningAndEligibility is Parent component which is source of data for all of its child components that includes ServiceInfo Container, About, Screening Info, Eligibility info, Patient Eligibility, Patient Screening Questions, Self Declaration and Button Components. This components scaffolds data and passes it down to its children as props
 */
export const ScreeningAndEligibility = ({
  toNextPage,
  goBack,
  organizationId,
  appointmentId,
  isSelfDeclarationDisabled = false,
  disableEdit = false,
  confirmEditService = false,
  isPatientInfoDisabled = true,
  showSelfDeclaration = true,
  isWaitlist = false,
  goToNextMenuGroupSideBar = () => {},
  goToPreviousMenuGroupSideBar = () => {},
  disabledFieldStatus = {},
}) => {
  changeTitle('Eligibility and Screening');
  const { setDetails } = useDetailContext();
  const { removePatientFromGroup } = useGroupAppointmentContext();

  const { ScreeningAndEligibilityProps, saveInfo, details, waitlistPatient } =
    useScreeningAndEligibility();

  const [patientInfoErrors, setPatientInfoErrors] = useState({});
  const [guardianInfoErrors, setGuardianInfoErrors] = useState({});
  const [isWaitlistLoading, setIsWaitlistLoading] = useState(false);
  const [contactType, setContactType] = useState(contactTypes.emailAndMobileNo);
  const waitlistValidationFields = isWaitlist
    ? contactType === contactTypes.emailAndMobileNo
      ? [contactTypes.email, contactTypes.mobileNo]
      : contactType === contactTypes.email
        ? [contactTypes.email]
        : [contactTypes.mobileNo]
    : [];

  const {
    services,
    patientInfo,
    isFirstAgree,
    selfDeclaration,
    currentPatientIndex,
    isGroupAppointment,
    selectedServices,
    screeningQuestions,
    eligibilityResponse,
    localizedWebContents,
    eligibilityQuestions,
    isEligibilityQuestionFetching,
    isLocalizedWebContentsFetching,
  } = details;

  const onSubmit = async (eligibilityQuestions, selfDeclarationCheck) => {
    await saveInfo(
      eligibilityQuestions,
      selfDeclarationCheck,
      isGroupAppointment,
    );

    if (!isPatientInfoDisabled) {
      const hasError = validatePatientInformation();

      if (hasError) return;
    }

    setIsWaitlistLoading(true);

    try {
      if (isWaitlist) {
        await waitlistPatient();
      }
      if (isGroupAppointment && !isWaitlist) {
        goToNextMenuGroupSideBar();
      } else {
        toNextPage();
      }
    } catch (err) {
      setIsWaitlistLoading(false);
      toast.error({
        title: '',
        message: 'Could not add patient to waitlist for selected service(s).',
      });
    }
  };

  const [primaryServiceContent = {}] = localizedWebContents;
  const { about = null } = primaryServiceContent;

  /**
   * Eligibility Information Component
   *
   * This function extracts eligibility information from an array of localized web contents, associates it with corresponding services, and filters out null entries.
   *
   * @param {Array} localizedWebContents - An array of localized web contents.
   * @param {Array} selectedServices - An array of selected services.
   * @param {Object} services - An object containing service information.
   * @returns {Array} - An array of eligibility information entities.
   */
  const eligibilityInformation = localizedWebContents
    .map((lwc, index) => {
      const serviceId = selectedServices[index].serviceId;
      const serviceName = services.byId[serviceId].name;
      if (lwc?.eligibilityText) {
        return {
          service: serviceName,
          eligibilityText: lwc?.eligibilityText,
        };
      }
      return null;
    })
    .filter((lc) => lc !== null);

  /**
   * ScreeningInformationChild Component
   *
   * This code extracts screening information from an array of localizedWebContents,
   * associates it with corresponding services, and filters out null entries.
   *
   * @param {Array} localizedWebContents - An array of localized web contents.
   * @param {Array} selectedServices - An array of selected services.
   * @param {Object} services - An object containing service information.
   * @returns {Array} - An array of screening information entities.
   */
  const screeningInformation = localizedWebContents
    .map((lwc, index) => {
      const serviceId = selectedServices[index].serviceId;
      const serviceName = services.byId[serviceId].name;
      if (lwc?.screeningInformation) {
        return {
          service: serviceName,
          screeningInformation: lwc?.screeningInformation,
        };
      }
      return null;
    })
    .filter((lc) => lc !== null);

  /**
   * SelfDeclarationChild Component
   *
   * This component handles the self-declaration checkbox and related methods.
   *
   * @states
   * selfDeclarationCheck: Boolean state to define the checkbox's state.
   *
   * @methods
   * handleSelfDeclarationhandler: Handles form input and validates the selfDeclaration data.
   * selfDeclarationCheckOnChange: Handles changes on the self-declaration checkbox.
   *
   */
  const [selfDeclarationCheck, setSelfDeclarationCheck] = useState(
    isFirstAgree[currentPatientIndex] ?? false,
  );
  const { firstName, lastName, middleName } =
    selfDeclaration[currentPatientIndex] ?? {};
  // Determine if self-declaration data is valid
  const isSelfDeclarationValid = !!(firstName && lastName);
  /**
   * Handles form input for self-declaration data.
   *
   * @param {string} id - The identifier of the input field.
   * @param {string} value - The input value to be validated and processed.
   */
  const handleSelfDeclarationhandler = (id, value) => {
    if (value.match(regexPattern.nameField) || value === '') {
      ScreeningAndEligibilityProps.onSelfDeclarationChange(
        id,
        limitChars(value, 30),
      );
    }
  };
  /**
   * Handles changes on the self-declaration checkbox.
   *
   * @param {boolean} checked - The new state of the checkbox.
   */
  const selfDeclarationCheckOnChange = (checked) => {
    setSelfDeclarationCheck(checked);
    if (!checked) {
      ScreeningAndEligibilityProps.setPagesInComplete();
    }
  };

  /**
   * EligibilityCheck Component
   *
   * This component handles the eligibility checkbox and related methods.
   *
   * @states
   * eligibilityQuestionResponse: Boolean state to define the checkbox's state.
   * eligibilityCheck: Boolean that defines if some eligibility questions are checked to true
   *
   *
   * @methods
   * eligibilityCheckOnChange: Handles and set eligibility check boolean upon each eligibility question response change
   * onChangeEligibilityQuestion: Handles onchange property of each individual eligibility checkbox
   */
  const [eligibilityQuestionResponse, setEligibilityQuestionResponse] =
    useState(eligibilityResponse[currentPatientIndex] ?? {});
  const [eligibilityCheck, setEligibilityCheck] = useState(false);

  const finalEligibilityQuestions = mergeArrayOfArraysByKey(
    eligibilityQuestions,
    'id',
  );

  /**
   * Set up data on first load of the application.
   */
  const setUpClinicAndAppointment = async () => {
    await ScreeningAndEligibilityProps.postServiceDataSetup(
      organizationId,
      appointmentId,
    );
  };

  // Effects on Component Mount
  useEffect(() => {
    eligibilityCheckOnChange();
    setUpClinicAndAppointment();
    ScreeningAndEligibilityProps.setFirstPageLoaded(true);
  }, []);

  /**
   * This function checks the eligibility response and updates the eligibility check state.
   */
  const eligibilityCheckOnChange = (e) => {
    const eligibilityCheck = Object.values(eligibilityQuestionResponse).some(
      (response) => {
        return !!response;
      },
    );
    setEligibilityCheck(eligibilityCheck);
  };

  // Dependency Update for Eligibility
  useEffect(() => {
    eligibilityCheckOnChange();
  }, [eligibilityQuestionResponse]);

  const onChangeEligibilityQuestion = (
    key,
    value,
    finalEligibilityQuestions,
  ) => {
    const defaultResponse = {};
    finalEligibilityQuestions.forEach((question) => {
      defaultResponse[question.id] = false;
    });
    setEligibilityQuestionResponse((prevResponse) => ({
      ...defaultResponse,
      ...prevResponse,
      [key]: value,
    }));
    ScreeningAndEligibilityProps.setPagesInComplete();
  };

  /**
   *
   * @param {boolean} isSelfDeclarationValid - Boolean indicating the validity of self-declared form input.
   * @param {boolean} selfDeclarationCheck - Boolean indicating whether a checkbox is checked for self-declaration.
   * @param {boolean} eligibilityCheck - Boolean indicating if one of the eligible criteria is checked.
   * @param {Array} eligibilityQuestions - An array of eligibility questions.
   * @param {boolean} allowHealthService - Boolean indicating if health service is allowed.
   * @param {Array} screeningQuestions - An array of screening questions.
   * @returns Boolean that determines if the submit button is enabled.
   */
  const isPatientEligible = (
    isSelfDeclarationValid,
    selfDeclarationCheck,
    eligibilityCheck,
    eligibilityQuestions,
    allowHealthService,
    screeningQuestions,
  ) => {
    let selfDeclarationValid = isSelfDeclarationDisabled
      ? true
      : isSelfDeclarationValid && selfDeclarationCheck;

    let isEligible = selfDeclarationValid && !isWaitlistLoading;

    if (eligibilityQuestions.length) {
      isEligible = isEligible && eligibilityCheck;
    }

    if (screeningQuestions.length) {
      isEligible = isEligible && allowHealthService;
    }
    return isEligible;
  };

  //  An array, finalScreeningQuestions, which contains the merged objects based on the 'id' attribute.
  const finalScreeningQuestions = mergeArrayOfArraysByKey(
    screeningQuestions,
    'id',
  );

  /**
   * Validate Patient Information
   *
   * This function validates patient information including their personal details and, if applicable, guardian information.
   * It checks for errors in the patient and guardian data and returns whether any errors exist.
   *
   * @returns {boolean} - `true` if there are validation errors, `false` if the data is valid.
   */
  const validatePatientInformation = () => {
    const currentPatient = patientInfo[currentPatientIndex] ?? {};
    const patientData = {
      firstName: currentPatient.firstName,
      lastName: currentPatient.lastName,
      middleName: currentPatient.middleName,
      birthYear: currentPatient.birthYear,
      birthMonth: currentPatient.birthMonth,
      birthDay: currentPatient.birthDay,
      gender: currentPatient.gender,
      race: currentPatient.race,
      ethnicity: currentPatient.ethnicity,
      zipCode: currentPatient.zipCode,
      mobileNo: currentPatient.mobileNo,
      email: currentPatient.email,
    };
    let { errors: patientErrors } = ValidatePatientInfo(
      patientData,
      true,
      waitlistValidationFields,
    );
    let guardianErrors = {};

    setPatientInfoErrors(patientErrors);
    const birthDate = `${currentPatient.birthMonth}-${currentPatient.birthDay}-${currentPatient.birthYear}`;
    const isUnderNineteen = DateTimeUtils.isUnderNineteen(birthDate);

    if (isUnderNineteen) {
      const guardianData = {
        guardianFirstName: currentPatient.guardianFirstName,
        guardianLastName: currentPatient.guardianLastName,
        guardianMiddleName: currentPatient.guardianMiddleName,
      };
      guardianErrors = validateGuardianInfo(guardianData)?.errors;
      setGuardianInfoErrors(guardianErrors);
    }

    const patientInfoErrorArray = Object.keys(patientErrors).filter(
      (key) => patientErrors[key],
    );
    const guardianInfoErrorArray = Object.keys(guardianErrors).filter(
      (key) => guardianErrors[key],
    );

    const hasError =
      !!patientInfoErrorArray.length || !!guardianInfoErrorArray.length;

    return hasError;
  };

  const removeCurrentPatient = (currentIndex) => {
    removePatientFromGroup(currentIndex);
    if (patientInfo.length === 1) {
      goBack();
      ScreeningAndEligibilityProps.setAddPatientPageIncomplete();
    }
    if (currentPatientIndex === patientInfo.length - 1) {
      goToPreviousMenuGroupSideBar();
    }
    if (currentPatientIndex > 0) {
      setDetails({ currentPatientIndex: currentPatientIndex - 1 });
    }
  };

  return (
    <>
      {(!isGroupAppointment || isWaitlist) && (
        <BackToPage
          text={getString('backToScheduleAppointmentPage')}
          onClick={goBack}
        />
      )}

      <ServicesInfoContainer
        confirmEditService={confirmEditService}
        disableEdit={disableEdit}
      />

      <SkeletonLoadingContainer loading={isLocalizedWebContentsFetching}>
        <AboutTenant about={about} />
      </SkeletonLoadingContainer>

      <SkeletonLoadingContainer loading={isLocalizedWebContentsFetching}>
        <EligibilityInfo eligibilityInformation={eligibilityInformation} />
      </SkeletonLoadingContainer>

      <SkeletonLoadingContainer loading={isLocalizedWebContentsFetching}>
        <VaccineInformation screeningInformation={screeningInformation} />
      </SkeletonLoadingContainer>

      <SkeletonLoadingContainer loading={isEligibilityQuestionFetching}>
        <PatientEligibility
          details={details}
          setEligibilityQuestionResponse={setEligibilityQuestionResponse}
          finalEligibilityQuestions={finalEligibilityQuestions}
          onChangeEligibilityQuestion={onChangeEligibilityQuestion}
          eligibilityQuestionResponse={eligibilityQuestionResponse}
        />
        {!!screeningQuestions.length && (
          <>
            <h2 className="mt-5x">Screening Questions</h2>
            <div className="section__container border-radius border">
              <HealthScreening
                details={details}
                onHealthQuestionAnswerChange={
                  ScreeningAndEligibilityProps.onHealthQuestionAnswerChange
                }
                showAlert={ScreeningAndEligibilityProps.showAlert}
                allowHealthService={
                  ScreeningAndEligibilityProps.allowHealthService
                }
                setShowAlert={ScreeningAndEligibilityProps.setShowAlert}
                screeningQuestions={finalScreeningQuestions}
                setAllowHealthService={
                  ScreeningAndEligibilityProps.setAllowHealthService
                }
                isGroupAppointment={isGroupAppointment}
                currentPatientIndex={currentPatientIndex}
                removeCurrentPatient={removeCurrentPatient}
              />
            </div>
          </>
        )}

        {showSelfDeclaration && (
          <>
            <h2 className="mt-5x">{getString('selfDeclaration')}</h2>
            <div className="section__container border-radius border mb-5x">
              <SelfDeclaration
                selfDeclarationHandler={handleSelfDeclarationhandler}
                firstName={firstName}
                lastName={lastName}
                middleName={middleName}
                isAgree={selfDeclarationCheck}
                handleAgreement={(event) => {
                  selfDeclarationCheckOnChange(event.target.checked);
                }}
                disabledFieldStatus={disabledFieldStatus}
              />

              <AlertMessage
                dataqa="self-declaration-alert"
                className="mb-0x"
                isVisible={true}
                type="info"
                message={getString('selfDeclarationAlert')}
              />
            </div>
          </>
        )}

        {!isPatientInfoDisabled && (
          <>
            <h2 className="mt-5x">{getString('patientInformation')}</h2>
            <div className="section__container border-radius border mb-5x">
              <PatientInformation
                patientInfoErrors={patientInfoErrors}
                guardianInfoErrors={guardianInfoErrors}
                setGuardianInfoErrors={setGuardianInfoErrors}
                setPatientInfoErrors={setPatientInfoErrors}
                isWaitlist={isWaitlist}
                contactType={contactType}
                setContactType={setContactType}
              />
            </div>
          </>
        )}
      </SkeletonLoadingContainer>

      <div className="row">
        {isGroupAppointment && !isWaitlist ? (
          <div
            className={`${styles['group-next-button']} col-12-sm order-2-sm`}
          >
            <Button
              label={'Next'}
              title={'Next'}
              onClick={() => {
                onSubmit(eligibilityQuestionResponse, selfDeclarationCheck);
              }}
              isEnabled={isPatientEligible(
                isSelfDeclarationValid,
                selfDeclarationCheck,
                eligibilityCheck,
                finalEligibilityQuestions,
                ScreeningAndEligibilityProps.allowHealthService,
                finalScreeningQuestions,
              )}
              isLoading={false}
              dataqa="go-to-screening"
            />
          </div>
        ) : (
          <>
            <div className="col-3-sm order-1">
              <CancelAppointment />
            </div>
            <div className={`col-9-sm order-2-sm`}>
              <Button
                label={getString('continue')}
                title={getString('continueTitle')}
                onClick={() => {
                  onSubmit(eligibilityQuestionResponse, selfDeclarationCheck);
                }}
                isEnabled={isPatientEligible(
                  isSelfDeclarationValid,
                  selfDeclarationCheck,
                  eligibilityCheck,
                  finalEligibilityQuestions,
                  ScreeningAndEligibilityProps.allowHealthService,
                  finalScreeningQuestions,
                )}
                isLoading={false}
                dataqa="go-to-screening"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const ScreeningAndEligibilityPage = () => {
  const nav = useNavigationHelper();
  const { isGroupAppointment } = useGroupAppointmentContext();
  const { organizationId, appointmentId } = useAppParams();
  const { isPaymentOptionEnabled } = useApplicationContext();

  const {
    details: {
      pageComplete,
      selectedLocationId,
      selectedServices,
      currentPatientIndex,
      patientInfo,
    },
    setDetails,
  } = useDetailContext();

  const toNextPage = () => {
    nav.toPatientInfo();
  };

  const goBack = () => {
    nav.goBack();
  };

  const navLinks = useMemo(
    () =>
      isGroupAppointment
        ? makeGroupAppointmentNavLinks(pageComplete, organizationId)
        : isPaymentOptionEnabled
          ? makeDefaultNavLinks(pageComplete, organizationId)
          : makeDefaultNavLinksWithNoPaymentOption(
              pageComplete,
              organizationId,
            ),
    [organizationId, pageComplete, appointmentId],
  );

  const patientListForNavBar = useMemo(() => {
    return patientInfo.reduce((acc, item) => {
      acc.push(`${item.firstName} ${item.lastName}`);
      return acc;
    }, []);
  }, [patientInfo]);

  const patientListKeys = useMemo(() => {
    return patientInfo.reduce((acc, item) => {
      acc.push(uuidV4());
      return acc;
    }, []);
  }, []);

  if (!selectedServices.length && !selectedLocationId) {
    nav.resetToFirstPage();
    return null;
  }

  return (
    <PageWrapper
      showNavMenu
      navLinks={navLinks}
      isGroupContainer={isGroupAppointment}
    >
      {!isGroupAppointment && (
        <ScreeningAndEligibility
          confirmEditService={true}
          organizationId={organizationId}
          id={appointmentId}
          goBack={goBack}
          toNextPage={toNextPage}
        />
      )}
      {isGroupAppointment && (
        <>
          <SideNavBar
            options={{
              allowNavigation: pageComplete[CovidPageNames.PatientEligibility],
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  height: '100%',
                  position: 'sticky',
                  zIndex: 490,
                  top: '65px',
                }}
              >
                <Navigations>
                  {patientListForNavBar.map((ls, idx) => {
                    return (
                      <Menu
                        label={ls}
                        key={patientListKeys[idx]}
                        menuIndex={idx}
                        onClick={() => {
                          setDetails({ currentPatientIndex: idx });
                        }}
                      >
                        <SubMenu
                          label={'Eligibility & Health Screening'}
                          subMenuIndex={0}
                        ></SubMenu>
                        <SubMenu
                          label={'Patient Info'}
                          subMenuIndex={1}
                        ></SubMenu>
                      </Menu>
                    );
                  })}
                </Navigations>
              </div>
              <Tabs>
                {patientListForNavBar.map((ls, idx) => {
                  return (
                    <React.Fragment key={patientListKeys[idx]}>
                      <Panel menuIndex={idx} subMenuIndex={0}>
                        <p className={styles['navigation-panel-header']}>
                          {getString('groupEligibilityHeader', {
                            patientName: (
                              <span
                                className={
                                  styles['navigation-panel-header-patient-name']
                                }
                              >
                                {ls}
                              </span>
                            ),
                          })}
                        </p>
                        <GroupAppointmentwrapper>
                          <ScreeningAndEligibility
                            confirmEditService={true}
                            organizationId={organizationId}
                            id={appointmentId}
                            goBack={goBack}
                            toNextPage={toNextPage}
                          />
                        </GroupAppointmentwrapper>
                      </Panel>
                      <Panel menuIndex={idx} subMenuIndex={1}>
                        <p className={styles['navigation-panel-header']}>
                          {getString('groupPatientInfoHeader', {
                            patientName: (
                              <span
                                className={
                                  styles['navigation-panel-header-patient-name']
                                }
                              >
                                {ls}
                              </span>
                            ),
                          })}
                        </p>
                        <GroupAppointmentwrapper>
                          <PatientInfoWrapper
                            isGroupAppointment={isGroupAppointment}
                          />
                        </GroupAppointmentwrapper>
                      </Panel>
                    </React.Fragment>
                  );
                })}
              </Tabs>
            </div>
          </SideNavBar>
        </>
      )}
    </PageWrapper>
  );
};
