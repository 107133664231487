import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { iconCheckboxChecked, iconCheckboxUnchecked } from 'assets/images';
import LineBreakPdfView from '../../common/LineBreak/LineBreakPdfView';
import { homeAssessmentToQuestionMap } from './HomeAssessmentAndSafety';

const HomeAssessmentAndSafetyPdfView = ({ homeAssessmentData }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Home Assessment and Safety</Text>

      {homeAssessmentData.map((document) => {
        const { key, checked, additionalInfo } = document;
        const question = homeAssessmentToQuestionMap[key];

        return (
          <View key={key} style={styles.documentRow}>
            {/* Display checkbox checked/unchecked based on document.checked */}
            <View style={styles.checkboxWrap}>
              <Image
                src={checked ? iconCheckboxChecked : iconCheckboxUnchecked}
                style={styles.checkboxImg}
                alt={checked ? 'checked' : 'unchecked'}
              />
              <Text style={styles.documentLabel}>{question}</Text>
            </View>

            {/* Display additionalInfo if 'other' */}
            {key === 'other' && additionalInfo && (
              <Text style={styles.additionalInfo}>{additionalInfo}</Text>
            )}
          </View>
        );
      })}

      <LineBreakPdfView />
    </View>
  );
};

// Styles for PDF rendering
const styles = StyleSheet.create({
  title: {
    fontSize: 11,
    fontWeight: 600,
    marginBottom: 10,
  },
  info: {
    fontSize: 12,
    marginBottom: 12,
  },
  documentRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 6,
  },
  checkboxWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  checkboxImg: {
    width: 12,
    height: 12,
    marginRight: 6,
  },
  documentLabel: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
  },
  additionalInfo: {
    fontSize: 10,
    color: '#555',
    marginLeft: 20,
  },
});

export default HomeAssessmentAndSafetyPdfView;
