import React from 'react';
import moment from 'moment';
import { UiPen } from 'vyaguta-icons/ui';
import { FiCheck } from 'vyaguta-icons/fi';

import { getString } from 'util/lang';
import { convertTo12HourTimeFormatWithAmPm } from 'util/DateAndTime';

const getSelectedScheduleString = (date, time) => {
  const dateString = `${moment(date).format('ddd, MMM D')}`;
  const timeString = convertTo12HourTimeFormatWithAmPm(time);
  return getString('dateAtTime', dateString, timeString);
};

const AppointmentInformation = ({
  appointmentType,
  appointment,
  doseNumber,
  onDateTimeEdit,
  isFulfilled,
}) => {
  const appointmentString = getString('selectedAppointment', appointmentType);
  const scheduledString = getSelectedScheduleString(
    appointment.date,
    appointment.time,
  );

  const editEnabled = typeof onDateTimeEdit === 'function';

  const handleDateTimeEdit = () => {
    if (!isFulfilled && editEnabled) {
      onDateTimeEdit(doseNumber);
    }
  };

  return (
    <div
      className="selected-date my-4x"
      aria-label={`${appointmentString} ${scheduledString}`}
    >
      <h3 className="mb-0x-md">{appointmentString}</h3>
      <button
        className={`btn btn-primary--outline w-100-sm ${isFulfilled || !editEnabled ? 'btn--fulfilled' : ''}`}
        onClick={handleDateTimeEdit}
        data-qa="btn-select-date"
      >
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div className="clinic__name">{scheduledString}</div>
          </div>
          <div className="d-flex align-items-center">
            {!editEnabled ? null : isFulfilled ? (
              <FiCheck size={24} className="btn__icon" />
            ) : (
              <UiPen size={24} className="btn__icon" />
            )}
          </div>
        </div>
      </button>
    </div>
  );
};

export default AppointmentInformation;
