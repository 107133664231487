import { printFontFamily } from 'constants/dme';
import { StyleSheet } from '@react-pdf/renderer';

export const useStyles = StyleSheet.create({
  container: {
    height: '100vh',
  },
  pdfViewer: {
    height: '100%',
    width: '100%',
  },
  page: {
    padding: '24px 16px',
    fontSize: '11px',
  },

  title: {
    fontSize: 16,
    fontFamily: printFontFamily,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
  },
  textWrap: {
    marginBottom: 16,
    marginTop: 8,
  },
  text: {
    fontSize: 12,
    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  textSemibold: {
    fontSize: 12,
    fontFamily: printFontFamily,
    fontWeight: 600,
  },
  subText: {
    fontSize: 11,
    fontFamily: printFontFamily,
    fontWeight: 400,
    marginTop: 4,
  },
  inputField: {
    borderBottom: '1px solid #201F1E',
    fontSize: 10,
    marginTop: 16,
    paddingBottom: 8,
    width: 170,
  },
  acceptanceCriteriaWrap: {
    borderTop: '1px solid #E1DFDD',
    paddingTop: 16,
  },
  footerWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #000000',
    paddingTop: 4,
    marginTop: '48px',
    position: 'absolute',
    bottom: 16,
    left: 16,
  },
  footerText: {
    fontSize: 10,
    fontFamily: printFontFamily,
    fontWeight: 400,
  },
  footerTextBold: {
    fontSize: 10,
    fontFamily: printFontFamily,
    fontWeight: 700,
  },
});
