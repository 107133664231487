import { getString } from "util/lang";

export const ABNFormTitle = ({ abnData,primaryPayerName }) => {
    return (
        <>
            <h4 className='heading heading--light mb-6x mt-6x'>{getString('dme.documentation.abnForm.abnNonCoverage')}</h4>
            <p className='desc mb-2x'>
                NOTE: {getString(`${abnData}.description`)}
            </p>
            <p className='desc mb-6x'>
                {getString(`${abnData}.note`, { primaryPayerName })}
            </p>
        </>
    )
}