import config from '../config';
import * as http from '../util/http';
import dateTimeUtil from '../util/DateAndTime';
import { sortSlotsByTime } from 'components/COVID/views/NewClinicAndTiming/helpers/appointmentDates';

/**
 * Fetch clinic shot details for a clinic from sub-scheduling system api.
 *
 * @param {string} locationId : Clinic Guid
 */
export async function getClinicDaysByClinicId(
  locationId,
  subServiceIds,
  timeGap,
  clinicalServiceCode,
  minDate,
) {
  const url = config.entity.clinicSlots;
  const {
    data: { data },
  } = await http.get(url, {
    params: {
      subServiceIds,
      clinicId: locationId,
      timeGap: timeGap,
      clinicalServiceCode,
      minDate,
    },
  });

  const availableDays = addDateStringToClinicDaysForSubScheduling(data);

  return availableDays;
}

export async function getAvailableSlots({
  subServiceIds,
  clinicId,
  date,
  slotsCount,
  clinicTimeGap,
}) {
  const url = config.entity.clinicBlocks;

  const {
    data: { data },
  } = await http.get(url, {
    params: {
      subServiceIds: subServiceIds,
      clinicId,
      date,
      slotsCount,
      timeGap: clinicTimeGap,
    },
  });

  return sortSlotsByTime(data);
}

/**
 * Add avalible timeslots to clinic day based on unavalible slots that it holds.
 *
 * @param {{slot_date: string}[]} clinicDays : Array of clinic Days.
 * @returns {{ slot_date: string, clinicDate: Date, clinicDateString: string }[]}
 */
export function addDateStringToClinicDaysForSubScheduling(clinicDays) {
  const clinicSlotDays = clinicDays.map((clinicDay) => {
    const clinicDate = dateTimeUtil.extractDateFromDateObject(
      clinicDay.slot_date,
    );
    clinicDay.clinicDate = clinicDate;
    clinicDay.clinicDateString = clinicDate.toString();

    return clinicDay;
  });

  return clinicSlotDays;
}
