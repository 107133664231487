export const BASE_URL = process.env.REACT_APP_BASE_URL;

const INACTIVE_SESSION_TIME_IN_MS =
  process.env.REACT_APP_INACTIVE_SESSION_TIME_IN_MS ?? 90 * 1000;
const INACTIVE_DEBOUNCE_TIME_IN_MS =
  process.env.REACT_APP_INACTIVE_DEBOUNCE_TIME_IN_MS ?? 250;

const END_POINTS = {
  clinics: 'clinics',
  clinicDay: 'clinicdays/clinics',
  appointment: 'appointment',
  unavalibleslots: 'unavalibleslots',
  insuranceOrganization: 'insurance-organization',
  appSettings: 'app-setting',
  localizedWebContents: 'localized-web-contents',
  accounts: 'organization',
  screening: 'screening',
  contactUs: 'contact/contactUs',
  waitlist: 'mvs_waitlist',
  eligibility: 'eligibility',
  eligibilityQuestions: 'eligibility-questions',
  securityVerification: 'verification',
  securityVerificationPendingGroupAppointment: 'verification-group-pending',
  securityVerificationGroupAppointment: 'group/verification',
  healthcareServices: 'healthcare-services',
  healthcareCategories: 'healthcare-categories',
  languages: 'languages',
  codeRegistration: 'code-registration',
  service: 'services',
  contact: 'contact',
  testReport: 'test-report',
  county: 'county',
  state: 'state',
  rescheduleVerification: 'appointment/valid-reschedule',
  groupAppointment: 'group',
  insuranceDetail: 'insurance-detail',
};

const MAPBOX_AUTOFILL_CONFIG = {
  MAX_API_CALLS: 30,
  MAX_SESSION_TIME_SECONDS: 3600, // 1 hour
  MAX_SESSION_TIME_BUFFER_SECONDS: 10,
  MAPBOX_AUTOFILL_SUGGEST_API_URL:
    'https://api.mapbox.com/autofill/v1/suggest/',
  MAPBOX_AUTOFILL_RETRIEVE_API_URL:
    'https://api.mapbox.com/autofill/v1/retrieve/',
  MAPBOX_AUTOFILL_SESSION_KEY: '@mapbox/autofill/session',
  DEFAULT_SUGGEST_OPTIONS: {
    limit: 3,
    types: 'address',
    country: 'us',
    language: 'en',
  },
};

const CONFIG = {
  env: process.env.NODE_ENV ?? 'development',
  baseUrl: BASE_URL,
  design: process.env.REACT_APP_DESIGN === 'true',
  mapKey: process.env.REACT_APP_MAPBOX_KEY, // TODO: Remove with unusued code
  mapBoxKey: process.env.REACT_APP_MAPBOX_KEY,
  appInsightInstrumentationKey:
    process.env.REACT_APP_APP_INSIGHT_INSTRUMENTATION_KEY,
  allowEligibilityBypass: true,
  entity: {
    clinics: `${BASE_URL}/v2/${END_POINTS.clinics}`,
    clinicSlots: `${BASE_URL}/v2/${END_POINTS.clinics}/slots/patient-portal`,
    clinicBlocks: `${BASE_URL}/v2/${END_POINTS.clinics}/blocks/patient-portal`,
    appointmentById: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}`,
    appointmentByGroup: (groupAppointmentId: string) =>
      `${BASE_URL}/v2/appointment/${groupAppointmentId}/group`,
    confirmFollowupAppointment: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/confirmFollowup`,
    verifyAppointmentById: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/valid-reschedule`,
    rescheduleAppointmentById: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/reschedule`,
    rescheduleGroupAppointmentByGroupAppointmentId: (
      groupAppointmentId: string,
    ) => `${BASE_URL}/v2/appointment/${groupAppointmentId}/group/reschedule`,
    followupStatus: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/followup-status`,
    groupAppointmentStatus: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/group-appointment-status`,
    fetchOrganizationById: (organizationId: string) =>
      `${BASE_URL}/v2/organization/${organizationId}`,
    getContactByAppointmentId: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/getContact`,
    updateAppointment: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}`,
    confirmGroupAppointment: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/confirmGroupAppointment`,
    waitlistPatient: `${BASE_URL}/v2/waitlist`,
    createAppointment: `${BASE_URL}/v2/${END_POINTS.appointment}`,
    createBatchAppointment: `${BASE_URL}/v2/appointment/${END_POINTS.groupAppointment}`,
    payerlist: `${BASE_URL}/v2/${END_POINTS.insuranceOrganization}/payerlist/service`,
    appSettings: `${BASE_URL}/v2/${END_POINTS.appSettings}`,
    localizedWebContents: `${BASE_URL}/v2/${END_POINTS.localizedWebContents}`,
    screeningQuestions: `${BASE_URL}/v2/${END_POINTS.screening}`,
    eligibility: `${BASE_URL}/v2/${END_POINTS.eligibility}/insurance`,
    eligibilityQuestions: `${BASE_URL}/v2/${END_POINTS.eligibilityQuestions}`,
    securityVerificationGroupAppointment: (groupAppointmentId: string) =>
      `${BASE_URL}/v2/appointment/${groupAppointmentId}/${END_POINTS.securityVerificationGroupAppointment}`,
    securityVerification: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/${END_POINTS.securityVerification}`,
    securityVerificationPendingGroupAppointment: (appointmentId: string) =>
      `${BASE_URL}/v2/appointment/${appointmentId}/${END_POINTS.securityVerificationPendingGroupAppointment}`,
    languages: `${BASE_URL}/v2/${END_POINTS.languages}`,
    service: `${BASE_URL}/v2/${END_POINTS.service}`,
    findContact: `${BASE_URL}/v2/${END_POINTS.contact}/find`,
    updateContactNotificationPreferences: (contactId: string) =>
      `${BASE_URL}/v2/contact/${contactId}/notification-preferences`,
    counties: `${BASE_URL}/v2/${END_POINTS.county}`,
    states: `${BASE_URL}/v2/${END_POINTS.state}`,
    testReport: `${BASE_URL}/${END_POINTS.testReport}`,
    contactUs: `${BASE_URL}/v2/${END_POINTS.contactUs}`,
    waitlist: `${BASE_URL}/${END_POINTS.waitlist}`,
    codeRegistration: `${BASE_URL}/${END_POINTS.codeRegistration}`,
    uploadFile: (appointmentId: string) =>
      `${BASE_URL}/v2/${END_POINTS.appointment}/${appointmentId}/uploadfile`,
    getTestReport: (appointmentId: string) =>
      `${BASE_URL}/v2/${END_POINTS.testReport}/${appointmentId}`,
    uploadInsuranceFiles: (insuranceId: string) =>
      `${BASE_URL}/v2/${END_POINTS.appointment}/${insuranceId}/uploadinsurance`,
    getDeliveryTicket: (productOrderId: string, deliveryTicketId: string) =>
      `/v2/product-order/delivery-ticket/${productOrderId}/${deliveryTicketId}`,
    getDeliveryPacket: (productOrderId: string) =>
      `/v2/product-order/delivery-packet/${productOrderId}`,
    verifyUser: (orderId: string) =>
      `/v2/product-order/patient/verification/${orderId}`,
    verifyStaff: (contactId: string, orderId: string) =>
      `/v2/product-order/staff/verification/${contactId}/${orderId}`,
    shareDeliveryPacketPdf: (productOrderId: string) =>
      `/v2/product-order/share/delivery-packet/${productOrderId}`,
    shareDeliveryTicketPdf: (productOrderId: string) =>
      `/v2/product-order/share/delivery-ticket/${productOrderId}`,
    getRequiredDocument: (productOrderId: string) =>
      `/v2/product-order/required-document/${productOrderId}`,
    getPayerList: () =>
      `${BASE_URL}/v2/${END_POINTS.insuranceDetail}/payerlist`,
  },
  captchaKey: process.env.REACT_APP_CAPTCHA_KEY,
  inactiveSessionTime: parseFloat(`${INACTIVE_SESSION_TIME_IN_MS}`),
  inactiveDebounceTime: parseFloat(`${INACTIVE_DEBOUNCE_TIME_IN_MS}`),
  captchaEnabled: process.env.REACT_APP_CAPTCHA_ENABLED === 'true',
  minAgeEligibility: 12,
  accordionTransitionTime: 400,
  mapboxAutofillConfig: MAPBOX_AUTOFILL_CONFIG,
};

export default CONFIG;
