import WAIT_LIST_STATUS from 'constants/waitlistStatus';

/**
 *
 * @param {*} globalWaitlistStatus
 * @param {*} serviceWaitlistStatus
 * @returns true if waitlist is enabled globally and for selected service
 * @returns false if waitlist is disabled globally or for selected service
 */
export const checkWaitlistStatus = (
  globalWaitlistStatus,
  serviceWaitlistStatus,
) => {
  if (
    globalWaitlistStatus === WAIT_LIST_STATUS.ENABLED_WHEN_NO_APPOINTMENTS &&
    serviceWaitlistStatus
  )
    return true;
  return false;
};
