import React from 'react';

import { appHubImg } from 'assets/images';

export const Banner = () => {
  return (
    <div className="welcome-page__banner">
      <div className="row">
        <div className="col-6-md p-0x">
          <div className="welcome-page__banner-text">
            <span>Signetic </span>For Pharmacies
            <p className="welcome-page__banner-desc">
              Transform your pharmacy into a community health destination
            </p>
          </div>
        </div>
        <div class="col-6-md p-0x">
          <div className="welcome-page__banner-img">
            <img src={appHubImg} alt="app_hub_img" />
          </div>
        </div>
      </div>
    </div>
  );
};
