import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSettings, useDetailContext } from 'context';
import { useSecurityToken } from 'context/useSecurityToken';
import { usePatientInfo } from 'context/index';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { AppointmentStatuses } from 'constants/appointment';

import { FiInfo } from 'vyaguta-icons/fi';
import { InfoWithContact } from 'components/common/InfoWithContact';
import { CovidPageNames } from 'constants/pages';
import { useGroupStatusCheck } from '../../../../../context/useGroupAppointmentStatusCheck';
import { InvalidAppointmentMessage } from '../../../common/InvalidAppointmentMessage';
import { SecurityCheckServiceCode } from '../../SecurityCheckServiceCode/container/SecurityCheckServiceCode';

export const GroupRegisterVerify = () => {
  const { groupAppointmentId, organizationId } = useParams();
  const { setSecurityToken, resetSecurityToken } = useSecurityToken();
  const {
    rescheduleDataSetup: groupAppointmentDataSetup,
    setDetails,
    setPageComplete,
  } = useDetailContext();
  const nav = useNavigationHelper();
  const [isLoading, setIsLoading] = useState(false);
  const [isApptStatusValid, setIsApptStatusValid] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [dataSetupCompleted, setDataSetupCompleted] = useState(false);
  const { currentPatientInfo } = usePatientInfo();

  const groupAppointmentStatus = useGroupStatusCheck(groupAppointmentId);

  const onSecurityVerified = useCallback(
    async ({ token, details: patientInfo, appointmentId }) => {
      try {
        setIsLoading(true);
        setSecurityToken(token);

        setDetails((details) => ({
          patientInfo: [
            {
              ...currentPatientInfo,
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
              birthMonth: patientInfo.birthMonth,
              birthDay: patientInfo.birthDay,
              birthYear: patientInfo.birthYear,
            },
          ],
          selfDeclaration: [
            {
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
            },
          ],
        }));

        if (!dataSetupCompleted) {
          // if user verifies, then cancels then verifies again, dont do the reschedule setup again
          const result = await groupAppointmentDataSetup(
            organizationId,
            appointmentId,
          );

          if (!result) {
            // handle error
            throw new Error('Failed to setup reschedule');
          }
          setDataSetupCompleted(true);
        }

        const appointment = await new Promise((resolve) => {
          setDetails(({ appointments }) => {
            resolve(appointments[0]);
            return {};
          });
        });

        setIsLoading(false);

        // appointment must be in pending state for group registration email
        const validRegistration = [AppointmentStatuses.PENDING].includes(
          appointment.appointmentStatus,
        );

        setIsApptStatusValid(validRegistration);

        if (!validRegistration) {
          return;
        }

        if (appointment.completed) {
          setIsCompleted(true);
          return;
        }

        setPageComplete({
          [CovidPageNames.SelectClinicAndTiming]: true,
        });

        nav.toGroup.register.screening(appointmentId);
      } catch (error) {
        console.error(error);
        error.message && toast.error({ title: '', message: error.message });
        resetSecurityToken();
      } finally {
        setIsLoading(false);
      }
    },
    [
      nav,
      setSecurityToken,
      resetSecurityToken,
      groupAppointmentId,
      organizationId,
      groupAppointmentDataSetup,
      setDetails,
      dataSetupCompleted,
      setPageComplete,
    ],
  );

  return (
    <PageWrapper>
      {!isApptStatusValid ? (
        <InvalidAppointmentMessage />
      ) : isCompleted ? (
        <AlreadyComplete />
      ) : (
        <SecurityCheckServiceCode
          loading={isLoading || !groupAppointmentStatus.loaded}
          activityId={groupAppointmentId}
          onSecurityVerified={onSecurityVerified}
          headerMsg={getString('followupRescheduleVerificationHeader')}
          alertMsg={getString('followupRescheduleVerificationAlert')}
        />
      )}
    </PageWrapper>
  );
};

const AlreadyComplete = () => {
  const { organizationContactPhone, organizationContactEmail } =
    useAppSettings();
  const organizationMessages = [
    { title: 'Phone number', message: organizationContactPhone },
    { title: 'Email address', message: organizationContactEmail },
  ];

  return (
    <InfoWithContact
      icon={<FiInfo size={44} color="#0259A8" className="mb-4x" />}
      contents={getString('followupAppointmentCompleteMessage')}
      contactInfoMessages={organizationMessages}
    />
  );
};
