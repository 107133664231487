import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSettings, useDetailContext, usePatientInfo } from 'context';
import { useSecurityToken } from 'context/useSecurityToken';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { SecurityCheck } from '../../SecurityCheck/SecurityCheck';
import { useFollowupStatusCheck } from 'context/useFollowupStatusCheck';
import { AppointmentStatuses } from 'constants/appointment';
import { InvalidAppointmentMessage } from '../../../common/InvalidAppointmentMessage';
import { FiInfo } from 'vyaguta-icons/fi';
import { InfoWithContact } from 'components/common/InfoWithContact';
import { CovidPageNames } from 'constants/pages';
import { changeTitle } from 'util/siteInfo';

export const RegisterVerify = () => {
  changeTitle('Follow Up | Verify');
  const { appointmentId, organizationId } = useParams();
  const { setSecurityToken, resetSecurityToken } = useSecurityToken();
  const { rescheduleDataSetup, setDetails, setPageComplete } =
    useDetailContext();
  const nav = useNavigationHelper();
  const [isLoading, setIsLoading] = useState(false);
  const [isApptStatusValid, setIsApptStatusValid] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [dataSetupCompleted, setDataSetupCompleted] = useState(false);

  const { currentPatientInfo } = usePatientInfo();
  const followupStatus = useFollowupStatusCheck(appointmentId);

  const onSecurityVerified = useCallback(
    async ({ token, details: patientInfo }) => {
      try {
        setIsLoading(true);
        setSecurityToken(token);
        setDetails((details) => ({
          patientInfo: [
            {
              ...currentPatientInfo,
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
              birthMonth: patientInfo.birthMonth,
              birthDay: patientInfo.birthDay,
              birthYear: patientInfo.birthYear,
            },
          ],
          selfDeclaration: [
            {
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
            },
          ],
        }));
        // fetch and setup data

        if (!dataSetupCompleted) {
          // if user verifies, then cancels then verifies again, dont do the reschedule setup again
          const result = await rescheduleDataSetup(
            organizationId,
            appointmentId,
          );
          if (!result) {
            // handle error
            throw new Error('Failed to setup reschedule');
          }
          setDataSetupCompleted(true);
        }

        const appointment = await new Promise((resolve) => {
          setDetails(({ appointments }) => {
            resolve(appointments[0]);
            return {};
          });
        });

        setIsLoading(false);

        const validReschedule = [
          AppointmentStatuses.PENDING,
          AppointmentStatuses.BOOKED,
        ].includes(appointment.appointmentStatus);
        setIsApptStatusValid(validReschedule);

        if (!validReschedule) {
          return;
        }

        if (appointment.completed) {
          setIsCompleted(true);
          return;
        }

        setPageComplete({
          [CovidPageNames.SelectClinicAndTiming]: true,
        });

        nav.toFollowup.register.screening(appointmentId);
      } catch (error) {
        console.error(error);
        error.message && toast.error({ title: '', message: error.message });
        resetSecurityToken();
      } finally {
        setIsLoading(false);
      }
    },
    [
      nav,
      setSecurityToken,
      resetSecurityToken,
      appointmentId,
      organizationId,
      rescheduleDataSetup,
      setDetails,
      dataSetupCompleted,
      setPageComplete,
    ],
  );

  return (
    <PageWrapper>
      {!isApptStatusValid ? (
        <InvalidAppointmentMessage />
      ) : isCompleted ? (
        <AlreadyComplete />
      ) : (
        <SecurityCheck
          loading={isLoading || !followupStatus.loaded}
          activityId={appointmentId}
          onSecurityVerified={onSecurityVerified}
          headerMsg={getString('followupRescheduleVerificationHeader')}
          alertMsg={getString('followupRescheduleVerificationAlert')}
        />
      )}
    </PageWrapper>
  );
};

const AlreadyComplete = () => {
  const { organizationContactPhone, organizationContactEmail } =
    useAppSettings();
  const organizationMessages = [
    { title: 'Phone number', message: organizationContactPhone },
    { title: 'Email address', message: organizationContactEmail },
  ];

  return (
    <InfoWithContact
      icon={<FiInfo size={44} color="#0259A8" className="mb-4x" />}
      contents={getString('followupAppointmentCompleteMessage')}
      contactInfoMessages={organizationMessages}
    />
  );
};
