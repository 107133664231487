import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';
import { TestResultOption } from 'constants/testReport';

const TestResult = ({ testResult, testPerformedName }) => (
  <>
    <View style={styles.detailsRow}>
      <View style={styles.detailsItem}>
        <Text style={styles.detailsLabel}>
          {getString('testReport.testResult')}
        </Text>
        <View style={[styles.detailsValue, styles.highlight]}>
          <Text>
            {TestResultOption.find(
              (option) => option.value === testResult,
            )?.label()}
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.detailsRow}>
      <View style={styles.detailsItem}>
        <Text style={styles.detailsLabel}>
          {getString('testReport.performedTestName')}
        </Text>
        <Text style={styles.detailsValue}>{testPerformedName}</Text>
      </View>
    </View>
    <View style={styles.itemSeparator}></View>
  </>
);

const TestDetailsPdfView = ({
  testResults,
  testKitIdType,
  testKitName,
  testDevice,
  testDate,
}) => (
  <View style={styles.section}>
    <Text style={styles.sectionTitle}>
      {getString('testReport.testDetails')}
    </Text>
    <View style={styles.sectionContent}>
      {testResults?.map((testResultItem, key) => (
        <TestResult
          testResult={testResultItem?.testPerformedResult}
          testPerformedName={testResultItem?.testPerformedName}
          key={key}
        />
      ))}
      <View style={styles.detailsRow}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>
            {getString('testReport.testKitId')}
          </Text>
          <Text style={styles.detailsValue}>{testKitIdType}</Text>
        </View>
      </View>
      <View style={styles.detailsRow} wrap={true}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>
            {getString('testReport.testKitName')}
          </Text>
          <Text style={{ ...styles.detailsValue, ...styles.customWidth }}>
            {testKitName}
          </Text>
        </View>
      </View>
      <View style={styles.detailsRow}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>
            {getString('testReport.testDevice')}
          </Text>
          <Text style={styles.detailsValue}>{testDevice}</Text>
        </View>
      </View>
      <View style={styles.detailsRow}>
        <View style={styles.detailsItem}>
          <Text style={styles.detailsLabel}>
            {getString('testReport.testDate')}
          </Text>
          <Text style={styles.detailsValue}>{testDate}</Text>
        </View>
      </View>
    </View>
  </View>
);

const styles = StyleSheet.create({
  section: {
    border: '1px solid #757575',
    marginBottom: 16,
  },
  sectionTitle: {
    fontWeight: 700,
    backgroundColor: '#dddddd',
    padding: '8px 12px',
  },
  sectionContent: {
    padding: 10,
  },
  detailsRow: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 10,
    alignItems: 'center',
  },
  detailsItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: 200,
  },
  customWidth: {
    width: '80%',
  },
  detailsLabel: {
    fontWeight: 700,
    minWidth: 120,
    paddingRight: 12,
  },
  highlight: {
    fontWeight: 700,
    padding: '0 10px',
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dddddd',
  },
  itemSeparator: {
    borderBottom: '1px solid #e1dfdd',
    marginBottom: 10,
    width: '100%',
  },
});

export default TestDetailsPdfView;
