const statusCodes = {
  ACTIVATED: 1,
  DEACTIVATED: 2,
};

export const httpStatusCodes = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
};

export default statusCodes;
