import React from 'react';

import FormInput from '../../../../common/FormInput';
import FormErrors from '../../../../common/FormErrors';
import { PatientPhysicianInfoKeys } from '../../../../../constants/patientInfo';
import { getString } from 'util/lang';

const PatientPhysicianInfo = (props) => {
  const {
    onChangeInput,
    patientInfo,
    errors = {},
    isInsuranceValidationLoading,
  } = props;

  const errorSet = React.useMemo(() => {
    const tempErrorSet = {};
    PatientPhysicianInfoKeys.forEach((key) => {
      if (errors[key]) {
        tempErrorSet[key] = errors[key];
      }
    });
    return tempErrorSet;
  }, [errors]);

  return (
    <>
      <section className="form-patient-address section__margin">
        <h2> {getString('physicianInfoHeading')}</h2>
        <p className="insurance-card-sub-header">
          {getString('physicianInfoSubHeading')}
        </p>
        <div className="patient-form-group border-rounded">
          <div className="row">
            <div className="col-6-md fs-mask">
              <FormInput
                label={getString('physicianName')}
                id="physicianName"
                onChange={onChangeInput}
                value={patientInfo.physicianName}
                hasError={!!errorSet['physicianName']}
                disableInput={isInsuranceValidationLoading}
                data-qa="physician-name"
              />
            </div>
            <div className="col-6-md">
              <FormInput
                label={getString('physicianPhoneNumber')}
                type="tel"
                id="physicianPhoneNumber"
                onChange={onChangeInput}
                value={patientInfo.physicianPhoneNumber}
                hasError={!!errorSet['physicianPhoneNumber']}
                disableInput={isInsuranceValidationLoading}
                data-qa="physician-phone-number"
              />
            </div>
          </div>
        </div>

        <FormErrors errors={errorSet} />
      </section>
    </>
  );
};

export default PatientPhysicianInfo;
