import styles from './LineBreak.module.scss';

const LineBreak = ({ titleText, className }) => {
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center ${styles.sectionBreak} ${className}`}
    >
      {!!titleText && <h3 className="text-uppercase">{titleText}</h3>}
      <div />
    </div>
  );
};

export default LineBreak;
