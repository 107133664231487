import { getString } from 'util/lang';
import TextArea from 'components/common/Textarea';

export const ABNFormAdditionalInfo = ({ abnData, abnAdditionalInfo }) => {
  return (
    <>
      <div className="mb-6x">
        <div className="row">
          <div className="col-12">
            <TextArea
              rows={4}
              textareaClassName="custom-textarea"
              labelClassName="label-placeholder"
              label={getString(
                'dme.documentation.abnForm.fields.additionalInfoLabel',
              )}
              disableInput={true}
              value={abnAdditionalInfo}
              solo
            />
          </div>
        </div>
      </div>
      <p className="desc mb-6x">
        <span className="title mr-1x">
          {getString(`${abnData}.additionalInfoBold`)}
        </span>
        {getString(`${abnData}.additionalInfo`)}
        <span className="title">
          {getString(`${abnData}.additionalInfoNumber`)}
        </span>
      </p>
      <p className="desc ">{getString(`${abnData}.signatureInfo`)}</p>
    </>
  );
};
