import React from 'react';

import { useSelectionsContext } from 'context';

import SelectLocation from './SelectLocation';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { changeTitle } from 'util/siteInfo';

const SelectLocationWrapper = () => {
  changeTitle('Location');
  const { selectedServices } = useSelectionsContext();

  const nav = useNavigationHelper();

  if (!selectedServices.length || selectedServices.length < 1) {
    nav.resetToFirstPage();
    return null;
  }

  return <SelectLocation />;
};

export default SelectLocationWrapper;
