export const compareStrings = (string1, string2) => {
  return string1?.toLowerCase() === string2?.toLowerCase();
};

export const changeStringToBoolean = (string) =>
  string === 'true' ? true : string === 'false' ? false : '';
export const hasExtraSpace = (value) => {
  if (!value) return false;
  return value.split(' ').filter((v) => !v).length > 0;
};

export const hasConsectiveChar = (value) => {
  if (!value) return false;
  if (value && value.length < 5) return false;
  let recurr = 0;
  let recurrChar = value[0];
  for (let i = 0; i < value.length; i++) {
    if (value[i] === recurrChar) {
      recurr++;
    } else {
      recurr = 1;
      recurrChar = value[i];
    }
    if (recurr > 4) return true;
  }
  return false;
};

/**
 * @param {(string | React.ReactNode)[]} strings
 * @returns {string[]}  input strings interleaved with ', ' and ' and '
 */
export const joinStringsWithAnd = (strings) => {
  return strings.reduce((words, str, idx, array) => {
    if (idx === 0) {
      return [str];
    }
    if (idx === array.length - 1) {
      return [...words, ' and ', str];
    }
    return [...words, ', ', str];
  }, []);
};

export function getBirthDateString(birthDay, birthMonth, birthYear) {
  if (birthDay && birthMonth && birthYear) {
    return `${birthYear}-${birthMonth}-${birthDay}`;
  }

  return '';
}

/**
 *
 * @param patientBirthInformation : { birthDay: string, birthMonth: string, birthYear: string}
 *
 */
export function isPatientBirthInformationPartiallyFilledOrEmpty(
  patientBirthInformation,
) {
  const patientBirthDay =
    (patientBirthInformation?.birthDay?.trim() ? 1 : 0) || '';
  const patientBirthMonth =
    (patientBirthInformation?.birthMonth?.trim() ? 1 : 0) || '';
  const patientBirthYear =
    (patientBirthInformation?.birthYear?.trim() ? 1 : 0) || '';

  return patientBirthDay + patientBirthMonth + patientBirthYear !== 3;
}

/**
 *
 * @param patientBirthInformation : { birthDay: string, birthMonth: string, birthYear: string}
 *
 */
export function isPatientBirthInformationPartiallyFilled(
  patientBirthInformation,
) {
  const patientBirthDay =
    (patientBirthInformation?.birthDay?.trim() ? 1 : 0) || '';
  const patientBirthMonth =
    (patientBirthInformation?.birthMonth?.trim() ? 1 : 0) || '';
  const patientBirthYear =
    (patientBirthInformation?.birthYear?.trim() ? 1 : 0) || '';

  const filledInformationCount =
    patientBirthDay + patientBirthMonth + patientBirthYear;

  return filledInformationCount !== 3 && filledInformationCount > 0;
}

/**
 *
 * @param arrayOfWords{string[]}
 */
export function formatMultipleWordsWithAnd(arrayOfWords = []) {
  const lastIndex = arrayOfWords.length - 1;
  const lastWord = arrayOfWords[lastIndex];
  if (lastIndex === 0) return lastWord;

  const subArrayOfWords = arrayOfWords.slice(0, lastIndex);

  return subArrayOfWords.join(', ') + (lastWord ? ` and ${lastWord}` : '');
}
/**
 * @desc Returns a string with a trailing comma if value is present
 */

export const getTrailingCommaValue = (value) => {
  return value ? `${value}, ` : '';
};

// Generic utility function to concatenate two strings with a separator
export const concatenateWithSeparator = (
  firstString,
  secondString,
  separator = ' - ',
) => {
  const strA = firstString || ''; // Default to empty string if undefined
  const strB = secondString || ''; // Default to empty string if undefined

  // Return formatted string only if at least one value exists
  return strA && strB ? `${strA} ${separator} ${strB}` : strA || strB;
};

// values: string[]
// sample input: ['4140 Parker Rd.', 'Allentown', 'New Mexico', '31134'], ', '
// sample output: '4140 Parker Rd., Allentown, New Mexico, 31134'
export const joinValues = (values, separator = ' ') => {
  // Filter out undefined or empty values and join with the provided separator
  return values.filter(Boolean).join(separator);
};
