import { useState, useEffect } from 'react';

import { useAddressSearch } from 'hooks/useAddressSearch';

import BackToPage from 'components/COVID/common/BackToPage';
import AlertMessage from 'components/COVID/common/AlertMessage';
import ActivityIndicator from 'components/common/ActivityIndicator';
import { ServicesInfoContainer } from 'components/COVID/common/ServicesInfo';

import PatientBasicInfo from '../presentation/PatientBasicInfo';
import PatientAddressInfo from '../presentation/PatientAddressInfo';
import VerifyPatientRecord from '../presentation/VerifyPatientRecord';
import PatientPhysicianInfo from '../presentation/PatientPhysicianInfo';
import AddressVerificationModal from '../presentation/AddressVerificationModal';
import AddressConfirmationModal from '../presentation/AddressConfirmationModal';

import LinkToPage from '../../../../common/LinkToPage';
import {
  ValidatePatientInfo,
  validateEmailWithConfirmEmail,
} from './PatientInfoValidation';
import { verifyPatientRecord } from 'services/patient';

import { CovidPageNames } from 'constants/pages';
import regexPattern from 'constants/regexPattern';
import {
  ConsentedBy,
  PatientAddressInfoKeys,
  AddressFilledTypeOptions,
} from 'constants/patientInfo';

import * as Regex from 'util/regex';
import { getString } from 'util/lang';
import { compareStrings } from 'util/string';
import DateTimeUtils from 'util/DateAndTime';
import { formatFormInput } from 'util/formatFormInput';
import { replaceArrayElementByIndex } from 'util/array';

const COMPONENT_STATE_KEY = {
  currentPatientIndex: 'currentPatientIndex',
  patientInfo: 'patientInfo',
  errors: 'errors',
  showNotifySuccess: 'showNotifySuccess',
  showAlert: 'showAlert',
  allowShot: 'allowShot',
  isAboveMaximumAge: 'isAboveMaximumAge',
  isUnderMinimumAge: 'isUnderMinimumAge',
  showMergeModal: 'showMergeModal',
  patientId: 'patientId',
  isPatientRecordVerifying: 'isPatientRecordVerifying',
  previousValidationPayload: 'previousValidationPayload',
  address: 'address',
  isAddressConfirmed: 'isAddressConfirmed',
  isAddressConfirmationModalOpen: 'isAddressConfirmationModalOpen',
  isAddressVerificationModalOpen: 'isAddressVerificationModalOpen',
  addressSuggestions: 'addressSuggestions',
  typeOfServiceCodes: 'typeOfServiceCodes',
  consentedBy: 'consentedBy',
};

const NAME_KEYS = [
  'firstName',
  'lastName',
  'middleName',
  'guardianFirstName',
  'guardianLastName',
  'guardianMiddleName',
];

const ADDRESS_KEYS = ['homeAddress', 'city', 'state', 'zipCode', 'county'];

/**
 *
 * @param {*} Props
 *  disableEdit: to desable/enable the editable option
 *  details: Context object holding the App global state
 *
 * @returns
 */
const PatientInformation = (props) => {
  const { disableEdit, details } = props;
  const { currentPatientIndex, patientInfo } = details;
  const currentPatient = patientInfo[currentPatientIndex] ?? {};

  const [componentState, setComponentState] = useState({
    currentPatientIndex: currentPatientIndex,
    patientInfo: {
      ...currentPatient,
    },
    errors: {},
    showNotifySuccess: false,
    showAlert: false,
    allowShot: true,
    isAboveMaximumAge: false,
    isUnderMinimumAge: false,
    showMergeModal: false,
    patientId: null,
    isPatientRecordVerifying: false,
    previousValidationPayload: {},
    address: {
      homeAddress: '',
      city: '',
      state: '',
      zipCode: '',
      county: '',
    },
    isAddressConfirmed: false,
    isAddressConfirmationModalOpen: false,
    isAddressVerificationModalOpen: false,
    addressSuggestions: [],
    typeOfServiceCodes: [],
    consentedBy:
      details?.commonAppointmentDetails[currentPatientIndex]?.consentedBy ??
      ConsentedBy.PATIENT,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const { selectedClinic, selectedServices } = props;
    const {
      currentPatientIndex,
      patientInfo,
      appLanguageCode,
      counties,
      states,
    } = props.details;
    const { patientId } =
      props.details.patientRecordInfo[currentPatientIndex] ?? {};
    const currentPatient = patientInfo[currentPatientIndex] ?? {};
    let stateLabel = '',
      countyLabel = '';

    if (props.isFollowup && currentPatient.state) {
      stateLabel = currentPatient?.state;
      currentPatient.state = states?.find(
        (state) => state.label === stateLabel,
      )?.value;
    } else {
      if (!currentPatient.state)
        currentPatient.state = selectedClinic?._smvs_state_value;
      stateLabel = states?.find(
        (state) => state.value === currentPatient.state,
      )?.label;
    }

    let filteredCountyOptions = counties?.filter(
      (county) => county.stateId === currentPatient.state,
    );

    if (props.isFollowup && currentPatient.county) {
      countyLabel = currentPatient?.county;
      currentPatient.county = filteredCountyOptions?.find(
        (county) => county.label === countyLabel,
      )?.value;
    } else {
      countyLabel = filteredCountyOptions?.find(
        (county) => county.value === currentPatient.county,
      )?.label;
      if (!currentPatient.county)
        currentPatient.county = selectedClinic?._smvs_county_value;
    }

    setAgeCriteria(currentPatient);

    setComponentState((prev) => ({
      ...prev,
      patientInfo: {
        ...currentPatient,
        addressFilledType:
          currentPatient.addressFilledType || AddressFilledTypeOptions.Manual,
        iisConsent:
          currentPatient.iisConsent == false
            ? false
            : !props.getAppSettings().isIISConsentEnabled
              ? false
              : true,
      },
      address: {
        homeAddress: currentPatient.homeAddress,
        city: currentPatient.city,
        state: stateLabel || '',
        zipCode: currentPatient.zipCode,
        county: countyLabel || '',
      },
      patientId,
      countyOptions: filteredCountyOptions,
      stateOptions: states,
      appLanguageCode,
      typeOfServiceCodes: selectedServices.map(
        ({ service }) => service.typeOfServiceCode,
      ),
    }));
  }, []);

  // Set Age Criteria upon patientInfo attribute change
  useEffect(() => {
    setAgeCriteria();
  }, [componentState.patientInfo]);

  useEffect(() => {
    if (
      componentState.isAddressConfirmed &&
      componentState.patientInfo.addressFilledType ===
        AddressFilledTypeOptions.Manual
    ) {
      handleSubmit();
    }
  }, [componentState.isAddressConfirmed]);

  const setAgeCriteria = (patientInfo) => {
    const { birthDay, birthMonth, birthYear } =
      patientInfo ?? componentState.patientInfo;
    const { selectedServices } = props;

    if (birthDay && birthMonth && birthYear) {
      const birthDate = `${birthMonth}-${birthDay}-${birthYear}`;
      const isUnderMinimumAge = selectedServices.some(({ subService }) =>
        DateTimeUtils.isUnderMinimumAge(
          birthDate,
          subService.minAgeRequirement,
        ),
      );
      const isAboveMaximumAge = selectedServices.some(({ subService }) =>
        DateTimeUtils.isAboveMaximumAge(
          birthDate,
          subService.maxAgeRequirement,
        ),
      );

      setComponentState((prev) => ({
        ...prev,
        isUnderMinimumAge,
        isAboveMaximumAge,
      }));
    }
  };

  /**
   *
   * @param {string} key The attribute who corresponding state value is to be updated
   * @param {any} value The value corresponding to the key to be updated
   */
  const updateComponentState = (key, value) => {
    if (Object.keys(COMPONENT_STATE_KEY).includes(key)) {
      setComponentState((prev) => ({ ...prev, [key]: value }));
    }
  };

  /**
   *
   * @param {Enumerator} emailFieldType value email | confirmEmail
   * @desc A generic email validator for both email and confirm email field
   */
  const validateEmailFieldsOnBlur = (emailFieldType) => {
    const errorset = {};

    if (
      !!componentState.patientInfo.emailNotificationConsent &&
      !componentState.patientInfo[emailFieldType]
    ) {
      errorset[emailFieldType] =
        emailFieldType === 'email'
          ? getString('emailRequired2')
          : getString('confirmEmailRequired');
    }

    if (
      !!componentState.patientInfo.emailNotificationConsent &&
      !!componentState.patientInfo[emailFieldType] &&
      !Regex.isEmail(componentState.patientInfo[emailFieldType])
    ) {
      errorset[emailFieldType] =
        emailFieldType === 'email'
          ? getString('validEmailRequired')
          : getString('validConfirmEmailRequired');
    }

    if (
      emailFieldType === 'confirmEmail' &&
      !!componentState.patientInfo.emailNotificationConsent &&
      !componentState.patientInfo.confirmEmail
    ) {
      errorset.confirmEmail = getString('confirmEmailRequired');
    }

    if (
      emailFieldType === 'confirmEmail' &&
      !!componentState.patientInfo.emailNotificationConsent &&
      !!Regex.isEmail(componentState.patientInfo.email) &&
      !!Regex.isEmail(componentState.patientInfo.confirmEmail) &&
      componentState.patientInfo.email !==
        componentState.patientInfo.confirmEmail
    ) {
      errorset.confirmEmail = getString('emailMatchRequired');
    }

    if (
      !!componentState.patientInfo.emailNotificationConsent &&
      !!Regex.isEmail(componentState.patientInfo.email) &&
      !!Regex.isEmail(componentState.patientInfo.confirmEmail) &&
      componentState.patientInfo.email ===
        componentState.patientInfo.confirmEmail
    ) {
      errorset.confirmEmail = null;
      errorset.email = null;
    }

    updateComponentState(COMPONENT_STATE_KEY.errors, {
      ...componentState.errors,
      ...errorset,
    });
  };

  /**
   *
   * @desc Compare global patient info and local
   */
  const isPatientInfoUpdated = () => {
    const {
      firstName,
      middleName,
      lastName,
      mobileNo,
      email,
      birthDay,
      birthMonth,
      birthYear,
    } = componentState.patientInfo;
    const { patientRecordInfo: globalPatientRecordInfo, currentPatientIndex } =
      props.details;
    const currentPatientRecordInfo =
      globalPatientRecordInfo[currentPatientIndex];
    if (
      compareStrings(currentPatientRecordInfo.firstName, firstName) &&
      compareStrings(currentPatientRecordInfo.middleName, middleName) &&
      compareStrings(currentPatientRecordInfo.lastName, lastName) &&
      currentPatientRecordInfo.mobileNo === mobileNo &&
      compareStrings(currentPatientRecordInfo.email, email) &&
      currentPatientRecordInfo.birthYear === birthYear &&
      currentPatientRecordInfo.birthMonth === birthMonth &&
      currentPatientRecordInfo.birthDay === birthDay
    ) {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...globalPatientRecordInfo[currentPatientIndex],
          isRecordVerified: true,
        },
      );
      props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });

      return false;
    } else {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...globalPatientRecordInfo[currentPatientIndex],
          isRecordVerified: false,
        },
      );
      props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });

      return true;
    }
  };

  const __getInputFieldErrors = () => {
    const { patientInfo } = componentState;

    let errors = {};

    const { errors: patientInfoErrors } = ValidatePatientInfo(patientInfo);

    const emailError = validateEmailWithConfirmEmail(
      patientInfo.email,
      patientInfo.confirmEmail,
      patientInfo.emailNotificationConsent,
    );

    return (errors = {
      ...errors,
      ...patientInfoErrors,
      ...emailError,
    });
  };

  /**
   *  TODO: check function should not have side effects that save data
   *
   * Check if form data is valid,
   * If isValid is true, proceeds to the confirm and submit page
   * If isValid is false, errors are set, doesnt route to next page and returns
   */
  const checkFormValidity = async (isAutoTrigger) => {
    const errors = __getInputFieldErrors();

    const errorList = Object.keys(errors).filter((key) => errors[key]);

    setComponentState((prev) => ({
      ...prev,
      errors,
    }));

    if (errorList.length > 0) {
      props.setSelfAndNextPageIncomplete();
      if (!isAutoTrigger) {
        animateToFormError();
      }
      return true;
    }
  };

  const onPatientInfoChange = (key, value, event) => {
    if (
      (key === 'mobileNo' ||
        key === 'homePhoneNo' ||
        key === 'physicianPhoneNumber') &&
      value.length < 12
    ) {
      const cursor = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = cursor;
        element.selectionEnd = cursor;
      });
      value = value.replaceAll('-', '');
    }

    if (
      (key === 'mobileNo' ||
        key === 'homePhoneNo' ||
        key === 'physicianPhoneNumber') &&
      !Regex.isNumber(value)
    ) {
      return;
    }

    if (key == null || value == null) return;

    if (
      NAME_KEYS.includes(key) &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    )
      return;

    const { patientInfo } = componentState;

    value = formatFormInput(key, value);
    // Case: Character Limit:
    // Do not process further if new value equals to old value
    if (componentState.patientInfo[key] === value) return;

    const newChange = { [key]: value };

    if (ADDRESS_KEYS.includes(key)) {
      updateReferenceAddress(key, value);

      const hasAddressChanged = isAddressChanged(key, value);

      newChange['addressFilledType'] = hasAddressChanged
        ? AddressFilledTypeOptions.Manual
        : AddressFilledTypeOptions.Verified;
    }
    // email opt out
    // email opt out, isEmailchecked: false, disbaleInput: true
    if (key === 'state') {
      let filteredCountyOptions = props.details.counties?.filter(
        (county) => county?.stateId === value,
      );
      setComponentState((prev) => ({
        ...prev,
        countyOptions: filteredCountyOptions,
      }));
    }

    let errors = resetError(key);

    if (key === 'phoneOptOut' && value === true) {
      newChange['mobileNo'] = '';
    }

    if (key === 'state') {
      newChange['county'] = '';
    }
    if (key === 'emailNotificationConsent' && value) {
      document.getElementById('email').focus();
    }

    if (key === 'emailNotificationConsent' && !value) {
      errors = resetErrorBulk(['email', 'confirmEmail']);
    }

    setComponentState((prev) => ({
      ...prev,
      patientInfo: { ...patientInfo, ...newChange },
      errors,
    }));

    setAgeCriteria(patientInfo);

    const { pageComplete } = props;

    // Check if saved record matches new record
    const hasPatientRecordUpdated = isPatientInfoUpdated();

    if (hasPatientRecordUpdated) {
      props.setSelfAndNextPageIncomplete();
    }
  };

  // Modal States
  const closeAddressConfirmationModal = () => {
    updateComponentState(
      COMPONENT_STATE_KEY.isAddressConfirmationModalOpen,
      false,
    );
  };

  const openAddressConfirmationModal = () => {
    updateComponentState(
      COMPONENT_STATE_KEY.isAddressConfirmationModalOpen,
      true,
    );
  };

  const closeAddressVerificationModal = () => {
    updateComponentState(
      COMPONENT_STATE_KEY.isAddressVerificationModalOpen,
      false,
    );
  };

  const openAddressVerificationModal = () => {
    updateComponentState(
      COMPONENT_STATE_KEY.isAddressVerificationModalOpen,
      true,
    );
  };

  // Address Component
  const handleConfirmAddress = async () => {
    setComponentState((prev) => ({ ...prev, isAddressConfirmed: true }));
  };

  const handleEditAddress = () => {
    setTimeout(() => {
      const addressSection = document.getElementsByClassName(
        'form-patient-address',
      )[0];
      if (addressSection) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: addressSection.offsetTop - 200,
        });
      }
    });
  };

  const handleAddressChange = (address) => {
    const { homeAddress, city, zipCode } = address;
    const state = props.details.states.find(
      (state) => state.label === address.state,
    );
    const filteredCountyOptions = props.details.counties.filter(
      (county) => county.stateCode === address.state,
    );
    const county = filteredCountyOptions.find(
      (county) =>
        county.label === address.county && county.stateCode === address.state,
    );
    setComponentState((prev) => ({
      ...prev,
      address,
      isAddressConfirmed: true,
      countyOptions: filteredCountyOptions,
      patientInfo: {
        ...componentState.patientInfo,
        city,
        zipCode,
        homeAddress,
        state: state?.value,
        county: county?.value,
        addressFilledType: AddressFilledTypeOptions.Verified,
      },
      errors: resetErrorBulk(PatientAddressInfoKeys),
    }));
  };

  const isAddressChanged = (id, value) => {
    const { patientInfo } = componentState;
    const { homeAddress, city, state, county, zipCode } = patientInfo;
    if (id === 'homeAddress') {
      return homeAddress !== value;
    }
    if (id === 'city') {
      return city !== value;
    }
    if (id === 'state') {
      return state !== value;
    }
    if (id === 'county') {
      return county !== value;
    }
    if (id === 'zipCode') {
      return zipCode !== value;
    }
    return false;
  };

  const updateReferenceAddress = (key, value) => {
    const hasAddressChanged = isAddressChanged(key, value);

    if (key === 'state') {
      value = props.details.states.find(
        (state) => state.value === value,
      )?.label;
    } else if (key === 'county') {
      value = props.details.counties.find(
        (county) => county.value === value,
      )?.label;
    }

    setComponentState((prev) => ({
      ...prev,
      isAddressConfirmed: !hasAddressChanged,
      address: {
        ...componentState.address,
        [key]: value,
      },
    }));
  };

  /**
   * Animation to error
   */
  const animateToFormError = () => {
    setTimeout(() => {
      const errorField = document.getElementsByClassName('form-error')[0];

      if (errorField) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: errorField.offsetTop - 200,
        });
      }
    });
  };

  /**
   * Clear error for input field with that key
   */
  const resetError = (key) => {
    const newError = { ...componentState.errors };
    newError[key] = null;
    return newError;
  };

  const resetErrorBulk = (keyList) => {
    const newError = { ...componentState.errors };
    keyList.forEach((key) => {
      newError[key] = null;
    });
    return newError;
  };

  const handleSubmit = async (isAutoTrigger) => {
    const { patientRecordInfo: globalPatientRecordInfo, currentPatientIndex } =
      props.details;
    const currentPatientRecordInfo =
      globalPatientRecordInfo[currentPatientIndex];
    const { patientInfo, isAddressConfirmed, address } = componentState;
    const { addressFilledType } = patientInfo;
    const suggest = props.suggest;
    const isFormInvalid = await checkFormValidity(isAutoTrigger);

    if (isFormInvalid) return;

    // Mapping Internal State  with the App state which is in details
    const { details } = props;
    const globalPatientInfo = replaceArrayElementByIndex(
      details.patientInfo,
      currentPatientIndex,
      patientInfo,
    );

    const currentSelfDeclaration = {
      ...details.selfDeclaration[currentPatientIndex],
      firstName: patientInfo.firstName,
      middleName: patientInfo.middleName,
      lastName: patientInfo.lastName,
    };

    const globalSelfDeclaration = replaceArrayElementByIndex(
      details.selfDeclaration,
      currentPatientIndex,
      currentSelfDeclaration,
    );

    props.setDetails({
      patientInfo: globalPatientInfo,
      selfDeclaration: globalSelfDeclaration,
    });

    if (!currentPatientRecordInfo.isRecordVerified) {
      try {
        updateComponentState(
          COMPONENT_STATE_KEY.isPatientRecordVerifying,
          true,
        );
        if (
          !isAddressConfirmed &&
          addressFilledType === AddressFilledTypeOptions.Manual
        ) {
          const suggestions = await suggest(address);
          if (!suggestions || suggestions?.length === 0) {
            updateComponentState(COMPONENT_STATE_KEY.addressSuggestions, []);
            openAddressConfirmationModal();
            return;
          } else {
            updateComponentState(
              COMPONENT_STATE_KEY.addressSuggestions,
              suggestions,
            );
            openAddressVerificationModal();
            return;
          }
        }

        if (!props.isFollowup) {
          const patientRecordMeta = await verifyPatientRecord(patientInfo);
          const { isExistingPatient, patientId } = patientRecordMeta;
          if (isExistingPatient) {
            //next page logic is handled by merge modal in this case
            setComponentState((prev) => ({
              ...prev,
              showMergeModal: true,
              patientId,
            }));
          } else {
            const updatedPatientRecordInfo = replaceArrayElementByIndex(
              globalPatientRecordInfo,
              currentPatientIndex,
              {
                isExistingPatient: false,
                patientId: null,
                isRecordVerified: true,
                firstName: '',
                lastName: '',
                middleName: '',
                birthMonth: '',
                birthDay: '',
                birthYear: '',
                mobileNo: '',
                email: '',
              },
            );
            props.setDetails({
              patientRecordInfo: updatedPatientRecordInfo,
            });
            gotoNextPage();
          }
        } else {
          gotoNextPage();
        }
      } catch (error) {
        console.log(error);
        gotoNextPage();
      } finally {
        updateComponentState(
          COMPONENT_STATE_KEY.isPatientRecordVerifying,
          false,
        );
      }
    } else {
      gotoNextPage();
    }
  };

  const saveAndMergePatientData = (isMergeAllowed) => {
    const { patientInfo } = componentState;

    const patientRecord = {
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      middleName: patientInfo.middleName,
      birthMonth: patientInfo.birthMonth,
      birthDay: patientInfo.birthDay,
      birthYear: patientInfo.birthYear,
      mobileNo: patientInfo.mobileNo,
      email: patientInfo.email,
    };

    const { patientRecordInfo: globalPatientRecordInfo, currentPatientIndex } =
      props.details;

    if (isMergeAllowed) {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          isExistingPatient: true,
          patientId: componentState.patientId,
          isRecordVerified: true,
          ...patientRecord,
        },
      );
      props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });
    } else {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...props.details.patientRecordInfo[currentPatientIndex],
          isExistingPatient: false,
          patientId: null,
          isRecordVerified: true,
        },
      );
      props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });
    }
    gotoNextPage();
  };

  const gotoNextPage = () => {
    props.toNextPage();
    props.setThisPageCompleteStatus(true);
  };

  return (
    <>
      <BackToPage
        text={getString('backToEligibilityAndHealthScreeningPage')}
        onClick={props.goBack}
      />
      <ServicesInfoContainer disableEdit={disableEdit} />
      <div className="patient-info">
        <AlertMessage
          type="info"
          message={getString('provideContactInfoAlert')}
          className="mb-5x"
          isVisible="true"
          dataqa="provide-contact-info-alert"
        />
        <PatientBasicInfo
          isInsuranceValidationLoading={false}
          onChangeInput={onPatientInfoChange}
          patientInfo={componentState.patientInfo}
          errors={componentState.errors}
          isIISConsentEnabled={props.getAppSettings().isIISConsentEnabled}
          validateEmailonBlur={validateEmailFieldsOnBlur}
          validateConfirmEmailonBlur={validateEmailFieldsOnBlur}
          disabledFieldStatus={props.disabledFieldStatus}
        />
        <AlertMessage
          type="danger"
          message={props.primarySelection.subService.minAgeMessage}
          className="my-5x"
          isVisible={componentState.isUnderMinimumAge}
          dataqa="is-under-min-age-alert"
        />
        <AlertMessage
          type="danger"
          message={props.primarySelection.subService.minAgeMessage}
          className="my-5x"
          isVisible={componentState.isAboveMaximumAge}
        />
      </div>
      <section className="form-patient-address section__margin">
        <h2>{getString('patientAddress')}</h2>
        <PatientAddressInfo
          isInsuranceValidationLoading={false}
          onChangeInput={onPatientInfoChange}
          patientInfo={componentState.patientInfo}
          errors={componentState.errors}
          stateOptions={componentState.stateOptions}
          countyOptions={componentState.countyOptions}
          onAddressSelect={handleAddressChange}
        />
      </section>
      <PatientPhysicianInfo
        isInsuranceValidationLoading={false}
        onChangeInput={onPatientInfoChange}
        patientInfo={componentState.patientInfo}
        errors={componentState.errors}
      />
      <AlertMessage
        dataqa="details-confirm-alert"
        type="info"
        message={getString('detailsConfirmAlert')}
        className="my-5x"
        isVisible={true}
      />
      <div className="row">
        <div className="col-12-sm order-2-sm">
          <LinkToPage
            onClick={handleSubmit}
            label={
              componentState.isPatientRecordVerifying ? (
                <ActivityIndicator className="loader--button loader--button--sm" />
              ) : (
                getString('continueToPaymentPage')
              )
            }
            enabled={true && !componentState.isPatientRecordVerifying}
            title={getString('continueTitle')}
            loading={
              props.details.loading || componentState.isPatientRecordVerifying
            }
            dataqa="go-to-clinic-timing"
          />
        </div>
        {componentState.showMergeModal && (
          <VerifyPatientRecord
            saveAndMergePatientData={saveAndMergePatientData}
          />
        )}
        {componentState.isAddressConfirmationModalOpen && (
          <AddressConfirmationModal
            closeModal={closeAddressConfirmationModal}
            address={componentState.address}
            onEditAddress={handleEditAddress}
            onConfirmAddress={handleConfirmAddress}
          />
        )}

        {componentState.isAddressVerificationModalOpen && (
          <AddressVerificationModal
            closeModal={closeAddressVerificationModal}
            address={componentState.address}
            suggestions={componentState.addressSuggestions}
            onAddressChange={handleAddressChange}
            onConfirmAddress={handleConfirmAddress}
          />
        )}
      </div>
    </>
  );
};

function withAddressSuggestionProps(Component) {
  return function WrappedComponent(props) {
    const { suggest } = useAddressSearch();
    return <Component {...props} suggest={suggest} />;
  };
}

export default withAddressSuggestionProps(PatientInformation);
