import React from 'react';
import { UiInfoCircle } from 'vyaguta-icons/ui';

import { getString } from 'util/lang';
import { passCriteria } from '../../../../constants/confirm';

const Information = ({ text }) => {
  return (
    <p className="important-info-item">
      <UiInfoCircle className="icon" size={20} />
      <span tabIndex="0">{text}</span>
    </p>
  );
};
/* Render the important information section */
const InformationSection = (props) => {
  const { answers, defaultWarnings, questions } = props;

  const questionsWithWarnings =
    questions && questions.filter((qs) => qs.errorType === 'WARNING');
  const dynamicWarnings = Object.keys(answers)
    .filter((key) => {
      const question = questionsWithWarnings.find((qs) => qs.id === key);
      if (!question) return false;
      const passCriteriaKey = passCriteria.find(
        (d) => d.value === question.eligibilityCriteria,
      );
      return answers[key].value === passCriteriaKey.key;
    })
    .map((key) => {
      const question = questionsWithWarnings.find((qs) => qs.id === key);
      return question.eligibilityFailureMessage;
    });

  if (dynamicWarnings.length === 0) return null;

  return (
    <section className="important-info">
      <h6 className="section__title" tabIndex="0">
        {getString('informationHeader')}
      </h6>
      <div className="important-info-content border border-radius">
        {dynamicWarnings.length > 0 &&
          dynamicWarnings.map((text) => <Information text={text} key={text} />)}
        {defaultWarnings.length > 0 &&
          defaultWarnings.map((text) => <Information text={text} key={text} />)}
      </div>
    </section>
  );
};

export default InformationSection;
