import styles from './PatientInfo.module.scss';
import { getString } from 'util/lang';

const LABEL_PREFIX = 'dme.deliveryTicket';

const PatientInfo = ({ data, className }) => {
  return (
    <div className={`${styles.pharmaInfo} ${className}`}>
      <h3>{getString(`${LABEL_PREFIX}.patientDetails.title`)}</h3>
      <p>
        <div className={styles.key}>
          {getString(`${LABEL_PREFIX}.patientDetails.name`)}:{' '}
        </div>
        <span className={styles.value}>{data?.name}</span>
      </p>
      <p>
        <span className={styles.key}>
          {getString(`${LABEL_PREFIX}.patientDetails.dob`)}:{' '}
        </span>
        <span className={styles.value}>{data?.dob}</span>
      </p>
      <p>
        <span className={styles.key}>
          {getString(`${LABEL_PREFIX}.patientDetails.phone`)}:{' '}
        </span>
        <span className={styles.value}>{data?.phone}</span>
      </p>
    </div>
  );
};

export default PatientInfo;
