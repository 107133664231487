import { getString } from 'util/lang';

export const BeneficiaryRelationValue = {
  SELF: 622490000,
  POWER_OF_ATTORYNEY: 622490001,
};

export const BeneficiaryRelation = [
  {
    label: getString('relationshipSelf'),
    value: BeneficiaryRelationValue.SELF,
  },
  {
    label: getString('relationPowerOfAttorney'),
    value: BeneficiaryRelationValue.POWER_OF_ATTORYNEY,
  },
];
