import { useDetailContext, useSelectionsContext } from 'context';
import { getLanguageIdByLanguageCode } from 'util/lang';
import * as appointmentService from 'services/appointment';
import { PaymentOptions } from 'constants/patientInfo';
import { replaceArrayElementByIndex } from 'util/array';
import {
  AvailableInsuranceCoverageIndices,
  InsuranceCoverageTypes,
} from 'constants/patientInfo';
import {
  uploadInsuranceFiles,
  convertToInsuranceDomain,
  getInsuranceIdForMatchingInsuranceDetails,
} from 'util/insuranceUtils';

const addPaymentSpecificData = (appointment, insuranceData) => {
  if (!appointment) return;
  // Insurance payment explicitly requires primaryInsuranceCompany attribute
  if (appointment.paymentType === parseInt(PaymentOptions.Insurance)) {
    appointment.primaryInsuranceCompany = insuranceData.filter(
      (insurance) =>
        insurance &&
        insurance.insuranceCoverageType ===
          InsuranceCoverageTypes[
            AvailableInsuranceCoverageIndices.primaryInsuranceIndex
          ],
    )[0]?.company;
  }
  // Noinsurance info are stored in insuranceData array which needs to be destructured
  if (appointment.paymentType === parseInt(PaymentOptions.NoInsurance)) {
    appointment = { ...appointment, ...insuranceData[0] };
  }
  return appointment;
};

export const useAppointmentSubmission = () => {
  const { setDetails } = useDetailContext();

  const { selectedServices, selectedClinic, isCounsellingSelected } =
    useSelectionsContext();

  const submitAppointment = async (
    appointmentId = null,
    noBlockDetails = false,
  ) => {
    const detailsPromise = new Promise((resolve) => {
      setDetails((details) => {
        resolve(details);
        return {};
      });
    });

    const {
      appointments,
      patientInfo,
      patientHealthAnswers,
      currentPatientIndex,
      organizationId,
      appointmentDetails: serviceWiseAppointmentDetails,
      captchaResponseKey,
      selfDeclaration,
      isAgree,
      eligibilityResponse,
      appLanguageCode,
      organizationObj,
      appSettings,
      states,
      counties,
      registrationCode,
      patientRecordInfo,
      eligibilityQuestions,
      screeningQuestions,
      phicureResponse: allPhicureResponse,
      insuranceCoverageList: allInsuranceCoverageList,
      localizedWebContents,
      security: { token },
    } = await detailsPromise;

    // Update state and county id with names
    const patientInfoRequest = { ...patientInfo[currentPatientIndex] };
    const currentPatientRecordInfo = patientRecordInfo[currentPatientIndex];
    const phicureResponse = allPhicureResponse[currentPatientIndex] ?? {};
    const insuranceCoverageList =
      allInsuranceCoverageList[currentPatientIndex] ?? [];
    patientInfoRequest.state =
      states.find((state) => state.value === patientInfoRequest.state)?.label ||
      '';
    patientInfoRequest.county =
      counties.find((county) => county.value === patientInfoRequest.county)
        ?.label || '';

    const selectedLanguageId = getLanguageIdByLanguageCode(
      appLanguageCode,
      appSettings.languages,
    );

    const { isGlobalSingleUseCodeEnabled } = appSettings;
    const isOrgSingleUseCodeEnabled =
      organizationObj && organizationObj.isSingleUseCodeEnabled;
    const requiresRegistration =
      isGlobalSingleUseCodeEnabled || isOrgSingleUseCodeEnabled;

    let selectedPaymentMethod;
    const requestAppointments = appointments.map(
      ({ firstAppointment }, idx) => {
        selectedPaymentMethod = serviceWiseAppointmentDetails[idx].paymentType;
        const firstAppointmentRequest = firstAppointment
          ? {
              ...firstAppointment,
              ...serviceWiseAppointmentDetails[idx],
              consentedBy: parseInt(
                serviceWiseAppointmentDetails[idx].consentedBy,
              ),
              paymentType: parseInt(selectedPaymentMethod),
            }
          : null;

        return {
          first: addPaymentSpecificData(
            firstAppointmentRequest,
            serviceWiseAppointmentDetails[idx].insuranceData,
          ),
        };
      },
    );

    const appointmentSpecificInsuranceData = serviceWiseAppointmentDetails.map(
      (s, serviceIndex) => {
        return selectedPaymentMethod === PaymentOptions.Insurance
          ? convertToInsuranceDomain(
              s.insuranceData,
              phicureResponse,
              insuranceCoverageList,
              serviceIndex,
            )
          : [];
      },
    );

    const requestEligibilityResponses = eligibilityQuestions.map((questions) =>
      questions.map((question) => ({
        id: question.id,
        value: eligibilityResponse[currentPatientIndex][question.id],
      })),
    );

    const requestScreeningAnswers = screeningQuestions.map((questions) =>
      questions.map((question) => ({
        id: question.id,
        value: patientHealthAnswers[currentPatientIndex][question.id]?.value,
        additionalInfo:
          patientHealthAnswers[currentPatientIndex][question.id]
            ?.additionalInfo,
      })),
    );

    const requestPhicureResponse = phicureResponse;
    const requestSelfDeclaration = selfDeclaration[currentPatientIndex];

    const apiCallFunction = appointmentId
      ? appointmentService.updateAppointment
      : appointmentService.createAppointment;

    const appointmentDetails = await apiCallFunction({
      appointments: requestAppointments,
      patientInfo: patientInfoRequest,
      eligibilityResponse: requestEligibilityResponses,
      screeningAnswers: requestScreeningAnswers,
      organizationId,
      captchaResponseKey,
      selfDeclaration: { ...requestSelfDeclaration, isAgree },
      selections: selectedServices,
      clinic: selectedClinic,
      selectedLanguageId,
      requiresRegistration,
      registrationCode,
      patientRecordInfo: currentPatientRecordInfo,
      insuranceCoverageList,
      localizedWebContents,
      primaryServiceCheckOnly: isCounsellingSelected,
      insuranceData: appointmentSpecificInsuranceData,
      accessToken: token,
      appointmentId,
      noBlockDetails,
    });

    const {
      insurances: responseInsurances,
      patientInfo: patientInfoResponse,
      appointments: responseAppointments,
    } = appointmentDetails;

    if (
      serviceWiseAppointmentDetails[currentPatientIndex].paymentType ===
      PaymentOptions.Insurance
    ) {
      let uploadFilePromise = [];
      serviceWiseAppointmentDetails[currentPatientIndex].insuranceData.forEach(
        (i) => {
          if (!i) return;
          const insuranceId = getInsuranceIdForMatchingInsuranceDetails(
            responseInsurances,
            i,
          );

          if (insuranceId) {
            uploadFilePromise.push(
              uploadInsuranceFiles(
                insuranceId,
                i.insuranceCardFront,
                i.insuranceCardBack,
                appointmentDetails?.token,
              ),
            );
          }
        },
      );
      await Promise.allSettled(uploadFilePromise);
    }

    const newStateAppointments = appointments.map(
      ({ firstAppointment, secondAppointment }, idx) => {
        const activityId = responseAppointments[idx];
        return {
          firstAppointment: firstAppointment
            ? {
                ...firstAppointment,
                activityid: activityId,
                canBeRescheduled: true,
              }
            : null,
          secondAppointment: secondAppointment
            ? {
                ...secondAppointment,
                activityid: activityId,
                canBeRescheduled: true,
              }
            : null,
        };
      },
    );

    setDetails({
      appointments: newStateAppointments,
      patientInfo: replaceArrayElementByIndex(
        patientInfo,
        currentPatientIndex,
        {
          ...patientInfo[currentPatientIndex],
          confirmationCode: patientInfoResponse.confirmationCode,
        },
      ),
    });
  };

  return { submitAppointment };
};
