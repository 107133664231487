import { useParams } from 'react-router-dom';
import React, { useContext, useState, useEffect } from 'react';

import CONFIG from '../../../../../config';
import Footer from '../../../common/footer';
import Button from '../../../../common/Button';
import ReCaptcha from '../../../common/ReCaptcha';
import * as toast from '../../../../../util/toast';
import NavBar from '../../../common/navbar/navbar';
import FormInput from '../../../../common/FormInput';
import SelectBox from '../../../../common/SelectBox';
import FormErrors from '../../../../common/FormErrors';
import { DetailContext } from '../../../../../context';
import AlertMessage from '../../../common/AlertMessage';
import { isLeapYear } from '../../../../../util/DateAndTime';
import { validateSecurityForm } from './SecurityCheckValidation';
import regexPattern from '../../../../../constants/regexPattern';
import { getFormattedFullName } from '../../../../../util/TextFormat';
import {
  DayList,
  MonthList,
  YearList,
} from '../../../../../constants/patientInfo';
import * as securityCheckService from '../../../../../services/securityVerification';
import { getString } from '../../../../../util/lang';
import { usePrevious } from '../../../../../hooks/usePrevious';
import objectUtils from '../../../../../util/objectUtils';
import RescheduleValidity from '../../RescheduleValidity/container/RescheduleValidity';
import ActivityIndicator from '../../../../common/ActivityIndicator';

const SecurityCheck = (props) => {
  const { confirmSecurity, formType } = props;
  const appContext = useContext(DetailContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isUserValid, setIsUserValid] = useState(true);
  const [captcha, setCaptcha] = useState(null);
  const [isCaptchaAttempted, setIsCaptchaAttempted] = useState(false);
  const [isValidReschedule, setValidReschedule] = useState(true);

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    birthMonth: '',
    birthDay: '',
    birthYear: '',
  });

  const appLanguageCode = appContext.details.appLanguageCode;

  const prevLanguageCode = usePrevious(appLanguageCode);

  useEffect(() => {
    if (formType === 'covidresult') {
      // from result page; skip verification
      setValidReschedule(true);
      setIsLoading(false);
      return;
    }

    const activityId = id || props.activityId;

    if (!activityId) {
      setValidReschedule(false);
      return;
    }

    setValidReschedule(true);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!prevLanguageCode) {
      return;
    }

    if (prevLanguageCode !== appLanguageCode && !objectUtils.isEmpty(errors)) {
      validateFormValues(formValues);
    }
  }, [appLanguageCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormValueChange = (key, value) => {
    if (
      (key === 'firstName' || key === 'lastName' || key === 'middleName') &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    )
      return;
    const formattedValue = formatFormInput(key, value);
    setFormValues({
      ...formValues,
      [key]: formattedValue,
    });
    setErrors(clearError(key, errors));
    setIsUserValid(true);
  };

  const formatFormInput = (key, value) => {
    switch (key) {
      case 'firstName':
        return value.trimStart().replace(/  +/g, ' ');
      case 'middleName':
        return value.trimStart().replace(/  +/g, ' ');
      case 'lastName':
        return value.trimStart().replace(/  +/g, ' ');
      default:
        return value;
    }
  };

  const validateFormValues = (formValues) => {
    const { errors, isValid } = validateSecurityForm(formValues);
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  const submitSecurityCheck = async () => {
    if (CONFIG.captchaEnabled && !captcha) {
      setIsCaptchaAttempted(true);
      return;
    }
    try {
      setIsLoading(true);
      const isFormValid = validateFormValues(formValues);
      const isCaptchaValid = CONFIG.captchaEnabled ? !!captcha : true;

      if (isFormValid && isCaptchaValid) {
        const userDetails = {
          ...formValues,
          id: id || props.activityId,
        };
        const securityResponse =
          await securityCheckService.verifySecurityQuestions(
            id || props.activityId,
            {
              captcha,
              ...userDetails,
            },
          );

        if (securityResponse?.data.isDetailsValid) {
          const patientFullName = getFormattedFullName({
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
          });

          confirmSecurity(true, {
            ...formValues,
            name: patientFullName,
            activityId: id || props.activityId,
            token: securityResponse.data.token,
          });
        } else {
          setIsUserValid(false);
          CONFIG.captchaEnabled && window.grecaptcha.reset();
          setCaptcha(null);
          setIsCaptchaAttempted(false);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error({
        title: '',
        message: getString('errorMessage'),
      });
      CONFIG.captchaEnabled && window.grecaptcha.reset();
    } finally {
      setIsLoading(false);
    }
  };

  const clearError = (key, { [key]: _, ...restErrors }) => restErrors;

  const getDaysInMonth = (birthMonth, birthYear) => {
    const TwentyNineDayMonths = ['2'];
    const ThirtyDayMonths = ['4', '6', '9', '11'];
    if (TwentyNineDayMonths.includes(birthMonth)) {
      if (!isLeapYear(birthYear)) {
        return DayList.slice(0, 28);
      }
      return DayList.slice(0, 29);
    } else if (ThirtyDayMonths.includes(birthMonth)) {
      return DayList.slice(0, 30);
    }
    return DayList;
  };

  const onCaptchaChange = (captchaResponseKey) => {
    setCaptcha(captchaResponseKey);
  };

  const headerMsg =
    formType === 'covidresult'
      ? getString('covidresultHeader')
      : getString('securityCheckHeader');
  const alertMsg =
    formType === 'covidresult'
      ? getString('covidresultAlert')
      : getString('securityCheckAlert');

  if (isLoading) {
    return <ActivityIndicator className="loader--clinic" />;
  }

  if (!isValidReschedule) {
    return <RescheduleValidity />;
  }

  return (
    <>
      <NavBar
        displayNavLinks={false}
        logo={appContext.details.appSettings.logoUrl}
      />
      <div className="main-wrapper">
        <div className="container">
          <section className="form-patient-info section__margin reschedule__wrapper">
            <h2 tabIndex="0">{headerMsg}</h2>
            <AlertMessage
              isVisible
              type="info"
              className="mb-6x mt-4x"
              message={alertMsg}
            />
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="patient-form-group">
                <div className="row">
                  <div className="col-4-md">
                    <FormInput
                      label={getString('firstName')}
                      id="firstName"
                      onChange={handleFormValueChange}
                      value={formValues.firstName}
                      hasError={!!errors['firstName']}
                      required={true}
                      data-qa="firstname-security-check"
                    />
                  </div>
                  <div className="col-4-md">
                    <FormInput
                      label={getString('middleName')}
                      id="middleName"
                      onChange={handleFormValueChange}
                      value={formValues.middleName}
                      hasError={!!errors['middleName']}
                      required={false}
                      data-qa="middlename-security-check"
                    />
                  </div>
                  <div className="col-4-md">
                    <FormInput
                      label={getString('lastName')}
                      id="lastName"
                      onChange={handleFormValueChange}
                      value={formValues.lastName}
                      hasError={!!errors['lastName']}
                      required={true}
                      data-qa="lastname-security-check"
                    />
                  </div>
                  <div className="col-4">
                    <SelectBox
                      label={getString('year')}
                      id="birthYear"
                      defaultValue={getString('birthYear')}
                      onChange={(e) =>
                        handleFormValueChange('birthYear', e.target.value)
                      }
                      value={formValues.birthYear}
                      hasError={!!errors['birthYear']}
                      required={true}
                      options={YearList()}
                      dataqa="birthyear-security-check"
                    />
                  </div>
                  <div className="col-4">
                    <SelectBox
                      id="birthMonth"
                      onChange={(e) =>
                        handleFormValueChange('birthMonth', e.target.value)
                      }
                      defaultValue={getString('birthMonth')}
                      label={getString('birthMonth')}
                      value={formValues.birthMonth}
                      options={MonthList}
                      hasError={!!errors['birthMonth']}
                      required={true}
                      dataqa="birthmonth-security-check"
                    />
                  </div>
                  <div className="col-4">
                    <SelectBox
                      id="birthDay"
                      onChange={(e) =>
                        handleFormValueChange('birthDay', e.target.value)
                      }
                      defaultValue={getString('birthDay')}
                      label={getString('birthDay')}
                      value={formValues.birthDay}
                      options={getDaysInMonth(
                        formValues.birthMonth,
                        formValues.birthYear,
                      )}
                      hasError={!!errors['birthDay']}
                      required={true}
                      dataqa="birthday-security-check"
                    />
                  </div>
                </div>
              </div>
              <FormErrors errors={errors} />
              <br />
              {CONFIG.captchaEnabled && (
                <ReCaptcha
                  isLoading={false}
                  onCaptchaChange={onCaptchaChange}
                  isCaptchaValid={isCaptchaAttempted ? !!captcha : true}
                />
              )}
              <div className="mt-4x">
                <Button
                  onClick={submitSecurityCheck}
                  label={getString('submit')}
                  isEnabled={!isLoading}
                  isLoading={isLoading}
                  dataqa="submit-security-check"
                />
              </div>
              <AlertMessage
                isVisible={!isUserValid}
                type="danger"
                className="mb-6x mt-6x"
                message={getString('securityCheckValidationAlert')}
                dataqa="alert-security-check"
              />
            </form>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SecurityCheck;
