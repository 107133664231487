import { useParams } from 'react-router-dom';

export const useAppParams = () => {
  const { organizationId, id, appointmentId } = useParams();

  return {
    organizationId,
    appointmentId: appointmentId || id,
  };
};
