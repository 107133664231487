import { getString } from 'util/lang';
import { validateDate } from './helper';

export const validateDeliveryTicket = (data) => {
  let hasError = false;
  const errors = {
    proofOfDelivery: {},
    consent: {},
    setupSignatureData: '',
  };

  // Validating date in questionnaire
  const consentData = data?.consent;
  const setupSignatureData = data?.setupSignatureData;

  if (!consentData.signature) {
    errors.consent.signature = getString(
      'dme.validation.requiredFieldMessage',
      {
        field: getString('dme.deliveryPacketForm.authorizationForm.signature'),
      },
    );
    hasError = true;
  }

  if (!consentData.signedDate) {
    errors.consent.signedDate = getString(
      'dme.validation.requiredFieldMessage',
      {
        field: getString('dme.deliveryPacketForm.authorizationForm.signedDate'),
      },
    );
    hasError = true;
  }

  if (!setupSignatureData) {
    errors.setupSignatureData = getString(
      'dme.validation.requiredFieldMessage',
      {
        field: getString('dme.deliveryPacketForm.authorizationForm.signedDate'),
      },
    );
    hasError = true;
  }

  return { errors, hasError };
};
