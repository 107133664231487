import React from 'react';
import { KEY_CODE_ENTER, KEY_CODE_SPACE } from '../../../constants/keyCodes';

import { getString } from '../../../util/lang';

export const SignaturePlaceholder = ({ onClick, isOpen }) => {
  if (!isOpen) {
    return null;
  }

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode === KEY_CODE_ENTER || keyCode === KEY_CODE_SPACE) {
      // consume the event
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div
      data-qa="patient-info-signature-add"
      tabIndex="0"
      onClick={onClick}
      className="signature-add border border-radius"
      onKeyDown={handleKeyDown}
    >
      {getString('signaturePlaceholder')}
    </div>
  );
};
