import React from 'react';
import { Route } from 'react-router-dom';

/**
 * A route that render the home page which allows to change the appointment details for a patient
 * @param Component : ClinicAndTiming Page
 * @return ClinicAndTiming page with addition parameter "rescheduleComponent",
 *  causing it to act as reschedule page
 **/
const RescheduleRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <Component {...routeProps} rescheduleComponent={true} />
      )}
    />
  );
};

export default RescheduleRoute;
