import Button from 'components/common/Button';
import Modal from 'components/COVID/common/Modal';
import { getString } from 'util/lang';
import styles from './EditServiceModal.module.scss';

export const EditServiceModal = ({ onClose, onConfirm }) => {
  return (
    <Modal
      headerContent={getString('editServiceConfirmation')}
      isClosable
      close={onClose}
      containerClassName={styles.EditServiceModal}
    >
      <div className={styles.textContainer}>{getString('editServiceInfo')}</div>

      <div className={styles.buttonContainer}>
        <Button
          isEnabled
          outline
          label={getString('dismiss')}
          onClick={onClose}
          classNames={{ button: styles.button }}
        />

        <Button
          isEnabled
          label={getString('positiveConfirmation')}
          onClick={onConfirm}
          classNames={{ button: styles.button }}
        />
      </div>
    </Modal>
  );
};
