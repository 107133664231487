import { useAppParams } from 'context/useAppParams';
import { useSecurityToken } from 'context/useSecurityToken';
import { useNavigationHelper, routes } from 'hooks/useNavigationHelper';
import { Route } from 'react-router-dom';

const makeFollowupRescheduleDefaultUrl = (appointmentId, organizationId) =>
  routes.followup.reschedule.verify(organizationId, appointmentId);
const makeFollowupRegisterDefaultUrl = (appointmentId, organizationId) =>
  routes.followup.register.verify(organizationId, appointmentId);

/**
 * @type {typeof Route}
 */
export const FollowupRescheduleRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={() => (
        <WithSecurityTokenCheck fallbackUrl={makeFollowupRescheduleDefaultUrl}>
          <Component />
        </WithSecurityTokenCheck>
      )}
    />
  );
};

/**
 * @type {typeof Route}
 */
export const FollowupRegisterRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={() => (
        <WithSecurityTokenCheck fallbackUrl={makeFollowupRegisterDefaultUrl}>
          <Component />
        </WithSecurityTokenCheck>
      )}
    />
  );
};

/**
 * @typedef {{ fallbackUrl: (appointmentId: string, organizationId?: string) => string }} Props
 *
 * @param {React.PropsWithChildren<Props>} props
 * @returns
 */
const WithSecurityTokenCheck = ({ children, fallbackUrl }) => {
  const { securityToken } = useSecurityToken();
  const nav = useNavigationHelper();
  const { appointmentId, organizationId } = useAppParams();

  if (!securityToken) {
    nav.toUrl(fallbackUrl(appointmentId, organizationId), { replace: true });
    return null;
  }
  return children;
};

// Note: due to limitations for dynamic urls in react-router v5, using this custom component to redirect
export const RedirectToRescheduleVerify = () => {
  const nav = useNavigationHelper();
  const { appointmentId } = useAppParams();

  nav.toFollowup.reschedule.verify(appointmentId);
  return null;
};

export const RedirectToRegisterVerify = () => {
  const nav = useNavigationHelper();
  const { appointmentId } = useAppParams();

  nav.toFollowup.register.verify(appointmentId);
  return null;
};
