import { useEffect, useState } from 'react';

import { FiChevronDown } from 'vyaguta-icons/fi';
import { UiMedicalSquareFull, UiUsersAlt } from 'vyaguta-icons/ui';

import { getString } from 'util/lang';

const getServiceStatus = (id, services) => {
  const service = services.find((s) => s.id === id);
  return service ? service.groupAppointmentStatus : false;
};

const groupAppointmentServiceLabel = getString('groupAppointmentServiceLabel');

const SelectService = (props) => {
  const {
    selectedService,
    selectedServiceType,
    services,
    onServiceChange,
    showGroupLabel = false,
  } = props;

  const [isGroupServiceEnabled, setIsGroupServiceEnabled] = useState(false);

  useEffect(() => {
    setIsGroupServiceEnabled(getServiceStatus(selectedService, services));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  const handleServiceChange = (event) => {
    const selectedServiceId = event.currentTarget.value;
    setIsGroupServiceEnabled(getServiceStatus(selectedServiceId, services));
    onServiceChange(event.currentTarget.value);
  };

  const disabledVaccineLabel = selectedServiceType
    ? getString('vaccinePlaceholder')
    : getString('serviceFirstPlaceholder');

  const disabledOptionValue = '-1';
  const selectedServiceId = selectedService
    ? selectedService
    : disabledOptionValue;

  return (
    <div className="form-group">
      <div className="form-select-wrapper">
        <select
          id="vaccine-selection"
          className="form-control form-control-full-bordered  form-control-w-icon"
          onChange={handleServiceChange}
          value={selectedServiceId}
          data-qa="vaccine-selection"
        >
          <>
            <option value={disabledOptionValue} disabled hidden></option>
            {services?.map((service) => {
              return (
                <option value={service.id} key={service.id}>
                  {service.name}
                </option>
              );
            })}
          </>
        </select>

        {!selectedService && (
          <label htmlFor="vaccine-selection" className="label-placeholder">
            {disabledVaccineLabel}
          </label>
        )}

        <div className="form-control-icon">
          <UiMedicalSquareFull size={22} className="color-primary-base" />
        </div>
        <div className="form-control-dropdown">
          <FiChevronDown size={20} />
        </div>
      </div>
      {showGroupLabel && isGroupServiceEnabled && (
        <div className="form-group-disclaimer my-1x">
          <UiUsersAlt size={18} className="color-primary-base" />{' '}
          {groupAppointmentServiceLabel}
        </div>
      )}
    </div>
  );
};

export default SelectService;
