import { Document, Font, Page } from '@react-pdf/renderer';

import { useStyles } from './ABNFormPdf.styles';
import { concatenateWithSeparator } from 'util/string';
import { ABNFormTitle } from './components/ABNFormTitle';
import { ABNFormTable } from './components/ABNFormTable';
import { ABNFormFooter } from './components/ABNFormFooter';
import { ABNFormHeader } from './components/ABNFormHeader';
import { ABNFormOptions } from './components/ABNFormOptions';
import { ABNFormSignature } from './components/ABNFormSignature';
import { ABNFormLegalNotice } from './components/ABNFormLegalNotice';
import { ABNFormAdditionalInfo } from './components/ABNFormAdditionalInfo';
import {
  ABNLang,
  printFontBoldCDN,
  printFontFamily,
  printFontRegularCDN,
} from 'constants/dme';

const ABNFormPdfView = ({
  notifier,
  orderItem,
  isMedicare,
  productOrder,
  primaryPayerName = 'Aetna',
  signedDocumentDetail,
}) => {
  const styles = useStyles;
  Font.register({
    family: printFontFamily,
    fonts: [
      {
        src: printFontRegularCDN,
        fontWeight: 400,
      },
      {
        src: printFontBoldCDN,
        fontWeight: 700,
      },
    ],
  });

  const abnLang = isMedicare ? ABNLang.MEDICARE : ABNLang.NON_MEDICARE;
  const abnData = `dme.documentation.abnForm.${abnLang}`;
  const signedOrderItemData = signedDocumentDetail.orderItems.find(
    (orderItem) => orderItem.orderItemId === orderItem.orderItemId,
  );
  return (
    <Document>
      <Page style={styles.page}>
        <ABNFormHeader
          orderItem={orderItem}
          patientFirstname={productOrder.firstName}
          patientMiddleName={productOrder.middleName || ''} // Handle undefined middleName
          patientLastName={productOrder.lastName}
          identificationNumber={orderItem.abnIdentificationNumber}
          notifier={notifier}
        />
        <ABNFormTitle abnData={abnData} primaryPayerName={primaryPayerName} />
        <ABNFormTable
          abnData={abnData}
          productHcpcs={concatenateWithSeparator(
            orderItem.hcpcsName,
            orderItem.hcpcsShortDescription,
            '-',
          )}
          reason={orderItem.reasonMedicareMayNotPay}
          estimatedCost={orderItem.abnEstimatedCost}
        />
        <ABNFormOptions
          abnData={abnData}
          option1={orderItem.abnOption1}
          option2={orderItem.abnOption2}
          option3={orderItem.abnOption3}
          primaryPayerName={primaryPayerName}
        />
        <ABNFormAdditionalInfo
          abnData={abnData}
          abnAdditionalInfo={orderItem.abnAdditionalInformation}
        />
        <ABNFormSignature
          signedOrderItemData={signedOrderItemData}
          abnData={abnData}
        />
        {isMedicare && (
          <>
            <ABNFormLegalNotice />
            <ABNFormFooter />
          </>
        )}
      </Page>
    </Document>
  );
};

export default ABNFormPdfView;
