import React, { useMemo } from 'react';

import PaymentInfo from './container/PaymentInfo';
import GroupPatientInfo from '../PatientInformation/container/GroupPatientInfo';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import {
  makeDefaultNavLinks,
  makeGroupAppointmentNavLinks,
} from 'components/common/NavBar/navLinks';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useDetailContext, useSelectionsContext } from '../../../../context';
import { CovidPageNames } from '../../../../constants/pages';
import ActivityIndicator from 'components/common/ActivityIndicator';
import { changeTitle } from 'util/siteInfo';

export const usePaymentInfo = () => {
  const {
    details,
    pageComplete,
    setInsuranceCoverageList,
    getPayerlist,
    fetchPayerList,
    getAppSettings,
    setDetails,
    postServiceDataSetup,
    setLoading,
    noInsuranceEnabled,
    isCreditCardAccepted,
    showPayment,
    setPageComplete,
  } = useDetailContext();

  const { currentPatientIndex, isGroupAppointment, patientInfo } = details;

  const { selectedClinic, selectedServices } = useSelectionsContext();

  const setThisPageCompleteStatus = (value) => {
    if (isGroupAppointment && currentPatientIndex === patientInfo.length - 1) {
      setPageComplete({
        [CovidPageNames.PaymentInfo]: value,
      });
    } else {
      setPageComplete({
        [CovidPageNames.PaymentInfo]: value,
      });
    }
  };

  const setSelfAndNextPageIncomplete = () => {
    setPageComplete({
      [CovidPageNames.PaymentInfo]: false,
      [CovidPageNames.Confirmation]: false,
    });
  };

  return {
    PaymentInfoProps: {
      details,
      pageComplete,
      setInsuranceCoverageList,
      getPayerList: getPayerlist,
      fetchPayerList,
      getAppSettings,
      setDetails,
      postServiceDataSetup,
      setLoading,
      noInsuranceEnabled,
      isCreditCardAccepted,
      showPayment,
      selectedServices,
      selectedClinic,
      setSelfAndNextPageIncomplete,
      setThisPageCompleteStatus,
      primarySelection: selectedServices[0],
    },
  };
};

export const PaymentInformation = ({
  toNextPage,
  goBack,
  organizationId,
  appointmentId,
  disableEdit = false,
  confirmEditService = false,
  isFollowup = false,
  goToNextMenuGroupSideBar = () => {},
  isGroupAppointment = false,
  disabledFieldStatus = {},
}) => {
  changeTitle('Payment Information');
  const { PaymentInfoProps } = usePaymentInfo();
  const {
    details: { loading },
  } = useDetailContext();

  if (loading) {
    return <ActivityIndicator className="loader--clinic" />;
  } else
    return (
      <>
        {isGroupAppointment ? (
          <GroupPatientInfo
            {...PaymentInfoProps}
            toNextPage={toNextPage}
            navigateGroupNavbar={goToNextMenuGroupSideBar}
            goBack={goBack}
            organizationId={organizationId}
            appointmentId={appointmentId}
            disableEdit={disableEdit}
            confirmEditService={confirmEditService}
            isFollowup={isFollowup}
          />
        ) : (
          <PaymentInfo
            {...PaymentInfoProps}
            toNextPage={toNextPage}
            navigateGroupNavbar={goToNextMenuGroupSideBar}
            goBack={goBack}
            organizationId={organizationId}
            appointmentId={appointmentId}
            disableEdit={disableEdit}
            confirmEditService={confirmEditService}
            isFollowup={isFollowup}
            disabledFieldStatus={disabledFieldStatus}
          />
        )}
      </>
    );
};

export const PaymentInformationPage = ({
  goToNextMenuGroupSideBar = () => {},
  isGroupAppointment = false,
}) => {
  const nav = useNavigationHelper();
  const { organizationId, appointmentId } = useAppParams();
  const {
    details: { pageComplete, selectedLocationId, selectedServices },
  } = useDetailContext();

  const toNextPage = () => {
    nav.toConfirmation();
  };

  const goBack = () => {
    nav.toPatientInfo();
  };

  const navLinks = useMemo(
    () =>
      isGroupAppointment
        ? makeGroupAppointmentNavLinks(pageComplete, organizationId)
        : makeDefaultNavLinks(pageComplete, organizationId),
    [organizationId, pageComplete, appointmentId],
  );

  if (!selectedServices.length && !selectedLocationId) {
    nav.resetToFirstPage();

    return null;
  }

  return (
    <>
      {isGroupAppointment ? (
        <PaymentInformation
          confirmEditService={true}
          organizationId={organizationId}
          appointmentId={appointmentId}
          goBack={goBack}
          toNextPage={toNextPage}
          goToNextMenuGroupSideBar={goToNextMenuGroupSideBar}
          isGroupAppointment={isGroupAppointment}
        />
      ) : (
        <PageWrapper showNavMenu navLinks={navLinks}>
          <PaymentInformation
            confirmEditService={true}
            organizationId={organizationId}
            appointmentId={appointmentId}
            goBack={goBack}
            toNextPage={toNextPage}
          />
        </PageWrapper>
      )}
    </>
  );
};
