import React from 'react';

import DoseNumber from 'constants/covid/doseNumber';

import AppointmentInformation from './AppointmentInformation';
import { getString } from 'util/lang';
import MessageBulletListBox from 'components/COVID/common/MessageBulletListBox';
import { MessageBtn } from 'components/COVID/common/MessageBtn';

const VaccineInformation = ({
  appointments,
  selectedServices,
  onEditService,
  onDateTimeEdit,
}) => {
  if (!selectedServices || selectedServices.length < 1) {
    // invalid
    return null;
  }

  // Get and Map selected services to be passed to MessageBulletListBox Component
  // [{message: service1_name}, {message: service2_name}?]
  const serviceNames = selectedServices.map(({ service }) => ({
    message: service.name,
  }));

  if (selectedServices.length === 1) {
    return (
      <SingleServiceInfo
        appointment={appointments[0]}
        serviceName={serviceNames}
        onDateTimeEdit={onDateTimeEdit}
        onEditService={onEditService}
      />
    );
  }

  // multiservice
  const primaryAppointment =
    appointments[0].firstAppointment || appointments[0].secondAppointment;

  return (
    <div className="section__container">
      <div className="d-flex justify-content-between">
        <h3>{getString('vaccineInformation')}</h3>
        {onEditService && (
          <MessageBtn
            label={getString('editService')}
            onClick={onEditService}
          />
        )}
      </div>

      <MessageBulletListBox messages={serviceNames} className="mb-8x" />

      <AppointmentInformation
        appointmentType="1st"
        doseNumber={DoseNumber.FirstDose}
        appointment={primaryAppointment}
        onDateTimeEdit={onDateTimeEdit}
        isFulfilled={!primaryAppointment.canBeRescheduled}
      />
    </div>
  );
};

const SingleServiceInfo = ({
  appointment,
  serviceName,
  onDateTimeEdit,
  onEditService,
}) => {
  return (
    <div className="section__container">
      <div className="d-flex justify-content-between">
        <h3>{getString('vaccineInformation')}</h3>
        {onEditService && (
          <MessageBtn
            label={getString('editService')}
            onClick={onEditService}
          />
        )}
      </div>

      <MessageBulletListBox messages={serviceName} className="mb-8x" />

      <AppointmentInformation
        appointmentType="1st"
        doseNumber={DoseNumber.FirstDose}
        appointment={appointment.firstAppointment}
        onDateTimeEdit={onDateTimeEdit}
        isFulfilled={!appointment.firstAppointment.canBeRescheduled}
      />
    </div>
  );
};

export default VaccineInformation;
