import { useDetailContext, useSelectionsContext } from 'context';
import { CovidPageNames } from 'constants/pages';

export const usePatientInfo = () => {
  const {
    details,
    pageComplete,
    setInsuranceCoverageList,
    getPayerlist,
    fetchPayerList,
    getAppSettings,
    setDetails,
    postServiceDataSetup,
    setLoading,
    noInsuranceEnabled,
    isCreditCardAccepted,
    showPayment,
    setPageComplete,
  } = useDetailContext();

  const { currentPatientIndex, isGroupAppointment, patientInfo } = details;

  const { selectedClinic, selectedServices } = useSelectionsContext();

  const setThisPageCompleteStatus = (value) => {
    if (isGroupAppointment && currentPatientIndex === patientInfo.length - 1) {
      setPageComplete({
        [CovidPageNames.PatientEligibility]: value,
      });
    } else {
      setPageComplete({
        [CovidPageNames.PatientInfo]: value,
      });
    }
  };

  const setSelfAndNextPageIncomplete = () => {
    setPageComplete({
      [CovidPageNames.PatientInfo]: false,
      [CovidPageNames.PaymentInfo]: false,
      [CovidPageNames.Confirmation]: false,
    });
  };

  return {
    PatientInfoProps: {
      details,
      pageComplete,
      setInsuranceCoverageList,
      getPayerList: getPayerlist,
      fetchPayerList,
      getAppSettings,
      setDetails,
      postServiceDataSetup,
      setLoading,
      noInsuranceEnabled,
      isCreditCardAccepted,
      showPayment,
      selectedServices,
      selectedClinic,
      setSelfAndNextPageIncomplete,
      setThisPageCompleteStatus,
      primarySelection: selectedServices[0],
    },
  };
};
