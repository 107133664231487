import { toStandardDateFormat } from 'util/DateAndTime';
import { DeliveryMethodType } from '../constants/deliveryMethodType';

const purchaseTypeChoices = [
  {
    label: 'Rental',
    value: 875080000,
  },
  {
    label: 'Purchase - new',
    value: 875080001,
  },
  {
    label: 'Purchase - used',
    value: 875080002,
  },
];

export const DeliveryMethodValue = {
  PICK_UP: 153940000,
  DIRECT_DELIVERY: 153940001,
  VIA_SHIPPING: 153940002,
  TO_NURSING_FACILITY: 916310001,
};

const deliveryMethodChoices = [
  {
    label: DeliveryMethodType.PICK_UP,
    value: DeliveryMethodValue.PICK_UP,
  },
  {
    label: DeliveryMethodType.DIRECT_DELIVERY,
    value: DeliveryMethodValue.DIRECT_DELIVERY,
  },
  {
    label: DeliveryMethodType.VIA_SHIPPING,
    value: DeliveryMethodValue.VIA_SHIPPING,
  },
  {
    label: DeliveryMethodType.TO_NURSING_FACILITY,
    value: DeliveryMethodValue.TO_NURSING_FACILITY,
  },
];

const getPurchaseTypeLabel = (purchaseTypeValue) => {
  const purchaseTypeChoice = purchaseTypeChoices.find(
    (purchaseTypeChoice) => purchaseTypeChoice.value === purchaseTypeValue,
  );

  return purchaseTypeChoice?.label;
};

export const getDeliveryMethodLabel = (deliveryMethodValue) => {
  const deliveryMethodChoice = deliveryMethodChoices.find(
    (deliveryMethodChoice) =>
      deliveryMethodChoice.value === deliveryMethodValue,
  );

  return deliveryMethodChoice?.label;
};

export const adaptOrder = (orderFromResponse, directDeliveryAddress) => {
  const {
    orderItemId,
    hcpcsId,
    hcpcsCode,
    orderItemName,
    hcpcsDescription,
    purchaseType,
    serialNumber,
    quantity,
    warrantyInMonths,
    warrantyInYears,
    shippingCompany,
    trackingNumber,
    patientResponsibility,
    discount,
    // TODO: Refactor the below logic during share delivery packet feature
    // deliveryPacketSharedAt,
    // deliveryTicketSharedAt,
  } = orderFromResponse;

  return {
    orderItemId,
    orderItemName,
    hcpcsId,
    hcpcsCode,
    hcpcsDescription,
    serialNumber,
    quantity,
    purchaseType: getPurchaseTypeLabel(purchaseType),
    billedPrice: patientResponsibility - discount,
    warrantyInMonths,
    warrantyInYears,
    shippingCompany,
    trackingNumber,
  };
};

export const adaptOrders = (ordersFromResponse, directDeliveryAddress) =>
  ordersFromResponse.map((orderFromResponse) =>
    adaptOrder(orderFromResponse, directDeliveryAddress),
  );

export const adaptOrderForRequestBody = (order) => {
  const {
    orderItemId,
    deliveryPacketGenerated,
    warrantyInMonths,
    warrantyInYears,
  } = order;

  return {
    orderItemId,
    deliveryPacketGenerated,
    warrantyInMonths,
    warrantyInYears,
  };
};

export const adaptOrdersForRequestBody = (orders) =>
  orders.map(adaptOrderForRequestBody);
