import { StyleSheet, Text, View } from '@react-pdf/renderer';

const LineBreakPdfView = ({ titleText, style = {} }) => {
  return (
    <View style={{ ...styles.wrap, ...style }}>
      <View style={styles.line} />
      {!!titleText && (
        <View style={styles.textWrap}>
          <Text style={styles.text}>{titleText}</Text>
        </View>
      )}
      <View style={styles.line} />
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  line: {
    border: '0.5px solid #E1DFDD',
    marginTop: 20,
    marginBottom: 20,
    flex: 1,
  },
  text: {
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingHorizontal: 3,
  },
  textWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default LineBreakPdfView;
