import React, { useContext, useEffect } from 'react';
import cls from 'classnames';
import { FiCheck } from 'vyaguta-icons/fi';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { getString } from 'util/lang';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import LanguageSelect from 'components/COVID/common/LanguageSelect';
import { useResetAppState } from '../../../context/useResetAppState';
import { DetailContext } from 'context';

/**
 * @param {{
 *  pageNumber: string
 *  label: string
 *  complete: boolean
 *  to: string
 *  active: boolean
 *  loading: boolean
 *  dataqa: string
 * }} props
 */
const NavTab = (props) => {
  const { pageNumber, label, complete, to, active, loading, dataqa } = props;
  const routeMatch = useRouteMatch(to);
  const content = complete ? (
    <FiCheck size={14} className="icon" />
  ) : (
    pageNumber
  );
  const { setDetails } = useContext(DetailContext);

  useEffect(() => {
    // scroll and snap selected nav to view
    if (routeMatch) {
      let scrollWrapper = document.querySelector('.node__wrapper');
      let scrollElemtr = document.querySelector('.nav__link--active');

      if (scrollElemtr) {
        setTimeout(() => {
          let scroller = scrollElemtr.parentElement;
          scrollWrapper.scrollLeft = scroller && scroller.offsetLeft;
        }, 10);
      }
    }
  }, [routeMatch]);

  const linkActive = active && !loading;

  return (
    <div className="nav__node">
      <NavLink
        to={{ pathname: to, search: window?.location?.search }}
        className={cls(
          'nav__link',
          complete && 'nav__link--complete',
          !linkActive && 'nav__link--disabled',
        )}
        activeClassName="nav__link--active"
        onClick={(evt) => {
          setDetails({ currentPatientIndex: 0 });
          if (!linkActive) {
            evt.preventDefault();
          }
        }}
      >
        <span data-qa={dataqa}>{content}</span>
        {label}
      </NavLink>
    </div>
  );
};

/**
 * @param {{
 *  logo: string
 *  displayNavLinks: boolean
 *  isLandingPage: boolean
 *  loading: boolean,
 *  navLinks: NavLinkConfig[]
 * }} props
 * @returns
 */
export const NavBar = ({
  displayNavLinks = true,
  logo,
  loading,
  isLandingPage,
  navLinks,
}) => {
  const nav = useNavigationHelper();
  const resetAppState = useResetAppState();

  const refreshPage = () => {
    resetAppState();
    nav.resetToFirstPage();
  };

  return (
    <>
      <div
        className={cls(
          'navbar__header',
          isLandingPage && 'navbar__header--landing-page',
        )}
      >
        <div className="navbar__logo text-center">
          {!!logo && (
            <>
              <div className="navbar__logo text-center">
                <img
                  src={logo}
                  onClick={refreshPage}
                  alt={getString('organizationLogo')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </>
          )}
        </div>
        <LanguageSelect />
      </div>
      {displayNavLinks && (
        <nav className="navbar navbar--sticky">
          <div className="nav">
            <div className="d-flex justify-content-center-lg node__wrapper">
              {navLinks.map((navLink) => (
                <NavTab
                  key={navLink.label}
                  to={navLink.to}
                  pageNumber={navLink.pageNumber}
                  complete={navLink.complete}
                  active={navLink.active}
                  label={navLink.label}
                  loading={loading}
                  dataqa={navLink.dataqa}
                />
              ))}
            </div>
          </div>
        </nav>
      )}
    </>
  );
};
