import { MessageBtn } from 'components/COVID/common/MessageBtn';
import styles from './selectedServiceMessageBox.module.scss';

const SelectedServiceMessageBox = (props) => {
  return (
    <div className={styles.messageBoxContainer}>
      <div>
        <p className={styles.title}>Selected service</p>
        <p>{props.selectedService}</p>
      </div>
      <div className={styles.messageBtn}>
        <MessageBtn label="Change Service" onClick={props.onChangeService} />
      </div>
    </div>
  );
};

export default SelectedServiceMessageBox;
