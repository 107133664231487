export default function LeftArrow({
  color = '#515151',
  width = '11',
  height = '18',
  className = '',
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6665 15.5025L4.17817 9L10.6665 2.4975L8.669 0.5L0.169005 9L8.669 17.5L10.6665 15.5025Z"
        fill={color}
      />
    </svg>
  );
}
