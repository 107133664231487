import React from 'react';
import cls from 'classnames';
import styles from './IconButton.module.scss';
import ActivityIndicator from './ActivityIndicator';
import { FiPlusCircle } from 'vyaguta-icons/all';

/**
 * @typedef {{
 *  onClick?: React.MouseEventHandler<HTMLElement>
 *  label: string
 *  isEnabled?: boolean
 *  isLoading?: boolean
 *  className?: string
 *  title: string
 *  dataqa?: string
 *  outline?: boolean
 *  classNames?: {
 *    button?: string
 *    ActivityIndicator?: string
 *  }
 * }} Props
 *
 * @param {Props} props
 */
const IconButton = (props) => {
  const {
    onClick,
    label,
    isEnabled,
    isLoading = false,
    className = null,
    title,
    dataqa,
    outline,
    labelIcon,
    classNames = {},
  } = props;
  const allowClick = !isLoading && isEnabled;

  return (
    <>
      <button
        className={cls({
          // TODO: make this uniform
          'btn btn-primary--outline btn-block': !className,
          [className]: !!className,
          'btn--disabled': !allowClick,
          'btn-primary': outline,
          [styles.IconButtonMain]: true,
          [classNames.button]: classNames.button,
        })}
        title={title}
        onClick={allowClick ? onClick : () => {}}
        disabled={!allowClick}
        data-qa={dataqa}
      >
        <span
          data-qa="btn-label-icon"
          className={cls(styles.IconButtonMain, styles.LabelIcon)}
        >
          {labelIcon}
        </span>
        {!isLoading && label}
        {isLoading && (
          <ActivityIndicator
            className={cls('loader--button', classNames.ActivityIndicator)}
            marginBottom={false}
          />
        )}
      </button>
    </>
  );
};

export const AddPatientButton = (props) => {
  const LabelIcon = (
    <FiPlusCircle
      size={24}
      className={cls({
        [styles.LabelIcon]: props.isEnabled,
        [styles.LabelIconDisabled]: !props.isEnabled,
      })}
    />
  );

  return <IconButton {...props} labelIcon={LabelIcon} />;
};

export default IconButton;
