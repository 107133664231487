import config from '../config';
import * as http from '../util/http';

/**
 * Fetch All States from Dataverse
 *
 *
 * @returns {Promise<{value: string, label: string}[]>} data: List of States with their id and state code
 */
export async function fetchStates() {
  const url = `${config.entity.states}`;
  const { data: response } = await http.get(url);

  if (response && response.success && Array.isArray(response.data)) {
    return mapStateToLabelValue(response.data);
  }

  // TODO: log possible issue becasuse of missing states
  console.log('Empty or invalid fetch states response');

  return [];
}

/**
 *
 * @param {{id: string, name: string}[]} states
 * @returns {{value: string, label: string}[]}
 */
export const mapStateToLabelValue = (states) => {
  return states.map((state) => ({
    value: state.id,
    label: state.name,
  }));
};
