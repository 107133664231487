import config from '../config';
import * as http from '../util/http';
import {
  convertAppointmentObjectToDynamicsFormat,
  convertPatientInfoObjectToDynamicsFormat,
  getCreateOrUpdateAppointmentPayload,
} from './create-update-appointment';

export async function createAppointment(params) {
  const {
    appointments,
    patientInfo,
    captchaResponseKey,
    screeningAnswers,
    selfDeclaration,
    eligibilityResponse,
    recordInfo,
    blockDetail,
  } = getCreateOrUpdateAppointmentPayload(params);

  const { data } = await http.post(config.entity.createAppointment, {
    appointments,
    patientInfo,
    captchaResponseKey,
    screeningAnswers,
    selfDeclaration,
    eligibilityResponse,
    recordInfo,
    blockDetail,
  });

  return data.data;
}

export async function createBatchAppointment({
  appointments,
  patientInfo,
  eligibilityResponse,
  screeningAnswers,
  organizationId,
  captchaResponseKey,
  selfDeclaration,
  selections,
  selectedLanguageId,
  requiresRegistration,
  registrationCode,
  patientRecordInfo,
  clinic,
  phicureResponse,
  insuranceCoverageList,
  localizedWebContents,
  primaryServiceCheckOnly,
}) {
  const recordInfo = {
    isExistingPatient: patientRecordInfo.isExistingPatient,
    patientId: patientRecordInfo.patientId,
  };

  patientInfo.mobileNo = patientInfo.mobileNo.replaceAll('-', '');
  patientInfo.physicianPhoneNumber =
    patientInfo.physicianPhoneNumber.replaceAll('-', '');

  // separator @@
  const termsAndConditionContents = localizedWebContents.map((content) => {
    return `
      ${content.termsAndCondition}
  
      @@
  
      ${content.termsAndConditionsAcceptance.map((item) => item.label).join('\n')}
      `;
  });

  const requestAppointments = appointments.map(
    ({ first: firstAppointment, second: secondAppointment }, idx) =>
      firstAppointment
        ? convertAppointmentObjectToDynamicsFormat({
            patientInfo,
            subService: selections[idx].subService,
            appointment: firstAppointment,
            organizationId,
            appointmentStatus:
              selections[idx].subService.firstAppointmentStatus,
            languageId: selectedLanguageId,
            clinic,
            serviceType: selections[idx].serviceType,
            iisConsent: patientInfo.iisConsent,
            phicureResponse: phicureResponse[idx],
            insuranceCoverage: insuranceCoverageList[idx],
            patientConsentData: termsAndConditionContents[0],
          })
        : secondAppointment
          ? convertAppointmentObjectToDynamicsFormat({
              patientInfo,
              subService: selections[idx].subService,
              appointment: secondAppointment,
              organizationId,
              appointmentStatus:
                selections[idx].subService.secondAppointmentStatus,
              languageId: selectedLanguageId,
              clinic,
              serviceType: selections[idx].serviceType,
              iisConsent: patientInfo.iisConsent,
              phicureResponse: phicureResponse[idx],
              insuranceCoverage: insuranceCoverageList[idx],
              patientConsentData:
                termsAndConditionContents.length === 1
                  ? termsAndConditionContents[0]
                  : termsAndConditionContents[idx],
            })
          : null,
  );

  const blockDetail = getBlockDetails(appointments, primaryServiceCheckOnly);

  const patientInfoObj = convertPatientInfoObjectToDynamicsFormat(
    patientInfo,
    requiresRegistration,
    registrationCode,
  );
  const postData = {
    appointments: requestAppointments,
    patientInfo: patientInfoObj,
    captchaResponseKey,
    screeningAnswers,
    selfDeclaration,
    eligibilityResponse,
    recordInfo,
    blockDetail,
  };

  const { data } = await http.post(config.entity.createAppointment, postData);

  return data.data;
}

export function getDataRequests({
  appointments,
  patientInfo,
  eligibilityResponse,
  screeningAnswers,
  organizationId,
  captchaResponseKey,
  selfDeclaration,
  selections,
  selectedLanguageId,
  requiresRegistration,
  registrationCode,
  patientRecordInfo,
  clinic,
  phicureResponse,
  insuranceCoverageList,
  localizedWebContents,
  primaryServiceCheckOnly,
  sendEmail = false,
}) {
  const recordInfo = {
    isExistingPatient: patientRecordInfo.isExistingPatient,
    patientId: patientRecordInfo.patientId,
  };

  patientInfo.mobileNo = patientInfo.mobileNo.replaceAll('-', '');
  patientInfo.physicianPhoneNumber =
    patientInfo.physicianPhoneNumber.replaceAll('-', '');

  // separator @@
  const termsAndConditionContents = localizedWebContents.map((content) => {
    return `
      ${content.termsAndCondition}
  
      @@
  
      ${content.termsAndConditionsAcceptance.map((item) => item.label).join('\n')}
      `;
  });

  const requestAppointments = appointments.map(
    ({ first: firstAppointment, second: secondAppointment }, idx) =>
      firstAppointment
        ? convertAppointmentObjectToDynamicsFormat({
            patientInfo,
            subService: selections[idx].subService,
            appointment: firstAppointment,
            organizationId,
            appointmentStatus:
              selections[idx].subService.firstAppointmentStatus,
            languageId: selectedLanguageId,
            clinic,
            serviceType: selections[idx].serviceType,
            iisConsent: patientInfo.iisConsent,
            insuranceCoverage: insuranceCoverageList[idx],
            patientConsentData: termsAndConditionContents[0],
          })
        : secondAppointment
          ? convertAppointmentObjectToDynamicsFormat({
              patientInfo,
              subService: selections[idx].subService,
              appointment: secondAppointment,
              organizationId,
              appointmentStatus:
                selections[idx].subService.secondAppointmentStatus,
              languageId: selectedLanguageId,
              clinic,
              serviceType: selections[idx].serviceType,
              iisConsent: patientInfo.iisConsent,
              insuranceCoverage: insuranceCoverageList[idx],
              patientConsentData:
                termsAndConditionContents.length === 1
                  ? termsAndConditionContents[0]
                  : termsAndConditionContents[idx],
            })
          : null,
  );

  const blockDetail = getBlockDetails(appointments, primaryServiceCheckOnly);
  const patientInfoObj = convertPatientInfoObjectToDynamicsFormat(
    patientInfo,
    requiresRegistration,
    registrationCode,
  );

  return {
    appointments: requestAppointments,
    patientInfo: patientInfoObj,
    captchaResponseKey,
    screeningAnswers,
    selfDeclaration,
    eligibilityResponse,
    recordInfo,
    sendEmail,
    blockDetail,
  };
}

export async function createBatchAppointments(payload) {
  const { data } = await http.post(
    config.entity.createBatchAppointment,
    payload,
  );

  return data.data;
}

/**
 *
 * @param {{}[]} appointments
 * @returns
 */
export function getBlockDetails(appointments, primaryServiceCheckOnly) {
  if (!appointments || !appointments.length) {
    return {};
  }

  const flatAppointments = appointments
    .map(({ first, second }) => [first, second])
    .flat()
    .filter((i) => i);

  if (!flatAppointments.length) {
    console.warn('no appointments');
    return {};
  }

  const { end_time, ...bookingBlockInfo } = flatAppointments[0].bookingBlock;

  return {
    serviceCodes: flatAppointments.map(({ serviceCode }) => serviceCode),
    venueCode: flatAppointments[0].venueCode,
    date: flatAppointments[0].date,
    bookingBlock: bookingBlockInfo,
    primaryServiceCheckOnly,
  };
}
