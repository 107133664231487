const patterns = {
  // A-Z a single character in the range between A and Z
  // Match period, single quote, and dash
  // i modifier: insensitive. Case insensitive match (ignores case of [a-zA-Z]
  nameField: /(^[A-Z.‘' -]+$)/i,
  // Match string containing alphabet, numbers,
  // and characters # ' , . /
  addressField: /(^[A-Z0-9 #‘'.,-/]+$)/i,
  cityField: /^[a-zA-Z -]*$/,
  codeField: /(^[a-z0-9]+$)/i,
};

export default patterns;
