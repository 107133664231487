import React from 'react';

import { getString } from 'util/lang';
import cls from 'classnames';
import styles from './GroupConfirmationCode.module.scss';

const GroupConfirmationCode = ({ patientInfo }) => {
  return (
    <div className="success-content border-bottom">
      <div className="confirmation-code">
        <h3 tabIndex="0" className="text-bold">
          {getString('bookingCode')}
        </h3>
        <p tabIndex="0">{getString('confirmationCodeMessage')}</p>
        <div
          tabIndex="0"
          className={cls(
            'conformation-code-box mt-6x color-primary-base',
            styles.customGroupConfirmation,
          )}
        >
          {patientInfo.map((p, i) => (
            <div key={i} className={styles.content}>
              <span className={styles.patientInfo}>
                {p.firstName} {p.middleName} {p.lastName}
              </span>{' '}
              <span className={styles.code}>{p.confirmationCode}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupConfirmationCode;
