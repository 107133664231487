import React from 'react';
import { getString } from 'util/lang';
import { FiMapPin } from 'vyaguta-icons/fi';

/**
 * @typedef {{
 *    msemr_name: string
 *    msemr_locationid: string
 *    msemr_addressline1: string
 *    msemr_addresscity: string
 * }} ClinicDetails
 *
 * @typedef {{
 *  clinicDetails: ClinicDetails
 *  onLocationChange: (location: ClinicDetails) => void
 *  selectedClinic?: { msemr_locationid: string }
 * }} Props
 *
 * @param {Props} props
 */
const ClinicCard = (props) => {
  const { clinicDetails, locationId, onLocationChange, isChecked } = props;
  const { name, address, city } = clinicDetails;

  const availableSlotText = getString('nAppointmentsAvailable');

  const handleClinicChange = () => {
    onLocationChange(clinicDetails);
  };

  return (
    <div className="col col-6-md clinic-item">
      <div className={`clinic-card`}>
        <input
          id={locationId}
          name="vaccine-location"
          type="radio"
          className="custom-radio-input"
          onChange={handleClinicChange}
          checked={isChecked}
          autoComplete="off"
          data-qa="vaccine-location"
        />
        <label htmlFor={locationId} className="clinic-card__label">
          <div className="clinic-card__location">
            <FiMapPin size={20} />
          </div>
          <div className="clinic-card__content">
            <div className="card__name clinic__name">{name}</div>
            <div className="card__location clinic__location">
              {address}, {city}
            </div>
            <div className="mt-2x pill pill-info">{availableSlotText}</div>
          </div>
          <div className="clinic-card__radio">
            <span className="app-radio-wrapper">
              <span className="app-radio"></span>
            </span>
          </div>
        </label>
      </div>
    </div>
  );
};

export default ClinicCard;
