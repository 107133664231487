import config from '../config';
import * as http from '../util/http';

const mapCompatibilityKeys = (clinic) => ({
  ...clinic,

  msemr_locationid: clinic.id,
  msemr_name: clinic.name,
  _smvs_state_value: clinic.stateId,
  msemr_addresscity: clinic.city,
  msemr_addressstate: clinic.state,
  msemr_addressline1: clinic.address,
  smvs_latitude: clinic.latitude,
  smvs_longitude: clinic.longitude,
  smvs_insurance: clinic.insurance,
  smvs_no_insurance: clinic.noInsurance,
  smvs_pay_at_the_time_of_service: clinic.payAtTimeOfService,
  smvs_time_gap: clinic.timeGap,
});

export async function getAllLocationsFromSubScheduling(
  subServiceIds,
  organizationId,
  skipScheduleCheck,
  isWaitlistEnabled,
) {
  const {
    data: { data: clinics },
  } = await http.get(config.entity.clinics, {
    params: {
      organizationId,
      subServiceIds: subServiceIds,
      skipScheduleCheck: skipScheduleCheck === true ? 'true' : undefined,
      isWaitlistEnabled: isWaitlistEnabled === true ? 'true' : undefined,
    },
  });

  const sortedClinics = clinics
    .map(mapCompatibilityKeys) // TODO: remove compatibility mapping
    .filter((d) => d.msemr_name)
    .sort((first, second) => {
      if (first.msemr_name < second.msemr_name) {
        return -1;
      }
      return 0;
    });

  return sortedClinics;
}
