import { getString } from 'util/lang';

export const ABNFormOptions = ({
  abnData,
  primaryPayerName,
  option1 = false,
  option2 = false,
  option3 = false,
}) => {
  return (
    <div>
      <h5 className="title title--bold mb-2x">
        {getString('dme.documentation.abnForm.needToKnow')}
      </h5>
      <ul className="list list--bulleted mb-2x">
        {getString('dme.documentation.abnForm.needToKnowPoints')?.map(
          (item, index) => {
            return (
              <li key={index}>
                <p className="desc mb-0x">
                  {getString(
                    `dme.documentation.abnForm.needToKnowPoints.${index}`,
                  )}
                </p>
              </li>
            );
          },
        )}
      </ul>
      <p className="desc">
        <span className="title mr-2x">Note:</span>
        {getString(`${abnData}.needToKnowNote`)}
      </p>

      <table className="table table--bordered table--colored mb-6x">
        <thead>
          <tr>
            <th>
              {getString('dme.documentation.abnForm.fields.optionsLabel')} :
              <span className="ml-1x">
                {getString('dme.documentation.abnForm.fields.optionsNote')}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="mb-4x">
                <div className="d-flex align-items-base">
                  <input
                    type="checkbox"
                    className="cursor-pointer mr-3x"
                    disabled={true}
                    checked={option1}
                  />
                  <div>
                    <span className="title--bold mr-1x ">
                      {getString(
                        'dme.documentation.abnForm.fields.option1Label',
                      )}
                    </span>
                    {getString(`${abnData}.option1`, { primaryPayerName })}
                  </div>
                </div>
              </div>
              <div className="mb-4x">
                <div className="d-flex align-items-base">
                  <input
                    type="checkbox"
                    className="cursor-pointer mr-3x"
                    disabled={true}
                    checked={option2}
                  />
                  <div>
                    <span className="title--bold mr-1x ">
                      {getString(
                        'dme.documentation.abnForm.fields.option2Label',
                      )}
                    </span>
                    {getString(`${abnData}.option2`, { primaryPayerName })}
                  </div>
                </div>
              </div>
              <div className="mb-4x">
                <div className="d-flex align-items-base">
                  <input
                    type="checkbox"
                    className="cursor-pointer mr-3x"
                    checked={option3}
                    disabled={true}
                  />
                  <div>
                    <span className="title--bold mr-1x ">
                      {getString(
                        'dme.documentation.abnForm.fields.option3Label',
                      )}
                    </span>
                    {getString(`${abnData}.option3`, { primaryPayerName })}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
