import React from 'react';
import LinkToPage from './LinkToPage';

import { DetailContext } from '../../../context';

const LinkToPageContainer = (props) => {
  return (
    <DetailContext.Consumer>
      {({ setPageComplete }) => (
        <LinkToPage setPageComplete={setPageComplete} {...props} />
      )}
    </DetailContext.Consumer>
  );
};

export default LinkToPageContainer;
