/**
 * Set upper and lower bound values of the 'value' passed
 * @param min - minimum possible value
 * @param max - maximum possible value
 * @param value - value to clamp
 * @returns {number} - value between [min, max]
 */
import { isNumber } from '../constants/type';

export const clamp = (min = 0, max = 0, value = 0) => {
  if (!(isNumber(min) && isNumber(max) && isNumber(value))) {
    throw new Error('All values are required for clamping.');
  }

  return Math.min(Math.max(value, min), max);
};
