import { StyleSheet, Text, View } from '@react-pdf/renderer';

const FormHeaderPdfView = ({ text, style }) => {
  return (
    <View style={{ ...styles.wrap, ...style }}>
      <Text style={styles.h2}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    height: 30,
    backgroundColor: '#E8F3FB',
    borderBottom: '0.5px solid #0078D4',
    marginBottom: 25,
  },
  h2: {
    fontSize: 10,
    fontWeight: 700,
  },
});

export default FormHeaderPdfView;
