import { getString } from './lang';

export const getErrorMessage = (statusCode, resource) => {
  const errorMessages = JSON.parse(
    getString('errorMessages', {
      resource,
    }),
  );

  const errorMessage = errorMessages[statusCode];

  if (!errorMessage) {
    return 'An unknown error occurred.';
  }

  return errorMessage;
};
