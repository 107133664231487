import InsuranceInput from './InsuranceInput';
import {
  AvailableInsuranceCoverageIndices,
  InsuranceTypeOptions,
} from '../../../../../constants/patientInfo';
import { getString } from '../../../../../util/lang';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './MultiInsuranceInput.module.scss';
import cls from 'classnames';
import Collapsible from 'react-collapsible';
import { FiChevronDown, FiChevronUp } from 'vyaguta-icons/fi';
import { RemoveIcon } from '../../../../../svg-images/RemoveIcon';
import { ConfirmModal } from 'components/common/ConfirmModal';

const AccordionCollapsibleTitle = (props) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const isNonPrimaryInsurance =
    props.insuranceIndex >
    AvailableInsuranceCoverageIndices.primaryInsuranceIndex;
  const insuranceCompanyName = props.insuranceCompanyName
    ? ' - ' + props.insuranceCompanyName
    : '';
  const triggerHeading =
    (isNonPrimaryInsurance
      ? getString('additionalInsurance')
      : getString('primaryInsurance')) + insuranceCompanyName;

  const onRemoveBtnClick = () => {
    setConfirmModal(false);
    props.onRemove(props.insuranceIndex);
  };

  const toggleConfirmationModalVisibility = (e) => {
    setConfirmModal((prev) => !prev);
    e.stopPropagation();
  };

  return (
    <div
      className={cls({
        [styles.accordionTrigger]: true,
        'mt-6x': isNonPrimaryInsurance,
      })}
    >
      <div className="ml-4x">{triggerHeading}</div>

      <div className={cls(styles.actionItemContainer, 'mr-4x')}>
        {isNonPrimaryInsurance && (
          <>
            <div
              className={cls(styles.removeIconContainer, 'mr-3x')}
              onClick={toggleConfirmationModalVisibility}
            >
              <RemoveIcon />
            </div>
            <div className={cls(styles.triggerSeparator)} />
          </>
        )}
        {props.isTriggerOpen ? (
          <FiChevronUp size={24} className="ml-2x" />
        ) : (
          <FiChevronDown size={24} className="ml-2x" />
        )}
      </div>
      <ConfirmModal
        show={confirmModal}
        onCancel={toggleConfirmationModalVisibility}
        onConfirm={onRemoveBtnClick}
        headerContent={
          <div className={styles.modalHeader}>
            {getString('insuranceDetailRemovalTitle')}
          </div>
        }
        bodyContent={
          <div className={cls(styles.modalContent)}>
            {getString('insuranceDetailRemovalContent')}
          </div>
        }
        cancelText={getString('dismiss')}
        confirmText={getString('positiveConfirmation')}
      />
    </div>
  );
};

export const MultiInsuranceInput = (props) => {
  const {
    onInsuranceTypeChange,
    availableInsuranceTypes,
    errors,
    insuranceType,
    value,

    // props for insurance input
    onChange,
    payerlist,
    insuranceInfo,
    setFieldError,
    selectedPayer,
    setCurrentInsuranceIndex,
    onRemoveInsuranceDetail,

    //insurance validation related states
    isInsuranceValidationLoading,
    setRequireFormDataFilledStatus,
    insuranceIndex = 0,
    selectedServices,
  } = props;

  const [isTriggerOpen, setTriggerOpen] = useState(true);

  const currentInsuranceErrors =
    errors?.insuranceDataErrors?.[insuranceIndex] ?? {};
  const isPolicyNumberInvalid =
    errors.insurancePolicyNumberVerificationErrors.includes(insuranceIndex);

  const validInsuranceTypeOptions = useMemo(() => {
    if (
      !Array.isArray(availableInsuranceTypes) ||
      availableInsuranceTypes.length === 0
    ) {
      return InsuranceTypeOptions;
    }

    const validInsuranceTypeSet = new Set(availableInsuranceTypes);

    return InsuranceTypeOptions.filter((item) =>
      validInsuranceTypeSet.has(item.insuranceType),
    );
  }, [availableInsuranceTypes]);

  const setInsuranceIndex = useCallback(() => {
    setCurrentInsuranceIndex(insuranceIndex);
  }, []);

  const triggerCallback = useCallback((state) => {
    setTriggerOpen(state);
    setInsuranceIndex();
  }, []);

  const onRemoveBtnCallback = useCallback(() => {
    onRemoveInsuranceDetail(insuranceIndex);
  }, []);

  return (
    <div
      className={cls(styles.multiInsuranceMainCotainer, 'form-payment-type')}
      onFocus={setInsuranceIndex}
    >
      <Collapsible
        transitionTime={150}
        trigger={
          <AccordionCollapsibleTitle
            insuranceCompanyName={selectedPayer?.label}
            isTriggerOpen={isTriggerOpen}
            insuranceIndex={insuranceIndex}
            onRemove={onRemoveBtnCallback}
          />
        }
        open={isTriggerOpen}
        onTriggerOpening={() => triggerCallback(true)}
        onTriggerClosing={() => triggerCallback(false)}
      >
        <InsuranceInput
          onChange={onChange}
          payerlist={payerlist}
          insuranceInfo={insuranceInfo}
          insuranceType={insuranceType}
          setFieldError={setFieldError}
          isInsuranceValidationLoading={isInsuranceValidationLoading}
          selectedPayer={selectedPayer}
          errors={currentInsuranceErrors || {}}
          onInsuranceTypeChange={onInsuranceTypeChange}
          validInsuranceTypeOptions={validInsuranceTypeOptions}
          currentInsuranceErrors={currentInsuranceErrors}
          setRequireFormDataFilledStatus={setRequireFormDataFilledStatus}
          value={value}
          isPolicyNumberInvalid={isPolicyNumberInvalid}
          selectedServices={selectedServices}
        />
      </Collapsible>
    </div>
  );
};
