import React, { useRef } from 'react';

const SkipToMainContent = ({ onClick = null, mainContentRef }) => {
  const anchorRef = useRef(null);

  const handleSkipAnchorClick = (e) => {
    e.preventDefault();
    // anchor tag takes the focus and doesn't release it by default for our MainContent to be focusable
    // the following code achieves it
    anchorRef.current.blur();
    if (onClick) {
      onClick(e);
      return;
    }
    const element = mainContentRef.current;
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
    element.focus();
  };

  //#hidden is required to supress lint warning. It won't change url as we are handling it programatically
  return (
    <a
      href="#hidden"
      className="ally-link"
      onClick={handleSkipAnchorClick}
      ref={anchorRef}
    >
      Skip to main content
    </a>
  );
};

export default SkipToMainContent;
