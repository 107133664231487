import React, { useEffect, useMemo, useState, useRef } from 'react';

import { getString } from 'util/lang';
import DropSelect from '../DropSelect';
import styles from './groupAddress.module.scss';
import PatientAddressInfo from 'components/COVID/views/PatientInformation/presentation/PatientAddressInfo';
import { PatientAddressInfoKeys } from 'constants/patientInfo';
import FormErrors from 'components/common/FormErrors';
import cls from 'classnames';

const OPTIONS = {
  INDIVIDUAL: 'individual',
  GROUP: 'group',
};

export const GroupAddress = (props) => {
  const {
    isInsuranceValidationLoading,
    onChangeInput,
    patientInfo = {},
    errors = {},
    stateOptions,
    countyOptions,
    onAddressSelect,
    addressList = [],
    onGroupAddressSelection,
    currentPatientIndex = 0,
  } = props;

  const [patientInfoState, setPatientInfoState] = useState({});

  const [addressOption, setAddressOption] = useState();

  const [selectedAddress, setSelectedAddress] = useState({ ...addressList[0] });

  // useRef to persist data between rerenders
  const patientInfoLoadedRef = useRef({ patientInfoLoaded: false });

  useEffect(() => {
    // default radio button option selection
    if (
      !patientInfoLoadedRef.current.patientInfoLoaded &&
      Object.keys(patientInfo).length
    ) {
      patientInfoLoadedRef.current.patientInfoLoaded = true;
      const matchFromGroup = addressList.find((a) => {
        return a.county === patientInfo.county && a.state === patientInfo.state;
      });
      if (matchFromGroup) {
        setAddressOption(OPTIONS.GROUP);
        setSelectedAddress(matchFromGroup);
      } else {
        setAddressOption(OPTIONS.INDIVIDUAL);
      }
    }

    setPatientInfoState({ ...patientInfo });
  }, [patientInfo]);

  const errorSet = React.useMemo(() => {
    const tempErrorSet = {};
    [...PatientAddressInfoKeys].forEach((key) => {
      if (errors[key]) {
        tempErrorSet[key] = errors[key];
      }
    });
    return tempErrorSet;
  }, [errors]);

  const dropSelectOptions = useMemo(
    () =>
      addressList.map((c) => {
        if (c.name) {
          return {
            label: c.name,
            value: c,
          };
        }
      }),
    [addressList],
  );

  const onAddressOptionChange = (e) => {
    const value = e.target.value;
    if (value) {
      setAddressOption(value);
    }
    if (typeof onGroupAddressSelection === 'function') {
      let options = {
        county: '',
        state: '',
        city: '',
        zipCode: '',
        homeAddress: '',
      };
      if (value === OPTIONS.GROUP) {
        options = selectedAddress;
      }
      onGroupAddressSelection(options);
    }
  };

  const onGroupAdressChange = (option) => {
    if (option) {
      setSelectedAddress(option);

      if (typeof onGroupAddressSelection === 'function') {
        onGroupAddressSelection(option);
      }
    }
  };

  const getOptionToLabel = (optionList, selectedOptionValue) => {
    if (!optionList || optionList.length < 1) {
      return '';
    }
    const optionObject = optionList.find(
      (option) => option.value === selectedOptionValue,
    );
    return optionObject ? optionObject.label : '';
  };

  const onInputChangeHandler = (key, value, event) => {
    if (typeof onChangeInput === 'function') {
      if (addressOption === OPTIONS.INDIVIDUAL) {
        onChangeInput(key, value, event);
      }
    } else {
      setPatientInfoState((prevState) => {
        return {
          ...prevState,
          [key]: value,
        };
      });
    }
  };

  return (
    <>
      <div className={`${styles['patient-address-wrapper']}`}>
        {currentPatientIndex !== 0 && (
          <div className={`${styles['patient-address-options__group']}`}>
            <div
              className={cls(
                `${styles['patient-address-options__item']}`,
                'custom-radio',
              )}
            >
              <input
                id="address__group"
                type="radio"
                name="group__address"
                className="custom-radio-input"
                value={OPTIONS.GROUP}
                checked={addressOption === OPTIONS.GROUP}
                onChange={onAddressOptionChange}
              />
              <label
                htmlFor="address__group"
                className={`${styles['address-option-label__text']}`}
              >
                {getString('useSameAddressFromGroup')}
                &nbsp;
                <span
                  htmlFor="address__group"
                  className={`${styles['patient-address-selected-name']} ${
                    dropSelectOptions.length > 1
                      ? styles['patient-address-selected-name-underline']
                      : ''
                  }`}
                >
                  {selectedAddress.name}
                </span>
              </label>
              &nbsp;
              <DropSelect
                options={dropSelectOptions}
                onChange={onGroupAdressChange}
              />
            </div>
            <div className={`${styles['patient-address-selected__text']}`}>
              {selectedAddress.homeAddress}&nbsp;
              {selectedAddress.city}&nbsp;
              {getOptionToLabel(countyOptions, selectedAddress.county)}&nbsp;
              {getOptionToLabel(stateOptions, selectedAddress.state)}&nbsp;
              {selectedAddress.zipCode}
            </div>
            <div
              className={cls(
                `${styles['patient-address-options__item']}`,
                'custom-radio',
              )}
            >
              <input
                id="address__individual"
                type="radio"
                name="group__address"
                className="custom-radio-input"
                value={OPTIONS.INDIVIDUAL}
                checked={addressOption === OPTIONS.INDIVIDUAL}
                onChange={onAddressOptionChange}
              />
              <label
                htmlFor="address__individual"
                className={`${styles['address-option-label__text']}`}
              >
                {getString('useDifferentAddressFromGroup')}
              </label>
            </div>
          </div>
        )}
        <div className={`${styles['patient-address-individual__content']}`}>
          {addressOption === OPTIONS.INDIVIDUAL && (
            <PatientAddressInfo
              isInsuranceValidationLoading={isInsuranceValidationLoading}
              onChangeInput={onInputChangeHandler}
              patientInfo={patientInfoState}
              // to render errors outside the Group Address Component
              errors={{}}
              stateOptions={stateOptions}
              countyOptions={countyOptions}
              onAddressSelect={onAddressSelect}
            />
          )}
        </div>
      </div>
      <FormErrors errors={errorSet} />
    </>
  );
};
