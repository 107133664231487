import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDetailContext, useGroupAppointmentContext } from 'context';
import { useSecurityToken } from 'context/useSecurityToken';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useConfirmModal } from 'components/common/ConfirmModal';
import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { useSelectedServiceNames } from 'context/useSelectedServiceNames';
import { AppointmentStatuses } from 'constants/appointment';
import { useGroupStatusCheck } from '../../../../../context/useGroupAppointmentStatusCheck';
import { SecurityCheck } from '../../SecurityCheck/SecurityCheck';
import { InvalidAppointmentMessage } from 'components/COVID/common/InvalidAppointmentMessage';

export const GroupRescheduleVerify = () => {
  const { groupAppointmentId, organizationId } = useParams();
  const { setSecurityToken, resetSecurityToken } = useSecurityToken();
  const { rescheduleDataSetup, setRescheduleAppointment, setDetails } =
    useDetailContext();
  const serviceNames = useSelectedServiceNames();
  const nav = useNavigationHelper();
  const [isLoading, setIsLoading] = useState(false);
  const { ConfirmModal, confirm } = useConfirmModal();
  const [isResheduleValid, setIsResheduleValid] = useState(true);
  const [dataSetupCompleted, setDataSetupCompleted] = useState(false);

  const groupAppointmentStatus = useGroupStatusCheck(groupAppointmentId);
  const { currentPatientInfo } = useGroupAppointmentContext();

  const onSecurityVerified = useCallback(
    async ({ token, details: patientInfo }) => {
      const appointmentId = patientInfo.appointmentId;

      try {
        setIsLoading(true);
        setSecurityToken(token);
        setDetails((details) => ({
          patientInfo: [
            {
              ...currentPatientInfo,
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
              birthMonth: patientInfo.birthMonth,
              birthDay: patientInfo.birthDay,
              birthYear: patientInfo.birthYear,
            },
          ],
          selfDeclaration: [
            {
              firstName: patientInfo.firstName,
              middleName: patientInfo.middleName,
              lastName: patientInfo.lastName,
            },
          ],
        }));
        // fetch and setup data

        if (!dataSetupCompleted) {
          // if user verifies, then cancels then verifies again, dont do the reschedule setup again
          const result = await rescheduleDataSetup(
            organizationId,
            appointmentId,
          );
          if (!result) {
            // handle error
            throw new Error('Failed to setup reschedule');
          }
          setDataSetupCompleted(true);
        }

        const appointment = await new Promise((resolve) => {
          setDetails(({ appointments }) => {
            resolve(appointments[0]);
            return {};
          });
        });

        setIsLoading(false);

        const validReschedule = [AppointmentStatuses.BOOKED].includes(
          appointment.appointmentStatus,
        );
        setIsResheduleValid(validReschedule);

        if (!validReschedule) {
          return;
        }

        nav.toGroup.reschedule.selection(appointmentId);
      } catch (error) {
        console.error(error);
        error.message && toast.error({ title: '', message: error.message });
        resetSecurityToken();
      } finally {
        setIsLoading(false);
      }
    },
    [
      nav,
      confirm,
      setSecurityToken,
      resetSecurityToken,
      groupAppointmentId,
      organizationId,
      rescheduleDataSetup,
      setDetails,
      dataSetupCompleted,
      setRescheduleAppointment,
    ],
  );

  return (
    <PageWrapper bgGrey>
      {isResheduleValid && !groupAppointmentStatus.error ? (
        <SecurityCheck
          loading={isLoading || !groupAppointmentStatus.loaded}
          activityId={groupAppointmentId}
          onSecurityVerified={onSecurityVerified}
          headerMsg={getString('followupRescheduleVerificationHeader')}
          alertMsg={getString('followupRescheduleVerificationAlert')}
          isGroupAppointment={true}
        />
      ) : (
        <InvalidAppointmentMessage />
      )}
      <ConfirmModal
        headerContent={getString('followupRescheduleConfirmHeader')}
        bodyContent={getString(
          'followupRescheduleConfirmMessage',
          <>{serviceNames}</>,
        )}
        cancelText={getString('followupRescheduleNo')}
        confirmText={getString('followupRescheduleYes')}
      />
    </PageWrapper>
  );
};
