import React from 'react';
import { FiMapPin } from 'vyaguta-icons/fi';

const AddressSuggestionItem = (props) => {
  const { address, onSelect } = props;
  const { id, homeAddress, city, state, zipCode } = address;

  const handleAddressChange = () => {
    onSelect(address);
  };

  return (
    <div className="address-item" onMouseUp={handleAddressChange}>
      <label htmlFor={id} className="address-item__label">
        <div className="address-item__location">
          <FiMapPin size={20} />
        </div>
        <div className="address-item__content">
          <div className="card__location address__location">{homeAddress}</div>
          <div className="card__location address__location">
            {city}, {state} {zipCode}, United States
          </div>
        </div>
      </label>
    </div>
  );
};

export { AddressSuggestionItem };
