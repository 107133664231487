import React, { useCallback, useMemo } from 'react';
import { v5 as uuidv5 } from 'uuid';
import { convertTo12HourTimeFormatWithAmPm } from 'util/DateAndTime';
import { getSlotCountString } from 'components/COVID/views/NewClinicAndTiming/helpers/appointmentTimes';

/**
 * @typedef {import('types').TimeSlot} TimeSlot
 *
 * @typedef {{
 *  time: string  // HH:mm
 *  isSelected: boolean
 *  slotCount: number
 *  timeSlot: TimeSlot
 *  onTimeSelect: (timeSlot: TimeSlot) => void
 *  dataqa: string
 * }} Props
 *
 * @param {Props} props
 * @returns
 */
const Timeslot = (props) => {
  const {
    time,
    slotCount,
    isSelected = false,
    timeSlot,
    onTimeSelect,
    dataqa,
  } = props;
  const timeString = convertTo12HourTimeFormatWithAmPm(time);
  const isAvailable = !!slotCount;
  const slotCountString = getSlotCountString(slotCount);

  const id = `${timeSlot.start_time}-${timeSlot.end_time}`;

  const onSelectTimeslot = useCallback(() => {
    onTimeSelect(timeSlot);
  }, [onTimeSelect, timeSlot]);

  return (
    <label className="select-time__radio" htmlFor={id}>
      <input
        type="radio"
        name={id}
        id={id}
        checked={isSelected}
        disabled={!isAvailable}
        onChange={onSelectTimeslot}
        data-qa={dataqa}
      />
      <span
        className={`select-time__item ${!isAvailable && 'select-time__item--disabled'} border border-radius`}
      >
        <span className="text-demibold">{timeString}</span>
        <span className="available-slots available-slots--light d-block">
          {slotCountString}
        </span>
      </span>
    </label>
  );
};

export default Timeslot;
