import React from 'react';
import { FiPrinter } from 'vyaguta-icons/fi';
import { getString } from '../../../../../util/lang';

const PrintAppointment = () => {
  const print = () => {
    window.print();
  };

  return (
    <button className="print-btn" onClick={print}>
      <FiPrinter size={24} className="mr-2x" />
      {getString('print')}
    </button>
  );
};

export default PrintAppointment;
