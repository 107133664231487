export const addDeliveryTicket = {
  patientName: 'patientName',
  dateOfBirth: 'dateOfBirth',
  phoneNumber: 'phoneNumber',
  pickedUpAtStore: 'pickedUpAtStore',
  deliveredToPartnerOrCareGiver: 'deliveredToPartnerOrCareGiver',
  deliveredToAFacility: 'deliveredToAFacility',
  shippedToPatient: 'shippedToPatient',
  deliveryOrShippingAddress: 'deliveryOrShippingAddress',
  facilityName: 'facilityName',
  shippingCompany: 'shippingCompany',
  shippingTrackingOrInvoiceNumber: 'shippingTrackingOrInvoiceNumber',
  dateDeliveredOrPickedUp: 'dateDeliveredOrPickedUp',
};

export const questionnaireKeys = {
  datePatientGotTheEquipment: 'datePatientGotTheEquipment',
  whoPaidForIt: 'whoPaidForIt',
  supplyRemainDays: 'supplyRemainDays',
  agencyName: 'agencyName',
  agencyPhoneNumber: 'agencyPhoneNumber',
  providerName: 'providerName',
  providerPhoneNumber: 'providerPhoneNumber',
  itemReceived: 'itemReceived',
  dateReceived: 'dateReceived',
  stillHasTheItem: 'stillHasTheItem',
  dateReturned: 'dateReturned',
  returnReason: 'returnReason',
  sixtyDaysBreakNeeded: 'sixtyDaysBreakNeeded',
};

export const proofOfDelivery = {
  checklist: 'checklist',
  recipientName: 'recipientName',
  relationshipToBeneficiary: 'relationshipToBeneficiary',
  reasonBeneficiaryIsUnableToSign: 'reasonBeneficiaryIsUnableToSign',
  signature: 'signature',
  date: 'date',
};
