import React from 'react';

import { getString } from 'util/lang';
import LocationFilter from './LocationFilter';
import Button from 'components/common/Button';

const DesktopClinicSearchBar = (props) => {
  const { searchTerm, onSearchTermChange, onSearch } = props;

  return (
    <div className="container-lg d-none d-lg-block py-4x">
      <div className="patient-form-group multi-service-form-group">
        <div className="d-flex">
          <div className="col col-search">
            <LocationFilter
              searchTerm={searchTerm}
              onSearchTermChange={onSearchTermChange}
              onSearch={onSearch}
              searchContainer="desktop-ms-location-search-container"
              searchInput="desktop-ms-location-search-input"
              searchButton="desktop-ms-location-search-button"
            />
          </div>
        </div>
      </div>

      <Button
        isEnabled={true}
        label={getString('search')}
        onClick={onSearch}
        className="btn btn-primary btn-block ms-search-button w-100-sm d-block d-sm-none mb-8x"
      />
    </div>
  );
};

export default DesktopClinicSearchBar;
