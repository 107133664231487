import { useState } from 'react';

import { getString } from 'util/lang';
import { useGroupAppointmentContext } from 'context';
import { useNavigationHelper } from 'hooks/useNavigationHelper';

import Button from 'components/common/Button';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import GroupAppointmentOption from '../presentation/GroupPayment/GroupAppointmentOptions';

const GroupAppointmentLanding = () => {
  const nav = useNavigationHelper();

  const { isGroupAppointment, setIsGroupAppointment } =
    useGroupAppointmentContext();
  const [radioInput, setRadioInput] = useState(isGroupAppointment);

  const submitHandler = () => {
    setIsGroupAppointment(radioInput);
    // radioInput=true => group appointment selected
    // radioInput=false => individual appointment selected
    if (radioInput) {
      nav.toSelectSubService();
    } else {
      nav.toPreScreening();
    }
  };

  return (
    <>
      <PageWrapper>
        <BackToPage
          text={getString('backToPreviousPage')}
          onClick={nav.goBack}
        />
        <div className="d-flex flex-column">
          <GroupAppointmentOption
            radioInput={radioInput}
            setRadioInput={setRadioInput}
          />
          <Button
            classNames={{ button: 'mt-10x' }}
            onClick={submitHandler}
            isEnabled
            label={getString('continue')}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default GroupAppointmentLanding;
