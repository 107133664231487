import { useCallback, useMemo, useState } from 'react';
import { FiAlertTriangle, FiChevronDown } from 'vyaguta-icons/fi';
import Collapsible from 'react-collapsible';
import cls from 'classnames';
import { UiPlusCircle } from 'vyaguta-icons/ui';

import styles from './EditServiceAccordion.module.scss';
import { getString } from 'util/lang';
import { SubServiceRadioInput } from '../../SelectSubservice/presentation/SubserviceRadioInput';
import AlertMessage from 'components/COVID/common/AlertMessage';
import { SelectServiceType } from 'components/COVID/common/SelectServiceType/SelectServiceType';

/**
 * @typedef {import('types').ServiceType} ServiceType
 * @typedef {import('types').Service} Service
 *
 * @typedef {ServiceType & { services: Service[] }} ServiceTypeWithServices
 * @typedef {{ serviceTypeId: string, serviceId: string }} ServiceSelection
 *
 * @template {TCbCtx}
 * @typedef {{
 *  heading: string
 *  serviceTypes: ServiceTypeWithServices[]
 *  serviceSelection: ServiceSelection
 *  open?: boolean
 *  removable?: boolean
 *  notice?: string
 *  addService?: boolean
 *  inEligible?: boolean
 *  cbCtx: TCbCtx  // opaque data passed by parent and passed back up in callbacks
 *  onSelectService: (serviceTypeId: string, serviceId?: string, cbCtx: TCbCtx) => void
 *  onRemoveService: (cbCtx: TCbCtx) => void
 *  onAddService: (cbCtx: TCbCtx) => void
 * }} Props
 *
 * @param {Props} props
 */
export const EditServiceAccordion = (props) => {
  const {
    heading,
    serviceTypes,
    serviceSelection,
    open,
    removable,
    notice,
    addService,
    inEligible,
    onRemoveService,
    onSelectService,
    onAddService,
    cbCtx,
  } = props;

  const [isOpen, setIsOpen] = useState(open);
  const [randomId] = useState(() => (Math.random() * 1e6).toFixed(0));

  const openAccordion = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeAccordion = useCallback(() => {
    setIsOpen(false);
  }, []);

  const selectedServiceType = useMemo(() => {
    return serviceTypes.find(
      ({ id }) => id === serviceSelection?.serviceTypeId,
    );
  }, [serviceTypes, serviceSelection?.serviceTypeId]);

  return (
    <Collapsible
      trigger={
        <ServiceTypeAccordionHeader
          heading={heading}
          subHeading={selectedServiceType?.name}
          serviceTypes={serviceTypes}
          open={isOpen}
          removable={removable}
          onClickRemove={() => onRemoveService(cbCtx)}
          inEligible={inEligible}
        />
      }
      open={isOpen}
      onTriggerClosing={closeAccordion}
      onTriggerOpening={openAccordion}
      contentOuterClassName="mb-4x"
    >
      {addService ? (
        <button
          className={cls(styles.addService, 'mb-6x mt-2x')}
          onClick={() => onAddService(cbCtx)}
        >
          <UiPlusCircle className={styles.addServiceIcon} />
          Add Additional Service
        </button>
      ) : (
        <>
          <SelectServiceType
            serviceTypeList={serviceTypes}
            selectedServiceTypeId={serviceSelection.serviceTypeId}
            onServiceTypeChange={(id) => onSelectService(id, undefined, cbCtx)}
            handleDropDownClick={noop}
          />
          <div className={styles.selectServicesTitle}>SELECT ANY 1 SERVICE</div>
          <div className={styles.servicesList}>
            {selectedServiceType?.services.map((service) => (
              <SubServiceRadioInput
                key={service.id}
                title={service.name}
                value={`${service.id}-${randomId}`}
                selected={service.id === serviceSelection.serviceId}
                name={`${serviceSelection.serviceTypeId}-${randomId}`}
                onChange={() =>
                  onSelectService(selectedServiceType.id, service.id, cbCtx)
                }
              />
            ))}
          </div>
          {inEligible && (
            <AlertMessage
              type="danger"
              message={getString('notEligibleForSelected')}
              isVisible
              className="mb-4x"
            />
          )}
          {notice && (
            <AlertMessage
              type="info"
              message={notice}
              isVisible
              className="mb-4x"
            />
          )}
        </>
      )}
    </Collapsible>
  );
};

const noop = () => {};

/**
 * @param {{
 *  heading: string
 *  subHeading: string
 *  serviceTypes: ServiceTypeWithServices
 *  open: boolean
 *  removable: boolean
 *  onClickRemove: (serviceId: string) => void
 *  inEligible?: boolean
 * }} props
 */
const ServiceTypeAccordionHeader = (props) => {
  const { heading, subHeading, open, removable, onClickRemove, inEligible } =
    props;

  const _onClickRemove = useCallback(
    (e) => {
      e.stopPropagation();
      onClickRemove();
    },
    [onClickRemove],
  );

  return (
    <div className={cls(styles.ServiceAccordionHeader, open && styles.open)}>
      <div className={styles.text}>
        <div className={styles.serviceText}>
          {heading}{' '}
          {inEligible && (
            <FiAlertTriangle size={20} strokeWidth={2} className="mr-4x" />
          )}
        </div>
        {!open && subHeading && (
          <div className={styles.subServiceText}>{subHeading}</div>
        )}
      </div>
      {removable && (
        <button tabIndex={0} onClick={_onClickRemove} className={styles.remove}>
          {getString('remove')}
        </button>
      )}
      <FiChevronDown className={styles.arrow} />
    </div>
  );
};
