import config from '../config';
import * as http from '../util/http';

/**
 * Fetch All Counties from Dataverse
 *
 *
 * @returns {array} data: List of counites with their id and state code
 */
export async function fetchCounties() {
  const url = `${config.entity.counties}`;
  const { data: response } = await http.get(url);

  if (response && response.success && Array.isArray(response.data)) {
    return mapCountyToLabelValue(response.data);
  }

  // TODO: log possible issue becasuse of missing counties
  console.log('Empty or invalid fetch counties response');

  return [];
}

/**
 *
 * @param {{id: string, name: string}[]} states
 * @returns {{value: string, label: string}[]}
 */
export const mapCountyToLabelValue = (counties) => {
  return counties.map((county) => ({
    value: county.countyid,
    label: county.name,
    stateId: county.stateId,
    stateCode: county.state,
  }));
};
