import { useMemo } from 'react';

import SelectService from './SelectService';
import { RadioSelectServiceType } from '../../../common/RadioSelectServiceType/RadioSelectServiceType';

const noop = () => {};

/**
 * @typedef {import('types').ServiceType} ServiceType
 * @typedef {import('types').Service} Service
 * @typedef {ServiceType & { services: Service[] }} ServiceTypeWithServices
 * @typedef {{ serviceTypeId: string, serviceId: string }} ServiceSelection
 *
 * @param {{
 *    serviceSelection: ServiceSelection
 *    serviceTypes: ServiceTypeWithServices[]
 *    onSelectServiceType: (serviceTypeId: string) => void
 *    onSelectService: (serviceId: string) => void
 * }} props
 * @returns
 */
const RadioSelectMultiServiceForm = (props) => {
  const {
    serviceSelection,
    serviceTypes,
    onSelectServiceType,
    onSelectService,
  } = props;

  const selectedServiceType = useMemo(() => {
    return serviceTypes.find(
      ({ id }) => id === serviceSelection?.serviceTypeId,
    );
  }, [serviceTypes, serviceSelection?.serviceTypeId]);

  return (
    <>
      <RadioSelectServiceType
        serviceTypeList={serviceTypes.sort((a, b) =>
          a.name.localeCompare(b.name),
        )}
        onServiceTypeChange={onSelectServiceType}
        selectedServiceTypeId={serviceSelection.serviceTypeId}
        collapsibleSelectService={
          <SelectService
            services={
              selectedServiceType?.services?.sort((a, b) =>
                a.name.localeCompare(b.name),
              ) ?? []
            }
            selectedServiceType={serviceSelection.serviceTypeId}
            selectedService={serviceSelection.serviceId}
            onServiceChange={onSelectService}
            showGroupLabel={true}
          />
        }
      />
    </>
  );
};

export default RadioSelectMultiServiceForm;
