import React from 'react';
import { UiPen } from 'vyaguta-icons/ui';
import styles from './MessageBtn.module.scss';
import cls from 'classnames';

const MessageBtn = (props) => {
  return (
    <div
      className={cls(
        'd-flex justify-content-end',
        styles.messageBtn,
        props.className,
      )}
    >
      <button
        className={cls('btn btn--plain', styles.btnMain)}
        tabIndex="0"
        onClick={props.onClick}
      >
        <UiPen size="16" className="mr-2x" />
        <span className={cls(styles.btnTitle)}>{props.label}</span>
      </button>
    </div>
  );
};

export { MessageBtn };
