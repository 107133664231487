import Button from 'components/common/Button';
import styles from './ActionButtons.module.scss';
import { iconPrintPdf } from 'assets/images';

const ActionButtons = ({
  handleSave = () => {},
  handlePrint = () => {},
  isEnabled = true,
}) => {
  return (
    <div
      className={`group-container d-flex justify-content-center mb-4x mt-3x ${styles.actionButton}`}
    >
      <Button
        title="Save"
        isEnabled={isEnabled}
        onClick={handleSave}
        className={`btn btn--regular btn-primary--outline btn--rounded ${styles.buttonSave}`}
        isActive
        label="Save"
      />
      {handlePrint && (
        <Button
          title="Print PDF"
          isEnabled
          onClick={handlePrint}
          className={`btn btn-primary--outline ${styles.button}`}
          isActive
          label="Print PDF"
          icon={
            <img
              src={iconPrintPdf}
              alt="print-pdf"
              className={styles.buttonPrint}
            />
          }
        />
      )}
    </div>
  );
};

export default ActionButtons;
