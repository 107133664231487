import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DetailContext, useAppSettings } from 'context';
import ReportPage from '../presentation/ReportPage';
import SecurityCheck from '../../SecurityCheck/container/SecurityCheck';
import { useGetTestReportQuery } from 'redux/test-report/testReport.api';

import * as toast from 'util/toast';
import { getString } from 'util/lang';
import { APPOINTMENT_TOKEN } from 'constants/appointment';
import {
  clearDataFromSessionStorage,
  getDataFromSessionStorage,
  storeDataToSessionStorage,
} from 'util/sessionStorage';

const TestReportWrapper = () => {
  const { id } = useParams();

  const appContext = useContext(DetailContext);
  const { organizationName, billingAddress } = useAppSettings();
  const { isSecurityChecked, setSecurityChecked, setSecurityToken, details } =
    appContext;

  const {
    data: testReportResponse,
    isError: isTestReportError,
    error,
  } = useGetTestReportQuery(id, {
    skip: !isSecurityChecked, // Skip the query if security is not checked
  });
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const token = getDataFromSessionStorage(APPOINTMENT_TOKEN);
    if (token) {
      setSecurityChecked(true);
    }
  }, []);

  useEffect(() => {
    if (isSecurityChecked && testReportResponse) {
      setLoading(false);
    }
    if (isTestReportError) {
      setSecurityChecked(false);
      clearDataFromSessionStorage(APPOINTMENT_TOKEN);
      toast.error({
        title: 'Error',
        message: error?.errorMessage || getString('errorMessage'),
      });
    }
  }, [isSecurityChecked, testReportResponse, isTestReportError, error]);

  const confirmSecurity = (checked, { token }) => {
    setSecurityChecked(checked);
    storeDataToSessionStorage(APPOINTMENT_TOKEN, token);
    setSecurityToken(token);
  };

  if (!isSecurityChecked) {
    return (
      <SecurityCheck
        confirmSecurity={confirmSecurity}
        formType={'covidresult'}
      />
    );
  }

  return (
    <ReportPage
      appointmentId={id}
      logoUrl={details.appSettings.logoUrl}
      isLoading={isLoading}
      testReportResponse={testReportResponse}
      organizationName={organizationName}
      billingAddress={billingAddress}
    />
  );
};

export default TestReportWrapper;
