import { useDetailContext } from 'context';
import { useCallback } from 'react';
import {
  composeFilters,
  excludeDoubleDose,
  filterSubservicesByAge,
  filterByDefaultStatus,
} from 'util/serviceFilter';

/**
 *
 * @typedef {import('types').ServiceType} ServiceType
 * @typedef {import('types').Service} Service
 * @typedef {import('types').SubService} SubService
 *
 * @param {{
 * primarySelection: { serviceTypeId: string, serviceId: string },
 * serviceTypesById: Record<string, ServiceType>
 * servicesById: Record<string, ServiceType>
 * subServicesById: Record<string, SubService>
 * patientInfo: {}
 * }} opts
 */
const filterValidSecondaryServices = ({
  primarySelection,
  serviceTypesById,
  servicesById,
  subServicesById,
  patientInfo,
}) => {
  const primaryServiceType = serviceTypesById[primarySelection.serviceTypeId];

  if (!primaryServiceType) {
    return [];
  }

  const filterEligibleSubServices = composeFilters(
    excludeDoubleDose,
    filterSubservicesByAge(patientInfo),
    filterByDefaultStatus,
  );

  return primaryServiceType.secondaryServiceTypes
    .filter((id) => serviceTypesById[id]) // remove invalid ids
    .map((id) => ({
      ...serviceTypesById[id],
      services: serviceTypesById[id].services
        .filter((id) => id !== primarySelection.serviceId) // cant select same service twice
        .map((id) => servicesById[id])
        .map((service) => {
          const ownSubServices = service.subServices
            .map((id) => subServicesById[id])
            .filter((i) => i);
          const eligibleSubServices = filterEligibleSubServices(ownSubServices);
          const singleDoseSubServices = excludeDoubleDose(ownSubServices);

          // filter out entries that only have double doses
          if (singleDoseSubServices.length === 0) {
            return null;
          }

          return {
            ...service,
            isEligible: eligibleSubServices.length > 0,
          };
        })
        .filter((i) => i),
    }))
    .filter(({ services }) => services.length > 0);
};

export const useFilterValidSecondaryServices = () => {
  const {
    details: { serviceTypes, services, subServices, patientInfo },
  } = useDetailContext();

  return useCallback(
    ({ serviceTypeId, serviceId }) =>
      filterValidSecondaryServices({
        primarySelection: {
          serviceTypeId,
          serviceId,
        },
        serviceTypesById: serviceTypes.byId,
        servicesById: services.byId,
        subServicesById: subServices.byId,
        patientInfo,
      }),
    [serviceTypes.byId, services.byId, subServices.byId, patientInfo],
  );
};
