import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { SelectLocation } from '../../SelectLocation/SelectLocation';

export const GroupRescheduleLocation = () => {
  const { appointmentId } = useParams();
  const nav = useNavigationHelper();

  const toNextPage = useCallback(() => {
    nav.toGroup.reschedule.schedule(appointmentId);
  }, [nav, appointmentId]);

  return (
    <PageWrapper
      container="full"
      classNames={{ mainWrapper: 'main-wrapper-sm' }}
    >
      <SelectLocation
        disableEdit
        toNextPage={toNextPage}
        preserveAppointmentState
      />
    </PageWrapper>
  );
};
