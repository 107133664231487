import React from 'react';
import { FiCheck } from 'vyaguta-icons/fi';

import { getString } from 'util/lang';

const EligibilityQuestion = ({
  eligibilityQuestionCheck,
  eligibilityQuestions,
  onChangeEligibilityQuestion,
}) => {
  const onChangeCheckbox = (name, checked) => {
    onChangeEligibilityQuestion(name, checked, eligibilityQuestions);
  };

  return (
    <section className="section__margin">
      <>
        <h2>{getString('eligibilityQuestionsHeader')}</h2>
        <div className="section__container border-radius border">
          <h3>{getString('eligibilityQuestionsMessage')}</h3>
          <div className="eligibility-wrapper">
            {eligibilityQuestions.map((qs, index) => (
              <div key={qs.id} className="custom-check px-0x mb-3x">
                <input
                  autoComplete="off"
                  type="checkbox"
                  id={qs.id}
                  data-qa={`${index}-eligibility`}
                  name={qs.id}
                  onChange={(e) => onChangeCheckbox(qs.id, e.target.checked)}
                  checked={eligibilityQuestionCheck[qs.id] || false}
                />
                <label htmlFor={qs.id}>
                  <FiCheck /> {qs.question}
                </label>
              </div>
            ))}
          </div>
        </div>
      </>
    </section>
  );
};

export default EligibilityQuestion;
