import React, { useEffect, useState } from 'react';
import CodeRegistrationForm from '../presentation/CodeRegistrationForm';
import { validateRegistartionCode } from '../presentation/CodeRegistrationValidation';
import * as codeRegistrationService from '../../../../../services/CodeRegistration';
import { dashCodeFormat, limitChars } from '../../../../../util/TextFormat';
import CONFIG from '../../../../../config';
import { getSearchQueryValue } from '../../../../../util/queryParser';
import { usePrevious } from '../../../../../hooks/usePrevious';
import objectUtils from '../../../../../util/objectUtils';

const CodeRegistration = (props) => {
  const { registerUser, organizationId, appLanguageCode } = props;
  const [registrationCode, setRegistrationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(null);
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);

  const prevLanguageCode = usePrevious(appLanguageCode);

  useEffect(() => {
    const queryRegCode = getSearchQueryValue('c');
    setRegistrationCode(limitChars(dashCodeFormat(queryRegCode), 9));
  }, []);

  useEffect(() => {
    if (!prevLanguageCode) {
      return;
    }

    if (prevLanguageCode !== appLanguageCode && !objectUtils.isEmpty(errors)) {
      const regCode = registrationCode.replaceAll('-', '');
      validateRegCode(regCode);
    }
  }, [appLanguageCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRegCodeChange = (value) => {
    const formattedValue = limitChars(value, 9);
    setRegistrationCode(formattedValue);
    setErrors({});
  };

  const handleRegCodeSubmit = async () => {
    const regCode = registrationCode.replaceAll('-', '');
    const isFormValid = validateRegCode(regCode);
    if (isFormValid) {
      try {
        const isCaptchaOK = validateCaptcha();
        if (isCaptchaOK) {
          setIsLoading(true);
          const response = await codeRegistrationService.verifyRegistrationCode(
            {
              code: regCode,
              captchaResponseKey: captcha,
              orgId: organizationId,
            },
          );
          const { isCodeValid, message } = response;
          if (isCodeValid) {
            registerUser(regCode);
          } else {
            setErrors({ registrationCode: message });
            CONFIG.captchaEnabled && window.grecaptcha.reset();
            setCaptcha(null);
          }
        }
      } catch (error) {
        CONFIG.captchaEnabled && window.grecaptcha.reset();
        setCaptcha(null);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validateRegCode = (registrationCode) => {
    const { errors, isValid } = validateRegistartionCode({ registrationCode });
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  const handleCaptchaChange = (captchaResponseKey) => {
    setCaptcha(captchaResponseKey);
    setIsCaptchaValid(true);
  };

  const validateCaptcha = () => {
    if (!captcha) {
      setIsCaptchaValid(false);
      return false;
    }
    return true;
  };

  return (
    <CodeRegistrationForm
      registrationCode={registrationCode}
      handleRegCodeChange={handleRegCodeChange}
      handleRegCodeSubmit={handleRegCodeSubmit}
      registrationCodeErrors={errors}
      isCaptchaValid={isCaptchaValid}
      handleCaptchaChange={handleCaptchaChange}
      isLoading={isLoading}
    />
  );
};

export default CodeRegistration;
