import React, { useContext, useState } from 'react';

import Modal from '../../../common/Modal';
import CONFIG from '../../../../../config';
import Button from '../../../../common/Button';
import ReCaptcha from '../../../common/ReCaptcha';
import { getString } from '../../../../../util/lang';
import { DetailContext } from '../../../../../context';

const CancelModal = (props) => {
  const context = useContext(DetailContext);
  const [captchaResponseKey, setCaptchaResponseKey] = useState('');
  const [isCaptchaAttempted, setIsCaptchaAttempted] = useState(false);

  const { closeModal, onCancelButtonClick } = props;
  const { isSecurityChecked } = context.details;

  const isCancelButtonEnabled = isSecurityChecked;

  const handleCancelButtonClicked = () => {
    if (CONFIG.captchaEnabled && !captchaResponseKey) {
      setIsCaptchaAttempted(true);
      return;
    }
    onCancelButtonClick(captchaResponseKey);
  };

  const headerContent = (
    <h4 className="color-primary-base mb-1x">
      {getString('appointmentCancellationHeader')}
    </h4>
  );

  return (
    <Modal
      close={closeModal}
      containerClassName="confirmation-modal"
      headerContent={headerContent}
    >
      <div className="modal__body">
        {getString('appointmentCancellationDescription')}
      </div>
      {isSecurityChecked && CONFIG.captchaEnabled && (
        <ReCaptcha
          onCaptchaChange={setCaptchaResponseKey}
          isCaptchaValid={isCaptchaAttempted ? !!captchaResponseKey : true}
        />
      )}
      <div className="modal__footer text-right border-top d-flex justify-content-between flex-wrap">
        <Button
          label={getString('doNotCancel')}
          className="btn btn--large btn-primary--outline"
          isEnabled
          onClick={closeModal}
          dataqa="close-cancel-modal"
        />
        <Button
          label={getString('cancelAppointment')}
          className="btn btn--large btn-primary"
          isEnabled={isCancelButtonEnabled}
          onClick={handleCancelButtonClicked}
          dataqa="ok-cancel-modal"
        />
      </div>
    </Modal>
  );
};

export default CancelModal;
