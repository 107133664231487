import React from 'react';

import Button from 'components/common/Button';
import Modal from 'components/COVID/common/Modal';

import { getString } from 'util/lang';
import { AddressOptionCard } from './AddressOptionCard';

const AddressVerificationModal = ({
  closeModal,
  address,
  suggestions,
  onAddressChange,
  onConfirmAddress,
}) => {
  const [selectedSuggestedAddress, setSelectedSuggestedAddress] =
    React.useState(null);

  const onOriginalAddressClick = () => {
    onConfirmAddress();
    closeModal();
  };
  const onSuggestedAddressClick = () => {
    onAddressChange(selectedSuggestedAddress);
    onConfirmAddress();
    closeModal();
  };
  return (
    <Modal
      close={closeModal}
      containerClassName="confirmation-modal"
      headerContent={getString('didYouMean')}
      isClosable={true}
    >
      <div className="modal__body">
        <div className="address-card-current">
          <div className="address-card-current__label">
            {getString('youEnteredAddress')}
          </div>
          <div className="address-card-current__content">
            {address.homeAddress}
          </div>
          <div className="address-card-current__content">
            {address.city && `${address.city}`}
            {address.state && `, ${address.state}`}
            {address.zipCode && ` ${address.zipCode}`}
          </div>
        </div>
        <div className="choose-address-location">
          <div className="title mt-4x mb-2x">
            {getString('suggestedAddress')}
          </div>
          <div className="d-flex flex-wrap">
            {suggestions.map((suggestedAddress) => (
              <AddressOptionCard
                key={suggestedAddress.id}
                address={suggestedAddress}
                selectedAddress={selectedSuggestedAddress}
                onAddressChange={setSelectedSuggestedAddress}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="modal__footer d-flex justify-content-between flex-wrap">
        <Button
          label={getString('updateSuggestedAddress')}
          className="btn btn--large btn-primary btn-block mb-4x"
          isEnabled={!!selectedSuggestedAddress}
          onClick={onSuggestedAddressClick}
        />
        <Button
          label={getString('useOriginalAddress')}
          className="btn btn--large btn-primary--outline btn-block"
          isEnabled
          onClick={onOriginalAddressClick}
        />
      </div>
    </Modal>
  );
};

export default AddressVerificationModal;
