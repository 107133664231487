import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Router as BrowserRouter } from 'react-router-dom';

import picoModal from 'picomodal';
import { useIdleTimer } from 'react-idle-timer';
import { Provider } from 'react-redux';

import { store } from 'redux/store';
import config from './config';
import history from './util/history';
import App from './components/COVID/App';
import { disableInspect } from './util/browserUtil';
import { getString } from './util/lang';

const MainApp = () => {
  const onIdle = () => {
    picoModal({
      content:
        '<div className="modal__wrapper open"> <div class="modal__content"><div class="modal__header pb-5x">' +
        '<h4 class="color-primary-base mb-0x pico-modal-idle-text"/>' +
        '</div>' +
        '<div class="modal__body date-time-pick vaccine-appointment">' +
        '<p class="pico-modal-idle-text"/>' +
        '</div><div class="modal__footer border-top py-4x"><div class="d-flex justify-content-end"}><div class="action-buttons">' +
        '<div class="btn btn--small btn-primary--outline cancel-reset-session"></div>' +
        '</div></div></div></div></div>',
      closeButton: false,
    })
      .afterCreate((modal: any) => {
        const stayButtonNode = modal
          .modalElem()
          .getElementsByClassName('cancel-reset-session')[0];

        stayButtonNode.append(getString('stay'));
        stayButtonNode.addEventListener('click', () => {
          modal.close();
        });

        const headerTextNode = modal
          .modalElem()
          .getElementsByClassName('pico-modal-idle-text')[0];
        headerTextNode.append(getString('clearingSessionHeader'));

        const messageTextNode = modal
          .modalElem()
          .getElementsByClassName('pico-modal-idle-text')[1];
        messageTextNode.append(getString('clearingSessionDescription'));
      })
      .afterClose(() => {
        clearTimeout(sessionTimeoutID);
      })
      .show();

    const sessionTimeoutID = setTimeout(() => {
      clearTimeout(sessionTimeoutID);
      window.location.reload();
    }, 60000);
  };

  useEffect(() => {
    if (config.env !== 'development') {
      disableInspect();
    }
  }, []);

  useIdleTimer({
    onIdle,
    debounce: config.inactiveDebounceTime,
    timeout: config.inactiveSessionTime,
  });

  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

const domNode = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(domNode);

root.render(
  <BrowserRouter history={history}>
    <MainApp />
  </BrowserRouter>,
);
