export function getRandomNLengthNumeralString(numberOfDigits = 6) {
  let randomStringBase = `${Math.random()}`.slice(2);
  while (randomStringBase.length < numberOfDigits) {
    randomStringBase += `${Math.random()}`.slice(2);
  }

  return randomStringBase.substring(0, numberOfDigits);
}

export function getRandomNDigitDecimalNumber(numberOfDigits = 6) {
  return parseInt(getRandomNLengthNumeralString(numberOfDigits), 10);
}
