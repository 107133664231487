import { SubServiceRadioInput } from './SubserviceRadioInput';
import styles from './ServiceAccordion.module.scss';
import { uniqueId } from 'lodash';
import { useState } from 'react';

/**
 * @typedef {import('types').SubService} SubService
 * @typedef {import('types').Service} Service
 *
 * @param {{
 *  service: Service & { selectedSubServiceId: string, subServices: SubService[] },
 *  onSelectSubService: (subServiceId: string) => void
 * }} props
 * @returns
 */
export const SubServiceSelector = ({ service, onSelectSubService }) => {
  const [componentId] = useState(() => uniqueId());

  return (
    <div className={styles.subServiceList}>
      {service.subServices.map((subService, subServiceIdx) => (
        <SubServiceRadioInput
          key={subService.id}
          dataqa={`service-0-${subServiceIdx}`}
          name={componentId}
          value={`${componentId}-${subService.id}`}
          title={subService.name}
          notice={subService.notice}
          onChange={() => {
            onSelectSubService(subService.id);
          }}
          selected={service.selectedSubServiceId === subService.id}
        />
      ))}
    </div>
  );
};
