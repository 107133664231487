import { useEffect } from 'react';
import { FiCheck } from 'vyaguta-icons/fi';

import { getString } from 'util/lang';
import LineBreak from '../../common/LineBreak/LineBreak';

const LABEL_PREFIX = 'dme.deliveryTicket.documentChecklist';

const documentData = [
  {
    key: 'manufacturer_documentation',
    question: getString(`${LABEL_PREFIX}.manufacturerDocumentation`),
  },
  {
    key: 'education_booklet',
    question: getString(`${LABEL_PREFIX}.educationBooklet`),
  },
  {
    key: 'warranty',
    question: getString(`${LABEL_PREFIX}.warranty`),
  },
  {
    key: 'delivery_confirmation_copy',
    question: getString(`${LABEL_PREFIX}.deliveryConfirmationCopy`),
  },
  {
    key: 'rental_agreement',
    question: getString(`${LABEL_PREFIX}.rentalAgreement`),
  },
  {
    key: 'terms_and_conditions',
    question: getString(`${LABEL_PREFIX}.termsAndConditions`),
  },
  {
    key: 'advanced_beneficiary_notice_medicare_pt_only',
    question: getString(
      `${LABEL_PREFIX}.advancedBeneficiaryNoticeMedicarePtOnly`,
    ),
  },
  {
    key: 'hipaa_privacy_notice',
    question: getString(`${LABEL_PREFIX}.hipaaPrivacyNotice`),
  },
  {
    key: 'medicare_supplier_standards_medicare_pt_only',
    question: getString(
      `${LABEL_PREFIX}.medicareSupplierStandardsMedicarePtOnly`,
    ),
  },
  {
    key: 'bill_of_rights_and_responsibilities',
    question: getString(`${LABEL_PREFIX}.billOfRightsAndResponsibilities`),
  },
];

export const documentKeyToQuestionMap = documentData.reduce((acc, curr) => {
  const { key, question } = curr;
  acc[key] = question;
  return acc;
}, {});

export const initialDocumentValues = documentData.map(({ key }) => ({
  key,
  checked: false,
}));

/**
 * Document Checklist Component - Delivery Ticket - DME
 *
 * @param {array} documentsData
 * @param {func} setDeliveryTicketHandler
 */
const DocumentCheckList = ({
  documentsData,
  setDeliveryTicketHandler,
  isEdit,
}) => {
  return (
    <div className="delivery-ticket__document_checklist--wrap">
      <div className="delivery-ticket__document_checklist">
        <h3>Documents Given to the Patient:</h3>
        <div className="row delivery-ticket__document_checklist-details">
          {documentsData.map((documentD) => {
            const { key, checked } = documentD;
            const question = documentKeyToQuestionMap[key];

            return (
              <div key={key} className={`col-6-md custom-check px-0x mb-3x`}>
                <input
                  autoComplete="off"
                  type="checkbox"
                  id={key}
                  name={question}
                  disabled={!isEdit}
                  onChange={() => {
                    setDeliveryTicketHandler(
                      documentsData.map((docDetail) => {
                        if (docDetail.key === key) {
                          return {
                            ...docDetail,
                            checked: !docDetail.checked,
                          };
                        }
                        return docDetail;
                      }),
                      'documentsData',
                    );
                  }}
                  value={checked}
                  checked={checked}
                  className="row delivery-ticket__document_checklist-box"
                />
                <label
                  htmlFor={key}
                  className="row delivery-ticket__document_checklist-label"
                >
                  <FiCheck /> {question}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <LineBreak />
    </div>
  );
};

export default DocumentCheckList;
