import React, { useContext } from 'react';

import IISConsent from './IISConsent';
import FormInput from '../../../../common/FormInput';
import SelectBox from '../../../../common/SelectBox';
import FormErrors from '../../../../common/FormErrors';
import AlertMessage from '../../../common/AlertMessage';
import {
  GenderOptions,
  MonthList,
  DayList,
  PatientBasicInfoKeys,
  ShowAsRequiredFields,
  RaceOptions,
  EthnicityOptions,
  YearList,
  LanguageOptions,
} from '../../../../../constants/patientInfo';
import { isLeapYear } from '../../../../../util/DateAndTime';
import { getString } from '../../../../../util/lang';
import NotificationPreferences from './NotificationPreferences';
import { useAppSettings } from '../../../../../context';

const PatientBasicInfo = (props) => {
  const {
    onChangeInput,
    patientInfo = {},
    errors = {},
    isInsuranceValidationLoading = false,
    isIISConsentEnabled,
    displayOnlyNameAndDob = false,
    disabledFieldStatus = {},
  } = props;

  const { organizationName } = useAppSettings();

  const errorSet = React.useMemo(() => {
    const errorSet = {};
    PatientBasicInfoKeys.forEach((key) => {
      if (errors[key]) {
        errorSet[key] = errors[key];
      }

      if (patientInfo.emailOptOut) {
        errorSet.confirmEmail = null;
      }
      if (isIISConsentEnabled && !patientInfo.iisConsent) {
        errorSet.njisValidation = getString('njisValidationMessage');
      }
    });
    return errorSet;
  }, [errors]);

  const getDaysInMonth = (birthMonth, birthYear) => {
    const TwentyNineDayMonths = ['2'];
    const ThirtyDayMonths = ['4', '6', '9', '11'];
    if (TwentyNineDayMonths.includes(birthMonth)) {
      if (!isLeapYear(birthYear)) {
        return DayList.slice(0, 28);
      }
      return DayList.slice(0, 29);
    } else if (ThirtyDayMonths.includes(birthMonth)) {
      return DayList.slice(0, 30);
    }
    return DayList;
  };

  return (
    <section className="form-patient-info section__margin ">
      <h2>{getString('patientInfo')}</h2>
      <div className="patient-form-group border-rounded">
        <div className="row">
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('firstName')}
              id="firstName"
              onChange={onChangeInput}
              value={patientInfo.firstName}
              hasError={!!errorSet['firstName']}
              required={ShowAsRequiredFields['firstName']}
              disableInput={
                isInsuranceValidationLoading || disabledFieldStatus['firstName']
              }
              data-qa="first-name"
            />
          </div>
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('middleName')}
              id="middleName"
              onChange={onChangeInput}
              value={patientInfo.middleName}
              hasError={!!errorSet['middleName']}
              required={ShowAsRequiredFields['middleName']}
              data-qa="middle-name"
              disableInput={
                isInsuranceValidationLoading ||
                disabledFieldStatus['middleName']
              }
            />
          </div>
          <div className="col-4-md fs-mask">
            <FormInput
              label={getString('lastName')}
              id="lastName"
              onChange={onChangeInput}
              value={patientInfo.lastName}
              hasError={!!errorSet['lastName']}
              required={ShowAsRequiredFields['lastName']}
              disableInput={
                isInsuranceValidationLoading || disabledFieldStatus['lastName']
              }
              data-qa="last-name"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              label={getString('year')}
              id="birthYear"
              defaultValue={getString('birthYear')}
              onChange={(e) => onChangeInput('birthYear', e.target.value)}
              value={patientInfo.birthYear}
              hasError={!!errorSet['birthYear']}
              required={ShowAsRequiredFields['birthYear']}
              options={YearList()}
              isDisabled={
                isInsuranceValidationLoading || disabledFieldStatus['birthYear']
              }
              dataqa="birth-year-info-page"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              id="birthMonth"
              onChange={(e) => onChangeInput('birthMonth', e.target.value)}
              defaultValue={getString('birthMonth')}
              label={getString('birthMonth')}
              value={patientInfo.birthMonth}
              options={MonthList}
              hasError={!!errorSet['birthMonth']}
              required={ShowAsRequiredFields['birthMonth']}
              isDisabled={
                isInsuranceValidationLoading ||
                disabledFieldStatus['birthMonth']
              }
              dataqa="birth-month-info-page"
            />
          </div>
          <div className="col-4 fs-mask">
            <SelectBox
              id="birthDay"
              onChange={(e) => onChangeInput('birthDay', e.target.value)}
              defaultValue={getString('day')}
              label={getString('day')}
              value={patientInfo.birthDay}
              options={getDaysInMonth(
                patientInfo.birthMonth,
                patientInfo.birthYear,
              )}
              hasError={!!errorSet['birthDay']}
              required={ShowAsRequiredFields['birthDay']}
              isDisabled={
                isInsuranceValidationLoading || disabledFieldStatus['birthDay']
              }
              dataqa="birth-day-info-page"
            />
          </div>

          {!displayOnlyNameAndDob && (
            <PatientContactAndNotificationPreferences
              {...props}
              organizationName={organizationName}
              errorSet={errorSet}
            />
          )}
        </div>

        {!displayOnlyNameAndDob && patientInfo.emailOptOut && (
          <div className="py-3x alert__wrapper--white">
            <AlertMessage
              type="warning"
              message={getString('optOutEmail')}
              className="mx-5x"
              isVisible={patientInfo.emailOptOut}
              dataqa="email-opt-alert-info-page"
            />
          </div>
        )}
      </div>
      <FormErrors errors={errorSet} />

      {!displayOnlyNameAndDob && (
        <AlertMessage
          type="info"
          message={getString('iisConsentInfo')}
          className="mt-5x"
          isVisible={isIISConsentEnabled}
          dataqa="iis-consent-alert-info-page"
        />
      )}
    </section>
  );
};

const PatientContactAndNotificationPreferences = (props) => {
  const {
    onChangeInput,
    patientInfo,
    errorSet,
    isInsuranceValidationLoading,
    isIISConsentEnabled,
    validateEmailonBlur,
    disabledFieldStatus,
    organizationName,
  } = props;
  return (
    <>
      <div className="col-4-md fs-mask">
        <SelectBox
          id="ethnicity"
          onChange={(e) => onChangeInput('ethnicity', e.target.value)}
          label={getString('ethnicity')}
          defaultValue=""
          value={patientInfo.ethnicity}
          options={EthnicityOptions}
          hasError={!!errorSet['ethnicity']}
          required={ShowAsRequiredFields['ethnicity']}
          isDisabled={disabledFieldStatus['ethnicity']}
          dataqa="ethnicity-info-page"
        />
      </div>
      <div className="col-8-md fs-mask">
        <SelectBox
          id="race"
          onChange={(e) => onChangeInput('race', e.target.value)}
          label={getString('race')}
          defaultValue=""
          value={patientInfo.race}
          options={RaceOptions}
          hasError={!!errorSet['race']}
          required={ShowAsRequiredFields['race']}
          isDisabled={
            isInsuranceValidationLoading || disabledFieldStatus['race']
          }
          dataqa="race-info-page"
        />
      </div>
      <div className="col-4-md fs-mask">
        <SelectBox
          id="gender"
          onChange={(e) => onChangeInput('gender', e.target.value)}
          defaultValue={getString('gender')}
          label={getString('gender')}
          value={patientInfo.gender}
          options={GenderOptions}
          hasError={!!errorSet['gender']}
          required={ShowAsRequiredFields['gender']}
          isDisabled={
            isInsuranceValidationLoading || disabledFieldStatus['gender']
          }
          dataqa="gender-info-page"
        />
      </div>
      <div className="col-8-md fs-mask">
        <SelectBox
          id="primaryLanguage"
          onChange={(e) => onChangeInput('primaryLanguage', e.target.value)}
          defaultValue=""
          label={getString('primaryLanguage')}
          value={patientInfo.primaryLanguage}
          options={LanguageOptions}
          hasError={!!errorSet['primaryLanguage']}
          required={ShowAsRequiredFields['primaryLanguage']}
          isDisabled={disabledFieldStatus['primaryLanguage']}
          dataqa="primary-language-info-page"
        />
      </div>
      <div className="col-6-md fs-mask">
        <FormInput
          label={getString('mobileNumber')}
          id="mobileNo"
          type="tel"
          // INFO: uncomment to allow phone opt out
          // optOutValue={patientInfo.phoneOptOut}
          // onChangeCheck={(checked) => {
          //   onChangeInput('phoneOptOut', checked);
          // }}
          // disableInput={patientInfo.phoneOptOut}
          onChange={onChangeInput}
          value={patientInfo.mobileNo}
          hasError={!!errorSet['mobileNo']}
          required={ShowAsRequiredFields['mobileNo']}
          disableInput={disabledFieldStatus['mobileNo']}
          aria-label="mobile number"
          data-qa="mobile-info-page"
        />
      </div>
      <div className="col-6-md fs-mask">
        <FormInput
          label={getString('homePhoneNo')}
          id="homePhoneNo"
          type="tel"
          onChange={onChangeInput}
          value={patientInfo.homePhoneNo}
          hasError={!!errorSet['homePhoneNo']}
          required={ShowAsRequiredFields['homePhoneNo']}
          disableInput={disabledFieldStatus['homePhoneNo']}
          aria-label="home phone number"
          data-qa="home-phone-number-info-page"
        />
      </div>
      <div className="col-12-md fs-mask">
        <FormInput
          label={getString('emailAddress')}
          id="email"
          type="email"
          inputAutoComplete={'off'}
          disablePaste={true}
          onChange={onChangeInput}
          value={patientInfo.email}
          hasError={!!errorSet['email']}
          data-qa="email-info-page"
          onBlur={() => validateEmailonBlur('email')}
          disableInput={disabledFieldStatus['email']}
          required={
            patientInfo.emailNotificationConsent
              ? ShowAsRequiredFields['email']
              : false
          }
        />
      </div>
      <div className="col-12-md fs-mask">
        <FormInput
          label={getString('emailAddressConfirm')}
          id="confirmEmail"
          type="email"
          disablePaste={true}
          onChange={onChangeInput}
          value={patientInfo.confirmEmail}
          hasError={!!errorSet['confirmEmail']}
          data-qa="email-confirm-info-page"
          onBlur={() => validateEmailonBlur('confirmEmail')}
          disableInput={disabledFieldStatus['confirmEmail']}
          required={
            patientInfo.emailNotificationConsent
              ? ShowAsRequiredFields['confirmEmail']
              : false
          }
        />
      </div>
      <div className="col-12-md fs-mask">
        <FormInput
          label={getString('referringOrganization')}
          id="referredBy"
          onChange={onChangeInput}
          value={patientInfo.referredBy}
          hasError={!!errorSet['referredBy']}
          required={ShowAsRequiredFields['referredBy']}
          disableInput={disabledFieldStatus['referredBy']}
          data-qa="referred-by-info-page"
        />
      </div>
      <div className="col-12-md fs-mask">
        <div className="consent-terms-list">
          {isIISConsentEnabled && (
            <IISConsent
              isConsentAgree={
                isIISConsentEnabled ? patientInfo.iisConsent : false
              }
              onConsentChange={onChangeInput}
              dataqa="iis-consent"
            />
          )}
          <NotificationPreferences
            patientInfo={patientInfo}
            onChange={onChangeInput}
            clientName={organizationName}
          />
        </div>
      </div>
    </>
  );
};

export default PatientBasicInfo;
