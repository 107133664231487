import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDetailContext } from 'context';
import { useSecurityToken } from 'context/useSecurityToken';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useConfirmModal } from 'components/common/ConfirmModal';
import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { SecurityCheck } from '../../SecurityCheck/SecurityCheck';
import { AppointmentStatuses } from 'constants/appointment';
import { useGroupStatusCheck } from 'context/useGroupAppointmentStatusCheck';
import { InvalidAppointmentMessage } from 'components/COVID/common/InvalidAppointmentMessage';

export const GroupCancelVerify = () => {
  const { groupId, organizationId } = useParams();
  const { setSecurityToken, resetSecurityToken } = useSecurityToken();
  const { rescheduleDataSetup, setDetails } = useDetailContext();

  const nav = useNavigationHelper();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSetupCompleted, setDataSetupCompleted] = useState(false);

  const groupAppointmentStatus = useGroupStatusCheck(groupId);
  const { confirm } = useConfirmModal();
  const [isCancelValid, setIsCancelValid] = useState(true);

  const onSecurityVerified = useCallback(
    async ({ token, details: patientInfo }) => {
      try {
        setIsLoading(true);
        setSecurityToken(token);

        if (!dataSetupCompleted) {
          // if user verifies, then cancels then verifies again, dont do the reschedule setup again
          const result = await rescheduleDataSetup(
            organizationId,
            patientInfo.appointmentId,
          );
          if (!result) {
            throw new Error('Failed to setup  reschedule');
          }
          setDataSetupCompleted(true);
        }

        const appointment = await new Promise((resolve) => {
          setDetails(({ appointments }) => {
            resolve(appointments[0]);
            return {};
          });
        });

        setIsLoading(false);

        const isValid = [
          AppointmentStatuses.PENDING,
          AppointmentStatuses.BOOKED,
        ].includes(appointment.appointmentStatus);
        setIsCancelValid(isValid);

        if (!isValid) {
          return;
        }

        setIsLoading(false);
        nav.toGroup.cancel.toGroupAppointmentCancelType(
          patientInfo.appointmentId,
          groupId,
        );
      } catch (error) {
        console.error(error);
        error.message && toast.error({ title: '', message: error.message });
        resetSecurityToken();
      }
    },
    [
      confirm,
      setSecurityToken,
      resetSecurityToken,
      groupId,
      organizationId,
      rescheduleDataSetup,
      setDetails,
      dataSetupCompleted,
    ],
  );

  return (
    <PageWrapper>
      {isCancelValid ? (
        <SecurityCheck
          loading={isLoading || !groupAppointmentStatus.loaded}
          activityId={groupId}
          onSecurityVerified={onSecurityVerified}
          headerMsg={getString('followupRescheduleVerificationHeader')}
          alertMsg={getString('followupRescheduleVerificationAlert')}
          isGroupAppointment={true}
        />
      ) : (
        <InvalidAppointmentMessage />
      )}
    </PageWrapper>
  );
};
