import { useEffect, useState } from 'react';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { checkGroupAppointmentStatus } from '../services/appointment';

export const useGroupStatusCheck = (groupAppointmentId, redirect = true) => {
  const nav = useNavigationHelper();
  const [status, setStatus] = useState({
    loaded: false,
    isGroupAppointment: false,
    ok: false,
    error: false,
  });

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const groupAppointmentStatus =
          await checkGroupAppointmentStatus(groupAppointmentId);

        setStatus({
          loaded: true,
          isGroupAppointment: groupAppointmentStatus.isGroup,
          ok: !groupAppointmentStatus.late,
          error: false,
        });

        if (groupAppointmentStatus.late && redirect) {
          nav.toGroup.late(
            groupAppointmentId,
            groupAppointmentStatus.subServiceIds,
          );
        }
      } catch (error) {
        console.error(error);
        setStatus({
          loaded: true,
          isGroupAppointment: false,
          ok: false,
          error: true,
        });
      }
    };

    checkStatus();
  }, [groupAppointmentId, nav, redirect]);

  return status;
};
