import { getString } from 'util/lang';

import styles from './GeneralHomeSafetyInformation.module.scss';
import FormHeader from '../../common/FormHeader/FormHeader';

const LABEL_PREFIX = 'dme.deliveryPacketForm.generalHomeSafetyInformation';

const GeneralHomeSafetyInformation = (props) => {
  const { organizationDetail } = props;
  return (
    <div className="d-flex flex-column">
      <FormHeader text={getString(`${LABEL_PREFIX}.title`)} />

      <p className={styles.desc}>{getString(`${LABEL_PREFIX}.description`)}</p>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.medicines.title`)}</h3>
        <ul>
          {getString(`${LABEL_PREFIX}.medicines.informations`)?.map(
            (information, index) => (
              <li key={index}>{information}</li>
            ),
          )}
        </ul>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.mobilityItems.title`)}</h3>
        <p>{getString(`${LABEL_PREFIX}.mobilityItems.description`)}</p>
        <ul>
          {getString(`${LABEL_PREFIX}.mobilityItems.informations`)?.map(
            (information, index) => (
              <li key={index}>{information}</li>
            ),
          )}
        </ul>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.slipsAndFalls.title`)}</h3>
        <p>{getString(`${LABEL_PREFIX}.slipsAndFalls.description`)}</p>
        <ul>
          {getString(`${LABEL_PREFIX}.slipsAndFalls.informations`)?.map(
            (information, index) => (
              <li key={index}>{information}</li>
            ),
          )}
        </ul>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.electricalAccidents.title`)}</h3>
        <p>{getString(`${LABEL_PREFIX}.electricalAccidents.description`)}</p>
        <ul>
          {getString(`${LABEL_PREFIX}.electricalAccidents.informations`)?.map(
            (information, index) => (
              <li key={index}>{information}</li>
            ),
          )}
        </ul>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.smellGas.title`)}</h3>
        <ul>
          {getString(`${LABEL_PREFIX}.smellGas.informations`)?.map(
            (information, index) => (
              <li key={index}>{information}</li>
            ),
          )}
        </ul>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.fire.title`)}</h3>
        <ul>
          {getString(`${LABEL_PREFIX}.fire.informations`)?.map(
            ({ content, subInformations }, index) => (
              <li key={index}>
                <p>{content}</p>
                <ul>
                  {subInformations.map((information, index) => (
                    <li key={index}>
                      <p>{information}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ),
          )}
        </ul>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.fireAction.title`)}</h3>
        <ol className={styles.numberedList}>
          {getString(`${LABEL_PREFIX}.fireAction.informations`)?.map(
            ({ content, subInformations }, index) => (
              <li key={index}>
                <p>{content}</p>
                <ul>
                  {subInformations?.map((information, index) => (
                    <li key={index} className={styles.sectionDeepList}>
                      <p>{information}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ),
          )}
        </ol>
      </div>

      <h2
        className={`text-center ${styles.note}`}
        dangerouslySetInnerHTML={{
          __html: getString(`${LABEL_PREFIX}.registerAsHighPriorityCustomer`),
        }}
      />

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.emergencyPreparednessPlan.title`)}</h3>
        <p>
          {getString(`${LABEL_PREFIX}.emergencyPreparednessPlan.description`, {
            companyName: organizationDetail?.organizationName,
          })}
        </p>
      </div>

      <h2 className={`text-center ${styles.disasterPrevention}`}>
        {getString(`${LABEL_PREFIX}.disasterPrevention.title`)}
      </h2>
      {JSON.parse(
        getString(`${LABEL_PREFIX}.disasterPrevention.contents`, {
          phone: organizationDetail?.organizationContactPhone,
        }),
      ).map(({ title, informations }, index) => (
        <div key={index} className={styles.sectionWrap}>
          <h3 className={styles.disableTextUnderline}>{title}</h3>
          <ul>
            {informations.map((information, index) => (
              <li key={index}>{information}</li>
            ))}
          </ul>
        </div>
      ))}

      <div className={styles.sectionWrap}>
        <h3>
          {getString(`${LABEL_PREFIX}.infectionControlInformation.title`)}
        </h3>
        <ol className={styles.numberedList}>
          {getString(
            `${LABEL_PREFIX}.infectionControlInformation.informations`,
          )?.map((information, index) => (
            <li key={index}>{information}</li>
          ))}
        </ol>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.abuseAndNeglect.title`)}</h3>
        {JSON.parse(
          getString(`${LABEL_PREFIX}.abuseAndNeglect.descriptions`, {
            companyName: organizationDetail?.organizationName,
          }),
        )?.map((description, index) => (
          <p key={index}>{description}</p>
        ))}
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.advanceDirectives.title`)}</h3>
        <p>{getString(`${LABEL_PREFIX}.advanceDirectives.description`)}</p>
      </div>

      <div className={styles.sectionWrap}>
        <h3>
          {getString(`${LABEL_PREFIX}.definitionOfAdvanceDirectives.title`)}
        </h3>
        <p>
          {getString(
            `${LABEL_PREFIX}.definitionOfAdvanceDirectives.description`,
          )}
        </p>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.livingWill.title`)}</h3>
        <p>{getString(`${LABEL_PREFIX}.livingWill.description`)}</p>
      </div>

      <div className={styles.sectionWrap}>
        <h3>
          {getString(`${LABEL_PREFIX}.healthcareSurrogateDesignation.title`)}
        </h3>
        {getString(
          `${LABEL_PREFIX}.healthcareSurrogateDesignation.descriptions`,
        )?.map((description, index) => (
          <p key={index}>{description}</p>
        ))}
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.advancedDirectiveUnderLaw.title`)}</h3>
        <p>
          {getString(`${LABEL_PREFIX}.advancedDirectiveUnderLaw.description`)}
        </p>
      </div>

      <div className={styles.sectionWrap}>
        <h3>{getString(`${LABEL_PREFIX}.changeMyMind.title`)}</h3>
        <p>{getString(`${LABEL_PREFIX}.changeMyMind.description`)}</p>
      </div>

      <div className={styles.sectionWrap}>
        <h3>
          {getString(`${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.title`)}
        </h3>
        <p>
          {getString(
            `${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.description`,
          )}
        </p>

        <div>
          <p>
            {getString(
              `${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.designatedHealthSurrogate.title`,
            )}
          </p>
          <ul>
            {getString(
              `${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.designatedHealthSurrogate.actions`,
            )?.map((action, index) => (
              <li key={index}>{action}</li>
            ))}
          </ul>
        </div>

        <p>
          {getString(
            `${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.changeYourAdvanceDirective`,
          )}
        </p>
      </div>
    </div>
  );
};

export default GeneralHomeSafetyInformation;
