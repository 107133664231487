import config from '../config';
import * as http from '../util/http';

export async function verifyPatientRecord(userDetails) {
  const mobileNumber = userDetails?.mobileNo?.replaceAll('-', '');
  const fullBirthDate = `${userDetails.birthYear}-${userDetails.birthMonth}-${userDetails.birthDay}`;

  // Avoid network call for record with no dob
  // remove in future implementation
  if (!userDetails.birthYear) {
    return {
      isExistingPatient: false,
      patientId: null,
    };
  }

  const {
    data: { data },
  } = await http.get(config.entity.findContact, {
    params: {
      firstname: userDetails.firstName,
      lastname: userDetails.lastName,
      middlename: userDetails.middleName,
      birthdate: fullBirthDate,
      email: userDetails.email,
      mobilephone: mobileNumber,
    },
  });
  return data;
}
