import { getString } from 'util/lang';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import FormHeaderPdfView from '../../common/FormHeader/FormHeaderPdfView';

const LABEL_PREFIX = 'dme.deliveryPacketForm.operatingInstructions';

const operatingInstructions = getString(
  `${LABEL_PREFIX}.equipmentInstructions`,
);

const OperatingInstructionPdfView = () => {
  return (
    <View style={styles.operatingInstructionWrap}>
      <View style={{ marginTop: 12 }} />
      <FormHeaderPdfView text={getString(`${LABEL_PREFIX}.title`)} />

      <View style={styles.operatingInstruction}>
        {operatingInstructions.map((operatingInstruction, index) => (
          <View key={index} style={styles.categoryWrap}>
            <Text style={{ ...styles.headerThree, marginBottom: 4 }}>
              {operatingInstruction.category}
            </Text>
            <View style={styles.sectionContainer}>
              {operatingInstruction.sections.map((section, index) => (
                <View key={index} style={styles.sectionWrap}>
                  {typeof section.content === 'string' ? (
                    <Text style={styles.text}>
                      {!!section.name?.length && (
                        <Text style={{ ...styles.text }}>
                          <Text
                            style={{
                              ...styles.text,
                              textDecoration: 'underline',
                            }}
                          >
                            {section.name}
                          </Text>
                          :{' '}
                        </Text>
                      )}
                      {section.content}
                    </Text>
                  ) : (
                    <View style={styles.contentList}>
                      {section.content.map((content, index) => (
                        <View style={styles.row}>
                          <Text style={styles.text}>{index + 1}. </Text>
                          <Text style={styles.text}>{content}</Text>
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  operatingInstructionWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  operatingInstruction: {
    marginTop: 18,
  },
  categoryWrap: {
    marginTop: 8,
  },
  sectionContainer: {},
  sectionWrap: {},
  contentList: {
    marginLeft: 14,
  },
  headerThree: {
    fontSize: 9,
    fontWeight: 700,
    marginBottom: 5,
  },
  text: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    marginBottom: 4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default OperatingInstructionPdfView;
