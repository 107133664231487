import React from 'react';

import { PaymentOptions } from '../../../../../constants/patientInfo';
import { getString } from '../../../../../util/lang';

const PaymentType = ({ onChange, currentValue, value, label, dataqa }) => {
  return (
    <span className="custom-radio mb-3x">
      <input
        autoComplete="off"
        name="payment-type"
        type="radio"
        id={value}
        value={value}
        className="custom-radio-input"
        checked={currentValue === value}
        onChange={onChange}
        data-qa={dataqa}
      />
      <label htmlFor={value}>{label}</label>
    </span>
  );
};

const PaymentTypeSection = (props) => {
  const {
    onPaymentTypeChange,
    value,
    noInsuranceEnabled = false,
    isInsuranceEnabled = false,
    payAtTimeOfServiceEnabled = false,
  } = props;

  return (
    // <section className="form-payment-type section__margin">
    <div>
      <div className="row">
        <div className="col-12-md">
          <div className="form-group">
            {isInsuranceEnabled && (
              <PaymentType
                currentValue={value}
                value={PaymentOptions.Insurance}
                onChange={onPaymentTypeChange}
                label={getString('insurance')}
                dataqa="payment-insurance"
              />
            )}

            {noInsuranceEnabled && (
              <PaymentType
                currentValue={value}
                value={PaymentOptions.NoInsurance}
                onChange={onPaymentTypeChange}
                label={getString('noInsurance')}
                dataqa="payment-no-insurance"
              />
            )}

            {payAtTimeOfServiceEnabled && (
              <PaymentType
                currentValue={value}
                value={PaymentOptions.PayAtTimeOfService}
                onChange={onPaymentTypeChange}
                label={getString('payAtTimeOfService')}
                dataqa="payment-pay-at-time-of-service"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentTypeSection;
