import * as appointmentService from 'services/appointment';
import {
  AvailableInsuranceCoverageIndices,
  InsuranceCoverageTypes,
} from 'constants/patientInfo';
import { mapInsuranceCoverageBooleanToValue } from 'services/create-update-appointment';

export const uploadInsuranceFiles = async (
  activityId,
  insuranceFront,
  insuranceBack,
  token,
) => {
  if (insuranceFront && insuranceBack) {
    await appointmentService.uploadInsuranceFile(
      activityId,
      insuranceFront,
      insuranceBack,
      token,
    );
  }
};

const toBackEndDomain = {
  cardHolderFirstName: 'cardHolderFirstName',
  cardHolderMiddleName: 'cardHolderMiddleName',
  cardHolderLastName: 'cardHolderLastName',
  company: 'company',
  otherInsuranceCompany: 'otherInsuranceCompany',
  insuranceCoverageType: 'insuranceCoverage',
  groupNumber: 'groupNumber',
  insurancePlanType: 'insurancePlanType',
  insuranceType: 'insuranceType',
  medicareID: 'medicareID',
  medicareIssuer: 'medicareIssuer',
  memberID: 'memberID',
  memberIDPrefix: 'memberIDPrefix',
  memberIDSuffix: 'memberIDSuffix',
  subscriberRelationship: 'subscriberRelationship',
  rxBin: 'rxBin',
  rxGroup: 'rxGroup',
  rxPCN: 'rxPCN',
  insuranceEffectiveFrom: 'insuranceEffectiveFrom',
  insuranceEffectiveUntil: 'insuranceEffectiveUntil',
};

const toFrontEndDomain = {
  cardHolderFirstName: 'cardHolderFirstName',
  cardHolderMiddleName: 'cardHolderMiddleName',
  cardHolderLastName: 'cardHolderLastName',
  company: 'company',
  otherInsuranceCompany: 'otherInsuranceCompany',
  insuranceCoverage: 'insuranceCoverageType',
  groupNumber: 'groupNumber',
  insurancePlanType: 'insurancePlanType',
  insuranceType: 'insuranceType',
  medicareID: 'medicareID',
  medicareIssuer: 'medicareIssuer',
  memberID: 'memberID',
  memberIDPrefix: 'memberIDPrefix',
  memberIDSuffix: 'memberIDSuffix',
  subscriberRelationship: 'subscriberRelationship',
  rxBin: 'rxBin',
  rxGroup: 'rxGroup',
  rxPCN: 'rxPCN',
  insuranceEffectiveFrom: 'insuranceEffectiveFrom',
  insuranceEffectiveUntil: 'insuranceEffectiveUntil',
};
/**
 *
 * @param {array} insuranceData Insurance data which is to be converted to required domain
 * @param {*} phicureResponse object which is to be inserted in primary insurance data
 * @returns Converts the insuranceData in a format that is recognized by the backend api
 */
export const convertToInsuranceDomain = (
  insuranceData,
  phicureResponse,
  activeInsuranceCoverage,
  serviceIndex,
) => {
  return insuranceData
    .filter((i) => i)
    .map((insuranceData, insuranceIndex) => {
      const { insuranceCardBack, insuranceCardFront, rxBin, ...insuranceInfo } =
        insuranceData;

      insuranceInfo.insuranceCoverage = parseInt(
        insuranceInfo.insuranceCoverageType,
      );
      insuranceInfo.insuranceType = parseInt(insuranceInfo.insuranceType);
      insuranceInfo.subscriberRelationship = parseInt(
        insuranceInfo.subscriberRelationship,
      );
      insuranceInfo.insuranceEffectiveFrom = insuranceInfo.effectiveFrom;
      insuranceInfo.insuranceEffectiveUntil = insuranceInfo.effectiveTo;

      if (
        insuranceInfo.insuranceCoverageType ===
        InsuranceCoverageTypes[
          AvailableInsuranceCoverageIndices.primaryInsuranceIndex
        ]
      ) {
        insuranceInfo.phicureResponse =
          phicureResponse[insuranceIndex][serviceIndex];
        insuranceInfo.activeInsuranceCoverage =
          mapInsuranceCoverageBooleanToValue(
            activeInsuranceCoverage[insuranceIndex][serviceIndex],
          );
      }

      if (rxBin) {
        insuranceInfo.rxBin = parseInt(rxBin);
      }

      if (insuranceInfo.insurancePlanType) {
        insuranceInfo.insurancePlanType = parseInt(
          insuranceInfo.insurancePlanType,
        );
      }
      return insuranceInfo;
    });
};

/**
 *
 * @param {array} insurancesResponses Response array of insuranceData from the backend
 * @param {object} insuranceData which is to be compared against the array to extract corresponding insuranceId
 * @returns insuranceId based on matched insurance info
 */
export const getInsuranceIdForMatchingInsuranceDetails = (
  insurancesResponses,
  insuranceData,
) => {
  return insurancesResponses.filter((insuranceR) => {
    const { insuranceId, policyNumber, patientId, ...rest } = insuranceR;
    const restKeys = Object.keys(rest);
    const keysMatch = restKeys.every((key) => {
      // the type returned by backend and frontend are different for some of the case. This is why == is kept instead of ===
      return (
        Object.keys(insuranceData).includes(toFrontEndDomain[key]) &&
        insuranceData[toFrontEndDomain[key]] == rest[key]
      );
    });
    return keysMatch;
  })[0]?.insuranceId;
};
