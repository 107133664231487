import { useEffect } from 'react';

export function useOutsideAlerter(ref, handleClick, exceptionElementsRef) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        exceptionElementsRef?.some((exceptionElement) =>
          exceptionElement?.current?.contains(event.target),
        )
      )
        return;
      if (ref?.current && !ref?.current?.contains(event.target)) {
        handleClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}
