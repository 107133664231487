import React from 'react';

import { CustomCollapsible } from './Collapsible';

import { collapsibleContent } from '../constants/collapsibleContent';

export const HelpCenterAccordion = () => {
  return (
    <div className="help-center__accordion">
      {collapsibleContent.map((contents) => {
        return (
          <CustomCollapsible
            trigger={contents.trigger}
            content={contents.content}
          />
        );
      })}
    </div>
  );
};
