import config from '../config';
import * as http from '../util/http';

export async function waitlistPatient({
  patientInfo,
  eligibilityResponse,
  screeningAnswers,
  associatedSubServiceIds,
}) {
  const waitlistData = {
    patientInfo,
    associatedSubServiceIds,
    screeningAnswers,
    eligibilityResponse,
  };

  const { data } = await http.post(config.entity.waitlistPatient, waitlistData);

  return data.data;
}
