import { DME_TOKEN } from 'constants/dme';

/**
 * Stores data in the sessionStorage.
 *
 * @param {string} key - The key under which the data will be stored.
 * @param {*} value - The data to be stored.
 */
export const storeDataToSessionStorage = (key, value) => {
  // Convert the value to a JSON string before storing
  const jsonValue = JSON.stringify(value);

  // Store the JSON string in the sessionStorage with the given key
  sessionStorage.setItem(key, jsonValue);
};

/**
 * Retrieves data from the sessionStorage.
 *
 * @param {string} key - The key under which the data is stored.
 * @returns {*} The retrieved data, or null if the key does not exist.
 */
export const getDataFromSessionStorage = (key) => {
  // Retrieve the JSON string from the sessionStorage using the given key
  const jsonValue = sessionStorage.getItem(key);

  // Parse the JSON string to convert it back to its original value
  if (jsonValue !== null) {
    return JSON.parse(jsonValue);
  } else {
    return null; // Return null if the key does not exist
  }
};

/**
 * Clears data from the sessionStorage.
 *
 * @param {string} key - The key under which the data is stored.
 */
export const clearDataFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

/**
 * Clears DME token from the sessionStorage.
 */
export const clearDMETokenFromSessionStorage = () => {
  clearDataFromSessionStorage(DME_TOKEN);
};
