import React, { useContext } from 'react';

import { Navbar } from '../../common/Navbar';
import { Banner } from '../../common/Banner';
import { DetailContext } from '../../../../../../context';
import { ApplicationListContainer } from '../presentation/ApplicationList/ApplicationListContainer';
import { HelpfulResourceListContainer } from '../presentation/HelpfulResource/HelpfulResourceListContainer';
import { changeTitle } from 'util/siteInfo';

export const WelcomePage = () => {
  changeTitle('Welcome');
  const context = useContext(DetailContext);

  const appSettings = context.getAppSettings();

  const usefulLinks = {
    demoVideo: appSettings.demoVideo || '#',
    managerApp: appSettings.managerApp || '#',
    staffApp: appSettings.staffApp || '#',
    trainingManual: appSettings.trainingManual || '#',
    dashboard: appSettings.dashboard || '#',
    billingOperationsPortal: appSettings.billingOperatorPortal || '#',
  };

  return (
    <div className="welcome-page">
      <div className="container-lg">
        <Navbar organizationName={appSettings.organizationName} />
        <Banner />
        <ApplicationListContainer usefulLinks={usefulLinks} />
      </div>
    </div>
  );
};
