import React from 'react';
import { getString } from 'util/lang';
import { FiCheck } from 'vyaguta-icons/fi';

/**
 * Input component
 * @param {string} type: input type
 * @param {string} id: input id
 * @param {string} label: label for input
 * @param {function} onChange: callback on input change
 * @param {string} value: value of input field
 * @param {bool} hasError: Validity of input
 * @param {bool} required: Show * to indicate as 'required'
 */
const FormInput = (props) => {
  const {
    type = 'text',
    id,
    inputmode,
    label,
    onChange,
    value,
    onBlur = () => {},
    hasError = false,
    required = false,
    disablePaste = false,
    isVisible = true,
    optOutValue,
    showOptOut,
    onChangeCheck,
    disableInput,
    inputAutoComplete,
    solo = false,
    fullWidth = false,
    defaultPlaceHolder,
    ...restProps
  } = props;

  const displayStyle = isVisible
    ? { display: 'block', width: '100%' }
    : { display: 'none' };

  return (
    <div
      className={`form-group mb-0x${hasError ? ' has-error' : ''} separate-label`}
      style={displayStyle}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label className={'d-inline-block mb-2x h5 text-demibold'} htmlFor={id}>
          <label className="" id="" />
          {label}
        </label>
        <div className="row">
          <div className={`col-${fullWidth ? '12' : '6'}-md`}>
            <input
              {...restProps}
              {...{ type, id, inputMode: inputmode }}
              disabled={disableInput}
              className={`form-control w-100 ${solo ? 'solo' : ''}`}
              onBlur={(e) => onBlur(id, e.target.value)}
              onChange={(e) => onChange(id, e.target.value)}
              autoComplete={inputAutoComplete ? inputAutoComplete : ''}
              onPaste={
                disablePaste
                  ? (e) => {
                      e.preventDefault();
                      return false;
                    }
                  : null
              }
              value={value || ''}
              placeholder={
                !disableInput
                  ? defaultPlaceHolder ?? getString('enterResponseText')
                  : ''
              }
            />

            {showOptOut ? (
              <CheckInput
                name={id}
                checked={optOutValue}
                onChange={(checked) => {
                  onChangeCheck(checked);
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const CheckInput = ({ name, checked = false, onChange }) => {
  return (
    <div className="custom-check custom-check--sm px-0x mb-3x check-opt-out">
      <input
        autoComplete="off"
        type="checkbox"
        id={name + 'cb'}
        name={name + 'cb'}
        onChange={(event) => {
          onChange(event.target.checked);
        }}
        checked={checked || false}
      />
      <label htmlFor={name + 'cb'}>
        <FiCheck /> {getString('optOut')}
      </label>
    </div>
  );
};

export default FormInput;
