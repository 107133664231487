import React, { useState, useContext } from 'react';
import { FiXCircle } from 'vyaguta-icons/fi';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import { UiCloudUpload, UiFilesLandscapes } from 'vyaguta-icons/ui';

import { getString } from '../../util/lang';
import { DetailContext } from '../../context/index';
import { InsuranceCardLimit } from 'constants/patientInfo';

const ImageUploader = (props) => {
  const appContext = useContext(DetailContext);
  const { appSettings } = appContext.details;
  const { getValue, onError, name, previewFile, dataqa } = props;
  const [file, setFile] = useState(previewFile ?? null);
  const [preview, setPreview] = useState(
    previewFile ? URL.createObjectURL(previewFile) : null,
  );
  const label = getString('imageUploaderLabel');
  const [uploadText, browseText] = label.split(
    getString('imageUploaderLabelSeparator'),
  );
  const setFilePreviewToNull = () => {
    setFile(null);
    setPreview(null);
    getValue(null);
  };

  const setFileData = (file) => {
    setFile(file);
    setPreview(URL.createObjectURL(file));
    getValue(file);
    onError(name, null);
  };

  const handlePDF = (file) => {
    if (file.size <= InsuranceCardLimit.InsuranceCardMaxSize) {
      setFileData(file);
    } else {
      onError(name, getString('insuranceCardLimitValidation'));
      setFilePreviewToNull();
    }
  };

  const handleImage = async (file) => {
    try {
      if (file.size <= InsuranceCardLimit.InsuranceCardMaxSize) {
        setFileData(file);
        return;
      }

      const compressedFile = await resizeImage(file);

      if (compressedFile.size <= InsuranceCardLimit.InsuranceCardMaxSize) {
        setFileData(compressedFile);
        return;
      }

      onError(name, getString('insuranceCardLimitValidation'));
      setFilePreviewToNull();
    } catch (error) {
      // TODO: different message
      onError(name, getString('insuranceCardLimitValidation'));
      setFilePreviewToNull();
    }
  };

  const resizeImage = (file) =>
    Promise.race([
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          appSettings?.imageWidth || 1024,
          appSettings?.imageHeight || 1024,
          'JPEG',
          96,
          0,
          (uri) => {
            resolve(uri);
          },
          'file',
        );
      }),
      new Promise((_, reject) => {
        setTimeout(
          () => reject(new Error('Cannot convert image within timeout')),
          10 * 1000,
        );
      }),
    ]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file.type === 'application/pdf') {
        handlePDF(file);
      } else if (file.type.startsWith('image/')) {
        handleImage(file);
      } else {
        onError(name, getString('insuranceCardValidation'));
        setFilePreviewToNull();
      }
    },
  });

  return (
    <>
      <div
        {...getRootProps()}
        className="image-uploader-container cursor-pointer"
      >
        <div className="image-uploader-elements ">
          <input data-qa={dataqa} {...getInputProps()} />

          {!preview && (
            <div>
              <div className="image-uploader-icon">
                <UiCloudUpload />
              </div>
              <div className="image-uploader-text">
                {uploadText}{' '}
                <p>
                  {' '}
                  {getString('imageUploaderLabelSeparator')}{' '}
                  <span> {browseText}</span>
                </p>
                <span>{getString('imageUploaderSupportedType')}</span>
              </div>
            </div>
          )}

          {preview && file && (
            <div className="preview-image">
              {file.type.startsWith('image/') ? (
                <img src={preview} alt="Preview" />
              ) : file.type === 'application/pdf' ? (
                <iframe
                  src={preview}
                  frameborder="0"
                  title="pdf-reader"
                ></iframe>
              ) : (
                <img src={UiFilesLandscapes} alt="Preview" />
              )}

              <div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setFilePreviewToNull();
                  }}
                >
                  <FiXCircle />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageUploader;
