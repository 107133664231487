import { FiMapPin } from 'vyaguta-icons/fi';
import MapMarkerIcon from 'svg-images/MapMarkerIcon';
import React, { Suspense, useEffect, useState } from 'react';

import { getString } from 'util/lang';
import CONFIG from '../../../../../config';
import Button from 'components/common/Button';
import ActivityIndicator from 'components/common/ActivityIndicator';

import 'mapbox-gl/dist/mapbox-gl.css';
import WaitlistNotice from './WaitlistNotice';
import NoAppointmentsNotice from '../../SelectService/presentation/NoAppointmentsNotice';

import ReactMapGL, { Marker, Popup } from 'react-map-gl';

const defaultLatitude = 47.62;
const defaultLongitude = -122.32;

const ClinicMap = (props) => {
  const {
    clinics,
    selectedClinic,
    onLocationChange,
    onSubmit,
    gotoWaitlist,
    showWaitlistNotice,
    showNoAppointmentsNotice,
    isModal = false,
  } = props;

  const [viewport, setViewport] = useState({
    latitude: defaultLatitude,
    longitude: defaultLongitude,
    zoom: 10,
  });

  // set map viewport when location is changed
  useEffect(() => {
    setViewport((viewPort) => ({
      ...viewPort,
      latitude: selectedClinic?.latitude ?? defaultLatitude,
      longitude: selectedClinic?.longitude ?? defaultLongitude,
    }));
  }, [selectedClinic]);

  const handleMarkerClick = onLocationChange;

  const availableSlotText = selectedClinic
    ? getString('nAppointmentsAvailable')
    : null;

  return (
    <Suspense fallback={<ActivityIndicator />}>
      <ReactMapGL
        {...viewport}
        height={'100%'}
        width={'100%'}
        mapboxApiAccessToken={CONFIG.mapBoxKey}
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
      >
        {clinics.map((clinic) => {
          return (
            <Marker
              key={clinic.id}
              latitude={clinic.latitude}
              longitude={clinic.longitude}
            >
              <MapMarkerIcon
                size={20}
                onClick={() => handleMarkerClick(clinic)}
              />
            </Marker>
          );
        })}

        {showWaitlistNotice && (
          <div className="clinic-card map-card d-block d-md-none">
            <div className="clinic-card__label flex-column">
              <WaitlistNotice gotoWaitlist={gotoWaitlist} />
            </div>
          </div>
        )}

        {showNoAppointmentsNotice && (
          <div className="clinic-card map-card d-block d-md-none">
            <div className="clinic-card__label flex-column">
              <NoAppointmentsNotice />
            </div>
          </div>
        )}
        {!!clinics.length && selectedClinic ? (
          <div>
            {isModal ? (
              <Popup
                latitude={selectedClinic.latitude}
                longitude={selectedClinic.longitude}
                className={'d-none d-lg-block'}
              >
                <div className="clinic-card map-card modal__map__card d-none d-lg-block">
                  <div className="clinic-card__label flex-column">
                    <div className="d-flex mb-4x">
                      <div className="clinic-card__location">
                        <FiMapPin size={20} />
                      </div>
                      <div className="clinic-card__content">
                        <div className="card__name clinic__name">
                          {selectedClinic.name}
                        </div>
                        <div className="card__location clinic__location">
                          {selectedClinic.address}, {selectedClinic.address}
                        </div>
                        <div className="mt-2x pill pill-info">
                          {availableSlotText}
                        </div>
                      </div>
                    </div>
                    <Button
                      isEnabled={true}
                      label={getString('continue')}
                      onClick={onSubmit}
                    />
                  </div>
                </div>
              </Popup>
            ) : (
              <Popup
                latitude={selectedClinic.latitude}
                longitude={selectedClinic.longitude}
                onClose={() => handleMarkerClick(null)}
                className={'d-none d-lg-block'}
              >
                <div>
                  <span className="text-bold mapbox-popup-title">
                    {selectedClinic.name}
                  </span>
                  <br />
                  <span className="mapbox-popup-address">
                    {selectedClinic.address}, {selectedClinic.city}
                  </span>
                </div>
              </Popup>
            )}

            {!!clinics.length && selectedClinic && (
              <div className="clinic-card map-card d-block d-lg-none">
                <div className="clinic-card__label flex-column">
                  <div className="d-flex align-self-start  mb-4x">
                    <div className="clinic-card__location">
                      <FiMapPin size={20} />
                    </div>
                    <div className="clinic-card__content">
                      <div className="card__name clinic__name">
                        {selectedClinic.msemr_name}
                      </div>
                      <div className="card__location clinic__location">
                        {selectedClinic.address}, {selectedClinic.city}
                      </div>
                      <div className="mt-2x pill pill-info">
                        {availableSlotText}
                      </div>
                    </div>
                  </div>
                  <Button
                    isEnabled={true}
                    label={getString('continue')}
                    onClick={onSubmit}
                  />
                </div>
              </div>
            )}
          </div>
        ) : null}
      </ReactMapGL>
      {!!clinics.length && selectedClinic && (
        <div className="clinic-card map-card d-block d-lg-none">
          <div className="clinic-card__label flex-column align-items-start">
            <div className="d-flex mb-4x">
              <div className="clinic-card__location">
                <FiMapPin size={20} />
              </div>
              <div className="clinic-card__content">
                <div className="card__name clinic__name">
                  {selectedClinic.name}
                </div>
                <div className="card__location clinic__location">
                  {selectedClinic.address}, {selectedClinic.city}
                </div>
                <div className="mt-2x pill pill-info">{availableSlotText}</div>
              </div>
            </div>
            <Button
              isEnabled={true}
              label={getString('continue')}
              onClick={onSubmit}
            />
          </div>
        </div>
      )}
    </Suspense>
  );
};

export default ClinicMap;
