import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { getString } from 'util/lang';

const PharmacistSignaturePdfView = ({
  pharmacistSignature,
  testResultDateTime,
}) => (
  <View style={styles.wrapper}>
    <View style={styles.cell}>
      <Text style={styles.signatureText}>
        {getString('testReport.providerSignature')}
      </Text>
      <Image src={pharmacistSignature} style={styles.signatureImage} />
    </View>
    <View style={styles.cell}>
      <Text>{getString('testReport.date')}</Text>{' '}
      <Text style={styles.dateTimeText}>{testResultDateTime}</Text>
    </View>
  </View>
);

export default PharmacistSignaturePdfView;

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px',
  },
  cell: {
    padding: '4px 12px',
    fontWeight: 700,
    width: '150px',
  },
  signatureText: {
    minWidth: '100px',
    paddingRight: 12,
  },
  signatureImage: {
    marginTop: '20px',
    height: '70px',
  },

  dateTimeText: {
    marginTop: '15px',
    fontWeight: 500,
  },
});
