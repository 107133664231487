import Button from 'components/common/Button';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import { useSelectionsContext } from 'context';
import { useFilterValidSecondaryServices } from 'context/useFilterValidSecondaryServices';
import { usePrimaryServiceTypes } from 'context/usePrimaryServiceTypes';
import { useResetAppState } from 'context/useResetAppState';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useCallback, useMemo, useState } from 'react';
import { getString } from 'util/lang';
import { EditServiceAccordion } from '../presentation/EditServiceAccordion';
import { changeTitle } from 'util/siteInfo';

const noop = () => {};

const EditService = () => {
  changeTitle('Edit Service');
  const nav = useNavigationHelper();
  const resetAppState = useResetAppState();

  const { selectedServices, setSelectedServices } = useSelectionsContext();
  const getValidSecondaryServices = useFilterValidSecondaryServices();

  const [primarySelection, setPrimarySelection] = useState({
    serviceTypeId: selectedServices[0]?.serviceTypeId,
    serviceId: selectedServices[0]?.serviceId,
  });

  const [secondarySelections, setSecondarySelections] = useState(() =>
    selectedServices.slice(1).map(({ serviceTypeId, serviceId }) => ({
      serviceTypeId,
      serviceId,
    })),
  );

  const primaryServiceTypes = usePrimaryServiceTypes();
  const [secondaryServiceTypes, setSecondaryServiceTypes] = useState(() =>
    getValidSecondaryServices({
      serviceTypeId: primarySelection.serviceTypeId,
      serviceId: primarySelection.serviceId,
    }),
  );

  const onSelectPrimaryService = useCallback(
    (serviceTypeId, serviceId) => {
      setPrimarySelection({ serviceTypeId, serviceId });

      const newSecondaryServiceTypes = getValidSecondaryServices({
        serviceTypeId,
        serviceId,
      });

      setSecondaryServiceTypes(newSecondaryServiceTypes);

      if (newSecondaryServiceTypes.length === 0) {
        // does not have secondary services

        setSecondarySelections([]);
        return;
      }

      let shouldUpdateSecondarySelections = false;
      const updatedSecondarySelection = secondarySelections.map(
        ({ serviceTypeId, serviceId }) => {
          const serviceType = newSecondaryServiceTypes.find(
            ({ id }) => id === serviceTypeId,
          );
          if (!serviceType) {
            shouldUpdateSecondarySelections = true;
            return {
              serviceTypeId: newSecondaryServiceTypes[0].id, // select first by default
              serviceId: undefined,
            };
          }
          const service = serviceType.services.find(
            ({ id }) => id === serviceId,
          );
          if (!service) {
            shouldUpdateSecondarySelections = true;
            return {
              serviceTypeId,
              serviceId: undefined,
            };
          }
          return { serviceTypeId, serviceId };
        },
      );

      if (shouldUpdateSecondarySelections) {
        setSecondarySelections(updatedSecondarySelection);
      }
    },
    [secondarySelections, getValidSecondaryServices],
  );

  const onSelectSecondaryService = (serviceTypeId, serviceId, { idx }) =>
    setSecondarySelections((serviceSelections) => [
      ...serviceSelections.slice(0, idx),
      { serviceTypeId, serviceId },
      ...serviceSelections.slice(idx + 1),
    ]);

  const onRemoveSecondaryService = ({ idx }) => {
    setSecondarySelections((serviceSelections) => [
      ...serviceSelections.slice(0, idx),
      ...serviceSelections.slice(idx + 1),
    ]);
  };

  const onAddSecondaryService = useCallback(() => {
    const serviceType = secondaryServiceTypes[0];
    if (!serviceType) {
      // no secondary services available
      return;
    }
    setSecondarySelections((secondarySelections) => [
      ...secondarySelections,
      { serviceTypeId: serviceType.id, serviceId: undefined },
    ]);
  }, [secondaryServiceTypes]);

  const onBack = useCallback(() => {
    nav.goBack();
  }, [nav]);

  const primaryServiceEligibile = useMemo(() => {
    const serviceType = primaryServiceTypes.find(
      ({ id }) => primarySelection.serviceTypeId === id,
    );
    if (!serviceType) {
      return true;
    }
    const service = serviceType.services.find(
      ({ id }) => primarySelection.serviceId === id,
    );
    if (!service) {
      return true;
    }

    return service.isEligible;
  }, [primarySelection, primaryServiceTypes]);

  const secondaryServiceElibilitity = useMemo(() => {
    return secondarySelections.map(({ serviceId, serviceTypeId }) => {
      const serviceType = secondaryServiceTypes.find(
        ({ id }) => serviceTypeId === id,
      );
      if (!serviceType) {
        return true;
      }
      const service = serviceType.services.find(({ id }) => serviceId === id);
      if (!service) {
        return true;
      }

      return service.isEligible;
    });
  }, [secondarySelections, secondaryServiceTypes]);

  const canSave = useMemo(
    () =>
      primarySelection.serviceId &&
      primarySelection.serviceTypeId &&
      primaryServiceEligibile &&
      secondarySelections.every(
        (selection) => selection.serviceId && selection.serviceTypeId,
      ) &&
      secondaryServiceElibilitity.every((eligible) => eligible),
    [
      primarySelection,
      secondarySelections,
      primaryServiceEligibile,
      secondaryServiceElibilitity,
    ],
  );

  const onSaveEdit = useCallback(() => {
    if (!canSave) {
      return;
    }

    const newServiceSelection = [primarySelection, ...secondarySelections];

    resetAppState(({ serviceTypes, services, subServices, patientInfo }) => ({
      serviceTypes,
      services,
      subServices,
      patientInfo,
    }));
    setSelectedServices(newServiceSelection);

    nav.toSelectSubService();
  }, [
    nav,
    primarySelection,
    secondarySelections,
    canSave,
    setSelectedServices,
    resetAppState,
  ]);

  if (!selectedServices || selectedServices.length < 1) {
    nav.resetToFirstPage();
    return null;
  }

  return (
    <PageWrapper>
      <BackToPage text={getString('backToPreviousPage')} onClick={onBack} />

      <h2 className="title--normal">{getString('editService')}</h2>

      <EditServiceAccordion
        heading={getString('selectedService')}
        notice={
          secondaryServiceTypes.length > 0
            ? getString('primaryServiceChangeNotice')
            : null
        }
        serviceTypes={primaryServiceTypes}
        serviceSelection={primarySelection}
        open={secondarySelections.length === 0 || !primaryServiceEligibile}
        removable={false}
        onSelectService={onSelectPrimaryService}
        onRemoveService={noop} // cant remove primary service
        inEligible={!primaryServiceEligibile}
      />

      {secondaryServiceTypes.length > 0 &&
        secondarySelections.map((selection, idx) => (
          <EditServiceAccordion
            key={idx}
            heading={getString('additionalService')}
            serviceTypes={secondaryServiceTypes}
            serviceSelection={selection}
            open
            removable
            onSelectService={onSelectSecondaryService}
            onRemoveService={onRemoveSecondaryService}
            cbCtx={{ idx }}
            inEligible={!secondaryServiceElibilitity[idx]}
          />
        ))}

      {secondarySelections.length === 0 && secondaryServiceTypes.length > 0 && (
        <EditServiceAccordion
          heading={getString('additionalService')}
          serviceTypes={[]}
          open
          addService
          onAddService={onAddSecondaryService}
        />
      )}

      <Button
        isEnabled={canSave}
        label={getString('saveChanges')}
        onClick={onSaveEdit}
        classNames={{ button: 'mb-4x' }}
      />
      <Button isEnabled outline label={getString('cancel')} onClick={onBack} />
    </PageWrapper>
  );
};

export default EditService;
