import { useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import {
  DME_VERIFY,
  DME_DELIVERY_TICKET,
  DME_DELIVERY_PACKET,
  DME_DELIVERY_TICKET_PRINT,
  DME_DELIVERY_PACKET_PRINT,
  DME_DOCUMENT,
} from 'constants/routes/covidRoutes';

const routeWithOrgId = (route, orgId) => (orgId ? `${route}/${orgId}` : route);

export const routes = {
  selectService: (orgId) => routeWithOrgId('/service', orgId),
  editService: (orgId) => routeWithOrgId('/editservices', orgId),
  prescreening: (orgId) => routeWithOrgId('/prescreening', orgId),
  secondaryService: (orgId) => routeWithOrgId('/secondaryservice', orgId),
  groupAppointmentOption: (orgId) => routeWithOrgId('/groupoption', orgId),
  selectSubService: (orgId) => routeWithOrgId('/subservicelist', orgId),
  notEligible: (orgId) => routeWithOrgId('/noteligible', orgId),
  selectLocation: (orgId) => routeWithOrgId('/location', orgId),
  selectTiming: (orgId) => routeWithOrgId('/clinicAndTiming', orgId),
  patientEligibility: (orgId) => routeWithOrgId('/home', orgId),
  patientInfo: (orgId) => routeWithOrgId('/patientinfo', orgId),
  paymentInfo: (orgId) => routeWithOrgId('/paymentInfo', orgId),
  addPatients: (orgId) => routeWithOrgId('/addPatients', orgId),
  confirmation: (orgId) => routeWithOrgId('/confirmation', orgId),
  success: (orgId) => routeWithOrgId('/success', orgId),
  reschedule: (orgId, activityId) =>
    routeWithOrgId(`/reschedule/${activityId}`, orgId),
  rescheduleConfirmation: (orgId, activityId) =>
    routeWithOrgId(`/appointment/confirmation/${activityId}`, orgId),
  rescheduleAppointment: (orgId, activityId) =>
    routeWithOrgId(`/appointment/clinicAndTiming/${activityId}`, orgId),
  unsubscribe: (patientId) => `/unsubscribe/${patientId}`,
  resubscribe: (patientId) => `/resubscribe/${patientId}`,
  testReport: (appointmentId) => `/testresult/${appointmentId}`,
  printTestReport: (appointmentId) => `/testresult/print/${appointmentId}`,
  followup: {
    reschedule: {
      verify: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/reschedule/verify`, orgId),
      location: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/reschedule/location`, orgId),
      schedule: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/reschedule/schedule`, orgId),
      screening: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/reschedule/screening`, orgId),
      patientInfo: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/reschedule/patientInfo`, orgId),
      paymentInfo: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/reschedule/paymentInfo`, orgId),
      confirmation: (orgId, activityId) =>
        routeWithOrgId(
          `/followup/${activityId}/reschedule/confirmation`,
          orgId,
        ),
      success: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/reschedule/success`, orgId),
    },
    late: (orgId, activityId) =>
      routeWithOrgId(`/followup/${activityId}/late`, orgId),
    register: {
      verify: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/complete/verify`, orgId),
      screening: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/complete/screening`, orgId),
      patientInfo: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/complete/patientInfo`, orgId),
      paymentInfo: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/complete/paymentInfo`, orgId),
      confirmation: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/complete/confirmation`, orgId),
      success: (orgId, activityId) =>
        routeWithOrgId(`/followup/${activityId}/complete/success`, orgId),
    },
  },
  group: {
    reschedule: {
      verify: (orgId, groupAppointmentId) =>
        routeWithOrgId(`/group/${groupAppointmentId}/reschedule/verify`, orgId),
      selection: (orgId, activityId) =>
        routeWithOrgId(`/group/${activityId}/reschedule/selection`, orgId),
      location: (orgId, activityId) =>
        routeWithOrgId(`/group/${activityId}/reschedule/location`, orgId),
      schedule: (orgId, activityId) =>
        routeWithOrgId(`/group/${activityId}/reschedule/schedule`, orgId),
      confirmation: (orgId, activityId) =>
        routeWithOrgId(`/group/${activityId}/reschedule/confirmation`, orgId),
      success: (orgId, activityId) =>
        routeWithOrgId(`/group/${activityId}/reschedule/success`, orgId),
    },
    register: {
      verify: (orgId, groupAppointmentId) =>
        routeWithOrgId(`/group/${groupAppointmentId}/complete/verify`, orgId),
      screening: (orgId, activityid) =>
        routeWithOrgId(`/group/${activityid}/complete/screening`, orgId),
      patientInfo: (orgId, activityId) =>
        routeWithOrgId(`/group/${activityId}/complete/patientInfo`, orgId),
      confirmation: (orgId, activityId) =>
        routeWithOrgId(`/group/${activityId}/complete/confirmation`, orgId),
      success: (orgId, activityId) =>
        routeWithOrgId(`/group/${activityId}/complete/success`),
    },
    cancel: {
      groupCancelType: (orgId) => routeWithOrgId('/group/canceltype', orgId),
      verify: (orgId, groupAppointmentId) =>
        routeWithOrgId(`/group/${groupAppointmentId}/cancel/verify`, orgId),
    },
    late: (orgId, activityId) =>
      routeWithOrgId(`/group/${activityId}/late`, orgId),
  },
  waitlist: {
    screening: (orgId) => routeWithOrgId('/waitlist/screening', orgId),
    success: (orgId) => routeWithOrgId('/waitlist/success', orgId),
  },
};

export const useNavigationHelper = () => {
  const location = useLocation();
  const history = useHistory();
  const { organizationId } = useParams();

  return useMemo(() => {
    /**
     *
     * @param {import('history').LocationDescriptorObject} locationDescriptor
     * @returns {(options?: { replace?: boolean }) => void}
     */
    const navigate =
      (locationDescriptor) =>
      (options = {}) => {
        if (options.replace) {
          history.replace({
            search: location.search,
            state: {
              from: location.state?.from,
              ...options.state,
            },
            ...locationDescriptor,
          });
        } else {
          history.push({
            search: location.search,
            state: {
              from: location,
              ...options.state,
            },
            ...locationDescriptor,
          });
        }
      };
    return {
      goBack: () => {
        if (location.state?.from) {
          history.push(location.state.from);
        } else {
          history.push({
            pathname: routes.selectService(organizationId),
            search: location.search,
          });
        }
      },
      resetToFirstPage: (_organizationId = undefined) => {
        history.replace({
          pathname: routes.selectService(_organizationId ?? organizationId),
          search: location.search,
          // no from state
        });
      },
      getNavigationState: () => location.state,
      toSelectService: navigate({
        pathname: routes.selectService(organizationId),
      }),
      toEditService: navigate({ pathname: routes.editService(organizationId) }),
      toPreScreening: navigate({
        pathname: routes.prescreening(organizationId),
      }),
      toGroupAppointmentOption: navigate({
        pathname: routes.groupAppointmentOption(organizationId),
      }),

      // Route to group appoitnemnt
      toSecondaryService: navigate({
        pathname: routes.secondaryService(organizationId),
      }),
      toSelectSubService: navigate({
        pathname: routes.selectSubService(organizationId),
      }),
      toNotEligible: navigate({ pathname: routes.notEligible(organizationId) }),
      toSelectLocation: navigate({
        pathname: routes.selectLocation(organizationId),
      }),
      toSelectTiming: navigate({
        pathname: routes.selectTiming(organizationId),
      }),
      toAddPatients: navigate({ pathname: routes.addPatients(organizationId) }),
      toPatientEligibility: navigate({
        pathname: routes.patientEligibility(organizationId),
      }),
      toPatientInfo: navigate({ pathname: routes.patientInfo(organizationId) }),
      toPaymentInfo: navigate({ pathname: routes.paymentInfo(organizationId) }),
      toConfirmation: navigate({
        pathname: routes.confirmation(organizationId),
      }),
      toSuccess: navigate({ pathname: routes.success(organizationId) }),
      toReschedule: (activityId, options) =>
        navigate({
          pathname: routes.reschedule(organizationId, activityId),
          state: { isReschedule: true },
        })(options),
      toRescheduleConfirmation: (activityId, options) =>
        navigate({
          pathname: routes.rescheduleConfirmation(organizationId, activityId),
        })(options),
      toRescheduleAppointment: (activityId, options) =>
        navigate({
          pathname: routes.rescheduleAppointment(organizationId, activityId),
        })(options),
      toCancel: (activityId, options) =>
        navigate({
          pathname: routes.reschedule(organizationId, activityId),
          state: { isCancel: true },
        })(options),
      toUnsubscribe: (patientId, hash, type, options) =>
        navigate({
          pathname: routes.unsubscribe(patientId),
          search: new URLSearchParams({ hash, type }).toString(),
        })(options),
      toResubscribe: (patientId, hash, type, options) =>
        navigate({
          pathname: routes.resubscribe(patientId),
          search: new URLSearchParams({ hash, type }).toString(),
        })(options),
      toGroup: {
        register: {
          verify: (groupAppointmentId, options) =>
            navigate({
              pathname: routes.group.register.verify(
                organizationId,
                groupAppointmentId,
              ),
            })(options),
          screening: (activityId, options) =>
            navigate({
              pathname: routes.group.register.screening(
                organizationId,
                activityId,
              ),
            })(options),
          patientInfo: (activityId, options) =>
            navigate({
              pathname: routes.group.register.patientInfo(
                organizationId,
                activityId,
              ),
            })(options),
          confirmation: (activityId, options) =>
            navigate({
              pathname: routes.group.register.confirmation(
                organizationId,
                activityId,
              ),
            })(options),
          success: (activityId, options) =>
            navigate({
              pathname: routes.group.register.success(
                organizationId,
                activityId,
              ),
            })(options),
        },
        cancel: {
          toGroupAppointmentCancelType: (
            activityId,
            groupAppointmentId,
            options,
          ) =>
            navigate({
              pathname: routes.group.cancel.groupCancelType(organizationId),
              state: { activityId, groupAppointmentId },
            })(options),
          verify: (groupAppointmentId, options) =>
            navigate({
              pathname: routes.group.cancel.verify(
                organizationId,
                groupAppointmentId,
              ),
            })(options),
        },
        late: (activityId, selectedSubServiceIds, options) =>
          navigate({
            pathname: routes.followup.late(organizationId, activityId),
            state: { selectedSubServiceIds },
          })(options),
        reschedule: {
          verify: (groupAppointmentId, options) =>
            navigate({
              pathname: routes.group.reschedule.verify(
                organizationId,
                groupAppointmentId,
              ),
            })(options),
          selection: (activityId, options) =>
            navigate({
              pathname: routes.group.reschedule.selection(
                organizationId,
                activityId,
              ),
            })(options),
          location: (activityId, options) =>
            navigate({
              pathname: routes.group.reschedule.location(
                organizationId,
                activityId,
              ),
            })(options),
          schedule: (activityId, options) =>
            navigate({
              pathname: routes.group.reschedule.schedule(
                organizationId,
                activityId,
              ),
            })(options),
          confirmation: (activityId, options) =>
            navigate({
              pathname: routes.group.reschedule.confirmation(
                organizationId,
                activityId,
              ),
            })(options),
          success: (activityId, options) =>
            navigate({
              pathname: routes.group.reschedule.success(
                organizationId,
                activityId,
              ),
            })(options),
        },
      },
      toFollowup: {
        reschedule: {
          verify: (activityId, options) =>
            navigate({
              pathname: routes.followup.reschedule.verify(
                organizationId,
                activityId,
              ),
            })(options),
          location: (activityId, options) =>
            navigate({
              pathname: routes.followup.reschedule.location(
                organizationId,
                activityId,
              ),
            })(options),
          schedule: (activityId, options) =>
            navigate({
              pathname: routes.followup.reschedule.schedule(
                organizationId,
                activityId,
              ),
            })(options),
          screening: (activityId, options) =>
            navigate({
              pathname: routes.followup.reschedule.screening(
                organizationId,
                activityId,
              ),
            })(options),
          patientInfo: (activityId, options) =>
            navigate({
              pathname: routes.followup.reschedule.patientInfo(
                organizationId,
                activityId,
              ),
            })(options),
          paymentInfo: (activityId, options) =>
            navigate({
              pathname: routes.followup.reschedule.paymentInfo(
                organizationId,
                activityId,
              ),
            })(options),
          confirmation: (activityId, options) =>
            navigate({
              pathname: routes.followup.reschedule.confirmation(
                organizationId,
                activityId,
              ),
            })(options),
          success: (activityId, options) =>
            navigate({
              pathname: routes.followup.reschedule.success(
                organizationId,
                activityId,
              ),
            })(options),
        },
        late: (activityId, selectedSubServiceIds, options) =>
          navigate({
            pathname: routes.followup.late(organizationId, activityId),
            state: { selectedSubServiceIds },
          })(options),
        register: {
          verify: (activityId, options) =>
            navigate({
              pathname: routes.followup.register.verify(
                organizationId,
                activityId,
              ),
            })(options),
          screening: (activityId, options) =>
            navigate({
              pathname: routes.followup.register.screening(
                organizationId,
                activityId,
              ),
            })(options),
          patientInfo: (activityId, options) =>
            navigate({
              pathname: routes.followup.register.patientInfo(
                organizationId,
                activityId,
              ),
            })(options),
          paymentInfo: (activityId, options) =>
            navigate({
              pathname: routes.followup.register.paymentInfo(
                organizationId,
                activityId,
              ),
            })(options),
          confirmation: (activityId, options) =>
            navigate({
              pathname: routes.followup.register.confirmation(
                organizationId,
                activityId,
              ),
            })(options),
          success: (activityId, options) =>
            navigate({
              pathname: routes.followup.register.success(
                organizationId,
                activityId,
              ),
            })(options),
        },
      },
      toWaitlist: {
        screening: () =>
          navigate({ pathname: routes.waitlist.screening(organizationId) }),
        success: () =>
          navigate({ pathname: routes.waitlist.success(organizationId) }),
      },
      // NOTE: toURL should be used in conjunction with nav.routes.xyz
      toUrl: (url, options) => navigate({ pathname: url })(options),
      routes: {
        selectService: routes.selectService(organizationId),
        followup: {
          reschedule: {
            verify: (appointmentId) =>
              routes.followup.reschedule.verify(organizationId, appointmentId),
          },
        },
      },
      dme: {
        document: {
          view: (productOrderId, options) =>
            navigate({
              pathname: `${DME_DOCUMENT}/${productOrderId}`,
              search: '',
            })(options),
          pdfView: (productOrderId, options) =>
            navigate({
              pathname: `${DME_DOCUMENT}/${productOrderId}/edit`,
            })(options),
        },

        /**
         *
         * @desc Named arguments as opposed to other navigation, as both deliveryTicketId and options args are optionals in this case
         */
        deliveryVerifyPage: ({
          productOrderId,
          from,
          deliveryTicketId,
          options,
        }) =>
          navigate({
            pathname: deliveryTicketId
              ? `${DME_VERIFY}/${productOrderId}/${deliveryTicketId}`
              : `${DME_VERIFY}/${productOrderId}`,
            search: `?from=${from}`,
          })(options),

        deliveryTicket: {
          view: (productOrderId, deliveryTicketId, contactId, options) =>
            navigate({
              pathname: `${DME_DELIVERY_TICKET}/${productOrderId}/${deliveryTicketId}`,
              search: contactId ? `?contactId=${contactId}` : '',
            })(options),
          edit: (productOrderId, deliveryTicketId, options) =>
            navigate({
              pathname: `${DME_DELIVERY_TICKET}/${productOrderId}/${deliveryTicketId}/edit`,
            })(options),
        },
        deliveryTicketPrint: (productOrderId, deliveryTicketId, options) =>
          navigate({
            pathname: `${DME_DELIVERY_TICKET_PRINT}/${productOrderId}/${deliveryTicketId}`,
          })(options),

        deliveryPacket: {
          view: (productOrderId, contactId, options) =>
            navigate({
              pathname: `${DME_DELIVERY_PACKET}/${productOrderId}`,
              search: contactId ? `?contactId=${contactId}` : '',
            })(options),
          edit: (productOrderId, options) =>
            navigate({
              pathname: `${DME_DELIVERY_PACKET}/${productOrderId}/edit`,
            })(options),
        },
        deliveryPacketPrint: (orderId, options) =>
          navigate({ pathname: `${DME_DELIVERY_PACKET_PRINT}/${orderId}` })(
            options,
          ),
      },
      toTestReport: (appointmentId, options) =>
        navigate({ pathname: routes.testReport(appointmentId) })(options),
      toPrintTestReport: (appointmentId, options) =>
        navigate({ pathname: routes.printTestReport(appointmentId) })(options),
    };
  }, [location, history, organizationId]);
};
