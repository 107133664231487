import { isLeapYear } from 'date-fns';

export const getDaysInMonth = (birthMonth, birthYear, DayList) => {
  const TwentyNineDayMonths = ['2'];
  const ThirtyDayMonths = ['4', '6', '9', '11'];
  if (TwentyNineDayMonths.includes(birthMonth)) {
    if (!isLeapYear(birthYear)) {
      return DayList.slice(0, 28);
    }
    return DayList.slice(0, 29);
  } else if (ThirtyDayMonths.includes(birthMonth)) {
    return DayList.slice(0, 30);
  }
  return DayList;
};
