import { isAboveMaximumAge, isUnderMinimumAge } from './DateAndTime';
import { flow } from 'lodash';
import { regimenViewType } from 'constants/healthcareServices';

/**
 * @typedef {import('types').SubService} SubService
 * @typedef {{ birthDay: string, birthMonth: string, birthYear: string }} PatientInfoBirthDate
 *
 * @typedef {(s: SubService[]) => SubService[]} SubServiceFilter
 */

/**
 * @param {PatientInfoBirthDate} patientInfo
 * @returns {SubServiceFilter}
 */
export const filterSubservicesByAge =
  (patientInfo) => (availableSubServices) => {
    const selectedDate = `${patientInfo.birthMonth}-${patientInfo.birthDay}-${patientInfo.birthYear}`;
    return availableSubServices.filter(
      ({ minAgeRequirement, maxAgeRequirement }) =>
        !isUnderMinimumAge(selectedDate, minAgeRequirement) &&
        !isAboveMaximumAge(selectedDate, maxAgeRequirement),
    );
  };

/**
 * defaultStatus === true -> 'I am not sure'
 * Return subservice regardless the defaultStatus if it the only subservice for chosen service
 *
 * @type {SubServiceFilter}
 */
export const filterByDefaultStatus = (subServices) =>
  subServices.length === 1
    ? subServices
    : subServices.filter(({ defaultStatus }) => !defaultStatus);

/**
 * @param {string[]} excludeServiceIds
 * @return {SubServiceFilter} */
export const excludeByServiceIds = (excludeServiceIds) => (subServices) =>
  subServices.filter(({ serviceId }) => !excludeServiceIds.includes(serviceId));

/** @type {SubServiceFilter} */
export const excludeDoubleDose = (subServices) =>
  subServices.filter(
    ({ serviceViewType }) =>
      serviceViewType !== regimenViewType.DOUBLE_DOSE_VIEW,
  );

/** @type {SubServiceFilter} */
export const passThrough = (subServices) => subServices;

/**
 *
 * @param {PatientInfoBirthDate} patientInfo
 * @param {SubService[]} availableSubServices
 * @returns {SubService[]}
 */
export const filterEligibleSubservices = (patientInfo, availableSubServices) =>
  filterByDefaultStatus(
    filterSubservicesByAge(patientInfo)(availableSubServices),
  );

/**
 * @type {(...filters: SubServiceFilter[]) => SubServiceFilter}
 */
export const composeFilters = flow;
