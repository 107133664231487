import styles from './NoServiceNotice.module.scss';

import MessageListBox from 'components/COVID/common/MessageListBox';
import { getString } from 'util/lang';

export const NoServicesNotice = ({ email, phone }) => {
  const messages = [
    { title: 'Phone number', message: phone },
    { title: 'Email address', message: email },
  ];
  return (
    <>
      <div className={styles.subtext}>{getString('noServicesAvailable')}</div>
      <div className={styles.contactInfoTitle}>
        {getString('clinicContactInformation')}:
      </div>
      <MessageListBox className={styles.messageListBox} messages={messages} />
    </>
  );
};
