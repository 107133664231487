export const dashMobileFormat = (number) => {
  var strPhone = number;

  if (strPhone != null && strPhone.length > 0 && strPhone.indexOf('-') === -1) {
    if (strPhone.length === 10) {
      strPhone =
        strPhone.substr(0, 3) +
        '-' +
        strPhone.substr(3, 3) +
        '-' +
        strPhone.substr(6, 4);
    } else if (strPhone.length > 10) {
      strPhone =
        strPhone.substr(0, 3) +
        '-' +
        strPhone.substr(3, 3) +
        '-' +
        strPhone.substr(6, 4);
    }
    number = strPhone;
  }
  return number;
};

export const dashCodeFormat = (codeStr) => {
  if (codeStr !== null && codeStr.length > 0 && codeStr.indexOf('-') === -1) {
    if (codeStr.length >= 12) {
      return (
        codeStr.substr(0, 4) +
        '-' +
        codeStr.substr(4, 4) +
        '-' +
        codeStr.substr(8, 12)
      );
    }
    return codeStr;
  }
  return codeStr;
};

/**
 *
 * Limit the characters in a given string to given number of characters
 * @param {*} str : string/word/letters
 * @param {*} limit : number of characters to limit
 */
export const limitChars = (str, limit) => {
  return str.slice(0, limit);
};

export const getFormattedFullName = ({ firstName, middleName, lastName }) =>
  [firstName, middleName, lastName]
    .filter(Boolean)
    .map((nameString) => nameString.trim())
    .join(' ');
