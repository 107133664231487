import React from 'react';

import MessageListBox from 'components/COVID/common/MessageListBox';
import WarningIcon from '../../../svg-images/WarningIcon';
import styles from './NoAppointmentInformation.module.scss';
import Button from '../../common/Button';
import cls from 'classnames';

const NoAppointmentInformation = (props) => {
  return (
    <div className={cls('section__nosites', styles.noAppointment)}>
      <div className="section__nosites__info">
        {props.sectionIcon || <WarningIcon />}
        <h2>{props.informationSectionTitle || 'Section Title'}</h2>
      </div>
      <p className="text-center label">{props.informationSectionDescription}</p>
      <div className="section-title mt-6x mb-3x">
        {props.extraDetailSectionTitle}:
      </div>
      <MessageListBox messages={props.messages} />
      <Button
        classNames={{ button: styles.button }}
        label={props.btnTitle || 'Button title'}
        outline
        isEnabled
        onClick={props.onClick}
        dataqa="goto-editPage"
      />
    </div>
  );
};

export { NoAppointmentInformation };
