import { FiInfo } from 'vyaguta-icons/fi';
import DmeHeader from '../DmeHeader/DmeHeader';

import { getString } from 'util/lang';
import MessageListBox from 'components/COVID/common/MessageListBox';

export const NoticeMessage = ({
  title,
  message,
  contactEmail,
  contactPhone,
}) => {
  const messages = [
    { title: getString('phoneNumber'), message: contactPhone },
    { title: getString('emailAddress'), message: contactEmail },
  ];
  return (
    <div className="selected-details">
      <DmeHeader isEdit={true} headerText={title} />
      <div className="container border my-4x">
        <div className="success-content border-bottom">
          <FiInfo size={24} className="success-check info" />
          {message && (
            <p tabIndex="0" className="mb-0x">
              {message}
            </p>
          )}
        </div>
        <div className="success-content">
          <h3 className="clinicContactInfo">
            {getString('contactInformation')}
          </h3>
          <MessageListBox messages={messages} className="messageBox mb-0x" />
        </div>
      </div>
    </div>
  );
};
