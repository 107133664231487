import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';
import { getTrailingCommaValue } from 'util/string';
import { useStyles } from './ElectronicSignaturePdf.styles';
import { contactMasking, zipCodeMasking } from 'util/contactFormat';
import {
  printFontBoldCDN,
  printFontFamily,
  printFontRegularCDN,
} from 'constants/dme';

const ElectronicSignaturePdfView = ({ signedDocumentDetail, appData = {} }) => {
  const styles = useStyles;
  Font.register({
    family: printFontFamily,
    fonts: [
      {
        src: printFontRegularCDN,
        fontWeight: 400,
      },
      {
        src: printFontBoldCDN,
        fontWeight: 700,
      },
    ],
  });

  const {
    organizationContactPhone,
    organizationName,
    billingAddress,
    billingCity,
    billingZipCode,
    billingState,
    organizationContactEmail,
  } = appData;

  const formattedOrganizationContact = contactMasking(organizationContactPhone);
  const formattedBillingZipCode = zipCodeMasking(billingZipCode);

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>
          {getString('dme.documentation.electronicSignatuteAcceptance.title')}
        </Text>
        <View>
          <Text style={styles.text}>
            {getString(
              `dme.documentation.electronicSignatuteAcceptance.noticeToRecipient.title`,
            )}
          </Text>
          <Text style={styles.subText}>
            {getString(
              `dme.documentation.electronicSignatuteAcceptance.noticeToRecipient.content`,
            )}
          </Text>
        </View>

        {getString('dme.documentation.electronicSignatuteAcceptance.terms').map(
          (section, index) => (
            <View key={index} style={styles.textWrap}>
              <Text style={styles.text}>
                {getString(
                  `dme.documentation.electronicSignatuteAcceptance.terms.${index}.title`,
                )}
              </Text>
              <Text style={styles.subText}>
                {getString(
                  `dme.documentation.electronicSignatuteAcceptance.terms.${index}.content`,
                  { email: organizationContactEmail },
                )}
              </Text>
            </View>
          ),
        )}

        <View style={styles.acceptanceCriteriaWrap}>
          <Text style={styles.text}>
            {getString(
              'dme.documentation.electronicSignatuteAcceptance.acceptanceCriteria.title',
            )}
          </Text>
          <Text style={styles.subText}>
            {getString(
              'dme.documentation.electronicSignatuteAcceptance.acceptanceCriteria.content',
            )}
          </Text>
          <Text style={styles.inputField}>
            {signedDocumentDetail.eSignature}
          </Text>
        </View>
        <View style={styles.footerWrap}>
          <Text style={styles.footerTextBold}>
            {getTrailingCommaValue(organizationName)}
          </Text>
          <Text style={styles.footerText}>
            {getTrailingCommaValue(billingAddress)}
            {getTrailingCommaValue(billingCity)}
            {getTrailingCommaValue(billingState)}
            {getTrailingCommaValue(formattedBillingZipCode)}
            {formattedOrganizationContact}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ElectronicSignaturePdfView;
