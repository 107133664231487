import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';

import FormHeaderPdfView from '../../common/FormHeader/FormHeaderPdfView';
import PharmacyInfoPdfView from '../../common/PharmaInfo/PharmaInfoPdfView';
import LineBreakPdfView from '../../common/LineBreak/LineBreakPdfView';

const LABEL_PREFIX = 'dme.deliveryPacketForm.noticeOfPrivacyPractices';

const additionalUsesOfInformationData = getString(
  `${LABEL_PREFIX}.additionalUsesOfInformation.data`,
);
const individualRightsData = getString(`${LABEL_PREFIX}.individualRights.data`);
const companyDutiesDescriptions = getString(
  `${LABEL_PREFIX}.companyDuties.descriptions`,
);

const NoticeOfPrivacyPracticesPdfView = ({
  organizationDetail,
  effectiveDate,
}) => {
  const { organizationName, organizationContactPhone, address } =
    organizationDetail;
  const usesAndDisclosuresData = JSON.parse(
    getString(`${LABEL_PREFIX}.usesAndDisclosures.data`, {
      companyName: organizationName,
    }),
  );

  return (
    <View style={styles.container}>
      <View style={{ marginTop: 12 }} />
      <FormHeaderPdfView text={getString(`${LABEL_PREFIX}.title`)} />
      <PharmacyInfoPdfView
        name={organizationName}
        address={address}
        contact={organizationContactPhone}
      />

      <Text style={{ ...styles.headerThree, marginTop: 15 }}>
        {getString(`${LABEL_PREFIX}.description`)}
      </Text>

      <LineBreakPdfView
        titleText={getString(`${LABEL_PREFIX}.usesAndDisclosures.title`)}
      />

      <View style={styles.listWrap}>
        {usesAndDisclosuresData.map(({ title, description }) => (
          <View key={title} style={styles.list}>
            <Text style={styles.headerThree}>{title}</Text>
            <Text style={{ ...styles.text, marginTop: 2 }}>{description}</Text>
          </View>
        ))}
      </View>

      <LineBreakPdfView
        titleText={getString(
          `${LABEL_PREFIX}.additionalUsesOfInformation.title`,
        )}
      />

      <View style={styles.listWrap}>
        {additionalUsesOfInformationData.map(({ title, description }) => (
          <View key={title} style={styles.list}>
            <Text style={styles.headerThree}>{title}</Text>
            <Text style={{ ...styles.text, marginTop: 2 }}>{description}</Text>
          </View>
        ))}
      </View>

      <View style={styles.individualRightWrap}>
        <LineBreakPdfView
          titleText={getString(`${LABEL_PREFIX}.individualRights.title`)}
        />
        <Text style={{ ...styles.text, marginBottom: 8 }}>
          {getString(`${LABEL_PREFIX}.individualRights.description`)}
        </Text>
        <View style={styles.bulletList}>
          {individualRightsData.map((individualRight, index) => (
            <View key={index}>
              <Text style={styles.text}>• {individualRight}</Text>
            </View>
          ))}
        </View>
      </View>

      <LineBreakPdfView />

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, marginBottom: 4 }}>
          {getString(`${LABEL_PREFIX}.companyDuties.title`, {
            companyName: organizationName,
          })}
        </Text>
        {companyDutiesDescriptions.map((description, index) => (
          <Text style={styles.text} key={index}>
            {description}
          </Text>
        ))}
      </View>

      <View style={styles.sectionWrap}>
        <Text
          style={{
            ...styles.headerThree,
            marginBottom: 4,
            textTransform: 'uppercase',
          }}
        >
          {getString(`${LABEL_PREFIX}.rightToRevisePrivacyPractices.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(
            `${LABEL_PREFIX}.rightToRevisePrivacyPractices.description`,
          )}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text
          style={{
            ...styles.headerThree,
            marginBottom: 4,
            textTransform: 'uppercase',
          }}
        >
          {getString(
            `${LABEL_PREFIX}.requestsToInspectProtectedHealthInformation.title`,
          )}
        </Text>
        <Text style={styles.text}>
          {getString(
            `${LABEL_PREFIX}.requestsToInspectProtectedHealthInformation.description`,
          )}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text
          style={{
            ...styles.headerThree,
            marginBottom: 4,
            textTransform: 'uppercase',
          }}
        >
          {getString(`${LABEL_PREFIX}.compliants.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.compliants.description`)}
        </Text>
      </View>

      <View style={styles.privacyOfficer}>
        <Text style={{ ...styles.headerThree, marginBottom: 4 }}>
          {getString(`${LABEL_PREFIX}.compliants.contactDesignation`)}
        </Text>
        <PharmacyInfoPdfView
          name={organizationName}
          address={address}
          contact={organizationContactPhone}
        />
      </View>
      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, marginBottom: 4 }}>
          {getString(`${LABEL_PREFIX}.effectiveDate.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.effectiveDate.descriptionForPdf`, {
            date: effectiveDate,
          })}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionWrap: {
    marginTop: 10,
  },
  privacyOfficer: {
    marginLeft: 4,
    marginTop: 4,
  },
  listWrap: {
    marginTop: -8,
  },
  list: {
    marginTop: 8,
  },
  individualRightWrap: {},
  bulletList: {
    marginLeft: 10,
  },
  headerThree: {
    fontSize: 9,
    fontWeight: 700,
  },
  text: {
    fontSize: 9,
    fontWeight: 400,
    color: '#323130',
    marginBottom: 2,
  },
});

export default NoticeOfPrivacyPracticesPdfView;
