import { useCallback, useEffect, useMemo } from 'react';

import {
  useApplicationContext,
  useDetailContext,
  useLocationContext,
} from 'context';
import { useAppParams } from 'context/useAppParams';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { usePatientInfo } from '../../PatientInformation/hooks/usePatientInfo';

import { PageWrapper } from 'components/COVID/common/PageWrapper';
import {
  makeFollowupRescheduleNavLinks,
  makeFollowupRescheduleNavLinksWithNoPaymentOption,
} from 'components/common/NavBar/navLinks';
import PatientInformation from '../../PatientInformation/container/PatientInformation';
import { changeTitle } from 'util/siteInfo';

export const ReschedulePatientInfo = () => {
  changeTitle('Patient Information');
  const {
    details: { pageComplete },
    getContactByAppointmentId,
  } = useDetailContext();
  const { isPaymentOptionEnabled } = useApplicationContext();

  const { PatientInfoProps } = usePatientInfo();

  const { appointmentId, organizationId } = useAppParams();
  const nav = useNavigationHelper();

  useEffect(() => {
    getContactByAppointmentId(appointmentId);
  }, [getContactByAppointmentId, appointmentId]);

  const toNextPage = useCallback(() => {
    isPaymentOptionEnabled
      ? nav.toFollowup.reschedule.paymentInfo(appointmentId)
      : nav.toFollowup.reschedule.confirmation(appointmentId);
  }, [nav, appointmentId]);

  const navLinks = useMemo(
    () =>
      isPaymentOptionEnabled
        ? makeFollowupRescheduleNavLinks(
            pageComplete,
            appointmentId,
            organizationId,
          )
        : makeFollowupRescheduleNavLinksWithNoPaymentOption(
            pageComplete,
            appointmentId,
            organizationId,
          ),
    [organizationId, pageComplete, appointmentId],
  );

  const goBack = () => nav.goBack();

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <PatientInformation
        {...PatientInfoProps}
        confirmEditService={false}
        organizationId={organizationId}
        goBack={goBack}
        toNextPage={toNextPage}
        disableEdit={true}
        isFollowup={true}
        disabledFieldStatus={{
          firstName: true,
          middleName: true,
          lastName: true,
          birthDay: true,
          birthMonth: true,
          birthYear: true,
        }}
      />
    </PageWrapper>
  );
};
