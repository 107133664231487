import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { FiCheck } from 'vyaguta-icons/fi';
import CONFIG from 'config';
import cls from 'classnames';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { useSecurityToken } from 'context/useSecurityToken';
import { useResetAppState } from 'context/useResetAppState';
import { useSelectedServiceNames } from 'context/useSelectedServiceNames';

import Button from 'components/common/Button';
import { GroupOption } from './component/GroupOption';
import { ConfirmModal } from 'components/common/ConfirmModal';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import ActivityIndicator from 'components/common/ActivityIndicator';
import { useAppointmentCancel } from 'context/useAppointmentCancel';
import styles from './GroupCancelTypeSelection.module.scss';
import { getString } from 'util/lang';

const OPTION = {
  individual: 'individual',
  group: 'group',
};

const COMPONENT_STATE_KEY = {
  showModal: 'showModal',
  isLoading: 'isLoading',
  cancelComplete: 'cancelComplete',
  cancelOption: 'cancelOption',
};

export const GroupCancelTypeSelection = () => {
  const [componentState, setComponentState] = useState({
    showModal: false,
    isLoading: false,
    cancelComplete: false,
    cancelOption: OPTION.individual,
  });

  const updateComponentState = (key, value) => {
    if (Object.keys(COMPONENT_STATE_KEY).includes(key)) {
      setComponentState((prev) => ({ ...prev, [key]: value }));
    }
  };

  const nav = useNavigationHelper();
  const resetAppState = useResetAppState();
  const { securityToken } = useSecurityToken();
  const serviceNames = useSelectedServiceNames();
  const {
    state: { groupAppointmentId },
  } = useLocation();
  const { cancelGroupAppointment } = useAppointmentCancel();

  const handleRadioChange = () => {
    updateComponentState(
      COMPONENT_STATE_KEY.cancelOption,
      componentState.cancelOption === OPTION.group
        ? OPTION.individual
        : OPTION.group,
    );
  };

  const handleSubmitButton = async (captchaResponseKey) => {
    updateComponentState(COMPONENT_STATE_KEY.showModal, false);
    updateComponentState(COMPONENT_STATE_KEY.isLoading, true);
    await cancelGroupAppointment(
      groupAppointmentId,
      captchaResponseKey,
      componentState.cancelOption === OPTION.group,
      securityToken,
    );
    updateComponentState(COMPONENT_STATE_KEY.cancelComplete, true);
    updateComponentState(COMPONENT_STATE_KEY.isLoading, false);
  };

  const onBookAnotherAppointment = useCallback(() => {
    resetAppState();
    nav.resetToFirstPage();
  }, [nav, resetAppState]);

  if (componentState.isLoading) {
    return <ActivityIndicator className="loader--clinic" />;
  }

  return (
    <PageWrapper>
      {componentState.cancelComplete ? (
        <CancelSuccess
          serviceNames={<>{serviceNames}</>}
          onBookAnotherAppointment={onBookAnotherAppointment}
          groupCancel={componentState.cancelOption === OPTION.group}
        />
      ) : (
        <div className={cls('d-flex flex-column', styles.cancelOptionWrapper)}>
          <GroupOption
            header={getString('cancelAppointment')}
            buttonText={getString('cancelAppointment')}
            alertMessage={getString('cancelModalAlert')}
            handleRadioChange={handleRadioChange}
            option={componentState.cancelOption}
            isEnabled={true}
            onClick={() => {
              updateComponentState(COMPONENT_STATE_KEY.showModal, true);
            }}
          />

          <ConfirmModal
            show={componentState.showModal}
            onCancel={() => {
              updateComponentState(COMPONENT_STATE_KEY.showModal, false);
            }}
            onConfirm={handleSubmitButton}
            headerContent={
              <div className={styles.modalHeader}>
                {componentState.cancelOption === OPTION.individual
                  ? getString('individualCancelModalHeader')
                  : getString('groupCancelModalHeader')}
              </div>
            }
            bodyContent={
              <div className={cls(styles.modalContent)}>
                <div>
                  {componentState.cancelOption === OPTION.individual
                    ? getString('individualCancelModalContent')
                    : getString('groupCancelModalContent')}
                </div>
              </div>
            }
            cancelText={getString('no')}
            confirmText={getString('yes')}
            captchaEnabled={CONFIG.captchaEnabled}
          />
        </div>
      )}
    </PageWrapper>
  );
};

const CancelSuccess = ({
  serviceNames,
  onBookAnotherAppointment,
  groupCancel,
}) => {
  return (
    <div className="success">
      <section className="selected-details section__margin">
        <div className="border border-radius section__container p-0x">
          <FiCheck
            size={24}
            className="success-check success-check--covid mt-9x"
          />
          <div className="success-content border-bottom">
            <p tabIndex="0" className="mb-0x-md">
              {groupCancel
                ? getString('cancelGroupAppointmentMessage', {
                    serviceName: <>{serviceNames}</>,
                  })
                : getString('cancelIndividualAppointmentMessage', {
                    serviceName: <>{serviceNames}</>,
                  })}
            </p>
            <div className="success-action-covid">
              <Button
                title={getString('bookAppointmentTitle')}
                isEnabled
                onClick={onBookAnotherAppointment}
                className="btn btn--large btn-primary--outline"
                isActive
                label={getString('bookAppointment')}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
