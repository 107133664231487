import { FiChevronRight } from 'vyaguta-icons/fi';

import { useAppSettings } from 'context';
import Button from 'components/common/Button';
import FormInput from 'components/common/FormInput';
import { componentKeys } from '../../../container/DmeDocument';

import { getString } from 'util/lang';
import { customPick } from 'util/objectUtils';
import { validateFormData } from 'util/formValidator';
import { ESignatureSchema } from 'validator/productOrder';

export const ElectronicSignature = ({
  goNext,
  nextLabel,
  isLastDocument,
  handleSubmit,
  organizationEmail,
  signedDocumentDetail,
  onChange,
  errors,
  setErrors,
}) => {
  const { logoUrl } = useAppSettings();

  const handleSubmission = () => {
    const { errors, isValid } = validateFormData(
      customPick(signedDocumentDetail, ['eSignature']),
      ESignatureSchema,
    );
    if (!isValid) {
      setErrors(errors);
      return;
    }
    if (isLastDocument) {
      handleSubmit();
    } else {
      goNext();
    }
    setErrors({});
  };

  return (
    <div className="wrapper">
      <div className="header">
        <div className="logo">
          <img src={logoUrl} alt="pharmacy_logo" />
        </div>
      </div>
      <div className="container-large">
        <h3 className="heading">
          {getString('dme.documentation.electronicSignatuteAcceptance.title')}
        </h3>
        <p className="color-black-text text-bold mb-1x">
          {getString(
            'dme.documentation.electronicSignatuteAcceptance.noticeToRecipient.title',
          )}
        </p>
        <p className="desc">
          {getString(
            'dme.documentation.electronicSignatuteAcceptance.noticeToRecipient.content',
          )}
        </p>
        <ul className="list list--numbered mb-6x">
          {getString(
            'dme.documentation.electronicSignatuteAcceptance.terms',
          )?.map((item, index) => {
            return (
              <li key={index}>
                <p className="title mb-1x">
                  {getString(
                    `dme.documentation.electronicSignatuteAcceptance.terms.${index}.title`,
                  )}
                </p>
                <p className="desc pl-4x mb-0x">
                  {getString(
                    `dme.documentation.electronicSignatuteAcceptance.terms.${index}.content`,
                    { email: organizationEmail },
                  )}
                </p>
              </li>
            );
          })}
        </ul>
        <p className="color-black-text text-bold mb-1x">
          {getString(
            'dme.documentation.electronicSignatuteAcceptance.acceptanceCriteria.title',
          )}
        </p>
        <p className="desc mb-4x">
          {getString(
            'dme.documentation.electronicSignatuteAcceptance.acceptanceCriteria.content',
          )}
        </p>
        <div className="row">
          <div className="col-6">
            <FormInput
              id="eSignature"
              labelClassName="label-placeholder"
              label="Patient Full Name"
              solo
              required
              value={signedDocumentDetail.eSignature}
              onChange={onChange}
              hasError={!!errors['eSignature']}
            />
          </div>
        </div>
        <div className="pagination">
          <div className="text-right flex-grow">
            {isLastDocument ? (
              <Button
                label={'Submit'}
                className="btn btn-primary btn--fixedWidth"
                onClick={handleSubmission}
                isEnabled
              />
            ) : (
              <>
                <p className="d-flex justify-content-end align-items-center" onClick={handleSubmission}>
                  <span className="color-grey-dark cursor-pointer mr-1x">
                    {getString('dme.documentation.pagination.next')}
                  </span>
                  <FiChevronRight size={12} color="#757575" />
                </p>
                <Button
                  className="btn btn--text link-pagination"
                  label={
                    nextLabel === componentKeys.termAndCondition
                      ? getString(
                        'dme.documentation.pagination.termsAndConditions',
                      )
                      : nextLabel
                  }
                  onClick={handleSubmission}
                  isEnabled
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
