import cls from 'classnames';

import styles from './GroupOption.module.scss';
import Button from 'components/common/Button';
import AlertMessage from 'components/COVID/common/AlertMessage';

export const GROUP_RESCHEDULE_CANCEL_SELECTION_OPTIONS = [
  {
    name: 'individual',
    title: 'For Myself',
  },
  {
    name: 'group',
    title: 'For Everyone in the group',
  },
];

export const GroupOption = (props) => {
  const {
    buttonText,
    alertMessage,
    handleRadioChange,
    option,
    onClick,
    isEnabled,
    header,
  } = props;

  return (
    <>
      <div className={styles.headerFont}>{header}</div>
      <div className={styles.content}>
        {GROUP_RESCHEDULE_CANCEL_SELECTION_OPTIONS.map((item, key) => {
          return (
            <div className={cls('custom-radio', styles.inputLabel)} key={key}>
              <input
                id={item.name}
                name={item.name}
                type="radio"
                className="custom-radio-input"
                onChange={handleRadioChange}
                checked={item.name === option}
                autoComplete="off"
              />
              <label htmlFor={item.name}>
                <div>
                  <div className="sub-title">{item.title}</div>
                </div>
              </label>
            </div>
          );
        })}

        <AlertMessage type="info" isVisible={true} message={alertMessage} />
      </div>
      <Button
        classNames={{ button: 'mt-10x' }}
        onClick={onClick}
        isEnabled={isEnabled}
        label={buttonText}
      />
    </>
  );
};
