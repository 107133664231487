import CONFIG from '../config';
import * as http from '../util/http';
import { InsuranceTypeMap } from './insuranceOrganization';


export async function fetchPayerList(insuranceTypeCode) {
  const insuranceType = InsuranceTypeMap[insuranceTypeCode];

  if (insuranceTypeCode && !insuranceType) {
    console.error('invalid insurance type', insuranceTypeCode);
  }

  const { data } = await http.get(`${CONFIG.entity.getPayerList()}${insuranceType ? `?insuranceType=${insuranceType}` : ''}`);

  return data.data;
}
