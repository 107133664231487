const resizeImage = (
  img,
  canvasWidth,
  canvasHeight,
  imageWidth,
  imageHeight,
  scale,
) => {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  var image = new Image();
  image.src = img;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  const scaleWidth = Math.floor(imageWidth / scale);
  const scaleHeight = Math.floor(imageHeight / scale);

  const xOffset = Math.floor((canvasWidth - scaleWidth) / 4);
  const yOffset = Math.floor((canvasHeight - scaleHeight) / 2);

  return new Promise((resolve, reject) => {
    try {
      image.onload = () => {
        ctx.drawImage(image, xOffset, yOffset, scaleWidth, scaleHeight);
        const resized = canvas.toDataURL('image/png', 0.1);
        resolve(resized);
      };
    } catch {
      reject('Could not resize image');
    }
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  resizeImage,
};
