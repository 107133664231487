import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  useAppSettings,
  useDetailContext,
  useGroupAppointmentContext,
  useSelectionsContext,
  useApplicationContext,
} from 'context';
import { useAppParams } from 'context/useAppParams';
import { CovidPageNames } from 'constants/pages';
import { toStandardDateFormat } from 'util/DateAndTime';
import { handleError } from 'util/errorHandler';
import { getLanguageIdByLanguageCode, getString } from 'util/lang';
import scrollUtils from 'util/scroll';
import * as clinicDayService from 'services/clinicDay';
import ActivityIndicator from 'components/common/ActivityIndicator';
import Button from 'components/common/Button';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import DateAndTimeSelection from './container/DateAndTimeSelection';
import { ServicesInfoContainer } from '../../common/ServicesInfo';
import { useNavigationHelper } from '../../../../hooks/useNavigationHelper';
import { PageWrapper } from 'components/COVID/common/PageWrapper';
import {
  makeDefaultNavLinks,
  makeGroupAppointmentNavLinks,
  makeRescheduleNavLinks,
  makeDefaultNavLinksWithNoPaymentOption,
} from 'components/common/NavBar/navLinks';
import RescheduleInvalid from '../RescheduleValidity/RescheduleInvalid';
import { changeTitle } from 'util/siteInfo';

export const useClinicAndTiming = ({
  enabled = true,
  firstDoseSlotRef,
} = {}) => {
  const { organizationId } = useAppParams();

  const {
    details: {
      appointments,
      dateTimeLoading,
      isServiceListFetching,
      appLanguageCode,
    },
    setDetails,
    fetchClinicDayForClinic,
    setPageComplete,
    fetchServices,
  } = useDetailContext();

  const {
    selectedClinic: selectedLocation,
    selectedServices,
    selectedSubServiceIds,
    isCounsellingSelected,
    primarySubServiceId,
    selectedSubServices,
  } = useSelectionsContext();

  const { languages } = useAppSettings();

  const [_selectedDate, setSelectedDate] = useState(() => {
    const date = appointments?.[0]?.firstAppointment?.date;
    return date ? toStandardDateFormat(date) : null;
  });
  const [_selectedBookingBlock, setSelectedBookingBlock] = useState(() => {
    const appointment = appointments?.[0]?.firstAppointment;
    if (appointment?.bookingBlock) {
      return appointment?.bookingBlock;
    }

    return appointment?.time
      ? { start_time: toStandardDateFormat(appointment.time) }
      : null;
  });
  const [availableDates, setAvailableDates] = useState([]);
  const [bookingBlocks, setBookingBlocks] = useState([]);
  const [isFetchingBlocks, setIsFetchingBlocks] = useState(false);
  const [isClinicDaysEmpty, setIsClinicDaysEmpty] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);

  const selectedBookingBlock = useMemo(() => {
    const availableBookingBlocks = bookingBlocks.filter(
      (bookingBlock) => bookingBlock.available_capacity > 0,
    );
    return (
      // find block where both start_time and end_time match
      availableBookingBlocks.find(
        (bookingBlock) =>
          bookingBlock.start_time === _selectedBookingBlock?.start_time &&
          bookingBlock.end_time === _selectedBookingBlock?.end_time,
      ) ??
      // or where atleast start_time matches (for edit)
      availableBookingBlocks.find(
        (bookingBlock) =>
          bookingBlock.start_date === _selectedBookingBlock?.start_date,
      ) ??
      // or select first item (unselected)
      availableBookingBlocks.find(
        (bookingBlock) => bookingBlock.available_capacity > 0,
      )
    );
  }, [bookingBlocks, _selectedBookingBlock]);

  const selectedDate = useMemo(() => {
    return availableDates.includes(_selectedDate)
      ? _selectedDate
      : availableDates[0];
  }, [availableDates, _selectedDate]);

  const subServiceIds = useMemo(
    () =>
      isCounsellingSelected ? [primarySubServiceId] : selectedSubServiceIds,
    [isCounsellingSelected, primarySubServiceId, selectedSubServiceIds],
  );

  const fetchClinicDays = useCallback(async () => {
    try {
      const clinicDays = await fetchClinicDayForClinic(
        selectedLocation?.id,
        false,
        subServiceIds,
        selectedLocation.timeGap,
      );

      const availableDates = clinicDays.map(({ clinicDate }) =>
        toStandardDateFormat(clinicDate),
      );

      setAvailableDates(availableDates);

      setIsClinicDaysEmpty(!clinicDays || !clinicDays.length);
    } catch (error) {
      handleError(error);
    }
  }, [fetchClinicDayForClinic, subServiceIds, selectedLocation]);

  useEffect(() => {
    enabled &&
      fetchClinicDays().finally(() => {
        setIsInitialised(true);
      });
  }, [enabled, fetchClinicDays]);

  const fetchAvailableBlocks = useCallback(async () => {
    if (!selectedDate || !selectedLocation?.id || subServiceIds?.length <= 0) {
      return;
    }

    setIsFetchingBlocks(true);
    try {
      const bookingTimeBlocks = await clinicDayService.getAvailableSlots({
        subServiceIds,
        clinicId: selectedLocation?.id,
        date: toStandardDateFormat(selectedDate),
        slotsCount: 1,
        clinicTimeGap: selectedLocation?.timeGap,
      });

      setBookingBlocks(bookingTimeBlocks || []);
    } catch (error) {
      setBookingBlocks([]);
      handleError(error);
    } finally {
      setIsFetchingBlocks(false);
    }
  }, [subServiceIds, selectedLocation, selectedDate]);

  //fetch available slots from given selected date.
  useEffect(() => {
    fetchAvailableBlocks();
  }, [fetchAvailableBlocks]);

  const languageId = getLanguageIdByLanguageCode(appLanguageCode, languages);

  // force re-fetch all data
  const refreshServiceList = useCallback(async () => {
    try {
      await fetchServices(languageId, organizationId);
      await fetchClinicDays();
      await fetchAvailableBlocks();
    } catch (error) {
      handleError(error);
    }
  }, [
    fetchServices,
    fetchClinicDays,
    fetchAvailableBlocks,
    languageId,
    organizationId,
  ]);

  /**
   *
   * @param {Date} date
   * @param {boolean} preventScroll
   * @returns
   */
  const onSelectFirstDoseDate = (date, preventScroll) => {
    if (!date) return;
    setSelectedDate(toStandardDateFormat(date));
    setSelectedBookingBlock(null);

    if (!preventScroll) scrollUtils.windowScrollTo(firstDoseSlotRef, -130);
  };

  const onSelectFirstDoseTime = useCallback((bookingBlock) => {
    if (!bookingBlock) return;
    setSelectedBookingBlock(bookingBlock);
  }, []);

  const isAppointmentValid = () => {
    if (selectedServices.length <= 0) return false;

    if (selectedDate && selectedBookingBlock) {
      return true;
    }

    return false;
  };

  const saveAppointment = () => {
    setDetails(({ appointments }) => {
      const allAppointments = selectedSubServices.map((subService, idx) => {
        const appointment = appointments[idx];
        return {
          firstAppointment: {
            ...appointment?.firstAppointment,

            serviceCode: subService.id,
            venueCode: selectedLocation.id,
            bookingBlock: selectedBookingBlock,
            date: selectedDate,
            time: selectedBookingBlock.start_time,
          },
          secondAppointment: appointment?.secondAppointment ?? null,
        };
      });

      return { appointments: allAppointments };
    });

    setPageComplete({
      [CovidPageNames.SelectClinicAndTiming]: true,
    });
  };

  return {
    isLoading: isServiceListFetching,
    isAppointmentValid,
    saveAppointment,
    shouldShow: selectedSubServiceIds.length > 0,
    isClinicDaysEmpty,
    DateAndTimeSelectionProps: {
      isFetchingClinicDays: dateTimeLoading || !isInitialised,
      isFetchingBlocks,
      slotRef: firstDoseSlotRef,
      availableDates,
      bookingBlocks,
      selectedDate,
      selectedBookingBlock,
      onDateSelect: onSelectFirstDoseDate,
      onTimeSelect: onSelectFirstDoseTime,
      refreshSlot: refreshServiceList,
    },
  };
};

export const ClinicAndTiming = ({
  disableEdit = false,
  disableBack = false,
  toNextPage,
  toNextPageLabel,
  setIsValidReschedule = () => {},
}) => {
  const nav = useNavigationHelper();

  const submitButtonRef = useRef(null);
  const firstDoseSlotRef = useRef(null);
  const secondDoseCalendarRef = useRef(null);
  const secondDoseSlotRef = useRef(null);

  const {
    saveAppointment,
    DateAndTimeSelectionProps,
    shouldShow,
    isLoading,
    isAppointmentValid,
  } = useClinicAndTiming({
    submitButtonRef,
    firstDoseSlotRef,
    secondDoseCalendarRef,
    secondDoseSlotRef,
  });

  const { availableDates, isFetchingClinicDays } = DateAndTimeSelectionProps;

  useEffect(() => {
    setIsValidReschedule(isFetchingClinicDays || availableDates.length > 0);
  }, [setIsValidReschedule, availableDates, isFetchingClinicDays]);

  const onSubmit = () => {
    saveAppointment();

    toNextPage();
  };

  return (
    <>
      {!disableBack && (
        <BackToPage
          text={getString('backToPreviousPage')}
          onClick={nav.goBack}
        />
      )}

      <ServicesInfoContainer disableEdit={disableEdit} />

      {isLoading ? (
        <section className="schedule-appointment section__container section__margin border border-radius p-20x">
          <ActivityIndicator />
        </section>
      ) : (
        <>
          {shouldShow && (
            <DateAndTimeSelection {...DateAndTimeSelectionProps} />
          )}

          <div ref={submitButtonRef}>
            <Button
              label={toNextPageLabel}
              title={toNextPageLabel}
              onClick={onSubmit}
              isEnabled={isAppointmentValid()}
              isLoading={false}
              dataqa="btn-continue"
            />
          </div>
        </>
      )}
    </>
  );
};

export const ClinicAndTimingPage = () => {
  changeTitle('Clinic and Timing');
  const {
    details: {
      reschedule,
      appointmentBeingRescheduled,
      isServiceAndClinicSelected,
      pageComplete,
    },
  } = useDetailContext();
  const { isPaymentOptionEnabled } = useApplicationContext();
  const { isGroupAppointment } = useGroupAppointmentContext();
  const { organizationId, appointmentId } = useAppParams();
  const [isValidReschedule, setIsValidReschedule] = useState(true);

  const nav = useNavigationHelper();
  const toNextPage = () => {
    if (reschedule) {
      nav.toRescheduleConfirmation(appointmentBeingRescheduled.activityid);
    } else {
      isGroupAppointment ? nav.toAddPatients() : nav.toPatientEligibility();
    }
  };

  const navLinks = useMemo(
    () =>
      reschedule
        ? makeRescheduleNavLinks(appointmentId, organizationId)
        : isGroupAppointment
          ? makeGroupAppointmentNavLinks(pageComplete, organizationId)
          : isPaymentOptionEnabled
            ? makeDefaultNavLinks(pageComplete, organizationId)
            : makeDefaultNavLinksWithNoPaymentOption(
                pageComplete,
                organizationId,
              ),
    [organizationId, pageComplete, reschedule, appointmentId],
  );

  if (!isServiceAndClinicSelected) {
    nav.resetToFirstPage();
    return null;
  }

  if (reschedule && !isValidReschedule) {
    return (
      <PageWrapper>
        <RescheduleInvalid />;
      </PageWrapper>
    );
  }

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <ClinicAndTiming
        disableEdit={reschedule}
        disableBack={reschedule}
        toNextPage={toNextPage}
        toNextPageLabel={
          reschedule
            ? getString('continueToConfirmation')
            : getString('continueToEligibilityAndScreeningPage')
        }
        setIsValidReschedule={setIsValidReschedule}
      />
    </PageWrapper>
  );
};
