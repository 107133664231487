import React from 'react';

import { AddressSuggestionItem } from './AddressSuggestionItem';

const AddressSuggestion = ({ suggestions, onSelect, isFocused }) => {
  const hideClassName = React.useMemo(() => {
    return isFocused ? '' : 'address-autofill-hide';
  }, [isFocused]);

  return (
    <div className="address-autofill">
      <div className={`address-autofill__suggestions ${hideClassName}`}>
        {suggestions.map((suggestion) => (
          <AddressSuggestionItem
            key={suggestion.id}
            address={suggestion}
            onSelect={onSelect}
          />
        ))}
      </div>
    </div>
  );
};

export { AddressSuggestion };
