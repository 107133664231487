import { DetailContext } from 'context';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import BackToPageComponent from './BackToPage';

/**
 * @type {React.FC<{
 *  text: string
 *  route: string
 * }>} props
 */
const BackToPage = (props) => {
  const { text, route } = props;
  const history = useHistory();
  const context = useContext(DetailContext);
  const {
    details: { organizationId = '' },
  } = context;

  const handleClick = () => {
    const routePath = `${route}/${organizationId}`;

    history.push({
      pathname: routePath,
      search: window.location.search,
    });
  };

  return <BackToPageComponent text={text} onClick={handleClick} />;
};

export default BackToPage;
