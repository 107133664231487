import { FiCheck } from 'vyaguta-icons/fi';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CancelledMessage from 'components/common/CanceledMessage';
import Notice from '../presentation/Notice';
import Button from 'components/common/Button';
import EmailNotice from '../presentation/EmailNotice';
import PrintAppointment from '../presentation/PrintAppointment';
import ConfirmationCode from '../../../common/ConfirmationCode';
import ActivityIndicator from 'components/common/ActivityIndicator';
import RegistrationDetails from '../presentation/RegistrationDetails';
import SkeletonLoadingContainer from 'components/common/SkeletonLoadingContainer';
import GroupConfirmationCode from 'components/COVID/common/group/confirmationCode/GroupConfirmationCode';

import { getString } from 'util/lang';
import { useDetailContext, useSelectionsContext } from 'context';
import * as DateTimeUtil from 'util/DateAndTime';
import { iconFrown, iconHurray } from 'assets/images';
import { appointmentStatus } from 'constants/patientInfo';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import RescheduleInvalid from '../../RescheduleValidity/RescheduleInvalid';

const RESCHEDULE_STATUS = {
  bothCancelled: 'BOTH_CANCELLED',
  firstFulfilledSecondNoShow: 'FIRST_FULFILLED_SECOND_NO_SHOW',
  firstFulfilledSecondCancel: 'FIRST_FULFILLED_SECOND_CANCEL',
  firstFulfilledSecondBooked: 'FIRST_FULFILLED_SECOND_BOOKED',
  bothFulfilled: 'BOTH_FULFILLED',
  bothBooked: 'BOTH_BOOKED',
  bothNoShow: 'BOTH_NO_SHOW',
};

const getRescheduleStatus = (firstAppointment, secondAppointment) => {
  if (firstAppointment?.cancelled) {
    return RESCHEDULE_STATUS.bothCancelled;
  }

  if (firstAppointment?.fulfilled) {
    if (secondAppointment?.cancelled) {
      return RESCHEDULE_STATUS.firstFulfilledSecondCancel;
    } else if (secondAppointment?.noShow) {
      return RESCHEDULE_STATUS.firstFulfilledSecondNoShow;
    } else if (secondAppointment?.fulfilled) {
      return RESCHEDULE_STATUS.bothFulfilled;
    }

    return RESCHEDULE_STATUS.firstFulfilledSecondBooked;
  }

  if (firstAppointment?.noShow) {
    return RESCHEDULE_STATUS.bothNoShow;
  }

  // Booked or checked in
  return RESCHEDULE_STATUS.bothBooked;
};

const ConfirmationContent = ({
  isRescheduleComponent,
  onCancelButtonClick,
}) => {
  const {
    details: {
      patientInfo,
      groupPendingPatientInfo,
      localizedWebContents,
      isLocalizedWebContentsFetching,
      appointments,
      loading,
      isGroupAppointment,
    },
    rescheduleDataSetup,
    resetForNewPatient,
    setRescheduleAppointment,
    isSecurityChecked,
  } = useDetailContext();
  const { organizationId, id } = useParams();
  const [isInvalidReschedule, setIsInvalidReschedule] = useState(false);
  const [isRescheduleLoading, setIsRescheduleLoading] = useState(true);
  const currentPatientInfo = patientInfo[0] ?? {};

  useEffect(() => {
    const handleRescheduleSetup = async () => {
      const dataSetupSuccess = await rescheduleDataSetup(
        organizationId,
        id,
        true,
      );

      if (!dataSetupSuccess) {
        // data setup failed -> either clinic, service or schedule is not available
        setIsInvalidReschedule(true);
      }

      setIsRescheduleLoading(false);
    };
    if (isRescheduleComponent) {
      handleRescheduleSetup();
    } else {
      setIsRescheduleLoading(false);
    }
  }, []);

  const nav = useNavigationHelper();

  const bookAnotherAppointment = () => {
    resetForNewPatient();

    nav.resetToFirstPage();
  };

  const cancelGroupAppointment = (groupAppointmentId) => {
    nav.toGroup.cancel.verify(groupAppointmentId);
  };

  const rescheduleGroupAppointment = (groupAppointmentId) => {
    nav.toGroup.reschedule.verify(groupAppointmentId);
  };

  const goToReschedule = async (activityid) => {
    await setRescheduleAppointment(activityid);

    if (isSecurityChecked) {
      return nav.toRescheduleAppointment(activityid);
    }

    nav.toReschedule(activityid);
  };

  const {
    selectedServices,
    services: allServices,
    selectedClinic,
  } = useSelectionsContext();
  const primaryService = allServices.byId[selectedServices[0]?.serviceId];
  const primarySubService = selectedServices[0]?.subService;

  if (loading || isRescheduleLoading) {
    return (
      <ActivityIndicator
        className="loader--clinic border border-radius section__container p-0x"
        message={getString('pleaseWaitLoading')}
      />
    );
  }

  if (isInvalidReschedule) {
    return <RescheduleInvalid />;
  }

  const { firstAppointment, secondAppointment } = appointments[0] ?? {};

  const rescheduleStatus = getRescheduleStatus(
    firstAppointment,
    secondAppointment,
  );

  switch (rescheduleStatus) {
    case RESCHEDULE_STATUS.bothCancelled:
      return (
        <CancelledMessage
          badge={
            <img
              tabIndex="0"
              src={iconFrown}
              alt={getString('frownAlt')}
              className="mb-4x"
            />
          }
          reBook={bookAnotherAppointment}
          message={getString('appointmentCancelledMessage')}
          showBookAppointmentButton={true}
        />
      );

    case RESCHEDULE_STATUS.bothNoShow:
      return (
        <CancelledMessage
          badge={
            <img
              tabIndex="0"
              src={iconFrown}
              alt={getString('frownAlt')}
              className="mb-4x"
            />
          }
          reBook={bookAnotherAppointment}
          title={getString('weMissedYou')}
          message={getString('noShowMessage')}
          showBookAppointmentButton={true}
        />
      );

    case RESCHEDULE_STATUS.bothFulfilled:
    case RESCHEDULE_STATUS.firstFulfilledSecondBooked:
    case RESCHEDULE_STATUS.firstFulfilledSecondCancel:
    case RESCHEDULE_STATUS.firstFulfilledSecondNoShow:
    case RESCHEDULE_STATUS.bothBooked:
      const showConfirmationCode =
        currentPatientInfo.confirmationCode &&
        !(
          firstAppointment?.noShow ||
          secondAppointment?.noShow ||
          secondAppointment?.cancelled ||
          secondAppointment?.fulfilled
        );

      const [primaryServiceContent = {}] = localizedWebContents;
      const { appointmentSuccessMessage = null } = primaryServiceContent;
      const { activityid } = firstAppointment || secondAppointment || {};
      const { email } = currentPatientInfo;

      const serviceConfirmationMessage =
        primaryService?.serviceConfirmationMessage;

      const groupAppointmentId = firstAppointment.groupAppointmentId;

      return (
        <div className="success">
          {showConfirmationCode && <PrintAppointment />}
          <section className="selected-details section__margin">
            <div className="border border-radius section__container p-0x">
              {showConfirmationCode && (
                <>
                  <FiCheck
                    size={24}
                    className="success-check success-check--covid mt-9x"
                  />
                  {isGroupAppointment ? (
                    <GroupConfirmationCode patientInfo={patientInfo} />
                  ) : (
                    <ConfirmationCode
                      code={currentPatientInfo.confirmationCode}
                    />
                  )}
                </>
              )}
              {rescheduleStatus === RESCHEDULE_STATUS.bothFulfilled && (
                <CancelledMessage
                  reBook={bookAnotherAppointment}
                  badge={
                    <img
                      tabIndex="0"
                      src={iconHurray}
                      alt={getString('hurrayAlt')}
                      className="mb-4x"
                    />
                  }
                  title={getString('vaccinated')}
                  message={getString('vaccinatedMessage')}
                  showBookAppointmentButton={false}
                />
              )}
              {rescheduleStatus ===
                RESCHEDULE_STATUS.firstFulfilledSecondCancel && (
                <CancelledMessage
                  reBook={bookAnotherAppointment}
                  badge={
                    <img
                      tabIndex="0"
                      src={iconFrown}
                      alt={getString('frownAlt')}
                      className="mb-4x"
                    />
                  }
                  title={getString('secondCancelled')}
                  message={getString('noShowMessage')}
                  showBookAppointmentButton={true}
                />
              )}
              {rescheduleStatus ===
                RESCHEDULE_STATUS.firstFulfilledSecondNoShow && (
                <CancelledMessage
                  reBook={bookAnotherAppointment}
                  badge={
                    <img
                      tabIndex="0"
                      src={iconFrown}
                      alt={getString('frownAlt')}
                      className="mb-4x"
                    />
                  }
                  title={getString('weMissedYou')}
                  message={getString('appointmentCancelledMessage')}
                  showBookAppointmentButton={true}
                />
              )}
              {firstAppointment && (
                <RegistrationDetails
                  onReschedule={goToReschedule}
                  dosNumber={getString('doseNumberFirst')}
                  appointment={firstAppointment}
                  selectedClinic={selectedClinic}
                  activityid={activityid}
                  showConfirmDialogBox={true}
                  serviceConfirmationMessage={serviceConfirmationMessage}
                  selections={selectedServices}
                  isGroupAppointment={isGroupAppointment}
                  patientCount={
                    patientInfo.length + groupPendingPatientInfo?.length
                  }
                  groupAppointmentId={groupAppointmentId}
                  rescheduleGroupAppointment={rescheduleGroupAppointment}
                />
              )}
              {secondAppointment && (
                <RegistrationDetails
                  cancelled={secondAppointment.cancelled}
                  onReschedule={goToReschedule}
                  dosNumber={getString('doseNumberSecond')}
                  appointment={secondAppointment}
                  selectedClinic={selectedClinic}
                  activityid={activityid}
                  showConfirmDialogBox={true}
                  firstDoseDate={DateTimeUtil.toDayAndMonth(
                    firstAppointment?.date || secondAppointment?.date,
                  )}
                  isWaitlisted={
                    primarySubService.secondAppointmentStatus ===
                    appointmentStatus.waitlist
                  }
                  selections={selectedServices}
                  isGroupAppointment={isGroupAppointment}
                  patientCount={
                    patientInfo.length + groupPendingPatientInfo?.length
                  }
                  groupAppointmentId={groupAppointmentId}
                  rescheduleGroupAppointment={rescheduleGroupAppointment}
                />
              )}
              {(firstAppointment?.canBeRescheduled ||
                secondAppointment?.canBeRescheduled) && (
                <div className="success-content">
                  <SkeletonLoadingContainer
                    loading={isLocalizedWebContentsFetching}
                  >
                    {appointmentSuccessMessage && (
                      <Notice noticeMessage={appointmentSuccessMessage} />
                    )}
                  </SkeletonLoadingContainer>
                  <EmailNotice email={email} />
                  <div className="success-action-covid">
                    <Button
                      isEnabled
                      label={getString('bookAnother')}
                      onClick={bookAnotherAppointment}
                      className="btn btn--large btn-primary--outline"
                      dataqa="book-another-appt-btn"
                    />
                    <Button
                      isEnabled
                      label={getString('cancelAppointment')}
                      className="btn btn--large btn-danger--outline"
                      onClick={() => {
                        if (isGroupAppointment) {
                          cancelGroupAppointment(groupAppointmentId);
                        } else {
                          onCancelButtonClick();
                        }
                      }}
                      dataqa="cancel-appt-btn"
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      );

    default:
      return null;
  }
};

export default ConfirmationContent;
