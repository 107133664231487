import { useMemo } from 'react';

import SelectService from './SelectService';
import { SelectServiceType } from 'components/COVID/common/SelectServiceType/SelectServiceType';

const noop = () => {};

/**
 * @typedef {import('types').ServiceType} ServiceType
 * @typedef {import('types').Service} Service
 * @typedef {ServiceType & { services: Service[] }} ServiceTypeWithServices
 * @typedef {{ serviceTypeId: string, serviceId: string }} ServiceSelection
 *
 * @param {{
 *    serviceSelection: ServiceSelection
 *    serviceTypes: ServiceTypeWithServices[]
 *    onSelectServiceType: (serviceTypeId: string) => void
 *    onSelectService: (serviceId: string) => void
 * }} props
 * @returns
 */
const SelectMultiServiceForm = (props) => {
  const {
    serviceSelection,
    serviceTypes,
    onSelectServiceType,
    onSelectService,
  } = props;

  const selectedServiceType = useMemo(() => {
    return serviceTypes.find(
      ({ id }) => id === serviceSelection?.serviceTypeId,
    );
  }, [serviceTypes, serviceSelection?.serviceTypeId]);

  return (
    <div>
      <SelectServiceType
        serviceTypeList={serviceTypes}
        onServiceTypeChange={onSelectServiceType}
        selectedServiceTypeId={serviceSelection.serviceTypeId}
        handleDropDownClick={noop}
      />
      <SelectService
        services={selectedServiceType?.services ?? []}
        selectedServiceType={serviceSelection.serviceTypeId}
        selectedService={serviceSelection.serviceId}
        onServiceChange={onSelectService}
      />
    </div>
  );
};

export default SelectMultiServiceForm;
