import React, { useMemo } from 'react';
import { useAppParams } from 'context/useAppParams';

import {
  useDetailContext,
  useGroupAppointmentContext,
  useApplicationContext,
} from 'context';
import { useAppointmentSubmission } from 'context/useAppointmentSubmission';
import Confirmation from './Confirmation';
import { IntialAppStateCovid } from 'constants/app';
import { PageWrapper } from 'components/COVID/common/PageWrapper';

import { useNavigationHelper } from 'hooks/useNavigationHelper';
import {
  makeDefaultNavLinks,
  makeGroupAppointmentNavLinks,
  makeDefaultNavLinksWithNoPaymentOption,
} from 'components/common/NavBar/navLinks';
import { useBatchAppointmentSubmission } from '../../../../../context/useGroupAppointmentSubmission';

const InitialState = JSON.parse(JSON.stringify(IntialAppStateCovid));

export const ConfirmationPage = () => {
  const nav = useNavigationHelper();
  const { organizationId, appointmentId } = useAppParams();
  const {
    details: {
      pageComplete,
      selectedLocationId,
      selectedServices,
      appointmentSuccess,
    },
  } = useDetailContext();
  const { isPaymentOptionEnabled } = useApplicationContext();
  const { isGroupAppointment } = useGroupAppointmentContext();

  const appContext = useDetailContext();
  const { details, setDetails, setAppointmentSuccess } = appContext;
  const { appSettings } = details;

  const { submitAppointment } = useAppointmentSubmission();
  const { submitAppointment: groupSubmitAppointment } =
    useBatchAppointmentSubmission();

  const toNextPage = () => {
    nav.toSuccess();
  };

  const goBack = () => {
    nav.goBack();
  };

  const navLinks = useMemo(
    () =>
      isGroupAppointment
        ? makeGroupAppointmentNavLinks(pageComplete, organizationId)
        : isPaymentOptionEnabled
          ? makeDefaultNavLinks(pageComplete, organizationId)
          : makeDefaultNavLinksWithNoPaymentOption(
              pageComplete,
              organizationId,
            ),
    [organizationId, pageComplete, appointmentId],
  );

  if (!selectedServices.length && !selectedLocationId) {
    nav.resetToFirstPage();

    return null;
  }

  if (appointmentSuccess) {
    // IF the user goes 'back' to confirm page after succes,
    // reset the state and and redirect to service selection
    setDetails({ ...InitialState, appSettings });
    setAppointmentSuccess(false);
    nav.resetToFirstPage();
    return null;
  }

  return (
    <PageWrapper showNavMenu navLinks={navLinks}>
      <Confirmation
        toNextPage={toNextPage}
        goBack={goBack}
        isGroupAppointment={isGroupAppointment}
        confirmAndSubmit={
          isGroupAppointment ? groupSubmitAppointment : submitAppointment
        }
      />
    </PageWrapper>
  );
};
