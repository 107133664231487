import React from 'react';
import MessageBulletListBox from './MessageBulletListBox';
import { MessageBtn } from './MessageBtn';
import { getString } from 'util/lang';

/**
 *
 * @param {{
 *  selectedServices: string[]
 *  className?: string
 *  onClick: () => void
 *  disableEdit?: boolean
 * }} props
 * @returns
 */
const SelectedServicesListBox = (props) => {
  const { selectedServices, className, onClick } = props;
  const selectedServicesMap = selectedServices.map((s) => {
    return { message: s };
  });

  return (
    <div className={className}>
      <div className="mb-1x d-flex flex-wrap justify-content-between">
        <h2 className="mb-1x ">
          <span className="color-primary-base">
            {selectedServices?.length} services
          </span>{' '}
          in this appointment
        </h2>
        {!props?.disableEdit && (
          <MessageBtn label={getString('editService')} onClick={onClick} />
        )}
      </div>
      <MessageBulletListBox
        messages={selectedServicesMap}
        className={'mt-1x'}
      />
    </div>
  );
};

export default SelectedServicesListBox;
