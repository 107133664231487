/**
 * BeforeApproval is used to identify access to clinic and timing at the start of the flow and end of the flow.
 * At the start patient are allowed access by setting pageComplete access to true.
 * When a patient submits the appointment request the access to clinic and timing is disabled by setting BeforeApproval to false
 */
export const CovidPageNames = {
  BeforeApproval: 'BeforeApproval',
  AddPatients: 'AddPatients',
  SelectClinicAndTiming: 'Clinic & Timing',
  PatientEligibility: 'Screening Questions',
  PatientInfo: 'Patient Info',
  PaymentInfo: 'Payment Info',
  Confirmation: 'Confirmation',
};
