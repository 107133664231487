import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { iconRadioCheck, iconRadioUnCheck } from 'assets/images';

const RadioCheckBoxPdfView = ({ options, selectedValue, style }) => {
  return (
    <View style={{ ...styles.container, ...style }}>
      {options.map((option) => (
        <View key={option.id} style={{ ...styles.radioWrap, marginRight: 12 }}>
          {/* Check if option is selected, show the correct image */}
          {selectedValue === option.value ? (
            <Image
              src={iconRadioCheck}
              alt="checkbox-checked"
              style={styles.img}
            />
          ) : (
            <Image
              src={iconRadioUnCheck}
              alt="checkbox-unchecked"
              style={styles.img}
            />
          )}
          <Text style={styles.radioText}>{option.label}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  radioWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  img: {
    height: 14,
    width: 14,
  },
  radioText: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    marginLeft: 5,
  },
});

export default RadioCheckBoxPdfView;
