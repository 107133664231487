import { CovidPageNames } from 'constants/pages';
import { routes } from 'hooks/useNavigationHelper';
import { getString } from 'util/lang';

/**
 * @desc Locations with payment and no payment option shares the navLinks
 */
const sharedDefaultLinksForRegularAppointment = (
  pageComplete,
  organizationId,
) => [
  {
    pageNumber: 1,
    label: getString('scheduleAppointment'),
    to: routes.selectTiming(organizationId),
    active: pageComplete[CovidPageNames.BeforeApproval],
    complete: pageComplete[CovidPageNames.SelectClinicAndTiming],
    dataqa: 'nav-schedule',
  },
  {
    pageNumber: 2,
    label: getString('patientEligibility'),
    to: routes.patientEligibility(organizationId),
    active: pageComplete[CovidPageNames.SelectClinicAndTiming],
    complete: pageComplete[CovidPageNames.PatientEligibility],
    dataqa: 'nav-eligibility',
  },
  {
    pageNumber: 3,
    label: getString('patientInfo'),
    to: routes.patientInfo(organizationId),
    active: pageComplete[CovidPageNames.PatientEligibility],
    complete: pageComplete[CovidPageNames.PatientInfo],
    dataqa: 'nav-patient-info',
  },
];

/**
 * @typedef {{
 *    label: string
 *    to: string
 *    pageNumber: string | number
 *    complete: boolean
 *    active: boolean
 *    dataqa?: string
 * }} NavLinkConfig
 *
 * @param {{ [key: string]: boolean }} pageComplete
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeDefaultNavLinks = (pageComplete, organizationId) => {
  return [
    ...sharedDefaultLinksForRegularAppointment(pageComplete, organizationId),
    {
      pageNumber: 4,
      label: getString('paymentInfo'),
      to: routes.paymentInfo(organizationId),
      active: pageComplete[CovidPageNames.PatientInfo],
      complete: pageComplete[CovidPageNames.PaymentInfo],
      dataqa: 'nav-payment-info',
    },
    {
      pageNumber: 5,
      label: getString('confirmation'),
      to: routes.confirmation(organizationId),
      active: pageComplete[CovidPageNames.PaymentInfo],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

export const makeDefaultNavLinksWithNoPaymentOption = (
  pageComplete,
  organizationId,
) => {
  return [
    ...sharedDefaultLinksForRegularAppointment(pageComplete, organizationId),
    {
      pageNumber: 4,
      label: getString('confirmation'),
      to: routes.confirmation(organizationId),
      active: pageComplete[CovidPageNames.PatientInfo],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

/**
 * @typedef {{
 *    label: string
 *    to: string
 *    pageNumber: string | number
 *    complete: boolean
 *    active: boolean
 *    dataqa?: string
 * }} NavLinkConfig
 *
 * @param {{ [key: string]: boolean }} pageComplete
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeGroupAppointmentNavLinks = (pageComplete, organizationId) => {
  return [
    {
      pageNumber: 1,
      label: getString('scheduleAppointment'),
      to: routes.selectTiming(organizationId),
      active: pageComplete[CovidPageNames.BeforeApproval],
      complete: pageComplete[CovidPageNames.SelectClinicAndTiming],
      dataqa: 'nav-schedule',
    },
    {
      pageNumber: 2,
      label: getString('addPatients'),
      to: routes.addPatients(organizationId),
      active: pageComplete[CovidPageNames.SelectClinicAndTiming],
      complete: pageComplete[CovidPageNames.AddPatients],
      dataqa: 'nav-add-patient',
    },
    {
      pageNumber: 3,
      label: getString('patientDetails'),
      to: routes.patientEligibility(organizationId),
      active: pageComplete[CovidPageNames.AddPatients],
      complete: pageComplete[CovidPageNames.PatientEligibility],
      dataqa: 'nav-eligibility',
    },
    // {
    //   pageNumber: 4,
    //   label: getString('patientInfoAndConfirm'),
    //   to: routes.patientInfo(organizationId),
    //   active: pageComplete[CovidPageNames.PatientEligibility],
    //   complete: pageComplete[CovidPageNames.PatientInfo],
    //   dataqa: 'nav-patient-info',
    // },
    {
      pageNumber: 4,
      label: getString('confirmation'),
      to: routes.confirmation(organizationId),
      active: pageComplete[CovidPageNames.PatientInfo],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

const sharedFollowUpRescheduleNavLinks = (
  pageComplete,
  appointmentId,
  organizationId,
) => [
  {
    pageNumber: '1',
    label: getString('scheduleAppointment'),
    to: routes.followup.reschedule.schedule(organizationId, appointmentId),
    active: pageComplete[CovidPageNames.BeforeApproval],
    complete: pageComplete[CovidPageNames.SelectClinicAndTiming],
    dataqa: 'nav-schedule',
  },
  {
    pageNumber: '2',
    label: getString('patientEligibility'),
    to: routes.followup.reschedule.screening(organizationId, appointmentId),
    active: pageComplete[CovidPageNames.SelectClinicAndTiming],
    complete: pageComplete[CovidPageNames.PatientEligibility],
    dataqa: 'nav-eligibility',
  },
  {
    pageNumber: '3',
    label: getString('patientInfo'),
    to: routes.followup.reschedule.patientInfo(organizationId, appointmentId),
    active: pageComplete[CovidPageNames.PatientEligibility],
    complete: pageComplete[CovidPageNames.PatientInfo],
    dataqa: 'nav-patient-info',
  },
];

/**
 *
 * @param {{ [key: string]: boolean }} pageComplete
 * @param {string} appointmentId
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeFollowupRescheduleNavLinks = (
  pageComplete,
  appointmentId,
  organizationId,
) => {
  return [
    ...sharedFollowUpRescheduleNavLinks(
      pageComplete,
      appointmentId,
      organizationId,
    ),
    {
      pageNumber: '4',
      label: getString('paymentInfo'),
      to: routes.followup.reschedule.paymentInfo(organizationId),
      active: pageComplete[CovidPageNames.PatientInfo],
      complete: pageComplete[CovidPageNames.PaymentInfo],
      dataqa: 'nav-payment-info',
    },
    {
      pageNumber: '5',
      label: getString('confirmation'),
      to: routes.followup.reschedule.confirmation(
        organizationId,
        appointmentId,
      ),
      active: pageComplete[CovidPageNames.PaymentInfo],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

export const makeFollowupRescheduleNavLinksWithNoPaymentOption = (
  pageComplete,
  appointmentId,
  organizationId,
) => {
  return [
    ...sharedFollowUpRescheduleNavLinks(
      pageComplete,
      appointmentId,
      organizationId,
    ),
    {
      pageNumber: '4',
      label: getString('confirmation'),
      to: routes.followup.reschedule.confirmation(
        organizationId,
        appointmentId,
      ),
      active: pageComplete[CovidPageNames.PatientInfo],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

/**
 *
 * @param {{ [key: string]: boolean }} pageComplete
 * @param {string} appointmentId
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeGroupRescheduleNavLinks = (
  pageComplete,
  appointmentId,
  organizationId,
) => {
  return [
    {
      pageNumber: '1',
      label: getString('scheduleAppointment'),
      to: routes.group.reschedule.schedule(organizationId, appointmentId),
      active: pageComplete[CovidPageNames.BeforeApproval],
      complete: pageComplete[CovidPageNames.SelectClinicAndTiming],
      dataqa: 'nav-schedule',
    },
    {
      pageNumber: '2',
      label: getString('confirmation'),
      to: routes.group.reschedule.confirmation(organizationId, appointmentId),
      active: pageComplete[CovidPageNames.SelectClinicAndTiming],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

/**
 * @desc Locations with payment and no payment option shares the navLinks for follow up register
 */
const sharedFollowUpRegisterNavLinks = (
  pageComplete,
  appointmentId,
  organizationId,
) => [
  {
    pageNumber: '1',
    label: getString('scheduleAppointment'),
    to: '#',
    active: false,
    complete: true,
    dataqa: 'nav-schedule',
  },
  {
    pageNumber: '2',
    label: getString('patientEligibility'),
    to: routes.followup.register.screening(organizationId, appointmentId),
    active: pageComplete[CovidPageNames.SelectClinicAndTiming],
    complete: pageComplete[CovidPageNames.PatientEligibility],
    dataqa: 'nav-eligibility',
  },
  {
    pageNumber: '3',
    label: getString('patientInfo'),
    to: routes.followup.register.patientInfo(organizationId, appointmentId),
    active: pageComplete[CovidPageNames.PatientEligibility],
    complete: pageComplete[CovidPageNames.PatientInfo],
    dataqa: 'nav-patient-info',
  },
];

/**
 *
 * @param {{ [key: string]: boolean }} pageComplete
 * @param {string} appointmentId
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeFollowupRegisterNavLinksWithNoPaymentOption = (
  pageComplete,
  appointmentId,
  organizationId,
) => {
  return [
    ...sharedFollowUpRegisterNavLinks(
      pageComplete,
      appointmentId,
      organizationId,
    ),
    {
      pageNumber: '4',
      label: getString('confirmation'),
      to: routes.followup.register.confirmation(organizationId, appointmentId),
      active: pageComplete[CovidPageNames.PatientInfo],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

/**
 *
 * @param {{ [key: string]: boolean }} pageComplete
 * @param {string} appointmentId
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeFollowupRegisterNavLinks = (
  pageComplete,
  appointmentId,
  organizationId,
) => {
  return [
    ...sharedFollowUpRegisterNavLinks(
      pageComplete,
      appointmentId,
      organizationId,
    ),
    {
      pageNumber: '4',
      label: getString('paymentInfo'),
      to: routes.followup.register.paymentInfo(organizationId),
      active: pageComplete[CovidPageNames.PatientInfo],
      complete: pageComplete[CovidPageNames.PaymentInfo],
      dataqa: 'nav-payment-info',
    },
    {
      pageNumber: '5',
      label: getString('confirmation'),
      to: routes.followup.register.confirmation(organizationId, appointmentId),
      active: pageComplete[CovidPageNames.PaymentInfo],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

/**
 *
 * @param {{ [key: string]: boolean }} pageComplete
 * @param {string} appointmentId
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeGroupRegisterNavLinks = (
  pageComplete,
  appointmentId,
  organizationId,
) => {
  return [
    {
      pageNumber: '1',
      label: getString('scheduleAppointment'),
      to: '#',
      active: false,
      complete: true,
      dataqa: 'nav-schedule',
    },
    {
      pageNumber: '2',
      label: getString('patientEligibility'),
      to: routes.group.register.screening(organizationId, appointmentId),
      active: pageComplete[CovidPageNames.SelectClinicAndTiming],
      complete: pageComplete[CovidPageNames.PatientEligibility],
      dataqa: 'nav-eligibility',
    },
    {
      pageNumber: '3',
      label: getString('patientInfo'),
      to: routes.group.register.patientInfo(organizationId, appointmentId),
      active: pageComplete[CovidPageNames.PatientEligibility],
      complete: pageComplete[CovidPageNames.PatientInfo],
      dataqa: 'nav-patient-info',
    },
    {
      pageNumber: '4',
      label: getString('confirmation'),
      to: routes.group.register.confirmation(organizationId, appointmentId),
      active: pageComplete[CovidPageNames.PatientInfo],
      complete: pageComplete[CovidPageNames.Confirmation],
      dataqa: 'nav-confirmation',
    },
  ];
};

/**
 *
 * @param {string} appointmentId
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeRescheduleNavLinks = (appointmentId, organizationId) => {
  return [
    {
      pageNumber: '1',
      label: getString('scheduleAppointment'),
      to: routes.rescheduleAppointment(organizationId, appointmentId),
      active: true,
      complete: false,
      dataqa: 'nav-schedule',
    },
    {
      pageNumber: '2',
      label: getString('confirmation'),
      to: routes.rescheduleConfirmation(organizationId, appointmentId),
      active: true,
      complete: false,
      dataqa: 'nav-confirmation',
    },
  ];
};

/**
 *
 * @param {{ [key: string]: boolean }} pageComplete
 * @param {string} [organizationId]
 * @returns {NavLinkConfig[]}
 */
export const makeWaitlistNavLinks = (pageComplete, organizationId) => {
  return [
    {
      pageNumber: '1',
      label: getString('eligibilityAndHealthScreening'),
      to: routes.waitlist.screening(organizationId),
      active: pageComplete[CovidPageNames.BeforeApproval],
      complete: pageComplete[CovidPageNames.PatientEligibility],
      dataqa: 'nav-eligibility-screening',
    },
  ];
};
