import React from 'react';
import AlertMessage from '../../../common/AlertMessage';
import { getString } from '../../../../../util/lang';

const WaitListNotice = () => {
  const infoMessage = getString('waitListNotice');
  return (
    // <div className="success-content border-bottom">
    <AlertMessage
      type="info"
      message={infoMessage}
      className="mt-5x waitlist"
      isVisible={infoMessage !== ''}
    />
    // </div>
  );
};

export default WaitListNotice;
