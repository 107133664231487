import * as Yup from 'yup';
import { getString } from '../../../../../util/lang';
import regexPattern from '../../../../../constants/regexPattern';
import * as Regex from '../../../../../util/regex';
import { dashMobileFormat, limitChars } from '../../../../../util/TextFormat';
import DateTimeUtils from '../../../../../util/DateAndTime';
import { isPatientBirthInformationPartiallyFilled } from '../../../../../util/string';

export const addPatientFieldNames = {
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  birthDay: 'birthDay',
  birthYear: 'birthYear',
  birthMonth: 'birthMonth',
  email: 'email',
  sendEmail: 'sendEmail',
  mobileNo: 'mobileNo',
  birthDate: 'birthDate',
};

const AddPatientBasicInfoYupSchema = () => {
  return {
    firstName: Yup.string()
      .required(getString('firstNameRequired'))
      .matches(regexPattern.nameField, {
        message: getString('firstNameValidationMessage'),
        excludeEmptyString: true,
      }),

    lastName: Yup.string()
      .required(getString('lastNameRequired'))
      .matches(regexPattern.nameField, getString('lastNameValidationMessage')),

    middleName: Yup.string().matches(regexPattern.nameField, {
      message: getString('middleNameValidationMessage'),
      excludeEmptyString: true,
    }),

    birthMonth: Yup.string().when(['sendEmail'], {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required(getString('monthRequired')),
    }),

    birthDay: Yup.string().when(['sendEmail'], {
      is: true,
      then: Yup.string().matches(
        /^([1-2]?[0-9]|3[0-1])$/,
        getString('dayValidationMessage'),
      ),
      otherwise: Yup.string()
        .required(getString('dayRequired'))
        .matches(/^([1-2]?[0-9]|3[0-1])$/, getString('dayValidationMessage')),
    }),

    birthYear: Yup.string().when(['sendEmail'], {
      is: true,
      then: Yup.string().matches(
        Regex.ValidBirthYear(),
        getString('yearValidationMessage'),
      ),
      otherwise: Yup.string()
        .required(getString('yearRequired'))
        .matches(Regex.ValidBirthYear(), getString('yearValidationMessage')),
    }),
    sendEmail: Yup.boolean().required(),
    mobileNo: Yup.string()
      .matches(/\W*([0-9]{3})-\W*([0-9]{3})-\W*([0-9]{4})/, {
        message: getString('mobileNumberValid'),
        excludeEmptyString: true,
      })
      .max(12, getString('mobileNumberValidationMessageMax')),
    email: Yup.string()
      .required(getString('emailRequired'))
      .email(getString('validEmailRequired')),
  };
};

export const checkAddPatientFormValidity = (patientInfo) => {
  let errors = {};

  const schema = AddPatientBasicInfoYupSchema();
  Object.keys(patientInfo).forEach((eachKey) => {
    try {
      if (!patientInfo['sendEmail'] && eachKey === 'email') return;

      //ignore validation for birthdates are completely empty and if send email is true
      const birthInformationKey =
        eachKey === 'birthDay' ||
        eachKey === 'birthMonth' ||
        eachKey === 'birthYear';
      if (
        patientInfo?.sendEmail &&
        !isPatientBirthInformationPartiallyFilled(patientInfo) &&
        birthInformationKey
      ) {
        return;
      }

      schema[eachKey].validateSync(patientInfo[eachKey]);
    } catch (err) {
      errors[eachKey] = err.message;
    }
  });

  return errors;
};

export const formatMobileNumber = (value) => {
  return dashMobileFormat(value);
};

export const getServiceAgeValidation = (
  selectedServices,
  birthYear,
  birthMonth,
  birthDay,
  patientIndex,
) => {
  if (birthDay && birthMonth && birthYear) {
    const birthDate = `${birthMonth}-${birthDay}-${birthYear}`;

    for (let i = 0; i < selectedServices.length; i++) {
      const subService = selectedServices[i].subService;

      if (
        DateTimeUtils.isUnderMinimumAge(
          birthDate,
          subService.minAgeRequirement,
        ) || // same error messages
        DateTimeUtils.isAboveMaximumAge(birthDate, subService.maxAgeRequirement)
      ) {
        // return displayable error object
        return {
          birthDate: getString(
            'patientUnderAgeValidationMsg',
            patientIndex,
            subService.serviceName,
          ),
        };
      }
    }
  }

  return {};
};
