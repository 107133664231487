export const collapsibleContent = [
  {
    trigger: 'What is Signetic for Pharmacy?',
    content:
      'While vaccinating 500k+ people we found that local pharmacies were the trusted place where patients looked for advice and help during the pandemic. We also realized that our software platform was perfect for helping pharmacies provide more medical services by streamlining the appointment and billing process.\n\nThus, “Signetic for Pharmacy”, was created. It is a software platform that is focusing on solving the problems that local pharmacies encounter.',
  },
  {
    trigger: "What is Signetic's Mission?",
    content:
      "Signetic's mission is to enable independent pharmacies to better serve their communities.",
  },
  {
    trigger: 'How does online scheduling work?',
    content:
      'Using a link you provide to your community, a patient can create, preregister, and manage an appointment with ease. With automated email and/or text message notifications, you and your patient are always in sync.',
  },
  {
    trigger: 'How do you fulfill patients?',
    content:
      'After a patient arrives for a service, the Staff Portal will guide your staff through the entire process, from verifying preregistered patient information through to fulfilling the patient with the push of a button.',
  },
];
