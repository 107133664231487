import { useDetailContext } from './index';
import { IntialAppStateCovid } from 'constants/app';
import { useCallback } from 'react';

/**
 * @typedef {import('./index').DetailsState} DetailsState
 */

/**
 * @template T
 * @param {T} obj
 * @returns T
 */
const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const useResetAppState = () => {
  const { setDetails } = useDetailContext();

  const resetAppState = useCallback(
    /** @param {Partial<DetailsState> | (s: DetailsState) => Partial<DetailsState>} preservedState */
    (preservedState) => {
      setDetails((details) => {
        return {
          // use copy to prevent issues from accidental mutations
          ...deepCopy(IntialAppStateCovid),
          // keep items fetched initially
          // languages is saved inside appsettings
          appLanguageCode: details.appLanguageCode,
          appSettings: details.appSettings,
          states: details.states,
          counties: details.counties,
          // any other state that needs to be preserved
          ...(typeof preservedState === 'function'
            ? preservedState(details)
            : preservedState),
        };
      });
    },
    [setDetails],
  );

  return resetAppState;
};
